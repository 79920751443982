import type { AdvancedSearchParams } from "~/components/TableLayout";
import type { MainTableProps } from "~/components/ui/MainTable";

import { useMemo, useState } from "react";
import { useInfiniteQuery, useMutation } from "@tanstack/react-query";
import { documentAttachOutline, mailOutline, walletOutline } from "ionicons/icons";

import { membershipStates } from "~/config/api-constants";
import { queryKeys } from "~/config/query-keys-constants";
import { formatDisplayDate, formatMoney } from "~/utils/misc";
import { useAuthContext } from "~/contexts/authContext";
import { useUIContext } from "~/contexts/uiContext";
import { useFileDeliver } from "~/hooks/useFileDeliver";
import { useModal } from "~/hooks/useModal";
import { handleApiError } from "~/services/errors";
import { getNextPageParamFn } from "~/api/_config";
import { getMemberships, sendMembershipInvoiceToCustomer } from "~/api/membership";
import { CooperatorsMembershipBillsSearchForm } from "~/components/AdvancedSearchForms/Cooperators/CooperatorsMembershipBillsSearchForm";
import { ConfirmationModal } from "~/components/Modals/ConfirmationModal";
import { MembershipPaymentsModal } from "~/components/Modals/MembershipPaymentsModal";
import { TableLayout } from "~/components/TableLayout";
import { ExternalLink, Link } from "~/components/ui/Link";

export const CooperatorsMembershipBills = () => {
  const { isAdmin } = useAuthContext();
  const { toast } = useUIContext();
  const { openModal, closeModal, renderModal } = useModal();
  const { openFile } = useFileDeliver();

  const [advancedSearchParams, setAdvancedSearchParams] = useState<AdvancedSearchParams>(null);

  const queryParams = {
    states: [membershipStates.STATE_BILL],
    ...advancedSearchParams
  };

  const { data, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: [queryKeys.memberships, queryParams],
    queryFn: ({ pageParam }) => getMemberships({ ...queryParams, page: pageParam }),
    getNextPageParam: getNextPageParamFn
  });

  const sendMembershipInvoiceToCustomerMutation = useMutation(sendMembershipInvoiceToCustomer, {
    onSuccess: () => {
      toast("La facture a été envoyé avec succès.", "success");
    },
    onError(error) {
      handleApiError(error, toast);
    }
  });

  const tableData: MainTableProps["data"] = useMemo(
    () =>
      data?.pages.flat().map((membership) => ({
        itemId: membership?.id,
        actions: [
          {
            label: "Consulter la facture",
            icon: documentAttachOutline,
            color: "sky",
            action: () =>
              openFile({ itemType: "membership-billed", itemParams: { id: membership?.id } })
          },
          {
            label: "Renvoyer la facture au coopérateur par email",
            icon: mailOutline,
            color: "amber",
            action: () =>
              openModal(
                <ConfirmationModal
                  title="Renvoyer la facture au client par email"
                  isOpen={true}
                  onClose={closeModal}
                  onConfirm={() =>
                    membership?.id
                      ? sendMembershipInvoiceToCustomerMutation.mutate({ id: membership.id })
                      : null
                  }
                >
                  Êtes vous sûr de vouloir renvoyer la facture au client par email ?
                </ConfirmationModal>
              )
          },
          {
            label: "Voir les réglements",
            icon: walletOutline,
            color: "teal",
            condition: isAdmin,
            action: () =>
              membership?.id
                ? openModal(
                    <MembershipPaymentsModal
                      membership={membership}
                      isOpen={true}
                      onClose={closeModal}
                    />
                  )
                : null
          }
        ],
        columns: [
          {
            key: "invoicedAt",
            label: "Date",
            display: membership?.invoicedAt ? formatDisplayDate(membership.invoicedAt) : "",
            sort: membership?.invoicedAt,
            search: membership?.invoicedAt ? formatDisplayDate(membership.invoicedAt) : ""
          },
          {
            key: "idFactureNormalized",
            label: "N° facture",
            display: (
              <Link
                to={`/file/deliver/membership-billed/${membership?.id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {membership?.idFactureNormalized}
              </Link>
            )
          },
          {
            key: "idNormalized",
            label: "N° contrat",
            display: (
              <Link
                to={`/file/deliver/membership-unbilled/${membership?.id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {membership?.idNormalized}
              </Link>
            )
          },
          {
            key: "cooperator.lastname",
            label: "Coopérateur",
            display: (
              <div className="flex flex-col gap-1">
                {membership?.cooperator?.companyName ? (
                  <span className="font-medium">{membership.cooperator.companyName}</span>
                ) : null}
                {membership?.cooperator?.lastname || membership?.cooperator?.firstname ? (
                  <span>
                    {membership.cooperator.lastname} {membership.cooperator.firstname}
                  </span>
                ) : null}
                {membership?.cooperator?.emailContact || membership?.cooperator?.email ? (
                  <ExternalLink
                    to={`mailto:${membership.cooperator.emailContact || membership.cooperator.email}`}
                    className="text-xs"
                  >
                    {membership.cooperator.emailContact || membership.cooperator.email}
                  </ExternalLink>
                ) : null}
                {membership?.cooperator?.mobileNumber ? (
                  <ExternalLink
                    to={`tel:${membership.cooperator.mobileNumber}`}
                    className="text-xs"
                  >
                    {membership.cooperator.mobileNumber}
                  </ExternalLink>
                ) : null}
                {membership?.cooperator?.fixeNumber ? (
                  <ExternalLink to={`tel:${membership.cooperator.fixeNumber}`} className="text-xs">
                    {membership.cooperator.fixeNumber}
                  </ExternalLink>
                ) : null}
              </div>
            ),
            sort: membership?.cooperator?.lastname,
            search: `${membership?.cooperator?.companyName} ${membership?.cooperator?.lastname} ${membership?.cooperator?.firstname} ${membership?.cooperator?.email} ${membership?.cooperator?.mobileNumber} ${membership?.cooperator?.fixeNumber}`,
            condition: isAdmin
          },
          {
            key: "totalTTC",
            label: "Montant TTC",
            display: membership?.totalTTC ? formatMoney(membership.totalTTC) : "",
            sort: membership?.totalTTC ? membership.totalTTC : "",
            search: membership?.totalTTC ? formatMoney(membership.totalTTC) : ""
          },
          {
            key: "membershipPayments.receivedAt",
            label: "Dernier paiement",
            display: membership?.lastMembershipPayments?.receivedAt
              ? formatDisplayDate(membership.lastMembershipPayments.receivedAt)
              : "",
            sort: membership?.lastMembershipPayments?.receivedAt,
            search: membership?.lastMembershipPayments?.receivedAt
              ? formatDisplayDate(membership.lastMembershipPayments.receivedAt)
              : ""
          },
          {
            key: "hasSocialPart",
            label: "Première adhésion",
            display: membership?.hasSocialPart ? "Oui" : "Non",
            sort: membership?.hasSocialPart
          }
        ]
      })) || [],
    [isAdmin, data?.pages, openFile, openModal, closeModal, sendMembershipInvoiceToCustomerMutation]
  );

  return (
    <>
      <TableLayout
        data={tableData}
        isLoading={isFetching || isFetchingNextPage}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        AdvancedSearchForm={CooperatorsMembershipBillsSearchForm}
        advancedSearchParams={advancedSearchParams}
        handleAdvancedSearch={(data) => setAdvancedSearchParams(data)}
      >
        <div className="text-end text-sm font-medium">
          Total montants TTC :{" "}
          <span className="text-sm text-primary-600">
            {formatMoney(
              data?.pages.flat().reduce((acc, curr) => acc + Number(curr?.totalTTC ?? 0), 0) ?? 0
            )}
          </span>
        </div>
      </TableLayout>

      {renderModal()}
    </>
  );
};
