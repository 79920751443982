import type { PayQuotationByCreditCardResponse } from "~/types/sign-payment.types";

import axios from "axios";

export const initiateCreditCardPayment = async (payboxData: PayQuotationByCreditCardResponse) => {
  const { url: payboxUrl, parameters: payboxParams } = payboxData;

  const htmlPaybox = await axios.post(payboxUrl, payboxParams, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    }
  });

  // Create and submit the form
  const payboxForm = document.createElement("html");
  payboxForm.innerHTML = htmlPaybox.data;
  document.body.appendChild(payboxForm);
  const form = payboxForm.querySelector("form");
  if (form) {
    form.submit();
  } else {
    throw new Error("Payment form not found in response");
  }
};
