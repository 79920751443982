import type { UseFormRegister } from "react-hook-form";

import { FormInput } from "~/components/ui/FormInput";
import { FormSelect } from "~/components/ui/FormSelect";

export const AttestationsSearchForm = ({ register }: { register: UseFormRegister<any> }) => {
  return (
    <>
      <FormInput label="Nom de société" type="text" optional {...register("customerCompanyName")} />
      <FormInput label="Nom" type="text" optional {...register("customerLastname")} />
      <FormInput label="Prénom" type="text" optional {...register("customerFirstname")} />
      <FormInput label="Email" type="text" optional {...register("customerEmail")} />
      <FormInput label="Adresse" type="text" optional {...register("customerAddress")} />
      <FormInput label="Code postal" type="text" optional {...register("customerZipcode")} />
      <FormInput label="Ville" type="text" optional {...register("customerCity")} />
      <FormSelect label="Type" optional {...register("isCustomerPro")}>
        <option value="">Tous</option>
        <option value="true">Professionnel</option>
        <option value="false">Particulier</option>
      </FormSelect>
      <FormInput
        label="Société du coopérateur"
        type="text"
        optional
        {...register("cooperatorCompanyName")}
      />
      <FormInput
        label="Nom du coopérateur"
        type="text"
        optional
        {...register("cooperatorLastname")}
      />
    </>
  );
};
