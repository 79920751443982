import type { HTMLReactParserOptions } from "html-react-parser";
import type { HTMLAttributes } from "react";

import parse from "html-react-parser";

const htmlParserOptions: HTMLReactParserOptions = {
  trim: true,
  replace: (domNode) => {
    // Remove all script tags
    if (domNode.type === "script") {
      return null;
    }
    // Replace all [separator] with a line
    if (domNode.type === "text" && domNode.data.includes("[separator]")) {
      return <div className="mx-auto my-6 h-px w-32 bg-primary" />;
    }
  }
};

export const HtmlParser = ({
  html,
  className,
  ...props
}: { html: string; className?: string } & HTMLAttributes<HTMLDivElement>) => {
  return (
    <div className={className} {...props}>
      {parse(html, htmlParserOptions)}
    </div>
  );
};
