import type { AdvancedSearchParams } from "~/components/TableLayout";
import type { MainTableProps } from "~/components/ui/MainTable";

import { useState } from "react";
import { useInfiniteQuery, useMutation } from "@tanstack/react-query";
import { documentAttachOutline, mailOutline, walletOutline } from "ionicons/icons";

import { quotationStates, quotationTypes } from "~/config/api-constants";
import { queryKeys } from "~/config/query-keys-constants";
import { formatDisplayDate, formatMoney } from "~/utils/misc";
import { useUIContext } from "~/contexts/uiContext";
import { useFileDeliver } from "~/hooks/useFileDeliver";
import { useModal } from "~/hooks/useModal";
import { handleApiError } from "~/services/errors";
import { getNextPageParamFn } from "~/api/_config";
import { getQuotations, sendQuotationInvoiceToCustomer } from "~/api/quotation";
import { PreFinancingPaidContractsSearchForm } from "~/components/AdvancedSearchForms/PreFinancing/PreFinancingPaidContractsSearchForm";
import { ConfirmationModal } from "~/components/Modals/ConfirmationModal";
import { QuotationPaymentsModal } from "~/components/Modals/QuotationPaymentsModal";
import { TableLayout } from "~/components/TableLayout";
import { ExternalLink, Link } from "~/components/ui/Link";

export const PreFinancingPaidContracts = () => {
  const { openFile } = useFileDeliver();
  const { toast } = useUIContext();
  const { openModal, closeModal, renderModal } = useModal();

  const [advancedSearchParams, setAdvancedSearchParams] = useState<AdvancedSearchParams>(null);

  const queryParams = {
    typeQuotation: quotationTypes.TYPE_QUOTATION_CONTRACT,
    states: [quotationStates.STATE_BILL],
    ...advancedSearchParams
  };

  const { data, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: [queryKeys.quotations, queryParams],
    queryFn: ({ pageParam }) => getQuotations({ ...queryParams, page: pageParam }),
    getNextPageParam: getNextPageParamFn
  });

  const sendQuotationInvoiceToCustomerMutation = useMutation(sendQuotationInvoiceToCustomer, {
    onSuccess: () => {
      toast("La facture a été envoyé avec succès.", "success");
    },
    onError(error) {
      handleApiError(error, toast);
    }
  });

  const tableData: MainTableProps["data"] =
    data?.pages.flat().map((quotation) => ({
      itemId: quotation?.id,
      actions: [
        {
          label: "Consulter la facture",
          icon: documentAttachOutline,
          color: "sky",
          action: () =>
            openFile({ itemType: "quotation-billed", itemParams: { id: quotation?.id } })
        },
        {
          label: "Renvoyer la facture au client par email",
          icon: mailOutline,
          color: "amber",
          action: () =>
            openModal(
              <ConfirmationModal
                title="Renvoyer la facture au client par email"
                isOpen={true}
                onClose={closeModal}
                onConfirm={() =>
                  quotation?.id
                    ? sendQuotationInvoiceToCustomerMutation.mutate({ id: quotation.id })
                    : null
                }
              >
                Êtes vous sûr de vouloir renvoyer la facture au client par email ?
              </ConfirmationModal>
            )
        },
        {
          label: "Voir les réglements",
          icon: walletOutline,
          color: "teal",
          action: () =>
            quotation?.id
              ? openModal(
                  <QuotationPaymentsModal
                    quotation={quotation}
                    isOpen={true}
                    onClose={closeModal}
                  />
                )
              : null
        }
      ],
      columns: [
        {
          key: "invoicedCustomerAt",
          label: "Créé le",
          display: quotation?.invoicedCustomerAt
            ? formatDisplayDate(quotation.invoicedCustomerAt)
            : "",
          sort: quotation?.invoicedCustomerAt,
          search: quotation?.invoicedCustomerAt
            ? formatDisplayDate(quotation.invoicedCustomerAt)
            : ""
        },
        {
          key: "cooperator.lastname",
          label: "Financeur",
          display: (
            <div className="flex flex-col gap-1">
              {quotation?.quotationCooperatorInfo?.[0]?.companyName ? (
                <span className="font-medium">
                  {quotation.quotationCooperatorInfo[0].companyName}
                </span>
              ) : null}
              {quotation?.quotationCooperatorInfo?.[0]?.lastname ||
              quotation?.quotationCooperatorInfo?.[0]?.firstname ? (
                <span>
                  {quotation.quotationCooperatorInfo[0].lastname}{" "}
                  {quotation.quotationCooperatorInfo[0].firstname}
                </span>
              ) : null}
              {quotation?.quotationCooperatorInfo?.[0]?.email ? (
                <ExternalLink
                  to={`mailto:${quotation.quotationCooperatorInfo[0].email}`}
                  className="text-xs"
                >
                  {quotation.quotationCooperatorInfo[0].email}
                </ExternalLink>
              ) : null}
              {quotation?.quotationCooperatorInfo?.[0]?.mobileNumber ? (
                <ExternalLink
                  to={`tel:${quotation.quotationCooperatorInfo[0].mobileNumber}`}
                  className="text-xs"
                >
                  {quotation.quotationCooperatorInfo[0].mobileNumber}
                </ExternalLink>
              ) : null}
              {quotation?.quotationCooperatorInfo?.[0]?.fixeNumber ? (
                <ExternalLink
                  to={`tel:${quotation.quotationCooperatorInfo[0].fixeNumber}`}
                  className="text-xs"
                >
                  {quotation.quotationCooperatorInfo[0].fixeNumber}
                </ExternalLink>
              ) : null}
            </div>
          ),
          sort: quotation?.quotationCooperatorInfo?.[0]?.lastname,
          search: `${quotation?.quotationCooperatorInfo?.[0]?.companyName} ${quotation?.quotationCooperatorInfo?.[0]?.lastname} ${quotation?.quotationCooperatorInfo?.[0]?.firstname} ${quotation?.quotationCooperatorInfo?.[0]?.email} ${quotation?.quotationCooperatorInfo?.[0]?.mobileNumber} ${quotation?.quotationCooperatorInfo?.[0]?.fixeNumber}`
        },
        {
          key: "customer.lastname",
          label: "Bénéficiaire",
          display: (
            <div className="flex flex-col gap-1">
              {quotation?.quotationCustomerInfo?.[0]?.companyName ? (
                <span className="font-medium">
                  {quotation.quotationCustomerInfo[0]?.companyName}
                </span>
              ) : null}
              {quotation?.quotationCustomerInfo?.[0]?.lastname ||
              quotation?.quotationCustomerInfo?.[0]?.firstname ? (
                <span>
                  {quotation.quotationCustomerInfo[0]?.lastname}{" "}
                  {quotation.quotationCustomerInfo[0]?.firstname}
                </span>
              ) : null}
              {quotation?.quotationCustomerInfo?.[0]?.email ? (
                <ExternalLink
                  to={`mailto:${quotation.quotationCustomerInfo[0]?.email}`}
                  className="text-xs"
                >
                  {quotation.quotationCustomerInfo[0]?.email}
                </ExternalLink>
              ) : null}
              {quotation?.quotationCustomerInfo?.[0]?.mobileNumber ? (
                <ExternalLink
                  to={`tel:${quotation.quotationCustomerInfo[0]?.mobileNumber}`}
                  className="text-xs"
                >
                  {quotation.quotationCustomerInfo[0]?.mobileNumber}
                </ExternalLink>
              ) : null}
              {quotation?.quotationCustomerInfo?.[0]?.fixeNumber ? (
                <ExternalLink
                  to={`tel:${quotation.quotationCustomerInfo[0]?.fixeNumber}`}
                  className="text-xs"
                >
                  {quotation.quotationCustomerInfo[0]?.fixeNumber}
                </ExternalLink>
              ) : null}
            </div>
          ),
          sort:
            quotation?.quotationCustomerInfo?.[0]?.companyName ||
            quotation?.quotationCustomerInfo?.[0]?.lastname,
          search: `${quotation?.quotationCustomerInfo?.[0]?.companyName} ${quotation?.quotationCustomerInfo?.[0]?.lastname} ${quotation?.quotationCustomerInfo?.[0]?.firstname} ${quotation?.quotationCustomerInfo?.[0]?.email} ${quotation?.quotationCustomerInfo?.[0]?.mobileNumber} ${quotation?.quotationCustomerInfo?.[0]?.fixeNumber}`
        },
        {
          key: "idFactureNormalized",
          label: "N° facture",
          display: (
            <Link
              to={`/file/deliver/contract-billed/${quotation?.id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {quotation?.idFactureNormalized}
            </Link>
          )
        },
        {
          key: "idNormalized",
          label: "N° contrat",
          display: (
            <Link
              to={`/file/deliver/contract-unbilled/${quotation?.id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {quotation?.idNormalized}
            </Link>
          )
        },
        {
          key: "totalTTC",
          label: "Montant TTC",
          display: quotation?.totalTTC ? formatMoney(quotation.totalTTC) : "",
          sort: quotation?.totalTTC ? parseFloat(quotation.totalTTC) : "",
          search: quotation?.totalTTC ? formatMoney(quotation.totalTTC) : ""
        },
        {
          key: "receivedAt",
          label: "Payé le",
          display: quotation?.lastQuotationPayments?.receivedAt
            ? formatDisplayDate(quotation.lastQuotationPayments.receivedAt)
            : "",
          sort: quotation?.lastQuotationPayments?.receivedAt,
          search: quotation?.lastQuotationPayments?.receivedAt
            ? formatDisplayDate(quotation.lastQuotationPayments.receivedAt)
            : ""
        }
      ]
    })) || [];

  return (
    <>
      <TableLayout
        data={tableData}
        isLoading={isFetching || isFetchingNextPage}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        AdvancedSearchForm={PreFinancingPaidContractsSearchForm}
        advancedSearchParams={advancedSearchParams}
        handleAdvancedSearch={(data) => setAdvancedSearchParams(data)}
      >
        <div className="text-end text-sm font-medium">
          Total montants TTC :{" "}
          <span className="text-sm text-primary-600">
            {formatMoney(
              data?.pages.flat().reduce((acc, curr) => acc + Number(curr?.totalTTC ?? 0), 0) ?? 0
            )}
          </span>
        </div>
      </TableLayout>

      {renderModal()}
    </>
  );
};
