import type { SmsSchema } from "~/schemas/sms.schema";
import type { Sms } from "~/types/sms.types";
import type { DeepPartial } from "~/types/utils.types";

import { INFINITE_SCROLL_LIMIT } from "~/config/api";
import { api } from "~/api/_config";

export const getAllSms = async ({
  page,
  itemsPerPage
}: {
  page?: number;
  itemsPerPage?: number;
}) => {
  const response = await api.get<DeepPartial<Sms[]>>("/api/admin/sms", {
    params: { page, itemsPerPage: itemsPerPage ?? INFINITE_SCROLL_LIMIT }
  });
  return response.data;
};

export const getSms = async ({ id }: { id: number }) => {
  const response = await api.get<Sms>(`/api/admin/sms/${id}`);
  return response.data;
};

export const createSms = async ({ sms }: { sms: SmsSchema }) => {
  const response = await api.post<Sms>("/api/admin/sms", sms);
  return response.data;
};

export const updateSms = async ({ id, sms }: { id: number; sms: SmsSchema }) => {
  const response = await api.put<Sms>(`/api/admin/sms/${id}`, sms);
  return response.data;
};
