import type { AdvancedSearchParams } from "~/components/TableLayout";
import type { MainTableProps } from "~/components/ui/MainTable";

import { useState } from "react";
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import { addOutline, cardOutline, createOutline, mailOutline, trashOutline } from "ionicons/icons";

import { userRoles } from "~/config/api-constants";
import { queryKeys } from "~/config/query-keys-constants";
import { formatDisplayDate, formatMoney } from "~/utils/misc";
import { useUIContext } from "~/contexts/uiContext";
import { useModal } from "~/hooks/useModal";
import { handleApiError } from "~/services/errors";
import { getNextPageParamFn } from "~/api/_config";
import { queryClient } from "~/api/_queryClient";
import { getTotalRemainingCredit } from "~/api/credit-service";
import {
  deleteBeneficiary,
  deleteMultipleUsers,
  getUsers,
  resendBeneficiaryCode,
  sendLowBalanceEmailToMultipleBeneficiaries,
  sendNoBalanceEmailToMultipleBeneficiaries
} from "~/api/user";
import { PreFinancingBeneficiariesSearchForm } from "~/components/AdvancedSearchForms/PreFinancing/PreFinancingBeneficiariesSearchForm";
import { AddBeneficiaryCreditModal } from "~/components/Modals/AddBeneficiaryCreditModal";
import { BeneficiaryTransactionsModal } from "~/components/Modals/BeneficiaryTransactionsModal";
import { ConfirmationModal } from "~/components/Modals/ConfirmationModal";
import { TableLayout } from "~/components/TableLayout";
import { ExternalLink } from "~/components/ui/Link";

export const PreFinancingBeneficiaries = () => {
  const { toast } = useUIContext();
  const { openModal, closeModal, renderModal } = useModal();

  const [advancedSearchParams, setAdvancedSearchParams] = useState<AdvancedSearchParams>(null);

  const queryParams = {
    role: userRoles.ROLE_BENEFICIARY,
    ...advancedSearchParams
  };

  const { data, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: [queryKeys.users, queryParams],
    queryFn: ({ pageParam, signal }) => getUsers({ ...queryParams, page: pageParam, signal }),
    getNextPageParam: getNextPageParamFn
  });

  const { data: remainingCredit, isLoading: isRemainingCreditLoading } = useQuery({
    queryKey: [queryKeys.remainingCredit],
    queryFn: getTotalRemainingCredit
  });

  const resendBeneficiaryCodeMutation = useMutation(resendBeneficiaryCode, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.users]);
      toast("Le code a été renvoyé avec succès.", "success");
    },
    onError(error) {
      handleApiError(error, toast);
    }
  });

  const deleteBeneficiaryMutation = useMutation(deleteBeneficiary, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.users]);
      toast("Le bénéficiaire a été supprimé avec succès.", "success");
    },
    onError(error) {
      handleApiError(error, toast);
    }
  });

  const disableMultipleBeneficiariesMutation = useMutation(deleteMultipleUsers, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.users]);
      toast("Les bénéficiaires ont été désactivés avec succès.", "success");
    },
    onError(error) {
      handleApiError(error, toast);
    }
  });

  const sendMultipleLowBalanceEmailsMutation = useMutation(
    sendLowBalanceEmailToMultipleBeneficiaries,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.users]);
        toast("Les mails de solde bas ont été envoyés avec succès.", "success");
      },
      onError(error) {
        handleApiError(error, toast);
      }
    }
  );

  const sendMultipleNoBalanceEmailsMutation = useMutation(
    sendNoBalanceEmailToMultipleBeneficiaries,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.users]);
        toast("Les mails de solde bas ont été envoyés avec succès.", "success");
      },
      onError(error) {
        handleApiError(error, toast);
      }
    }
  );

  const groupedActions = [
    {
      id: "disable",
      label: "Désactiver les bénéficiaires sélectionnés",
      action: (ids: number[]) =>
        openModal(
          <ConfirmationModal
            title="Désactiver les bénéficiaires sélectionnés"
            danger
            isOpen={true}
            onClose={closeModal}
            onConfirm={() => disableMultipleBeneficiariesMutation.mutate(ids)}
          >
            Êtes vous sûr de vouloir désactiver les bénéficiaires sélectionnés ?
          </ConfirmationModal>
        )
    },
    {
      id: "send-low-balance-email",
      label: "Envoyer un mail de solde bas",
      action: (ids: number[]) =>
        openModal(
          <ConfirmationModal
            title="Envoyer un mail de solde bas"
            isOpen={true}
            onClose={closeModal}
            onConfirm={() => sendMultipleLowBalanceEmailsMutation.mutate(ids)}
          >
            Êtes vous sûr de vouloir envoyer un mail de solde bas aux bénéficiaires sélectionnés ?
          </ConfirmationModal>
        )
    },
    {
      id: "send-no-balance-email",
      label: "Envoyer un mail de solde épuisé",
      action: (ids: number[]) =>
        openModal(
          <ConfirmationModal
            title="Envoyer un mail de solde épuisé"
            isOpen={true}
            onClose={closeModal}
            onConfirm={() => sendMultipleNoBalanceEmailsMutation.mutate(ids)}
          >
            Êtes vous sûr de vouloir envoyer un mail de solde épuisé aux bénéficiaires sélectionnés
            ?
          </ConfirmationModal>
        )
    }
  ];

  const tableData: MainTableProps["data"] =
    data?.pages.flat().map((user) => ({
      itemId: user?.id,
      checkboxConditions: [
        {
          id: "disable",
          condition: true
        },
        {
          id: "send-low-balance-email",
          condition: true
        },
        {
          id: "send-no-balance-email",
          condition: true
        }
      ],
      actions: [
        {
          label: "Voir les transactions",
          icon: cardOutline,
          color: "teal",
          action: () =>
            user?.id
              ? openModal(
                  <BeneficiaryTransactionsModal
                    beneficiaryId={user.id}
                    isOpen={true}
                    onClose={closeModal}
                  />
                )
              : null
        },
        {
          label: "Renvoyer le code",
          icon: mailOutline,
          color: "amber",
          action: () =>
            openModal(
              <ConfirmationModal
                title="Renvoyer le code"
                isOpen={true}
                onClose={closeModal}
                onConfirm={() => (user?.id ? resendBeneficiaryCodeMutation.mutate(user.id) : null)}
              >
                Êtes vous sûr de vouloir renvoyer le code au bénéficiaire ?
              </ConfirmationModal>
            )
        },
        {
          label: "Ajouter du crédit",
          icon: addOutline,
          color: "violet",
          condition: !!user?.remainingCredit && user.remainingCredit > 0,
          action: () =>
            user?.id
              ? openModal(
                  <AddBeneficiaryCreditModal
                    beneficiaryId={user.id}
                    isOpen={true}
                    onClose={closeModal}
                  />
                )
              : null
        },
        {
          label: "Éditer le bénéficiaire",
          icon: createOutline,
          color: "blue",
          href: `/prefinancing-contracts/beneficiaries/edit/${user?.id}`
        },
        {
          label: "Supprimer le bénéficiaire",
          icon: trashOutline,
          color: "red",
          action: () =>
            openModal(
              <ConfirmationModal
                title="Supprimer le bénéficiaire"
                danger
                isOpen={true}
                onClose={closeModal}
                onConfirm={() => (user?.id ? deleteBeneficiaryMutation.mutate(user.id) : null)}
              >
                Êtes vous sûr de vouloir supprimer ce bénéficiaire ?
              </ConfirmationModal>
            )
        }
      ],
      columns: [
        {
          key: "financer",
          label: "Financeur",
          display: (
            <div className="flex flex-col gap-1">
              {user?.financer?.companyName ? (
                <span className="font-medium">{user.financer.companyName}</span>
              ) : null}
              {user?.financer?.lastname || user?.financer?.firstname ? (
                <span>
                  {user.financer.lastname} {user.financer.firstname}
                </span>
              ) : null}
            </div>
          )
        },
        {
          key: "lastname",
          label: "Nom/Prénom",
          display: `${user?.lastname} ${user?.firstname}`,
          sort: user?.lastname,
          search: `${user?.lastname} ${user?.firstname}`
        },
        {
          key: "email",
          label: "Email",
          display: <ExternalLink to={`mailto:${user?.email}`}>{user?.email}</ExternalLink>
        },
        {
          key: "codeContratService",
          label: "Code du bénéficiaire",
          display: user?.codeContratService
        },
        {
          key: "remainingCredit",
          label: "Crédit restant",
          display: user?.remainingCredit != null ? formatMoney(user.remainingCredit) : "",
          sort: user?.remainingCredit
        },
        {
          key: "invoicedCustomerAt",
          label: "Date dernier mouvement",
          display: user?.creditServices?.[0]?.creditServiceTransactions?.[0]?.createdAt
            ? formatDisplayDate(user.creditServices[0]?.creditServiceTransactions?.[0]?.createdAt)
            : "",
          sort: user?.creditServices?.[0]?.creditServiceTransactions?.[0]?.createdAt
        },
        { key: "deleted", label: "Activé", display: user?.deleted ? "Non" : "Oui" }
      ]
    })) || [];

  return (
    <>
      <TableLayout
        data={tableData}
        isLoading={isFetching || isFetchingNextPage}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        AdvancedSearchForm={PreFinancingBeneficiariesSearchForm}
        groupedActions={groupedActions}
        advancedSearchParams={advancedSearchParams}
        handleAdvancedSearch={setAdvancedSearchParams}
        handleSimpleSearch={setAdvancedSearchParams}
      >
        <div className="text-end text-sm font-medium">
          Total crédit restant :{" "}
          {isRemainingCreditLoading || !remainingCredit ? "..." : formatMoney(remainingCredit)}
        </div>
      </TableLayout>

      {renderModal()}
    </>
  );
};
