import { IonContent, IonPage } from "@ionic/react";
import { Redirect, Switch } from "react-router";

import { useAuthContext } from "~/contexts/authContext";
import { CreateEditBeneficiary } from "~/pages/PreFinancing/CreateEditBeneficiary";
import { CreateEditFinancer } from "~/pages/PreFinancing/CreateEditFinancer";
import { CreateEditPreFinancingContract } from "~/pages/PreFinancing/CreateEditPreFinancingContract";
import { PreFinancingBeneficiaries } from "~/pages/PreFinancing/PreFinancingBeneficiaries";
import { PreFinancingFinancers } from "~/pages/PreFinancing/PreFinancingFinancers";
import { PreFinancingPaidContracts } from "~/pages/PreFinancing/PreFinancingPaidContracts";
import { PreFinancingWaitingContracts } from "~/pages/PreFinancing/PreFinancingWaitingContracts";
import { SubNavigation } from "~/components/SubNavigation";
import { ProtectedRoute } from "~/Router";

export const PreFinancingLayout = () => {
  const { isAdmin } = useAuthContext();

  return (
    <IonPage>
      <IonContent className="container">
        <SubNavigation
          links={[
            {
              label: "Liste des contrats en attente",
              to: "/prefinancing-contracts/waiting"
            },
            {
              label: "Liste des contrats payés",
              to: "/prefinancing-contracts/paid"
            },
            {
              label: "Financeurs",
              to: "/prefinancing-contracts/financers"
            },
            {
              label: "Bénéficiaires",
              to: "/prefinancing-contracts/beneficiaries"
            }
          ]}
        />
        <Switch>
          <ProtectedRoute exact path="/prefinancing-contracts/waiting" condition={isAdmin}>
            <PreFinancingWaitingContracts />
          </ProtectedRoute>
          <ProtectedRoute exact path="/prefinancing-contracts/paid" condition={isAdmin}>
            <PreFinancingPaidContracts />
          </ProtectedRoute>
          <ProtectedRoute exact path="/prefinancing-contracts/financers" condition={isAdmin}>
            <PreFinancingFinancers />
          </ProtectedRoute>
          <ProtectedRoute exact path="/prefinancing-contracts/beneficiaries" condition={isAdmin}>
            <PreFinancingBeneficiaries />
          </ProtectedRoute>

          <ProtectedRoute exact path="/prefinancing-contracts/create" condition={isAdmin}>
            <CreateEditPreFinancingContract />
          </ProtectedRoute>
          <ProtectedRoute
            exact
            path="/prefinancing-contracts/edit/:quotation_id"
            condition={isAdmin}
          >
            <CreateEditPreFinancingContract editMode />
          </ProtectedRoute>

          <ProtectedRoute exact path="/prefinancing-contracts/financers/create" condition={isAdmin}>
            <CreateEditFinancer />
          </ProtectedRoute>
          <ProtectedRoute
            exact
            path="/prefinancing-contracts/financers/edit/:user_id"
            condition={isAdmin}
          >
            <CreateEditFinancer editMode />
          </ProtectedRoute>

          <ProtectedRoute
            exact
            path="/prefinancing-contracts/beneficiaries/create/:financer_id"
            condition={isAdmin}
          >
            <CreateEditBeneficiary />
          </ProtectedRoute>
          <ProtectedRoute
            exact
            path="/prefinancing-contracts/beneficiaries/edit/:user_id"
            condition={isAdmin}
          >
            <CreateEditBeneficiary editMode />
          </ProtectedRoute>

          <Redirect to={"/prefinancing-contracts/waiting"} />
        </Switch>
      </IonContent>
    </IonPage>
  );
};
