import type { AdvancedSearchParams } from "~/components/TableLayout";
import type { MainTableProps } from "~/components/ui/MainTable";

import { useState } from "react";
import { IonIcon } from "@ionic/react";
import { useInfiniteQuery, useMutation } from "@tanstack/react-query";
import {
  add as addIcon,
  arrowForwardOutline,
  checkmarkCircleOutline,
  createOutline,
  documentAttachOutline,
  listOutline,
  refreshOutline,
  trashOutline
} from "ionicons/icons";

import {
  quotationCoopPaymentStates,
  quotationStates,
  quotationTypes
} from "~/config/api-constants";
import { queryKeys } from "~/config/query-keys-constants";
import { formatDisplayDate, formatDisplayHourFromDate, formatMoney } from "~/utils/misc";
import { useAuthContext } from "~/contexts/authContext";
import { useUIContext } from "~/contexts/uiContext";
import { useFileDeliver } from "~/hooks/useFileDeliver";
import { useModal } from "~/hooks/useModal";
import { handleApiError } from "~/services/errors";
import { getNextPageParamFn } from "~/api/_config";
import { queryClient } from "~/api/_queryClient";
import {
  deleteQuotation,
  getQuotations,
  passQuotationToBilling,
  renewQuotation,
  sendQuotationSignatureToCustomer
} from "~/api/quotation";
import { PreFinancingWaitingContractsSearchForm } from "~/components/AdvancedSearchForms/PreFinancing/PreFinancingWaitingContractsSearchForm";
import { ConfirmationModal } from "~/components/Modals/ConfirmationModal";
import { QuotationPaymentsModal } from "~/components/Modals/QuotationPaymentsModal";
import { RecoveryQuotationsActionsModal } from "~/components/Modals/RecoveryQuotationActionsModal";
import { TableLayout } from "~/components/TableLayout";
import { Button } from "~/components/ui/Button/Button";
import { ExternalLink, Link } from "~/components/ui/Link";
import euroIcon from "~/assets/icons/euro.svg";

export const PreFinancingWaitingContracts = () => {
  const { isAdmin } = useAuthContext();
  const { openFile } = useFileDeliver();
  const { toast } = useUIContext();
  const { openModal, closeModal, renderModal } = useModal();

  const [advancedSearchParams, setAdvancedSearchParams] = useState<AdvancedSearchParams>(null);

  const queryParams = {
    typeQuotation: quotationTypes.TYPE_QUOTATION_CONTRACT,
    states: [
      quotationStates.STATE_DRAFT,
      quotationStates.STATE_SEND,
      quotationStates.STATE_SIGNED,
      quotationStates.STATE_PENDING,
      quotationStates.STATE_PENDING_CHOOSE,
      quotationStates.STATE_PARTIAL_PAID,
      quotationStates.STATE_PAID,
      quotationStates.STATE_INSTANT_ADVANCE_FRAUD
    ],
    ...advancedSearchParams
  };

  const { data, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: [queryKeys.quotations, queryParams, advancedSearchParams],
    queryFn: ({ pageParam }) => getQuotations({ ...queryParams, page: pageParam }),
    getNextPageParam: getNextPageParamFn
  });

  const passQuotationToBillingMutation = useMutation(passQuotationToBilling, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.quotations]);
      toast("Le contrat a été passé en facturation avec succès.", "success");
    },
    onError(error) {
      handleApiError(error, toast);
    }
  });

  const renewQuotationMutation = useMutation(renewQuotation, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.quotations]);
      toast("Le contrat a été réinitialisé avec succès.", "success");
    },
    onError(error) {
      handleApiError(error, toast);
    }
  });

  const deleteQuotationMutation = useMutation(deleteQuotation, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.quotations]);
      toast("Le contrat a été supprimé avec succès.", "success");
    },
    onError(error) {
      handleApiError(error, toast);
    }
  });

  const sendQuotationSignatureToCustomerMutation = useMutation(sendQuotationSignatureToCustomer, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.recoveryQuotations]);
      toast("Le relevé a été envoyé avec succès.", "success");
    },
    onError(error) {
      handleApiError(error, toast);
    }
  });

  const globalActions = [
    {
      label: "Nouveau contrat",
      icon: addIcon,
      href: "/prefinancing-contracts/create"
    }
  ];

  const tableData: MainTableProps["data"] =
    data?.pages.flat().map((quotation) => ({
      itemId: quotation?.id,
      actions: [
        {
          label: "Consulter le document",
          icon: documentAttachOutline,
          color: "slate",
          condition: quotation?.state !== quotationStates.STATE_DRAFT,
          action: () =>
            openFile({ itemType: "contract-unbilled", itemParams: { id: quotation?.id } })
        },
        {
          label: "Voir les actions",
          icon: listOutline,
          color: "indigo",
          condition: quotation?.isRecoverable,
          action: () =>
            quotation?.id
              ? openModal(
                  <RecoveryQuotationsActionsModal
                    quotationId={quotation.id}
                    withForm
                    isOpen
                    onClose={closeModal}
                  />
                )
              : null
        },
        {
          label: "Ajouter un réglement",
          icon: euroIcon,
          color: "teal",
          condition: quotation?.state !== quotationStates.STATE_DRAFT,
          action: () =>
            quotation?.id
              ? openModal(
                  <QuotationPaymentsModal
                    quotation={quotation}
                    withForm
                    isOpen={true}
                    onClose={closeModal}
                  />
                )
              : null
        },
        {
          label: "Passer en facturation",
          icon: arrowForwardOutline,
          color: "orange-accent",
          condition: quotation?.state === quotationStates.STATE_PAID,
          action: () =>
            openModal(
              <ConfirmationModal
                title="Passer le contrat en facturation"
                isOpen={true}
                onClose={closeModal}
                onConfirm={() =>
                  quotation?.id ? passQuotationToBillingMutation.mutate({ id: quotation.id }) : null
                }
              >
                Êtes vous sûr de vouloir passer ce contrat en facture ? L&apos;action déclenchera
                l&apos;envoi de la facture au client.
              </ConfirmationModal>
            )
        },
        {
          label: "Éditer le contrat",
          icon: createOutline,
          color: "blue",
          condition:
            (isAdmin ||
              quotation?.state === quotationStates.STATE_DRAFT ||
              quotation?.state === quotationStates.STATE_SEND) &&
            quotation?.statePaymentCooperator !==
              quotationCoopPaymentStates.STATE_PAYMENT_COOP_PAID,
          href: `/prefinancing-contracts/edit/${quotation?.id}`
        },
        {
          label: "Supprimer le contrat",
          icon: trashOutline,
          color: "red",
          condition:
            (isAdmin ||
              quotation?.state === quotationStates.STATE_DRAFT ||
              quotation?.state === quotationStates.STATE_SEND) &&
            quotation?.statePaymentCooperator !==
              quotationCoopPaymentStates.STATE_PAYMENT_COOP_PAID,
          action: () =>
            openModal(
              <ConfirmationModal
                title="Supprimer un contrat"
                isOpen={true}
                onClose={closeModal}
                danger
                onConfirm={() =>
                  quotation?.id ? deleteQuotationMutation.mutate({ id: quotation.id }) : null
                }
              >
                Êtes vous sûr de vouloir supprimer le contrat de préfinancement ? Cette action est
                irréversible.
              </ConfirmationModal>
            )
        },
        {
          label: "Réinitialiser",
          icon: refreshOutline,
          color: "orange",
          condition:
            isAdmin &&
            !!quotation?.signedAt &&
            !(quotation.quotationPayments?.length && quotation.quotationPayments.length > 0),
          action: () =>
            openModal(
              <ConfirmationModal
                title="Réinitialiser le contrat"
                danger
                isOpen={true}
                onClose={closeModal}
                onConfirm={() =>
                  quotation?.id ? renewQuotationMutation.mutate({ id: quotation.id }) : null
                }
              >
                Êtes vous sûr de vouloir réinitialiser le contrat ? Cela remettra le contrat en
                brouillon en annulant la signature, il faudra alors le renvoyer au client en
                l&apos;éditant.
              </ConfirmationModal>
            )
        }
      ],
      columns: [
        {
          key: "trueDateValidate",
          label: "Créé le",
          display: quotation?.trueDateValidate ? formatDisplayDate(quotation.trueDateValidate) : "",
          sort: quotation?.trueDateValidate,
          search: quotation?.trueDateValidate ? formatDisplayDate(quotation.trueDateValidate) : ""
        },
        {
          key: "cooperator.lastname",
          label: "Financeur",
          display: (
            <div className="flex flex-col gap-1">
              {quotation?.quotationCooperatorInfo?.[0]?.companyName ? (
                <span className="font-medium">
                  {quotation.quotationCooperatorInfo[0].companyName}
                </span>
              ) : null}
              {quotation?.quotationCooperatorInfo?.[0]?.lastname ||
              quotation?.quotationCooperatorInfo?.[0]?.firstname ? (
                <span>
                  {quotation.quotationCooperatorInfo[0].lastname}{" "}
                  {quotation.quotationCooperatorInfo[0].firstname}
                </span>
              ) : null}
              {quotation?.quotationCooperatorInfo?.[0]?.email ? (
                <ExternalLink
                  to={`mailto:${quotation.quotationCooperatorInfo[0].email}`}
                  className="text-xs"
                >
                  {quotation.quotationCooperatorInfo[0].email}
                </ExternalLink>
              ) : null}
              {quotation?.quotationCooperatorInfo?.[0]?.mobileNumber ? (
                <ExternalLink
                  to={`tel:${quotation.quotationCooperatorInfo[0].mobileNumber}`}
                  className="text-xs"
                >
                  {quotation.quotationCooperatorInfo[0].mobileNumber}
                </ExternalLink>
              ) : null}
              {quotation?.quotationCooperatorInfo?.[0]?.fixeNumber ? (
                <ExternalLink
                  to={`tel:${quotation.quotationCooperatorInfo[0].fixeNumber}`}
                  className="text-xs"
                >
                  {quotation.quotationCooperatorInfo[0].fixeNumber}
                </ExternalLink>
              ) : null}
            </div>
          ),
          sort: quotation?.quotationCooperatorInfo?.[0]?.lastname,
          search: `${quotation?.quotationCooperatorInfo?.[0]?.companyName} ${quotation?.quotationCooperatorInfo?.[0]?.lastname} ${quotation?.quotationCooperatorInfo?.[0]?.firstname} ${quotation?.quotationCooperatorInfo?.[0]?.email} ${quotation?.quotationCooperatorInfo?.[0]?.mobileNumber} ${quotation?.quotationCooperatorInfo?.[0]?.fixeNumber}`
        },
        {
          key: "customer.lastname",
          label: "Bénéficiaire",
          display: (
            <div className="flex flex-col gap-1">
              {quotation?.quotationCustomerInfo?.[0]?.companyName ? (
                <span className="font-medium">
                  {quotation.quotationCustomerInfo[0]?.companyName}
                </span>
              ) : null}
              {quotation?.quotationCustomerInfo?.[0]?.lastname ||
              quotation?.quotationCustomerInfo?.[0]?.firstname ? (
                <span>
                  {quotation.quotationCustomerInfo[0]?.lastname}{" "}
                  {quotation.quotationCustomerInfo[0]?.firstname}
                </span>
              ) : null}
              {quotation?.quotationCustomerInfo?.[0]?.email ? (
                <ExternalLink
                  to={`mailto:${quotation.quotationCustomerInfo[0].email}`}
                  className="text-xs"
                >
                  {quotation.quotationCustomerInfo[0]?.email}
                </ExternalLink>
              ) : null}
              {quotation?.quotationCustomerInfo?.[0]?.mobileNumber ? (
                <ExternalLink
                  to={`tel:${quotation.quotationCustomerInfo[0].mobileNumber}`}
                  className="text-xs"
                >
                  {quotation.quotationCustomerInfo[0]?.mobileNumber}
                </ExternalLink>
              ) : null}
              {quotation?.quotationCustomerInfo?.[0]?.fixeNumber ? (
                <ExternalLink
                  to={`tel:${quotation.quotationCustomerInfo[0].fixeNumber}`}
                  className="text-xs"
                >
                  {quotation.quotationCustomerInfo[0]?.fixeNumber}
                </ExternalLink>
              ) : null}
            </div>
          ),
          sort:
            quotation?.quotationCustomerInfo?.[0]?.companyName ||
            quotation?.quotationCustomerInfo?.[0]?.lastname,
          search: `${quotation?.quotationCustomerInfo?.[0]?.companyName} ${quotation?.quotationCustomerInfo?.[0]?.lastname} ${quotation?.quotationCustomerInfo?.[0]?.firstname} ${quotation?.quotationCustomerInfo?.[0]?.email} ${quotation?.quotationCustomerInfo?.[0]?.mobileNumber} ${quotation?.quotationCustomerInfo?.[0]?.fixeNumber}`
        },
        {
          key: "idNormalized",
          label: "N° contrat",
          display: (
            <Link
              to={`/file/deliver/contract-unbilled/${quotation?.id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {quotation?.idNormalized}
            </Link>
          )
        },
        {
          key: "totalTTC",
          label: "Montant TTC",
          display: quotation?.totalTTC ? formatMoney(quotation.totalTTC) : "",
          sort: quotation?.totalTTC ? parseFloat(quotation.totalTTC) : "",
          search: quotation?.totalTTC ? formatMoney(quotation.totalTTC) : ""
        },
        {
          key: "state",
          label: "Statut",
          display:
            quotation?.state === quotationStates.STATE_DRAFT
              ? "Brouillon"
              : quotation?.state === quotationStates.STATE_SEND
                ? "En attente de signature"
                : quotation?.state === quotationStates.STATE_SIGNED
                  ? "Signé, en attente de choix du réglement."
                  : quotation?.state === quotationStates.STATE_PENDING
                    ? "En attente de réglement"
                    : quotation?.state === quotationStates.STATE_PENDING_CHOOSE
                      ? `En attente de réglement (${quotation.lastQuotationPayments?.paymentMethod?.name})`
                      : quotation?.state === quotationStates.STATE_PARTIAL_PAID
                        ? "Réglement incomplet"
                        : quotation?.state === quotationStates.STATE_PAID
                          ? "Payé"
                          : quotation?.state === quotationStates.STATE_CANCEL
                            ? "Annulé"
                            : "",
          sort: quotation?.state
        },
        {
          key: "signedAt",
          label: "Signature",
          display: (
            <div className="text-center">
              {quotation?.state !== quotationStates.STATE_DRAFT ? (
                quotation?.signedAt ? (
                  <div className="flex flex-col items-center justify-center gap-1">
                    <IonIcon color="primary" icon={checkmarkCircleOutline} className="size-4" />
                    Signé le
                    <br />
                    {formatDisplayDate(quotation.signedAt)} à{" "}
                    {formatDisplayHourFromDate(quotation.signedAt)}
                  </div>
                ) : (
                  <>
                    {quotation?.sendAt ? (
                      <div className="mb-2">
                        Envoyé le
                        <br />
                        {formatDisplayDate(quotation.sendAt)} à{" "}
                        {formatDisplayHourFromDate(quotation.sendAt)}
                      </div>
                    ) : null}
                    <Button
                      onClick={() =>
                        quotation?.id
                          ? sendQuotationSignatureToCustomerMutation.mutate({
                              id: quotation.id
                            })
                          : null
                      }
                      fill="outline"
                      className="w-full"
                    >
                      {quotation?.sendAt ? "Renvoyer" : "Envoyer"}
                    </Button>
                  </>
                )
              ) : (
                "Veuillez enregistrer le contrat de préfinancement"
              )}
            </div>
          ),
          sort: quotation?.signedAt,
          search: quotation?.signedAt ? formatDisplayDate(quotation.signedAt) : ""
        }
      ]
    })) || [];

  return (
    <>
      <TableLayout
        data={tableData}
        isLoading={isFetching || isFetchingNextPage}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        AdvancedSearchForm={PreFinancingWaitingContractsSearchForm}
        advancedSearchParams={advancedSearchParams}
        handleAdvancedSearch={(data) => setAdvancedSearchParams(data)}
        globalActions={globalActions}
      >
        <div className="text-end text-sm font-medium">
          Total montants TTC :{" "}
          <span className="text-sm text-primary-600">
            {formatMoney(
              data?.pages.flat().reduce((acc, curr) => acc + Number(curr?.totalTTC ?? 0), 0) ?? 0
            )}
          </span>
        </div>
      </TableLayout>

      {renderModal()}
    </>
  );
};
