import { zodResolver } from "@hookform/resolvers/zod";
import { IonContent, IonPage } from "@ionic/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router";

import { queryKeys } from "~/config/query-keys-constants";
import { useUIContext } from "~/contexts/uiContext";
import { handleApiError } from "~/services/errors";
import { anonymousGetQuotation, anonymousValidatePrepaidCode } from "~/api/anonymous";
import { Button } from "~/components/ui/Button/Button";
import { FormInput } from "~/components/ui/FormInput";
import { LoadingSpinner } from "~/components/ui/LoadingSpinner";
import { AnonymousValidatePrepaidCodeFormSchema } from "~/schemas/anonymous.schema";

export const AnonymousValidatePrepaidCodeForm = () => {
  const { hash: quotationHash } = useParams<{ hash: string }>();
  const history = useHistory();
  const { toast } = useUIContext();

  const {
    data: quotation,
    isFetching: isQuotationFetching,
    isError: isQuotationError
  } = useQuery({
    queryKey: [queryKeys.quotations],
    queryFn: () => anonymousGetQuotation({ hash: quotationHash })
  });

  const sendPrepaidCode = useMutation({
    mutationFn: anonymousValidatePrepaidCode,
    onSuccess: () => {
      history.push(`/_anon/pay/cartepre/success`);
    },
    onError(error) {
      handleApiError(error, toast);
    }
  });

  const form = useForm<AnonymousValidatePrepaidCodeFormSchema>({
    resolver: zodResolver(AnonymousValidatePrepaidCodeFormSchema),
    defaultValues: {
      code: ""
    }
  });

  const onSubmit = (data: AnonymousValidatePrepaidCodeFormSchema) => {
    if (!quotation?.id) return;

    sendPrepaidCode.mutate({
      id: quotation.id,
      code: data.code
    });
  };

  return (
    <IonPage>
      <IonContent>
        <div className="flex h-full w-full items-center justify-center bg-grey-100 p-4 sm:p-8">
          <div className="mx-auto max-w-6xl rounded-lg border border-grey-100 bg-white px-4 py-4 shadow-md sm:px-8">
            {isQuotationFetching ? (
              <LoadingSpinner size="lg" fullPage />
            ) : !quotation || isQuotationError ? (
              <div>
                <h1>Erreur</h1>
                <p>
                  Une erreur est survenue lors de la récupération de votre relevé de prestation.
                </p>
              </div>
            ) : (
              <>
                <h1 className="m-0 border-b border-b-grey-100 pb-4 text-center text-lg font-semibold uppercase">
                  Paiement par carte prépayée <br />
                  <span className="text-base normal-case text-grey-700">
                    Relevé de prestation n°{quotation.id}
                  </span>
                </h1>

                <div className="mt-8">
                  <p className="font-medium">
                    Saisissez votre code à 4 chiffres reçu par SMS / Email dans le champ ci-dessous:
                  </p>

                  <p className="mt-2 text-sm leading-snug text-grey-600">
                    Si vous n&apos;avez pas reçu de code, verifiez dans vos spams.
                  </p>
                  <form onSubmit={form.handleSubmit(onSubmit)} className="mx-auto mt-8 max-w-96">
                    <FormInput
                      label="Code"
                      placeholder="Saisissez le code à 4 chiffres reçu par SMS / Email"
                      errorMessage={form.formState.errors.code?.message}
                      {...form.register("code")}
                    />

                    <div className="mt-8 flex justify-between gap-8">
                      <Button routerLink={`/_anon/sign/${quotationHash}`} fill="outline">
                        Annuler
                      </Button>
                      <Button type="submit">Valider</Button>
                    </div>
                  </form>
                </div>
              </>
            )}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
