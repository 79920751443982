import type { PropsWithChildren } from "react";

import { forwardRef } from "react";
import { IonIcon } from "@ionic/react";
import { chevronDownOutline } from "ionicons/icons";

import { cn } from "~/utils/misc";
import { FormLabel } from "~/components/ui/FormLabel";

// type SelectOption = {
//   label: string;
//   value: string;
// } | null;

type FormSelectProps = PropsWithChildren<{
  label?: string;
  inputClassName?: string;
  infoMessage?: string | null | undefined;
  warningMessage?: string | null | undefined;
  errorMessage?: string | null | undefined;
  optional?: boolean;
}> &
  React.ComponentPropsWithRef<"select">;

export const FormSelect = forwardRef<HTMLSelectElement, FormSelectProps>(
  function ForwardedRefFormSelect(
    {
      label,
      infoMessage,
      warningMessage,
      errorMessage,
      optional,
      className,
      inputClassName,
      children,
      ...props
    },
    ref
  ) {
    // const [selectedOption, setSelectedOption] = useState<SelectOption>(null);

    return (
      <div className={className}>
        {label ? (
          <FormLabel htmlFor={props.id || props.name} optional={optional || false}>
            {label}
          </FormLabel>
        ) : null}

        <div className={cn("group relative", props.disabled && "pointer-events-none opacity-50")}>
          <select
            id={props.id || props.name}
            className={cn(
              "w-full cursor-pointer appearance-none rounded-lg border border-grey-200 pl-3 text-sm transition-colors placeholder:border-grey-400 focus-visible:outline-primary group-hover:border-grey-300",
              errorMessage && "border-danger-700 text-danger-900 focus-visible:outline-danger-700",
              props.multiple ? "h-40" : "h-10 pr-12",
              inputClassName
            )}
            ref={ref}
            {...props}
          >
            {children}
          </select>
          {!props.multiple ? (
            <IonIcon
              icon={chevronDownOutline}
              className="pointer-events-none absolute right-3 top-1/2 h-5 w-5 -translate-y-1/2 text-grey-400 transition-colors group-hover:text-grey-600"
            />
          ) : null}
        </div>

        {errorMessage ? (
          <p className="mx-1 mt-1 whitespace-pre-line text-sm text-danger-700">{errorMessage}</p>
        ) : null}
        {warningMessage ? (
          <p className="mx-1 mt-1 whitespace-pre-line text-xs text-danger-700">{warningMessage}</p>
        ) : null}
        {infoMessage ? (
          <p className="mx-1 mt-1 whitespace-pre-line text-xs text-grey-500">{infoMessage}</p>
        ) : null}
      </div>
    );
  }
);
