export enum StatisticsType {
  COOPERATOR_CREATED = "cooperator_created",
  CUSTOMER_CREATED = "customer_created",
  QUOTATION_CREATED = "quotation_created",
  QUOTATION_BILLED = "quotation_billed",
  QUOTATION_ROW_BILLED = "quotation_row_billed",
  COOPERATORS = "cooperators",
  TOP_CUSTOMERS = "top_customers",
  TOP_COOPERATORS = "top_cooperators",
  CATEGORIES_COOPERATOR = "categories_cooperators"
}

export type GlobalStatistics = {
  totalDraft: number;
  totalToSign: number;
  totalToPay: number;
};

export type RecoveryStatistics = {
  startDate: {
    date: string;
    timezone_type: number;
    timezone: string;
  };
  endDate: {
    date: string;
    timezone_type: number;
    timezone: string;
  };
  perfStandardTxSign: number;
  perfStandardTxPay: number;
  perfRecoveryTxSign: number;
  perfRecoveryTxPay: number;
  perfRecoveryTelTxSign: number;
  perfRecoveryTelTxPay: number;
  nbPerfStandardTxSign: number;
  nbPerfStandardTxPay: number;
  nbPerfRecoveryTxSign: number;
  nbPerfRecoveryTxPay: number;
  nbPerfRecoveryTelTxSign: number;
  nbPerfRecoveryTelTxPay: number;
  nbQuotations: number;
};

/** Generic types */

export type MonthlyData<T = number> = { [year: string]: { [month: string]: T } };
export type DailyData<T = number> = { [year: string]: { [month: string]: { [date: string]: T } } };

export type BiPeriodicStats<P> = [
  P, // Data période
  P, // Data période N-1
  number, // Cumul période
  number, // Cumul période N-1
  number, // Moyenne période
  number // Moyenne période N-1
];

export type UserStats = {
  nb: string;
  id: number;
  lastname: string;
  firstname: string;
  companyName: string;
};

/** API payload types */

export type StatisticsData = {
  startDate: { date: string };
  startDateN1: { date: string };

  endDate: { date: string };
  endDateN1: { date: string };

  cooperatorSelected: null;
};

export type CustomerCreatedStats = StatisticsData & {
  customersCreatedStatsArrays: BiPeriodicStats<MonthlyData>;
};

export type CooperatorCreatedStats = StatisticsData & {
  cooperatorsCreatedStatsArrays: BiPeriodicStats<MonthlyData>;
};

export type QuotationCreatedStats = StatisticsData & {
  quotationsCreatedStats: BiPeriodicStats<DailyData>;
};

export type QuotationBilledStats = StatisticsData & {
  quotationsBilledStats: BiPeriodicStats<DailyData>;
};

export type QuotationRowBilledStats = StatisticsData & {
  quotationsRowBilledStatsN: [
    {
      [key: string]: {
        [categId: string]: {
          nb: string;
          name: string;
          datefull?: string;
          category_id: number;
        };
      };
    },
    {
      [categId: string]: {
        name: string;
        total: number;
      };
    }
  ];
};

export type TopCustomersStats = StatisticsData & {
  topCustomers: UserStats[];
};

export type TopCooperatorsStats = StatisticsData & {
  topCooperators: UserStats[];
};

export type CategoriesCooperatorsStats = StatisticsData & {
  categoriesCooperator: {
    name: string;
    nb: number;
  }[];
};

/** Others */

export type GetStatisticsParams = {
  startDate: string;
  endDate: string;
  cooperator: number | null;
};

export type StatisticsChartProps = GetStatisticsParams;
