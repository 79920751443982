import type { PropsWithChildren } from "react";
import type { ModalProps } from "~/components/ui/Modal/Modal";

import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { useForm } from "react-hook-form";

import { useUIContext } from "~/contexts/uiContext";
import { handleApiError } from "~/services/errors";
import { createCreditService } from "~/api/credit-service";
import { Button } from "~/components/ui/Button/Button";
import { FormInput } from "~/components/ui/FormInput";
import { FormRow } from "~/components/ui/FormRow";
import { Modal } from "~/components/ui/Modal/Modal";
import { CreditServiceSchema } from "~/schemas/credit-service.schema";

interface AddBeneficiaryCreditModalProps extends ModalProps {
  beneficiaryId: number;
  withForm?: boolean;
}

export const AddBeneficiaryCreditModal = ({
  beneficiaryId,
  ...props
}: PropsWithChildren<AddBeneficiaryCreditModalProps>) => {
  const { toast } = useUIContext();

  const addCreditServiceMutation = useMutation({
    mutationFn: createCreditService,
    onSuccess: () => {
      props.onClose();
      toast("Le crédit a été ajouté avec succès.", "success");
    },
    onError(error) {
      handleApiError(error, toast);
    }
  });

  const form = useForm<CreditServiceSchema>({
    resolver: zodResolver(CreditServiceSchema),
    values: {
      amount: "",
      reason: ""
    }
  });

  const onSubmit = (data: CreditServiceSchema) => {
    addCreditServiceMutation.mutate({ beneficiaryId, data });
  };

  return (
    <Modal title="Réglements" {...props}>
      <h2 className="text-xl font-semibold">Ajouter du crédit</h2>

      <form className="mt-4" onSubmit={form.handleSubmit(onSubmit)}>
        <FormRow className="lg:grid-cols-2">
          <FormInput label="Montant à ajouter" {...form.register("amount")} />
        </FormRow>
        <FormRow className="lg:grid-cols-2">
          <FormInput
            label="Motif de l'ajout de crédit en quelques mots"
            className="col-span-2"
            {...form.register("reason")}
          />
        </FormRow>

        <div className="mt-12 flex justify-end gap-4 border-t border-grey-200 pt-6">
          <Button fill="clear" onClick={() => props.onClose()}>
            Annuler
          </Button>
          <Button type="submit" disabled={addCreditServiceMutation.isLoading}>
            Ajouter
          </Button>
        </div>
      </form>
    </Modal>
  );
};
