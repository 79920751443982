import type { AdvancedSearchParams } from "~/components/TableLayout";
import type { MainTableProps } from "~/components/ui/MainTable";

import { useMemo, useState } from "react";
import { useInfiniteQuery, useMutation } from "@tanstack/react-query";
import {
  checkmarkOutline,
  closeOutline,
  documentAttachOutline,
  lockClosed,
  lockOpen
} from "ionicons/icons";

import {
  quotationCoopPaymentStates,
  quotationStates,
  quotationTypes
} from "~/config/api-constants";
import { queryKeys } from "~/config/query-keys-constants";
import { formatDisplayDate, formatMoney } from "~/utils/misc";
import { useAuthContext } from "~/contexts/authContext";
import { useUIContext } from "~/contexts/uiContext";
import { useFileDeliver } from "~/hooks/useFileDeliver";
import { useModal } from "~/hooks/useModal";
import { handleApiError } from "~/services/errors";
import { getNextPageParamFn } from "~/api/_config";
import { queryClient } from "~/api/_queryClient";
import {
  getQuotations,
  putQuotationPaymentOnPending,
  putQuotationPaymentOnProblem,
  unblockQuotationPayment,
  unlockMultipleQuotationPayments
} from "~/api/quotation";
import { CooperatorsQuotationBillsSearchForm } from "~/components/AdvancedSearchForms/Cooperators/CooperatorsQuotationBillsSearchForm";
import { ConfirmationModal } from "~/components/Modals/ConfirmationModal";
import { ExportQuotationBillsModal } from "~/components/Modals/ExportQuotationBillsModal";
import { TableLayout } from "~/components/TableLayout";
import { Link } from "~/components/ui/Link";

export const CooperatorsQuotationBills = () => {
  const { isAdmin } = useAuthContext();
  const { toast } = useUIContext();
  const { openModal, closeModal, renderModal } = useModal();
  const { openFile } = useFileDeliver();

  const [advancedSearchParams, setAdvancedSearchParams] = useState<AdvancedSearchParams>(null);

  const queryParams = {
    typeQuotation: quotationTypes.TYPE_QUOTATION_NORMAL,
    states: [quotationStates.STATE_BILL],
    statePaymentCooperatorSort: "asc",
    ...advancedSearchParams
  };

  const { data, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: [queryKeys.quotations, queryParams],
    queryFn: ({ pageParam }) => getQuotations({ ...queryParams, page: pageParam }),
    getNextPageParam: getNextPageParamFn
  });

  const unblockQuotationPaymentMutation = useMutation(unblockQuotationPayment, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.quotationPayments]);
      queryClient.invalidateQueries([queryKeys.quotations]);

      toast("Le virement a été débloqué avec succès.", "success");
    },
    onError(error) {
      handleApiError(error, toast);
    }
  });
  const unlockMultipleQuotationPaymentsMutation = useMutation(unlockMultipleQuotationPayments, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.quotationPayments]);
      queryClient.invalidateQueries([queryKeys.quotations]);
      toast("Les virements ont été débloqués avec succès.", "success");
    },
    onError(error) {
      handleApiError(error, toast);
    }
  });

  const putQuotationPaymentOnPendingMutation = useMutation(putQuotationPaymentOnPending, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.quotationPayments]);
      queryClient.invalidateQueries([queryKeys.quotations]);
      toast("Le virement a été mis en attente avec succès.", "success");
    },
    onError(error) {
      handleApiError(error, toast);
    }
  });

  const putQuotationPaymentOnProblemMutation = useMutation(putQuotationPaymentOnProblem, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.quotationPayments]);
      queryClient.invalidateQueries([queryKeys.quotations]);
      toast("Le virement a été bloqué avec succès.", "success");
    },
    onError(error) {
      handleApiError(error, toast);
    }
  });

  const globalActions = [
    {
      label: "Exporter mes factures de prestations",
      icon: documentAttachOutline,
      action: () => openModal(<ExportQuotationBillsModal isOpen={true} onClose={closeModal} />)
    }
  ];

  const groupedActions = [
    {
      id: "unlock-payment",
      label: "Débloquer les paiements des factures sélectionnées",
      condition: isAdmin,
      action: (ids: number[]) =>
        openModal(
          <ConfirmationModal
            title="Débloquer les paiements des factures sélectionnées"
            isOpen={true}
            onClose={closeModal}
            onConfirm={() => unlockMultipleQuotationPaymentsMutation.mutate({ ids })}
          >
            Êtes vous sûr de vouloir débloquer les paiements des factures sélectionnées ?
          </ConfirmationModal>
        )
    },
    {
      id: "download-pdf",
      label: "Télécharger les pdfs des factures sélectionnées",
      action: (ids: number[]) =>
        openFile({
          itemType: "multiple-quotation-invoices",
          fileType: "application/zip",
          itemParams: { quotationIds: ids }
        })
    }
  ];

  const tableData: MainTableProps["data"] = useMemo(
    () =>
      data?.pages.flat().map((quotation) => ({
        itemId: quotation?.id,
        checkboxConditions: [
          {
            id: "unlock-payment",
            condition:
              isAdmin &&
              quotation?.statePaymentCooperator ===
                quotationCoopPaymentStates.STATE_PAYMENT_COOP_PENDING
          },
          {
            id: "download-pdf",
            condition: true
          }
        ],
        actions: [
          {
            label: "Consulter la facture",
            icon: documentAttachOutline,
            color: "sky",
            action: () =>
              openFile({
                itemType: "quotation-cooperator-billed",
                itemParams: { id: quotation?.id }
              })
          },
          {
            label: "Débloquer le paiement",
            icon: checkmarkOutline,
            color: "green",
            condition:
              isAdmin &&
              quotation?.statePaymentCooperator ===
                quotationCoopPaymentStates.STATE_PAYMENT_COOP_PENDING,
            action: () =>
              openModal(
                <ConfirmationModal
                  title="Débloquer le paiement"
                  isOpen={true}
                  onClose={closeModal}
                  onConfirm={() =>
                    quotation?.id
                      ? unblockQuotationPaymentMutation.mutate({ id: quotation.id })
                      : null
                  }
                >
                  Êtes vous sûr de vouloir débloquer le paiement ?
                </ConfirmationModal>
              )
          },
          {
            label: "Mettre le virement en attente",
            icon: closeOutline,
            color: "orange",
            condition:
              isAdmin &&
              quotation?.statePaymentCooperator !==
                quotationCoopPaymentStates.STATE_PAYMENT_COOP_PENDING &&
              (quotation?.statePaymentCooperator ===
                quotationCoopPaymentStates.STATE_PAYMENT_COOP_PAID ||
                quotation?.statePaymentCooperator ===
                  quotationCoopPaymentStates.STATE_PAYMENT_COOP_ALREADY_PAID),
            action: () =>
              openModal(
                <ConfirmationModal
                  title="Mettre le virement en attente"
                  isOpen={true}
                  onClose={closeModal}
                  onConfirm={() =>
                    quotation?.id
                      ? putQuotationPaymentOnPendingMutation.mutate({ id: quotation.id })
                      : null
                  }
                >
                  Êtes vous sûr de vouloir mettre le virement en attente ?
                </ConfirmationModal>
              )
          },
          {
            label: "Bloquer pour impayé",
            icon: lockClosed,
            color: "red",
            condition:
              isAdmin &&
              quotation?.statePaymentCooperator ===
                quotationCoopPaymentStates.STATE_PAYMENT_COOP_PENDING,
            action: () =>
              openModal(
                <ConfirmationModal
                  title="Bloquer pour impayé"
                  isOpen={true}
                  onClose={closeModal}
                  onConfirm={() =>
                    quotation?.id
                      ? putQuotationPaymentOnProblemMutation.mutate({ id: quotation.id })
                      : null
                  }
                >
                  Êtes vous sûr de vouloir bloquer le virement ?
                </ConfirmationModal>
              )
          },
          {
            label: "Débloquer pour impayé",
            icon: lockOpen,
            color: "orange",
            condition:
              isAdmin &&
              quotation?.statePaymentCooperator ===
                quotationCoopPaymentStates.STATE_PAYMENT_COOP_PROBLEM,
            action: () =>
              openModal(
                <ConfirmationModal
                  title="Débloquer pour impayé"
                  isOpen={true}
                  onClose={closeModal}
                  onConfirm={() =>
                    quotation?.id
                      ? putQuotationPaymentOnPendingMutation.mutate({ id: quotation.id })
                      : null
                  }
                >
                  Êtes vous sûr de vouloir débloquer le virement ?
                </ConfirmationModal>
              )
          }
        ],
        columns: [
          {
            key: "invoicedCooperatorAt",
            label: "Date création",
            display: quotation?.invoicedCooperatorAt
              ? formatDisplayDate(quotation.invoicedCooperatorAt)
              : "",
            sort: quotation?.invoicedCooperatorAt,
            search: quotation?.invoicedCooperatorAt
              ? formatDisplayDate(quotation.invoicedCooperatorAt)
              : ""
          },
          {
            key: "idFactureCooperator",
            label: "N° facture",
            display: (
              <Link
                to={`/file/deliver/quotation-cooperator-billed/${quotation?.id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {quotation?.idFactureCooperatorNormalized}
              </Link>
            )
          },
          {
            key: "idNormalized",
            label: "N° relevé",
            display: (
              <Link
                to={`/file/deliver/quotation/${quotation?.id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {quotation?.idNormalized}
              </Link>
            )
          },
          {
            key: "customer.lastname",
            label: "Client",
            display: (
              <div className="flex flex-col gap-1">
                {quotation?.quotationCustomerInfo?.[0]?.companyName ? (
                  <span className="font-medium">
                    {quotation.quotationCustomerInfo[0]?.companyName}
                  </span>
                ) : null}
                {quotation?.quotationCustomerInfo?.[0]?.lastname ||
                quotation?.quotationCustomerInfo?.[0]?.firstname ? (
                  <span>
                    {quotation.quotationCustomerInfo[0]?.lastname}{" "}
                    {quotation.quotationCustomerInfo[0]?.firstname}
                  </span>
                ) : null}
              </div>
            ),
            sort:
              quotation?.quotationCustomerInfo?.[0]?.companyName ||
              quotation?.quotationCustomerInfo?.[0]?.lastname,
            search: `${quotation?.quotationCustomerInfo?.[0]?.companyName} ${quotation?.quotationCustomerInfo?.[0]?.lastname} ${quotation?.quotationCustomerInfo?.[0]?.firstname} ${quotation?.quotationCustomerInfo?.[0]?.email} ${quotation?.quotationCustomerInfo?.[0]?.mobileNumber} ${quotation?.quotationCustomerInfo?.[0]?.fixeNumber}`
          },
          {
            key: "cooperator.lastname",
            label: "Coopérateur",
            display: (
              <div className="flex flex-col gap-1">
                {quotation?.quotationCooperatorInfo?.[0]?.companyName ? (
                  <span className="font-medium">
                    {quotation.quotationCooperatorInfo[0].companyName}
                  </span>
                ) : null}
                {quotation?.quotationCooperatorInfo?.[0]?.lastname ||
                quotation?.quotationCooperatorInfo?.[0]?.firstname ? (
                  <span>
                    {quotation.quotationCooperatorInfo[0].lastname}{" "}
                    {quotation.quotationCooperatorInfo[0].firstname}
                  </span>
                ) : null}
              </div>
            ),
            sort: quotation?.quotationCooperatorInfo?.[0]?.lastname,
            search: `${quotation?.quotationCooperatorInfo?.[0]?.companyName} ${quotation?.quotationCooperatorInfo?.[0]?.lastname} ${quotation?.quotationCooperatorInfo?.[0]?.firstname} ${quotation?.quotationCooperatorInfo?.[0]?.email} ${quotation?.quotationCooperatorInfo?.[0]?.mobileNumber} ${quotation?.quotationCooperatorInfo?.[0]?.fixeNumber}`,
            condition: isAdmin
          },
          {
            key: "totalTTC",
            label: "Montant TTC",
            display: quotation?.totalTTCCooperator ? formatMoney(quotation.totalTTCCooperator) : "",
            sort: quotation?.totalTTCCooperator ? quotation.totalTTCCooperator : "",
            search: quotation?.totalTTCCooperator ? formatMoney(quotation.totalTTCCooperator) : ""
          },
          {
            key: "state",
            label: "Statut",
            display:
              quotation?.statePaymentCooperator ===
              quotationCoopPaymentStates.STATE_PAYMENT_COOP_PENDING
                ? "En attente de virement"
                : quotation?.statePaymentCooperator ===
                    quotationCoopPaymentStates.STATE_PAYMENT_COOP_WAITING
                  ? "Virement débloqué, en attente"
                  : quotation?.statePaymentCooperator ===
                      quotationCoopPaymentStates.STATE_PAYMENT_COOP_PAID
                    ? "Virement effectué"
                    : quotation?.statePaymentCooperator ===
                        quotationCoopPaymentStates.STATE_PAYMENT_COOP_ALREADY_PAID
                      ? "Réglement antérieur"
                      : quotation?.statePaymentCooperator ===
                          quotationCoopPaymentStates.STATE_PAYMENT_COOP_PROBLEM
                        ? "Bloqué pour impayé."
                        : "En attente de virement",
            sort: quotation?.statePaymentCooperator
          },
          {
            key: "invoicedCooperatorPaidAt",
            label: "Payé le",
            display: quotation?.invoicedCooperatorPaidAt
              ? formatDisplayDate(quotation.invoicedCooperatorPaidAt)
              : "",
            sort: quotation?.invoicedCooperatorPaidAt,
            search: quotation?.invoicedCooperatorPaidAt
              ? formatDisplayDate(quotation.invoicedCooperatorPaidAt)
              : ""
          }
        ]
      })) || [],
    [
      isAdmin,
      data?.pages,
      openFile,
      unblockQuotationPaymentMutation,
      putQuotationPaymentOnPendingMutation,
      closeModal,
      openModal
    ]
  );

  return (
    <>
      <TableLayout
        data={tableData}
        isLoading={isFetching || isFetchingNextPage}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        globalActions={globalActions}
        groupedActions={groupedActions}
        advancedSearchParams={advancedSearchParams}
        handleAdvancedSearch={setAdvancedSearchParams}
        AdvancedSearchForm={CooperatorsQuotationBillsSearchForm}
      >
        <div className="text-end text-sm font-medium">
          Total montants TTC :{" "}
          <span className="text-sm text-primary-600">
            {formatMoney(
              data?.pages
                .flat()
                .reduce((acc, curr) => acc + Number(curr?.totalTTCCooperator ?? 0), 0) ?? 0
            )}
          </span>
        </div>
      </TableLayout>

      {renderModal()}
    </>
  );
};
