import { zodResolver } from "@hookform/resolvers/zod";
import { IonContent, IonPage } from "@ionic/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router";

import { quotationStates } from "~/config/api-constants";
import { queryKeys } from "~/config/query-keys-constants";
import { useUIContext } from "~/contexts/uiContext";
import { handleApiError } from "~/services/errors";
import { anonymousGetQuotation, anonymousSendPrepaidCode } from "~/api/anonymous";
import { Alert } from "~/components/ui/Alert";
import { Button } from "~/components/ui/Button/Button";
import { FormInput } from "~/components/ui/FormInput";
import { LoadingSpinner } from "~/components/ui/LoadingSpinner";
import { AnonymousSendPrepaidCodeFormSchema } from "~/schemas/anonymous.schema";

export const AnonymousSendPrepaidCodeForm = () => {
  const { hash: quotationHash } = useParams<{ hash: string }>();
  const history = useHistory();
  const { toast } = useUIContext();

  const {
    data: quotation,
    isFetching: isQuotationFetching,
    isError: isQuotationError
  } = useQuery({
    queryKey: [queryKeys.quotations],
    queryFn: () => anonymousGetQuotation({ hash: quotationHash })
  });

  const sendPrepaidCode = useMutation({
    mutationFn: anonymousSendPrepaidCode,
    onSuccess: () => {
      history.push(`/_anon/pay/cartepre/${quotationHash}/auth`);
    },
    onError(error) {
      handleApiError(error, toast);
    }
  });

  const form = useForm<AnonymousSendPrepaidCodeFormSchema>({
    resolver: zodResolver(AnonymousSendPrepaidCodeFormSchema),
    defaultValues: {
      code: ""
    }
  });

  const onSubmit = (data: AnonymousSendPrepaidCodeFormSchema) => {
    if (!quotation?.id) return;

    sendPrepaidCode.mutate({
      id: quotation.id,
      code: data.code
    });
  };

  return (
    <IonPage>
      <IonContent>
        <div className="flex h-full w-full items-center justify-center bg-grey-100 p-4 sm:p-8">
          <div className="mx-auto max-w-6xl rounded-lg border border-grey-100 bg-white px-4 py-4 shadow-md sm:px-8">
            {isQuotationFetching ? (
              <LoadingSpinner size="lg" fullPage />
            ) : !quotation || isQuotationError ? (
              <Alert
                type="error"
                title="Erreur"
                text={
                  <>
                    Une erreur est survenue lors de la récupération de votre relevé de prestation,
                    merci de réessayer ultérieurement.
                    <br />
                    Si le problème persiste, merci de vous rapprocher de votre prestataire pour plus
                    de détails.
                  </>
                }
              />
            ) : quotation.state === quotationStates.STATE_CANCEL ? (
              <Alert
                type="error"
                title="Erreur"
                text={
                  <>
                    Ce relevé de prestation a été annulé ou supprimé.
                    <br />
                    Merci de vous rapprocher de votre prestataire pour plus de détails.
                  </>
                }
              />
            ) : quotation.state === quotationStates.STATE_BILL ||
              quotation.state === quotationStates.STATE_PAID ? (
              <Alert
                type="error"
                title="Erreur"
                text={
                  <>
                    Ce relevé de prestation a déjà été payé.
                    <br />
                    Merci de vous rapprocher de votre prestataire pour plus de détails.
                  </>
                }
              />
            ) : quotation.state !== quotationStates.STATE_SEND ? (
              <Alert
                type="error"
                title="Erreur"
                text={
                  <>
                    Ce relevé de prestation ne peut pas être signé car il n&apos;a pas le statut
                    &quot;envoyé&quot;.
                    <br />
                    Merci de vous rapprocher de votre prestataire pour plus de détails.
                  </>
                }
              />
            ) : (
              <>
                <h1 className="m-0 border-b border-b-grey-100 pb-4 text-center text-lg font-semibold uppercase">
                  Paiement par carte prépayée <br />
                  <span className="text-base normal-case text-grey-700">
                    Relevé de prestation n°{quotation.id}
                  </span>
                </h1>

                <div className="mt-8">
                  <p className="mb-8 font-medium">
                    Saisissez votre code personnel à 6 chiffres dans le champ ci-dessous:
                  </p>
                  <form onSubmit={form.handleSubmit(onSubmit)} className="mx-auto max-w-96">
                    <FormInput
                      label="Code personnel"
                      placeholder="Saisissez votre code personnel à 6 chiffres"
                      errorMessage={form.formState.errors.code?.message}
                      {...form.register("code")}
                    />

                    <p className="mt-2 text-sm leading-snug text-grey-600">
                      Afin de vérifier votre identité, vous recevrez un SMS et un email de
                      vérification sur le numéro de téléphone et l&apos;adresse email associés à ce
                      code.
                    </p>

                    <div className="mt-8 flex justify-between gap-8">
                      <Button routerLink={`/_anon/sign/${quotationHash}`} fill="outline">
                        Annuler
                      </Button>
                      <Button type="submit">Valider</Button>
                    </div>
                  </form>
                </div>
              </>
            )}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
