import type { HTMLAttributes } from "react";

import { IonIcon } from "@ionic/react";

import { cn, formatMoney } from "~/utils/misc";
import { LoadingSpinner } from "~/components/ui/LoadingSpinner";

interface Props extends HTMLAttributes<HTMLDivElement> {
  amount: number;
  title: string;
  loading?: boolean;
  icon?: string;
  highlightTotal?: boolean;
}

export const StatusPaymentCard = ({
  title,
  icon,
  amount,
  loading,
  highlightTotal,
  className,
  ...props
}: Props) => {
  return (
    <div
      className={cn(
        "flex flex-col items-center justify-center gap-2 rounded-3xl bg-grey-100 p-3 md:gap-4 md:p-6",
        highlightTotal && "status-payment__highlight",
        className
      )}
      {...props}
    >
      <div
        className={cn("flex w-fit items-center gap-4 font-medium", highlightTotal && "text-center")}
      >
        {icon ? (
          <span className="flex items-center justify-center rounded-full bg-primary bg-opacity-5 p-2">
            <IonIcon src={icon} className="h-5 w-5" />
          </span>
        ) : null}
        {title}
      </div>
      <div className={cn("text-xl font-semibold", highlightTotal && "text-2xl text-primary")}>
        {loading ? (
          <LoadingSpinner size="sm" />
        ) : (
          <>
            {formatMoney(amount)}{" "}
            {!highlightTotal ? <span className="text-xs font-medium uppercase">ttc</span> : null}
          </>
        )}
      </div>
    </div>
  );
};
