import type { ReactElement, ReactNode } from "react";

import {
  cloneElement,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState
} from "react";
import { IonIcon } from "@ionic/react";
import { closeOutline, eyeOffOutline, eyeOutline } from "ionicons/icons";

import { cn } from "~/utils/misc";
import { FormLabel } from "~/components/ui/FormLabel";

type FormInputProps = {
  label?: string | null;
  icon?: ReactElement;
  onIconClick?: (e: any) => void;
  infoMessage?: string | null | undefined;
  warningMessage?: string | null | undefined;
  errorMessage?: string | null | undefined;
  optional?: boolean;
  clearable?: boolean;
  inputClassName?: string;
  leftAddon?: ReactNode;
  rightAddon?: ReactNode;
  absoluteComponent?: ReactNode;
} & React.ComponentPropsWithRef<"input">;

export const FormInput = forwardRef<HTMLInputElement, FormInputProps>(function ForwardedRefInput(
  {
    label,
    type = "text",
    infoMessage,
    warningMessage,
    errorMessage,
    optional,
    icon,
    clearable,
    className,
    inputClassName,
    leftAddon,
    rightAddon,
    absoluteComponent,
    ...props
  },
  ref
) {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const innerRef = useRef<HTMLInputElement>(null);

  useImperativeHandle(ref, () => innerRef.current!);

  const clearInput = useCallback(() => {
    if (innerRef.current) {
      // Trigger onInput event when clearing input
      innerRef.current.value = "";
      const event = new Event("input", {
        bubbles: true,
        cancelable: true
      });
      innerRef.current.dispatchEvent(event);
      innerRef.current.focus();
    }
  }, [innerRef]);

  const LeftIconComponent = icon
    ? cloneElement(icon, {
        className:
          "pointer-events-none absolute left-4 top-1/2 h-5 w-5 -translate-y-1/2 text-grey-500"
      })
    : null;

  return (
    <div className={cn("flex flex-col", className)}>
      {label ? (
        <FormLabel htmlFor={props.id || props.name} optional={optional || false}>
          {label}
        </FormLabel>
      ) : null}

      <div className="flex items-center gap-1">
        <div
          className={cn(
            "group flex grow rounded-lg border bg-white text-sm transition-colors",
            errorMessage
              ? "border-danger-700 hover:border-danger-800"
              : "border-grey-200 hover:border-grey-300",
            props.disabled && "pointer-events-none opacity-50"
          )}
        >
          {leftAddon ? (
            <div className="flex shrink-0 items-center justify-center rounded-l-lg border-r border-grey-200 bg-grey-100 px-4">
              {leftAddon}
            </div>
          ) : null}
          <div className="relative w-full">
            {LeftIconComponent ? LeftIconComponent : null}
            <input
              id={props.id || props.name}
              type={isPasswordVisible ? "text" : type}
              className={cn(
                "h-10 w-full appearance-none rounded-lg bg-transparent text-sm transition-colors placeholder:text-grey-400 focus-visible:outline-primary",
                errorMessage && "text-danger-900 focus-visible:outline-danger-700",
                LeftIconComponent ? "pl-12" : "pl-3",
                clearable ? "pr-12" : "pr-3",
                leftAddon && "rounded-l-none",
                rightAddon && "rounded-r-none",
                type === "date" && "cursor-pointer text-center",
                type === "file" &&
                  "cursor-pointer px-0 file:mr-4 file:h-full file:cursor-pointer file:border-y-0 file:border-l-0 file:border-r file:border-solid file:border-r-grey-200 file:bg-grey-50 file:px-4 file:outline-none file:transition-colors hover:bg-grey-50 file:hover:bg-grey-100",
                inputClassName
              )}
              ref={innerRef}
              onWheel={(e) => e.currentTarget.blur()}
              {...props}
            />
            {clearable && innerRef.current?.value !== "" ? (
              <button
                type="button"
                className="group/clear absolute right-4 top-1/2 -translate-y-1/2 leading-[0px]"
                onClick={clearInput}
                aria-label="Clear input"
              >
                <IonIcon
                  icon={closeOutline}
                  className="h-5 w-5 text-grey-400 transition-colors group-hover/clear:text-grey-600"
                />
              </button>
            ) : null}
            {absoluteComponent ? absoluteComponent : null}
          </div>
          {type === "password" ? (
            <button
              type="button"
              className="flex shrink-0 items-center justify-center rounded-r-lg border-l border-grey-200 bg-grey-100 px-4"
              onClick={() => setIsPasswordVisible(!isPasswordVisible)}
              aria-label={isPasswordVisible ? "Cacher le mot de passe" : "Montrer le mot de passe"}
            >
              <IonIcon icon={isPasswordVisible ? eyeOffOutline : eyeOutline} />
            </button>
          ) : rightAddon ? (
            <div className="flex shrink-0 items-center justify-center rounded-r-lg border-l border-grey-200 bg-grey-100 px-4">
              {rightAddon}
            </div>
          ) : null}
        </div>
      </div>

      {errorMessage ? (
        <p className="mx-1 mt-1 whitespace-pre-line text-sm text-danger-700">{errorMessage}</p>
      ) : null}
      {warningMessage ? (
        <p className="mx-1 mt-1 whitespace-pre-line text-xs text-danger-700">{warningMessage}</p>
      ) : null}
      {infoMessage ? (
        <p className="mx-1 mt-1 whitespace-pre-line text-xs text-grey-500">{infoMessage}</p>
      ) : null}
    </div>
  );
});
