import type { CooperatorCategory } from "~/types/cooperator-category.types";

import { api } from "~/api/_config";

export const getCooperatorCategories = async ({
  isContract,
  page,
  itemsPerPage
}: {
  isContract?: boolean;
  page?: number;
  itemsPerPage?: number;
}) => {
  const response = await api.get<CooperatorCategory[]>(`/api/admin/categories_cooperator`, {
    params: {
      isContract,
      page,
      itemsPerPage
    }
  });
  return response.data;
};

export const getCooperatorCategory = async ({ id }: { id: number }) => {
  const response = await api.get<CooperatorCategory>(`/api/admin/categories_cooperator/${id}`);
  return response.data;
};
