import type { QuotationPaymentSchema } from "~/schemas/quotation-payment.schema";
import type { QuotationPayment } from "~/types/quotation-payment.types";
import type { Quotation } from "~/types/quotation.types";
import type { DeepPartial } from "~/types/utils.types";

import { INFINITE_SCROLL_LIMIT } from "~/config/api";
import { api } from "~/api/_config";

export const getQuotationPayments = async ({
  quotationId,
  page,
  itemsPerPage
}: {
  quotationId?: number;
  page?: number;
  itemsPerPage?: number;
}) => {
  const params = {
    ...(quotationId && { "quotation.id": quotationId }),
    page,
    itemsPerPage: itemsPerPage ?? INFINITE_SCROLL_LIMIT
  };
  const response = await api.get<DeepPartial<QuotationPayment[]>>("/api/admin/quotation_payments", {
    params
  });
  return response.data;
};

export const createQuotationPayment = async ({
  quotationId,
  data
}: {
  quotationId: Quotation["id"];
  data: QuotationPaymentSchema;
}) => {
  const response = await api.post<DeepPartial<QuotationPayment>>("/api/admin/quotation_payments", {
    quotation: quotationId,
    ...data
  });
  return response.data;
};

export const editQuotationPayment = async ({
  paymentId,
  data
}: {
  paymentId: QuotationPayment["id"];
  data: QuotationPaymentSchema;
}) => {
  const response = await api.put<DeepPartial<QuotationPayment>>(
    `/api/admin/quotation_payments/${paymentId}`,
    data
  );
  return response.data;
};

export const deleteQuotationPayment = async ({
  paymentId
}: {
  paymentId: QuotationPayment["id"];
}) => {
  const response = await api.delete(`/api/admin/quotation_payments/${paymentId}`);
  return response.data;
};
