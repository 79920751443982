import type { PropsWithChildren } from "react";
import type { ModalProps } from "~/components/ui/Modal/Modal";

import { zodResolver } from "@hookform/resolvers/zod";
import dayjs from "dayjs";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { useFileDeliver } from "~/hooks/useFileDeliver";
import { Button } from "~/components/ui/Button/Button";
import { FormInput } from "~/components/ui/FormInput";
import { FormRow } from "~/components/ui/FormRow";
import { Modal } from "~/components/ui/Modal/Modal";

interface ExportQuotationBillsModalProps extends ModalProps {
  withForm?: boolean;
}

export const ExportQuotationBillsModal = ({
  ...props
}: PropsWithChildren<ExportQuotationBillsModalProps>) => {
  const { openFile, isLoading } = useFileDeliver();

  const formSchema = z.object({
    dateStart: z.string().min(1, "Veuillez renseigner la date de début."),
    dateEnd: z.string().min(1, "Veuillez renseigner la date de fin.")
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    values: {
      dateStart: dayjs().subtract(1, "month").format("YYYY-MM-DD"),
      dateEnd: dayjs().format("YYYY-MM-DD")
    }
  });

  const onSubmit = (data: z.infer<typeof formSchema>) => {
    openFile({
      itemType: "export-quotation-cooperator-invoices-csv",
      itemParams: {
        dateStart: data.dateStart,
        dateEnd: data.dateEnd
      }
    });
  };

  return (
    <Modal title="Réglements" {...props}>
      <h2 className="text-xl font-semibold">Exporter mes factures coopérateur</h2>

      <form className="mt-4" onSubmit={form.handleSubmit(onSubmit)}>
        <FormRow className="lg:grid-cols-2">
          <FormInput type="date" {...form.register("dateStart")} />
          <FormInput type="date" {...form.register("dateEnd")} />
        </FormRow>

        <div className="mt-12 flex justify-end gap-4 border-t border-grey-200 pt-6">
          <Button fill="clear" onClick={() => props.onClose()}>
            Annuler
          </Button>
          <Button type="submit" disabled={isLoading}>
            Exporter
          </Button>
        </div>
      </form>
    </Modal>
  );
};
