import type { ModuleSchema, SendModuleSchema } from "~/schemas/module.schema";
import type { Module } from "~/types/module.types";
import type { DeepPartial } from "~/types/utils.types";

import { INFINITE_SCROLL_LIMIT } from "~/config/api";
import { api } from "~/api/_config";

export const getModules = async ({
  page,
  itemsPerPage
}: {
  page?: number;
  itemsPerPage?: number;
}) => {
  const response = await api.get<DeepPartial<Module[]>>("/api/admin/modules", {
    params: { page, itemsPerPage: itemsPerPage ?? INFINITE_SCROLL_LIMIT }
  });
  return response.data;
};

export const getModule = async ({ id }: { id: number }) => {
  const response = await api.get<DeepPartial<Module>>(`/api/admin/modules/${id}`);
  return response.data;
};

export const createModule = async ({ data }: { data: ModuleSchema }) => {
  const response = await api.post(`/api/admin/modules`, data);
  return response.data;
};

export const updateModule = async ({ id, data }: { id: number; data: ModuleSchema }) => {
  const response = await api.put(`/api/admin/modules/${id}`, data);
  return response.data;
};

export const sendModule = async ({ id, data }: { id: number; data: SendModuleSchema }) => {
  const response = await api.post(`/api/module/send/${id}`, data);
  return response.data;
};
