import type { UseFormRegister } from "react-hook-form";

import dayjs from "dayjs";

import { quotationStates } from "~/config/api-constants";
import { transformDateTimeToDate } from "~/utils/misc";
import { FormDatePicker } from "~/components/ui/FormDatePicker";
import { FormInput } from "~/components/ui/FormInput";
import { FormSelect } from "~/components/ui/FormSelect";

export const QuotationsPendingSearchForm = ({ register }: { register: UseFormRegister<any> }) => {
  return (
    <>
      <FormDatePicker
        label="Date min"
        defaultValue={transformDateTimeToDate(dayjs().subtract(3, "year").toISOString()) || ""}
        optional
        {...register("createdAtAfter")}
      />
      <FormDatePicker
        label="Date max"
        defaultValue={transformDateTimeToDate(dayjs().toISOString()) || ""}
        optional
        {...register("createdAtBefore")}
      />
      <FormInput
        label="Nom du client"
        placeholder="ex: Dupond"
        type="text"
        optional
        {...register("customerLastname")}
      />
      <FormInput
        label="Société du client"
        placeholder="ex: HEXA-COOP"
        type="text"
        optional
        {...register("customerCompanyName")}
      />
      <FormInput
        label="Nom du coopérateur"
        placeholder="ex: Dupont"
        type="text"
        optional
        {...register("cooperatorLastname")}
      />
      <FormInput
        label="Société du coopérateur"
        placeholder="ex: Jardi Services"
        type="text"
        optional
        {...register("cooperatorCompanyName")}
      />
      <FormInput
        label="Numéro de relevé"
        placeholder="6 derniers chiffres (ex: 231887)"
        type="text"
        optional
        {...register("id")}
      />
      <FormSelect label="Statut" optional {...register("state")}>
        <option value="">Tous</option>
        <option value={quotationStates.STATE_DRAFT}>Brouillons</option>
        <option value={quotationStates.STATE_SEND}>En attente de signature</option>
        <option value={quotationStates.STATE_SIGNED}>Signés</option>
        <option value={quotationStates.STATE_PENDING_CHOOSE}>
          En attente du choix de paiement
        </option>
        <option value={quotationStates.STATE_PARTIAL_PAID}>Partiellement payés</option>
        <option value={quotationStates.STATE_PAID}>Payés</option>
        <option value={quotationStates.STATE_CANCEL}>Annulés</option>
        <option value={quotationStates.STATE_INSTANT_ADVANCE_FRAUD}>A contrôler</option>
      </FormSelect>

      <FormInput
        label="Montant TTC min (€)"
        type="number"
        placeholder="ex: 1000"
        optional
        {...register("minTotalTTC")}
      />
      <FormInput
        label="Montant TTC max (€)"
        type="number"
        placeholder="ex: 1500"
        optional
        {...register("maxTotalTTC")}
      />
    </>
  );
};
