import type { ConfigSchema } from "~/schemas/config.schema";
import type { Config } from "~/types/config.types";
import type { DeepPartial } from "~/types/utils.types";

import { INFINITE_SCROLL_LIMIT } from "~/config/api";
import { api } from "~/api/_config";

export const getConfigs = async ({
  page,
  itemsPerPage
}: {
  page?: number;
  itemsPerPage?: number;
}) => {
  const response = await api.get<DeepPartial<Config[]>>("/api/admin/configs", {
    params: { page, itemsPerPage: itemsPerPage ?? INFINITE_SCROLL_LIMIT }
  });
  return response.data;
};

export const getConfig = async (id: number) => {
  const response = await api.get<Config>(`/api/admin/configs/${id}`);
  return response.data;
};

export const createConfig = async (data: ConfigSchema) => {
  const response = await api.post("/api/admin/configs", data);
  return response.data;
};

export const updateConfig = async ({ id, data }: { id: number; data: ConfigSchema }) => {
  const response = await api.put(`/api/admin/configs/${id}`, data);
  return response.data;
};
