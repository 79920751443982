import type { QuotationCreatedStats, StatisticsChartProps } from "~/types/statistics.types";

import { formatMoney } from "~/utils/misc";
import { useGetStatisticsQuery } from "~/api/statistics";
import { StatisticsType } from "~/types/statistics.types";
import { extractDailyData } from "./utils";
import { DailyLineChart } from "../charts/DailyLineChart";
import { LoadingSpinner } from "../ui/LoadingSpinner";

export const QuotationCreatedChart = (params: StatisticsChartProps) => {
  const { data, isLoading } = useGetStatisticsQuery<QuotationCreatedStats>(
    StatisticsType.QUOTATION_CREATED,
    params
  );

  if (isLoading) {
    return (
      <div className="my-20 flex w-full items-center justify-center gap-4">
        <LoadingSpinner /> Chargement des données
      </div>
    );
  }

  if (!data) {
    return (
      <div className="my-20 flex w-full items-center justify-center gap-4">
        Pas de donnée sur la période sélectionnée
      </div>
    );
  }

  const stats = data.quotationsCreatedStats;

  return (
    <>
      <div className="grid grid-cols-1 text-sm sm:grid-cols-2">
        <div>
          <b>Cumul de la période :</b> {formatMoney(stats[2])}
        </div>
        <div>
          <b>Moyenne de la période :</b> {formatMoney(stats[4])}
        </div>
        <div>
          <b>Cumul de la période N-1 :</b> {formatMoney(stats[3])}
        </div>
        <div>
          <b>Moyenne de la période N-1 :</b> {formatMoney(stats[5])}
        </div>
      </div>
      <div className="h-80 py-4 sm:h-96 sm:px-4">
        <DailyLineChart
          datasets={[
            {
              label: "Période courante",
              data: extractDailyData(stats[0]),
              backgroundColor: "#82a907",
              borderWidth: 1,
              borderColor: "#82a907"
            },
            {
              label: "Période N-1",
              data: extractDailyData(stats[1], 1),
              backgroundColor: "#82a90722",
              borderWidth: 1,
              borderColor: "#82a90755"
            }
          ]}
        />
      </div>
    </>
  );
};
