import { z } from "zod";

export const ExportSchema = z.object({
  type: z.number().min(1),
  email: z.string(),
  startDate: z.string(),
  endDate: z.string(),
  // Not sended
  name: z.string().optional().nullable()
});

export type ExportSchema = z.infer<typeof ExportSchema>;
