import { z } from "zod";

const MAX_FILE_SIZE = 1024 * 1024 * 100; // 100MB
const ACCEPTED_FILE_TYPES = ["text/csv"];

export const ImportCustomersSchema = z
  .object({
    fileIndividual: z
      .instanceof(FileList)
      .refine((files) => {
        if (files.length === 0) return true;
        return ACCEPTED_FILE_TYPES.includes(files[0]?.type ?? "");
      }, "Le fichier doit être au format csv")
      .refine((files) => {
        if (files.length === 0) return true;
        return files[0]?.size ?? 0 <= MAX_FILE_SIZE;
      }, "Le fichier est trop volumineux (100Mo maximum)")
      .optional()
      .nullable(),
    fileProfessional: z
      .instanceof(FileList)
      .refine((files) => {
        if (files.length === 0) return true;
        return ACCEPTED_FILE_TYPES.includes(files[0]?.type ?? "");
      }, "Le fichier doit être au format csv")
      .refine((files) => {
        if (files.length === 0) return true;
        return files[0]?.size ?? 0 <= MAX_FILE_SIZE;
      }, "Le fichier est trop volumineux (100Mo maximum)")
      .optional()
      .nullable()
  })
  .refine((data) => data.fileIndividual || data.fileProfessional, {
    message: "Au moins un fichier doit être sélectionné",
    path: ["fileIndividual"]
  });

export type ImportCustomersSchema = z.infer<typeof ImportCustomersSchema>;
