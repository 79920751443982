import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router";

import { queryKeys } from "~/config/query-keys-constants";
import { useUIContext } from "~/contexts/uiContext";
import { handleApiError } from "~/services/errors";
import { createAdmin, getUser, updateAdmin } from "~/api/user";
import { Button } from "~/components/ui/Button/Button";
import { FormInput } from "~/components/ui/FormInput";
import { FormRow } from "~/components/ui/FormRow";
import { FormSelect } from "~/components/ui/FormSelect";
import { FormTitle } from "~/components/ui/FormTitle";
import { LoadingSpinner } from "~/components/ui/LoadingSpinner";
import { AdminSchema } from "~/schemas/admin.schema";

export const CreateEditAdmin = ({ editMode = false }: { editMode?: boolean }) => {
  const history = useHistory();
  const { toast } = useUIContext();
  const queryClient = useQueryClient();
  const { user_id: userId } = useParams<{ user_id?: string }>();

  const { data: userData, isLoading: isUserLoading } = useQuery({
    queryKey: [queryKeys.users, { id: userId ? parseInt(userId) : null }],
    queryFn: () => getUser({ id: userId ? parseInt(userId) : null }),
    enabled: !!userId
  });

  const adminMutation = useMutation({
    mutationFn: (data: AdminSchema) =>
      editMode
        ? updateAdmin({ userId: userId ? parseInt(userId) : null, data })
        : createAdmin({ data }),
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: [queryKeys.users] });
      toast(
        editMode
          ? "L'administrateur a été modifié avec succès."
          : "L'administrateur a été créé avec succès.",
        "success"
      );
      history.push("/administration/administrators");
    },
    onError(error) {
      handleApiError(error, toast);
    }
  });

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<AdminSchema>({
    resolver: zodResolver(AdminSchema),
    values: {
      lastname: userData?.lastname || "",
      firstname: userData?.firstname || "",
      email: userData?.email || "",
      isActive: userData?.isActive || false
    }
  });

  const onSubmit = (data: AdminSchema) => {
    adminMutation.mutate(data);
  };

  if (!!userId && isUserLoading) {
    return <LoadingSpinner size="lg" fullPage />;
  }

  if (!!userId && !userData) {
    return <p className="text-center">L&apos;administrateur à éditer est introuvable.</p>;
  }

  return (
    <>
      <h2 className="mt-6">
        {editMode
          ? `Édition d'un administrateur : ${userData?.lastname} ${userData?.firstname}`
          : "Création d'un administrateur"}
      </h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormTitle as="h3">Informations</FormTitle>

        <FormRow>
          <FormInput
            label="Nom"
            errorMessage={errors.lastname?.message}
            {...register("lastname")}
          />
          <FormInput
            label="Prénom"
            errorMessage={errors.firstname?.message}
            {...register("firstname")}
          />
          <FormInput label="Email" errorMessage={errors.email?.message} {...register("email")} />
          <FormSelect
            label="Compte activé"
            defaultValue=""
            errorMessage={errors.isActive?.message}
            {...register("isActive", { setValueAs: (v) => v === "true" || v === true })}
          >
            <option value="false">Non</option>
            <option value="true">Oui</option>
          </FormSelect>
        </FormRow>

        <div className="mt-12 flex justify-end gap-4 border-t border-grey-200 pt-6">
          <Button fill="clear" onClick={() => history.goBack()}>
            Annuler
          </Button>
          <Button type="submit" disabled={adminMutation.isLoading}>
            {editMode ? "Modifier l'administrateur" : "Créer l'administrateur"}
          </Button>
        </div>
      </form>
    </>
  );
};
