import type { ComponentPropsWithoutRef, Dispatch, ReactNode, SetStateAction } from "react";
import type { TableActionsProps } from "~/components/TableActions";

import React, { useMemo } from "react";
import { IonCheckbox, IonIcon } from "@ionic/react";

import { cn } from "~/utils/misc";
import { TableActions } from "~/components/TableActions";
import arrowDownIcon from "~/assets/icons/arrow-down.svg";
import arrowUpIcon from "~/assets/icons/arrow-up.svg";

export type MainTableProps = {
  data: {
    itemId: number | null | undefined;
    actions?: TableActionsProps["actions"] | null | undefined;
    checkboxConditions?: { id: string; condition: boolean | undefined }[];
    columns: {
      key: string;
      label: string;
      display: string | ReactNode;
      search?: string;
      sort?: string | number | ReactNode;
      sortable?: boolean;
      onMobile?: boolean;
      condition?: boolean | undefined;
    }[];
  }[];
  currentSort?: { key: string; value: "ASC" | "DESC" } | null;
  onSort: (sortKey: string) => void;
  selectedGroupedAction?: string | null;
  selectedItems?: number[];
  setSelectedItems?: Dispatch<SetStateAction<number[]>>;
  someGroupedAction?: boolean;
} & ComponentPropsWithoutRef<"div">;

export const MainTable = React.memo(function MainTable({
  data,
  currentSort,
  onSort,
  selectedGroupedAction,
  selectedItems,
  setSelectedItems,
  someGroupedAction,
  ...props
}: MainTableProps) {
  const headers = useMemo(
    () =>
      data[0]?.columns?.map((col) => ({
        key: col.key,
        label: col.label,
        sortable: col.sortable === false ? false : true,
        onMobile: col.onMobile
      })) || [],
    [data]
  );

  return (
    <div
      className={cn(
        "mb-12 mt-6 w-full overflow-auto rounded-lg border border-grey-200",
        props.className
      )}
      {...props}
    >
      <table
        className="w-full text-[0.8125rem]"
        // {...props}
      >
        <thead className="rounded-t-lg border-b border-b-grey-200">
          <tr className="divide-x divide-grey-200 rounded-t-lg bg-grey-100 bg-opacity-70">
            {someGroupedAction &&
            data.some(
              (row) =>
                row.checkboxConditions?.find((condition) => condition.id === selectedGroupedAction)
                  ?.condition === true
            ) ? (
              <th className="w-10 min-w-10 max-w-10 rounded-tl-lg">
                <div className="flex items-center justify-center p-2">
                  <IonCheckbox
                    mode="md"
                    className="h-4 w-4"
                    onIonChange={(e) => {
                      if (e.target.checked) {
                        setSelectedItems?.(data.map((row) => row.itemId || 0));
                      } else {
                        setSelectedItems?.([]);
                      }
                    }}
                  />
                </div>
              </th>
            ) : null}

            <th className="lg:hidden">
              <div className="flex items-center justify-start p-2">
                <div className="text-start"> </div>
              </div>
            </th>

            {headers.map((header) => (
              <th
                key={`header-${header.key}-${header.label}`}
                className={cn(
                  "w-0 min-w-0 font-semibold text-grey-900",
                  header.onMobile ? "hidden" : "hidden lg:table-cell"
                )}
              >
                {header.sortable ? (
                  <button
                    onClick={() => onSort(header.key)}
                    className="group flex w-full items-center justify-start gap-1.5 px-3 py-2"
                  >
                    <div className="text-start">{header.label}</div>
                    <div className="flex items-center">
                      <IonIcon
                        icon={arrowUpIcon}
                        className={cn(
                          "w-3.5 text-grey-500 group-hover:text-grey-800",
                          currentSort?.key === header.key &&
                            currentSort.value === "DESC" &&
                            "text-primary-500 group-hover:text-primary-600"
                        )}
                      />
                      <IonIcon
                        icon={arrowDownIcon}
                        className={cn(
                          "-ml-1.5 w-3.5 text-grey-500 group-hover:text-grey-800",
                          currentSort?.key === header.key &&
                            currentSort.value === "ASC" &&
                            "text-primary-500 group-hover:text-primary-600"
                        )}
                      />
                    </div>
                  </button>
                ) : (
                  <div className="px-3 py-2">{header.label}</div>
                )}
              </th>
            ))}

            {data.some((row) => row.actions && row.actions.length > 0) ? (
              <th className="rounded-tr-lg text-start font-semibold text-grey-900">
                <div className="px-3 py-2">Actions</div>
              </th>
            ) : null}
          </tr>
        </thead>
        <tbody className="divide-y divide-grey-200 rounded-b-lg bg-white">
          {data.map((row, rowIndex) => (
            <tr
              key={rowIndex}
              className="divide-x divide-grey-100 last:rounded-b-lg even:bg-grey-50/50 hover:bg-grey-100/60"
            >
              {someGroupedAction &&
              data.some(
                (row) =>
                  row.checkboxConditions?.find(
                    (condition) => condition.id === selectedGroupedAction
                  )?.condition === true
              ) ? (
                <td className="w-[1%] rounded-bl-lg">
                  <div className="flex items-center justify-center p-2">
                    {row.checkboxConditions?.find(
                      (condition) => condition.id === selectedGroupedAction
                    )?.condition === true ? (
                      <IonCheckbox
                        mode="md"
                        className="h-4 w-4"
                        checked={selectedItems?.includes(row.itemId || 0) || false}
                        onIonChange={(e) => {
                          if (e.target.checked) {
                            setSelectedItems?.((prev) =>
                              row.itemId ? [...prev, row.itemId] : prev
                            );
                          } else {
                            setSelectedItems?.((prev) => prev.filter((id) => id !== row.itemId));
                          }
                        }}
                      />
                    ) : (
                      " "
                    )}
                  </div>
                </td>
              ) : null}

              {Object.values(row.columns).map((cell, cellIndex) => (
                <td
                  key={cellIndex}
                  className={cn(
                    "p-2 leading-snug text-grey-800",
                    cell.onMobile ? "hidden" : "hidden lg:table-cell"
                  )}
                >
                  {cell.display}
                </td>
              ))}

              {/* Cell for mobile with all columns in a cell */}
              <td className="p-1 leading-snug text-grey-800 lg:hidden">
                {row.columns
                  .filter((col) => !col.onMobile)
                  .map((cell, cellIndex, arr) => (
                    <div
                      key={cellIndex}
                      className={cn(
                        "border-grey-50 p-2",
                        cellIndex === arr.length - 1 ? "border-none" : "border-b"
                      )}
                    >
                      <div className="mb-1 font-semibold">{headers[cellIndex]?.label} : </div>
                      <div className="pl-2">{cell.display}</div>
                    </div>
                  ))}
              </td>

              {row.actions && row.actions.length > 0 ? (
                <td className="w-[1%] rounded-br-lg">
                  <div className="flex items-center justify-start p-3">
                    <TableActions itemId={row.itemId} actions={row.actions} />
                  </div>
                </td>
              ) : null}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
});
