import { IonFooter } from "@ionic/react";

import { Link } from "~/components/ui/Link";

export function Footer() {
  return (
    <IonFooter>
      <div className="flex flex-wrap items-center justify-center gap-x-8 gap-y-2 p-4 text-xs text-grey-600">
        <Link to="/personal-data-security-policy" target="_blank" rel="noopener noreferrer">
          Politique de sécurité des données personnelles
        </Link>
        <Link to="/legal-notice" target="_blank" rel="noopener noreferrer">
          Mentions légales
        </Link>
      </div>
    </IonFooter>
  );
}
