import type { AdvancedSearchParams } from "~/components/TableLayout";
import type { MainTableProps } from "~/components/ui/MainTable";

import { useMemo, useState } from "react";
import { useInfiniteQuery, useMutation } from "@tanstack/react-query";
import {
  createOutline,
  documentAttachOutline,
  downloadOutline,
  duplicateOutline,
  mailOutline,
  walletOutline
} from "ionicons/icons";

import { quotationStates, quotationTypes } from "~/config/api-constants";
import { queryKeys } from "~/config/query-keys-constants";
import { formatDisplayDate, formatMoney } from "~/utils/misc";
import { useAuthContext } from "~/contexts/authContext";
import { useUIContext } from "~/contexts/uiContext";
import { useFileDeliver } from "~/hooks/useFileDeliver";
import { useModal } from "~/hooks/useModal";
import { handleApiError } from "~/services/errors";
import { getNextPageParamFn } from "~/api/_config";
import { queryClient } from "~/api/_queryClient";
import {
  duplicateQuotationFromInvoice,
  getQuotations,
  sendQuotationInvoiceToCustomer
} from "~/api/quotation";
import { QuotationsBillsSearchForm } from "~/components/AdvancedSearchForms/Quotations/QuotationsBillsSearchForm";
import { ConfirmationModal } from "~/components/Modals/ConfirmationModal";
import { QuotationPaymentsModal } from "~/components/Modals/QuotationPaymentsModal";
import { TableLayout } from "~/components/TableLayout";
import { ExternalLink, Link } from "~/components/ui/Link";

export const QuotationsBills = () => {
  const { isAdmin } = useAuthContext();
  const { toast } = useUIContext();
  const { openModal, closeModal, renderModal } = useModal();
  const { openFile } = useFileDeliver();

  const [advancedSearchParams, setAdvancedSearchParams] = useState<AdvancedSearchParams>(null);

  const queryParams = {
    typeQuotation: quotationTypes.TYPE_QUOTATION_NORMAL,
    states: [quotationStates.STATE_BILL],
    ...advancedSearchParams
  };

  const { data, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: [queryKeys.quotations, queryParams],
    queryFn: ({ pageParam }) => getQuotations({ ...queryParams, page: pageParam }),
    getNextPageParam: getNextPageParamFn
  });

  const sendQuotationInvoiceToCustomerMutation = useMutation(sendQuotationInvoiceToCustomer, {
    onSuccess: () => {
      toast("La facture a été envoyé avec succès.", "success");
    },
    onError(error) {
      handleApiError(error, toast);
    }
  });

  const duplicateQuotationMutation = useMutation(duplicateQuotationFromInvoice, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.quotations]);
      toast("Le relevé a été créé avec succès.", "success");
    },
    onError(error) {
      handleApiError(error, toast);
    }
  });

  const globalActions = [
    {
      label: "Exporter",
      icon: downloadOutline,
      action: () =>
        openFile({
          itemType: "export-quotation-invoices-csv"
        })
    }
  ];

  const tableData: MainTableProps["data"] = useMemo(
    () =>
      data?.pages.flat().map((quotation) => ({
        itemId: quotation?.id,
        actions: [
          {
            label: "Consulter la facture",
            icon: documentAttachOutline,
            color: "sky",
            action: () =>
              openFile({ itemType: "quotation-billed", itemParams: { id: quotation?.id } })
          },
          {
            label: "Renvoyer la facture au client par email",
            icon: mailOutline,
            color: "amber",
            action: () =>
              openModal(
                <ConfirmationModal
                  title="Renvoyer la facture au client par email"
                  isOpen={true}
                  onClose={closeModal}
                  onConfirm={() =>
                    quotation?.id
                      ? sendQuotationInvoiceToCustomerMutation.mutate({ id: quotation.id })
                      : null
                  }
                >
                  Êtes vous sûr de vouloir renvoyer la facture au client par email ?
                </ConfirmationModal>
              )
          },
          {
            label: "Recréer un relevé à partir de cette facture",
            icon: duplicateOutline,
            color: "indigo",
            action: () =>
              openModal(
                <ConfirmationModal
                  title="Recréer un relevé à partir d'une facture"
                  isOpen={true}
                  onClose={closeModal}
                  onConfirm={() =>
                    quotation?.id ? duplicateQuotationMutation.mutate({ id: quotation.id }) : null
                  }
                >
                  Êtes vous sûr de vouloir recréer un relevé à partir de cette facture ?
                </ConfirmationModal>
              )
          },
          {
            label: "Voir les réglements",
            icon: walletOutline,
            color: "teal",
            condition: isAdmin,
            action: () =>
              quotation?.id
                ? openModal(
                    <QuotationPaymentsModal
                      quotation={quotation}
                      isOpen={true}
                      onClose={closeModal}
                    />
                  )
                : null
          },
          {
            label: "Éditer la facture",
            icon: createOutline,
            color: "blue",
            condition: isAdmin,
            href: `/quotations/edit/${quotation?.id}`
          }
        ],
        columns: [
          {
            key: "invoicedCustomerAt",
            label: "Date",
            display: quotation?.invoicedCustomerAt
              ? formatDisplayDate(quotation.invoicedCustomerAt)
              : "",
            sort: quotation?.invoicedCustomerAt,
            search: quotation?.invoicedCustomerAt
              ? formatDisplayDate(quotation.invoicedCustomerAt)
              : ""
          },
          {
            key: "idFactureNormalized",
            label: "N° facture",
            display: (
              <Link
                to={`/file/deliver/quotation-billed/${quotation?.id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {quotation?.idFactureNormalized}
              </Link>
            )
          },
          {
            key: "idNormalized",
            label: "N° relevé",
            display: (
              <Link
                to={`/file/deliver/quotation/${quotation?.id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {quotation?.idNormalized}
              </Link>
            )
          },
          {
            key: "customer.lastname",
            label: "Client",
            display: (
              <div className="flex flex-col gap-1">
                {quotation?.quotationCustomerInfo?.[0]?.companyName ? (
                  <span className="font-medium">
                    {quotation.quotationCustomerInfo[0]?.companyName}
                  </span>
                ) : null}
                {quotation?.quotationCustomerInfo?.[0]?.lastname ||
                quotation?.quotationCustomerInfo?.[0]?.firstname ? (
                  <span>
                    {quotation.quotationCustomerInfo[0]?.lastname}{" "}
                    {quotation.quotationCustomerInfo[0]?.firstname}
                  </span>
                ) : null}
                {quotation?.quotationCustomerInfo?.[0]?.email ? (
                  <ExternalLink
                    to={`mailto:${quotation.quotationCustomerInfo[0]?.email}`}
                    className="text-xs"
                  >
                    {quotation.quotationCustomerInfo[0]?.email}
                  </ExternalLink>
                ) : null}
                {quotation?.quotationCustomerInfo?.[0]?.mobileNumber ? (
                  <ExternalLink
                    to={`tel:${quotation.quotationCustomerInfo[0]?.mobileNumber}`}
                    className="text-xs"
                  >
                    {quotation.quotationCustomerInfo[0]?.mobileNumber}
                  </ExternalLink>
                ) : null}
                {quotation?.quotationCustomerInfo?.[0]?.fixeNumber ? (
                  <ExternalLink
                    to={`tel:${quotation.quotationCustomerInfo[0]?.fixeNumber}`}
                    className="text-xs"
                  >
                    {quotation.quotationCustomerInfo[0]?.fixeNumber}
                  </ExternalLink>
                ) : null}
              </div>
            ),
            sort:
              quotation?.quotationCustomerInfo?.[0]?.companyName ||
              quotation?.quotationCustomerInfo?.[0]?.lastname
          },
          {
            key: "cooperator.lastname",
            label: "Coopérateur",
            display: (
              <div className="flex flex-col gap-1">
                {quotation?.quotationCooperatorInfo?.[0]?.companyName ? (
                  <span className="font-medium">
                    {quotation.quotationCooperatorInfo[0].companyName}
                  </span>
                ) : null}
                {quotation?.quotationCooperatorInfo?.[0]?.lastname ||
                quotation?.quotationCooperatorInfo?.[0]?.firstname ? (
                  <span>
                    {quotation.quotationCooperatorInfo[0]?.lastname}{" "}
                    {quotation.quotationCooperatorInfo[0]?.firstname}
                  </span>
                ) : null}
                {quotation?.quotationCooperatorInfo?.[0]?.email ? (
                  <ExternalLink
                    to={`mailto:${quotation.quotationCooperatorInfo[0].email}`}
                    className="text-xs"
                  >
                    {quotation.quotationCooperatorInfo[0].email}
                  </ExternalLink>
                ) : null}
                {quotation?.quotationCooperatorInfo?.[0]?.mobileNumber ? (
                  <ExternalLink
                    to={`tel:${quotation.quotationCooperatorInfo[0].mobileNumber}`}
                    className="text-xs"
                  >
                    {quotation.quotationCooperatorInfo[0].mobileNumber}
                  </ExternalLink>
                ) : null}
                {quotation?.quotationCooperatorInfo?.[0]?.fixeNumber ? (
                  <ExternalLink
                    to={`tel:${quotation.quotationCooperatorInfo[0].fixeNumber}`}
                    className="text-xs"
                  >
                    {quotation.quotationCooperatorInfo[0].fixeNumber}
                  </ExternalLink>
                ) : null}
              </div>
            ),
            sort:
              quotation?.quotationCooperatorInfo?.[0]?.companyName ||
              quotation?.quotationCooperatorInfo?.[0]?.lastname,
            condition: isAdmin
          },
          {
            key: "presta",
            label: "Prestations",
            display: (
              <div className="flex flex-col gap-1">
                {quotation?.quotationRow
                  ? quotation.quotationRow.map((row, index) =>
                      row ? (
                        <span key={`${quotation.id}-${index}`}>
                          {parseInt(row.quantity || "")}x {row.category?.name}
                        </span>
                      ) : null
                    )
                  : null}
              </div>
            ),
            condition: isAdmin
          },
          {
            key: "totalTTC",
            label: "Montant TTC",
            display: quotation?.totalTTC ? formatMoney(quotation.totalTTC) : "",
            sort: quotation?.totalTTC ? parseFloat(quotation.totalTTC) : "",
            search: `${quotation?.totalTTC ? formatMoney(quotation.totalTTC) : ""} ${
              quotation?.totalTTC ? parseFloat(quotation.totalTTC) : ""
            }`
          },
          {
            key: "invoicedCooperatorPaidAt",
            label: "Payé le",
            display: quotation?.invoicedCooperatorPaidAt
              ? formatDisplayDate(quotation.invoicedCooperatorPaidAt)
              : "",
            sort: quotation?.invoicedCooperatorPaidAt,
            search: quotation?.invoicedCooperatorPaidAt
              ? formatDisplayDate(quotation.invoicedCooperatorPaidAt)
              : ""
          }
        ]
      })) || [],
    [
      isAdmin,
      data?.pages,
      openFile,
      closeModal,
      openModal,
      sendQuotationInvoiceToCustomerMutation,
      duplicateQuotationMutation
    ]
  );

  return (
    <>
      <TableLayout
        data={tableData}
        globalActions={globalActions}
        isLoading={isFetching || isFetchingNextPage}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        AdvancedSearchForm={QuotationsBillsSearchForm}
        advancedSearchParams={advancedSearchParams}
        handleAdvancedSearch={(data) => setAdvancedSearchParams(data)}
      >
        <div className="text-end text-sm font-medium">
          Total montants TTC :{" "}
          <span className="text-sm text-primary-600">
            {formatMoney(
              data?.pages.flat().reduce((acc, curr) => acc + Number(curr?.totalTTC ?? 0), 0) ?? 0
            )}
          </span>
        </div>
      </TableLayout>

      {renderModal()}
    </>
  );
};
