import { Link } from "react-router-dom";

import { useAuthContext } from "~/contexts/authContext";
import { useUIContext } from "~/contexts/uiContext";
import { LoginLayout } from "~/layouts/LoginLayout";
import { Button } from "~/components/ui/Button/Button";
import { FormCheckbox } from "~/components/ui/FormCheckbox";
import { FormInput } from "~/components/ui/FormInput";

export const LoginPage = () => {
  const { login } = useAuthContext();
  const { toast } = useUIContext();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    const data = {
      username: formData.get("username")?.toString() ?? "",
      password: formData.get("password")?.toString() ?? "",
      remember: formData.get("remember") === "on"
    };

    if (data.username && data.password) {
      login(data);
    } else {
      toast("Veuillez saisir votre nom d'utilisateur et votre mot de passe", "error");
    }
  };

  return (
    <LoginLayout>
      <h1 className="text-2xl">Connexion à votre portail</h1>

      <form onSubmit={handleSubmit} className="block w-full">
        <FormInput
          type="text"
          name="username"
          label="Nom d'utilisateur"
          placeholder="Votre nom d'utilisateur"
          className="w-full text-grey-900 [&_label]:text-white"
        />
        <FormInput
          name="password"
          type="password"
          label="Mot de passe"
          placeholder="Votre mot de passe"
          className="mt-4 w-full text-grey-900 [&_label]:text-white"
        />

        <div className="mt-4 flex justify-between text-sm">
          <FormCheckbox
            name="remember"
            label="Se souvenir de moi"
            className="[&_label]:text-white"
          />
          <Link to="/forgot-password" className="hover:underline">
            Mot de passe oublié
          </Link>
        </div>

        <Button type="submit" color="primary" fill="solid" className="mt-8 block w-full">
          Connexion
        </Button>
      </form>
    </LoginLayout>
  );
};
