import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Controller, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router";

import { queryKeys } from "~/config/query-keys-constants";
import { useUIContext } from "~/contexts/uiContext";
import { handleApiError } from "~/services/errors";
import { createEmail, getEmail, updateEmail } from "~/api/email";
import { Button } from "~/components/ui/Button/Button";
import { FormInput } from "~/components/ui/FormInput";
import { FormRichTextEditor } from "~/components/ui/FormRichTextEditor";
import { FormRow } from "~/components/ui/FormRow";
import { FormSelect } from "~/components/ui/FormSelect";
import { LoadingSpinner } from "~/components/ui/LoadingSpinner";
import { EmailSchema } from "~/schemas/email.schema";

export const CreateEditEmail = ({ editMode = false }: { editMode?: boolean }) => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const { toast } = useUIContext();
  const { email_id: emailId } = useParams<{ email_id?: string }>();

  const { data: emailData, isLoading: isEmailLoading } = useQuery({
    queryKey: [queryKeys.emails, { id: emailId }],
    queryFn: () => (emailId ? getEmail(parseInt(emailId)) : null),
    enabled: !!emailId
  });

  const emailMutation = useMutation({
    mutationFn: (data: EmailSchema) =>
      editMode && emailId ? updateEmail({ id: parseInt(emailId), data }) : createEmail(data),
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: [queryKeys.emails] });
      toast(
        editMode ? "L'Email a été modifié avec succès." : "L'Email a été créé avec succès.",
        "success"
      );
      history.push("/administration/emails");
    },
    onError(error) {
      handleApiError(error, toast);
    }
  });

  const form = useForm<EmailSchema>({
    resolver: zodResolver(EmailSchema),
    values: {
      name: emailData?.name || "",
      code: emailData?.code || "",
      subject: emailData?.subject || "",
      content: emailData?.content || "",
      isActive: emailData?.isActive || false
    }
  });

  const onSubmit = (data: EmailSchema) => {
    emailMutation.mutate(data);
  };

  if (!!emailId && isEmailLoading) {
    return <LoadingSpinner size="lg" fullPage />;
  }

  if (!!emailId && !emailData) {
    return <p className="text-center">L&apos;Email à éditer est introuvable.</p>;
  }

  return (
    <>
      <h2 className="mt-6">
        {editMode ? `Édition d'un Email : ${emailData?.name}` : "Création d'un Email"}
      </h2>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <FormRow>
          <FormInput
            label="Nom"
            errorMessage={form.formState.errors.name?.message}
            {...form.register("name")}
          />
          <FormInput
            label="Sujet"
            errorMessage={form.formState.errors.subject?.message}
            {...form.register("subject")}
          />
          <FormInput
            label="Code"
            errorMessage={form.formState.errors.code?.message}
            {...form.register("code")}
          />
          <FormSelect
            label="Actif"
            errorMessage={form.formState.errors.isActive?.message}
            {...form.register("isActive", { setValueAs: (v) => v === "true" || v === true })}
          >
            <option value="true">Oui</option>
            <option value="false">Non</option>
          </FormSelect>
        </FormRow>
        <FormRow>
          <Controller
            name="content"
            control={form.control}
            render={({ field }) => {
              return (
                <FormRichTextEditor
                  label="Contenu"
                  defaultValue={field.value}
                  editorRef={field.ref}
                  className="col-span-3"
                  {...field}
                />
              );
            }}
          />
        </FormRow>

        <div className="mt-12 flex justify-end gap-4 border-t border-grey-200 pt-6">
          <Button fill="clear" onClick={() => history.goBack()}>
            Annuler
          </Button>
          <Button type="submit" disabled={emailMutation.isLoading}>
            {editMode ? "Modifier l'Email" : "Créer l'Email"}
          </Button>
        </div>
      </form>
    </>
  );
};
