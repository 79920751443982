import type { MainTableProps } from "~/components/ui/MainTable";

import { useInfiniteQuery } from "@tanstack/react-query";
import { add as addIcon, createOutline } from "ionicons/icons";

import { queryKeys } from "~/config/query-keys-constants";
import { formatDisplayDate } from "~/utils/misc";
import { getNextPageParamFn } from "~/api/_config";
import { getJobs } from "~/api/job";
import { TableLayout } from "~/components/TableLayout";

export const AdministrationJobs = () => {
  const { data, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: [queryKeys.jobs],
    queryFn: ({ pageParam }) => getJobs({ page: pageParam }),
    getNextPageParam: getNextPageParamFn
  });

  const globalActions = [
    {
      label: "Nouveau métier",
      icon: addIcon,
      href: "/administration/jobs/create"
    }
  ];

  const tableData: MainTableProps["data"] =
    data?.pages.flat().map((job) => ({
      itemId: job?.id,
      actions: [
        {
          label: "Éditer le métier",
          icon: createOutline,
          color: "blue",
          href: `/administration/jobs/edit/${job?.id}`
        }
      ],
      columns: [
        { key: "name", label: "Nom", display: job?.name },
        {
          key: "createdAt",
          label: "Créé le",
          display: job?.createdAt ? formatDisplayDate(job.createdAt) : "",
          sort: job?.createdAt,
          search: job?.createdAt ? formatDisplayDate(job.createdAt) : ""
        }
      ]
    })) || [];

  return (
    <>
      <TableLayout
        data={tableData}
        isLoading={isFetching || isFetchingNextPage}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        globalActions={globalActions}
      />
    </>
  );
};
