import type { ExportSchema } from "~/schemas/export.schema";
import type { LogExport } from "~/types/log-export.types";
import type { DeepPartial } from "~/types/utils.types";

import { INFINITE_SCROLL_LIMIT } from "~/config/api";
import { api } from "~/api/_config";

export const getExports = async ({
  name,
  startDate,
  endDate,
  page,
  itemsPerPage
}: {
  name?: string;
  startDate?: string;
  endDate?: string;
  page?: number;
  itemsPerPage?: number;
}) => {
  const response = await api.get<DeepPartial<LogExport[]>>("/api/admin/log_exports", {
    params: {
      name,
      "startDate[after]": startDate,
      "endDate[before]": endDate,
      "order[exportedAt]": "DESC",
      page,
      itemsPerPage: itemsPerPage ?? INFINITE_SCROLL_LIMIT
    }
  });
  return response.data;
};

export const createExport = async (data: ExportSchema) => {
  const response = await api.post<DeepPartial<LogExport>>("/api/admin/log_exports", data);
  return response.data;
};
