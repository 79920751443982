import type { ComboboxInputProps } from "@headlessui/react";
import type { ChangeEvent } from "react";

import { forwardRef, Fragment, useCallback, useState } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { IonIcon } from "@ionic/react";
import {
  checkmarkOutline as checkmarkIcon,
  chevronDownOutline as chevronDownIcon
} from "ionicons/icons";

import { cn } from "~/utils/misc";
import { FormInput } from "~/components/ui/FormInput";
import { LoadingSpinner } from "~/components/ui/LoadingSpinner";

type ComboBoxOption = {
  id?: string | number;
  value: string;
  label: string;
  labelInInput?: string;
};

type FormComboBoxProps = {
  // value: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
  label?: string;
  options: ComboBoxOption[];
  defaultOption?: ComboBoxOption | null;
  errorMessage?: string | null | undefined;
  onSearchChange: (value: string) => void;
  allowCustomValue?: boolean;
  nullable?: boolean;
  isLoading?: boolean;
  by?: string;
} & ComboboxInputProps<typeof FormInput, typeof FormInput>;

export const FormComboBox = forwardRef<any, FormComboBoxProps>(function ForwardedRefComboBox(
  {
    onChange,
    onBlur,
    label,
    options,
    defaultOption,
    errorMessage,
    onSearchChange,
    allowCustomValue,
    nullable,
    isLoading,
    by,
    className = "",
    ...props
  },
  ref
) {
  const [selectedOption, setSelectedOption] = useState<ComboBoxOption | null>(
    defaultOption || null
  );

  const handleOptionSelect = useCallback(
    (option: ComboBoxOption) => {
      setSelectedOption(option);
      onChange(option.value || "");
    },
    [onChange]
  );

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    onSearchChange(event.currentTarget.value);

    if (allowCustomValue) {
      handleOptionSelect({
        id: event.currentTarget.value,
        label: event.currentTarget.value,
        value: event.currentTarget.value
      });
    }
  };

  return (
    <Combobox
      value={selectedOption}
      onChange={handleOptionSelect}
      nullable={!!nullable as any}
      by={by || "id"}
    >
      <div className={cn("relative block", className)}>
        <Combobox.Button className="block w-full text-left">
          <Combobox.Input
            as={FormInput}
            label={label || null}
            displayValue={() => selectedOption?.labelInInput || selectedOption?.label || ""}
            onBlur={onBlur}
            onChange={handleInputChange}
            errorMessage={errorMessage}
            ref={ref}
            className="w-full"
            inputClassName="pr-10"
            {...props}
            absoluteComponent={
              <>
                <div className="group absolute bottom-0 right-0 top-0 flex items-center justify-center rounded-lg p-3 focus:outline-none">
                  <IonIcon
                    icon={chevronDownIcon}
                    className="h-5 w-5 text-grey-400 transition-colors group-hover:text-grey-600"
                  />
                </div>
                <Transition
                  as={Fragment}
                  enter="transition duration-100 ease-out origin-top"
                  enterFrom="transform scale-50 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-100 ease-out origin-top"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-50 opacity-0"
                >
                  <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-lg border border-grey-200 bg-white py-1 text-sm shadow-lg">
                    {isLoading ? (
                      <Combobox.Option disabled value={null} className="select-none px-4 py-2">
                        <LoadingSpinner size="sm" />
                      </Combobox.Option>
                    ) : options.length > 0 ? (
                      options.map((option: ComboBoxOption) => (
                        <Combobox.Option
                          key={option.id}
                          value={option}
                          className={({ active }) =>
                            cn(
                              "flex cursor-pointer select-none items-center gap-3 px-4 py-2",
                              active && "bg-primary-600 text-white"
                            )
                          }
                        >
                          {({ active, selected }) => (
                            <>
                              <span className={cn("block truncate", selected && "font-semibold")}>
                                {option.label}
                              </span>
                              {selected ? (
                                <IonIcon
                                  icon={checkmarkIcon}
                                  className={cn("h-5 w-5", active ? "text-white" : "text-primary")}
                                  aria-hidden="true"
                                />
                              ) : null}
                            </>
                          )}
                        </Combobox.Option>
                      ))
                    ) : (
                      <Combobox.Option disabled value={null} className="select-none px-4 py-2">
                        Aucun résultat.
                      </Combobox.Option>
                    )}
                  </Combobox.Options>
                </Transition>
              </>
            }
          />
        </Combobox.Button>
      </div>
    </Combobox>
  );
});
