import { z } from "zod";

export const QuotationPaymentSchema = z.object({
  paymentMethod: z.string().min(1, "Veuillez renseigner un moyen de paiement."),
  amount: z.string().min(1, "Veuillez renseigner un montant."),
  logInfo: z.string().optional().nullable(),
  codeRemise: z.string().optional().nullable(),
  receivedAt: z.string().min(1, "Veuillez renseigner une date de réception du paiement."),
  overflowMoney: z.array(z.object({})).optional().nullable(),
  billedLastYear: z.boolean({
    required_error: "Veuillez renseigner si le paiement a été facturé sur l'exercice précédent.",
    invalid_type_error: "Veuillez renseigner si le paiement a été facturé sur l'exercice précédent."
  })
});

export type QuotationPaymentSchema = z.infer<typeof QuotationPaymentSchema>;
