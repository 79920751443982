import type { PropsWithChildren } from "react";
import type { ModalProps } from "~/components/ui/Modal/Modal";

import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";

import { queryKeys } from "~/config/query-keys-constants";
import { formatDisplayDate } from "~/utils/misc";
import { getQuotationRecoveries } from "~/api/quotation-recovery";
import { LoadingSpinner } from "~/components/ui/LoadingSpinner";
import { Modal } from "~/components/ui/Modal/Modal";
import { Table } from "~/components/ui/Table";

interface QuotationRecoveriesModalProps extends ModalProps {
  quotationId: number;
}

export const QuotationRecoveriesModal = ({
  quotationId,
  ...props
}: PropsWithChildren<QuotationRecoveriesModalProps>) => {
  const queryParams = {
    quotationId
  };

  const { data: recoveries, isLoading: isRecoveriesLoading } = useQuery({
    queryKey: [queryKeys.recoveryQuotations, queryParams],
    queryFn: () => getQuotationRecoveries(queryParams)
  });

  return (
    <Modal title="Actions" {...props}>
      <h2 className="text-xl font-semibold">Historiques des relances</h2>
      {isRecoveriesLoading ? (
        <LoadingSpinner fullPage />
      ) : recoveries && recoveries.length > 0 ? (
        <div className="">
          <Table
            data={{
              headers: ["Description", "Date", "Statut(s)"],
              rows: recoveries.map((r) => {
                let dateCellContent = "";
                if (!r) return { key: 0, columns: ["", ""] };

                if (r.logEmails) {
                  dateCellContent = formatDisplayDate(r.logEmails.createdAt || "");
                } else {
                  if (r.recovery?.stateQuotation === 1) {
                    if (!r.quotation?.signedAt) {
                      dateCellContent =
                        r.recovery.email || r.recovery.sms
                          ? `Sera envoyée le ${dayjs(r.quotation?.firstSendAt)
                              .add(r.recovery.whenDays || 0, "day")
                              .format("DD/MM/YYYY")}`
                          : `À partir du ${dayjs(r.quotation?.firstSendAt)
                              .add(r.recovery.whenDays || 0, "day")
                              .format("DD/MM/YYYY")}`;
                    } else {
                      dateCellContent = "Relevé déjà signé.";
                    }
                  } else if (r.recovery?.stateQuotation === 4 && r.quotation?.signedAt) {
                    dateCellContent =
                      r.recovery.email || r.recovery.sms
                        ? `Sera envoyée le ${dayjs(r.quotation.signedAt)
                            .add(r.recovery.whenDays || 0, "day")
                            .format("DD/MM/YYYY")}`
                        : `À partir du ${dayjs(r.quotation.signedAt)
                            .add(r.recovery.whenDays || 0, "day")
                            .format("DD/MM/YYYY")}`;
                  }
                }
                return {
                  key: r.recovery?.id || 0,
                  columns: [
                    r.recovery?.title,
                    dateCellContent,
                    r.logEmails?.logEmailEvents?.map((logEmailEvent, i) => (
                      <span
                        key={i}
                        className="btn btn-default btn-sm float-left mr-2"
                        title={`Le ${formatDisplayDate(logEmailEvent?.createdAt ?? "")}`}
                      >
                        {logEmailEvent?.stateSendinblue?.cleanName ||
                          logEmailEvent?.stateSendinblue?.name}
                      </span>
                    ))
                  ]
                };
              })
            }}
          />
        </div>
      ) : (
        <p>Aucune relance enregistré.</p>
      )}
    </Modal>
  );
};
