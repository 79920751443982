import type { CategoriesCooperatorsStats, StatisticsChartProps } from "~/types/statistics.types";

import { useGetStatisticsQuery } from "~/api/statistics";
import { StatisticsType } from "~/types/statistics.types";
import { PieChart } from "../charts/PieChart";
import { LoadingSpinner } from "../ui/LoadingSpinner";

export const CategoriesCooperatorsChart = (params: StatisticsChartProps) => {
  const { data, isLoading } = useGetStatisticsQuery<CategoriesCooperatorsStats>(
    StatisticsType.CATEGORIES_COOPERATOR,
    params
  );

  if (isLoading) {
    return (
      <div className="my-20 flex w-full items-center justify-center gap-4">
        <LoadingSpinner /> Chargement des données
      </div>
    );
  }

  if (!data || !data.categoriesCooperator.length) {
    return (
      <div className="my-20 flex w-full items-center justify-center gap-4">
        Pas de donnée sur la période sélectionnée
      </div>
    );
  }

  const stats = data.categoriesCooperator;
  return (
    <div className="h-[600px] py-4 sm:px-4">
      <PieChart data={stats.map((s) => ({ label: s.name, value: s.nb }))} />
    </div>
  );
};
