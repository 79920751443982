import type { PropsWithChildren } from "react";
import type { ModalProps } from "~/components/ui/Modal/Modal";
import type { Quotation } from "~/types/quotation.types.ts";

import { zodResolver } from "@hookform/resolvers/zod";
import { IonIcon } from "@ionic/react";
import { useMutation } from "@tanstack/react-query";
import { addOutline, trash } from "ionicons/icons";
import { useFieldArray, useForm } from "react-hook-form";

import { recoveryActionTypes } from "~/config/api-constants";
import { queryKeys } from "~/config/query-keys-constants";
import { useUIContext } from "~/contexts/uiContext.tsx";
import { handleApiError } from "~/services/errors";
import { queryClient } from "~/api/_queryClient.ts";
import { createMultipleQuotationRecoveryActions } from "~/api/recovery-action.ts";
import { Button } from "~/components/ui/Button/Button.tsx";
import { FormCheckbox } from "~/components/ui/FormCheckbox";
import { FormDatePicker } from "~/components/ui/FormDatePicker.tsx";
import { FormRow } from "~/components/ui/FormRow.tsx";
import { FormSelect } from "~/components/ui/FormSelect.tsx";
import { FormTextArea } from "~/components/ui/FormTextArea.tsx";
import { Modal } from "~/components/ui/Modal/Modal";
import { MultipleRecoveryQuotationsActionSchema } from "~/schemas/recovery-action.schema";

interface MultipleRecoveryQuotationsActionsModalProps extends ModalProps {
  quotationIds: Array<Quotation["id"]>;
}

export const MultipleRecoveryQuotationsActionsModal = ({
  quotationIds,
  ...props
}: PropsWithChildren<MultipleRecoveryQuotationsActionsModalProps>) => {
  const { toast } = useUIContext();

  const { control, register, handleSubmit, formState, reset } =
    useForm<MultipleRecoveryQuotationsActionSchema>({
      resolver: zodResolver(MultipleRecoveryQuotationsActionSchema),
      defaultValues: {
        actionRecoveries: [
          {
            name: "",
            comment: "",
            dateActionAt: "",
            dateActionDoneAt: ""
          }
        ],
        isFinishedTask: false
      }
    });

  const { fields, append, remove } = useFieldArray({
    control, // Lier à useForm
    name: "actionRecoveries" // Nom du tableau de champs
  });

  const createActionMutation = useMutation({
    mutationFn: (data: MultipleRecoveryQuotationsActionSchema & { isSaveAndSend: boolean }) =>
      createMultipleQuotationRecoveryActions({ quotationIds, data }),
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.quotations]);
      queryClient.invalidateQueries([queryKeys.recoveryQuotations]);
      queryClient.invalidateQueries([queryKeys.recoveriesActions]);

      reset();
      toast("Les actions ont été ajoutés à la séléction avec succès", "success");
    },
    onError(error) {
      handleApiError(error, toast);
    }
  });

  const onSubmit = ({
    isSaveAndSend,
    data
  }: {
    isSaveAndSend: boolean;
    data: MultipleRecoveryQuotationsActionSchema;
  }) => {
    createActionMutation.mutate({ isSaveAndSend, ...data });
  };

  return (
    <Modal title="Actions" {...props}>
      <h2 className="text-xl font-semibold">Ajouter des actions à la séléction</h2>
      <>
        <form className="mt-4">
          <div className="flex flex-col gap-4">
            {fields.map((field, index) => (
              <div className={"rounded-lg border border-grey-100 bg-neutral-50 p-3"} key={field.id}>
                <FormRow className="first:mt-0 lg:grid-cols-2">
                  <FormSelect
                    label="Nom de l'action"
                    errorMessage={formState.errors.actionRecoveries?.[index]?.name?.message}
                    {...register(`actionRecoveries.${index}.name`)}
                  >
                    <option value="" disabled>
                      Choisir une action
                    </option>
                    {Object.entries(recoveryActionTypes).map(([key, value]) => (
                      <option key={key} value={value}>
                        {value}
                      </option>
                    ))}
                  </FormSelect>

                  {index > 0 ? (
                    <div className={"flex justify-end"}>
                      <Button
                        onClick={() => remove(index)}
                        className={"mt-4"}
                        color={"danger"}
                        fill={"clear"}
                      >
                        <IonIcon icon={trash} /> Supprimer l&#39;action
                      </Button>
                    </div>
                  ) : null}
                </FormRow>
                <FormRow className="lg:grid-cols-2">
                  <FormDatePicker
                    label="Date prévue"
                    errorMessage={formState.errors.actionRecoveries?.[index]?.dateActionAt?.message}
                    {...register(`actionRecoveries.${index}.dateActionAt`)}
                  />
                  <FormDatePicker
                    label="Effectuée le"
                    errorMessage={
                      formState.errors.actionRecoveries?.[index]?.dateActionDoneAt?.message
                    }
                    {...register(`actionRecoveries.${index}.dateActionDoneAt`)}
                  />
                </FormRow>
                <FormRow className="lg:grid-cols-2">
                  <FormTextArea
                    label="Commentaire"
                    className="col-span-2"
                    rows={4}
                    errorMessage={formState.errors.actionRecoveries?.[index]?.comment?.message}
                    {...register(`actionRecoveries.${index}.comment`)}
                  />
                </FormRow>
              </div>
            ))}
          </div>
          <Button
            fill="outline"
            onClick={() =>
              append({
                name: "",
                comment: "",
                dateActionAt: "",
                dateActionDoneAt: ""
              })
            }
            className={"mt-6"}
          >
            <IonIcon icon={addOutline} /> Ajouter une action
          </Button>

          <FormCheckbox
            label="Clôturer les tâches précédentes"
            className="mt-6"
            {...register("isFinishedTask")}
          />
          <div className="mt-8 flex flex-wrap justify-between gap-8 border-t border-grey-200 pt-6">
            <Button fill="clear" onClick={() => props.onClose()}>
              Annuler
            </Button>
            <div className="flex flex-wrap items-center gap-4">
              <Button
                type="button"
                onClick={handleSubmit((data) => onSubmit({ isSaveAndSend: true, data }))}
                disabled={createActionMutation.isLoading}
              >
                Enregistrer et envoyer l&apos;historique au coopérateur
              </Button>
              <Button
                type="button"
                onClick={handleSubmit((data) => onSubmit({ isSaveAndSend: false, data }))}
                disabled={createActionMutation.isLoading}
              >
                Enregistrer
              </Button>
            </div>
          </div>
        </form>
      </>
    </Modal>
  );
};
