import type { DeepPartial } from "react-hook-form";
import type { Quotation } from "~/types/quotation.types";

import { quotationCoopPaymentStates, quotationStates } from "~/config/api-constants";

export const isQuotationEditable = ({
  quotation,
  isAdmin
}: {
  quotation: DeepPartial<Quotation> | null | undefined;
  isAdmin: boolean;
}) => {
  if (!quotation) return false;

  return (
    (isAdmin &&
      (!quotation.isAvanceImmediate ||
        quotation.state === quotationStates.STATE_DRAFT ||
        quotation.state === quotationStates.STATE_INSTANT_ADVANCE_FRAUD)) ||
    (quotation.isAvanceImmediate &&
      (quotation.state === quotationStates.STATE_DRAFT ||
        quotation.state === quotationStates.STATE_INSTANT_ADVANCE_FRAUD)) ||
    (!quotation.isAvanceImmediate &&
      (quotation.state === quotationStates.STATE_DRAFT ||
        quotation.state === quotationStates.STATE_INSTANT_ADVANCE_FRAUD ||
        quotation.state === quotationStates.STATE_SEND))
  );
};

export const isQuotationDeletable = ({
  quotation,
  isAdmin
}: {
  quotation: DeepPartial<Quotation> | null | undefined;
  isAdmin: boolean;
}) => {
  if (!quotation) return false;

  return (
    isQuotationEditable({ quotation, isAdmin }) &&
    quotation.statePaymentCooperator !== quotationCoopPaymentStates.STATE_PAYMENT_COOP_PAID
  );
};

export const convertReccurenceToTasks = ({
  isRecurrenceMaster,
  startAt,
  endAt,
  period,
  hourTask
}: {
  isRecurrenceMaster: boolean;
  startAt: string | null | undefined;
  endAt: string | null | undefined;
  period: string | null | undefined;
  hourTask: string | null | undefined;
}) => {
  if (!isRecurrenceMaster || !startAt || !endAt || !period || !hourTask) {
    return [];
  }

  const tasks: {
    scheduledAt: string;
  }[] = [];

  const currentDate = new Date(startAt);
  const endDate = new Date(endAt);

  while (currentDate <= endDate) {
    // Set the hour for the task
    currentDate.setHours(
      parseInt(hourTask.split(":")[0] || "10"),
      parseInt(hourTask.split(":")[1] || "0")
    );

    // Create the task
    const task = {
      scheduledAt: currentDate.toISOString()
    };

    // Add the task to the list
    tasks.push(task);

    // Increment currentDate based on period
    switch (period) {
      case "once-a-month":
        currentDate.setMonth(currentDate.getMonth() + 1);
        break;
      case "every-2-months":
        currentDate.setMonth(currentDate.getMonth() + 2);
        break;
      case "four-times-a-year":
        currentDate.setMonth(currentDate.getMonth() + 3);
        break;
      case "twice-a-year":
        currentDate.setMonth(currentDate.getMonth() + 6);
        break;
      case "once-a-year":
        currentDate.setFullYear(currentDate.getFullYear() + 1);
        break;
    }
  }

  return tasks;
};
