import type { Membership } from "~/types/membership.types";
import type { Quotation } from "~/types/quotation.types";
import type { PayQuotationByCreditCardResponse } from "~/types/sign-payment.types";
import type { AnonymousUser } from "~/types/user.types";

import { api } from "~/api/_config";

export const anonymousGetQuotation = async ({ hash }: { hash: string }) => {
  const response = await api.get<Partial<Quotation>>(`/api/_anon/quotations/hash/${hash}`);
  return response.data;
};

export const anonymousGetMembership = async ({ hash }: { hash: string }) => {
  const response = await api.get<Membership>(`/api/_anon/memberships/hash/${hash}`);
  return response.data;
};

export const anonymousPayQuotationByCreditCard = async ({ id }: { id: number }) => {
  const response = await api.post<PayQuotationByCreditCardResponse>(`/api/_anon/pay/cb/${id}`, {});
  return response.data;
};

export const anonymousPayMembershipByCreditCard = async ({ id }: { id: number }) => {
  const response = await api.post<PayQuotationByCreditCardResponse>(
    `/api/_anon/pay/membership/cb/${id}`,
    {}
  );
  return response.data;
};

export const anonymousSignQuotation = async ({
  hash,
  paymentMethod
}: {
  hash: string;
  paymentMethod: string;
}) => {
  const response = await api.post<Quotation>(`/api/_anon/sign/${hash}`, {
    paymentMethod
  });
  return response.data;
};

export const anonymousSignMembership = async ({
  hash,
  paymentMethod
}: {
  hash: string;
  paymentMethod: string;
}) => {
  const response = await api.post<Membership>(`/api/_anon/sign/membership/${hash}`, {
    paymentMethod
  });
  return response.data;
};

export const anonymousSendPrepaidCode = async ({ id, code }: { id: number; code: string }) => {
  const response = await api.post<Quotation>(`/api/_anon/pay/cartePre/${id}`, {
    code
  });
  return response.data;
};

export const anonymousValidatePrepaidCode = async ({ id, code }: { id: number; code: string }) => {
  const response = await api.post<Quotation>(`/api/_anon/pay/cartePre/${id}/auth`, {
    code
  });
  return response.data;
};

export const anonymousGetUser = async ({ hash }: { hash: string }) => {
  const response = await api.get<AnonymousUser>(`/api/_anon/users/hash/${hash}`);
  return response.data;
};
