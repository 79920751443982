import type { UseFormRegister } from "react-hook-form";

import dayjs from "dayjs";

import { transformDateTimeToDate } from "~/utils/misc";
import { FormDatePicker } from "~/components/ui/FormDatePicker";
import { FormInput } from "~/components/ui/FormInput";

export const PreFinancingPaidContractsSearchForm = ({
  register
}: {
  register: UseFormRegister<any>;
}) => {
  return (
    <>
      <FormDatePicker
        label="Date min"
        defaultValue={transformDateTimeToDate(dayjs().subtract(3, "year").toISOString()) || ""}
        optional
        {...register("createdAtAfter")}
      />
      <FormDatePicker
        label="Date max"
        defaultValue={transformDateTimeToDate(dayjs().toISOString()) || ""}
        optional
        {...register("createdAtBefore")}
      />
      <FormInput
        label="Bénéficiaire"
        type="text"
        placeholder="Nom, prénom ou société"
        optional
        {...register("customer")}
      />
      <FormInput
        label="Financeur"
        type="text"
        placeholder="Nom, prénom ou société"
        optional
        {...register("cooperator")}
      />
      <FormInput
        label="N° de contrat"
        type="text"
        placeholder="6 derniers chiffres (ex: 231887)"
        optional
        {...register("id")}
      />
      <FormInput
        label="N° de facture"
        type="text"
        placeholder="6 derniers chiffres (ex: 231887)"
        optional
        {...register("idFacture")}
      />
      <FormInput
        label="Montant TTC min (€)"
        type="number"
        placeholder="ex: 1000"
        optional
        {...register("minTotalTTC")}
      />
      <FormInput
        label="Montant TTC max (€)"
        type="number"
        placeholder="ex: 1500"
        optional
        {...register("maxTotalTTC")}
      />
      <FormDatePicker label="Payé le" optional {...register("receivedAt")} />
    </>
  );
};
