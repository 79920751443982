import { useMutation } from "@tanstack/react-query";
import { Link } from "react-router-dom";

import { useUIContext } from "~/contexts/uiContext";
import { handleApiError } from "~/services/errors";
import { requestForgottenPassword } from "~/api/auth";
import { LoginLayout } from "~/layouts/LoginLayout";
import { Alert } from "~/components/ui/Alert";
import { Button } from "~/components/ui/Button/Button";
import { FormInput } from "~/components/ui/FormInput";

export const ForgotPasswordPage = () => {
  const { toast } = useUIContext();

  const mutation = useMutation({
    mutationFn: requestForgottenPassword,
    onSuccess: () => {
      toast("Un email pour récupérer votre mot de passe vient d'être envoyé", "success");
    },
    onError(error) {
      handleApiError(error, toast);
    }
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    const email = formData.get("email")?.toString() ?? "";

    if (email) {
      mutation.mutate({ email });
    } else {
      toast("Veuillez saisir votre adresse email", "error");
    }
  };

  return (
    <LoginLayout>
      <h1 className="text-2xl">Mot de passe oublié</h1>

      <form onSubmit={handleSubmit} className="block w-full">
        <FormInput
          type="email"
          name="email"
          label="Adresse email associée à votre compte"
          placeholder="Votre email"
          className="w-full text-grey-900 [&_label]:text-white"
        />

        <Alert
          className="mt-4 bg-blue-600 bg-opacity-50 text-center"
          iconClassName="text-white"
          text={
            <>
              En cas d&apos;oubli de votre adresse email, merci de contacter la coopérative du lundi
              au vendredi de 9h à 18h au
              <br />
              04 15 40 00 00
            </>
          }
        />
        <div className="mt-4 flex justify-between text-sm">
          <Link to="/login" className="hover:underline">
            Se connecter
          </Link>
        </div>

        <Button type="submit" color="primary" fill="solid" className="mt-8 block w-full">
          Valider
        </Button>
      </form>
    </LoginLayout>
  );
};
