import type { MainTableProps } from "~/components/ui/MainTable";

import { useInfiniteQuery } from "@tanstack/react-query";
import { createOutline } from "ionicons/icons";

import { queryKeys } from "~/config/query-keys-constants";
import { getNextPageParamFn } from "~/api/_config";
import { getModules } from "~/api/module";
import { TableLayout } from "~/components/TableLayout";

export const AdministrationModules = () => {
  const { data, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: [queryKeys.modules],
    queryFn: ({ pageParam }) => getModules({ page: pageParam }),
    getNextPageParam: getNextPageParamFn
  });

  const tableData: MainTableProps["data"] =
    data?.pages.flat().map((module) => ({
      itemId: module?.id,
      actions: [
        {
          label: "Éditer le module",
          icon: createOutline,
          color: "blue",
          href: `/administration/modules/edit/${module?.id}`
        }
      ],
      columns: [
        { key: "code", label: "Code", display: module?.code },
        { key: "name", label: "Nom", display: module?.name }
      ]
    })) || [];

  return (
    <>
      <TableLayout
        data={tableData}
        isLoading={isFetching || isFetchingNextPage}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
      />
    </>
  );
};
