import type {
  ComponentPropsWithoutRef,
  MouseEventHandler,
  PropsWithChildren,
  ReactNode
} from "react";

import { useState } from "react";
import { Transition } from "@headlessui/react";

import { cn } from "~/utils/misc";

type TooltipProps = PropsWithChildren<{
  content: ReactNode;
  contentClassName?: string;
}> &
  Omit<ComponentPropsWithoutRef<"div">, "content">;

// Tooltip component
export const Tooltip = ({
  children,
  content,
  className,
  contentClassName,
  ...props
}: TooltipProps) => {
  const [show, setShow] = useState(false);

  const showTooltip: MouseEventHandler<HTMLDivElement> = () => {
    setShow(true);
  };

  const hideTooltip = () => {
    setShow(false);
  };

  return (
    <div
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
      className={cn("relative", className)}
      {...props}
    >
      {children}

      <Transition
        className={cn(
          "absolute bottom-full left-1/2 z-10 mb-1 w-fit -translate-x-1/2 text-nowrap rounded-md border border-grey-200 bg-white p-2 shadow-sm",
          contentClassName
        )}
        show={show}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        {content}
      </Transition>
    </div>
  );
};
