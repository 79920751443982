import type { User } from "~/types/user.types";
import type { DeepPartial } from "~/types/utils.types";

import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";

import { queryKeys } from "~/config/query-keys-constants";
import { useUIContext } from "~/contexts/uiContext";
import { handleApiError } from "~/services/errors";
import { updateCooperator } from "~/api/user";
import { Button } from "~/components/ui/Button/Button";
import { FormInput } from "~/components/ui/FormInput";
import { FormRow } from "~/components/ui/FormRow";
import { ProfileQuotationHeadersSchema } from "~/schemas/profile.schema";

export const ProfileQuotationHeaders = ({ userData }: { userData?: DeepPartial<User> }) => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const { toast } = useUIContext();

  // Customer mutation
  const mutation = useMutation({
    mutationFn: (data: ProfileQuotationHeadersSchema) =>
      updateCooperator({ userId: userData?.id ? userData.id : null, data }),
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: [queryKeys.users] });
      toast("En-tête mise à jour avec succès.", "success");
    },
    onError(error) {
      handleApiError(error, toast);
    }
  });

  // Define form
  const form = useForm<ProfileQuotationHeadersSchema>({
    resolver: zodResolver(ProfileQuotationHeadersSchema),
    values: {
      header1: userData?.header1 || "",
      header2: userData?.header2 || "",
      header3: userData?.header3 || "",
      header4: userData?.header4 || "",
      header5: userData?.header5 || "",
      header6: userData?.header6 || ""
    }
  });

  const onSubmit = (data: ProfileQuotationHeadersSchema) => {
    const formatedData = {
      ...data,
      userId: userData?.id ? userData.id : null
    };

    mutation.mutate(formatedData);
  };

  return (
    <>
      <h2 className="mt-6">En-tête relevés</h2>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <p className="text-sm text-grey-600">
          Laissez les champs souhaités vides pour conserver les informations de base.
        </p>
        <FormRow>
          <div className="col-span-1">
            <h3 className="mb-4 text-base font-medium leading-none text-grey-900">Mon en-tête</h3>
            <div className="flex flex-col gap-1">
              <FormInput
                placeholder="Nom de l'entreprise"
                errorMessage={form.formState.errors.header1?.message}
                {...form.register("header1")}
              />
              <FormInput
                placeholder="Nom et Prénom"
                errorMessage={form.formState.errors.header2?.message}
                {...form.register("header2")}
              />
              <FormInput
                placeholder="Adresse"
                errorMessage={form.formState.errors.header3?.message}
                {...form.register("header3")}
              />
              <FormInput
                placeholder="Code Postal et Ville"
                errorMessage={form.formState.errors.header4?.message}
                {...form.register("header4")}
              />
              <FormInput
                placeholder="Email"
                errorMessage={form.formState.errors.header5?.message}
                {...form.register("header5")}
              />
              <FormInput
                placeholder="Téléphone(s)"
                errorMessage={form.formState.errors.header6?.message}
                {...form.register("header6")}
              />
            </div>
          </div>
          <div className="col-span-1">
            <h3 className="mb-4 text-base font-medium leading-none text-grey-900">Aperçu</h3>
            <div className="space-y-1.5 rounded-lg border border-grey-100 bg-grey-50 p-4 text-sm">
              <div className="h-5">{form.watch("header1") || userData?.companyName}</div>
              <div className="h-5">
                {form.watch("header2") || `${userData?.lastname} ${userData?.firstname}`}
              </div>
              <div className="h-5">{form.watch("header3") || userData?.address}</div>
              <div className="h-5">
                {form.watch("header4") || `${userData?.zipcode} ${userData?.city}`}
              </div>
              <div className="h-5">{form.watch("header5") || userData?.email}</div>
              <div className="h-5">
                {form.watch("header6") ||
                  `${userData?.fixeNumber ? `${userData.fixeNumber} - ` : ""}${
                    userData?.mobileNumber || ""
                  }`}
              </div>
            </div>
          </div>
          <div className="col-span-1">
            <h3 className="mb-4 text-base font-medium leading-none text-grey-900">
              En tête par défaut
            </h3>
            <div className="space-y-1.5 rounded-lg border border-grey-100 bg-grey-50 p-4 text-sm">
              <div className="h-5">{userData?.companyName}</div>
              <div className="h-5">
                {userData?.lastname} {userData?.firstname}
              </div>
              <div className="h-5">{userData?.address}</div>
              <div className="h-5">
                {userData?.zipcode} {userData?.city}
              </div>
              <div className="h-5">{userData?.email}</div>
              <div className="h-5">
                {userData?.fixeNumber ? `${userData.fixeNumber} - ` : ""}{" "}
                {userData?.mobileNumber || ""}
              </div>
            </div>
          </div>
        </FormRow>

        <div className="mt-12 flex justify-end gap-4 border-t border-grey-200 pt-6">
          <Button fill="clear" onClick={() => history.goBack()}>
            Annuler
          </Button>
          <Button type="submit" disabled={mutation.isLoading}>
            Enregistrer
          </Button>
        </div>
      </form>
    </>
  );
};
