import type { PropsWithChildren } from "react";
import type { User } from "~/types/user.types";

import { createContext, useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import * as jose from "jose";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";

import { connectedUserRoles } from "~/config/api-constants";
import { COOKIE_REFRESH_TOKEN, COOKIE_TOKEN, COOKIE_USER_ID } from "~/config/cookies-constants";
import { useUIContext } from "~/contexts/uiContext";
import { handleApiError } from "~/services/errors";
import { requestLogin } from "~/api/auth";
import { getUser } from "~/api/user";
import { LoadingSpinner } from "~/components/ui/LoadingSpinner";

type AuthContextProps = {
  token: string | null;
  refreshToken: string | null;
  login: (_data: { username: string; password: string; remember: boolean }) => void;
  logout: () => void;
  currentUser: Partial<User> | null;
  isAuth: boolean;
  isAdmin: boolean;
};

const AuthContext = createContext<AuthContextProps | null>(null);

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const history = useHistory();
  const { setGlobalLoading, toast } = useUIContext();
  const [isAuthLoading, setIsAuthLoading] = useState(true);
  const [token, setToken] = useState(Cookies.get(COOKIE_TOKEN) || null);
  const [refreshToken, setRefreshToken] = useState(Cookies.get(COOKIE_REFRESH_TOKEN) || null);
  const [currentUserId, setCurrentUserId] = useState(
    parseInt(Cookies.get(COOKIE_USER_ID) || "") || null
  );
  const [currentUser, setCurrentUser] = useState<Partial<User> | null>(null);

  useEffect(() => {
    // If there's a user ID in the cookie but currentUser is null, then the user data hasn't been fetched yet.
    if (currentUserId && !currentUser) {
      return;
    }
    // Once it's fetched, set loading to false.
    // If there's no user ID in the cookie or the user data has been fetched (either exists or null), set loading to false.
    setIsAuthLoading(false);
  }, [currentUserId, currentUser]);

  const loginMutation = useMutation(requestLogin, {
    onError(error) {
      handleApiError(error, toast);
    },
    onSettled: () => setGlobalLoading({ isLoading: false })
  });

  const { data: userData, isFetching } = useQuery({
    queryKey: ["current-user", currentUserId],
    queryFn: () => getUser({ id: currentUserId || 0 }),
    enabled: !!currentUserId,
    onSuccess: setCurrentUser,
    onError(error) {
      handleApiError(error, toast);
    },
    onSettled: () => setGlobalLoading({ isLoading: false })
  });

  const login = async ({
    username,
    password
    // remember
  }: {
    username: string;
    password: string;
    // remember: boolean;
  }) => {
    try {
      setGlobalLoading({ isLoading: true });

      const { token, refresh_token } = await loginMutation.mutateAsync({ username, password });
      const decodedToken = jose.decodeJwt(token ?? "") as { id: string } | null | undefined;

      setToken(token);
      setRefreshToken(refresh_token);
      setCurrentUserId(decodedToken ? parseInt(decodedToken.id) : null);
      setCurrentUser(userData || null);

      // const cookieOptions = remember ? { expires: 14 } : undefined;
      Cookies.set(COOKIE_TOKEN, token ?? "", { expires: 14 });
      Cookies.set(COOKIE_REFRESH_TOKEN, refresh_token ?? "", { expires: 14 });
      Cookies.set(COOKIE_USER_ID, decodedToken?.id || "", { expires: 14 });

      history.push("/");
    } catch (error: any) {
      toast("Erreur lors de la connexion: " + error.message, "error");
    }
  };

  const logout = () => {
    setToken(null);
    setRefreshToken(null);
    setCurrentUserId(null);
    setCurrentUser(null);
    Cookies.remove(COOKIE_TOKEN);
    Cookies.remove(COOKIE_REFRESH_TOKEN);
    Cookies.remove(COOKIE_USER_ID);
    history.push("/");
  };

  if (isAuthLoading || isFetching) {
    return (
      <div className="flex h-screen items-center justify-center">
        <LoadingSpinner size="lg" />
      </div>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        token,
        refreshToken,
        login,
        logout,
        currentUser,
        isAuth: !!currentUserId,
        isAdmin: currentUser?.roles?.includes(connectedUserRoles.ROLE_ADMIN) || false
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuthContext must be used within a AuthProvider");
  }

  return context;
};
