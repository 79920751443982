import type { ChartData } from "chart.js";

import {
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  TimeScale,
  Tooltip
} from "chart.js";
import { Line } from "react-chartjs-2";

import "chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm";

import { formatMoney } from "~/utils/misc";

// Register used modules
ChartJS.register(
  LineController,
  LineElement,
  PointElement,
  Legend,
  LinearScale,
  TimeScale,
  Tooltip
);

interface Props {
  datasets: ChartData<"line", Record<string, number>>["datasets"];
}

export const DailyLineChart = ({ datasets }: Props) => {
  return (
    <Line
      options={{
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            type: "time",
            time: {
              tooltipFormat: "DD/MM/YYYY",
              displayFormats: {
                day: "D MMM"
              },
              unit: "day"
            }
          },
          y: {
            min: 0
          }
        },
        plugins: {
          legend: {
            position: "bottom"
          },
          tooltip: {
            callbacks: {
              label: (tooltipItem) =>
                `${tooltipItem.dataset.label} : ${formatMoney(tooltipItem.parsed.y)}`
            }
          }
        }
      }}
      data={{
        datasets
      }}
    />
  );
};
