import type { ComponentProps } from "react";

import { useQuery } from "@tanstack/react-query";

import { queryKeys } from "~/config/query-keys-constants";
import { cn } from "~/utils/misc";
import { getHomeStatistics } from "~/api/statistics";
import documentIcon from "~/assets/icons/document.svg";
import dollarIcon from "~/assets/icons/dollar.svg";
import moneyIcon from "~/assets/icons/money.svg";
import pencilIcon from "~/assets/icons/pencil.svg";
import { StatusPaymentCard } from "./StatusPaymentCard";

export const StatusPayment = ({ className, ...props }: ComponentProps<"div">) => {
  const { data: statsData, isLoading: isStatsLoading } = useQuery({
    queryKey: [queryKeys.statisticsGlobal],
    queryFn: () => getHomeStatistics()
  });

  return (
    <div className={cn("", className)} {...props}>
      <h2 className="text-base font-medium text-grey-600">Statistiques du jour :</h2>
      <div className="mt-2 grid grid-cols-1 grid-rows-5 gap-4 md:grid-cols-2 md:grid-rows-3 lg:grid-cols-3 lg:grid-rows-2">
        <StatusPaymentCard
          title="En attente de signature"
          icon={pencilIcon}
          amount={statsData?.totalToSign || 0}
          loading={isStatsLoading}
        />

        <StatusPaymentCard
          title="En attente de paiement"
          icon={dollarIcon}
          amount={statsData?.totalToPay || 0}
          loading={isStatsLoading}
        />

        <StatusPaymentCard
          title="Total paiements + signatures"
          icon={moneyIcon}
          amount={(statsData?.totalToSign || 0) + (statsData?.totalToPay || 0)}
          loading={isStatsLoading}
        />

        <StatusPaymentCard
          title="Total brouillons"
          icon={documentIcon}
          amount={statsData?.totalDraft || 0}
          loading={isStatsLoading}
        />

        <StatusPaymentCard
          title="Montant total des prestations"
          amount={
            (statsData?.totalToSign || 0) +
            (statsData?.totalToPay || 0) +
            (statsData?.totalDraft || 0)
          }
          highlightTotal={true}
          loading={isStatsLoading}
          className="md:col-span-2 lg:col-start-3 lg:row-span-2 lg:row-start-1"
        />
      </div>
    </div>
  );
};
