import { IonContent, IonIcon, IonPage } from "@ionic/react";
import { alertCircleOutline } from "ionicons/icons";

export const AnonymousPayCbError = () => {
  return (
    <IonPage>
      <IonContent>
        <div className="flex h-full w-full items-center justify-center bg-grey-100 p-4 sm:p-8">
          <div className="mx-auto max-w-6xl rounded-lg border border-grey-100 bg-white px-4 py-4 shadow-md sm:px-8">
            <h1 className="m-0 border-b border-b-grey-100 pb-4 text-center text-lg font-semibold uppercase">
              Erreur de paiement par carte bleue
            </h1>

            <IonIcon
              className="mx-auto mt-4 block h-12 w-12 text-danger-500"
              icon={alertCircleOutline}
            />

            <p className="mt-4 max-w-2xl text-center">
              Un problème a été rencontré durant le processus de paiement de votre relevé.
              <br />
              Pour une nouvelle tentative de paiement, merci de cliquer de nouveau sur le lien dans
              le mail ou SMS que vous avez reçu ou de contacter nos services au :<br />
              <span className="font-medium">04 15 40 00 00</span>
            </p>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
