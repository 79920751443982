import type { UseFormRegister } from "react-hook-form";

import { FormInput } from "~/components/ui/FormInput";

export const PreFinancingBeneficiariesSearchForm = ({
  register
}: {
  register: UseFormRegister<any>;
}) => {
  return (
    <>
      <FormInput label="Nom du bénéficiaire" type="text" optional {...register("lastname")} />
      <FormInput label="Email du bénéficiaire" type="text" optional {...register("email")} />
      <FormInput label="Nom du financeur" type="text" optional {...register("financerLastname")} />
    </>
  );
};
