import type { UserRole } from "~/config/api-constants";
import type { AdminSchema } from "~/schemas/admin.schema";
import type { BeneficiarySchema } from "~/schemas/beneficiary.schema";
import type { CommercialSchema } from "~/schemas/commercial.schema";
import type { CooperatorSchema } from "~/schemas/cooperator.schema";
import type { CustomerSchema } from "~/schemas/customer.schema";
import type { FinancerSchema } from "~/schemas/financer.schema";
import type { ImportCustomersSchema } from "~/schemas/import-customers.schema";
import type {
  ProfileAccountingExportSchema,
  ProfileInformationsSchema,
  ProfilePasswordSchema,
  ProfileQuotationHeadersSchema
} from "~/schemas/profile.schema";
import type { User } from "~/types/user.types";
import type { DeepPartial } from "~/types/utils.types";

import { INFINITE_SCROLL_LIMIT } from "~/config/api";
import { userRoles } from "~/config/api-constants";
import { api } from "./_config";

export const getUsers = async ({
  role,
  createdAtAfter,
  createdAtBefore,
  internRefCoop,
  accountNumber,
  cooperatorLastname,
  cooperatorFirstname,
  cooperatorCompanyName,
  financerLastname,
  beneficiariesLastname,
  commercialLastname,
  cooperatorId,
  financerId,
  companyName,
  lastname,
  firstname,
  searchName,
  email,
  address,
  zipcode,
  city,
  mobileNumber,
  fixeNumber,
  jobName,
  isPro,
  isAvanceImmediate,
  isArchived = false,
  search,
  sort,
  page,
  itemsPerPage,
  signal
}: {
  role?: UserRole;
  createdAtAfter?: string;
  createdAtBefore?: string;
  internRefCoop?: string;
  accountNumber?: string;
  cooperatorLastname?: string;
  cooperatorFirstname?: string;
  cooperatorCompanyName?: string;
  financerLastname?: string;
  beneficiariesLastname?: string;
  commercialLastname?: string;
  cooperatorId?: number | null;
  financerId?: number | null;
  companyName?: string;
  lastname?: string;
  firstname?: string;
  searchName?: string;
  email?: string;
  address?: string;
  zipcode?: string;
  city?: string;
  mobileNumber?: string;
  fixeNumber?: string;
  jobName?: string;
  isPro?: string;
  isAvanceImmediate?: string;
  isArchived?: boolean;
  search?: string;
  sort?: { key: string; value: "ASC" | "DESC" };
  page?: number;
  itemsPerPage?: number;
  signal?: AbortSignal;
}) => {
  const params = {
    ...(role && { roles: role }),
    ...(createdAtAfter && { "createdAt[after]": createdAtAfter }),
    ...(createdAtBefore && { "createdAt[before]": createdAtBefore + "T23:59:59" }),
    ...(internRefCoop && { internRefCoop }),
    ...(accountNumber && { accountNumber }),
    ...(cooperatorLastname && { "cooperator.lastname": cooperatorLastname }),
    ...(cooperatorFirstname && { "cooperator.firstname": cooperatorFirstname }),
    ...(cooperatorCompanyName && { "cooperator.companyName": cooperatorCompanyName }),
    ...(financerLastname && { "financer.lastname": financerLastname }),
    ...(beneficiariesLastname && { "beneficiaries.lastname": beneficiariesLastname }),
    ...(commercialLastname && { "commercial.lastname": commercialLastname }),
    ...(cooperatorId && { "cooperator.id": cooperatorId }),
    ...(financerId && { "financer.id": financerId }),
    ...(companyName && { companyName }),
    ...(lastname && { lastname }),
    ...(firstname && { firstname }),
    ...(searchName && { q: searchName }),
    ...(email && { email }),
    ...(address && { address }),
    ...(zipcode && { zipcode }),
    ...(city && { city }),
    ...(mobileNumber && { mobileNumber }),
    ...(fixeNumber && { fixeNumber }),
    ...(jobName && { "job.name": jobName }),
    ...(isPro && { isPro: isPro === "true" ? true : isPro === "false" ? false : null }),
    ...(isAvanceImmediate && {
      isAvanceImmediate:
        isAvanceImmediate === "true" ? true : isAvanceImmediate === "false" ? false : null
    }),
    ...(search && { q: search }),
    isArchived: isArchived ? true : false,
    ...(sort && { [`order[${sort.key}]`]: sort.value }),
    page,
    itemsPerPage: itemsPerPage ?? INFINITE_SCROLL_LIMIT
  };
  const response = await api.get<DeepPartial<User[]>>("/api/users", { params, signal });

  return response.data;
};

export const getUser = async ({ id }: { id: User["id"] | null }) => {
  if (!id) return null;
  const response = await api.get<Partial<User>>(`/api/users/${id}`);
  return response.data;
};

export const createAdmin = async ({ data }: { data: AdminSchema }) => {
  const response = await api.post(`/api/admin/users/create-admin`, data);
  return response.data;
};

export const createClient = async ({ data }: { data: CustomerSchema }) => {
  const response = await api.post(`/api/admin/users/create-client`, {
    ...data,
    role: userRoles.ROLE_CUSTOMER
  });
  return response.data;
};

export const createCommercial = async ({ data }: { data: CommercialSchema }) => {
  const response = await api.post(`/api/admin/users/create-commercial`, data);
  return response.data;
};

export const createCooperator = async ({ data }: { data: CooperatorSchema }) => {
  const response = await api.postForm(`/api/admin/users/create-cooperator`, data);
  return response.data;
};

export const createFinancer = async ({ data }: { data: FinancerSchema }) => {
  const response = await api.post(`/api/admin/users/create-financer`, data);
  return response.data;
};

export const createBeneficiary = async ({ data }: { data: BeneficiarySchema }) => {
  const response = await api.post(`/api/admin/users/create-beneficiary`, data);
  return response.data;
};

export const updateAdmin = async ({
  userId,
  data
}: {
  userId: User["id"] | null;
  data: AdminSchema;
}) => {
  if (!userId) return null;
  const response = await api.put(`/api/admin/users/update-admin/${userId}`, data);
  return response.data;
};

export const updateClient = async ({
  userId,
  data
}: {
  userId: User["id"] | null;
  data: CustomerSchema;
}) => {
  if (!userId) return null;
  const response = await api.put(`/api/admin/users/update-client/${userId}`, data);
  return response.data;
};

export const updateCommercial = async ({
  userId,
  data
}: {
  userId: User["id"] | null;
  data: CommercialSchema;
}) => {
  if (!userId) return null;
  const response = await api.put(`/api/admin/users/update-commercial/${userId}`, data);
  return response.data;
};

export const updateCooperator = async ({
  userId,
  data
}: {
  userId: User["id"] | null;
  data:
    | CooperatorSchema
    | ProfileInformationsSchema
    | ProfileQuotationHeadersSchema
    | ProfileAccountingExportSchema
    | Omit<ProfilePasswordSchema, "passwordConfirmation">;
}) => {
  if (!userId) throw new Error("User ID is required");

  const response = await api.postForm(`/api/admin/users/update-cooperator/${userId}`, data);
  return response.data;
};

export const updateFinancer = async ({
  userId,
  data
}: {
  userId: User["id"] | null;
  data: FinancerSchema;
}) => {
  if (!userId) return null;
  const response = await api.put(`/api/admin/users/update-financer/${userId}`, data);
  return response.data;
};

export const disableFinancer = async (userId: User["id"]) => {
  const response = await api.put(`/api/admin/users/update-financer/${userId}`, {
    deleted: true
  });
  return response.data;
};

export const updateBeneficiary = async ({
  userId,
  data
}: {
  userId: User["id"] | null;
  data: BeneficiarySchema;
}) => {
  if (!userId) return null;
  const response = await api.put(`/api/admin/users/update-beneficiary/${userId}`, data);
  return response.data;
};

export const archiveCustomer = async (userId: User["id"]) => {
  const response = await api.put(`/api/admin/users/update-client/${userId}`, {
    isArchived: true
  });
  return response.data;
};

export const unarchiveCustomer = async (userId: User["id"]) => {
  const response = await api.put(`/api/admin/users/update-client/${userId}`, {
    isArchived: false
  });
  return response.data;
};

export const deleteCustomer = async (userId: User["id"]) => {
  const response = await api.put(`/api/admin/users/update-client/${userId}`, {
    deleted: true
  });
  return response.data;
};

export const resendBeneficiaryCode = async (userId: User["id"]) => {
  const response = await api.post(`/api/admin/users/beneficiary/${userId}/resend/code`);
  return response.data;
};

export const resendCooperatorCode = async (userId: User["id"]) => {
  const response = await api.post(`/api/admin/users/cooperator/${userId}/resend/code`);
  return response.data;
};

export const deleteBeneficiary = async (userId: User["id"]) => {
  const response = await api.put(`/api/admin/users/update-beneficiary/${userId}`, {
    deleted: true
  });
  return response.data;
};

export const deleteMultipleUsers = async (ids: User["id"][]) => {
  const response = await api.post(`/api/admin/users/delete`, {
    user_id: ids
  });
  return response.data;
};

export const updateClientUrssafStatus = async ({ userId }: { userId: User["id"] | null }) => {
  if (!userId) return null;
  const response = await api.patch(`/api/admin/users/update-client/statut/urssaf/${userId}`, {});
  return response.data;
};

export const sendLowBalanceEmailToMultipleBeneficiaries = async (ids: User["id"][]) => {
  const response = await api.post(
    `/api/admin/users/beneficiary/send/balance/contract-beneficiary-low-balance`,
    {
      user_id: ids
    }
  );
  return response.data;
};

export const sendNoBalanceEmailToMultipleBeneficiaries = async (ids: User["id"][]) => {
  const response = await api.post(
    `/api/admin/users/beneficiary/send/balance/contract-beneficiary-no-balance`,
    {
      user_id: ids
    }
  );
  return response.data;
};

export const getDuplicatedCooperatorRibCount = async ({ userId }: { userId: User["id"] }) => {
  const response = await api.get<{ nbDuplicatedRib: number }>(
    `/api/cooperator/check/data/bank/${userId}`
  );
  return response.data.nbDuplicatedRib;
};

export const importCustomers = async (data: ImportCustomersSchema) => {
  const response = await api.postForm("/api/admin/users/customers/import", {
    file_individual: data.fileIndividual,
    file_professional: data.fileProfessional
  });
  return response.data;
};
