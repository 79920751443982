import type { EmailSchema } from "~/schemas/email.schema";
import type { Email } from "~/types/email.types";
import type { DeepPartial } from "~/types/utils.types";

import { INFINITE_SCROLL_LIMIT } from "~/config/api";
import { api } from "./_config";

export const getEmails = async ({
  page,
  itemsPerPage
}: {
  page?: number;
  itemsPerPage?: number;
}) => {
  const response = await api.get<DeepPartial<Email[]>>("/api/admin/emails", {
    params: { page, itemsPerPage: itemsPerPage ?? INFINITE_SCROLL_LIMIT }
  });
  return response.data;
};

export const getEmail = async (id: number) => {
  const response = await api.get<Email>(`/api/admin/emails/${id}`);
  return response.data;
};

export const createEmail = async (data: EmailSchema) => {
  const response = await api.post("/api/admin/emails", data);
  return response.data;
};

export const updateEmail = async ({ id, data }: { id: number; data: EmailSchema }) => {
  const response = await api.put(`/api/admin/emails/${id}`, data);
  return response.data;
};

export const deleteEmail = async (id: number) => {
  const response = await api.delete(`/api/admin/emails/${id}`);
  return response.data;
};
