import type { Dayjs } from "dayjs";
import type { User } from "~/types/user.types";

import { useMemo, useState } from "react";
import { IonContent, IonPage } from "@ionic/react";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";

import { userRoles } from "~/config/api-constants";
import { queryKeys } from "~/config/query-keys-constants";
import { useAuthContext } from "~/contexts/authContext";
import { useDebounce } from "~/hooks/useDebounce";
import { getUsers } from "~/api/user";
import { CategoriesCooperatorsChart } from "~/components/statistics/CategoriesCooperatorsChart";
import { CooperatorCreatedChart } from "~/components/statistics/CooperatorCreatedChart";
import { CustomerCreatedChart } from "~/components/statistics/CustomerCreatedChart";
import { QuotationBilledChart } from "~/components/statistics/QuotationBilledChart";
import { QuotationCreatedChart } from "~/components/statistics/QuotationCreatedChart";
import { QuotationRowBilledChart } from "~/components/statistics/QuotationRowBilledChart";
import { TopCooperatorsChart } from "~/components/statistics/TopCooperatorsChart";
import { TopCustomersChart } from "~/components/statistics/TopCustomersChart";
import { FormComboBox } from "~/components/ui/FormComboBox";
import { FormDatePicker } from "~/components/ui/FormDatePicker";

export const StatisticsPage = () => {
  const { isAdmin, currentUser } = useAuthContext();
  const [params, setParams] = useState<{
    startDate: Dayjs;
    endDate: Dayjs;
    cooperator: "all" | User["id"] | null;
  }>({
    startDate: dayjs().subtract(4, "month"),
    endDate: dayjs(),
    cooperator: isAdmin ? "all" : currentUser?.id || 0
  });

  const chartProps = {
    // useDebounce because datepicker can be spam-clicked
    startDate: useDebounce<Dayjs>(params.startDate, 500).format("YYYY-MM-DD"),
    endDate: useDebounce<Dayjs>(params.endDate, 500).format("YYYY-MM-DD"),
    cooperator: params.cooperator === "all" ? null : params.cooperator
  };

  const isCurrentUserOrAdmin = isAdmin || currentUser?.id === params.cooperator;
  const isAdminAndAllView = isAdmin && params.cooperator === "all";

  // Liste des coopérateurs si admin
  const [cooperatorsSearch, setCooperatorsSearch] = useState("");
  const debouncedCooperatorsSearch = useDebounce<string>(cooperatorsSearch, 500);
  const cooperatorsQueryParams = {
    role: userRoles.ROLE_COOPERATOR,
    searchName: debouncedCooperatorsSearch,
    sort: { key: "lastname", value: "ASC" }
  } as const;
  const { data: cooperators, isLoading: isCooperatorsLoading } = useQuery({
    queryKey: [queryKeys.users, cooperatorsQueryParams],
    queryFn: () => getUsers(cooperatorsQueryParams),
    enabled: isAdmin
  });
  const cooperatorOptions = useMemo(() => {
    const options = [
      {
        id: 0,
        label: "-- Tous les coopérateurs --",
        value: "all"
      }
    ];
    if (cooperators) {
      options.push(
        ...cooperators.map((coop) => ({
          id: coop?.id || 0,
          label: `${coop?.lastname || ""} ${coop?.firstname || ""}${
            coop?.companyName ? " (" + coop.companyName + ")" : ""
          }`,
          value: coop?.id?.toString() || ""
        }))
      );
    }
    return options;
  }, [cooperators]);

  return (
    <IonPage>
      <IonContent className="container">
        <h1>Statistiques</h1>
        <div className="mb-8 flex flex-wrap gap-x-8 gap-y-4">
          <fieldset>
            <legend>
              Période de visualisation<sup>*</sup>
            </legend>
            <div className="mt-2 flex gap-2">
              <FormDatePicker
                defaultValue={params.startDate.format("YYYY-MM-DD")}
                onBlur={({ target: { value } }) => {
                  setParams((prev) => {
                    return {
                      ...prev,
                      startDate: dayjs(value)
                    };
                  });
                }}
              />
              <FormDatePicker
                defaultValue={params.endDate.format("YYYY-MM-DD")}
                onBlur={({ target: { value } }) => {
                  setParams((prev) => {
                    return {
                      ...prev,
                      endDate: dayjs(value)
                    };
                  });
                }}
              />
            </div>
            <div className="mt-2 text-xs italic">
              <sup>*</sup> limitée à une durée maximale de 1 an
            </div>
          </fieldset>
          {isAdmin ? (
            <fieldset className="max-w-96 flex-1">
              <legend>Coopérateur sélectionné</legend>
              <FormComboBox
                className="mt-2"
                options={cooperatorOptions}
                defaultOption={cooperatorOptions[0] || null}
                onChange={(value) => {
                  const cooperator = cooperators?.find(
                    (cooperator) => cooperator?.id?.toString() === value
                  );
                  if (cooperator && cooperator.id) {
                    setParams((prev) => {
                      return {
                        ...prev,
                        cooperator: cooperator.id || null
                      };
                    });
                  } else {
                    setParams((prev) => {
                      return {
                        ...prev,
                        cooperator: "all"
                      };
                    });
                  }
                }}
                onSearchChange={setCooperatorsSearch}
                onFocus={(e) => {
                  e.currentTarget.select();
                }}
                isLoading={isCooperatorsLoading}
              />
            </fieldset>
          ) : null}
        </div>

        <div className="grid grid-cols-1 gap-x-4 gap-y-8 xl:grid-cols-2">
          {isAdminAndAllView ? (
            <div>
              <h3>Nouveaux coopérateurs par mois</h3>
              <CooperatorCreatedChart {...chartProps} />
            </div>
          ) : null}
          {isCurrentUserOrAdmin ? (
            <div>
              <h3>Nouveaux clients par mois</h3>
              <CustomerCreatedChart {...chartProps} />
            </div>
          ) : null}
          {isCurrentUserOrAdmin ? (
            <div className="col-start-1">
              <h3>Montants des relevés saisis par jour</h3>
              <QuotationCreatedChart {...chartProps} />
            </div>
          ) : null}
          {isCurrentUserOrAdmin ? (
            <div>
              <h3>Montants des relevés facturés par jour</h3>
              <QuotationBilledChart {...chartProps} />
            </div>
          ) : null}
          {isCurrentUserOrAdmin ? (
            <div className="xl:col-span-2">
              <h3>Montants des relevés facturés par jour et service</h3>
              <QuotationRowBilledChart
                key={JSON.stringify(chartProps)} // force full redraw
                {...chartProps}
              />
            </div>
          ) : null}
          {isCurrentUserOrAdmin ? (
            <div>
              <h3>Top 10 clients</h3>
              <TopCustomersChart {...chartProps} />
            </div>
          ) : null}
          {isAdminAndAllView ? (
            <div>
              <h3>Top 10 coopérateurs</h3>
              <TopCooperatorsChart {...chartProps} />
            </div>
          ) : null}
          {isAdminAndAllView ? (
            <div className="xl:col-span-2">
              <h3>Proportion de nouveaux coopérateurs par service</h3>
              <CategoriesCooperatorsChart {...chartProps} />
            </div>
          ) : null}
        </div>
      </IonContent>
    </IonPage>
  );
};
