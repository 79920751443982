import type { MainTableProps } from "~/components/ui/MainTable";

export function getSearchedData({
  search,
  data
}: {
  search?: string | null | undefined;
  data?: MainTableProps["data"] | null | undefined;
  propertiesToSearch?: string[] | null | undefined;
}) {
  if (!data || data.length === 0) {
    return [];
  }

  if (!search || search === "" || search === " ") {
    return data;
  }

  return data.filter((row) => {
    const values = row.columns
      .map((cell) => {
        return cell.search || cell.sort || cell.display;
      })
      .join(" ");
    return search.split(" ").every((searchString: string) => {
      return values.toLowerCase().includes(searchString.toLowerCase());
    });
  });
}
