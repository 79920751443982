import type { ChangeEvent } from "react";

import { useEffect, useMemo, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Controller, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router";

import { civilities, legalForms, userRoles } from "~/config/api-constants";
import { queryKeys } from "~/config/query-keys-constants";
import { removeSpaces, transformDateTimeToDate } from "~/utils/misc";
import { useAuthContext } from "~/contexts/authContext";
import { useUIContext } from "~/contexts/uiContext";
import { useDebounce } from "~/hooks/useDebounce";
import { handleApiError } from "~/services/errors";
import { handleIbanChange } from "~/services/iban";
import { handleSiretChange } from "~/services/siret";
import { getGouvAddresses } from "~/api/gouv-addresses";
import { getInseeCountries } from "~/api/insee-countries";
import { createClient, getUser, getUsers, updateClient } from "~/api/user";
import { Button } from "~/components/ui/Button/Button";
import { FormComboBox } from "~/components/ui/FormComboBox";
import { FormDatePicker } from "~/components/ui/FormDatePicker";
import { FormInput } from "~/components/ui/FormInput";
import { FormRow } from "~/components/ui/FormRow";
import { FormSelect } from "~/components/ui/FormSelect";
import { FormTitle } from "~/components/ui/FormTitle";
import { LoadingSpinner } from "~/components/ui/LoadingSpinner";
import { CustomerSchema } from "~/schemas/customer.schema";

export const CreateEditCustomer = ({ editMode = false }: { editMode?: boolean }) => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const { user_id: userId } = useParams<{ user_id?: string }>();
  const { currentUser, isAdmin } = useAuthContext();
  const { toast } = useUIContext();

  // Get the customer when form is in edit mode
  const { data: userToEdit, isLoading: isUserLoading } = useQuery({
    queryKey: [queryKeys.users, userId],
    queryFn: () => getUser({ id: userId ? parseInt(userId) : null }),
    enabled: !!userId
  });

  // Cooperators list for cooperator selection (Admin only)
  const [cooperatorsSearch, setCooperatorsSearch] = useState("");
  const debouncedCooperatorsSearch = useDebounce<string>(cooperatorsSearch, 500);
  const cooperatorsQueryParams = {
    role: userRoles.ROLE_COOPERATOR,
    searchName: debouncedCooperatorsSearch,
    sort: { key: "lastname", value: "ASC" }
  } as const;
  const { data: cooperators, isLoading: isCooperatorsLoading } = useQuery({
    queryKey: [queryKeys.users, cooperatorsQueryParams],
    queryFn: () => getUsers(cooperatorsQueryParams),
    enabled: isAdmin
  });

  // Countries list for birth country
  const [countriesSearch, setCountriesSearch] = useState("");
  const { data: countries, isLoading: isCountriesLoading } = useQuery({
    queryKey: [queryKeys.countries],
    queryFn: () => getInseeCountries({ itemsPerPage: 1500 })
  });
  const filteredCountries =
    countries && countries.length > 0
      ? countries.filter((country) => country?.libcog?.includes(countriesSearch))
      : [];

  // Cities list for address
  const [addressSearch, setAddressSearch] = useState("");
  const debouncedAddressSearch = useDebounce<string>(addressSearch, 500);
  const { data: addresses, isLoading: isAddressesLoading } = useQuery({
    queryKey: [queryKeys.addresses, { search: debouncedAddressSearch }],
    queryFn: () => getGouvAddresses({ search: debouncedAddressSearch }),
    enabled: debouncedAddressSearch.length > 3
  });

  // Set first search values for comboboxes
  useEffect(() => {
    if (userToEdit) {
      setCooperatorsSearch(userToEdit.cooperator?.[0]?.lastname ?? "");
      setCountriesSearch(userToEdit.countryBirth?.id.toString() ?? "");
      setAddressSearch(userToEdit.address ?? "");
    }
  }, [userToEdit]);

  // Customer mutation
  const customerMutation = useMutation(
    (data: CustomerSchema) =>
      editMode
        ? updateClient({ userId: userId ? parseInt(userId) : null, data })
        : createClient({ data }),
    {
      onSuccess() {
        queryClient.invalidateQueries({ queryKey: [queryKeys.users] });
        toast(
          editMode ? "Le client a été modifié avec succès." : "Le client a été créé avec succès.",
          "success"
        );
        history.push("/customers");
      },
      onError(error: any) {
        if (error.response?.data.user_id) {
          history.push(`/customers/edit/${error.response.data.user_id}`);
        }
        handleApiError(error, toast);
      }
    }
  );

  const defaultValues = useMemo(() => {
    return {
      ...(isAdmin ? { canAvanceImmediate: userToEdit?.canAvanceImmediate ?? false } : {}),
      isPro: userToEdit?.isPro,
      cooperator: userToEdit?.cooperator?.[0]?.id
        ? [userToEdit.cooperator[0].id.toString()]
        : isAdmin
          ? [""]
          : [currentUser?.id?.toString() ?? ""],
      isAvanceImmediate: userToEdit?.isAvanceImmediate ?? false,
      internRefCoop: userToEdit?.internRefCoop ?? null,
      civility: userToEdit?.civility ?? null,
      lastname: userToEdit?.lastname ?? "",
      lastnameBirth: userToEdit?.lastnameBirth ?? "",
      firstname: userToEdit?.firstname ?? "",
      email: userToEdit?.email ?? "",
      mobileNumber: userToEdit?.mobileNumber ?? null,
      fixeNumber: userToEdit?.fixeNumber ?? null,
      zipcode: userToEdit?.zipcode ?? "",
      city: userToEdit?.city ?? "",
      address: userToEdit?.address ?? "",
      // Convert date time to date
      birthdayDate: transformDateTimeToDate(userToEdit?.birthdayDate) ?? null,
      countryBirth: userToEdit?.countryBirth?.id.toString() ?? null,
      birthdayLocation: userToEdit?.birthdayLocation ?? null,
      companyName: userToEdit?.companyName ?? null,
      siret: userToEdit?.siret ?? null,
      legalForm: userToEdit?.legalForm ?? null,
      tvaNumber: userToEdit?.tvaNumber ?? null,
      iban: userToEdit?.ibanCountryCode
        ? `${userToEdit.ibanCountryCode ?? ""}${userToEdit.ibanControlKey ?? ""}${
            userToEdit.ibanBankCode ?? ""
          }${userToEdit.ibanGuichetCode ?? ""}${userToEdit.ibanAccountNumber ?? ""}${
            userToEdit.ibanRIBKey ?? ""
          }`
        : null,
      bic: userToEdit?.bicBankCode
        ? `${userToEdit.bicBankCode ?? ""}${userToEdit.bicCountryCode ?? ""}${
            userToEdit.bicLocalisationCode ?? ""
          }${userToEdit.bicOptionnalCode ?? ""}`
        : null
    };
  }, [userToEdit, currentUser, isAdmin]);

  // Define form
  const form = useForm<CustomerSchema>({
    resolver: zodResolver(CustomerSchema),
    defaultValues
  });

  useEffect(() => {
    form.reset(defaultValues, { keepDirtyValues: true });
  }, [defaultValues, form]);

  const canAvanceImmediateSelected = form.watch("canAvanceImmediate");
  const isProSelected = form.watch("isPro");
  const isAvanceImmediateSelected = form.watch("isAvanceImmediate");

  const onSubmit = (data: CustomerSchema) => {
    const { iban, bic, ...dataRest } = data;
    const formatedData =
      iban && bic
        ? {
            ...dataRest,
            ibanCountryCode: iban.slice(0, 2),
            ibanControlKey: iban.slice(2, 4),
            ibanBankCode: iban.slice(4, 9),
            ibanGuichetCode: iban.slice(9, 14),
            ibanAccountNumber: iban.slice(14, 25),
            ibanRIBKey: iban.slice(25, 27),
            bicBankCode: bic.slice(0, 4),
            bicCountryCode: bic.slice(4, 6),
            bicLocalisationCode: bic.slice(6, 8),
            bicOptionnalCode: bic.slice(8, 11) || ""
          }
        : dataRest;
    const cleanData = Object.fromEntries(
      Object.entries(formatedData).filter(([_, v]) => v !== null)
    ) as CustomerSchema;

    customerMutation.mutate(cleanData);
  };

  if (!!userId && isUserLoading) {
    return <LoadingSpinner size="lg" fullPage />;
  }

  if (!!userId && !userToEdit) {
    return <p className="text-center">Le client à éditer est introuvable.</p>;
  }

  return (
    <>
      <h2 className="mt-6">
        {editMode
          ? `Édition d'un client (${userToEdit?.lastname} ${userToEdit?.firstname})`
          : "Création d'un client"}
      </h2>
      {isAdmin && userToEdit?.urssafUser ? (
        <small>Identifiant URSSAF : {userToEdit.urssafUser.idClient}</small>
      ) : (
        ""
      )}
      <form onSubmit={form.handleSubmit(onSubmit)}>
        {isAdmin ? (
          <>
            <FormRow>
              <Controller
                name="cooperator.0"
                control={form.control}
                render={({ field: { onChange, onBlur, ref } }) => {
                  return (
                    <FormComboBox
                      label="Coopérateur associé"
                      onChange={onChange}
                      onBlur={onBlur}
                      onSearchChange={setCooperatorsSearch}
                      options={
                        cooperators?.map((coop) => ({
                          id: coop?.id ?? 0,
                          label: `${coop?.lastname ?? ""} ${coop?.firstname ?? ""} - ${coop?.companyName ?? ""}`,
                          value: coop?.id?.toString() ?? ""
                        })) ?? []
                      }
                      defaultOption={
                        userToEdit?.cooperator?.[0]?.id
                          ? {
                              id: userToEdit.cooperator[0].id,
                              label: `${userToEdit.cooperator[0]?.lastname} ${userToEdit.cooperator[0]?.firstname}`,
                              value: userToEdit.cooperator[0].id.toString()
                            }
                          : null
                      }
                      infoMessage="Coopérateur auquel est associé le client."
                      errorMessage={
                        form.formState.errors.cooperator?.[0]?.message ??
                        form.formState.errors.cooperator?.message
                      }
                      isLoading={isCooperatorsLoading}
                      ref={ref}
                    />
                  );
                }}
              />
            </FormRow>

            <FormTitle as="h3">Fonctionnalités (réservé aux administrateurs)</FormTitle>
            <FormRow>
              <FormSelect
                label="Autoriser l'avance immédiate sur ses relevés"
                infoMessage={`Permet d'autoriser l'avance immédiate sur les relevés du client.
                (Option réservée aux administrateurs)`}
                errorMessage={form.formState.errors.canAvanceImmediate?.message}
                {...form.register("canAvanceImmediate", {
                  setValueAs: (v) => v === "true" || v === true,
                  onChange: () => {
                    form.resetField("isAvanceImmediate");
                    form.resetField("iban");
                    form.resetField("bic");
                  }
                })}
              >
                <option value="false">Non</option>
                <option value="true">Oui</option>
              </FormSelect>
            </FormRow>
          </>
        ) : null}

        <FormTitle as="h3">Informations du compte client</FormTitle>
        <FormRow>
          <FormSelect
            label="Type de client"
            errorMessage={form.formState.errors.isPro?.message}
            {...form.register("isPro", {
              setValueAs: (v) => (v === "" ? undefined : v === "true" || v === true),
              onChange: (e) => {
                if (e.target.value === "true") {
                  form.resetField("isAvanceImmediate");
                  form.resetField("iban");
                  form.resetField("bic");
                  form.resetField("birthdayDate");
                  form.resetField("countryBirth");
                  form.resetField("birthdayLocation");
                }
                if (e.target.value === "false") {
                  form.resetField("companyName");
                  form.resetField("siret");
                  form.resetField("legalForm");
                  form.resetField("tvaNumber");
                }
              }
            })}
            defaultValue=""
          >
            <option value="" disabled>
              Choisir
            </option>
            <option value="true">Professionnel</option>
            <option value="false">Particulier</option>
          </FormSelect>

          {(isAdmin ? canAvanceImmediateSelected : currentUser?.canAvanceImmediate) &&
          !isProSelected ? (
            <FormSelect
              label="Activer l'option d'avance immédiate"
              warningMessage={`Si oui, merci de remplir les champs: civilité, n° de mobile, date de naissance, pays de naissance, commune de naissance, IBAN et BIC.`}
              errorMessage={form.formState.errors.isAvanceImmediate?.message}
              {...form.register("isAvanceImmediate", {
                setValueAs: (v) => v === "true" || v === true,
                onChange: () => {
                  form.resetField("iban");
                  form.resetField("bic");
                }
              })}
            >
              <option value="false">Non</option>
              <option value="true">Oui</option>
            </FormSelect>
          ) : null}
        </FormRow>

        <FormTitle as="h3">Informations personnelles du client</FormTitle>

        <FormRow>
          <FormInput
            label="Compte auxiliaire client"
            optional
            errorMessage={form.formState.errors.internRefCoop?.message}
            {...form.register("internRefCoop")}
          />
        </FormRow>

        <FormRow>
          <FormSelect
            label="Civilité"
            optional={isProSelected || !isAvanceImmediateSelected}
            errorMessage={form.formState.errors.civility?.message}
            {...form.register("civility", {
              setValueAs: (v) => parseInt(v)
            })}
          >
            <option value="">Non renseignée</option>
            <option value={civilities.MADAME}>Madame</option>
            <option value={civilities.MONSIEUR}>Monsieur</option>
          </FormSelect>
          {isProSelected || !isAvanceImmediateSelected ? null : (
            <FormInput
              label="Nom de naissance (nom de jeune fille)"
              errorMessage={form.formState.errors.lastnameBirth?.message}
              optional={form.watch("civility") === civilities.MONSIEUR}
              {...form.register("lastnameBirth", {
                onChange: (e) => {
                  if (form.watch("civility") === civilities.MONSIEUR) {
                    form.setValue("lastname", e.currentTarget.value);
                  }
                }
              })}
            />
          )}
          <FormInput
            label="Nom d'usage"
            errorMessage={form.formState.errors.lastname?.message}
            {...form.register("lastname")}
          />
          <FormInput
            label="Prénom"
            errorMessage={form.formState.errors.firstname?.message}
            {...form.register("firstname")}
          />
          <FormInput
            label="Email"
            warningMessage="L'email que vous allez saisir permet la validation et le paiement des prestations par votre client. Veuillez, dans la mesure du possible, compléter correctement cette information pour le confort de votre client."
            errorMessage={form.formState.errors.email?.message}
            {...form.register("email")}
          />
          <FormInput
            label="N° de mobile"
            optional={isProSelected || !isAvanceImmediateSelected}
            warningMessage="Le téléphone que vous allez saisir permet la notification des relevés de prestation à votre client. Veuillez, dans la mesure du possible, compléter correctement cette information pour le confort de votre client."
            errorMessage={form.formState.errors.mobileNumber?.message}
            {...form.register("mobileNumber")}
          />
          <FormInput
            label="N° de fixe"
            optional
            errorMessage={form.formState.errors.fixeNumber?.message}
            {...form.register("fixeNumber")}
          />
        </FormRow>

        <FormRow>
          <Controller
            name="address"
            control={form.control}
            render={({ field: { onChange, onBlur, ref } }) => {
              return (
                <FormComboBox
                  label={isProSelected ? "Adresse de l'entreprise" : "Adresse"}
                  onChange={(value) => {
                    const address = addresses?.find((address) => address.id === value);
                    if (!address) return onChange(value);
                    form.setValue("zipcode", address.postcode ?? "", { shouldValidate: true });
                    form.setValue("city", address.district ?? address.city ?? "", {
                      shouldValidate: true
                    });
                    form.trigger("address");
                    onChange(
                      address.name ??
                        (address.housenumber && address.street
                          ? `${address.housenumber} ${address.street}`
                          : "")
                    );
                  }}
                  onBlur={onBlur}
                  onSearchChange={setAddressSearch}
                  allowCustomValue={true}
                  options={
                    addresses?.map((address) => ({
                      id: address.id ?? 0,
                      value: address.id ?? "",
                      label: address.label ?? "",
                      labelInInput: address.name ?? `${address.housenumber} ${address.street}`
                    })) ?? []
                  }
                  defaultOption={
                    userToEdit?.address
                      ? {
                          id: userToEdit.address,
                          value: userToEdit.address,
                          label: userToEdit.address
                        }
                      : null
                  }
                  infoMessage="Selectionnez une des propositions afin de correspondre aux données de l'URSSAF."
                  warningMessage={
                    isProSelected
                      ? "Adresse de l'entreprise à facturer et non celle du client."
                      : null
                  }
                  errorMessage={form.formState.errors.address?.message}
                  isLoading={isAddressesLoading}
                  ref={ref}
                />
              );
            }}
          />

          <FormInput
            label={isProSelected ? "Code postal de l'entreprise" : "Code postal"}
            warningMessage={
              isProSelected
                ? "Code postal de l'entreprise à facturer et non celui du client."
                : null
            }
            errorMessage={form.formState.errors.zipcode?.message}
            {...form.register("zipcode")}
          />
          <FormInput
            label={isProSelected ? "Ville de l'entreprise" : "Ville"}
            warningMessage={
              isProSelected ? "Ville de l'entreprise à facturer et non celle du client." : null
            }
            errorMessage={form.formState.errors.city?.message}
            {...form.register("city")}
          />
        </FormRow>

        {!isProSelected ? (
          <FormRow>
            <FormDatePicker
              label="Date de naissance"
              optional={!isAvanceImmediateSelected}
              errorMessage={form.formState.errors.birthdayDate?.message}
              {...form.register("birthdayDate")}
            />
            <Controller
              name="countryBirth"
              control={form.control}
              render={({ field: { onChange, onBlur, ref } }) => {
                return (
                  <FormComboBox
                    label="Pays de naissance"
                    onChange={onChange}
                    onBlur={onBlur}
                    onSearchChange={(value) => setCountriesSearch(value ? value.toUpperCase() : "")}
                    optional={!isAvanceImmediateSelected}
                    options={filteredCountries.map((country) => ({
                      id: country?.id ?? 0,
                      label: country?.libcog ?? "",
                      value: country?.id?.toString() ?? ""
                    }))}
                    defaultOption={
                      userToEdit?.countryBirth?.libcog
                        ? {
                            id: userToEdit.countryBirth.id || 0,
                            label: userToEdit.countryBirth.libcog,
                            value: userToEdit.countryBirth.id.toString() || ""
                          }
                        : null
                    }
                    errorMessage={form.formState.errors.countryBirth?.message}
                    isLoading={isCountriesLoading}
                    ref={ref}
                  />
                );
              }}
            />

            <FormInput
              label="Commune de naissance"
              optional={!isAvanceImmediateSelected}
              infoMessage="Précisez l'arrondissement si existant."
              errorMessage={form.formState.errors.birthdayLocation?.message}
              {...form.register("birthdayLocation")}
            />
          </FormRow>
        ) : null}

        {isProSelected ? (
          <>
            <FormTitle as="h3">Informations professionnelles du client</FormTitle>

            <FormRow>
              <FormInput
                label="Nom de la société"
                errorMessage={form.formState.errors.companyName?.message}
                {...form.register("companyName")}
              />
              <FormSelect
                label="Forme juridique"
                errorMessage={form.formState.errors.legalForm?.message}
                {...form.register("legalForm")}
              >
                {Object.values(legalForms).map((legalForm) => (
                  <option key={legalForm} value={legalForm}>
                    {legalForm}
                  </option>
                ))}
              </FormSelect>
              <FormInput
                label="SIRET"
                errorMessage={form.formState.errors.siret?.message}
                {...form.register("siret", {
                  onChange: handleSiretChange,
                  setValueAs: (v) => removeSpaces(v)
                })}
              />
              <FormInput
                label="Numéro de TVA"
                errorMessage={form.formState.errors.tvaNumber?.message}
                {...form.register("tvaNumber")}
              />
            </FormRow>
          </>
        ) : null}

        {!isProSelected && isAvanceImmediateSelected ? (
          <>
            <FormTitle as="h3">Coordonnées bancaires</FormTitle>

            <FormRow>
              <FormInput
                label="IBAN"
                placeholder="FR76 2852 1966 1423 3450 8845 009"
                errorMessage={form.formState.errors.iban?.message}
                {...form.register("iban", {
                  onChange: (e) => {
                    handleIbanChange(e);
                    form.trigger("iban").then((isValid) => {
                      if (isValid) {
                        form.setFocus("bic");
                      }
                    });
                  },
                  setValueAs: (v) => removeSpaces(v?.toUpperCase())
                })}
              />

              <FormInput
                label="BIC"
                placeholder="CMCIFRMM"
                errorMessage={form.formState.errors.bic?.message}
                {...form.register("bic", {
                  setValueAs: (v) => (v && v !== "" ? v.toUpperCase() : null),
                  onChange: (e: ChangeEvent<HTMLInputElement>) => {
                    e.target.value = e.target.value.toUpperCase();
                  }
                })}
              />
            </FormRow>
          </>
        ) : null}

        <div className="mt-12 flex justify-end gap-4 border-t border-grey-200 pt-6">
          <Button fill="clear" onClick={() => history.goBack()}>
            Annuler
          </Button>
          <Button type="submit" disabled={customerMutation.isLoading}>
            {editMode ? "Modifier le client" : "Créer le client"}
          </Button>
        </div>
      </form>
    </>
  );
};
