import type { User } from "~/types/user.types";
import type { DeepPartial } from "~/types/utils.types";

import { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router";

import { queryKeys } from "~/config/query-keys-constants";
import { useUIContext } from "~/contexts/uiContext";
import { useDebounce } from "~/hooks/useDebounce";
import { handleApiError } from "~/services/errors";
import { getGouvAddresses } from "~/api/gouv-addresses";
import { updateCooperator } from "~/api/user";
import { Button } from "~/components/ui/Button/Button";
import { FormComboBox } from "~/components/ui/FormComboBox";
import { FormInput } from "~/components/ui/FormInput";
import { FormRow } from "~/components/ui/FormRow";
import { ProfileInformationsSchema } from "~/schemas/profile.schema";

export const ProfileInformations = ({ userData }: { userData?: DeepPartial<User> }) => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const { toast } = useUIContext();

  // Addresses list for address selection
  const [addressSearch, setAddressSearch] = useState("");
  const debouncedAddressSearch = useDebounce<string>(addressSearch, 500);
  const { data: addressesData, isLoading: isAddressesLoading } = useQuery({
    queryKey: [queryKeys.addresses, { search: debouncedAddressSearch }],
    queryFn: () => getGouvAddresses({ search: debouncedAddressSearch }),
    enabled: debouncedAddressSearch.length > 3
  });

  // Customer mutation
  const mutation = useMutation({
    mutationFn: (data: ProfileInformationsSchema) =>
      updateCooperator({ userId: userData?.id ? userData.id : null, data }),
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: [queryKeys.users] });
      toast("Profil mis à jour avec succès.", "success");
    },
    onError(error) {
      handleApiError(error, toast);
    }
  });

  // Define form
  const {
    register,
    handleSubmit,
    setValue,
    control,
    trigger,
    formState: { errors }
  } = useForm<ProfileInformationsSchema>({
    resolver: zodResolver(ProfileInformationsSchema),
    values: {
      email: userData?.email || "",
      mobileNumber: userData?.mobileNumber || "",
      fixeNumber: userData?.fixeNumber || "",
      address: userData?.address || "",
      zipcode: userData?.zipcode || "",
      city: userData?.city || "",
      logo: userData?.logoUrl || ""
    }
  });

  const onSubmit = (data: ProfileInformationsSchema) => {
    const formatedData = {
      ...data,
      userId: userData?.id ? userData.id : null
    };
    const cleanData = Object.fromEntries(
      Object.entries(formatedData).filter(([_, v]) => v !== null)
    ) as ProfileInformationsSchema;
    mutation.mutate(cleanData);
  };

  return (
    <>
      <h2 className="mt-6">Informations générales</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormRow>
          <FormInput
            label="Email de contact"
            errorMessage={errors.email?.message}
            {...register("email")}
          />
          <FormInput
            label="N° de mobile"
            optional
            errorMessage={errors.mobileNumber?.message}
            {...register("mobileNumber")}
          />
          <FormInput
            label="N° de fixe"
            optional
            errorMessage={errors.fixeNumber?.message}
            {...register("fixeNumber")}
          />
        </FormRow>

        <FormRow>
          <Controller
            name="address"
            control={control}
            render={({ field: { onChange, onBlur, ref } }) => {
              return (
                <FormComboBox
                  label="Adresse"
                  onBlur={onBlur}
                  onChange={(value) => {
                    const address = addressesData?.find((address) => address.id === value);
                    if (!address) return onChange(value || "");
                    setValue("zipcode", address.postcode || "", { shouldValidate: true });
                    setValue("city", address.district || address.city || "", {
                      shouldValidate: true
                    });
                    trigger("address");
                    onChange(
                      address.name ||
                        (address.housenumber && address.street
                          ? `${address.housenumber} ${address.street}`
                          : "")
                    );
                  }}
                  onSearchChange={setAddressSearch}
                  allowCustomValue={true}
                  options={
                    addressesData?.map((address) => ({
                      id: address.id || 0,
                      value: address.id || "",
                      label: address.label || "",
                      labelInInput: address.name || `${address.housenumber} ${address.street}`
                    })) || []
                  }
                  defaultOption={
                    userData?.address
                      ? {
                          id: userData.address,
                          value: userData.address,
                          label: userData.address
                        }
                      : null
                  }
                  isLoading={isAddressesLoading}
                  infoMessage="Selectionnez une des propositions afin de correspondre aux données de l'URSSAF."
                  errorMessage={errors.address?.message}
                  ref={ref}
                />
              );
            }}
          />

          <FormInput
            label="Code postal"
            errorMessage={errors.zipcode?.message}
            {...register("zipcode")}
          />
          <FormInput label="Ville" errorMessage={errors.city?.message} {...register("city")} />
          <FormInput
            label="Logo"
            type="file"
            optional
            errorMessage={
              typeof errors.logo?.message !== "string"
                ? errors.logo?.message?.toString()
                : errors.logo.message
            }
            {...register("logo")}
          />
          <img src={userData?.logoUrl} alt="logo" />
        </FormRow>

        <div className="mt-12 flex justify-end gap-4 border-t border-grey-200 pt-6">
          <Button fill="clear" onClick={() => history.goBack()}>
            Annuler
          </Button>
          <Button type="submit" disabled={mutation.isLoading}>
            Enregistrer
          </Button>
        </div>
      </form>
    </>
  );
};
