import type { MainTableProps } from "~/components/ui/MainTable";

import { zodResolver } from "@hookform/resolvers/zod";
import { useInfiniteQuery, useMutation } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useForm } from "react-hook-form";

import { queryKeys } from "~/config/query-keys-constants";
import { formatDisplayDate, transformDateTimeToDate } from "~/utils/misc";
import { useAuthContext } from "~/contexts/authContext";
import { useUIContext } from "~/contexts/uiContext";
import { useModal } from "~/hooks/useModal";
import { handleApiError } from "~/services/errors";
import { getNextPageParamFn } from "~/api/_config";
import { queryClient } from "~/api/_queryClient";
import { createExport, getExports } from "~/api/log-export";
import { ConfirmationModal } from "~/components/Modals/ConfirmationModal";
import { TableLayout } from "~/components/TableLayout";
import { Button } from "~/components/ui/Button/Button";
import { FormDatePicker } from "~/components/ui/FormDatePicker";
import { FormInput } from "~/components/ui/FormInput";
import { FormRow } from "~/components/ui/FormRow";
import { FormSelect } from "~/components/ui/FormSelect";
import { FormTitle } from "~/components/ui/FormTitle";
import { ExternalLink, Link } from "~/components/ui/Link";
import { ExportSchema } from "~/schemas/export.schema";

export const AdministrationExports = () => {
  const { currentUser } = useAuthContext();
  const { openModal, closeModal, renderModal } = useModal();
  const { toast } = useUIContext();

  const queryParams = {};
  const { data, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: [queryKeys.exports, queryParams],
    queryFn: ({ pageParam }) => getExports({ ...queryParams, page: pageParam }),
    getNextPageParam: getNextPageParamFn
  });

  const exportOptions = [
    {
      value: 21,
      name: "SAGE - Export banque, achats, ventes, trop perçus + journal des tiers (format ZIP)"
    },
    { value: 20, name: "SAGE - Export banque, achats, ventes, trop perçus" },
    { value: 9, name: "SAGE - Export des tiers" },
    { value: 1, name: "Coopérateurs" },
    { value: 2, name: "Clients" },
    { value: 3, name: "Commerciaux" },
    { value: 15, name: "Bénéficiaires de contrat" },
    { value: 4, name: "Relevés de prestation" },
    { value: 5, name: "Factures clients" },
    { value: 16, name: "Factures contrats de prefinancement" },
    { value: 6, name: "Factures coopérateurs" },
    { value: 12, name: "Réglements clients" },
    { value: 13, name: "Réglements coopérateurs" },
    { value: 17, name: "Crédits attribués manuellement" },
    { value: 18, name: "Factures d'adhésion des coopérateurs" }
  ];

  const getExistingExport = async ({
    startDate,
    endDate,
    name
  }: {
    name: string;
    startDate: string;
    endDate: string;
  }) => {
    const existingExports = await getExports({ name, startDate, endDate });
    return existingExports[0] || null;
  };

  const exportMutation = useMutation({
    mutationFn: createExport,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.exports] });
      toast("Export créé avec succès.", "success");
    },
    onError(error) {
      handleApiError(error, toast);
    }
  });

  const onSubmit = async (data: ExportSchema) => {
    const { name, ...filteredData } = data;
    const existingExport = await getExistingExport({
      name: data.name || "",
      startDate: data.startDate + "T00:00:00",
      endDate: data.endDate + "T23:59:59"
    });
    if (existingExport) {
      openModal(
        <ConfirmationModal
          title="Export déjà existant"
          isOpen={true}
          onClose={closeModal}
          onConfirm={() => exportMutation.mutate(filteredData)}
        >
          Un export du même type existe déjà pour la période du {formatDisplayDate(data.startDate)}{" "}
          au {formatDisplayDate(data.endDate)}.<br />
          <br />
          Exporté par : {existingExport.exportBy?.firstname} {existingExport.exportBy?.lastname}
          <br />
          Le : {existingExport.exportedAt
            ? formatDisplayDate(existingExport.exportedAt)
            : ""} à{" "}
          {existingExport.exportedAt ? dayjs(existingExport.exportedAt).format("HH:mm") : ""}
          <br />
          Envoyé à : {existingExport.email}
          <br />
          <br />
          {existingExport.link ? (
            existingExport.link === "En cours de génération..." ? (
              existingExport.link
            ) : (
              <Link to={existingExport.link} target="_blank" rel="noreferrer">
                Télécharger l&apos;export existant
              </Link>
            )
          ) : (
            "Téléchargement indisponible"
          )}
          <br />
          <br />
          Souhaitez-vous créer cet export à nouveau ?
        </ConfirmationModal>
      );
    } else {
      exportMutation.mutate(filteredData);
    }
  };

  const tableData: MainTableProps["data"] =
    data?.pages.flat().map((exportLog) => ({
      itemId: exportLog?.id,
      columns: [
        { key: "name", label: "Nom", display: exportLog?.name },
        { key: "description", label: "Description", display: exportLog?.description },
        {
          key: "startDate",
          label: "Date de début",
          display: exportLog?.startDate ? formatDisplayDate(exportLog.startDate) : "",
          sort: exportLog?.startDate,
          search: exportLog?.startDate ? formatDisplayDate(exportLog.startDate) : ""
        },
        {
          key: "endDate",
          label: "Date de fin",
          display: exportLog?.endDate ? formatDisplayDate(exportLog.endDate) : "",
          sort: exportLog?.endDate,
          search: exportLog?.endDate ? formatDisplayDate(exportLog.endDate) : ""
        },
        {
          key: "email",
          label: "Email de réception",
          display: <ExternalLink to={`mailto:${exportLog?.email}`}>{exportLog?.email}</ExternalLink>
        },
        {
          key: "link",
          label: "Lien de téléchargement",
          display: exportLog?.link ? (
            exportLog.link === "En cours de génération..." ? (
              exportLog.link
            ) : (
              <Link to={exportLog.link} target="_blank" rel="noreferrer">
                Télécharger
              </Link>
            )
          ) : (
            "Téléchargement indisponible"
          )
        },
        {
          key: "exportedBy",
          label: "Exporté par",
          display: `${exportLog?.exportBy?.lastname} ${exportLog?.exportBy?.firstname}`
        },
        {
          key: "exportedAt",
          label: "Date d'export'",
          display: exportLog?.exportedAt ? formatDisplayDate(exportLog.exportedAt) : "",
          sort: exportLog?.exportedAt,
          search: exportLog?.exportedAt ? formatDisplayDate(exportLog.exportedAt) : ""
        }
      ]
    })) || [];

  const yesterdayDate = dayjs().subtract(1, "day");
  const form = useForm<ExportSchema>({
    resolver: zodResolver(ExportSchema),
    values: {
      type: 21,
      email: currentUser?.email || "",
      startDate: transformDateTimeToDate(yesterdayDate.toISOString()) || "",
      endDate: transformDateTimeToDate(yesterdayDate.toISOString()) || ""
    }
  });

  return (
    <>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <FormTitle as="h3">Nouvel export</FormTitle>

        <FormRow className="lg:grid-cols-2">
          <FormSelect
            label="Type d'export"
            {...form.register("type", {
              setValueAs: (v) => parseInt(v),
              onChange: (e) => {
                const exportOption = exportOptions.find(
                  (option) => option.value === parseInt(e.currentTarget.value)
                );
                form.setValue("name", exportOption?.name);
              }
            })}
          >
            {exportOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.name}
              </option>
            ))}
          </FormSelect>
          <input type="hidden" {...form.register("name")} />
          <FormInput
            label="Email pour recevoir le lien de téléchargement"
            {...form.register("email")}
          />
        </FormRow>
        <FormRow className="lg:grid-cols-2">
          <FormDatePicker label="Date de début" {...form.register("startDate")} />
          <FormDatePicker label="Date de fin" {...form.register("endDate")} />
        </FormRow>

        <div className="mt-6 flex justify-center gap-4 border-b border-grey-200 pb-6">
          <Button type="submit" disabled={exportMutation.isLoading}>
            Recevoir l&apos;export
          </Button>
        </div>
      </form>
      <TableLayout
        data={tableData}
        isLoading={isFetching || isFetchingNextPage}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
      />
      {renderModal()}
    </>
  );
};
