import type { ChangeEvent } from "react";

import { removeSpaces } from "~/utils/misc";

export const handleSiretChange = (e: ChangeEvent<HTMLInputElement>) => {
  const { value } = e.target;

  const formattedValue = removeSpaces(value);
  e.target.value = formattedValue;
};
