import { isValidBIC, isValidIBAN } from "ibantools";
import { z } from "zod";

import { allowedIbanPrefixes } from "~/services/iban";

const MAX_FILE_SIZE = 1024 * 1024 * 2; // 2MB
const ACCEPTED_IMAGE_TYPES = ["image/png", "image/jpeg", "image/jpg"];

export const CooperatorSchema = z.object({
  lastname: z.string().min(1, "Veuillez renseigner un nom."),
  firstname: z.string().min(1, "Veuillez renseigner un prénom."),
  job: z.string().min(1, "Veuillez sélectionner un métier."),
  companyName: z.string().optional().nullable(),
  logo: z
    .any()
    .refine((files) => {
      if (!files || files.length === 0) return true;
      return ACCEPTED_IMAGE_TYPES.includes(files[0].type);
    }, "L'image doit être au format png, jpeg ou jpg")
    .refine((files) => {
      if (!files || files.length === 0) return true;
      return files[0].size <= MAX_FILE_SIZE;
    }, "Le fichier est trop volumineux (2Mo maximum)")
    .optional()
    .nullable(),
  legalForm: z.string().min(1, "Veuillez sélectionner une forme juridique."),
  siret: z
    .string()
    .min(1, "Veuillez renseigner un numéro SIRET.")
    .length(14, "Le numéro SIRET doit contenir 14 chiffres.")
    .regex(/^\d+$/, "Le numéro SIRET doit contenir uniquement des chiffres."),
  tvaNumber: z.string().optional().nullable(),
  email: z.string().email("Veuillez renseigner une adresse e-mail valide."),
  emailContact: z
    .string()
    .email("Veuillez renseigner une adresse e-mail valide ou laisser ce champ vide.")
    .or(z.literal("").transform(() => null))
    .optional()
    .nullable(),

  mobileNumber: z
    .string()
    .regex(
      /^(\+33|\+330|0)[67]([-. ]?[0-9]{2}){4}$/,
      "Veuillez renseigner un numéro de téléphone mobile français valide ou laisser ce champ vide."
    )
    .transform((value) => value.replace(/[-. ]/g, ""))
    .or(z.literal("").transform(() => null))
    .optional()
    .nullable(),
  fixeNumber: z
    .string()
    .regex(
      /^(\+33|\+330|0)[1-9]([-. ]?[0-9]{2}){4}$/,
      "Veuillez renseigner un numéro de téléphone fixe valide ou laisser ce champ vide."
    )
    .transform((value) => value.replace(/[-. ]/g, ""))
    .or(z.literal("").transform(() => null))
    .optional()
    .nullable(),
  address: z.string().min(1, "Veuillez renseigner une adresse."),
  zipcode: z.string().min(1, "Veuillez renseigner un code postal."),
  city: z.string().min(1, "Veuillez renseigner une ville."),
  nameSMS: z.string().optional().nullable(),
  recommandation: z.string().optional().nullable(),
  shareCapital: z.string().optional().nullable(),
  cityAndNumberRCSRCM: z.string().optional().nullable(),
  professionalLiabilityDueDateAt: z.string().optional().nullable(),
  categoriesCooperatorUser: z
    .array(z.string().min(1, "Veuillez sélectionner une catégorie."))
    .min(1, "Veuillez sélectionner au moins une catégorie."),
  commercial: z.string().min(1, "Veuillez sélectionner un commercial."),
  hasTva: z.boolean({ required_error: "Veuillez sélectionner une option" }),
  tvaRate: z.string().optional().nullable(),
  accountNumber: z.string().optional().nullable(),
  accountNumberCoopAsCustomer: z.string().optional().nullable(),
  isActive: z.boolean({ required_error: "Veuillez sélectionner une option." }),
  isRecoverable: z.boolean({ required_error: "Veuillez sélectionner une option." }),
  hasRecurrence: z.boolean({ required_error: "Veuillez sélectionner une option." }),
  canAvanceImmediate: z.boolean({ required_error: "Veuillez sélectionner une option." }),
  hasAlertsActivated: z.boolean({ required_error: "Veuillez sélectionner une option." }),
  hasAutoDeletionQuotation: z.boolean({ required_error: "Veuillez sélectionner une option." }),
  // Iban must start with a value in allowedIbanPrefixes
  iban: z
    .string({ required_error: "Veuillez renseigner un IBAN." })
    .refine(
      (v) => {
        const ibanPrefix = v.slice(0, 2);
        return allowedIbanPrefixes.includes(ibanPrefix);
      },
      `L'IBAN renseigné doit etre Français (DOM/TOM inclus). Ex : ${allowedIbanPrefixes.join(", ")}.`
    )
    .refine((v) => isValidIBAN(v), "L'IBAN renseigné est invalide."),
  bic: z
    .string({ required_error: "Veuillez renseigner un BIC." })
    .refine((v) => isValidBIC(v), "Le BIC renseigné est invalide.")
});

export type CooperatorSchema = z.infer<typeof CooperatorSchema>;
