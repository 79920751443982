import type { GetNextPageParamFunction } from "@tanstack/react-query";
import type { TokenResponse } from "~/types/token.types";

import axios from "axios";
import Cookies from "js-cookie";

import {
  COOKIE_REFRESH_TOKEN,
  COOKIE_SESSION_VERSION,
  COOKIE_TOKEN,
  COOKIE_USER_ID
} from "~/config/cookies-constants";

export const getNextPageParamFn: GetNextPageParamFunction = (lastPage: any, allPages: any) => {
  return lastPage && lastPage.length && lastPage.length > 0 ? allPages.length + 1 : undefined;
};

function clearAuthCookiesAndRedirect() {
  Cookies.remove(COOKIE_TOKEN);
  Cookies.remove(COOKIE_REFRESH_TOKEN);
  Cookies.remove(COOKIE_USER_ID);
  window.location.href = "/";
}

let refreshAttempts = 0; // Limit the number of refresh retries

export const api = (() => {
  const axiosInstance = axios.create({
    baseURL: import.meta.env.VITE_API_URL ?? "",
    timeout: 60 * 1000 * 2, // 2 minutes
    responseType: "json",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
    // params: {
    //   "order[createdAt]": "DESC"
    // }
  });

  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = Cookies.get(COOKIE_TOKEN);
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
        // config.withCredentials = true;
      }
      if (config.url?.includes("/api/token/refresh")) {
        config.headers["Authorization"] = null;
        // config.withCredentials = true;
      }
      // If request is PATCH change the content type
      if (config.method === "patch") {
        config.headers["Content-Type"] = "application/merge-patch+json";
      }

      const sessionVersion = Cookies.get(COOKIE_SESSION_VERSION);
      const projectVersion = import.meta.env.VITE_APP_VERSION;

      if (!sessionVersion || sessionVersion !== projectVersion) {
        Cookies.set(COOKIE_SESSION_VERSION, projectVersion);
        window.location.reload();
      }

      return config;
    },

    async (error) => {
      return Promise.reject(error);
    }
  );

  // Try to refresh token if the response error is 401
  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalConfig = error.config;

      // Check if the error is a 401 (Unauthorized) and handle refresh logic
      if (error.response?.status === 401) {
        if (!originalConfig._retry && refreshAttempts < 3) {
          originalConfig._retry = true;
          refreshAttempts += 1;

          const refreshToken = Cookies.get(COOKIE_REFRESH_TOKEN);

          if (!refreshToken) {
            clearAuthCookiesAndRedirect();
            return Promise.reject(new Error("No refresh token available"));
          }

          try {
            // Request a new token using the refresh token
            const newTokenResponse = await axiosInstance.post<TokenResponse>("/api/token/refresh", {
              refresh_token: refreshToken
            });

            const { token, refresh_token } = newTokenResponse.data;

            // Update tokens and retry the original request
            Cookies.set(COOKIE_TOKEN, token ?? "", { expires: 14 });
            Cookies.set(COOKIE_REFRESH_TOKEN, refresh_token ?? "", { expires: 14 });
            originalConfig.headers["Authorization"] = `Bearer ${token}`;

            return axiosInstance(originalConfig);
          } catch (refreshError) {
            // If token refresh fails, clear cookies and redirect
            clearAuthCookiesAndRedirect();
            return Promise.reject(refreshError);
          }
        } else {
          // If retries are exhausted, clear cookies and redirect
          clearAuthCookiesAndRedirect();
          return Promise.reject(error);
        }
      }

      // For network errors or other issues, just reject the error
      if (!error.response) {
        console.error("Network error or no response:", error);
        return Promise.reject(new Error("Network error or no response received"));
      }

      return Promise.reject(error);
    }
  );
  return axiosInstance;
})();
