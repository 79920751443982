import { z } from "zod";

export const AnonymousSignFormSchema = z.object({
  paymentMethod: z.enum(["cb", "virement", "cheque", "cesu", "carte-prefinancee"], {
    required_error: "Veuillez choisir un moyen de paiement.",
    invalid_type_error: "Veuillez choisir un moyen de paiement.",
    description: "Moyen de paiement"
  }),
  conditions: z
    .boolean()
    .refine((value) => value, { message: "Vous devez accepter les conditions générales." }),
  privacy: z
    .boolean()
    .refine((value) => value, { message: "Vous devez accepter la politique de confidentialité." })
});

export const AnonymousSendPrepaidCodeFormSchema = z.object({
  code: z
    .string()
    .min(1, "Veuillez saisir votre code personnel.")
    .length(6, "Le code doit contenir 6 chiffres.")
});

export const AnonymousValidatePrepaidCodeFormSchema = z.object({
  code: z.string().min(1, "Veuillez saisir le code.").length(4, "Le code doit contenir 4 chiffres.")
});

export type AnonymousSignFormSchema = z.infer<typeof AnonymousSignFormSchema>;
export type AnonymousSendPrepaidCodeFormSchema = z.infer<typeof AnonymousSendPrepaidCodeFormSchema>;
export type AnonymousValidatePrepaidCodeFormSchema = z.infer<
  typeof AnonymousValidatePrepaidCodeFormSchema
>;
