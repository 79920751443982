import type { ComponentPropsWithoutRef } from "react";

import { Link as ReactRouterLink } from "react-router-dom";

import { cn } from "~/utils/misc";

interface LinkProps extends ComponentPropsWithoutRef<ReactRouterLink> {}

export function Link({ children, className, ...props }: LinkProps) {
  return (
    <ReactRouterLink
      className={cn("text-indigo-500 underline-offset-2 hover:underline", className)}
      {...props}
    >
      {children}
    </ReactRouterLink>
  );
}

interface ExternalLinkProps extends Omit<ComponentPropsWithoutRef<"a">, "href"> {
  to: string;
}

export function ExternalLink({ children, to, className, ...props }: ExternalLinkProps) {
  return (
    <a
      href={to}
      className={cn("text-indigo-500 underline-offset-2 hover:underline", className)}
      {...props}
    >
      {children}
    </a>
  );
}
