import type { User } from "~/types/user.types";
import type { DeepPartial } from "~/types/utils.types";

import { Fragment } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";

import { queryKeys } from "~/config/query-keys-constants";
import { useUIContext } from "~/contexts/uiContext";
import { handleApiError } from "~/services/errors";
import { updateCooperator } from "~/api/user";
import { Button } from "~/components/ui/Button/Button";
import { FormInput } from "~/components/ui/FormInput";
import { FormRow } from "~/components/ui/FormRow";
import { ProfileAccountingExportSchema } from "~/schemas/profile.schema";

export const ProfileAccountingExport = ({ userData }: { userData?: DeepPartial<User> }) => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const { toast } = useUIContext();

  // Customer mutation
  const mutation = useMutation({
    mutationFn: (data: ProfileAccountingExportSchema) =>
      updateCooperator({ userId: userData?.id ? userData.id : null, data }),
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: [queryKeys.users] });
      toast("Paramètres mis à jour avec succès.", "success");
    },
    onError(error) {
      handleApiError(error, toast);
    }
  });

  // Define form
  const form = useForm<ProfileAccountingExportSchema>({
    resolver: zodResolver(ProfileAccountingExportSchema),
    values: {
      journalCode: userData?.journalCode || "",
      tvaExportCode: userData?.tvaExportCode || "",
      administrativeCostsExportCode: userData?.administrativeCostsExportCode || "",
      totalExportCode: userData?.totalExportCode || "",
      codeAideFinanciere: userData?.codeAideFinanciere || "",
      libelleAideFinanciere: userData?.libelleAideFinanciere || ""
    }
  });

  const onSubmit = (data: ProfileAccountingExportSchema) => {
    const formatedData = {
      ...data,
      userId: userData?.id ? userData.id : null
    };

    mutation.mutate(formatedData);
  };

  return (
    <>
      <h2 className="mt-6">Paramètres export comptabilité</h2>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        {/* <p className="text-sm text-grey-600">
          Laissez les champs souhaités vides pour conserver les informations de base.
        </p> */}
        <FormRow className="lg:grid-cols-2">
          <FormInput
            label="Code journal des ventes"
            errorMessage={form.formState.errors.journalCode?.message}
            optional
            {...form.register("journalCode")}
          />
          <FormInput
            label="Code comptable de la TVA collectée"
            errorMessage={form.formState.errors.tvaExportCode?.message}
            optional
            {...form.register("tvaExportCode")}
          />
          <FormInput
            label="Code comptable des frais administratifs Hexa Coop"
            errorMessage={form.formState.errors.administrativeCostsExportCode?.message}
            optional
            {...form.register("administrativeCostsExportCode")}
          />
          <FormInput
            label="Code comptable de la prestation TTC"
            errorMessage={form.formState.errors.totalExportCode?.message}
            optional
            {...form.register("totalExportCode")}
          />
        </FormRow>

        <FormRow className="lg:grid-cols-2">
          <FormInput
            label="Libellé de l'aide financière"
            errorMessage={form.formState.errors.libelleAideFinanciere?.message}
            optional
            {...form.register("libelleAideFinanciere")}
          />
          <FormInput
            label="Code comptable de l'aide financière"
            errorMessage={form.formState.errors.codeAideFinanciere?.message}
            optional
            {...form.register("codeAideFinanciere")}
          />
        </FormRow>
        <>
          {userData?.categoriesCooperatorUser?.map((c) => (
            <Fragment key={c?.category?.id}>
              <FormRow className="lg:grid-cols-2">
                <FormInput label={`Libellé de la prestation ${c?.category?.name}`} optional />
                <FormInput
                  label={`Code comptable de la prestation ${c?.category?.name}`}
                  optional
                />
              </FormRow>
              <FormRow className="lg:grid-cols-2"></FormRow>
            </Fragment>
          ))}
        </>

        <div className="mt-12 flex justify-end gap-4 border-t border-grey-200 pt-6">
          <Button fill="clear" onClick={() => history.goBack()}>
            Annuler
          </Button>
          <Button type="submit" disabled={mutation.isLoading}>
            Enregistrer
          </Button>
        </div>
      </form>
    </>
  );
};
