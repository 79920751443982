import type { CustomerCreatedStats, StatisticsChartProps } from "~/types/statistics.types";

import { useGetStatisticsQuery } from "~/api/statistics";
import { StatisticsType } from "~/types/statistics.types";
import { extractMonthlyData } from "./utils";
import { MonthlyBarChart } from "../charts/MonthlyBarChart";
import { LoadingSpinner } from "../ui/LoadingSpinner";

export const CustomerCreatedChart = (params: StatisticsChartProps) => {
  const { data, isLoading } = useGetStatisticsQuery<CustomerCreatedStats>(
    StatisticsType.CUSTOMER_CREATED,
    params
  );

  if (isLoading) {
    return (
      <div className="my-20 flex w-full items-center justify-center gap-4">
        <LoadingSpinner /> Chargement des données
      </div>
    );
  }

  if (!data) {
    return (
      <div className="my-20 flex w-full items-center justify-center gap-4">
        Pas de donnée sur la période sélectionnée
      </div>
    );
  }
  const stats = data.customersCreatedStatsArrays;

  return (
    <>
      <div className="grid grid-cols-1 text-sm sm:grid-cols-2">
        <div>
          <b>Cumul de la période :</b> {stats[2]}
        </div>
        <div>
          <b>Moyenne de la période :</b> {stats[4].toFixed()}
        </div>
        <div>
          <b>Cumul de la période N-1 :</b> {stats[3]}
        </div>
        <div>
          <b>Moyenne de la période N-1 :</b> {stats[5].toFixed()}
        </div>
      </div>
      <div className="h-80 py-4 sm:h-96 sm:px-4">
        <MonthlyBarChart
          datasets={[
            {
              label: "Période courante",
              data: extractMonthlyData(stats[0]),
              backgroundColor: "#82a907"
            },
            {
              label: "Période N-1",
              data: extractMonthlyData(stats[1], 1),
              backgroundColor: "#82a90722",
              borderWidth: 1,
              borderColor: "#82a907"
            }
          ]}
        />
      </div>
    </>
  );
};
