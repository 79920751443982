import { createContext, useContext, useState } from "react";

type GlobalLoading = {
  action?: "create" | "delete" | "loading" | "update" | "search";
  isLoading: boolean;
};

type GlobalToast = {
  show: boolean;
  message: string;
  status?: "success" | "warning" | "error" | "info";
  title?: string | null;
};

export type UIContextProps = {
  globalLoading: GlobalLoading;
  setGlobalLoading: (_args: GlobalLoading) => void;
  globalToast: GlobalToast;
  setGlobalToast: (_args: GlobalToast) => void;
  toast: (message: string, status?: GlobalToast["status"], title?: string) => void;
  hideToast: () => void;
};

const UIContext = createContext<UIContextProps | null>(null);

export const UIProvider = ({ children }: { children: React.ReactNode }) => {
  const [globalLoading, setGlobalLoading] = useState<GlobalLoading>({ isLoading: false });
  const [globalToast, setGlobalToast] = useState<GlobalToast>({
    show: false,
    message: "",
    status: "error"
  });

  const toast = (
    message: string,
    status?: "success" | "warning" | "error" | "info",
    title?: string
  ) => {
    setGlobalToast({
      show: true,
      message: message,
      status: status || "error",
      title: title || null
    });
  };

  const hideToast = () => {
    setGlobalToast({
      show: false,
      message: ""
    });
  };

  return (
    <UIContext.Provider
      value={{
        globalLoading,
        setGlobalLoading,
        globalToast,
        setGlobalToast,
        toast,
        hideToast
      }}
    >
      {children}
    </UIContext.Provider>
  );
};

export function useUIContext() {
  const context = useContext(UIContext);

  if (!context) {
    throw new Error("useUIContext must be used within a UIProvider");
  }

  return context;
}
