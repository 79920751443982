import type { PropsWithChildren } from "react";
import type { SubmitHandler } from "react-hook-form";

import { Button } from "~/components/ui/Button/Button";

export const AdvancedSearchFormLayout = ({
  onSubmit,
  onReset,
  children
}: PropsWithChildren<{
  onSubmit: SubmitHandler<any>;
  onReset: () => void;
}>) => {
  return (
    <form onSubmit={onSubmit}>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">{children}</div>
      <div className="mt-8 flex justify-center gap-4">
        <Button fill="outline" onClick={onReset}>
          Réinitialiser
        </Button>
        <Button type="submit">Rechercher</Button>
      </div>
    </form>
  );
};
