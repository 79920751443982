import type { MenuProps } from "@headlessui/react";

import { Fragment } from "react/jsx-runtime";
import { Menu, Transition } from "@headlessui/react";
import { IonIcon } from "@ionic/react";
import { ellipsisHorizontal } from "ionicons/icons";
import { Link } from "react-router-dom";

import { cn } from "~/utils/misc";
import { Tooltip } from "~/components/Tooltip";

export interface TableActionsProps extends MenuProps<"div"> {
  itemId: number | null | undefined;
  actions?: {
    label: string;
    icon?: string;
    color?: string;
    href?: string;
    action?: (args: any) => void;
    condition?: boolean | undefined;
    disabled?: boolean | undefined;
  }[];
}

export const TableActions = ({ itemId, actions, className, ...props }: TableActionsProps) => {
  if (!actions || actions.length === 0 || actions.every((action) => action.condition === false))
    return null;

  return (
    <>
      <div className={cn("flex rounded-md shadow-sm max-2xl:hidden", className)} {...props}>
        {actions.map((action) => {
          const child = (
            <>
              {action.icon ? (
                <IonIcon icon={action.icon} className="h-[18px] w-[18px]" aria-hidden="true" />
              ) : null}
              <span className="sr-only">{action.label}</span>
            </>
          );

          if (action.condition === false) return null;

          return (
            <Tooltip
              content={action.label}
              className="group -ml-px flex items-center justify-center border border-solid border-grey-200 bg-white text-grey-500 first:rounded-l-md last:rounded-r-md hover:bg-grey-50"
              contentClassName="-translate-x-0 left-auto right-0"
              key={action.label + action.icon + action.href + action.action}
            >
              {action.href ? (
                <Link
                  to={action.href}
                  aria-disabled={action.disabled === true}
                  className={cn(
                    "inline-flex appearance-none items-center p-2.5 focus:z-10 group-first:rounded-l group-last:rounded-r",
                    action.disabled && "pointer-events-none cursor-not-allowed opacity-40",
                    action.color === "slate" && "bg-slate-100 text-slate-800 hover:bg-slate-200",
                    action.color === "red" && "bg-red-100 text-red-800 hover:bg-red-200",
                    action.color === "orange" &&
                      "bg-orange-100 text-orange-800 hover:bg-orange-200",
                    action.color === "amber" && "bg-amber-100 text-amber-800 hover:bg-amber-200",
                    action.color === "yellow" &&
                      "bg-yellow-100 text-yellow-800 hover:bg-yellow-200",
                    action.color === "lime" && "bg-lime-100 text-lime-800 hover:bg-lime-200",
                    action.color === "green" && "bg-green-100 text-green-800 hover:bg-green-200",
                    action.color === "emerald" &&
                      "bg-emerald-100 text-emerald-800 hover:bg-emerald-200",
                    action.color === "teal" && "bg-teal-100 text-teal-800 hover:bg-teal-200",
                    action.color === "cyan" && "bg-cyan-100 text-cyan-800 hover:bg-cyan-200",
                    action.color === "sky" && "bg-sky-100 text-sky-800 hover:bg-sky-200",
                    action.color === "blue" && "bg-blue-100 text-blue-800 hover:bg-blue-200",
                    action.color === "indigo" &&
                      "bg-indigo-100 text-indigo-800 hover:bg-indigo-200",
                    action.color === "violet" &&
                      "bg-violet-100 text-violet-800 hover:bg-violet-200",
                    action.color === "purple" &&
                      "bg-purple-100 text-purple-800 hover:bg-purple-200",
                    action.color === "fuchsia" &&
                      "bg-fuchsia-100 text-fuchsia-800 hover:bg-fuchsia-200",
                    action.color === "pink" && "bg-pink-100 text-pink-800 hover:bg-pink-200",
                    action.color === "rose" && "bg-rose-100 text-rose-800 hover:bg-rose-200",
                    action.color === "orange-accent" &&
                      "bg-orange-300 text-black hover:bg-orange-400"
                  )}
                >
                  {child}
                </Link>
              ) : (
                <button
                  key={action.label + action.icon + action.action}
                  type="button"
                  onClick={() => action.action && action.action(itemId)}
                  disabled={action.disabled}
                  className={cn(
                    "inline-flex appearance-none items-center p-2.5 focus:z-10 group-first:rounded-l group-last:rounded-r",
                    action.disabled && "pointer-events-none cursor-not-allowed opacity-40",
                    action.color === "slate" && "bg-slate-100 text-slate-800 hover:bg-slate-200",
                    action.color === "red" && "bg-red-100 text-red-800 hover:bg-red-200",
                    action.color === "orange" &&
                      "bg-orange-100 text-orange-800 hover:bg-orange-200",
                    action.color === "amber" && "bg-amber-100 text-amber-800 hover:bg-amber-200",
                    action.color === "yellow" &&
                      "bg-yellow-100 text-yellow-800 hover:bg-yellow-200",
                    action.color === "lime" && "bg-lime-100 text-lime-800 hover:bg-lime-200",
                    action.color === "green" && "bg-green-100 text-green-800 hover:bg-green-200",
                    action.color === "emerald" &&
                      "bg-emerald-100 text-emerald-800 hover:bg-emerald-200",
                    action.color === "teal" && "bg-teal-100 text-teal-800 hover:bg-teal-200",
                    action.color === "cyan" && "bg-cyan-100 text-cyan-800 hover:bg-cyan-200",
                    action.color === "sky" && "bg-sky-100 text-sky-800 hover:bg-sky-200",
                    action.color === "blue" && "bg-blue-100 text-blue-800 hover:bg-blue-200",
                    action.color === "indigo" &&
                      "bg-indigo-100 text-indigo-800 hover:bg-indigo-200",
                    action.color === "violet" &&
                      "bg-violet-100 text-violet-800 hover:bg-violet-200",
                    action.color === "purple" &&
                      "bg-purple-100 text-purple-800 hover:bg-purple-200",
                    action.color === "fuchsia" &&
                      "bg-fuchsia-100 text-fuchsia-800 hover:bg-fuchsia-200",
                    action.color === "pink" && "bg-pink-100 text-pink-800 hover:bg-pink-200",
                    action.color === "rose" && "bg-rose-100 text-rose-800 hover:bg-rose-200",
                    action.color === "orange-accent" &&
                      "bg-orange-300 text-black hover:bg-orange-400"
                  )}
                >
                  {child}
                </button>
              )}
            </Tooltip>
          );
        })}
      </div>

      <Menu as="div" className={cn("relative text-left 2xl:hidden", className)} {...props}>
        <div>
          <Menu.Button className="mx-auto flex items-center justify-center p-2 text-grey-500 hover:text-grey-600">
            <IonIcon
              icon={ellipsisHorizontal}
              className="pointer-events-none h-5 w-5"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-full top-0 z-10 mr-2 w-max origin-top-right divide-y divide-grey-100 overflow-visible rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              {actions.map((action) => {
                const child = (
                  <>
                    {action.icon ? (
                      <IonIcon icon={action.icon} className="h-5 w-5" aria-hidden="true" />
                    ) : null}
                    {action.label}
                  </>
                );
                if (action.condition === false) return null;
                return (
                  <Menu.Item key={`${action.label}-${action.action || action.href}`}>
                    {({ active }) =>
                      action.href ? (
                        <Link
                          to={action.href}
                          aria-disabled={action.disabled}
                          className={cn(
                            active ? "bg-grey-100 text-grey-900" : "text-grey-700",
                            action.disabled && "pointer-events-none cursor-not-allowed opacity-40",
                            "flex w-full items-center gap-4 px-4 py-2 text-sm"
                          )}
                        >
                          {child}
                        </Link>
                      ) : (
                        <button
                          onClick={() => action.action && action.action(itemId)}
                          className={cn(
                            active ? "bg-grey-100 text-grey-900" : "text-grey-700",
                            action.disabled && "pointer-events-none cursor-not-allowed opacity-40",
                            "flex w-full items-center gap-4 px-4 py-2 text-sm"
                          )}
                        >
                          {child}
                        </button>
                      )
                    }
                  </Menu.Item>
                );
              })}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
};
