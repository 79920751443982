import { useCallback, useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import { Circle, GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api";
import { useQuery } from "@tanstack/react-query";
import { Controller, useForm } from "react-hook-form";

import { queryKeys } from "~/config/query-keys-constants";
import { useDebounce } from "~/hooks/useDebounce";
import { useFileDeliver } from "~/hooks/useFileDeliver";
import { getCooperatorCategories } from "~/api/cooperator-categories";
import { getCooperatorsByGeolocation } from "~/api/geoloc";
import { getGouvAddresses } from "~/api/gouv-addresses";
import { Button } from "~/components/ui/Button/Button";
import { FormCheckbox } from "~/components/ui/FormCheckbox";
import { FormComboBox } from "~/components/ui/FormComboBox";
import { FormInput } from "~/components/ui/FormInput";
import { FormRow } from "~/components/ui/FormRow";
import { FormSelect } from "~/components/ui/FormSelect";
import { FormTitle } from "~/components/ui/FormTitle";
import { LoadingSpinner } from "~/components/ui/LoadingSpinner";

type Marker = {
  lat: number;
  lng: number;
};

export const AdministrationCooperatorsGeolocalisation = () => {
  const { openFile } = useFileDeliver();

  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [geocoder, setGeocoder] = useState<google.maps.Geocoder | null>(null);
  const [markers, setMarkers] = useState<Marker[]>([]);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY
  });

  const onLoad = useCallback((map: google.maps.Map) => {
    setMap(map);
  }, []);

  // Addresses search
  const [addressSearch, setAddressSearch] = useState("");
  const debouncedAddressSearch = useDebounce<string>(addressSearch, 500);
  const { data: addresses, isFetching: isAddressesFetching } = useQuery({
    queryKey: [queryKeys.addresses, { search: debouncedAddressSearch }],
    queryFn: () => getGouvAddresses({ search: debouncedAddressSearch }),
    enabled: debouncedAddressSearch.length > 3
  });

  // Prestations list
  const categoriesQueryParams = { itemsPerPage: 1500 };
  const { data: categories } = useQuery({
    queryKey: [queryKeys.cooperatorCategories, categoriesQueryParams],
    queryFn: () => getCooperatorCategories(categoriesQueryParams)
  });

  // Cooperators list for map
  const [queryParams, setQueryParams] = useState<{
    // firstName: string | null;
    // lastName: string | null;
    latitude: number | null;
    longitude: number | null;
    radius: number | null;
    prestations: number[];
  }>({
    // firstName: null,
    // lastName: null,
    latitude: null,
    longitude: null,
    radius: null,
    prestations: []
  });
  const { data: cooperators, isFetching: isCooperatorsFetching } = useQuery({
    queryKey: [queryKeys.users, queryParams],
    queryFn: () => getCooperatorsByGeolocation(queryParams),
    enabled: !!queryParams.latitude && !!queryParams.longitude && !!queryParams.radius
  });

  const [selectedCooperators, setSelectedCooperators] = useState<number[]>([]);

  useEffect(() => {
    if (map && markers.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      markers.forEach((marker) => {
        bounds.extend(new window.google.maps.LatLng(marker.lat, marker.lng));
      });
      map.fitBounds(bounds);
    } else if (map && !queryParams.latitude && !queryParams.longitude && !(markers.length > 0)) {
      map.setCenter({
        lat: 46.603354,
        lng: 1.888334
      });
      map.setZoom(6);
    }
  }, [map, markers, queryParams]);

  useEffect(() => {
    if (isLoaded && map) {
      setGeocoder(new window.google.maps.Geocoder());
    }
  }, [isLoaded, map]);

  useEffect(() => {
    if (cooperators && cooperators.length > 0) {
      setMarkers(
        cooperators.map((cooperator) => ({
          lat: parseFloat(cooperator?.cooperator?.latitude || "0"),
          lng: parseFloat(cooperator?.cooperator?.longitude || "0")
        }))
      );
    }
  }, [cooperators]);

  const form = useForm({
    defaultValues: {
      // firstName: "",
      // lastName: "",
      address: "",
      zipcode: "",
      city: "",
      radius: 5,
      prestations: []
    }
  });

  const onSubmit = (data: any) => {
    geocoder?.geocode(
      { address: `${data?.address} ${data.zipcode} ${data.city}` },
      (results, status) => {
        if (status === "OK") {
          const location = results?.[0]?.geometry.location;
          setQueryParams({
            // firstName: data.firstname || null,
            // lastName: data.lastname || null,
            latitude: location?.lat() || null,
            longitude: location?.lng() || null,
            radius: data.radius || null,
            prestations: data.prestations || []
          });
        }
      }
    );
  };

  return (
    <>
      <h2 className="mt-6">Géolocalisation des coopérateurs</h2>
      <form className="mb-4" onSubmit={form.handleSubmit(onSubmit)}>
        <FormTitle as="h3">Rechercher des coopérateurs</FormTitle>
        {/* <FormRow>
          <FormInput
            label="Prénom"
            optional
            errorMessage={form.formState.errors.firstName?.message}
            {...form.register("firstName")}
          />
          <FormInput
            label="Nom"
            optional
            errorMessage={form.formState.errors.lastName?.message}
            {...form.register("lastName")}
          />
        </FormRow> */}
        <FormRow>
          <Controller
            name="address"
            control={form.control}
            render={({ field: { onChange, onBlur, ref } }) => {
              return (
                <FormComboBox
                  ref={ref}
                  label="Adresse"
                  onChange={(value) => {
                    const address = addresses?.find((address) => address.id === value);
                    if (!address) return onChange(value || "");
                    form.setValue("zipcode", address.postcode || "", { shouldValidate: true });
                    form.setValue("city", address.district || address.city || "", {
                      shouldValidate: true
                    });
                    form.trigger("address");
                    // setSelectedAddress(address);
                    onChange(
                      address.name ||
                        (address.housenumber && address.street
                          ? `${address.housenumber} ${address.street}`
                          : "")
                    );
                  }}
                  onBlur={onBlur}
                  onSearchChange={setAddressSearch}
                  options={
                    addresses?.map((address) => ({
                      id: address.id || 0,
                      value: address.id || "",
                      label: address.label || "",
                      labelInInput: address.name || `${address.housenumber} ${address.street}`
                    })) || []
                  }
                  optional
                  errorMessage={form.formState.errors.address?.message}
                  isLoading={isAddressesFetching}
                  allowCustomValue={true}
                />
              );
            }}
          />

          <FormInput
            label="Code postal"
            optional
            errorMessage={form.formState.errors.zipcode?.message}
            {...form.register("zipcode")}
          />
          <FormInput
            label="Ville"
            optional
            errorMessage={form.formState.errors.city?.message}
            {...form.register("city")}
          />
          <FormSelect
            label="Rayon (km)"
            optional
            errorMessage={form.formState.errors.radius?.message}
            {...form.register("radius")}
          >
            <option value="5">5 km</option>
            <option value="10">10 km</option>
            <option value="15">15 km</option>
            <option value="20">20 km</option>
            <option value="50">50 km</option>
            <option value="100">100 km</option>
            <option value="200">200 km</option>
          </FormSelect>
        </FormRow>

        <fieldset className="mt-8 grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          <legend className="mb-4 text-sm font-medium">Prestations associées</legend>
          {categories?.map((category) => (
            <FormCheckbox
              key={category.id}
              id={`prestations-${category.id}`}
              label={category.name}
              value={category.id}
              {...form.register("prestations")}
            />
          ))}
        </fieldset>

        <Button type="submit" className="mx-auto mt-8 block w-max">
          Rechercher des coopérateurs
        </Button>
      </form>
      <div className="mt-4 grid grid-cols-1 gap-y-4 md:grid-cols-3 md:gap-x-4">
        <div className="col-span-2">
          {!isLoaded ? (
            <LoadingSpinner />
          ) : loadError ? (
            <div>Une erreur est survenue lors du chargement de la carte.</div>
          ) : (
            <GoogleMap
              mapContainerClassName="px-8 mx-auto rounded-md overflow-hidden w-full h-[480px] sm:h-[640px] relative"
              onBoundsChanged={() => {
                // const zoom = map?.getZoom();
                // if (zoom && zoom > 19) map?.setZoom(13);
              }}
              options={{
                controlSize: 24,
                fullscreenControl: false,
                gestureHandling: "cooperative"
                // restriction: {
                //   // latLngBounds: { north: 51.5, south: 41.5, west: -5.5, east: 9.5 },
                //   // strictBounds: false
                // }
              }}
              onLoad={onLoad}
            >
              <>
                {markers.map((marker, index) => (
                  <MarkerF
                    key={index}
                    position={{
                      lat: marker.lat,
                      lng: marker.lng
                    }}
                    // label={`${index + 1}`}
                    options={{
                      label: {
                        text: `${index + 1}`,
                        className: "text-sm text-white font-medium"
                      }
                    }}
                    title="Coopérateur"
                    clickable={true}
                  />
                ))}
                <MarkerF
                  position={{
                    lat: queryParams.latitude || 0,
                    lng: queryParams.longitude || 0
                  }}
                  title="Adresse"
                  clickable={true}
                />
                <Circle
                  center={{
                    lat: queryParams.latitude || 0,
                    lng: queryParams.longitude || 0
                  }}
                  radius={queryParams.radius ? queryParams.radius * 1000 * 1.60935 : 0}
                  options={{
                    fillColor: "#386982",
                    fillOpacity: 0.1,
                    strokeColor: "#386982",
                    strokeOpacity: 0.6,
                    strokeWeight: 2,
                    clickable: false,
                    draggable: false,
                    editable: false,
                    visible: true,
                    zIndex: 1
                  }}
                />
                <Transition
                  show={isCooperatorsFetching}
                  enter="transition-opacity duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute inset-0 flex items-center justify-center bg-grey-900 bg-opacity-60">
                    <LoadingSpinner size="lg" className="z-50" />
                  </div>
                </Transition>
              </>
            </GoogleMap>
          )}
        </div>
        <div className="col-span-1">
          <div className="h-[480px] space-y-2 overflow-y-auto rounded-lg border border-grey-200 p-2 sm:h-[592px]">
            {cooperators && cooperators.length > 0
              ? cooperators.map((cooperator, index) =>
                  cooperator ? (
                    <div
                      key={cooperator.cooperator?.id}
                      className="flex items-center justify-start gap-4 rounded-md border border-grey-100 p-2 text-sm shadow"
                    >
                      <FormCheckbox
                        checked={selectedCooperators.includes(cooperator.cooperator?.id || 0)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedCooperators((prev) => [
                              ...prev,
                              cooperator.cooperator?.id || 0
                            ]);
                          } else {
                            setSelectedCooperators((prev) =>
                              prev.filter((id) => id !== cooperator.cooperator?.id)
                            );
                          }
                        }}
                      />
                      <div className="flex h-6 w-6 shrink-0 items-center justify-center rounded-full bg-red-500 text-sm text-black">
                        {index + 1}
                      </div>
                      <div>
                        <div className="font-medium">{cooperator.cooperator?.companyName}</div>
                        <div>
                          {cooperator.cooperator?.firstname} {cooperator.cooperator?.lastname}
                        </div>
                        <div>
                          {cooperator.cooperator?.address}
                          <br />
                          {cooperator.cooperator?.zipcode} {cooperator.cooperator?.city}
                        </div>
                        <div>{cooperator.cooperator?.email}</div>
                        <div>
                          {cooperator.cooperator?.fixe ? `${cooperator.cooperator.fixe} - ` : ""}
                          {cooperator.cooperator?.mobile || ""}
                        </div>
                      </div>
                    </div>
                  ) : null
                )
              : null}
          </div>
          {cooperators && cooperators.length > 0 ? (
            <Button
              className="mt-2 w-full"
              onClick={() => {
                openFile({
                  itemType: "geoloc-cooperators-pdf",
                  itemParams: {
                    geoloc: {
                      // firstName: queryParams.firstName,
                      // lastName: queryParams.lastName,
                      latitude: queryParams.latitude,
                      longitude: queryParams.longitude,
                      radius: queryParams.radius,
                      prestations: queryParams.prestations,
                      selectedCooperators
                    }
                  }
                });
              }}
            >
              Exporter les coopérateurs sélectionnés (en PDF)
            </Button>
          ) : null}
        </div>
      </div>
    </>
  );
};
