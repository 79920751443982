import type { PropsWithChildren } from "react";
import type { ModalProps } from "~/components/ui/Modal/Modal";

import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { useForm } from "react-hook-form";

import { queryKeys } from "~/config/query-keys-constants";
import { useUIContext } from "~/contexts/uiContext";
import { handleApiError } from "~/services/errors";
import { queryClient } from "~/api/_queryClient";
import { updateTaskQuotation } from "~/api/quotation";
import { Button } from "~/components/ui/Button/Button";
import { FormDatePicker } from "~/components/ui/FormDatePicker";
import { FormRow } from "~/components/ui/FormRow";
import { Modal } from "~/components/ui/Modal/Modal";
import { ReprogramQuotationTaskSchema } from "~/schemas/quotation-task.schema";

interface ReprogramQuotationTaskModalProps extends ModalProps {
  taskId: number;
  withForm?: boolean;
}

export const ReprogramQuotationTaskModal = ({
  taskId,
  ...props
}: PropsWithChildren<ReprogramQuotationTaskModalProps>) => {
  const { toast } = useUIContext();

  const reprogramTaskMutation = useMutation({
    mutationFn: updateTaskQuotation,
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.taskQuotations]);
      queryClient.invalidateQueries([queryKeys.quotations]);
      props.onClose();
      toast("La date d'envoi a été modifié avec succès.", "success");
    },
    onError(error) {
      handleApiError(error, toast);
    }
  });

  const form = useForm<ReprogramQuotationTaskSchema>({
    resolver: zodResolver(ReprogramQuotationTaskSchema),
    values: {
      scheduledAt: ""
    }
  });

  const onSubmit = (data: ReprogramQuotationTaskSchema) => {
    reprogramTaskMutation.mutate({ id: taskId, data });
  };

  return (
    <Modal title="Réglements" {...props}>
      <h2 className="text-xl font-semibold">Reprogrammer la date d&apos;envoi</h2>

      <form className="mt-4" onSubmit={form.handleSubmit(onSubmit)}>
        <FormRow className="lg:grid-cols-2">
          <FormDatePicker
            label="Date d'envoi"
            errorMessage={form.formState.errors.scheduledAt?.message}
            type="datetime-local"
            {...form.register("scheduledAt")}
          />
        </FormRow>

        <div className="mt-12 flex justify-end gap-4 border-t border-grey-200 pt-6">
          <Button fill="clear" onClick={() => props.onClose()}>
            Annuler
          </Button>
          <Button type="submit" disabled={reprogramTaskMutation.isLoading}>
            Reprogrammer
          </Button>
        </div>
      </form>
    </Modal>
  );
};
