import type { PropsWithChildren } from "react";

import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonPopover,
  IonToolbar
} from "@ionic/react";
import { useQuery } from "@tanstack/react-query";
import { closeOutline, menuOutline, personOutline } from "ionicons/icons";
import { Link, useLocation } from "react-router-dom";

import { userRoles } from "~/config/api-constants";
import { queryKeys } from "~/config/query-keys-constants";
import { cn } from "~/utils/misc";
import { useAuthContext } from "~/contexts/authContext";
import { getConfigs } from "~/api/config";
import { Footer } from "~/components/Footer";
import { TopMessage } from "~/components/TopMessage";
import { Button } from "~/components/ui/Button/Button";
import logoText from "~/assets/logo/logo-text.png";

interface DashboardCategories {
  url: string;
  title: string;
  adminOnly?: boolean;
  cooperatorOnly?: boolean;
}

const dashboardCategories: DashboardCategories[] = [
  {
    title: "Relevés de prestation",
    url: "/quotations"
  },
  {
    title: "Clients",
    url: "/customers"
  },
  {
    title: "Espace coopérateur",
    url: "/cooperator-dashboard"
  },
  {
    title: "Attestations",
    url: "/attestations"
  },
  {
    title: "Administration",
    url: "/administration",
    adminOnly: true
  },
  {
    title: "Contrat de préfinancement",
    url: "/prefinancing-contracts",
    adminOnly: true
  },
  {
    title: "Statistiques",
    url: "/statistics"
  },
  {
    title: "Mes paramètres",
    url: "/profile",
    cooperatorOnly: true
  },
  {
    title: "Je recommande",
    url: "/recommendation"
  }
];

export const DashboardLayout = ({ children }: PropsWithChildren) => {
  const { currentUser, isAdmin, isAuth, logout } = useAuthContext();
  const location = useLocation();

  const { data: configs } = useQuery({
    queryKey: [queryKeys.configsWithoutPagination],
    queryFn: () => getConfigs({}),
    enabled: !!isAuth
  });

  return (
    <>
      <div id="dashboard-content" className="ion-page">
        <IonHeader className="!shadow-md">
          <TopMessage configs={configs ?? null} />

          {/* Desktop top toolbar (with nav) */}
          <div className="hidden bg-primary px-2 sm:px-4 md:px-6 sidebar-breakpoint:block">
            <div className="flex items-center justify-between gap-8 py-3">
              <Link to="/" className="flex items-center divide-x divide-white">
                <img src={logoText} alt="Logo Hexa Coop" className="h-10 pr-6" />
                <p className="px-6 text-lg font-semibold text-white">Mon portail coopérateur</p>
              </Link>

              <Button id="popover-button" color="light" fill="outline" className="font-semibold">
                {currentUser?.firstname} {currentUser?.lastname}
                <IonIcon slot="end" icon={personOutline} />
              </Button>
              <UserMenu trigger="popover-button" onLogout={logout} />
            </div>
            <div className="flex items-center justify-start gap-2 border-t border-t-white/80 bg-primary py-2">
              {dashboardCategories.map((page) => {
                if (page.adminOnly && !isAdmin) {
                  return null;
                }
                if (
                  page.cooperatorOnly &&
                  !currentUser?.roles?.includes(userRoles.ROLE_COOPERATOR)
                ) {
                  return null;
                }
                return (
                  <Link
                    key={page.url}
                    className={cn(
                      "block rounded-lg px-2.5 py-2 text-sm font-semibold text-white",
                      location.pathname.includes(page.url)
                        ? "bg-primary-700 hover:bg-primary-700/80"
                        : "bg-primary hover:bg-primary-500/50"
                    )}
                    to={page.url}
                  >
                    {page.title}
                  </Link>
                );
              })}
            </div>
          </div>

          {/* Mobile top toolbar */}
          <IonToolbar color="primary" className="sidebar-breakpoint:hidden">
            <IonButtons slot="start">
              <IonMenuToggle>
                <IonButton fill="clear">
                  <IonIcon slot="icon-only" icon={menuOutline} />
                </IonButton>
              </IonMenuToggle>
            </IonButtons>

            <div className="flex justify-center">
              <Link to="/" className="mx-auto">
                <img src={logoText} alt="Logo Hexa Coop" className="mx-auto h-8" />
              </Link>
            </div>

            <IonButtons slot="end">
              <Button id="popover-button-mobile" fill="clear" color="light">
                <IonIcon slot="icon-only" icon={personOutline} />
              </Button>
              <UserMenu trigger="popover-button-mobile" onLogout={logout} />
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        {/* Dashboard content */}
        <IonContent>{children}</IonContent>
        <Footer />
      </div>

      {/* Dashboard mobile side menu */}
      <IonMenu side="start" contentId="dashboard-content">
        <IonContent color="primary" className="relative h-full">
          <IonMenuToggle className="absolute right-0 top-2">
            <IonButton fill="clear" color="light">
              <IonIcon slot="icon-only" icon={closeOutline} className="h-7 w-7" />
            </IonButton>
          </IonMenuToggle>
          <IonMenuToggle>
            <Link to="/">
              <img
                src={logoText}
                alt="Logo Hexa Coop"
                className="mx-auto hidden h-32 sidebar-breakpoint:block"
              />
            </Link>
          </IonMenuToggle>

          <div className="mt-12 border-b border-grey-200 pb-4 pl-8 text-white sidebar-breakpoint:hidden">
            <div className="text-base font-medium">
              {currentUser?.firstname} {currentUser?.lastname}
            </div>
            <div className="text-sm font-normal">{currentUser?.email}</div>
          </div>

          <div className="bg-primary">
            {dashboardCategories.map((page) => {
              if (page.adminOnly && !isAdmin) {
                return null;
              }
              if (page.cooperatorOnly && !currentUser?.roles?.includes(userRoles.ROLE_COOPERATOR)) {
                return null;
              }
              return (
                <IonMenuToggle key={page.url}>
                  <Link
                    className={cn(
                      "block px-8 py-4 font-medium text-white",
                      location.pathname.includes(page.url)
                        ? "bg-primary-700 hover:bg-primary-700/80"
                        : "bg-primary hover:bg-primary-500/50"
                    )}
                    to={page.url}
                  >
                    <IonLabel>{page.title}</IonLabel>
                  </Link>
                </IonMenuToggle>
              );
            })}
          </div>
          <Link to="/">
            <img
              src={logoText}
              alt="Logo Hexa Coop"
              className="mx-auto hidden h-32 w-32 sidebar-breakpoint:hidden"
            />
          </Link>
        </IonContent>
      </IonMenu>
    </>
  );
};

export const UserMenu = ({ trigger, onLogout }: { trigger: string; onLogout: () => void }) => {
  return (
    <IonPopover trigger={trigger} dismissOnSelect={true} mode="ios">
      <IonContent>
        <IonList>
          <IonItem button={true} detail={false} routerLink="/profile">
            Mon profil
          </IonItem>
          <IonItem button={true} detail={false} onClick={onLogout}>
            <span className="text-red-600">Deconnexion</span>
          </IonItem>
        </IonList>
      </IonContent>
    </IonPopover>
  );
};
