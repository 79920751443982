import type { JobSchema } from "~/schemas/job.schema";
import type { Job } from "~/types/job.types";
import type { DeepPartial } from "~/types/utils.types";

import { INFINITE_SCROLL_LIMIT } from "~/config/api";
import { api } from "./_config";

export const getJobs = async ({ page, itemsPerPage }: { page?: number; itemsPerPage?: number }) => {
  const response = await api.get<DeepPartial<Job[]>>("/api/admin/jobs", {
    params: { page, itemsPerPage: itemsPerPage ?? INFINITE_SCROLL_LIMIT }
  });
  return response.data;
};

export const getJob = async ({ id }: { id: Job["id"] }) => {
  const response = await api.get<Job>(`/api/admin/jobs/${id}`);
  return response.data;
};

export const createJob = async ({ data }: { data: JobSchema }) => {
  const response = await api.post("/api/admin/jobs", data);
  return response.data;
};

export const updateJob = async ({ id, data }: { id: Job["id"]; data: JobSchema }) => {
  const response = await api.patch(`/api/admin/jobs/${id}`, data);
  return response.data;
};

export const deleteJob = async ({ id }: { id: Job["id"] }) => {
  const response = await api.delete(`/api/admin/jobs/${id}`);
  return response.data;
};
