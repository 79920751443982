import type { Quotation } from "~/types/quotation.types";
import type { User } from "~/types/user.types";
import type { DeepPartial } from "~/types/utils.types";

import { useEffect, useMemo, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Controller, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router";

import { quotationStates, userRoles } from "~/config/api-constants";
import { queryKeys } from "~/config/query-keys-constants";
import { formatMoney } from "~/utils/misc";
import { useUIContext } from "~/contexts/uiContext";
import { useDebounce } from "~/hooks/useDebounce";
import { useModal } from "~/hooks/useModal";
import { handleApiError } from "~/services/errors";
import { getCooperatorCategories } from "~/api/cooperator-categories";
import { createQuotation, getQuotation, updateQuotation } from "~/api/quotation";
import { getUsers } from "~/api/user";
import { ConfirmationModal } from "~/components/Modals/ConfirmationModal";
import { Button } from "~/components/ui/Button/Button";
import { FormComboBox } from "~/components/ui/FormComboBox";
import { FormInput } from "~/components/ui/FormInput";
import { FormRow } from "~/components/ui/FormRow";
import { FormSelect } from "~/components/ui/FormSelect";
import { FormTextArea } from "~/components/ui/FormTextArea";
import { FormTitle } from "~/components/ui/FormTitle";
import { LoadingSpinner } from "~/components/ui/LoadingSpinner";
import { PrefinancingContractSchema } from "~/schemas/quotation.schema";

const DEFAULT_PRESTATION_PRICE = "2309.09";

export const CreateEditPreFinancingContract = ({ editMode = false }: { editMode?: boolean }) => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const { quotation_id: quotationId } = useParams<{ quotation_id?: string }>();
  const { toast } = useUIContext();
  const { openModal, closeModal, renderModal } = useModal();

  // Get the quotation when form is in edit mode
  const { data: quotationToEdit, isLoading: isQuotationLoading } = useQuery({
    queryKey: [queryKeys.quotations, quotationId],
    queryFn: quotationId ? () => getQuotation({ id: parseInt(quotationId) }) : () => null,
    enabled: !!quotationId
  });

  // Financers list for financer selection
  const [selectedFinancer, setSelectedFinancer] = useState<DeepPartial<User> | null>(
    quotationToEdit?.cooperator || null
  );
  const [financerSearch, setFinancerSearch] = useState("");
  const debouncedFinancerSearch = useDebounce<string>(financerSearch, 500);
  const financersQueryParams = {
    role: userRoles.ROLE_FINANCER,
    searchName: debouncedFinancerSearch,
    sort: { key: "lastname", value: "ASC" }
  } as const;
  const { data: financers, isLoading: isFinancersLoading } = useQuery({
    queryKey: [queryKeys.users, financersQueryParams],
    queryFn: () => getUsers({ ...financersQueryParams })
  });

  // Beneficiaries list for beneficiary selection
  const [selectedBeneficiary, setSelectedBeneficiary] = useState<DeepPartial<User> | null>(
    quotationToEdit?.customer || null
  );
  const [beneficiarySearch, setBeneficiarySearch] = useState("");
  const debouncedBeneficiarySearch = useDebounce<string>(beneficiarySearch, 500);
  const beneficiariesSearchParams = {
    role: userRoles.ROLE_BENEFICIARY,
    searchName: debouncedBeneficiarySearch,
    financerId: selectedFinancer?.id || null,
    sort: { key: "lastname", value: "ASC" },
    itemsPerPage: 1500
  } as const;
  const { data: beneficiaries, isLoading: isBeneficiariesLoading } = useQuery({
    queryKey: [queryKeys.users, beneficiariesSearchParams],
    queryFn: () => getUsers({ ...beneficiariesSearchParams }),
    enabled: !!selectedFinancer?.id
  });

  // Get prefinancing category
  const { data: prefinancingPrestations, isLoading: isPrefinancingPrestationLoading } = useQuery({
    queryKey: [queryKeys.cooperatorCategories, { isContract: true }],
    queryFn: () => getCooperatorCategories({ isContract: true })
  });

  const prefinancingPrestation = prefinancingPrestations?.[0];

  const defaultQuotationRow = useMemo(
    () => ({
      category: prefinancingPrestation,
      nameService: prefinancingPrestation?.name || "",
      quantity: "1",
      tva: prefinancingPrestation?.tva || "",
      priceUnit: DEFAULT_PRESTATION_PRICE,
      priceUnitTTC:
        parseFloat(DEFAULT_PRESTATION_PRICE) *
        (1 + parseFloat(prefinancingPrestation?.tva || "0") / 100),
      totalHT: parseFloat(DEFAULT_PRESTATION_PRICE),
      totalTTC:
        parseFloat(DEFAULT_PRESTATION_PRICE) *
        (1 + parseFloat(prefinancingPrestation?.tva || "0") / 100)
    }),
    [prefinancingPrestation]
  );

  // Quotation rows
  const [quotationRows, setQuotationRows] = useState<DeepPartial<Quotation["quotationRow"]>>([
    defaultQuotationRow
  ]);

  useEffect(() => {
    if (prefinancingPrestation) {
      setQuotationRows([defaultQuotationRow]);
    }
  }, [prefinancingPrestation, defaultQuotationRow]);

  // Calculate totals
  const totalHT = quotationRows.reduce((acc, row) => acc + (row?.totalHT || 0), 0);
  const totalTVA = quotationRows.reduce(
    (acc, row) => acc + (row?.totalHT || 0) * (parseFloat(row?.tva || "0") / 100),
    0
  );
  const totalTTC = quotationRows.reduce((acc, row) => acc + (row?.totalTTC || 0), 0);

  const defaultValues = useMemo(
    () => ({
      cooperator: selectedFinancer?.id?.toString() || "",
      customer: selectedBeneficiary?.id?.toString() || "",
      isRecurrenceMaster: quotationToEdit?.isRecurrenceMaster || false,
      isAvanceImmediate: quotationToEdit?.isAvanceImmediate || false,
      isRecoverable: quotationToEdit?.isRecoverable || false,
      recurrence: null,
      quotationRow: quotationToEdit?.quotationRow?.map((row) => ({
        category: (prefinancingPrestation ? row?.category?.id?.toString() : "") || "",
        tva: row?.tva || "",
        quantity: row?.quantity || "",
        priceUnit: row?.priceUnit || "",
        priceUnitTTC: parseFloat(row?.priceUnit || "0") * (1 + parseFloat(row?.tva || "0") / 100),
        totalHT: row?.totalHT || 0,
        totalTTC: row?.totalTTC || 0
      })) || [{ ...defaultQuotationRow, category: prefinancingPrestation?.id.toString() || "" }]
    }),
    [
      defaultQuotationRow,
      quotationToEdit,
      selectedBeneficiary,
      selectedFinancer,
      prefinancingPrestation
    ]
  );

  // Define form
  const form = useForm<PrefinancingContractSchema>({
    resolver: zodResolver(PrefinancingContractSchema),
    defaultValues
  });

  useEffect(() => {
    form.reset(defaultValues, { keepDirtyValues: true });
  }, [defaultValues, form]);

  // Quotation mutation
  const quotationMutation = useMutation({
    mutationFn: (data: PrefinancingContractSchema) =>
      editMode && quotationId
        ? updateQuotation({ data, id: parseInt(quotationId || "") })
        : createQuotation({ data }),
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: [queryKeys.quotations] });
      toast(
        editMode ? "Le contrat a été modifié avec succès." : "Le contrat a été créé avec succès.",
        "success"
      );
      history.push("/prefinancing-contracts/waiting");
    },
    onError(error) {
      handleApiError(error, toast);
    }
  });
  const onSubmit = ({
    state,
    data
  }: {
    state: number | null | undefined;
    data: PrefinancingContractSchema;
  }) => {
    const formatedData = {
      ...data,
      ...(state !== undefined ? { state } : {}),
      typeQuotation: 1,
      quotationRow: quotationRows.map((row) => {
        if (!row?.category?.id) throw new Error("Veuillez sélectionner une prestation.");
        return {
          category: row.category.id.toString() || "",
          nameService: row.category.name || "",
          quantity: row.quantity || "",
          tva: row.tva || "",
          priceUnit: row.priceUnit || ""
        };
      })
    };

    quotationMutation.mutate(formatedData);
  };

  // Set first search values for comboboxes
  useEffect(() => {
    if (quotationToEdit) {
      setFinancerSearch(quotationToEdit.cooperator?.lastname || "");
      setSelectedFinancer(quotationToEdit.cooperator || null);
      setBeneficiarySearch(quotationToEdit.customer?.lastname || "");
      setSelectedBeneficiary(quotationToEdit.customer || null);
      setQuotationRows(quotationToEdit.quotationRow || []);
    }
  }, [quotationToEdit]);

  if (!!quotationId && isQuotationLoading) {
    return <LoadingSpinner size="lg" fullPage />;
  }

  if (!!quotationId && !quotationToEdit) {
    return <p className="text-center">Le contrat à éditer est introuvable.</p>;
  }

  return (
    <>
      <h2 className="mt-6">
        {editMode
          ? `Édition d'un contrat : ${quotationToEdit?.idNormalized}`
          : "Création d'un contrat"}
      </h2>
      <form>
        <FormTitle as="h3">Paramètres du contrat</FormTitle>
        <FormRow>
          <Controller
            name="cooperator"
            control={form.control}
            render={({ field: { onChange, onBlur, ref } }) => {
              return (
                <FormComboBox
                  label="Financeur"
                  onBlur={onBlur}
                  onChange={(value) => {
                    const cooperator = financers?.find(
                      (cooperator) => cooperator?.id?.toString() === value
                    );
                    setSelectedFinancer(cooperator || null);
                    setSelectedBeneficiary(null);
                    form.setValue("customer", "");
                    onChange(value);
                  }}
                  onSearchChange={setFinancerSearch}
                  options={
                    financers?.map((coop) => ({
                      id: coop?.id || 0,
                      label: `${coop?.lastname || ""} ${coop?.firstname || ""}`,
                      value: coop?.id?.toString() || ""
                    })) || []
                  }
                  defaultOption={
                    quotationToEdit?.cooperator?.id
                      ? {
                          id: quotationToEdit.cooperator.id,
                          label: `${quotationToEdit.cooperator.lastname} ${quotationToEdit.cooperator.firstname}`,
                          value: quotationToEdit.cooperator.id.toString()
                        }
                      : null
                  }
                  errorMessage={form.formState.errors.cooperator?.message}
                  isLoading={isFinancersLoading}
                  ref={ref}
                />
              );
            }}
          />

          {selectedFinancer ? (
            <div className="col-span-1">
              <h4 className="mb-2 text-sm font-medium leading-none text-grey-900">
                Coordonnées du financeur
              </h4>
              <div className="space-y-1.5 rounded-lg border border-grey-100 bg-grey-50 p-4">
                {selectedFinancer.companyName ? (
                  <div className="font-semibold">{selectedFinancer.companyName}</div>
                ) : null}
                {selectedFinancer.lastname || selectedFinancer.firstname ? (
                  <div className="font-medium">
                    {selectedFinancer.lastname || ""} {selectedFinancer.firstname || ""}
                  </div>
                ) : null}
                {selectedFinancer.address ? (
                  <div className="text-sm">{selectedFinancer.address}</div>
                ) : null}
                {selectedFinancer.zipcode || selectedFinancer.city ? (
                  <div className="text-sm">
                    {" "}
                    {selectedFinancer.zipcode || ""} {selectedFinancer.city || ""}
                  </div>
                ) : null}
                {selectedFinancer.email ? (
                  <div className="text-sm">{selectedFinancer.email}</div>
                ) : null}
                {selectedFinancer.mobileNumber ? (
                  <div className="text-sm">{selectedFinancer.mobileNumber}</div>
                ) : null}
                {selectedFinancer.fixeNumber ? (
                  <div className="text-sm">{selectedFinancer.fixeNumber}</div>
                ) : null}
              </div>
            </div>
          ) : null}
        </FormRow>

        {selectedFinancer ? (
          <>
            <FormRow>
              <Controller
                name="customer"
                control={form.control}
                render={({ field: { onChange, onBlur, ref } }) => {
                  return (
                    <FormComboBox
                      label="Bénéficiaire"
                      onChange={(value) => {
                        const customer = beneficiaries?.find(
                          (customer) => customer?.id?.toString() === value
                        );
                        setSelectedBeneficiary(customer || null);
                        onChange(value);
                      }}
                      onBlur={onBlur}
                      onSearchChange={setBeneficiarySearch}
                      options={
                        beneficiaries?.map((customer) => ({
                          id: customer?.id || 0,
                          label: `${customer?.lastname || ""} ${customer?.firstname || ""}`,
                          value: customer?.id?.toString() || ""
                        })) || []
                      }
                      defaultOption={
                        quotationToEdit?.customer?.id
                          ? {
                              id: quotationToEdit.customer.id,
                              label: `${quotationToEdit.customer.lastname} ${quotationToEdit.customer.firstname}`,
                              value: quotationToEdit.customer.id.toString()
                            }
                          : null
                      }
                      errorMessage={form.formState.errors.customer?.message}
                      isLoading={isBeneficiariesLoading}
                      ref={ref}
                    />
                  );
                }}
              />

              {selectedBeneficiary ? (
                <div className="col-span-1">
                  <h4 className="mb-2 text-sm font-medium leading-none text-grey-900">
                    Coordonnées du bénéficiaire
                  </h4>
                  <div className="space-y-1.5 rounded-lg border border-grey-100 bg-grey-50 p-4">
                    {selectedBeneficiary.companyName ? (
                      <div className="font-semibold">{selectedBeneficiary.companyName}</div>
                    ) : null}
                    {selectedBeneficiary.lastname || selectedBeneficiary.firstname ? (
                      <div className="font-medium">
                        {selectedBeneficiary.lastname || ""} {selectedBeneficiary.firstname || ""}
                      </div>
                    ) : null}
                    {selectedBeneficiary.address ? (
                      <div className="text-sm">{selectedBeneficiary.address}</div>
                    ) : null}
                    {selectedBeneficiary.zipcode || selectedBeneficiary.city ? (
                      <div className="text-sm">
                        {" "}
                        {selectedBeneficiary.zipcode || ""} {selectedBeneficiary.city || ""}
                      </div>
                    ) : null}
                    {selectedBeneficiary.email ? (
                      <div className="text-sm">{selectedBeneficiary.email}</div>
                    ) : null}
                    {selectedBeneficiary.mobileNumber ? (
                      <div className="text-sm">{selectedBeneficiary.mobileNumber}</div>
                    ) : null}
                    {selectedBeneficiary.fixeNumber ? (
                      <div className="text-sm">{selectedBeneficiary.fixeNumber}</div>
                    ) : null}
                  </div>
                </div>
              ) : null}
            </FormRow>
          </>
        ) : null}

        {isPrefinancingPrestationLoading ? (
          <LoadingSpinner fullPage />
        ) : selectedBeneficiary && prefinancingPrestation ? (
          <>
            <FormTitle as="h3">Prestation</FormTitle>

            {quotationRows.length > 0
              ? quotationRows.map((quotationRow, index) => (
                  <div
                    key={index}
                    className="mt-6 rounded-xl border border-grey-100 bg-grey-50 p-4 shadow"
                  >
                    <FormRow>
                      <FormSelect
                        label="Nature de la prestation"
                        {...form.register(`quotationRow.${index}.category`, {
                          onChange() {
                            const category = prefinancingPrestation;
                            const tva = parseFloat(category.tva || "0") / 100;
                            const priceUnitHT = parseFloat(quotationRow?.priceUnit || "0");
                            const priceUnitTTC = parseFloat((priceUnitHT * (1 + tva)).toFixed(2));
                            const quantity = parseFloat(quotationRow?.quantity || "0");
                            const totalTTC = priceUnitTTC * quantity;

                            form.setValue(`quotationRow.${index}.priceUnitTTC`, priceUnitTTC);
                            form.setValue(`quotationRow.${index}.totalTTC`, totalTTC);

                            setQuotationRows((prev) => {
                              const newRows = [...prev];
                              newRows[index] = {
                                ...newRows[index],
                                category,
                                nameService: category.name || "",
                                tva: category.tva || "0"
                              };
                              return newRows;
                            });
                          }
                        })}
                      >
                        <option value={prefinancingPrestation.id}>
                          {prefinancingPrestation.name}
                        </option>
                      </FormSelect>
                    </FormRow>

                    <FormRow>
                      <FormInput
                        type="number"
                        step="any"
                        label="Total HT"
                        rightAddon="€ HT"
                        errorMessage={
                          form.formState.errors.quotationRow?.[index]?.priceUnit?.message
                        }
                        {...form.register(`quotationRow.${index}.priceUnit`, {
                          setValueAs: (v) => v.toString(),
                          onChange: (e) => {
                            const tva = parseFloat(quotationRow?.tva || "0") / 100;
                            const priceUnitHT = parseFloat(e.target.value || "0");

                            const priceUnitTTC = parseFloat((priceUnitHT * (1 + tva)).toFixed(2));
                            const quantity = parseFloat(quotationRow?.quantity || "0");
                            const totalHT = priceUnitHT * quantity;
                            const totalTTC = priceUnitTTC * quantity;
                            form.setValue(`quotationRow.${index}.priceUnitTTC`, priceUnitTTC);
                            form.setValue(`quotationRow.${index}.totalHT`, totalHT);
                            form.setValue(`quotationRow.${index}.totalTTC`, totalTTC);
                            setQuotationRows((prev) => {
                              const newRows = [...prev];
                              newRows[index] = {
                                ...newRows[index],
                                priceUnit: priceUnitHT.toString(),
                                totalHT,
                                totalTTC
                              };
                              return newRows;
                            });
                          }
                        })}
                      />
                      <FormInput
                        type="number"
                        step="any"
                        label="Total TTC"
                        infoMessage={`${parseFloat(quotationRow?.tva || "0")}% de TVA.`}
                        rightAddon="€ TTC"
                        errorMessage={
                          form.formState.errors.quotationRow?.[index]?.priceUnitTTC?.message
                        }
                        {...form.register(`quotationRow.${index}.priceUnitTTC`, {
                          setValueAs: (v) => parseFloat(v),
                          onChange: (e) => {
                            const tva = parseFloat(quotationRow?.tva || "0") / 100;
                            const priceUnitTTC = parseFloat(e.target.value || "0");
                            const priceUnitHT = parseFloat((priceUnitTTC / (1 + tva)).toFixed(2));
                            const quantity = parseFloat(quotationRow?.quantity || "0");
                            const totalHT = priceUnitHT * quantity;
                            const totalTTC = priceUnitTTC * quantity;
                            form.setValue(
                              `quotationRow.${index}.priceUnit`,
                              priceUnitHT.toString()
                            );
                            form.setValue(`quotationRow.${index}.totalHT`, totalHT);
                            form.setValue(`quotationRow.${index}.totalTTC`, totalTTC);
                            setQuotationRows((prev) => {
                              const newRows = [...prev];
                              newRows[index] = {
                                ...newRows[index],
                                priceUnit: priceUnitHT.toString(),
                                totalHT,
                                totalTTC
                              };
                              return newRows;
                            });
                          }
                        })}
                      />
                    </FormRow>
                  </div>
                ))
              : null}

            <div className="mt-8 grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
              <FormTextArea
                label="Services souhaités"
                className="lg:col-span-2"
                textareaClassName="min-h-32"
                errorMessage={form.formState.errors.comments?.message}
                {...form.register("comments")}
              />
              <div className="space-y-1 rounded-xl border border-grey-200 bg-grey-100 p-4 shadow-sm md:mt-6">
                <div className="flex items-center justify-between gap-8">
                  <div className="text-sm">Total HT</div>
                  <div className="font-medium">{formatMoney(totalHT)}</div>
                </div>
                <div className="flex items-center justify-between gap-8">
                  <div className="text-sm">TVA</div>
                  <div className="font-medium">{formatMoney(totalTVA)}</div>
                </div>
                <div className="!mt-2 flex items-center justify-between gap-8">
                  <div className="font-medium">Total TTC</div>
                  <div className="text-lg font-bold text-primary-700">{formatMoney(totalTTC)}</div>
                </div>
              </div>
            </div>

            <div className="mt-12 flex flex-wrap gap-4 border-t border-grey-200 pt-6 sm:justify-end">
              <Button fill="clear" onClick={() => history.goBack()}>
                Annuler
              </Button>

              {!quotationToEdit?.state || quotationToEdit.state === quotationStates.STATE_DRAFT ? (
                <Button
                  type="button"
                  fill="outline"
                  onClick={form.handleSubmit((data) =>
                    onSubmit({ state: quotationStates.STATE_DRAFT, data })
                  )}
                  disabled={quotationMutation.isLoading}
                >
                  Enregistrer comme brouillon
                </Button>
              ) : null}

              {quotationToEdit?.state === quotationStates.STATE_BILL ? (
                <Button
                  type="button"
                  onClick={form.handleSubmit((data) =>
                    onSubmit({ state: quotationStates.STATE_BILL, data })
                  )}
                  disabled={quotationMutation.isLoading}
                >
                  Enregistrer & régénérer la facture
                </Button>
              ) : quotationToEdit?.signedAt ? (
                <Button
                  type="button"
                  onClick={form.handleSubmit((data) => onSubmit({ state: undefined, data }))}
                  disabled={quotationMutation.isLoading}
                >
                  Modifier
                </Button>
              ) : (
                <Button
                  type="button"
                  onClick={() =>
                    openModal(
                      <ConfirmationModal
                        title="Valider le relevé"
                        isOpen={true}
                        onClose={closeModal}
                        onConfirm={form.handleSubmit((data) => {
                          onSubmit({
                            state: quotationStates.STATE_SEND,
                            data
                          });
                        })}
                      >
                        Etes vous sûr de vouloir valider le contrat ?<br />
                        Un email et un sms de signature seront envoyés au financeur.
                      </ConfirmationModal>
                    )
                  }
                  disabled={quotationMutation.isLoading}
                >
                  Enregistrer & envoyer au financeur
                </Button>
              )}
            </div>
          </>
        ) : null}
      </form>
      {renderModal()}
    </>
  );
};
