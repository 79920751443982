import type { FetchNextPageOptions } from "@tanstack/react-query";
import type { ComponentType, PropsWithChildren, ReactNode } from "react";
import type { SubmitHandler, UseFormRegister } from "react-hook-form";
import type { MainTableProps } from "~/components/ui/MainTable";

import { useEffect, useState } from "react";
import { IonIcon } from "@ionic/react";
import { optionsOutline, search as searchIcon } from "ionicons/icons";
import { useForm } from "react-hook-form";
import { useInView } from "react-intersection-observer";

import { cn, flattenObject } from "~/utils/misc";
import { useModal } from "~/hooks/useModal";
import { getSearchedData } from "~/services/search";
import { getSortedData } from "~/services/sort";
import { AdvancedSearchFormLayout } from "~/components/AdvancedSearchForms/AdvancedSearchFormLayout";
import { Button } from "~/components/ui/Button/Button";
import { FormInput } from "~/components/ui/FormInput";
import { FormSelect } from "~/components/ui/FormSelect";
import { LoadingSpinner } from "~/components/ui/LoadingSpinner";
import { MainTable } from "~/components/ui/MainTable";
import { Modal } from "~/components/ui/Modal/Modal";

export type AdvancedSearchParams = {
  [key: string]: string | number | boolean | null;
} | null;

interface TableLayoutProps
  extends PropsWithChildren<{
    data: MainTableProps["data"];
    isLoading: boolean;
    fetchNextPage: (opt?: FetchNextPageOptions) => void;
    hasNextPage?: boolean | undefined;
    AdvancedSearchForm?: ComponentType<{ register: UseFormRegister<any> }>;
    advancedSearchParams?: AdvancedSearchParams;
    handleAdvancedSearch?: (data: AdvancedSearchParams) => void;
    handleSimpleSearch?: (data: AdvancedSearchParams) => void;
    globalActions?: {
      label: string;
      icon?: string;
      fill?: "solid" | "outline" | "clear";
      align?: "start" | "end";
      color?: "primary" | "secondary" | "warning" | "danger";
      href?: string;
      action?: () => void;
      condition?: boolean | undefined;
      disabled?: boolean | undefined;
      info?: ReactNode;
    }[];
    groupedActions?: {
      id: string;
      label: string;
      action: (ids: number[]) => void;
      condition?: boolean | undefined;
    }[];
  }> {}

export const TableLayout = ({
  data,
  isLoading,
  fetchNextPage,
  hasNextPage,
  AdvancedSearchForm,
  advancedSearchParams,
  handleAdvancedSearch,
  handleSimpleSearch,
  globalActions,
  groupedActions,
  children
}: TableLayoutProps) => {
  const [sort, setSort] = useState<{ key: string; value: "ASC" | "DESC" } | null>(null);
  const [search, setSearch] = useState<string | null>(null);
  const {
    openModal: openAdvancedSearch,
    closeModal: closeAdvancedSearch,
    renderModal: renderAdvancedSearch
  } = useModal();

  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [selectedGroupedAction, setSelectedGroupedAction] = useState<string | null>(null);

  // Infinite scroll intersection observer
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage]);

  // Filter conditional columns
  const filteredData = data.map((row) => ({
    ...row,
    columns: row.columns.filter((col: any) => col.condition === undefined || col.condition === true)
  }));

  // Simple Search
  const searchedData = handleSimpleSearch
    ? filteredData
    : getSearchedData({ search, data: filteredData });

  // Sort
  const sortedData = getSortedData({ sort, data: searchedData });

  const handleSort = (sortKey: string) => {
    setSort((prevSort) => {
      if (!prevSort?.key) return { key: sortKey, value: "ASC" };
      return { key: sortKey, value: prevSort.value === "DESC" ? "ASC" : "DESC" };
    });
  };

  // Advanced Search
  const advancedSearchForm = useForm({
    shouldUnregister: true,
    values: advancedSearchParams || {}
  });

  const handleAdvancedSearchSubmit: SubmitHandler<any> = (data) => {
    const dataWithItemsPerPage = { ...data, search, itemsPerPage: 1500 };
    const dataWithoutEmptyValues = Object.keys(dataWithItemsPerPage).reduce((acc, key) => {
      if (dataWithItemsPerPage[key] !== null && dataWithItemsPerPage[key] !== "") {
        acc[key] = dataWithItemsPerPage[key];
      }
      return acc;
    }, {} as any);
    closeAdvancedSearch();
    handleAdvancedSearch?.(flattenObject(dataWithoutEmptyValues));
  };

  const handleAdvancedSearchReset = () => {
    setSearch(null);
    handleAdvancedSearch?.({});
    advancedSearchForm.reset();
    closeAdvancedSearch();
  };

  const totalSearchParams =
    Object.keys(advancedSearchParams || {}).reduce(
      (acc, key) => (advancedSearchParams && advancedSearchParams[key] ? acc + 1 : acc),
      0
    ) - 1;

  const filteredGlobalActions = globalActions?.filter(
    (a) => a.condition === undefined || a.condition === true
  );

  const filteredGroupedActions = groupedActions?.filter(
    (a) => a.condition === undefined || a.condition === true
  );

  return (
    <>
      <div className="mt-6">
        {filteredGlobalActions && filteredGlobalActions.length > 0 ? (
          <div className="flex flex-wrap items-start gap-4">
            {filteredGlobalActions.map((action) => (
              <div key={action.label} className="flex flex-col gap-1">
                {action.href ? (
                  <Button
                    fill={action.fill || "solid"}
                    color={action.color || "primary"}
                    icon={action.icon}
                    routerLink={action.href}
                    disabled={action.disabled || false}
                    className={cn(action.align === "end" && "ml-auto")}
                  >
                    {action.label}
                  </Button>
                ) : action.action ? (
                  <Button
                    fill={action.fill || "solid"}
                    color={action.color || "primary"}
                    icon={action.icon}
                    onClick={action.action}
                    disabled={action.disabled || false}
                    className={cn(action.align === "end" && "ml-auto")}
                  >
                    {action.label}
                  </Button>
                ) : null}
                {action.info ? action.info : null}
              </div>
            ))}
          </div>
        ) : null}
        <div className="mt-4 flex flex-wrap items-end justify-between gap-4">
          <div className="flex flex-wrap items-center gap-2">
            <FormInput
              id="advanced-search-input"
              placeholder="Rechercher"
              icon={<IonIcon icon={searchIcon} />}
              clearable={true}
              value={search || ""}
              onInput={(e) => {
                setSearch(e.currentTarget.value);
                if (handleSimpleSearch) {
                  handleSimpleSearch({ ...advancedSearchParams, search: e.currentTarget.value });
                }
              }}
              className="grow"
            />
            {AdvancedSearchForm ? (
              <div className="relative">
                <Button
                  color="primary"
                  fill="clear"
                  icon={optionsOutline}
                  onClick={() => {
                    setSearch(null);
                    handleAdvancedSearch?.({});
                    advancedSearchForm.reset();
                    openAdvancedSearch(
                      <Modal isOpen={true} onClose={closeAdvancedSearch} title="Recherche avancée">
                        <AdvancedSearchFormLayout
                          onReset={handleAdvancedSearchReset}
                          onSubmit={advancedSearchForm.handleSubmit(handleAdvancedSearchSubmit)}
                        >
                          <AdvancedSearchForm register={advancedSearchForm.register} />
                        </AdvancedSearchFormLayout>
                      </Modal>
                    );
                  }}
                >
                  Recherche avancée
                </Button>
                {totalSearchParams > 0 ? (
                  <div className="absolute -right-1 -top-1 flex h-5 w-5 items-center justify-center rounded-full bg-primary text-xs font-bold text-white">
                    {totalSearchParams}
                  </div>
                ) : null}
              </div>
            ) : null}
            {Object.keys(advancedSearchParams || {}).length > 0 ? (
              <Button
                color="primary"
                fill="clear"
                onClick={() => {
                  handleAdvancedSearchReset();
                }}
              >
                Réinitialiser
              </Button>
            ) : null}
          </div>

          {filteredGroupedActions && filteredGroupedActions.length > 0 ? (
            <form
              className="flex items-end gap-2"
              onSubmit={(e) => {
                e.preventDefault();
                if (selectedGroupedAction && selectedItems.length > 0) {
                  const action = filteredGroupedActions.find((a) => a.id === selectedGroupedAction);
                  action?.action(selectedItems);
                }
              }}
            >
              <FormSelect
                id="grouped-action-select"
                name="grouped-action"
                defaultValue=""
                onChange={(e) => {
                  setSelectedGroupedAction(e.currentTarget.value);
                  setSelectedItems([]);
                }}
              >
                <option value="" disabled>
                  Actions groupées
                </option>
                {filteredGroupedActions.map((action, index) => (
                  <option key={index} value={action.id}>
                    {action.label} ({selectedItems.length})
                  </option>
                ))}
              </FormSelect>
              <Button type="submit">Ok</Button>
            </form>
          ) : null}
        </div>

        {children ? <div className="mt-4">{children}</div> : null}

        {sortedData.length > 0 ? (
          <MainTable
            data={sortedData}
            onSort={handleSort}
            currentSort={sort}
            selectedGroupedAction={selectedGroupedAction}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            someGroupedAction={(groupedActions && groupedActions.length > 0) || false}
          />
        ) : isLoading ? null : (
          <div className="mt-8 text-center">Aucun résultat</div>
        )}

        {isLoading ? (
          <LoadingSpinner fullPage className="my-2 h-4" />
        ) : hasNextPage ? (
          <div ref={ref} className="my-2 h-4" />
        ) : null}
      </div>

      {renderAdvancedSearch()}
    </>
  );
};
