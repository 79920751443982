import type { GouvAddress } from "~/types/gouv-addresses.types";
import type { DeepPartial } from "~/types/utils.types";

import axios from "axios";

export const getGouvAddresses = async ({
  search,
  zipCode,
  limit
}: {
  search?: string;
  zipCode?: string;
  limit?: number;
}) => {
  const response = await axios.get<DeepPartial<GouvAddress>>(
    "https://api-adresse.data.gouv.fr/search",
    {
      params: { q: search, postcode: zipCode, limit: limit ?? 10 }
    }
  );

  return response.data.features?.map((feature) => ({
    ...feature?.properties,
    longitude: feature?.geometry?.coordinates?.[0],
    latitude: feature?.geometry?.coordinates?.[1]
  }));
};
