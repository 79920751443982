import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router";

import { queryKeys } from "~/config/query-keys-constants";
import { useUIContext } from "~/contexts/uiContext";
import { handleApiError } from "~/services/errors";
import { createSms, getSms, updateSms } from "~/api/sms";
import { Button } from "~/components/ui/Button/Button";
import { FormInput } from "~/components/ui/FormInput";
import { FormRow } from "~/components/ui/FormRow";
import { FormTextArea } from "~/components/ui/FormTextArea";
import { LoadingSpinner } from "~/components/ui/LoadingSpinner";
import { SmsSchema } from "~/schemas/sms.schema";

export const CreateEditSms = ({ editMode = false }: { editMode?: boolean }) => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const { toast } = useUIContext();
  const { sms_id: smsId } = useParams<{ sms_id?: string }>();

  const { data: smsData, isLoading: isSmsLoading } = useQuery({
    queryKey: [queryKeys.sms, { id: smsId }],
    queryFn: () => (smsId ? getSms({ id: parseInt(smsId) }) : null),
    enabled: !!smsId
  });

  const smsMutation = useMutation({
    mutationFn: (data: SmsSchema) =>
      editMode && smsId ? updateSms({ id: parseInt(smsId), sms: data }) : createSms({ sms: data }),
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: [queryKeys.sms] });
      toast(
        editMode ? "Le SMS a été modifié avec succès." : "Le SMS a été créé avec succès.",
        "success"
      );
      history.push("/administration/sms");
    },
    onError(error) {
      handleApiError(error, toast);
    }
  });

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<SmsSchema>({
    resolver: zodResolver(SmsSchema),
    values: {
      name: smsData?.name || "",
      code: smsData?.code || "",
      content: smsData?.content || ""
    }
  });

  const onSubmit = (data: SmsSchema) => {
    smsMutation.mutate(data);
  };

  if (!!smsId && isSmsLoading) {
    return <LoadingSpinner size="lg" fullPage />;
  }

  if (!!smsId && !smsData) {
    return <p className="text-center">Le SMS à éditer est introuvable.</p>;
  }

  return (
    <>
      <h2 className="mt-6">
        {editMode ? `Édition d'un SMS : ${smsData?.name}` : "Création d'un SMS"}
      </h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormRow>
          <FormInput label="Nom" errorMessage={errors.name?.message} {...register("name")} />
          <FormInput label="Code" errorMessage={errors.code?.message} {...register("code")} />
        </FormRow>
        <FormRow>
          <FormTextArea
            label="Contenu"
            errorMessage={errors.content?.message}
            className="col-span-3"
            rows={12}
            {...register("content")}
          />
        </FormRow>

        <div className="mt-12 flex justify-end gap-4 border-t border-grey-200 pt-6">
          <Button fill="clear" onClick={() => history.goBack()}>
            Annuler
          </Button>
          <Button type="submit" disabled={smsMutation.isLoading}>
            {editMode ? "Modifier le SMS" : "Créer le SMS"}
          </Button>
        </div>
      </form>
    </>
  );
};
