import type { Config } from "~/types/config.types";
import type { DeepPartial } from "~/types/utils.types";

import { useState } from "react";
import { Transition } from "@headlessui/react";
import { IonIcon } from "@ionic/react";
import { closeOutline, warningOutline } from "ionicons/icons";

import { HtmlParser } from "~/components/HtmlParser";

export function TopMessage({ configs }: { configs: DeepPartial<Config[]> | null }) {
  const [isTopMessageOpen, setIsTopMessageOpen] = useState(import.meta.env.PROD ? true : false);
  const topMessageText = configs?.find((config) => config?.code === "top-message-text")?.value;
  const isTopMessageActive = configs?.find(
    (config) => config?.code === "top-message-active"
  )?.value;

  return (
    <>
      {isTopMessageActive && topMessageText && topMessageText.length > 0 ? (
        <div>
          <Transition
            show={isTopMessageOpen}
            className={"overflow-hidden"}
            enter="transition-max-height duration-300"
            enterFrom="max-h-[0px]"
            enterTo="max-h-[96px]"
            leave="transition-max-height duration-300"
            leaveFrom="max-h-[96px]"
            leaveTo="max-h-[0px]"
          >
            <div className="flex items-center justify-between gap-4 bg-danger-700 p-2 text-white sm:p-3">
              <IonIcon icon={warningOutline} className="h-6 w-6 shrink-0" />
              <HtmlParser
                html={topMessageText}
                className="text-center text-sm font-semibold sm:text-base md:text-lg"
              />
              <button
                onClick={() => setIsTopMessageOpen(false)}
                className="flex shrink-0 items-center justify-center text-white focus:outline-none"
              >
                <IonIcon icon={closeOutline} className="h-6 w-6" />
              </button>
            </div>
          </Transition>
          <div className="relative">
            <Transition
              as="button"
              onClick={() => setIsTopMessageOpen(true)}
              className="absolute right-20 top-0 z-50 flex items-center justify-center rounded-b-md bg-danger-700 p-2 text-white sidebar-breakpoint:right-60"
              show={!isTopMessageOpen}
              enter="transition-opacity duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              {/* <button className="absolute right-8 top-0 z-50 flex items-center justify-center rounded-b-md bg-danger-700 p-2 text-white"> */}
              <IonIcon icon={warningOutline} className="h-6 w-6 shrink-0" />
              {/* </button> */}
            </Transition>
          </div>
        </div>
      ) : null}
    </>
  );
}
