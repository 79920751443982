import { z } from "zod";

const MAX_FILE_SIZE = 1024 * 1024 * 2; // 2MB
const ACCEPTED_IMAGE_TYPES = ["image/png", "image/jpeg", "image/jpg"];

export const ModuleSchema = z.object({
  name: z.string().min(1, "Veuillez renseigner un nom pour le module."),
  code: z.string().min(1, "Veuillez renseigner un code pour le module."),
  title: z.string().min(1, "Veuillez renseigner un titre pour le module."),
  imageFile: z
    .any()
    .refine((files) => {
      if (!files || files.length === 0) return true;
      return ACCEPTED_IMAGE_TYPES.includes(files[0].type);
    }, "L'image doit être au format png, jpeg ou jpg")
    .refine((files) => {
      if (!files || files.length === 0) return true;
      return files[0].size <= MAX_FILE_SIZE;
    }, "Le fichier est trop volumineux (2Mo maximum)")
    .optional()
    .nullable(),
  description1: z.string().optional().nullable(),
  description2: z.string().optional().nullable(),
  description3: z.string().optional().nullable()
});

export type ModuleSchema = z.infer<typeof ModuleSchema>;

// Send error message to "sms" field if "emails" and "sms" are both empty
export const SendModuleSchema = z
  .object({
    sms: z.string().optional().nullable(),
    emails: z.string().optional().nullable()
  })
  .superRefine((data, ctx) => {
    if (!data.sms && !data.emails) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Veuillez renseigner au moins un email ou un numéro de téléphone",
        path: ["emails"]
      });
    }
  });

export type SendModuleSchema = z.infer<typeof SendModuleSchema>;
