import { useState } from "react";
import { IonIcon } from "@ionic/react";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { searchOutline } from "ionicons/icons";

import { queryKeys } from "~/config/query-keys-constants";
import { formatPercent } from "~/utils/misc";
import { getRecoveryStatistics } from "~/api/statistics";
import { Button } from "~/components/ui/Button/Button";
import { FormDatePicker } from "~/components/ui/FormDatePicker";
import { LoadingSpinner } from "~/components/ui/LoadingSpinner";

export const QuotationRecoveryStatistics = () => {
  const [selectedDates, setSelectedDates] = useState<[Date | null, Date | null]>([
    dayjs().subtract(3, "month").toDate(),
    dayjs().toDate()
  ]);

  const {
    data: statsData,
    isLoading: isStatsLoading,
    refetch
  } = useQuery({
    queryKey: [queryKeys.statisticsRecoveries],
    queryFn: () =>
      getRecoveryStatistics({
        startDate: dayjs(selectedDates[0]).format("YYYY-MM-DD"),
        endDate: dayjs(selectedDates[1]).format("YYYY-MM-DD")
      })
  });

  return (
    <>
      <h2 className="mt-6">Statistiques des relevés</h2>
      {isStatsLoading ? (
        <LoadingSpinner fullPage />
      ) : !statsData ? (
        <p>Pas de données de statistiques</p>
      ) : (
        <>
          <div className="flex flex-wrap items-center justify-between gap-8">
            <p>
              Nombre de relevés sur la période :{" "}
              <span className="font-bold">{statsData.nbQuotations}</span>
            </p>
            <div className="flex items-end gap-2">
              <FormDatePicker
                label="Date de début"
                optional
                value={dayjs(selectedDates[0]).format("YYYY-MM-DD")}
                onChange={(e) => {
                  setSelectedDates((currentDates) => [
                    dayjs(e.target.value).toDate(),
                    currentDates[1]
                  ]);
                }}
              />
              <FormDatePicker
                label="Date de fin"
                optional
                value={dayjs(selectedDates[1]).format("YYYY-MM-DD")}
                onChange={(e) => {
                  setSelectedDates((currentDates) => [
                    currentDates[0],
                    dayjs(e.target.value).toDate()
                  ]);
                }}
              />
              <Button onClick={() => refetch()} color="primary" size="default" className="h-[42px]">
                <IonIcon icon={searchOutline} />
              </Button>
            </div>
          </div>
          <h3 className="mt-8">Performance standard</h3>
          <div className="flex flex-wrap justify-stretch gap-8">
            <div className="grow basis-96 rounded-lg border border-grey-200 p-6">
              <h4 className="mb-2 text-grey-800">Pourcentage de taux de signature</h4>
              <p className="mb-4 text-sm text-grey-600">
                Taux des relevés signés avant d’être rentrés dans le syteme de relance
              </p>
              <p className="text-2xl font-semibold text-grey-700">
                {formatPercent(statsData.perfStandardTxSign)}{" "}
                <span className="ml-4 text-sm italic text-grey-500">
                  {statsData.nbPerfStandardTxSign} relevés
                </span>
              </p>
            </div>

            <div className="grow basis-96 rounded-lg border border-grey-200 p-6">
              <h4 className="mb-2 text-grey-800">Pourcentage de taux de paiement</h4>
              <p className="mb-4 text-sm text-grey-600">
                Taux des relevés payés avant d’être rentrés dans le syteme de relance
              </p>
              <p className="text-2xl font-semibold text-grey-700">
                {formatPercent(statsData.perfStandardTxPay)}{" "}
                <span className="ml-4 text-sm italic text-grey-500">
                  {statsData.nbPerfStandardTxPay} relevés
                </span>
              </p>
            </div>
          </div>

          <h3 className="mt-8">Performance de l&apos;outil de relance</h3>
          <div className="flex flex-wrap justify-stretch gap-8">
            <div className="grow basis-96 rounded-lg border border-grey-200 p-6">
              <h4 className="mb-2 text-grey-800">
                Pourcentage de taux de réussite des relances signature
              </h4>
              <p className="mb-4 text-sm text-grey-600">
                Pourcentage des relevés qui sont rentrés dans le processus de relance et qui ont été
                signés sans passer par la dernière relance.
              </p>
              <p className="text-2xl font-semibold text-grey-700">
                {formatPercent(statsData.perfRecoveryTxSign)}{" "}
                <span className="ml-4 text-sm italic text-grey-500">
                  {statsData.nbPerfRecoveryTxSign} relevés
                </span>
              </p>
            </div>

            <div className="grow basis-96 rounded-lg border border-grey-200 p-6">
              <h4 className="mb-2 text-grey-800">
                Pourcentage de taux de réussite des relances paiement
              </h4>
              <p className="mb-4 text-sm text-grey-600">
                Pourcentage des relevés qui sont rentrés dans le processus de relance et qui ont été
                payés sans passer par la dernière relance.
              </p>
              <p className="text-2xl font-semibold text-grey-700">
                {formatPercent(statsData.perfRecoveryTxPay)}{" "}
                <span className="ml-4 text-sm italic text-grey-500">
                  {statsData.nbPerfRecoveryTxPay} relevés
                </span>
              </p>
            </div>
          </div>

          <h3 className="mt-8">Performance de la relance téléphonique</h3>
          <div className="flex flex-wrap justify-stretch gap-8">
            <div className="grow basis-96 rounded-lg border border-grey-200 p-6">
              <h4 className="mb-2 text-grey-800">
                Pourcentage de taux de réussite de la relance téléphonique signature
              </h4>
              <p className="mb-4 text-sm text-grey-600">
                Taux des relevés arrivés au statut échoués et qui sont passés en signés aprés la
                date du statut échoué
              </p>
              <p className="text-2xl font-semibold text-grey-700">
                {formatPercent(statsData.perfRecoveryTelTxSign)}{" "}
                <span className="ml-4 text-sm italic text-grey-500">
                  {statsData.nbPerfRecoveryTelTxSign} relevés
                </span>
              </p>
            </div>

            <div className="grow basis-96 rounded-lg border border-grey-200 p-6">
              <h4 className="mb-2 text-grey-800">
                Pourcentage de taux de réussite de la relance téléphonique paiement
              </h4>
              <p className="mb-4 text-sm text-grey-600">
                Taux des relevés arrivés au statut échoués et qui sont passés en payés aprés la date
                du statut échoué
              </p>
              <p className="text-2xl font-semibold text-grey-700">
                {formatPercent(statsData.perfRecoveryTelTxPay)}{" "}
                <span className="ml-4 text-sm italic text-grey-500">
                  {statsData.nbPerfRecoveryTelTxPay} relevés
                </span>
              </p>
            </div>
          </div>
        </>
      )}
    </>
  );
};
