import type { MainTableProps } from "~/components/ui/MainTable";

import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import { documentTextOutline, downloadOutline } from "ionicons/icons";

import { queryKeys } from "~/config/query-keys-constants";
import { formatDisplayDate, formatMoney } from "~/utils/misc";
import { useUIContext } from "~/contexts/uiContext";
import { useFileDeliver } from "~/hooks/useFileDeliver";
import { useModal } from "~/hooks/useModal";
import { handleApiError } from "~/services/errors";
import { getNextPageParamFn } from "~/api/_config";
import { queryClient } from "~/api/_queryClient";
import {
  exportAllPendingPayments,
  getCoopProblems,
  getPayments,
  getPaymentsTotalPaid,
  hasPaymentExportTask
} from "~/api/payment-export";
import { ConfirmationModal } from "~/components/Modals/ConfirmationModal";
import { TableLayout } from "~/components/TableLayout";
import { Link } from "~/components/ui/Link";

export const AdministrationPayments = () => {
  const { openFile } = useFileDeliver();
  const { openModal, closeModal, renderModal } = useModal();
  const { toast } = useUIContext();

  const queryParams = {};
  const { data, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: [queryKeys.paymentExports, queryParams],
    queryFn: ({ pageParam }) => getPayments({ ...queryParams, page: pageParam }),
    getNextPageParam: getNextPageParamFn
  });

  const { data: totalPaid = 0 } = useQuery({
    queryKey: [queryKeys.paymentExports, "totalPaid"],
    queryFn: getPaymentsTotalPaid
  });

  const { data: hasTask = false } = useQuery({
    queryKey: [queryKeys.paymentExports, "hasTask"],
    queryFn: () => hasPaymentExportTask()
  });

  const { data: coopProblems = [] } = useQuery({
    queryKey: [queryKeys.paymentExports, "coopProblems"],
    queryFn: getCoopProblems
  });

  const exportAllPendingPaymentsMutation = useMutation(exportAllPendingPayments, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.paymentExports]);
      toast("Les virements en attente on été exportés avec succès.", "success");
    },
    onError(error) {
      handleApiError(error, toast);
    }
  });

  const globalActions = [
    {
      label: "Exporter les virements en attente",
      action: () =>
        openModal(
          <ConfirmationModal
            title="Exporter les virements en attente"
            isOpen={true}
            onClose={closeModal}
            onConfirm={() => exportAllPendingPaymentsMutation.mutate()}
          >
            Êtes vous sûr de vouloir exporter tout les virements en attente ?
          </ConfirmationModal>
        ),
      disabled: totalPaid === 0 || hasTask,
      info: (
        <div className="text-sm text-grey-700">
          {totalPaid === 0 ? (
            "Aucun virement en attente"
          ) : totalPaid > 0 && hasTask ? (
            "Fichier en cours de génération"
          ) : (
            <>
              <p>Total à payer : {formatMoney(totalPaid)}</p>
              {coopProblems.length > 0 ? (
                <>
                  <p className="text-red-600">
                    Attention, ces coopérateurs ne pourront pas être payés car aucune information
                    banquaire n&apos;est renseignée dans leur compte :
                  </p>
                  <ul className="mt-1 list-disc pl-4">
                    {coopProblems.map((coop) => (
                      <li key={coop.id}>
                        <Link to={`/administration/cooperators/${coop.id}`}>
                          {coop.companyName} {coop.lastname} {coop.firstname}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </>
              ) : null}
            </>
          )}
        </div>
      )
    }
  ];

  const tableData: MainTableProps["data"] =
    data?.pages.flat().map((paymentExport) => ({
      itemId: paymentExport?.id,
      actions: [
        {
          label: !paymentExport?.fileName
            ? "Export indisponible"
            : paymentExport.isInTask
              ? "En cours de génération..."
              : "Exporter le virement (format XML)",
          icon: downloadOutline,
          color: "cyan",
          disabled: !paymentExport?.fileName || paymentExport.isInTask,
          action: () => {
            return openFile({
              itemType: "virement",
              itemParams: { path: paymentExport?.fileName }
            });
          }
        },
        {
          label: !paymentExport?.fileName
            ? "Export indisponible"
            : paymentExport.isInTask
              ? "En cours de génération..."
              : "Exporter le virement (format TXT)",
          icon: documentTextOutline,
          color: "slate",
          disabled: !paymentExport?.fileName || paymentExport.isInTask,
          action: () =>
            openFile({
              itemType: "virement",
              itemParams: { path: paymentExport?.fileName?.replace(".xml", ".txt") }
            })
        }
      ],
      columns: [
        {
          key: "createdAt",
          label: "Date d'export",
          display: paymentExport?.createdAt ? formatDisplayDate(paymentExport.createdAt) : "",
          sort: paymentExport?.createdAt,
          search: paymentExport?.createdAt ? formatDisplayDate(paymentExport.createdAt) : ""
        },
        {
          key: "nbTransactions",
          label: "Nombre de virements/relevés",
          display: paymentExport?.nbTransactions,
          sort: paymentExport?.nbTransactions?.toString() || ""
        },
        {
          key: "total",
          label: "Total virements",
          display: paymentExport?.total ? formatMoney(paymentExport.total) : "",
          sort: paymentExport?.total ? parseFloat(paymentExport.total) : "",
          search: paymentExport?.total || ""
        },
        {
          key: "exportedBy",
          label: "Exporté par",
          display: `${paymentExport?.exportedBy?.firstname ?? ""} ${
            paymentExport?.exportedBy?.lastname ?? ""
          }`,
          sort: paymentExport?.exportedBy?.firstname,
          search: paymentExport?.exportedBy?.firstname || ""
        }
      ]
    })) || [];

  return (
    <>
      <TableLayout
        data={tableData}
        isLoading={isFetching || isFetchingNextPage}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        globalActions={globalActions}
      />

      {renderModal()}
    </>
  );
};
