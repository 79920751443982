import type { BeneficiarySchema } from "~/schemas/beneficiary.schema";

import { useEffect, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Controller, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router";

import { queryKeys } from "~/config/query-keys-constants";
import { useUIContext } from "~/contexts/uiContext";
import { useDebounce } from "~/hooks/useDebounce";
import { handleApiError } from "~/services/errors";
import { getGouvAddresses } from "~/api/gouv-addresses";
import { createBeneficiary, getUser, updateBeneficiary } from "~/api/user";
import { Button } from "~/components/ui/Button/Button";
import { FormComboBox } from "~/components/ui/FormComboBox";
import { FormInput } from "~/components/ui/FormInput";
import { FormRow } from "~/components/ui/FormRow";
import { FormSelect } from "~/components/ui/FormSelect";
import { FormTitle } from "~/components/ui/FormTitle";
import { LoadingSpinner } from "~/components/ui/LoadingSpinner";
import { BeneficiaryFormSchema } from "~/schemas/beneficiary.schema";

export const CreateEditBeneficiary = ({ editMode = false }: { editMode?: boolean }) => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const { user_id: userId, financer_id: financerId } = useParams<{
    user_id?: string;
    financer_id?: string;
  }>();
  const { toast } = useUIContext();

  // Get the beneficiary when form is in edit mode
  const { data: beneficiaryToEdit, isLoading: isBeneficiaryLoading } = useQuery({
    queryKey: [queryKeys.users, userId],
    queryFn: () => getUser({ id: userId ? parseInt(userId) : null }),
    enabled: !!userId
  });

  // Get the financer associated to the beneficiary
  const { data: financer, isLoading: isFinancerLoading } = useQuery({
    queryKey: [queryKeys.users, financerId],
    queryFn: () =>
      getUser({ id: financerId ? parseInt(financerId) : beneficiaryToEdit?.financer?.id || null }),
    enabled: !!financerId || !!beneficiaryToEdit?.financer?.id
  });

  // Get financers list for combobox autocomplete
  // const [financerSearch, setFinancerSearch] = useState("");
  // const debouncedFinancerSearch = useDebounce<string>(financerSearch, 500);
  // const financersQueryParams = {
  //   role: userRoles.ROLE_FINANCER,
  //   searchName: debouncedFinancerSearch,
  //   sort: { key: "lastname", value: "ASC" }
  // } as const;
  // const { data: financers, isLoading: isFinancersLoading } = useQuery({
  //   queryKey: [queryKeys.users, financersQueryParams],
  //   queryFn: () => getUsers({ ...financersQueryParams })
  // });

  // Get addresses list for combobox autocomplete
  const [addressSearch, setAddressSearch] = useState("");
  const debouncedAddressSearch = useDebounce<string>(addressSearch, 500);
  const { data: addresses, isLoading: isAddressesLoading } = useQuery({
    queryKey: ["addresses", { search: debouncedAddressSearch }],
    queryFn: () => getGouvAddresses({ search: debouncedAddressSearch }),
    enabled: debouncedAddressSearch.length > 3
  });

  // Set first search values for comboboxes
  useEffect(() => {
    if (beneficiaryToEdit) {
      // setFinancerSearch(beneficiaryToEdit.commercial?.lastname || "");
      setAddressSearch(beneficiaryToEdit.address || "");
    }
  }, [beneficiaryToEdit]);

  // Beneficiary mutation
  const beneficiaryMutation = useMutation({
    mutationFn: (data: BeneficiarySchema) =>
      editMode
        ? updateBeneficiary({ userId: userId ? parseInt(userId) : null, data })
        : createBeneficiary({ data }),
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: [queryKeys.users] });
      toast(
        editMode
          ? "Le bénéficiaire a été modifié avec succès."
          : "Le bénéficiaire a été créé avec succès.",
        "success"
      );
      history.push("/prefinancing-contracts/beneficiaries");
    },
    onError(error) {
      handleApiError(error, toast);
    }
  });

  // Define form
  const form = useForm<BeneficiaryFormSchema>({
    resolver: zodResolver(BeneficiaryFormSchema),
    values: {
      lastname: beneficiaryToEdit?.lastname || "",
      firstname: beneficiaryToEdit?.firstname || "",
      email: beneficiaryToEdit?.email || "",
      mobileNumber: beneficiaryToEdit?.mobileNumber || "",
      fixeNumber: beneficiaryToEdit?.fixeNumber || null,
      address: beneficiaryToEdit?.address || "",
      zipcode: beneficiaryToEdit?.zipcode || "",
      city: beneficiaryToEdit?.city || "",
      isPro: beneficiaryToEdit?.isPro || false,
      financer: beneficiaryToEdit?.financer?.id.toString() || financerId || ""
    }
  });

  const isProSelected = form.watch("isPro");

  const onSubmit = (data: BeneficiaryFormSchema) => {
    const cleanData = Object.fromEntries(
      Object.entries(data).map(([key, value]) => [key, value === "" ? null : value])
    ) as BeneficiaryFormSchema;

    const mutationData = {
      ...cleanData,
      isActive: true
      // financer: beneficiaryToEdit?.financer?.id?.toString() || financerId || ""
    };

    beneficiaryMutation.mutate(mutationData);
  };

  if (
    editMode &&
    !!userId &&
    (isBeneficiaryLoading || (beneficiaryToEdit?.financer?.id && isFinancerLoading))
  ) {
    return <LoadingSpinner size="lg" fullPage />;
  }

  if (!editMode && !!financerId && isFinancerLoading) {
    return <LoadingSpinner size="lg" fullPage />;
  }

  if (editMode && !!userId && !beneficiaryToEdit) {
    return <p className="text-center">Le bénéficiaire à éditer est introuvable.</p>;
  }

  if (!editMode && !!financerId && !financer) {
    return <p className="text-center">Le financeur associé est introuvable.</p>;
  }

  return (
    <>
      <h2 className="mt-6">
        {editMode
          ? `Édition d'un bénéficiaire (${beneficiaryToEdit?.lastname} ${beneficiaryToEdit?.firstname})`
          : "Création d'un bénéficiaire"}
      </h2>
      <p>
        <span className="font-semibold">Financeur associé: </span>
        {financer?.lastname} {financer?.firstname}
      </p>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        {/* <FormRow>
          <Controller
            name="financer"
            control={form.control}
            render={({ field: { onChange, onBlur, ref } }) => {
              return (
                <FormComboBox
                  label="Financeur associé"
                  onChange={onChange}
                  onBlur={onBlur}
                  onSearchChange={setFinancerSearch}
                  options={
                    financers?.map((commercial) => ({
                      id: commercial?.id || 0,
                      label: `${commercial?.lastname || ""} ${commercial?.firstname || ""}`,
                      value: commercial?.id?.toString() || ""
                    })) || []
                  }
                  defaultOption={
                    beneficiaryToEdit?.commercial?.id
                      ? {
                          id: beneficiaryToEdit.commercial.id,
                          label: `${beneficiaryToEdit.commercial.lastname} ${beneficiaryToEdit.commercial.firstname}`,
                          value: beneficiaryToEdit.commercial.id.toString()
                        }
                      : null
                  }
                  infoMessage="Financeur associé au bénéficiaire."
                  errorMessage={form.formState.errors.financer?.message}
                  isLoading={isFinancersLoading}
                  ref={ref}
                />
              );
            }}
          />
        </FormRow> */}

        <FormTitle as="h3">Informations du bénéficiaire</FormTitle>

        <FormRow>
          <FormSelect
            label="Type de bénéficiaire"
            errorMessage={form.formState.errors.isPro?.message}
            {...form.register("isPro", {
              setValueAs: (v) => v === "true" || v === true
            })}
          >
            <option value="false">Particulier</option>
            <option value="true">Professionnel</option>
          </FormSelect>
        </FormRow>

        <FormRow>
          <FormInput
            label="Nom"
            errorMessage={form.formState.errors.lastname?.message}
            {...form.register("lastname")}
          />
          <FormInput
            label="Prénom"
            errorMessage={form.formState.errors.firstname?.message}
            {...form.register("firstname")}
          />
          <FormInput
            label="Email"
            errorMessage={form.formState.errors.email?.message}
            {...form.register("email")}
          />
          <FormInput
            label="N° de mobile"
            errorMessage={form.formState.errors.mobileNumber?.message}
            {...form.register("mobileNumber")}
          />
          <FormInput
            label="N° de fixe"
            optional
            errorMessage={form.formState.errors.fixeNumber?.message}
            {...form.register("fixeNumber")}
          />
        </FormRow>

        <FormRow>
          <Controller
            name="address"
            control={form.control}
            render={({ field: { onChange, onBlur, ref } }) => {
              return (
                <FormComboBox
                  label={isProSelected ? "Adresse de l'entreprise" : "Adresse"}
                  onChange={(value) => {
                    const address = addresses?.find((address) => address.id === value);
                    if (!address) return onChange(value || "");
                    form.setValue("zipcode", address.postcode || "", { shouldValidate: true });
                    form.setValue("city", address.district || address.city || "", {
                      shouldValidate: true
                    });
                    form.trigger("address");
                    onChange(
                      address.name ||
                        (address.housenumber && address.street
                          ? `${address.housenumber} ${address.street}`
                          : "")
                    );
                  }}
                  onBlur={onBlur}
                  onSearchChange={setAddressSearch}
                  allowCustomValue={true}
                  options={
                    addresses?.map((address) => ({
                      id: address.id || 0,
                      value: address.id || "",
                      label: address.label || "",
                      labelInInput: address.name || `${address.housenumber} ${address.street}`
                    })) || []
                  }
                  defaultOption={
                    beneficiaryToEdit?.address
                      ? {
                          id: beneficiaryToEdit.address,
                          value: beneficiaryToEdit.address,
                          label: beneficiaryToEdit.address
                        }
                      : null
                  }
                  infoMessage="Selectionnez une des propositions afin de correspondre aux données de l'URSSAF."
                  warningMessage={
                    isProSelected
                      ? "Adresse de l'entreprise à facturer et non celle du client."
                      : null
                  }
                  errorMessage={form.formState.errors.address?.message}
                  isLoading={isAddressesLoading}
                  ref={ref}
                />
              );
            }}
          />

          <FormInput
            label={isProSelected ? "Code postal de l'entreprise" : "Code postal"}
            warningMessage={
              isProSelected
                ? "Code postal de l'entreprise à facturer et non celui du client."
                : null
            }
            errorMessage={form.formState.errors.zipcode?.message}
            {...form.register("zipcode")}
          />
          <FormInput
            label={isProSelected ? "Ville de l'entreprise" : "Ville"}
            warningMessage={
              isProSelected ? "Ville de l'entreprise à facturer et non celle du client." : null
            }
            errorMessage={form.formState.errors.city?.message}
            {...form.register("city")}
          />
        </FormRow>

        <div className="mt-12 flex justify-end gap-4 border-t border-grey-200 pt-6">
          <Button fill="clear" onClick={() => history.goBack()}>
            Annuler
          </Button>
          <Button type="submit" disabled={beneficiaryMutation.isLoading}>
            {editMode ? "Modifier le bénéficiaire" : "Créer le bénéficiaire"}
          </Button>
        </div>
      </form>
    </>
  );
};
