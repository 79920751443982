import type { FallbackProps } from "react-error-boundary";

import { IonApp, IonContent, IonLoading, IonPage, IonToast, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { QueryClientProvider, QueryErrorResetBoundary } from "@tanstack/react-query";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { ErrorBoundary } from "react-error-boundary";

import { getToastColor } from "~/utils/misc";
import { AuthProvider } from "~/contexts/authContext";
import { UIProvider, useUIContext } from "~/contexts/uiContext";
import { queryClient } from "~/api/_queryClient";
import { Button } from "~/components/ui/Button/Button";
import { Router } from "./Router";

import "dayjs/locale/fr";
/* Core CSS required for Ionic components to work properly (mandatory) */
import "@ionic/react/css/core.css";
/* Basic CSS for apps built with Ionic (mandatory) */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";
/* Global css with Ionic variables (mandatory if you want an Ionic app looks like) */
import "~/theme/global.scss";

import { StrictMode } from "react";

setupIonicReact();
dayjs.extend(customParseFormat);
dayjs.locale("fr");

export const App = () => {
  const { globalLoading, globalToast, hideToast } = useUIContext();

  return (
    <>
      <Router />

      <IonLoading
        isOpen={globalLoading.isLoading}
        mode="md"
        message={
          globalLoading.action === "delete"
            ? "Suppression..."
            : globalLoading.action === "create"
              ? "Création..."
              : globalLoading.action === "update"
                ? "Mise à jour..."
                : globalLoading.action === "search"
                  ? "Recherche..."
                  : "Chargement..."
        }
      />
      <IonToast
        mode="md"
        position={window.innerWidth < 768 ? "top" : "bottom"}
        isOpen={globalToast.show}
        title={globalToast.title || ""}
        message={globalToast.message || ""}
        color={getToastColor(globalToast.status || "")}
        onDidDismiss={hideToast}
        duration={10000}
      />
    </>
  );
};

export function withProviders(Component: React.ComponentType) {
  return (
    <StrictMode>
      <IonApp className="font-inter">
        <QueryClientProvider client={queryClient}>
          <IonReactRouter basename={import.meta.env.BASE_URL}>
            <UIProvider>
              <AuthProvider>
                <QueryErrorResetBoundary>
                  {({ reset }) => (
                    <ErrorBoundary onReset={reset} FallbackComponent={ErrorBoundaryFallback}>
                      <Component />
                    </ErrorBoundary>
                  )}
                </QueryErrorResetBoundary>
              </AuthProvider>
            </UIProvider>
          </IonReactRouter>
        </QueryClientProvider>
      </IonApp>
    </StrictMode>
  );
}

export function ErrorBoundaryFallback({ error, resetErrorBoundary }: FallbackProps) {
  return (
    <IonPage>
      <IonContent className="container h-full">
        <div className="flex h-full flex-col items-center justify-center gap-8">
          <h1>Une erreur est survenue :</h1>
          <pre style={{ whiteSpace: "normal" }}>{error?.message}</pre>
          <div className="flex gap-4">
            <Button onClick={resetErrorBoundary}>Réessayer</Button>
            <Button onClick={() => window.location.reload()}>Recharger la page</Button>
            <Button routerLink="/">Retour à l&apos;accueil</Button>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
}
