import type { PaymentMethod } from "~/types/payment-method.types";
import type { DeepPartial } from "~/types/utils.types";

import { api } from "~/api/_config";

export const getPaymentMethods = async ({
  page,
  itemsPerPage,
  codes
}: {
  page?: number;
  itemsPerPage?: number;
  codes?: string[];
}) => {
  const response = await api.get<DeepPartial<PaymentMethod[]>>("/api/admin/payment_methods", {
    params: {
      ...(codes && { "code[]": codes }),
      page,
      itemsPerPage: itemsPerPage ?? 100
    }
  });
  return response.data;
};
