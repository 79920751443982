import { IonButton, IonIcon } from "@ionic/react";

import "./Button.scss";

type ButtonProps = {
  icon?: React.ComponentProps<typeof IonIcon>["icon"];
} & React.ComponentProps<typeof IonButton>;

export const Button = ({
  color = "primary",
  fill = "solid",
  size = "default",
  icon,
  children,
  ...props
}: ButtonProps) => {
  return (
    <IonButton color={color} fill={fill} size={size} mode="md" {...props}>
      {icon ? <IonIcon slot={children ? "start" : "icon-only"} icon={icon} /> : null}
      {children ? children : null}
    </IonButton>
  );
};
