import type { Attestation } from "~/types/attestation.types";
import type { DeepPartial } from "~/types/utils.types";

import { INFINITE_SCROLL_LIMIT } from "~/config/api";
import { api } from "./_config";

export const getAttestations = async ({
  year,
  customerCompanyName,
  customerLastname,
  customerFirstname,
  customerEmail,
  customerAddress,
  customerZipcode,
  customerCity,
  cooperatorCompanyName,
  cooperatorLastname,
  isCustomerPro,
  createdAtAfter,
  createdAtBefore,
  page,
  itemsPerPage
}: {
  year?: string;
  customerCompanyName?: string;
  customerLastname?: string;
  customerFirstname?: string;
  customerEmail?: string;
  customerAddress?: string;
  customerZipcode?: string;
  customerCity?: string;
  cooperatorCompanyName?: string;
  cooperatorLastname?: string;
  isCustomerPro?: string;
  createdAtAfter?: string;
  createdAtBefore?: string;
  page?: number;
  itemsPerPage?: number;
}) => {
  const params = {
    ...(year && { year }),
    ...(createdAtAfter && { "createdAt[after]": createdAtAfter }),
    ...(createdAtBefore && { "createdAt[before]": createdAtBefore + "T23:59:59" }),
    ...(customerCompanyName && { "customer.companyName": customerCompanyName }),
    ...(customerLastname && { "customer.lastname": customerLastname }),
    ...(customerFirstname && { "customer.firstname": customerFirstname }),
    ...(customerEmail && { "customer.email": customerEmail }),
    ...(customerAddress && { "customer.address": customerAddress }),
    ...(customerZipcode && { "customer.zipcode": customerZipcode }),
    ...(customerCity && { "customer.city": customerCity }),
    ...(cooperatorCompanyName && { "customer.cooperator.companyName": cooperatorCompanyName }),
    ...(cooperatorLastname && { "customer.cooperator.lastname": cooperatorLastname }),
    ...(isCustomerPro && {
      "customer.isPro": isCustomerPro === "true" ? true : isCustomerPro === "false" ? false : null
    }),
    page,
    itemsPerPage: itemsPerPage ?? INFINITE_SCROLL_LIMIT,
    "order[sendAt]": "desc"
  };

  const response = await api.get<DeepPartial<Attestation[]>>(`/api/admin/attestations`, { params });
  return response.data;
};

export const generateAttestationPdf = async ({
  year,
  customerId
}: {
  year: number;
  customerId: number;
}) => {
  const response = await api.get<Blob>(`/api/admin/attestations/pdf/${year}/${customerId}`, {
    responseType: "blob"
  });

  return response.data;
};

export const sendAttestationMail = async ({
  attestationId,
  email
}: {
  attestationId: number;
  email: string;
}) => {
  const response = await api.post(`/api/admin/attestations/${attestationId}/send/to/${email}`);
  return response.data;
};
