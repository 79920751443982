import { forwardRef } from "react";

// import "react-datepicker/dist/react-datepicker.css";

import { cn } from "~/utils/misc";
// import fr from "date-fns/locale/fr";
// import { registerLocale } from "react-datepicker";

import { FormInput } from "~/components/ui/FormInput";

type FormDatePickerProps = {
  label?: string;
  // setValue?: UseFormSetValue<any>;
} & React.ComponentPropsWithRef<typeof FormInput>;

// registerLocale("fr", fr);

export const FormDatePicker = forwardRef<HTMLInputElement, FormDatePickerProps>(
  function ForwardedRefInput({ label, inputClassName, ...props }, ref) {
    // const inputRef = useRef<HTMLInputElement>(null);
    // useImperativeHandle(ref, () => inputRef.current!);

    // useEffect(() => {
    //   if (inputRef.current?.value) {
    //   }
    // }, [inputRef.current?.value]);

    // const [date, setDate] = useState<string | null>(defaultValue || null);
    // useEffect(() => {
    //   if (setValue && props.name && date) {
    //     setValue(props.name, dayjs(date).format("YYYY-MM-DD"));
    //   }
    // }, [date, setValue, props.name]);

    return (
      <>
        <FormInput
          type={props.type || "date"}
          inputClassName={cn("text-center", inputClassName)}
          label={label || null}
          ref={ref}
          {...props}
        />
        {/* <input
          type="date"
          // value={date ? dayjs(date).format("YYYY-MM-DD") : ""}
          // value={date || ""}
          ref={inputRef}
          {...props}
        /> */}
        {/* <DatePicker
          selected={inputRef.current?.value ? new Date(inputRef.current.value) : null}
          onChange={(date) => {
            if (setValue && props.name) {
              setValue(props.name, dayjs(date).format("YYYY-MM-DD"));
            }
          }}
          locale={"fr"}
          wrapperClassName="w-full"
          dateFormat={"dd/MM/yyyy"}
          customInput={<FormInput inputClassName="text-center" label={label || null} />}
        /> */}
      </>
    );
  }
);
