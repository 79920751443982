import { z } from "zod";

export const RecoveryQuotationActionSchema = z.object({
  name: z.string().min(1, "Veuillez sélectionner une action."),
  comment: z.string().optional().nullable(),
  dateActionAt: z.string().optional().nullable(),
  dateActionDoneAt: z.string().optional().nullable()
});

export const MultipleRecoveryQuotationsActionSchema = z.object({
  actionRecoveries: z.array(RecoveryQuotationActionSchema),
  isFinishedTask: z.boolean()
});

export type RecoveryQuotationActionSchema = z.infer<typeof RecoveryQuotationActionSchema>;
export type MultipleRecoveryQuotationsActionSchema = z.infer<
  typeof MultipleRecoveryQuotationsActionSchema
>;
