import type { ComponentProps, PropsWithChildren } from "react";

import { cn } from "~/utils/misc";

export const FormLabel = ({
  children,
  optional,
  ...props
}: PropsWithChildren<{ optional?: boolean }> & ComponentProps<"label">) => {
  return (
    // <div className="mx-1 mb-2 flex items-end justify-between text-sm">
    <label
      className={cn("mx-1 mb-2 text-sm font-medium", optional ? "" : "text-danger-700")}
      {...props}
    >
      {children}
    </label>
    //   {optional ? <span className="text-xs text-grey-500">Facultatif</span> : null}
    // </div>
  );
};
