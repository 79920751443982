import type { AdvancedSearchParams } from "~/components/TableLayout";
import type { MainTableProps } from "~/components/ui/MainTable";

import { useMemo, useState } from "react";
import { useInfiniteQuery, useMutation } from "@tanstack/react-query";
import { calendarOutline, trashOutline } from "ionicons/icons";

import { scheduleQuotationTaskStates } from "~/config/api-constants";
import { queryKeys } from "~/config/query-keys-constants";
import { formatDisplayDate } from "~/utils/misc";
import { useAuthContext } from "~/contexts/authContext";
import { useUIContext } from "~/contexts/uiContext";
import { useModal } from "~/hooks/useModal";
import { handleApiError } from "~/services/errors";
import { getNextPageParamFn } from "~/api/_config";
import { queryClient } from "~/api/_queryClient";
import {
  cancelMultipleQuotationTasks,
  cancelQuotationTask,
  getTaskQuotations
} from "~/api/quotation";
import { QuotationsTasksSearchForm } from "~/components/AdvancedSearchForms/Quotations/QuotationsTasksSearchForm";
import { ConfirmationModal } from "~/components/Modals/ConfirmationModal";
import { ReprogramQuotationTaskModal } from "~/components/Modals/ReprogramQuotationTaskModal";
import { TableLayout } from "~/components/TableLayout";
import { ExternalLink, Link } from "~/components/ui/Link";

export const QuotationsTasks = () => {
  const { isAdmin } = useAuthContext();
  const { toast } = useUIContext();
  const { openModal, closeModal, renderModal } = useModal();

  const [advancedSearchParams, setAdvancedSearchParams] = useState<AdvancedSearchParams>(null);

  const queryParams = {
    states: [scheduleQuotationTaskStates.STATE_WAITING],
    ...advancedSearchParams
  };

  const { data, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: [queryKeys.taskQuotations, queryParams],
    queryFn: ({ pageParam }) => getTaskQuotations({ ...queryParams, page: pageParam }),
    getNextPageParam: getNextPageParamFn
  });

  const cancelQuotationTaskMutation = useMutation(cancelQuotationTask, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.taskQuotations]);
      toast("La tâche a été annulée avec succès.", "success");
    },
    onError(error) {
      handleApiError(error, toast);
    }
  });

  const cancelMultipleQuotationTasksMutation = useMutation(cancelMultipleQuotationTasks, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.taskQuotations]);
      toast("Les tâches ont été annulée avec succès.", "success");
    },
    onError(error) {
      handleApiError(error, toast);
    }
  });

  const groupedActions = [
    {
      id: "cancel",
      label: "Annuler les tâches sélectionnées",
      action: (ids: number[]) =>
        openModal(
          <ConfirmationModal
            title="Annuler les tâches sélectionnées"
            danger
            isOpen={true}
            onClose={closeModal}
            onConfirm={() => cancelMultipleQuotationTasksMutation.mutate({ ids })}
          >
            Êtes vous sûr de vouloir annuler les tâches sélectionnées ?
          </ConfirmationModal>
        )
    }
  ];

  const tableData: MainTableProps["data"] = useMemo(
    () =>
      data?.pages.flat().map((task) => {
        const quotation = task?.quotationMaster;
        return {
          itemId: task?.id || 0,
          checkboxConditions: [
            {
              id: "cancel",
              condition: true
            }
          ],
          actions: [
            {
              label: "Reprogrammer la tâche",
              icon: calendarOutline,
              color: "cyan",
              action: () =>
                task?.id
                  ? openModal(
                      <ReprogramQuotationTaskModal
                        taskId={task.id}
                        isOpen={true}
                        onClose={closeModal}
                      />
                    )
                  : null
            },
            {
              label: "Annuler l'envoi de ce relevé",
              icon: trashOutline,
              color: "red",
              action: () =>
                openModal(
                  <ConfirmationModal
                    title="Annuler l'envoi du relevé"
                    isOpen={true}
                    onClose={closeModal}
                    danger
                    onConfirm={() =>
                      task?.id ? cancelQuotationTaskMutation.mutate({ id: task.id }) : null
                    }
                  >
                    Êtes vous sûr de vouloir annuler l&apos;envoi de ce relevé ?
                  </ConfirmationModal>
                )
            }
          ],
          columns: [
            {
              key: "scheduledAt",
              label: "Date d'envoi prévue",
              display: task?.scheduledAt ? formatDisplayDate(task.scheduledAt) : "",
              sort: task?.scheduledAt,
              search: task?.scheduledAt ? formatDisplayDate(task.scheduledAt) : ""
            },
            {
              key: "idNormalized",
              label: "N° relevé parent",
              display: (
                <Link
                  to={`/file/deliver/quotation/${task?.quotationMaster?.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {quotation?.idNormalized}
                </Link>
              )
            },
            {
              key: "customer.lastname",
              label: "Client",
              display: (
                <div className="flex flex-col gap-1">
                  {quotation?.customer?.companyName ? (
                    <span className="font-medium">{quotation.customer.companyName}</span>
                  ) : null}
                  {quotation?.customer?.lastname || quotation?.customer?.firstname ? (
                    <span>
                      {quotation.customer.lastname} {quotation.customer.firstname}
                    </span>
                  ) : null}
                  {quotation?.customer?.email ? (
                    <ExternalLink to={`mailto:${quotation.customer.email}`} className="text-xs">
                      {quotation.customer.email}
                    </ExternalLink>
                  ) : null}
                  {quotation?.customer?.mobileNumber ? (
                    <ExternalLink to={`tel:${quotation.customer.mobileNumber}`} className="text-xs">
                      {quotation.customer.mobileNumber}
                    </ExternalLink>
                  ) : null}
                  {quotation?.customer?.fixeNumber ? (
                    <ExternalLink to={`tel:${quotation.customer.fixeNumber}`} className="text-xs">
                      {quotation.customer.fixeNumber}
                    </ExternalLink>
                  ) : null}
                </div>
              ),
              sort: quotation?.customer?.companyName || quotation?.customer?.lastname,
              search: `${quotation?.customer?.companyName} ${quotation?.customer?.lastname} ${quotation?.customer?.firstname} ${quotation?.customer?.email} ${quotation?.customer?.mobileNumber} ${quotation?.customer?.fixeNumber}`
            },
            {
              key: "cooperator.lastname",
              label: "Coopérateur",
              display: (
                <div className="flex flex-col gap-1">
                  {quotation?.cooperator?.companyName ? (
                    <span className="font-medium">{quotation.cooperator.companyName}</span>
                  ) : null}
                  {quotation?.cooperator?.lastname || quotation?.cooperator?.firstname ? (
                    <span>
                      {quotation.cooperator.lastname} {quotation.cooperator.firstname}
                    </span>
                  ) : null}
                  {quotation?.cooperator?.email ? (
                    <ExternalLink to={`mailto:${quotation.cooperator.email}`} className="text-xs">
                      {quotation.cooperator.email}
                    </ExternalLink>
                  ) : null}
                  {quotation?.cooperator?.mobileNumber ? (
                    <ExternalLink
                      to={`tel:${quotation.cooperator.mobileNumber}`}
                      className="text-xs"
                    >
                      {quotation.cooperator.mobileNumber}
                    </ExternalLink>
                  ) : null}
                  {quotation?.cooperator?.fixeNumber ? (
                    <ExternalLink to={`tel:${quotation.cooperator.fixeNumber}`} className="text-xs">
                      {quotation.cooperator.fixeNumber}
                    </ExternalLink>
                  ) : null}
                </div>
              ),
              sort: quotation?.cooperator?.lastname,
              search: `${quotation?.cooperator?.companyName} ${quotation?.cooperator?.lastname} ${quotation?.cooperator?.firstname} ${quotation?.cooperator?.email} ${quotation?.cooperator?.mobileNumber} ${quotation?.cooperator?.fixeNumber}`,
              condition: isAdmin
            },
            {
              key: "isAvanceImmediate",
              label: "Avance Immédiate",
              display: quotation?.isAvanceImmediate ? "Oui" : "Non",
              sort: quotation?.isAvanceImmediate,
              search: quotation?.isAvanceImmediate ? "avance immédiate" : ""
            },
            {
              key: "state",
              label: "Statut",
              display:
                task?.state === scheduleQuotationTaskStates.STATE_CANCELED
                  ? "Annulé"
                  : task?.state === scheduleQuotationTaskStates.STATE_TREATED
                    ? "Traité"
                    : task?.state === scheduleQuotationTaskStates.STATE_WAITING
                      ? "En attente"
                      : "",
              sort: task?.state
            }
          ]
        };
      }) || [],
    [data?.pages, isAdmin, openModal, closeModal, cancelQuotationTaskMutation]
  );

  return (
    <>
      <TableLayout
        data={tableData}
        isLoading={isFetching || isFetchingNextPage}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        AdvancedSearchForm={QuotationsTasksSearchForm}
        advancedSearchParams={advancedSearchParams}
        handleAdvancedSearch={(data) => setAdvancedSearchParams(data)}
        groupedActions={groupedActions}
      />

      {renderModal()}
    </>
  );
};
