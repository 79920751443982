import type { StatisticsChartProps, TopCustomersStats } from "~/types/statistics.types";

import { formatMoney } from "~/utils/misc";
import { useGetStatisticsQuery } from "~/api/statistics";
import { StatisticsType } from "~/types/statistics.types";
import { LoadingSpinner } from "../ui/LoadingSpinner";
import { Table } from "../ui/Table";

export const TopCustomersChart = (params: StatisticsChartProps) => {
  const { data, isLoading } = useGetStatisticsQuery<TopCustomersStats>(
    StatisticsType.TOP_CUSTOMERS,
    params
  );

  if (isLoading) {
    return (
      <div className="my-20 flex w-full items-center justify-center gap-4">
        <LoadingSpinner /> Chargement des données
      </div>
    );
  }

  if (!data || !data.topCustomers.length) {
    return (
      <div className="my-20 flex w-full items-center justify-center gap-4">
        Aucun montant facturé sur la période
      </div>
    );
  }

  const stats = data.topCustomers;

  return (
    <div className="py-4 sm:px-4">
      <Table
        data={{
          headers: ["Nom", "Prénom", "Société", "CA HT Facturé"],
          rows: stats.map((row) => ({
            key: row.id,
            columns: [
              row.lastname.toUpperCase(),
              row.firstname,
              row.companyName,
              formatMoney(row.nb)
            ]
          }))
        }}
      />
    </div>
  );
};
