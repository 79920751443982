import type { User } from "~/types/user.types";
import type { DeepPartial } from "~/types/utils.types";

import { useEffect, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import { Controller, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router";

import { membershipStates, userRoles } from "~/config/api-constants";
import { queryKeys } from "~/config/query-keys-constants";
import { formatMoney, transformDateTimeToDate } from "~/utils/misc";
import { useAuthContext } from "~/contexts/authContext";
import { useUIContext } from "~/contexts/uiContext";
import { useDebounce } from "~/hooks/useDebounce";
import { useModal } from "~/hooks/useModal";
import { handleApiError } from "~/services/errors";
import { createMembership, getMembership, updateMembership } from "~/api/membership";
import { getUsers } from "~/api/user";
import { ConfirmationModal } from "~/components/Modals/ConfirmationModal";
import { Button } from "~/components/ui/Button/Button";
import { FormComboBox } from "~/components/ui/FormComboBox";
import { FormDatePicker } from "~/components/ui/FormDatePicker";
import { FormInput } from "~/components/ui/FormInput";
import { FormRow } from "~/components/ui/FormRow";
import { FormSelect } from "~/components/ui/FormSelect";
import { FormTextArea } from "~/components/ui/FormTextArea";
import { FormTitle } from "~/components/ui/FormTitle";
import { LoadingSpinner } from "~/components/ui/LoadingSpinner";
import { MembershipSchema } from "~/schemas/membership.schema";

export const CreateEditMembership = ({ editMode = false }: { editMode?: boolean }) => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const { membership_id: membershipId } = useParams<{ membership_id?: string }>();
  const { currentUser, isAdmin } = useAuthContext();
  const { toast } = useUIContext();
  const { openModal, closeModal, renderModal } = useModal();

  // Get the quotation when form is in edit mode
  const { data: membershipToEdit, isLoading: isMembershipLoading } = useQuery({
    queryKey: [queryKeys.memberships, membershipId],
    queryFn: membershipId ? () => getMembership({ id: parseInt(membershipId) }) : () => null,
    enabled: !!membershipId
  });

  // Cooperators list for cooperator selection (Admin only)
  const [selectedCooperator, setSelectedCooperator] = useState<DeepPartial<User> | null>(
    membershipToEdit?.cooperator || null
  );
  const [cooperatorsSearch, setCooperatorsSearch] = useState("");
  const debouncedCooperatorsSearch = useDebounce<string>(cooperatorsSearch, 500);
  const cooperatorsQueryParams = {
    role: userRoles.ROLE_COOPERATOR,
    searchName: debouncedCooperatorsSearch,
    sort: { key: "lastname", value: "ASC" }
  } as const;
  const { data: cooperators, isLoading: isCooperatorsLoading } = useQuery({
    queryKey: [queryKeys.users, cooperatorsQueryParams],
    queryFn: () => getUsers({ ...cooperatorsQueryParams }),
    enabled: isAdmin
  });

  // Set first search values for comboboxes
  useEffect(() => {
    if (membershipToEdit) {
      setCooperatorsSearch(membershipToEdit.cooperator?.lastname || "");
      setSelectedCooperator(membershipToEdit.cooperator || null);
    }
  }, [membershipToEdit]);

  // Customer mutation
  const membershipMutation = useMutation({
    mutationFn: (data: MembershipSchema) =>
      editMode
        ? updateMembership({ data, id: parseInt(membershipId || "") })
        : createMembership({ data }),
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: [queryKeys.memberships] });
      toast(
        editMode ? "L'adhesion a été modifiée avec succès." : "L'adhesion a été créée avec succès.",
        "success"
      );
      history.push("/cooperator-dashboard/waiting-memberships");
    },
    onError(error) {
      handleApiError(error, toast);
    }
  });

  // Define form
  const form = useForm<MembershipSchema>({
    resolver: zodResolver(MembershipSchema),
    values: {
      cooperator: membershipToEdit?.cooperator?.id
        ? membershipToEdit.cooperator.id.toString()
        : isAdmin
          ? selectedCooperator?.id?.toString() || ""
          : currentUser?.id?.toString() || "",
      contactFirstname: membershipToEdit?.cooperator?.firstname || "",
      contactLastname: membershipToEdit?.cooperator?.lastname || "",
      contactEmail: membershipToEdit?.cooperator?.email || "",
      contactPhone:
        membershipToEdit?.cooperator?.mobileNumber ||
        membershipToEdit?.cooperator?.fixeNumber ||
        "",
      amountMembership: membershipToEdit?.amountMembership || "390",
      discount: membershipToEdit?.discount || null,
      // Today date in format YYYY-MM-DD
      startAt:
        transformDateTimeToDate(membershipToEdit?.startAt) ||
        new Date().toISOString().split("T")[0] ||
        "",
      // Today date + 1 year
      endAt:
        transformDateTimeToDate(membershipToEdit?.endAt) ||
        new Date(new Date().setFullYear(new Date().getFullYear() + 1))
          .toISOString()
          .split("T")[0] ||
        "",
      hasSocialPart: membershipToEdit ? Boolean(membershipToEdit.hasSocialPart) : true
    }
  });

  const currentAmountMembership = form.watch("amountMembership");
  const currentDiscount = form.watch("discount");
  const currentHasSocialPart = form.watch("hasSocialPart");

  const onSubmit = ({
    state,
    data
  }: {
    state: number | null | undefined;
    data: MembershipSchema;
  }) => {
    const formatedData = { ...data, ...(state !== undefined ? { state } : {}) };

    membershipMutation.mutate(formatedData);
  };

  if (!!membershipId && isMembershipLoading) {
    return <LoadingSpinner size="lg" fullPage />;
  }

  if (!!membershipId && !membershipToEdit) {
    return <p className="text-center">L&apos;adhésion à éditer est introuvable.</p>;
  }

  return (
    <>
      <h2 className="mt-6">
        {editMode
          ? `Édition d'une adhésion : ${membershipToEdit?.idNormalized}`
          : "Création d'une adhésion"}
      </h2>
      <form>
        {isAdmin ? (
          <>
            <FormTitle as="h3">Informations du contact</FormTitle>
            <FormRow>
              <div className="col-span-1 space-y-8">
                <Controller
                  name="cooperator"
                  control={form.control}
                  render={({ field: { onChange, onBlur, ref } }) => {
                    return (
                      <FormComboBox
                        label="Coopérateur associé"
                        onBlur={onBlur}
                        onChange={(value) => {
                          const cooperator = cooperators?.find(
                            (cooperator) => cooperator?.id?.toString() === value
                          );
                          if (!cooperator) return;
                          form.setValue("contactFirstname", cooperator.firstname || "");
                          form.setValue("contactLastname", cooperator.lastname || "");
                          form.setValue("contactEmail", cooperator.email || "");
                          form.setValue("contactPhone", cooperator.mobileNumber || "");

                          // setSelectedCooperator(cooperator);
                          onChange(value);
                        }}
                        onSearchChange={setCooperatorsSearch}
                        options={
                          cooperators?.map((coop) => ({
                            id: coop?.id || 0,
                            label: `${coop?.lastname || ""} ${coop?.firstname || ""}${coop?.companyName ? ` - ${coop.companyName}` : ""}`,
                            value: coop?.id?.toString() || ""
                          })) || []
                        }
                        defaultOption={
                          membershipToEdit?.cooperator?.id
                            ? {
                                id: membershipToEdit.cooperator.id,
                                label: `${membershipToEdit.cooperator.lastname} ${membershipToEdit.cooperator.firstname}`,
                                value: membershipToEdit.cooperator.id.toString()
                              }
                            : null
                        }
                        infoMessage="Coopérateur auquel est associé le relevé."
                        errorMessage={form.formState.errors.cooperator?.message}
                        isLoading={isCooperatorsLoading}
                        ref={ref}
                      />
                    );
                  }}
                />
                <Button
                  href="/administration/cooperators/create"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Créer un coopérateur
                </Button>
              </div>
              <div className="grid grid-cols-1 gap-6 sm:col-span-2 sm:grid-cols-2">
                <FormInput
                  label="Prénom"
                  errorMessage={form.formState.errors.contactFirstname?.message}
                  {...form.register("contactFirstname")}
                />
                <FormInput
                  label="Nom"
                  errorMessage={form.formState.errors.contactLastname?.message}
                  {...form.register("contactLastname")}
                />
                <FormInput
                  label="Email"
                  errorMessage={form.formState.errors.contactEmail?.message}
                  {...form.register("contactEmail")}
                />
                <FormInput
                  label="Téléphone"
                  errorMessage={form.formState.errors.contactPhone?.message}
                  {...form.register("contactPhone")}
                />
              </div>
            </FormRow>
          </>
        ) : null}

        <FormTitle as="h3">Paramètres de l&apos;adhésion</FormTitle>

        <FormRow>
          <FormInput
            type="number"
            step="any"
            label="Montant HT de l'adhésion"
            rightAddon="€ HT"
            errorMessage={form.formState.errors.amountMembership?.message}
            {...form.register("amountMembership")}
          />
          <FormInput
            type="number"
            step="any"
            label="Montant HT de la remise"
            rightAddon="€ HT"
            errorMessage={form.formState.errors.discount?.message}
            {...form.register("discount")}
          />
          <FormSelect
            label="Achat de part sociale"
            infoMessage="Le montant de la part sociale est de 10€ TTC."
            errorMessage={form.formState.errors.hasSocialPart?.message}
            {...form.register("hasSocialPart", {
              setValueAs: (v) => v === "true" || v === true
            })}
          >
            <option value="true">Oui</option>
            <option value="false">Non</option>
          </FormSelect>
          <FormDatePicker
            label="Date de prise d'effet"
            errorMessage={form.formState.errors.startAt?.message}
            {...form.register("startAt", {
              onChange: (event) => {
                const date = dayjs(event.target.value);
                const endDate = date.add(1, "year").format("YYYY-MM-DD");
                form.setValue("endAt", endDate);
              }
            })}
          />
          <FormDatePicker
            label="Date de fin"
            errorMessage={form.formState.errors.endAt?.message}
            {...form.register("endAt")}
          />
        </FormRow>

        <div className="mt-8 gap-6 sm:flex">
          <FormTextArea
            label="Commentaires / Observations"
            className="grow"
            textareaClassName="h-32"
            errorMessage={form.formState.errors.comments?.message}
            {...form.register("comments")}
          />
          <div className="mt-6 min-w-80 space-y-1 rounded-xl bg-grey-100 p-4 shadow-sm">
            {currentDiscount && parseFloat(currentDiscount) > 0 ? (
              <>
                <div className="flex items-center justify-between gap-8">
                  <div className="text-sm">Total HT (sans remise)</div>
                  <div className="font-medium">{formatMoney(currentAmountMembership)}</div>
                </div>
                <div className="flex items-center justify-between gap-8">
                  <div className="text-sm">Remise</div>
                  <div className="font-medium">-{formatMoney(currentDiscount)}</div>
                </div>
              </>
            ) : null}
            <div className="flex items-center justify-between gap-8">
              <div className="text-sm">Total HT</div>
              <div className="font-medium">
                {currentDiscount && parseFloat(currentDiscount) > 0
                  ? formatMoney(parseFloat(currentAmountMembership) - parseFloat(currentDiscount))
                  : formatMoney(currentAmountMembership)}
              </div>
            </div>
            <div className="flex items-center justify-between gap-8">
              <div className="text-sm">TVA 20%</div>
              <div className="font-medium">
                {formatMoney(
                  currentDiscount
                    ? (parseFloat(currentAmountMembership) - parseFloat(currentDiscount)) * 0.2
                    : parseFloat(currentAmountMembership) * 0.2
                )}
              </div>
            </div>
            {currentHasSocialPart ? (
              <div className="flex items-center justify-between gap-8">
                <div className="text-sm">Part sociale</div>
                <div className="font-medium">{formatMoney(10)}</div>
              </div>
            ) : null}
            <div className="!mt-2 flex items-center justify-between gap-8">
              <div className="font-medium">Total TTC</div>
              <div className="text-lg font-bold text-primary-700">
                {formatMoney(
                  currentDiscount
                    ? (parseFloat(currentAmountMembership) - parseFloat(currentDiscount)) * 1.2 +
                        (currentHasSocialPart ? 10 : 0)
                    : parseFloat(currentAmountMembership) * 1.2 + (currentHasSocialPart ? 10 : 0)
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="mt-12 flex flex-wrap gap-4 border-t border-grey-200 pt-6 sm:justify-end">
          <Button fill="clear" onClick={() => history.goBack()}>
            Annuler
          </Button>

          {!membershipToEdit?.state || membershipToEdit.state === membershipStates.STATE_DRAFT ? (
            <Button
              type="button"
              fill="outline"
              onClick={form.handleSubmit((data) =>
                onSubmit({ state: membershipStates.STATE_DRAFT, data })
              )}
              disabled={membershipMutation.isLoading}
            >
              Enregistrer comme brouillon
            </Button>
          ) : null}

          {membershipToEdit?.state === membershipStates.STATE_BILL ? (
            <Button
              type="button"
              onClick={form.handleSubmit((data) =>
                onSubmit({ state: membershipStates.STATE_BILL, data })
              )}
              disabled={membershipMutation.isLoading}
            >
              Enregistrer & régénérer la facture
            </Button>
          ) : membershipToEdit?.signedAt ? (
            <Button
              type="button"
              onClick={form.handleSubmit((data) => onSubmit({ state: undefined, data }))}
              disabled={membershipMutation.isLoading}
            >
              Modifier
            </Button>
          ) : (
            <Button
              type="button"
              onClick={() =>
                openModal(
                  <ConfirmationModal
                    title="Valider le relevé"
                    isOpen={true}
                    onClose={closeModal}
                    onConfirm={form.handleSubmit((data) => {
                      onSubmit({
                        state: membershipStates.STATE_SEND,
                        data
                      });
                    })}
                  >
                    Etes vous sûr de valider l&apos;adhésion ?<br />
                    Un email et un sms de signature seront envoyés au coopérateur.
                  </ConfirmationModal>
                )
              }
              disabled={membershipMutation.isLoading}
            >
              Enregistrer & envoyer au coopérateur
            </Button>
          )}
        </div>
      </form>
      {renderModal()}
    </>
  );
};
