import type { PaymentExport } from "~/types/payment-export.types";
import type { DeepPartial } from "~/types/utils.types";

import { INFINITE_SCROLL_LIMIT } from "~/config/api";
import { api } from "~/api/_config";

export const getPayments = async ({
  page,
  itemsPerPage
}: {
  page?: number;
  itemsPerPage?: number;
}) => {
  const response = await api.get<DeepPartial<PaymentExport[]>>("/api/admin/payment_exports", {
    params: {
      "order[createdAt]": "DESC",
      page,
      itemsPerPage: itemsPerPage ?? INFINITE_SCROLL_LIMIT
    }
  });
  return response.data;
};

export const exportAllPendingPayments = async () => {
  const response = await api.get<string>("/api/admin/payment_exports/export/waiting");
  return response.data;
};

export const getPaymentsTotalPaid = async () => {
  const response = await api.get<{ totalPaid: number }>("/api/admin/payment_exports/total/waiting");
  return response.data.totalPaid;
};

export const hasPaymentExportTask = async () => {
  const response = await api.get<{ hasTask: boolean }>("/api/tasks/admin/has-task/PaymentExport");
  return response.data.hasTask;
};

export const getCoopProblems = async () => {
  const response = await api.get<{
    coopMissingRibs: { id: number; companyName: string; lastname: string; firstname: string }[];
  }>("/api/admin/payment_exports/cooperator/missing-rib");
  return response.data.coopMissingRibs;
};
