import { z } from "zod";

// "paymentMethod": "path/실례.html",
// "amount": "string",
// "logInfo": "string",
// "codeRemise": "string",
// "createdAt": "2024-01-31T13:19:00.135Z",
// "receivedAt": "2024-01-31T13:19:00.135Z",
// "invoiceAt": "2024-01-31T13:19:00.135Z"

export const MembershipPaymentSchema = z.object({
  paymentMethod: z.string().min(1, "Veuillez renseigner un moyen de paiement."),
  amount: z.string().min(1, "Veuillez renseigner un montant."),
  logInfo: z.string().optional().nullable(),
  codeRemise: z.string().optional().nullable(),
  receivedAt: z.string().min(1, "Veuillez renseigner une date de réception du paiement."),
  billedLastYear: z.boolean({
    required_error: "Veuillez renseigner si le paiement a été facturé sur l'exercice précédent.",
    invalid_type_error: "Veuillez renseigner si le paiement a été facturé sur l'exercice précédent."
  })
});

export type MembershipPaymentSchema = z.infer<typeof MembershipPaymentSchema>;
