import type { TokenResponse } from "~/types/token.types";
import type { User } from "~/types/user.types";

import { api } from "./_config";

export const requestLogin = async ({
  username,
  password
}: {
  username: string;
  password: string;
}) => {
  const response = await api.post<TokenResponse>("/api/login", {
    username: username,
    password: password
  });
  return response.data;
};

export const requestRefreshToken = async (refreshToken: string) => {
  const response = await api.post<TokenResponse>(
    "/api/token/refresh",
    {
      refresh_token: refreshToken
    },
    {
      headers: {
        Authorization: `TEST`
      }
    }
  );
  return response.data;
};

export const renewPassword = async ({
  id,
  password,
  token
}: {
  id: User["id"];
  password: string;
  token: string;
}) => {
  const response = await api.put(`/api/users/${id}/renew_password`, {
    password,
    token
  });
  return response.data;
};

export const requestForgottenPassword = async ({ email }: { email: string }) => {
  const response = await api.post("/api/users/forgotten_password", {
    email
  });
  return response.data;
};
