import { z } from "zod";

const MAX_FILE_SIZE = 1024 * 1024 * 2; // 2MB
const ACCEPTED_IMAGE_TYPES = ["image/png", "image/jpeg", "image/jpg"];

export const ProfileInformationsSchema = z.object({
  email: z.string().min(1, "Veuillez saisir un email de contact").email(),
  mobileNumber: z
    .string()
    .min(1, "Veuillez renseigner un numéro de téléphone mobile.")
    .regex(
      /^(\+33|\+330|0)[67]([-. ]?[0-9]{2}){4}$/,
      "Veuillez renseigner un numéro de téléphone mobile français valide ou laisser ce champ vide."
    )
    .transform((value) => value.replace(/[-. ]/g, "")),
  fixeNumber: z
    .string()
    .min(1, "Veuillez renseigner un numéro de téléphone mobile.")
    .regex(
      /^(\+33|\+330|0)[1-9]([-. ]?[0-9]{2}){4}$/,
      "Veuillez renseigner un numéro de téléphone fixe valide ou laisser ce champ vide."
    )
    .transform((value) => value.replace(/[-. ]/g, "")),
  address: z.string().min(1, "Veuillez saisir une adresse"),
  zipcode: z.string().min(1, "Veuillez saisir un code postal"),
  city: z.string().min(1, "Veuillez saisir une ville"),
  logo: z
    .any()
    .refine((files) => {
      if (!files || files.length === 0) return true;
      return ACCEPTED_IMAGE_TYPES.includes(files[0].type);
    }, "L'image doit être au format png, jpeg ou jpg")
    .refine((files) => {
      if (!files || files.length === 0) return true;
      return files[0].size <= MAX_FILE_SIZE;
    }, "Le fichier est trop volumineux (2Mo maximum)")
    .optional()
    .nullable()
});

export const ProfileQuotationHeadersSchema = z.object({
  header1: z.string().optional().nullable(),
  header2: z.string().optional().nullable(),
  header3: z.string().optional().nullable(),
  header4: z.string().optional().nullable(),
  header5: z.string().optional().nullable(),
  header6: z.string().optional().nullable()
});

export const ProfileAccountingExportSchema = z.object({
  journalCode: z.string().optional().nullable(),
  tvaExportCode: z.string().optional().nullable(),
  administrativeCostsExportCode: z.string().optional().nullable(),
  totalExportCode: z.string().optional().nullable(),
  codeAideFinanciere: z.string().optional().nullable(),
  libelleAideFinanciere: z.string().optional().nullable()
});

export const ProfilePasswordSchema = z
  .object({
    password: z.string().min(8, "Le mot de passe doit contenir au moins 8 caractères"),
    passwordConfirmation: z.string().min(8, "Le mot de passe doit contenir au moins 8 caractères")
  })
  .refine((data) => data.password === data.passwordConfirmation, {
    message: "Les mots de passe ne correspondent pas",
    path: ["passwordConfirmation"]
  });

export type ProfileInformationsSchema = z.infer<typeof ProfileInformationsSchema>;
export type ProfileQuotationHeadersSchema = z.infer<typeof ProfileQuotationHeadersSchema>;
export type ProfileAccountingExportSchema = z.infer<typeof ProfileAccountingExportSchema>;
export type ProfilePasswordSchema = z.infer<typeof ProfilePasswordSchema>;
