import type { PropsWithChildren } from "react";
import type { ModalProps } from "~/components/ui/Modal/Modal";

import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { useForm } from "react-hook-form";

import { useUIContext } from "~/contexts/uiContext";
import { handleApiError } from "~/services/errors";
import { sendAttestationMail } from "~/api/attestation";
import { Button } from "~/components/ui/Button/Button";
import { FormInput } from "~/components/ui/FormInput";
import { FormRow } from "~/components/ui/FormRow";
import { Modal } from "~/components/ui/Modal/Modal";
import { AttestationMailSchema } from "~/schemas/attestation-email.schema";

interface SendAttestationMailModalProps extends ModalProps {
  attestationId: number;
  withForm?: boolean;
}

export const SendAttestationMailModal = ({
  attestationId,
  ...props
}: PropsWithChildren<SendAttestationMailModalProps>) => {
  const { toast } = useUIContext();

  const sendAttestationMailMutation = useMutation({
    mutationFn: sendAttestationMail,
    onSuccess: () => {
      props.onClose();
      toast("L'attestation a été envoyée avec succès.", "success");
    },
    onError(error) {
      handleApiError(error, toast);
    }
  });

  const form = useForm<AttestationMailSchema>({
    resolver: zodResolver(AttestationMailSchema),
    values: {
      email: ""
    }
  });

  const onSubmit = (data: AttestationMailSchema) => {
    sendAttestationMailMutation.mutate({ attestationId, email: data.email });
  };

  return (
    <Modal title="Réglements" {...props}>
      <h2 className="text-xl font-semibold">Envoyer l&apos;attestation par mail</h2>

      <form className="mt-4" onSubmit={form.handleSubmit(onSubmit)}>
        <FormRow className="lg:grid-cols-2">
          <FormInput
            label="Email sur lequel envoyer le document"
            {...form.register("email")}
            className="col-span-2"
          />
        </FormRow>

        <div className="mt-12 flex justify-end gap-4 border-t border-grey-200 pt-6">
          <Button fill="clear" onClick={() => props.onClose()}>
            Annuler
          </Button>
          <Button type="submit" disabled={sendAttestationMailMutation.isLoading}>
            Envoyer
          </Button>
        </div>
      </form>
    </Modal>
  );
};
