import { QueryClient } from "@tanstack/react-query";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      retryDelay: 500, // 0.5 seconds
      // staleTime: 1000 * 60 * 15, // 15 minutes
      // cacheTime: 1000 * 60 * 15, // 15 minutes
      staleTime: 1,
      cacheTime: 1,
      refetchOnMount: false,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false
    }
  }
});
