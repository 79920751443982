import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router";

import { queryKeys } from "~/config/query-keys-constants";
import { useUIContext } from "~/contexts/uiContext";
import { handleApiError } from "~/services/errors";
import { createJob, getJob, updateJob } from "~/api/job";
import { Button } from "~/components/ui/Button/Button";
import { FormInput } from "~/components/ui/FormInput";
import { FormRow } from "~/components/ui/FormRow";
import { FormTextArea } from "~/components/ui/FormTextArea";
import { FormTitle } from "~/components/ui/FormTitle";
import { LoadingSpinner } from "~/components/ui/LoadingSpinner";
import { JobSchema } from "~/schemas/job.schema";

export const CreateEditJob = ({ editMode = false }: { editMode?: boolean }) => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const { toast } = useUIContext();
  const { job_id: jobId } = useParams<{ job_id?: string }>();

  const { data: jobData, isLoading: isJobLoading } = useQuery({
    queryKey: [queryKeys.jobs, { id: jobId }],
    queryFn: () => (jobId ? getJob({ id: parseInt(jobId) }) : null),
    enabled: !!jobId
  });

  const jobMutation = useMutation({
    mutationFn: (data: JobSchema) =>
      editMode && jobId ? updateJob({ id: parseInt(jobId), data }) : createJob({ data }),
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: [queryKeys.jobs] });
      toast(
        editMode ? "Le métier a été modifié avec succès." : "Le métier a été créé avec succès.",
        "success"
      );
      history.push("/administration/jobs");
    },
    onError(error) {
      handleApiError(error, toast);
    }
  });

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<JobSchema>({
    resolver: zodResolver(JobSchema),
    values: {
      name: jobData?.name || "",
      description: jobData?.description || null
    }
  });

  const onSubmit = (data: JobSchema) => {
    jobMutation.mutate(data);
  };

  if (!!jobId && isJobLoading) {
    return <LoadingSpinner size="lg" fullPage />;
  }

  if (!!jobId && !jobData) {
    return <p className="text-center">Le métier à éditer est introuvable.</p>;
  }

  return (
    <>
      <h2 className="mt-6">
        {editMode ? `Édition d'un métier : ${jobData?.name}` : "Création d'un métier"}
      </h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormTitle as="h3">Informations</FormTitle>

        <FormRow>
          <FormInput label="Nom" errorMessage={errors.name?.message} {...register("name")} />
        </FormRow>
        <FormRow>
          <FormTextArea
            label="Description"
            errorMessage={errors.description?.message}
            className="col-span-2"
            rows={4}
            {...register("description")}
          />
        </FormRow>

        <div className="mt-12 flex justify-end gap-4 border-t border-grey-200 pt-6">
          <Button fill="clear" onClick={() => history.goBack()}>
            Annuler
          </Button>
          <Button type="submit" disabled={jobMutation.isLoading}>
            {editMode ? "Modifier le métier" : "Créer le métier"}
          </Button>
        </div>
      </form>
    </>
  );
};
