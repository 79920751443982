import { IonContent, IonPage } from "@ionic/react";
import { useQuery } from "@tanstack/react-query";
import { Redirect, Switch } from "react-router";

import { userRoles } from "~/config/api-constants";
import { queryKeys } from "~/config/query-keys-constants";
import { useAuthContext } from "~/contexts/authContext";
import { getUser } from "~/api/user";
import { ProfileAccountingExport } from "~/pages/Profile/ProfileAccountingExport";
import { ProfileInformations } from "~/pages/Profile/ProfileInformations";
import { ProfilePassword } from "~/pages/Profile/ProfilePassword";
import { ProfileQuotationHeaders } from "~/pages/Profile/ProfileQuotationHeaders";
import { SubNavigation } from "~/components/SubNavigation";
import { LoadingSpinner } from "~/components/ui/LoadingSpinner";
import { ProtectedRoute } from "~/Router";

export const ProfileLayout = () => {
  const { currentUser, isAuth } = useAuthContext();
  const userId = currentUser?.id;

  const { data: userData, isLoading } = useQuery({
    queryKey: [queryKeys.users, userId],
    queryFn: () => getUser({ id: userId || null }),
    enabled: !!userId
  });

  return (
    <IonPage>
      <IonContent className="container">
        <SubNavigation
          links={[
            {
              label: "Informations",
              to: "/profile/informations"
            },
            {
              label: "En-tête relevés",
              to: "/profile/quotation-headers"
            },
            {
              label: "Export comptabilité",
              to: "/profile/accounting-export"
            },
            {
              label: "Mot de passe",
              to: "/profile/password"
            }
          ]}
        />
        <Switch>
          <ProtectedRoute
            exact
            path="/profile/informations"
            condition={(isAuth && currentUser?.roles?.includes(userRoles.ROLE_COOPERATOR)) || false}
          >
            {isLoading ? (
              <LoadingSpinner fullPage />
            ) : userData ? (
              <ProfileInformations userData={userData} />
            ) : null}
          </ProtectedRoute>
          <ProtectedRoute
            exact
            path="/profile/quotation-headers"
            condition={(isAuth && currentUser?.roles?.includes(userRoles.ROLE_COOPERATOR)) || false}
          >
            {isLoading ? (
              <LoadingSpinner fullPage />
            ) : userData ? (
              <ProfileQuotationHeaders userData={userData} />
            ) : null}
          </ProtectedRoute>
          <ProtectedRoute
            exact
            path="/profile/accounting-export"
            condition={(isAuth && currentUser?.roles?.includes(userRoles.ROLE_COOPERATOR)) || false}
          >
            {isLoading ? (
              <LoadingSpinner fullPage />
            ) : userData ? (
              <ProfileAccountingExport userData={userData} />
            ) : null}
          </ProtectedRoute>
          <ProtectedRoute
            exact
            path="/profile/password"
            condition={(isAuth && currentUser?.roles?.includes(userRoles.ROLE_COOPERATOR)) || false}
          >
            {isLoading ? (
              <LoadingSpinner fullPage />
            ) : userData ? (
              <ProfilePassword userData={userData} />
            ) : null}
          </ProtectedRoute>

          <Redirect to={"/profile/informations"} />
        </Switch>
      </IonContent>
    </IonPage>
  );
};
