import type { PropsWithChildren } from "react";

import { IonContent, IonIcon, IonPage } from "@ionic/react";

import backgroundImage from "~/assets/images/background-login.jpg";
import logoIcon from "~/assets/logo/logo-bg.svg";

export const LoginLayout = ({ children }: PropsWithChildren) => {
  return (
    <IonPage>
      <IonContent className="container">
        <img
          src={backgroundImage}
          alt="Background"
          className="absolute inset-0 h-full w-full select-none object-cover brightness-75"
        />
        <div className="relative mx-auto flex min-h-full w-full max-w-md flex-col items-center justify-start gap-8 px-4 py-8 text-white sm:gap-12 md:gap-16">
          <IonIcon src={logoIcon} title="Logo Hexa Coop" className="h-24 w-24" />
          {children}
        </div>
      </IonContent>
    </IonPage>
  );
};
