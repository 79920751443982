import type { ClassValue } from "clsx";

import clsx from "clsx";
import dayjs from "dayjs";
import { twMerge } from "tailwind-merge";
import { z } from "zod";

import { queryKeys } from "~/config/query-keys-constants";

// Properly merge tailwind classes with twMerge and clsx
export const cn = (...classes: ClassValue[]) => twMerge(clsx(...classes));

// Remove all spaces from a string
export const removeSpaces = (string: any): string => {
  if (typeof string !== "string") return string;
  return string.replace(/\s/g, "").trim();
};

// Format a date to a string like "08/12/2023"
export const formatDisplayDate = (date: Date | string) => {
  if (!date) return "";
  return dayjs(date).format("DD/MM/YYYY");
};

// Format a date to an hour like "14:30"
export const formatDisplayHourFromDate = (date: Date | string) => {
  if (!date) return "";
  return dayjs(date).format("HH:mm");
};

// Tranform a datetime (1996-02-27T00:00:00+01:00) into date (1996-02-27)
export const transformDateTimeToDate = (datetime: string | null | undefined) => {
  if (!datetime) return null;
  return datetime.split("T")[0];
};

// Round a number to a certain number of decimals
export const round = (value: number, decimals: number) => {
  return Number(Math.round(Number(value + "e" + decimals)) + "e-" + decimals);
};

// Format a number or string to a money string
export const formatMoney = (value: number | string) => {
  const formatter = new Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "EUR"
  });
  const numberValue = typeof value === "string" ? parseFloat(value) : value;
  if (isNaN(numberValue)) {
    return formatter.format(0);
  }
  return formatter.format(numberValue);
};

export const roundMoney = (value: number | string) => {
  const currencyFormatter = new Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "EUR"
  });

  const numberFormatter = new Intl.NumberFormat("fr-FR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    useGrouping: false
  });

  const numberValue = typeof value === "string" ? parseFloat(value) : value;
  if (isNaN(numberValue)) {
    return currencyFormatter.format(0);
  }

  const formattedValue = numberFormatter.format(numberValue);

  return formattedValue.replace(",", ".").replace(" ", "");
};

// Format a number or string to a percent string
export const formatPercent = (value: number | string) => {
  const numberValue = typeof value === "string" ? parseFloat(value) : value;
  if (isNaN(numberValue)) {
    return "0%";
  }
  return `${numberValue.toFixed(2)}%`;
};

// Convert a nested object to a flat string like "user.informations.firstname"
export const flattenObject = (obj: Record<string, any>, prefix = "") => {
  return Object.keys(obj).reduce(
    (acc, k) => {
      const pre = prefix.length ? prefix + "." : "";
      if (typeof obj[k] === "object") Object.assign(acc, flattenObject(obj[k], pre + k));
      else acc[pre + k] = obj[k];
      return acc;
    },
    {} as Record<string, any>
  );
};

export const invalidateAllQueriesExcept = (queryClient: any, keys: string[]) => {
  queryClient.invalidateQueries({
    predicate(query: any) {
      return keys.indexOf(query.queryKey[0]) === -1;
    }
  });
};

export const invalidateAllQueries = (queryClient: any) => {
  invalidateAllQueriesExcept(queryClient, [queryKeys.users, queryKeys.configs]);
};

const emptyStringToUndefined = z.literal("").transform(() => undefined);

export const optionalField = <T extends z.ZodTypeAny>(schema: T) =>
  schema.optional().or(emptyStringToUndefined);

export const getToastColor = (status: string) => {
  switch (status) {
    case "success":
      return "success";
    case "warning":
      return "warning";
    case "info":
      return "medium";
    case "error":
      return "danger";
    default:
      return "medium";
  }
};

export function zodAlwaysRefine<T extends z.ZodTypeAny>(zodType: T) {
  return z.any().superRefine(async (value, ctx) => {
    const res = await zodType.safeParseAsync(value);

    if (res.success === false)
      for (const issue of res.error.issues) {
        ctx.addIssue(issue);
      }
  }) as unknown as T;
}

export function isZipcodeDomTom(zipcode: string) {
  return /^97[0-8]/.test(zipcode);
}
