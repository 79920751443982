import type { FileDeliverParams, FileDeliverType } from "~/api/file-deliver";

import { useCallback } from "react";
import { useMutation } from "@tanstack/react-query";
import { saveAs } from "file-saver";

import { useUIContext } from "~/contexts/uiContext";
import { handleApiError } from "~/services/errors";
import { getFileDeliver } from "~/api/file-deliver";

export const useFileDeliver = (props?: { isAnonymous?: boolean }) => {
  const { toast } = useUIContext();
  const { mutateAsync, isLoading } = useMutation({
    mutationFn: ({ type, params }: { type: FileDeliverType; params?: FileDeliverParams }) =>
      getFileDeliver({ type, params, isAnonymous: props?.isAnonymous || false }),
    onError(error) {
      handleApiError(error, toast);
    }
  });

  const openFile = useCallback(
    async ({
      itemType,
      itemParams,
      fileType,
      newTab = true
    }: {
      itemType: FileDeliverType;
      itemParams?: FileDeliverParams;
      fileType?: string | null | undefined;
      newTab?: boolean;
    }) => {
      const { file, filename, filetype } = await mutateAsync({
        type: itemType,
        params: itemParams
      });
      if (!file) return;

      const type = fileType || filetype || "application/pdf";

      if (type !== "application/pdf") {
        saveAs(file, filename || "fichier-hexa-coop");
        return;
      }

      const blob = new Blob([file], { type });
      const url = URL.createObjectURL(blob);

      if (newTab) {
        const newWindow = window.open("/", "_blank");
        if (!newWindow) return;
        newWindow.onload = () => {
          newWindow.location = url;
        };
        return;
      }

      window.open(url, "_self");
    },
    [mutateAsync]
  );

  const downloadFile = useCallback(
    async ({
      itemType,
      itemParams,
      fileType,
      fileName
    }: {
      itemType: FileDeliverType;
      itemParams: FileDeliverParams;
      fileType?: string | null | undefined;
      fileName?: string | null | undefined;
    }) => {
      const { file, filename, filetype } = await mutateAsync({
        type: itemType,
        params: itemParams
      });
      if (!file) return;

      const type = fileType || filetype || "application/pdf";

      const blob = new Blob([file], { type });
      saveAs(blob, fileName || filename || "fichier-hexa-coop");
    },
    [mutateAsync]
  );

  return { openFile, downloadFile, isLoading };
};
