import type { ComponentPropsWithoutRef, PropsWithChildren } from "react";

import { cn } from "~/utils/misc";

export const FormTitle = ({
  as = "p",
  className,
  children
}: PropsWithChildren<
  { as: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" } & ComponentPropsWithoutRef<"div">
>) => {
  const Tag = as;
  return <Tag className={cn("mb-0 mt-10 text-base font-semibold", className)}>{children}</Tag>;
};
