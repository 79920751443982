import type { DailyData, MonthlyData } from "~/types/statistics.types";

export const extractMonthlyData = (stats: MonthlyData, yearOffset = 0) => {
  const result: Record<string, number> = {};

  for (const year of Object.keys(stats).sort()) {
    const yearData = stats[year]!;
    for (const month of Object.keys(yearData).sort()) {
      const monthData = yearData[month]!;
      result[`${+year + yearOffset}-${month}`] = monthData;
    }
  }
  return result;
};

export const extractDailyData = (stats: DailyData, yearOffset = 0) => {
  const result: Record<string, number> = {};

  // Data are not sorted, so we must sort by key
  for (const year of Object.keys(stats).sort()) {
    const yearData = stats[year]!;
    for (const month of Object.keys(yearData).sort()) {
      const monthData = yearData[month]!;
      for (const date of Object.keys(monthData).sort()) {
        const dateData = monthData[date]!;
        result[`${+year + yearOffset}-${month}-${date}`] = dateData;
      }
    }
  }
  return result;
};
