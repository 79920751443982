import type { UseFormRegister } from "react-hook-form";

import { FormInput } from "~/components/ui/FormInput";

export const PreFinancingFinancersSearchForm = ({
  register
}: {
  register: UseFormRegister<any>;
}) => {
  return (
    <>
      <FormInput label="Nom du financeur" type="text" optional {...register("lastname")} />
      <FormInput label="Société du financeur" type="text" optional {...register("companyName")} />
      <FormInput
        label="Nom bénéficiaires"
        type="text"
        optional
        {...register("beneficiariesLastname")}
      />
      <FormInput
        label="Nom du commercial"
        type="text"
        optional
        {...register("commercialLastname")}
      />
    </>
  );
};
