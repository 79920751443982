import type {
  MultipleRecoveryQuotationsActionSchema,
  RecoveryQuotationActionSchema
} from "~/schemas/recovery-action.schema";
import type { Quotation } from "~/types/quotation.types";
import type { RecoveryAction } from "~/types/recovery-action.types";
import type { DeepPartial } from "~/types/utils.types";

import { api } from "~/api/_config";

export const getRecoveryActions = async ({
  quotationId,
  page,
  itemsPerPage
}: {
  quotationId?: number;
  page?: number;
  itemsPerPage?: number;
}) => {
  const params = {
    ...(quotationId && { "quotation.id": quotationId }),
    page,
    itemsPerPage
  };
  const response = await api.get<DeepPartial<RecoveryAction[]>>("/api/admin/action_recovery", {
    params
  });
  return response.data;
};

export const createQuotationRecoveryAction = async ({
  quotationId,
  data
}: {
  quotationId: Quotation["id"];
  data: RecoveryQuotationActionSchema;
}) => {
  const response = await api.post<DeepPartial<RecoveryAction>>("/api/admin/action_recovery", {
    quotation: quotationId,
    ...data
  });
  return response.data;
};

export const createMultipleQuotationRecoveryActions = async ({
  quotationIds,
  data
}: {
  quotationIds: Array<Quotation["id"]>;
  data: MultipleRecoveryQuotationsActionSchema & { isSaveAndSend: boolean };
}) => {
  const response = await api.post<DeepPartial<RecoveryAction[]>>(
    "/api/admin/action_recovery/bulk/add",
    {
      quotationIds: quotationIds,
      actionRecoveries: data.actionRecoveries,
      isFinishedTask: data.isFinishedTask,
      isSaveAndSend: data.isSaveAndSend
    }
  );
  return response.data;
};

export const sendQuotationRecoveryActionsToCooperator = async ({
  quotationId
}: {
  quotationId: Quotation["id"];
}) => {
  const response = await api.post<DeepPartial<RecoveryAction[]>>(
    `/api/admin/quotations/${quotationId}/send/action`,
    {}
  );
  return response.data;
};

export const sendMultipleQuotationRecoveryActionsToCooperator = async ({
  quotationIds
}: {
  quotationIds: Array<Quotation["id"]>;
}) => {
  const response = await api.post<DeepPartial<RecoveryAction[]>>(
    `/api/admin/quotations/${quotationIds}/send/action`,
    {}
  );
  return response.data;
};
