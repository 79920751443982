import type { PropsWithChildren } from "react";
import type { ModalProps } from "~/components/ui/Modal/Modal";

import { Button } from "~/components/ui/Button/Button";
import { Modal } from "~/components/ui/Modal/Modal";

interface ConfirmationModalProps extends ModalProps {
  title: string;
  onCancel?: () => void;
  onConfirm: () => void;
  backButtonText?: string;
  confirmButtonText?: string;
  danger?: boolean;
}

export const ConfirmationModal = ({
  isOpen,
  onClose,
  title,
  onCancel,
  onConfirm,
  backButtonText,
  confirmButtonText,
  danger = false,
  children
}: PropsWithChildren<ConfirmationModalProps>) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title} className="text-center">
      {children}
      <div className="mt-8 flex justify-center gap-4">
        <Button onClick={onCancel || onClose} color={danger ? "danger" : "primary"} fill="outline">
          {backButtonText || "Retour"}
        </Button>
        <Button
          onClick={() => {
            onConfirm();
            onClose();
          }}
          color={danger ? "danger" : "primary"}
          fill="solid"
        >
          {confirmButtonText || "Confirmer"}
        </Button>
      </div>
    </Modal>
  );
};
