import { RadioGroup } from "@headlessui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { IonContent, IonIcon, IonPage } from "@ionic/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { cardOutline, pencilOutline, swapHorizontalOutline } from "ionicons/icons";
import { Controller, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router";

import { membershipStates } from "~/config/api-constants";
import { queryKeys } from "~/config/query-keys-constants";
import { cn, formatDisplayDate, formatMoney } from "~/utils/misc";
import { useUIContext } from "~/contexts/uiContext";
import { handleApiError } from "~/services/errors";
import {
  anonymousGetMembership,
  anonymousPayMembershipByCreditCard,
  anonymousSignMembership
} from "~/api/anonymous";
import { HtmlParser } from "~/components/HtmlParser";
import { Alert } from "~/components/ui/Alert";
import { Button } from "~/components/ui/Button/Button";
import { FormCheckbox } from "~/components/ui/FormCheckbox";
import { Link } from "~/components/ui/Link";
import { LoadingSpinner } from "~/components/ui/LoadingSpinner";
import { Table } from "~/components/ui/Table";
import LogoIcon from "~/assets/logo/logo-bg.svg";
import { AnonymousSignFormSchema } from "~/schemas/anonymous.schema";

const PAYMENT_METHODS = [
  { name: "Carte bleue", icon: cardOutline, value: "cb", available: true },
  { name: "Virement", icon: swapHorizontalOutline, value: "virement", available: true },
  { name: "Chèque", icon: pencilOutline, value: "cheque", available: true }
];

export const AnonymousMembershipSignForm = () => {
  const { hash: membershipHash } = useParams<{ hash: string }>();
  const history = useHistory();
  const { toast } = useUIContext();

  const {
    data: membership,
    isFetching: isMembershipFetching,
    isError: isMembershipError
  } = useQuery({
    queryKey: [queryKeys.memberships],
    queryFn: () => anonymousGetMembership({ hash: membershipHash })
  });

  const payByCreditCard = useMutation({
    mutationFn: anonymousPayMembershipByCreditCard,
    onError(error) {
      handleApiError(error, toast);
    }
  });

  const signQuotation = useMutation({
    mutationFn: anonymousSignMembership,
    onSuccess: (_data, variables) => history.push(`/_anon/sign/success/${variables.paymentMethod}`),
    onError(error) {
      handleApiError(error, toast);
    }
  });

  const form = useForm<AnonymousSignFormSchema>({
    resolver: zodResolver(AnonymousSignFormSchema),
    defaultValues: {
      paymentMethod: "cb",
      conditions: true,
      privacy: true
    }
  });

  const onSubmit = async (data: AnonymousSignFormSchema) => {
    if (!membership?.id) return;

    if (data.paymentMethod === "cb") {
      const { url: payboxUrl, parameters: payboxParams } = await payByCreditCard.mutateAsync({
        id: membership.id
      });
      const htmlPaybox = await axios.post(payboxUrl, payboxParams, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      });
      const payboxForm = document.createElement("html");
      payboxForm.innerHTML = htmlPaybox.data;
      document.body.appendChild(payboxForm);
      payboxForm.querySelector("form")?.submit();
    } else if (data.paymentMethod === "virement" || data.paymentMethod === "cheque") {
      signQuotation.mutate({
        hash: membershipHash,
        paymentMethod: data.paymentMethod
      });
    } else {
      toast("Moyen de paiement inconnu", "error");
    }
  };

  return (
    <IonPage>
      <IonContent>
        <div className="flex min-h-full w-full items-center justify-center bg-grey-100 p-4 sm:p-8">
          <div className="mx-auto max-w-6xl rounded-lg border border-grey-100 bg-white px-4 py-4 shadow-md sm:px-8">
            {isMembershipFetching ? (
              <LoadingSpinner size="lg" fullPage />
            ) : !membership || isMembershipError ? (
              <Alert
                type="error"
                title="Erreur"
                text={
                  <>
                    Une erreur est survenue lors de la récupération de votre contrat
                    d&apos;adhésion, merci de réessayer ultérieurement.
                    <br />
                    Si le problème persiste, merci de vous rapprocher de votre prestataire pour plus
                    de détails.
                  </>
                }
              />
            ) : membership.state === membershipStates.STATE_CANCEL ? (
              <Alert
                type="error"
                title="Erreur"
                text={
                  <>
                    Ce contrat d&apos;adhésion a été annulé ou supprimé.
                    <br />
                    Merci de vous rapprocher de votre prestataire pour plus de détails.
                  </>
                }
              />
            ) : membership.signedAt ? (
              <Alert
                type="error"
                title="Erreur"
                text={
                  <>
                    Ce contrat d&apos;adhésion a déjà été signé le{" "}
                    {formatDisplayDate(membership.signedAt)}.
                    <br />
                    Merci de vous rapprocher de votre prestataire pour plus de détails.
                  </>
                }
              />
            ) : membership.state !== membershipStates.STATE_SEND ? (
              <Alert
                type="error"
                title="Erreur"
                text={
                  <>
                    Ce contrat d&apos;adhésion ne peut pas être signé car il n&apos;a pas le statut
                    &quot;envoyé&quot;.
                    <br />
                    Merci de vous rapprocher de votre prestataire pour plus de détails.
                  </>
                }
              />
            ) : (
              <>
                <h1 className="m-0 border-b border-b-grey-100 pb-4 text-center text-lg font-semibold uppercase">
                  Votre contrat d&apos;adhésion n°{membership.id}
                </h1>
                <div className="mt-4 grid grid-cols-1 gap-8 sm:grid-cols-12">
                  <div className="text-start sm:col-span-6 md:col-span-5">
                    <h2 className="mb-2 text-lg">Informations Hexa Coop</h2>
                    <address className="text-xs not-italic text-grey-600 sm:text-sm">
                      SAS HEXA-COOP
                      <br />
                      17 rue de Sarliève
                      <br />
                      63800 COURNON D&apos;AUVERGNE
                      <br />
                      SIRET: 834 225 096 00017
                      <br />
                      N°TVA: FR 28 834225096
                    </address>
                  </div>

                  <div className="order-first sm:col-span-12 md:order-none md:col-span-2">
                    <IonIcon
                      src={LogoIcon}
                      title="Logo Hexa Coop"
                      className="mx-auto block h-20 w-20 sm:h-24 sm:w-24 md:h-28 md:w-28"
                    />
                  </div>

                  <div className="ml-auto text-end sm:col-span-6 md:col-span-5">
                    <h2 className="mb-2 text-lg">Vos informations</h2>
                    <address className="text-xs not-italic text-grey-600 sm:text-sm">
                      {membership.cooperator.header1 || membership.cooperator.companyName || null}
                      <br />
                      {membership.cooperator.header2 ||
                        `${membership.cooperator.lastname} ${membership.cooperator.firstname}` ||
                        null}
                      <br />
                      {membership.cooperator.header3 || membership.cooperator.address || null}
                      <br />
                      {membership.cooperator.header4 ||
                        `${membership.cooperator.zipcode} ${membership.cooperator.city}` ||
                        null}
                      <br />
                      {membership.cooperator.header5 || membership.cooperator.email || null}
                      <br />
                      {membership.cooperator.header6 ? (
                        <>
                          {membership.cooperator.header6}
                          <br />
                        </>
                      ) : null}
                      {membership.cooperator.siret ? (
                        <>
                          SIRET: {membership.cooperator.siret}
                          <br />
                        </>
                      ) : null}
                      {membership.cooperator.tvaNumber ? (
                        <>
                          N° TVA: {membership.cooperator.tvaNumber}
                          <br />
                        </>
                      ) : null}
                    </address>
                  </div>
                </div>
                <Table
                  className="mt-8"
                  data={{
                    headers: [
                      "Nature de la prestation",
                      "Total HT",
                      ...(membership.discount && parseFloat(membership.discount) > 0
                        ? ["Remise", "Total HT Remisé"]
                        : []),
                      "TVA 20%",
                      "Total TTC"
                    ],
                    rows: [
                      {
                        key: "membership",
                        columns: [
                          `Adhésion du ${formatDisplayDate(
                            membership.startAt
                          )} au ${formatDisplayDate(membership.endAt)}`,
                          formatMoney(membership.amountMembership),
                          ...(membership.discount && parseFloat(membership.discount) > 0
                            ? [
                                formatMoney(membership.discount),
                                formatMoney(
                                  parseFloat(membership.amountMembership) -
                                    parseFloat(membership.discount)
                                )
                              ]
                            : []),
                          formatMoney(membership.amountTvaMembership),
                          formatMoney(
                            membership.totalTTC -
                              (membership.hasSocialPart
                                ? parseFloat(membership.amountSocialPart)
                                : 0)
                          )
                        ]
                      },
                      ...(membership.hasSocialPart
                        ? [
                            {
                              key: "socialPart",
                              columns: [
                                "Part sociale",
                                formatMoney(membership.amountSocialPart),
                                ...(membership.discount && parseFloat(membership.discount) > 0
                                  ? [formatMoney(0), formatMoney(membership.amountSocialPart)]
                                  : []),
                                formatMoney(0),
                                formatMoney(membership.amountSocialPart)
                              ]
                            }
                          ]
                        : []),
                      {
                        key: "total",
                        columns: [
                          "",
                          "",
                          ...(membership.discount && parseFloat(membership.discount) > 0
                            ? ["", ""]
                            : []),
                          "Total TTC",
                          formatMoney(membership.totalTTC)
                        ]
                      }
                    ]
                  }}
                />
                {membership.comments ? (
                  <div className="mt-8">
                    <h2 className="mb-4 text-lg">Commentaires :</h2>
                    <HtmlParser className="text-sm text-grey-700" html={membership.comments} />
                  </div>
                ) : null}
                <div className="mt-12">
                  <h2 className="mb-4 text-xl">Comment souhaitez-vous régler ?</h2>
                  <form onSubmit={form.handleSubmit(onSubmit)}>
                    <Controller
                      name="paymentMethod"
                      control={form.control}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <RadioGroup
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          ref={ref}
                          className="mt-2"
                        >
                          <RadioGroup.Label className="sr-only">
                            Sélectionnez un moyen de paiement
                          </RadioGroup.Label>
                          <div className="mx-auto grid grid-cols-4 gap-4 sm:grid-cols-3">
                            {PAYMENT_METHODS.map((option) => (
                              <RadioGroup.Option
                                key={option.value}
                                value={option.value}
                                disabled={!option.available}
                                className={({ checked }) =>
                                  cn(
                                    "flex flex-col items-center justify-center gap-2 rounded-md p-2 text-xs font-medium sm:p-3 sm:text-sm md:p-4",
                                    "col-span-2 first:col-span-4 sm:col-span-1 sm:first:col-span-1",
                                    option.available
                                      ? "cursor-pointer focus:outline-none"
                                      : "cursor-not-allowed opacity-25",
                                    checked
                                      ? "bg-primary text-white hover:bg-primary-500"
                                      : "bg-white text-primary ring-1 ring-inset ring-primary hover:bg-grey-50"
                                  )
                                }
                              >
                                <IonIcon icon={option.icon} className="h-6 w-6" />
                                <RadioGroup.Label className="cursor-pointer text-center">
                                  {option.name}
                                </RadioGroup.Label>
                              </RadioGroup.Option>
                            ))}
                          </div>
                        </RadioGroup>
                      )}
                    />
                    {form.formState.errors.paymentMethod?.message ? (
                      <p className="mx-auto mt-2 whitespace-pre-line text-center text-sm text-danger-700">
                        {form.formState.errors.paymentMethod.message}
                      </p>
                    ) : null}

                    <FormCheckbox
                      label={
                        <>
                          Je reconnais avoir été préalablement informé des modalités contractuelles
                          et avoir pris connaissances des{" "}
                          <Link
                            to="/terms-and-conditions/membership"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            conditions générales
                          </Link>{" "}
                          d&apos;HEXA COOP.
                        </>
                      }
                      className="mt-8"
                      errorMessage={form.formState.errors.conditions?.message}
                      {...form.register("conditions")}
                    />
                    <FormCheckbox
                      label={
                        <>
                          J&apos;ai lu, j&apos;accepte et je me conforme à la{" "}
                          <Link
                            to="/personal-data-security-policy"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            politique de sécurité des données personnelles HEXA COOP.
                          </Link>
                        </>
                      }
                      className="mt-4"
                      errorMessage={form.formState.errors.privacy?.message}
                      {...form.register("privacy")}
                    />

                    <Button type="submit" size="large" className="mx-auto mt-8 block w-fit">
                      Valider et régler
                    </Button>
                  </form>
                </div>
              </>
            )}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
