import type { ComponentPropsWithoutRef, ReactNode } from "react";

import { IonIcon } from "@ionic/react";
import {
  alertCircleOutline,
  checkmarkCircleOutline,
  informationCircleOutline,
  warningOutline
} from "ionicons/icons";

import { cn } from "~/utils/misc";

type AlertProps = {
  type?: "info" | "warning" | "error" | "success";
  list?: string[];
  title?: string;
  text?: ReactNode;
  iconClassName?: string;
} & ComponentPropsWithoutRef<"div">;

export const Alert = ({
  type = "info",
  text,
  title,
  list,
  className,
  iconClassName
}: AlertProps) => {
  const icons = {
    info: informationCircleOutline,
    warning: warningOutline,
    error: alertCircleOutline,
    success: checkmarkCircleOutline
  } as const;

  return (
    <div className={cn("rounded-lg bg-grey-100 p-4", className)}>
      <div className={cn("mb-3 flex items-center gap-4", !title && "justify-center")}>
        {icons[type] ? (
          <IonIcon
            className={cn(
              "h-6 w-6 shrink-0 rounded-lg",
              {
                "text-blue-600": type === "info",
                "text-warning-600": type === "warning",
                "text-danger-600": type === "error",
                "text-success-600": type === "success"
              },
              iconClassName
            )}
            icon={icons[type]}
          />
        ) : null}
        {title ? <div className="text-base font-semibold">{title}</div> : null}
      </div>
      <div>
        {list ? (
          <ul className="list-disc pl-6">
            {list.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
        ) : (
          <div className="text-sm leading-normal">{text}</div>
        )}
      </div>
    </div>
  );
};
