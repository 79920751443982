import { IonContent, IonPage } from "@ionic/react";

export const MembershipGeneralConditions = () => {
  return (
    <IonPage>
      <IonContent className="container">
        <div className="mx-auto max-w-5xl [&_h2]:mb-6 [&_h2]:text-2xl [&_h2]:font-medium [&_h2]:text-grey-700 [&_h3]:mb-4 [&_h3]:mt-6 [&_h3]:text-base [&_h3]:font-bold [&_h4]:mb-4 [&_h4]:mt-6 [&_h4]:text-base [&_h4]:font-bold [&_section]:mt-6 [&_section]:text-justify [&_section]:text-sm [&_section]:font-medium [&_section]:text-grey-700">
          <h1 className="text-center text-lg font-bold uppercase text-grey-700">
            Conditions générales
          </h1>

          <section>
            <h2>CHARTE DÉONTOLOGIQUE</h2>
            <h3>EXPOSÉ PRÉALABLE</h3>
            <p>
              HEXA COOP est une société Coopérative constituée sous la forme de société par actions
              simplifiée à capital variable. Elle a pour objet de regrouper sous une seule et même
              entité des Coopérateurs affiliés réalisant au nom et pour son compte des prestations
              de services à la personne à domicile.{" "}
            </p>
            <p>
              HEXA COOP est immatriculée au Registre du Commerce et des Sociétés de CLERMONT-FERRAND
              sous le numéro 834 225 096 et remplit l’intégralité des conditions posées par la Loi
              n° 2005-841 du 26 juillet 2005 relative au développement des services à la personne et
              portant diverses mesures en faveur de la cohésion sociale et notamment l’obligation de
              déclaration d’activité.
            </p>
            <p>
              Dans le cadre de son activité, HEXA COOP a développé une formule de commercialisation
              de prestation de services à la personne innovante et performante se traduisant par la
              réalisation, au bénéfice de clients particuliers ou professionnels, de prestations de
              services entrant dans le champs des « activités de services à la personne » telles
              qu’elles sont prévues par les articles L. 7231-1 et D. 7231-1 du Code du travail.
            </p>
            <p>
              EN CONSEQUENCE, HEXA COOP s’appuie sur l’affiliation de Coopérateurs de confiance
              remplissant des conditions de compétences et partageant les mêmes valeurs éthiques et
              déontologiques, telles que définies par les présentes.
            </p>
            <h3> PARTIE I – OBJET ET CHAMP D’APPLICATION</h3>
            <h4>ARTICLE 1 – OBJET DE LA CHARTE</h4>
            <p>
              Dès lors que HEXA COOP est une société Coopérative contribuant à fédérer des
              Coopérateurs indépendants désireux de participer, par leurs compétences respectives,
              au développement d’une structure Coopérative., la présente Charte a pour objectif de
              définir : les modalités d’affiliation des Coopérateurs, les conditions d’admissibilité
              et les règles d’exécution.
            </p>
            <p>La présente charte a valeur de règlement intérieur.</p>
            <p>
              Pour cela, HEXA COOP et ses Coopérateurs désirent adhérer, par la présente Charte
              déontologique, aux dispositions qu’ils jugent les mieux appropriées à la pérennité de
              leur association, et notamment par exemple :
            </p>
            <p>
              - organiser les modalités de leur association,
              <br />
              - assurer le respect, par les Coopérateurs, des dispositions légales et réglementaires
              en matière de services à la personne,
              <br />
              - éviter toute action de concurrence directe ou indirecte,
              <br />
              - organiser les modalités de fonctionnement de leur association,
              <br />- aménager le régime de responsabilité de leur association.
            </p>
            <p>
              Le respect de la présente Charte constitue un élément indispensable de la relation
              entre HEXA COOP et ses Coopérateurs.{" "}
            </p>
            <h4>ARTICLE 2 – DÉFINITIONS</h4>
            <p>
              Chaque fois qu’ils sont utilisés dans la présente Charte ou en relation avec son
              exécution, les termes suivants entre guillemets ont le sens défini ci-après :
            </p>
            <p>
              « Bénéficiaire » : Particulier ou professionnel bénéficiant de services à la personne
              ouvrant droit à crédit d’impôt.
            </p>
            <p>
              « Charte » : La présente convention à laquelle les Coopérateurs acceptent d’adhérer et
              s’engagent à respecter et telle qu’elle sera, le cas échéant modifiée ou complétée
              ultérieurement ;
            </p>
            <p>
              « Client » : Particulier ou professionnel recourant aux services proposés par le
              Coopérateur ;
            </p>
            <p>
              « Coopérateur » : Toute personne ayant adhéré à la « Coopérative », suite à l’agrément
              de celle-ci et à la souscription d’une part sociale au capital de cette dernière ;
            </p>
            <p>
              « Coopérative » : La Société HEXA COOP : Société par actions simplifiée à capital
              variable dont le siège social est fixé à COURNON D’AUVERGNE (63800) – 27, Route du
              Cendre, immatriculée au Registre du Commerce et des Sociétés de CLERMONT-FERRAND sous
              le numéro 834 225 096 ;
            </p>
            <p>
              « Service » : Prestation de services à la personne assurée par le Coopérateur dans le
              cadre de son association avec la Coopérative, dans les conditions prévues par la Loi ;
            </p>
            <h3>PARTIE II – ACTIVITÉS ET ADHÉSION</h3>
            <h4>ARTICLE 3 – ACTIVITÉS DE LA COOPÉRATIVE</h4>
            <p>
              La Coopérative a pour objectif de regrouper en son sein des professionnels
              indépendants dont les activités habituelles relèvent, à titre principal ou accessoire,
              des services à la personne tels qu’ils sont définis par les articles L. 7231-1 et D.
              7231-1 du Code du travail.
            </p>
            <p>
              Ainsi, entre dans le champ d’activité de la Coopérative, divers services à la
              personne, parmi lesquels :
            </p>
            <p>
              - Entretien de la maison,
              <br />
              - Petit travaux de jardinage,
              <br />
              - Travaux de petit bricolage,
              <br />
              - Garde d’enfants de plus de 3 ans à domicile,
              <br />
              - Soutien scolaire ou cours à domicile,
              <br />
              - Soins esthétiques à domicile pour les personnes dépendantes,
              <br />
              - Préparation de repas à domicile (y compris temps passé aux courses),
              <br />
              - Livraison de repas à domicile,
              <br />
              - Livraison de courses à domicile,
              <br />
              - Collecte et livraison à domicile de linge repassé,
              <br />
              - Assistance informatique à domicile,
              <br />
              - Soins et promenades d’animaux de compagnie pour les personnes dépendantes,
              <br />
              - Maintenance, entretien et vigilance temporaires à domicile,
              <br />
              - Assistance administrative à domicile,
              <br />
              - Accompagnement des enfants de plus de 3 ans dans leurs déplacements,
              <br />
              - Télé-assistance et visio-assistance,
              <br />
              - Interprète en langue des signes, technicien de l’écrit et codeur en langage parlé
              complété,
              <br />
              - Prestation du véhicule de conduite personnel des personnes qui présente une
              invalidité temporaire,
              <br />
              - Accompagnement des personnes qui présentent une invalidité temporaire en dehors de
              leur domicile,
              <br />
              - Assistance aux personnes qui ont besoin temporairement d’une aide personnelle à leur
              domicile, à l’exclusion des soins relevant d’actes médicaux,
              <br />- Coordination et délivrance des services.
            </p>
            <p>La présente liste n’est pas exhaustive.</p>
            <p>
              Afin de n’offrir à ses clients que des prestations éligibles aux différents crédits
              d’impôts prévus par le Code général des impôts (C.G.I.), la Coopérative et ses
              Coopérateurs s’engagent à n’effectuer lesdites prestations qu’au domicile (résidence
              principale) du client. Aussi, cette résidence doit impérativement être située sur le
              territoire français.{" "}
            </p>
            <p>
              La législation admet un assouplissement avec la possibilité d’effectuer les
              prestations de services à la personne à la résidence secondaire du bénéficiaire ou
              encore à la résidence d’un de ses ascendants (parents, grands-parents) à la condition
              que ce dernier perçoive l’allocation personnalisée d’autonomie (Apa).
            </p>
            <h4>ARTICLE 4 – POLITIQUE D’ADHÉSION</h4>
            <p>- Souscription au capital social </p>
            <p>
              Tout candidat à la coopération devra, en vu de son affiliation à la Coopérative,
              procéder à la souscription d’au moins une part au capital social de cette dernière.
              Cette souscription constitue un préalable essentiel à toute relation entre la
              Coopérative et le Coopérateur et s’inscrit dans l’objectif coopératif poursuivi par
              HEXA COOP.{" "}
            </p>
            <p>
              L’adhésion à la Coopérative, par la souscription d’une part sociale, permet au
              Coopérateur de participer aux assemblées organisées par HEXA COOP (les éventuels frais
              de déplacement restent à la charge du Coopérateur).
            </p>
            <p>
              La Coopérative s’engage à délivrer au candidat Coopérateur remplissant les conditions
              d’adhésion une part sociale moyennant le règlement d’une somme de DIX EUROS (10,00 €).
            </p>
            <p>
              Toute adhésion emporte engagement de respecter la présente Charte, les statuts ainsi
              que tous les autres textes que la Coopérative serait amenée à adopter postérieurement.{" "}
            </p>
            <p>
              L’adhésion demeure soumise à l’agrément des associés de la Coopérative qui se réserve
              le droit de débouter tout candidat ne respectant pas les conditions légales,
              réglementaires, éthiques ou déontologiques poursuivies par la Coopérative.{" "}
            </p>
            <p>- Frais d’adhésion</p>
            <p>
              Outre l’acquisition d’une part sociale, l’adhésion est soumise au règlement, au jour
              de l’adhésion puis chaque année à la date d’anniversaire, d’une somme forfaitaire dont
              le montant est indiqué sur le bordereau d’adhésion. Le Coopérateur s’engage à régler
              ladite somme dans les plus brefs délais et au maximum un mois après l’adhésion ou la
              date d’anniversaire de cette dernière les années suivantes.{" "}
            </p>
            <p>
              A défaut de règlement, la Coopérative procédera à une mise en demeure et pourra
              résilier le contrat d’adhésion en l’absence de règlement dans le mois suivant cette
              dernière.{" "}
            </p>
            <h3>PARTIE III – SERVICES</h3>
            <h4>ARTICLE 5 – SERVICES ASSURÉS PAR LE COOPÉRATEUR</h4>
            <p>
              Le Coopérateur assure la réalisation de prestations de services relevant des
              définitions légales des services à la personne de la Loi Borloo.
            </p>
            <p>
              Il assure la réalisation de ces prestations auprès de clients particuliers ou
              professionnels en fonction de ses compétences.{" "}
            </p>
            <p>
              Auprès de la clientèle et en toute situation, le Coopérateur se présente en tant
              qu’affilié de la Coopérative et doit veiller à ce qu’aucune ambiguïté ou doute ne
              puisse subsister quant à son affiliation à la Coopérative.{" "}
            </p>
            <p>
              Le Coopérateur s’engage à respecter les modalités d’intervention fixées par la
              Coopérative notamment en matière de nature des prestations effectuées, délais et
              qualité d’intervention ainsi qu’à user de toutes les diligences nécessaires dans ses
              échanges avec les clients.{" "}
            </p>
            <p>
              Après la réalisation des prestations visées par la présente Charte, le Coopérateur
              procède, via l’espace extranet mis à sa disposition par la Coopérative, à la saisie
              d’un relevé de prestations sur lequel il lui appartient de renseigner l’intégralité
              des renseignements demandés afin que soit traitée sa demande.
            </p>
            <p>
              Toute commande de services du Coopérateur est préalablement validée en ligne par le
              bénéficiaire des services, lequel devra confirmer en conséquence la nature effective
              des services sollicités.
            </p>
            <p>
              Le Coopérateur doit conserver l’intégralité des documents attestant de l’exécution de
              ses prestations et les fournit à première demande de la Coopérative.{" "}
            </p>
            <h4>ARTICLE 6 – SERVICES ASSURÉS PAR LA COOPÉRATIVE</h4>
            <p>
              La Coopérative s’engage à mettre à la disposition du Coopérateur l’intégralité des
              informations nécessaires à l’exécution de sa mission. Ainsi, la Coopérative s’engage à
              attribuer, à chaque collaborateur, un espace extranet personnel et dédié à la saisie
              du relevé des prestations effectuées au bénéfice des clients.{" "}
            </p>
            <p>
              De même, la Coopérative tient le Coopérateur continuellement informé des évolutions
              légales et réglementaires et des éventuels aménagements apportés aux modalités de leur
              association.{" "}
            </p>
            <p>
              La Coopérative n’a pas vocation à assurer un rôle d’apporteur d’affaires au
              Coopérateur qui fait son affaire du démarchage de la clientèle.{" "}
            </p>
            <p>
              La Coopérative met à la disposition du Coopérateur l’intégralité des outils permettant
              à ce dernier d’afficher son affiliation auprès de la Coopérative.{" "}
            </p>
            <p>
              La Coopérative s’engage à établir et à porter à la connaissance du Coopérateur les
              modalités d’intervention qu’elle entend exiger de ses affiliés notamment en matière de
              délais d’intervention et qualité d’intervention.{" "}
            </p>
            <p>
              Plus généralement la Coopérative s’engage à remettre spontanément et en temps utile,
              ainsi qu’à première demande, au Coopérateur, l’ensemble des informations et documents
              qui lui sont nécessaires pour exécuter des prestations et à le tenir informé de tous
              éléments de quelque nature qu’ils soient, indispensables à la bonne exécution de sa
              mission.
            </p>
            <h4>ARTICLE 7 - RESPONSABILITÉ</h4>
            <p>
              La Coopérative n’assume aucune responsabilité quant aux prestations exécutées par le
              Coopérateur. Sa responsabilité ne peut être recherchée par le Coopérateur qui demeure
              seul responsable des actes commis dans le cadre de ses prestations.{" "}
            </p>
            <p>
              Par ailleurs, la Coopérative décline toute responsabilité dans les cas où
              l’administration fiscale viendrait à refuser ou remettre en cause les avantages
              fiscaux attachés à l’exécution de prestations de services à la personne.{" "}
            </p>
            <p>
              Le Coopérateur s’engage donc personnellement à effectuer ses prestations dans le
              respect des prescriptions légales et réglementaires. De même, le Coopérateur, qui
              n’est pas un préposé de la Coopérative, assume la responsabilité de tout préjudice
              causé, de son fait, aux clients ou à ses biens.{" "}
            </p>
            <p>
              Ainsi, il doit faire son affaire de la souscription de polices d’assurance couvrant
              son activité et s’engage à les maintenir aussi longtemps qu’il sera affilié à la
              Coopérative ainsi qu’à en justifier auprès de cette dernière.
            </p>
            <h3>PARTIE IV – FACTURATION ET CREDIT D’IMPOT</h3>
            <h4>
              ARTICLE 8 – NATURE DU MANDAT DE FACTURATION – RÉMUNÉRATION DES SERVICES DE LA
              COOPÉRATIVE
            </h4>
            <p>
              Le Coopérateur, mandant, donne expressément pouvoir à la Coopérative, mandataire,
              d’émettre des factures (affectées du taux de T.V.A. applicable auxdites prestations)
              au titre des prestations qu’il a effectué et de recouvrer les sommes objet desdites
              factures auprès des clients.{" "}
            </p>
            <p>
              Le Coopérateur émettra ensuite une facture adressée à la Coopérative et relative aux
              prestations effectuées.{" "}
            </p>
            <p>
              Une fois recouvrées auprès des clients, la Coopérative reversera au Coopérateur
              lesdites sommes (affectées le cas échéant de la T.V.A. au taux applicable) et
              déduction faite d’un forfait de 10 % de frais administratifs calculé sur le chiffre
              d’affaires toutes taxes comprises (T.T.C.).{" "}
            </p>
            <p>
              La Coopérative devra établir les factures et retranscrire avec exactitude les
              informations saisies par le Coopérateur sur son espace extranet. Les factures devront
              être adressées aux clients dans un délai maximum de QUINZE (15) jours à compter de la
              validation par le client du relevé de prestations saisi par le Coopérateur.
            </p>
            <p>
              De convention expresse, il est convenu que la Coopérative n’est tenue d’aucune
              obligation de résultat quant aux règlements et aux relances du client. A ce titre, la
              Coopérative ne reversera au Coopérateur que les sommes qu’elle aura effectivement
              recouvrées auprès du client.{" "}
            </p>
            <h4>ARTICLE 9 – MODALITÉS DE FACTURATION</h4>
            <p>
              Ledit mandat est conclu « intuitu personae », et ne peut être, à ce titre, ni cédé, ni
              transmis sous peine d’être automatiquement résilié de plein droit et sans délai.
            </p>
            <h4>ARTICLE 10 – DURÉE DU MANDAT</h4>
            <p>
              Le présent mandat de facturation est établi pour une durée illimitée à compter de la
              date figurant au terme du bordereau d’adhésion et demeurera valable aussi longtemps
              que le Coopérateur sera affilié à la Coopérative.
            </p>
            <p>
              En cas de manquement, de l’une partie, à ses obligations, l’autre partie pourra mettre
              fin audit mandat par lettre recommandée avec accusé de réception envoyée UN (1) mois
              après mise en demeure.
            </p>
            <h4>ARTICLE 11 – AVANTAGES FISCAUX </h4>
            <p>
              Conformément aux articles 199 sexdecies et 244 quater F du Code général des impôts
              (C.G.I.), les prestations effectuées par le Coopérateur dans le cadre de son adhésion
              à la Coopérative et entrant dans le champ des articles L. 7231-1 et D. 7231-1 du Code
              du travail ouvrent droit à crédits d’impôts.{" "}
            </p>
            <p>
              Lorsque les prestations sont effectuées au bénéfice d’un particulier, le crédit
              d’impôt sur le revenu bénéficiant à ce dernier est égal à 50 % des dépenses engagées
              pour des prestations de services à la personne dans la limite de 12.000 € par an. Des
              majorations du plafond annuel de dépenses (jusqu’à 20.000 € maximum) peuvent
              intervenir en fonction du nombre d’enfants à charge, de la présence d’enfants
              handicapés, d’ascendants vivant au domicile du déclarant…).
            </p>
            <p>
              Ce plafond est applicable pour toutes les activités de services à la personne, sauf
              pour :
            </p>
            <p>
              - Le petit jardinage à domicile : plafond limité à 5.000 € ;<br />
              - L’assistance informatique et internet : plafond limité à 3.000 € ;<br />- Le petit
              bricolage : plafond limité à 500 € (une intervention ne peut dépasser 2 heures).
            </p>
            <p>
              Lorsque les prestations sont sollicitées par un professionnel, le crédit d’impôt sur
              les bénéfices est égal à 25 % desdites dépenses. Ce crédit est calculé en prenant en
              compte les dépenses effectivement supportées par l’entreprise au cours de l’année
              civile (peu importe la date de clôture de l’exercice).{" "}
            </p>
            <p>
              ATTENTION : L’octroi du crédit d’impôt est subordonné au respect, par le
              professionnel, des conditions fixées par la loi et l’URSSAF.
            </p>
            <p>
              Ce crédit d’impôt sur les bénéfices d’un maximum de 500.000 € par exercice prend en
              compte les dépenses effectuées par l’entreprise (à concurrence de 2 millions €). Ce
              crédit est imputé sur l’impôt sur le revenu (IR) ou sur l’impôt sur les sociétés (IS)
              dû par l’entreprise au titre de l’année au cours de laquelle les dépenses ont été
              engagées.{" "}
            </p>
            <p>
              Les sommes supportées par l’entreprise pour des prestations au profit de ses salariés
              ou dirigeants sociaux n’ont pas le caractère de rémunération (article L. 7233-4 et D.
              7233-8 du Code du travail) et sont à ce titre exonérées d’impôt sur le revenu (IR)
              dans la limite de 1.830 € par an et par bénéficiaire (article 81 du Code général des
              impôts).{" "}
            </p>
            <p>
              Ainsi, la Coopérative assure l’établissement et l’envoi aux clients bénéficiaires,
              particuliers et professionnels, de l’attestation fiscale permettant à ces derniers de
              faire valoir leur droit à crédit d’impôt auprès de l’administration fiscale.{" "}
            </p>
            <h3>PARTIE V – DÉONTOLOGIE ET CONFIDENTIALITÉ</h3>
            <h4>ARTICLE 12 – CONFIDENTIALITÉ</h4>
            <p>
              Les Coopérateurs s’interdisent de divulguer toute information technique, commerciale,
              financière ou concernant la Coopérative ou les clients et dont ils auraient pu avoir
              connaissance dans le cadre leur association avec la Coopérative.
            </p>
            <p>
              Cette obligation de confidentialité est stipulée sans limitation de durée. Elle ne
              s’éteindra le cas échéant, que lorsque les informations concernées seront tombées dans
              le domaine public.
            </p>
            <p>
              La Coopérative s’engage à mettre en œuvre l’intégralité des moyens et outils
              opérationnels indispensables à la confidentialité et la protection des données qu’elle
              collectera dans le cadre de son activité.{" "}
            </p>
            <h4>ARTICLE 13 – LOYAUTÉ ET BONNE FOI</h4>
            <p>
              La Coopérative et les Coopérateurs prennent l’engagement d’exercer leurs missions dans
              le respect des dispositions légales et règlementaires ainsi que d’agir avec loyauté et
              bonne foi.{" "}
            </p>
            <p>
              Les Coopérateurs s’engagent à n’effectuer auprès de la clientèle que des prestations
              relevant du domaine des services à la personne et étant éligibles aux dispositifs
              fiscaux des articles 199 sexdecies et 244 quater F du Code général des impôts
              (C.G.I.).
            </p>
            <p>
              Ainsi, les Coopérateurs s’interdisent d’user de leur adhésion auprès de la Coopérative
              à des fins étrangères à celles prévues par la présente Charte et notamment à assurer,
              auprès des clients, des prestations qui ne seraient pas éligibles auxdits dispositifs
              de réduction d’impôts.{" "}
            </p>
            <p>
              Les Coopérateurs, s’interdisent d’accomplir tout acte pouvant être qualifié de
              concurrence déloyale ou ayant pour conséquence de porter atteinte à l’image ou à la
              réputation de la Coopérative.
            </p>
            <h4>ARTICLE 14 – NON-DÉBAUCHAGE ENTRE COOPÉRATEURS</h4>
            <p>
              Chacun des coopérateurs s’engage à ne pas débaucher le personnel d’un autre
              coopérateur ayant participé, directement ou indirectement, à la réalisation des
              prestations prévues par les présentes, pendant toute la durée des présentes et pendant
              une (1) année à compter de la cessation de l’adhésion à HEXA COOP.
              <br />
              Tout manquement à cette obligation expose la partie défaillante à payer immédiatement
              à l’autre partie, à titre de clause pénale, une indemnité égale à douze (12) fois le
              dernier salaire brut mensuel de la personne concernée.
            </p>
            <h3>PARTIE VI – DISPOSITIONS GÉNÉRALES</h3>
            <h4>ARTICLE 15 – NULLITÉ PARTIELLE</h4>
            <p>
              De convention expresse entre la Coopérative et les Coopérateurs, l’annulation de l’une
              ou l’autre des clauses de la présente Charte ne pourra entraîner l’annulation de
              celle-ci dans son ensemble, à condition toutefois que l’équilibre et l’économie
              générale puissent en être sauvegardés.
            </p>
            <p>
              En tout état de cause, la Coopérative et les Coopérateurs s’engagent en cas
              d’annulation ou d’illicéité d’une clause, à négocier de bonne foi, la conclusion d’une
              clause de remplacement, économiquement et juridiquement équivalente, dans la mesure du
              possible, à la clause frappée de nullité ou d’illicéité.
            </p>
            <h4>ARTICLE 16 - RÈGLEMENT DES LITIGES</h4>
            <p>
              Toutes contestations qui s’élèveraient entre la Coopérative et les Coopérateurs
              relativement à l’interprétation et/ou à l’exécution des présentes seront soumises au
              Tribunal de commerce de CLERMONT-FERRAND.
            </p>
            <h4>ARTICLE 17 – AUDIT </h4>
            <p>
              La Coopérative pourra procéder à tout contrôle ou audit auprès des Coopérateurs
              affiliés afin de contrôler le respect des dispositions légales et réglementaires,
              d’une part et, d’autre part, des dispositions prévues à la présente Charte.{" "}
            </p>
            <p>
              Il est bien stipulé que les informations susceptibles d’être réclamées dans le cadre
              de ce contrôle ou audit ne peuvent couvrir des données couvertes par le secret
              professionnel.
            </p>
            <p>
              En cas de manquement du Coopérateur à l’une de ses obligations, ce dernier s’expose,
              outre la résiliation de son adhésion sans indemnités, au paiement de pénalités.{" "}
            </p>
            <h4>ARTICLE 18 – CONFIDENTIALITÉ - DONNÉES PERSONNELLES</h4>
            <p>
              L’utilisation des données personnelles se fait dans le respect des dispositions de la
              Loi n° 78-17 du 6 janvier 1978 relative à l’informatique et libertés. La Coopérative
              prend l’engagement de ne transmettre à aucun tiers l’ensemble des données communiquées
              par le Coopérateur et à observer la plus grande vigilance quant à la protection de ces
              dernières.
            </p>
            <p>
              Conformément à la loi informatique et libertés du 6 janvier 1978, le Coopérateur
              bénéficie d’un droit d’accès, de modification, de rectification ainsi que du droit de
              s’opposer à ce que ses informations personnelles soient utilisées à des fins
              promotionnelles et/ou publicitaires par la Coopérative ou par l’un de ses partenaires.
              A ce titre, la Coopérative HEXA COOP s’engage à ce que l’ensemble des informations
              demeurent strictement confidentielles et ne soient utilisées qu’aux fins de mise en
              application de la présente Charte.
            </p>
            <h4>ARTICLE 19 - DÉCLARATION D’INDÉPENDANCE RÉCIPROQUE</h4>
            <p>
              Sauf les cas de solidarité prévus par les présentes ou la Loi, les parties déclarent
              et reconnaissent qu’elles sont et demeureront, pendant toute la durée des présentes,
              des professionnels indépendants, et que cette charte ne peut conférer à l’une ou
              l’autre la qualité de mandataire ou de représentant de son cocontractant, chaque
              partie assurant seule les risques de sa propre exploitation.
              <br />
            </p>
          </section>

          <section>
            <h2>Conditions générales de prestations de services particuliers</h2>

            <p>
              <h3>ARTICLE 1 – CHAMP D’APPLICATION</h3>
              La société HEXA COOP est une société Coopérative regroupant sous une seule entité des
              Coopérateurs affiliés réalisant en son nom et pour son compte des prestations de
              services à la personne.
              <br />
              <br />
              HEXA COOP est immatriculée au Registre du Commerce et des Sociétés de CLERMONT-FERRAND
              sous le numéro 834 225 096 et remplit l’intégralité des conditions posées par la&nbsp;
              Loi n° 2005-841 du 26 juillet 2005 relative au développement des services à la
              personne et portant diverses mesures en faveur de la cohésion sociale et notamment
              l’obligation de déclaration d’activité. A ce titre, les prestations qu’elle exécute
              auprès des particuliers sont éligibles au dispositif de crédit d’impôt prévu par la
              l’article 199 sexdecies du Code général des impôts C.G.I..
              <br />
              <br />
              Tout client souhaitant recourir aux services proposés par la société Coopérative HEXA
              COOP, s’engage à adhérer aux présentes conditions générales de prestations de
              services. Le client prend l’engagement d’en prendre connaissance préalablement à toute
              commande de manière à ce que toute sollicitation auprès de HEXA COOP ou de ses
              Coopérateurs en suppose adhésion entière et sans réserve.
              <br />
              <h3>ARTICLE 2 – RELEVÉ ET PRESTATIONS </h3>
              Client et Coopérateur procèdent à la conclusion d’un relevé de prestations de services
              à la personne, lequel est réputé formé dès signature et péremption du droit de
              rétraction prévu en pareille matière. <br />
              <br />
              Les prestations assurées par le Coopérateur affilié à la Coopérative HEXA COOP sont
              celles relevant du domaine des services à la personne à domicile tels qu’ils sont
              définis aux articles L. 7231-1 et D. 7231-1 du Code du travail. <br />
              <br />
              Client et Coopérateur font leur affaire de la planification des prestations de
              services à la personne et notamment de leur nature, lieu et date d’exécution.
              <br />
              <br />
              <h3>ARTICLE 3 – LE PRIX </h3>
              Les prix des interventions et des éventuels frais afférents sont fixés librement par
              les parties. HEXA COOP ne fournit aucune grille tarifaire au Coopérateur qui fixe,
              d’un commun d’accord avec le client les tarifs appliqués aux prestations assurées. La
              signature du relevé par le client vaut consentement à l’égard desdits tarifs.
              <br />
              <br />
              Les prestations effectuées sont affectées de la T.V.A. au taux applicable (selon la
              nature des prestations).
              <br />
              <br />
              <h3>ARTICLE 4 – EXTRANET</h3>
              Une fois les prestations effectuées, le Coopérateur procède, via son espace personnel
              affilié, à la saisie en ligne d’un relevé de prestations recensant l’intégralité des
              prestations assurées.
              <br />
              <br />
              <h3>ARTICLE 5 – PAIEMENT</h3>
              La Coopérative, en qualité de mandataire, et après validation du relevé de prestations
              par le client, établit la facture et recouvre les sommes dues par ce dernier au titre
              des prestations effectivement effectuées par le Coopérateur.
              <br />
              <br />
              Le client s’engage à régler l’intégralité des sommes dues au titre des prestations
              effectuées dés validation du relevé et, au plus tard, dans les QUINZE (15) jours
              suivant la validation. Les prestations sont réglées, à la convenance du client, par
              chèque bancaire, virement, prélèvement, chèque emploi service universel (CESU)
              préfinancé, carte bancaire (la Coopérative HEXA COOP se réserve le droit d’appliquer
              des frais sur les paiements par carte bancaire) ou par tout autre moyen de paiement
              auquel la Coopérative aura préalablement donné son aval. Tout rejet du règlement fera
              l’objet de frais supplémentaires à la charge du client.
              <br />
              <br />
              Le paiement effectué entre les mains de la Coopérative vaut règlement définitif et non
              équivoque des prestations effectuées et libère le client. <br />
              <br />
              <h3>ARTICLE 6 – FISCALITE</h3>
              Conformément à l’article 199 sexdecies du Code général des impôts (C.G.I.), les
              dépenses exposées par les particuliers pour financer des prestations de services à la
              personne à domicile (entrant dans le champ des articles L. 7231-1 et D. 7231-1 du Code
              du travail) ouvrent droit à crédit d’impôt. <br />
              <br />
              Ce crédit d’impôt est égal à 50% des dépenses effectivement supportées par le client,
              retenues dans une certaine limite annuelle. Ces dépenses s’entendent nettes des
              éventuelles aides perçues pour l’emploi de personnes à domicile (aide personnalisée
              d’autonomie, prestation d’accueil du jeune enfant, chèque emploi service universel
              CESU, …).
              <br />
              <br />
              Les dépenses sont retenues dans la limite annuelle de 12.000 euros, majorée de 1.500 €
              :<br />
              <br />
              •&nbsp;&nbsp; &nbsp;par enfant à charge (750 € en cas de résidence alternée) ;
              <br />
              •&nbsp;&nbsp; &nbsp;par membre du foyer âgé de plus de 65 ans ;<br />
              •&nbsp;&nbsp; &nbsp;par ascendant âgé de plus de 65 ans, remplissant les conditions
              pour bénéficier de l’APA lorsque les dépenses sont engagées à son domicile.
              <br />
              <br />
              La limite majorée ne peut en aucun cas excéder la somme de 15.000€.
              <br />
              <br />
              Ce plafond est applicable pour toutes les activités de services à la personne, sauf
              pour :<br />
              <br />
              •&nbsp;&nbsp; &nbsp;Le petit jardinage à domicile : plafond limité à 5.000 € ;
              <br />
              •&nbsp;&nbsp; &nbsp;L’assistance informatique et internet : plafond limité à 3.000 € ;
              <br />
              •&nbsp;&nbsp; &nbsp;Le petit bricolage : plafond limité à 500 € (une intervention ne
              peut dépasser 2 heures).
              <br />
              <br />
              Le plafond de dépenses retenues est porté de 12.000 € à 15.000 € (majoré de 1.500 €
              par personne à charge dans les mêmes conditions que la limite de 12.000€, sans excéder
              le total de 18.000 € après majorations) pour la première année où le bénéficiaire
              recourt à la réduction d’impôt (case 7DQ de la déclaration des revenus).
              <br />
              <br />
              Les dépenses sont retenues dans la limite de 20.000 € lorsque l’un des membres du
              foyer fiscal est titulaire d’une carte d’invalidité d’au moins 80 % (case 7DG de la
              déclaration de revenus) (limite prévue par l’article L. 241-3 du code de&nbsp;
              l’action sociale et des familles) ou perçoit une pension d’invalidité de 3ème
              catégorie ou le complément d’allocation d’éducation de l’enfant handicapé. Aucune
              majoration ne s’applique à cette limite de 20.000€.
              <br />
              <br />
              La Coopérative HEXA COOP assure l’envoi, au client, de l’attestation fiscale
              permettant à ce dernier de faire valoir ses droits à crédit d’impôt. Cette attestation
              est envoyée à chaque début d’année civile et recense le montant des prestations
              éligibles et ayant été effectivement assurées au bénéfice du client.
              <br />
              <br />
              Le client fait son affaire de l’établissement de sa déclaration de revenus et de
              l’imputation du crédit d’impôt dont il bénéfice au titre des prestations éligibles à
              l’article 199 sexdecies du Code général des impôts (C.G.I.). <br />
              <br />
              La responsabilité de la Coopérative HEXA COOP ou de ses Coopérateurs ne saurait être
              recherchée en cas de remise en cause dudit avantage fiscal par l’administration
              trouvant son origine dans une erreur commise par le client lors de sa déclaration de
              revenus.
            </p>
            <p>
              <h3>ARTICLE 7 – RESPONSABILITÉ</h3>
              Dans le cadre de son adhésion à la Coopérative, le Coopérateur a souscrit les polices
              d’assurance couvrant les dommages qui pourraient être causés au client de part son
              fait. Toute demande de réparation et/ou dédommagement relative à un dommage causé par
              le Coopérateur sera à adresser à ce dernier. La Coopérative ne saurait être tenue pour
              responsable des dommages causés par le fait du Coopérateur à l’occasion de l’exécution
              de prestations. <br />
              <br />
              En cas de manquement du Coopérateur constaté par le client, ce dernier peut en
              informer sans délai la Coopérative qui effectuera les contrôles et opérations
              nécessaires. <br />
              <br />
              Le client certifie avoir contracté les polices d’assurance d’usage quant à son
              domicile et à sa responsabilité civile afin de couvrir tout dommage qui pourrait être
              causé par son fait au Coopérateur. La responsabilité de la Coopérative ne saurait être
              engagée en cas de dommage imputable au client.
              <br />
              <h3>ARTICLE 8 –DONNÉES PERSONNELLES</h3>
              L’utilisation des données personnelles se fait dans le respect des dispositions de la
              Loi n° 78-17 du 6 janvier 1978 et de la Directive du 27 avril 2016 (RGPD). Sauf à ses
              coopérateurs, HEXA COOP s’engage à ne transmettre à aucun tiers l’ensemble des données
              communiquées par les clients dont l’anonymat à l’égard du public est garanti par
              l’utilisation d’identifiants utilisés par ces derniers.
              <br />
              <br />
              Les informations recueillies sont strictement confidentielles et ne seront utilisées
              que dans le cadre des fonctionnements des services d’HEXA COOP. Conformément à la loi
              informatique et libertés du 6 janvier 1978, tout utilisateur bénéfice d’un droit
              d’accès et, le cas échéant, de modification, de rectification, d’oubli et d’opposition
              de ses données personnelles. D’autre part, les informations communiquées pourront
              également être utilisées à titre promotionnel et/ou publicitaires par HEXA COOP ou par
              l’un de ses partenaires en cas d’accord du client.
              <br />
              <br />
              Conformément aux dispositions de la loi du n°78-17 du 6 janvier 1978, l’utilisateur
              est informé qu’il dispose d’un droit d’accès, de rectification et d’opposition portant
              sur les données le concernant en écrivant à l’exploitant du site web dont l’adresse
              est la suivante : contact@hexa-coop.fr
              <br />
              <br />
              1) Collecte des données
              <br />
              <br />
              Les données personnelles collectées et ultérieurement traitées par HEXA COOP sont
              celles que le client ou le Coopérateur lui transmet volontairement via le formulaire
              d’inscription à la plate-forme des services HEXA COOP et lors de leur utilisation
              desdits services. <br />
              <br />
              Les données collectées via le formulaire d’inscription sont les suivantes : <br />
              •&nbsp;&nbsp; &nbsp;nom
              <br />
              •&nbsp;&nbsp; &nbsp;prénoms
              <br />
              •&nbsp;&nbsp; &nbsp;identifiant et mot de passe
              <br />
              •&nbsp;&nbsp; &nbsp;adresse électronique
              <br />
              •&nbsp;&nbsp; &nbsp;adresse postale (domicile)
              <br />
              <br />
              Les données identifiées comme étant obligatoires dans le formulaire d’inscription,
              c’est-à-dire celles entrées manuellement par le client lors de son inscription, sont
              nécessaires pour bénéficier de l’ensemble des prestations des Services HEXA COOP et
              notamment pour satisfaire les réglementations fiscales et financières. <br />
              <br />
              Lorsque l’Internaute (visiteur non identifié) non-enregistré visite et interagit avec
              le Site, certaines données peuvent être recueillies automatiquement, y compris :{" "}
              <br />
              •&nbsp;&nbsp; &nbsp;l’adresse du protocole Internet (IP) du terminal ;<br />
              •&nbsp;&nbsp; &nbsp;le type de terminal, de navigateur et le système d’exploitation ;
              <br />
              •&nbsp;&nbsp; &nbsp;les informations standards du journal du serveur. <br />
              <br />
              2) Finalité des traitements
              <br />
              <br />
              Les données collectées par le Site permettent à HEXA COOP : <br />− avec le
              consentement du client, de lui envoyer des informations sur son compte et les services
              souscrits auprès du Coopérateur et, toujours avec son consentement, des messages
              promotionnels sur les prestations de Services proposées par HEXA COOP ou sur les
              services et les produits des partenaires marketing.
              <br />− de répondre aux&nbsp; demandes des Coopérateurs ou des clients.
              <br />− de prévenir des activités illégales, de faire respecter les présentes CG aini
              que la Charte déontologique de la coopérative HEXA COOP et, de manière générale,
              protéger nos droits et ceux des utilisateurs. <br />
              <br />
              3) Destinataires des données
              <br />
              <br />
              En aucun cas HEXA COOP ou les coopérateurs qui y ont accès ne partageront avec des
              tiers ni ne divulgueront les données personnelles des clients, sauf si ceux-ci l’y ont
              autorisé expressément. <br />
              <br />
              Toutefois, en application d’une loi, d’un règlement ou en vertu d’une décision d’une
              autorité règlementaire ou judiciaire compétente, les données des clients pourront être
              divulguées, si cela s’avère nécessaire, aux fins, pour HEXA COOP, de préserver ses
              droits et ses intérêts. <br />
              <br />
              Le client peut accepter de recevoir des messages promotionnels des partenaires
              commerciaux de HEXA COOP lors de son inscription ou en modifiant ses informations
              personnelles dans son espace personnel. <br />
              <br />
              4) Conservation des données
              <br />
              <br />
              Les données personnelles collectées sont stockées chez l’hébergeur identifié dans les
              Mentions Légales et sont conservées pour la durée strictement nécessaire à la
              réalisation des finalités visées ci-dessus et notamment à la gestion de la relation
              commerciale. Cependant, les données d’identification sont conservées jusqu’à la date
              de suppression du compte au terme de laquelle les données seront anonymisées. <br />
              <br />
              5) Sécurité des données
              <br />
              <br />
              HEXA COOP attache une importance particulière à la protection et à la sécurité des
              données personnelles de ses clients. En conséquence, HEXA COOP met tout en œuvre pour
              assurer l’intégrité et la protection de ces données contre toutes formes de dommage,
              perte, intrusion, divulgation, altération ou destruction. <br />
              <br />
              HEXA COOP ne peut cependant garantir la sécurité à 100% d’une transmission de données
              ou moyen de stockage. En tout état de cause, cet engagement ne pourra s’analyser comme
              une obligation de résultat. <br />
              <br />
              6) Accès aux données personnelles
              <br />
              <br />
              Conformément aux articles 38 et suivants de la loi n° 78-17 du 6 janvier 1978 telle
              que modifiée, le client peut, à tout moment accéder, rectifier, compléter, mettre à
              jour, bloquer ou supprimer ses données personnelles. <br />
              Il bénéficie également d’un accès direct à ces données sur la plate-forme extranet
              HEXA COOP.
              <br />
              <br />
              <h3>ARTICLE 9 – DURÉE - RÉSILIATION</h3>
              Le contrat conclu entre le client et le Coopérateur vaut pour une durée attachée à
              l’exécution des prestations visées. Il prend automatiquement fin à l’issue de
              l’exécution des prestations de façon à ce qu’aucune partie ne puisse en demander
              exécution à posteriori. <br />
              <br />
              Le contrat peut être conclu à durée indéterminée lorsqu’il prévoit l’exécution de
              prestations récurrentes. Dans ce cas, chacune des parties a le pouvoir d’y mettre un
              terme à tout moment, par envoi d’un courrier recommandé avec demande d’avis de
              réception et sous réserve du respect d’un délai de préavis de UN (1) mois. <br />
              <br />
              En cas de manquement de l’une des parties au contrat, l’autre partie aura la
              possibilité d’en demander la résiliation sans délai ni indemnité.{" "}
            </p>
          </section>
          <section>
            <h2>Conditions générales de prestations de services professionnels</h2>

            <p>
              <h3>ARTICLE 1 – CHAMP D’APPLICATION</h3>
              La société HEXA COOP est une société Coopérative regroupant sous une seule entité des
              Coopérateurs affiliés réalisant en son nom et pour son compte des prestations de
              services à la personne.
              <br />
              <br />
              HEXA COOP est immatriculée au Registre du Commerce et des Sociétés de CLERMONT-FERRAND
              sous le numéro 834 225 096 et remplit l’intégralité des conditions posées par la&nbsp;
              Loi n° 2005-841 du 26 juillet 2005 relative au développement des services à la
              personne et portant diverses mesures en faveur de la cohésion sociale et notamment
              l’obligation de déclaration d’activité. A ce titre, les prestations qu’elle propose et
              assure auprès des dirigeants et salariés d’entreprises sont éligibles au dispositif du
              crédit d’impôt famille prévu par l’article 244 quater F du Code général des impôts
              C.G.I..
              <br />
              <br />
              Tout dirigeant d’entreprise souhaitant mettre en place au sein de sa structure les
              services proposés par la société Coopérative HEXA COOP, s’engage à adhérer aux
              présentes conditions générales de prestations de services. Le dirigeant prend
              l’engagement d’en prendre connaissance préalablement à toute commande de manière à ce
              que toute sollicitation auprès de HEXA COOP ou de ses Coopérateurs en suppose adhésion
              entière et sans réserve.
              <br />
              <br />
              <h3>ARTICLE 2 – RELEVÉ ET PRESTATIONS </h3>
              Professionnel et Coopérateur procèdent à la conclusion d’un relevé de prestations de
              services à la personne, lequel est réputé formé dès sa signature. <br />
              <br />
              Les prestations assurées par le Coopérateur affilié à HEXA COOP sont celles relevant
              du domaine des services à la personne tels qu’ils sont définis aux articles L. 7231-1
              et D. 7231-1 du Code du travail et assurées auprès de toutes personnes exerçant une
              activité professionnelle au sein de l’entreprise (article L. 7233-5 du Code du
              travail) :<br />
              •&nbsp;&nbsp; &nbsp;personnel salarié au sens du droit du travail, c’est-à-dire
              titulaire d’un contrat de travail ;<br />
              •&nbsp;&nbsp; &nbsp;personnel non salarié :<br />
              o&nbsp;&nbsp; &nbsp;le chef d’entreprise dans une entreprise individuelle (profession
              libérale, artisan, commerçant...) ;<br />
              o&nbsp;&nbsp; &nbsp;les dirigeants sociaux d’une société : président, directeur
              général, directeur général délégué, gérant ou membre du directoire.
              <br />
              <br />
              Professionnel et Coopérateur font leur affaire de la planification des prestations de
              services à la personne et notamment de leur nature, lieu et date d’exécution.
              <br />
              <br />
              ATTENTION : L’octroi du crédit d’impôt est subordonné au respect, par le
              professionnel, des conditions fixées par la loi et l’URSSAF.
              <br />
              <br />
              <h3>ARTICLE 3 – LE PRIX </h3>
              Les prix des interventions et des éventuels frais afférents sont fixés librement par
              les parties. HEXA COOP ne fournit aucune grille tarifaire au Coopérateur qui fixe,
              d’un commun d’accord avec le professionnel les tarifs appliqués aux prestations
              assurées. La signature du relevé par le professionnel vaut consentement à l’égard
              desdits tarifs.
              <br />
              <br />
              Les prestations effectuées sont affectées de la T.V.A. au taux applicable (selon la
              nature des prestations).
              <br />
              <h3>ARTICLE 4 – EXTRANET</h3>
              Une fois les prestations effectuées, le Coopérateur procède, via son espace personnel
              affilié, à la saisie en ligne d’un relevé de prestations recensant l’intégralité des
              prestations assurées.
              <br />
              <br />
              <h3>ARTICLE 5 – PAIEMENT</h3>
              La Coopérative, en qualité de mandataire, et après validation du relevé de prestations
              par le professionnel, établit la facture et recouvre les sommes dues par ce dernier au
              titre des prestations effectivement effectuées par le Coopérateur.
              <br />
              <br />
              Le professionnel s’engage à régler l’intégralité des sommes dues au titre des
              prestations effectuées dés validation du relevé et, au plus tard, dans les QUINZE (15)
              jours suivant la validation. Les prestations sont réglées, à la convenance du
              professionnel, par chèque bancaire, virement, prélèvement automatique, chèque emploi
              service universel (CESU) préfinancé, carte bancaire (la Coopérative HEXA COOP se
              réserve le droit d’appliquer des frais sur les paiements par carte bancaire) ou par
              tout autre moyen de paiement auquel la Coopérative HEXA COOP aura préalablement donné
              son aval. Tout rejet du règlement fera l’objet de frais supplémentaires à la charge du
              professionnel.
              <br />
              <br />
              Le paiement effectué entre les mains de la Coopérative vaut règlement définitif et non
              équivoque des prestations effectuées et libère le professionnel. <br />
              <br />
              <h3>ARTICLE 6 – FISCALITÉ</h3>
              Conformément à l’article 244 quater F du Code général des impôts (C.G.I.), les
              dépenses exposées par une entreprise et ayant servi à financer des prestations de
              services entrant dans le champ des « activités de services à la personne » au sens des
              articles L. 7231-1 et D. 7231-1 du Code du travail ouvrent droit à un crédit d’impôt
              égal à 25 % desdites dépenses. Ce crédit est calculé en prenant en compte les dépenses
              effectivement supportées par l’entreprise au cours de l’année civile (peu importe la
              date de clôture de l’exercice). <br />
              <br />
              ATTENTION : L’octroi du crédit d’impôt est subordonné au respect, par le
              professionnel, des conditions fixées par la loi et l’URSSAF.
              <br />
              <br />
              Ce crédit d’impôt sur les bénéfices d’un maximum de 500.000 € par exercice prend en
              compte les dépenses effectuées par l’entreprise (à concurrence de 2 millions €). Ce
              crédit est imputé sur l’impôt sur le revenu (IR) ou sur l’impôt sur les sociétés (IS)
              dû par l’entreprise au titre de l’année au cours de laquelle les dépenses ont été
              engagées. <br />
              <br />
              Les sommes supportées par l’entreprise pour des prestations au profit de ses salariés
              ou dirigeants sociaux n’ont pas le caractère de rémunération (article L. 7233-4 et D.
              7233-8 du Code du travail) et sont à ce titre exonérées d’impôt sur le revenu (IR)
              dans la limite de 1.830 € par an et par bénéficiaire (article 81 du Code général des
              impôts). <br />
              <br />
              La Coopérative HEXA COOP assure l’envoi, au professionnel, de l’attestation fiscale
              permettant à l’entreprise de faire valoir ses droits à crédit d’impôt. Cette
              attestation est envoyée à chaque début d’année civile et recense le montant des
              prestations éligibles et ayant été effectivement assurées.
              <br />
              <br />
              Le professionnel fait son affaire de l’établissement de sa déclaration de résultat et
              de l’imputation du crédit d’impôt dont il bénéfice au titre des prestations éligibles
              à l’article 244 quater F du Code général des impôts (C.G.I.). <br />
              <br />
              La responsabilité de la Coopérative HEXA COOP ou de ses Coopérateurs ne saurait être
              recherchée en cas de remise en cause dudit avantage fiscal par l’administration
              trouvant son origine dans une erreur commise par le professionnel lors de sa
              déclaration de résultat.
              <br />
              <br />
              <h3>ARTICLE 7 – RESPONSABILITÉ</h3>
              Dans le cadre de son adhésion à la Coopérative HEXA COOP, le Coopérateur a souscrit
              les polices d’assurance couvrant les dommages qui pourraient être causés de part son
              fait. Toute demande de réparation et/ou dédommagement relative à un dommage causé par
              le Coopérateur sera à adresser à ce dernier. La Coopérative HEXA COOP ne saurait être
              tenue pour responsable des dommages causés par le fait du Coopérateur à l’occasion de
              l’exécution de prestations. <br />
              <br />
              En cas de manquement du Coopérateur constaté par le professionnel, ce dernier peut en
              informer sans délai la Coopérative qui effectuera les contrôles et opérations
              nécessaires. <br />
              <br />
              Le professionnel certifie disposer des police d’assurance d’usage afin de couvrir tout
              dommage qui pourrait être causé par son fait, ou celui de ses salariés bénéficiaires
              des prestations, au Coopérateur. La responsabilité de la Coopérative ne saurait être
              engagée en cas de dommage imputable au professionnel.
              <br />
              <br />
              <h3>
                ARTICLE 8 – CONFIDENTIALITÉ - DONNÉES PERSONNELLES DES BÉNÉFICIAIRES DES SERVICES
              </h3>
              L’utilisation des données personnelles se fait dans le respect des dispositions de la
              Loi n° 78-17 du 6 janvier 1978 et de la Directive du 27 avril 2016 (RGPD). Sauf à ses
              coopérateurs, HEXA COOP s’engage à ne transmettre à aucun tiers l’ensemble des données
              personnelles des bénéficiaires des services communiquées par les clients dont
              l’anonymat à l’égard du public est garanti par l’utilisation d’identifiants utilisés
              par ces derniers.
              <br />
              <br />
              Les informations des bénéficiaires des services recueillies sont strictement
              confidentielles et ne seront utilisées que dans le cadre des fonctionnements des
              services d’HEXA COOP. Conformément à la loi informatique et libertés du 6 janvier
              1978, tout bénéficiaire des services bénéfice d’un droit d’accès et, le cas échéant,
              de modification, de rectification, d’oubli et d’opposition de ses données
              personnelles. D’autre part, les informations communiquées pourront également être
              utilisées à titre promotionnel et/ou publicitaires par HEXA COOP ou par l’un de ses
              partenaires en cas d’accord du client.
              <br />
              <br />
              Conformément aux dispositions de la loi du n°78-17 du 6 janvier 1978, le bénéficiaire
              des services est informé qu’il dispose d’un droit d’accès, de rectification et
              d’opposition portant sur les données le concernant en écrivant à l’exploitant du site
              web dont l’adresse est la suivante : <br />
              contact@hexa-coop.fr
              <br />
              <br />
              1) Collecte des données
              <br />
              <br />
              Les données personnelles collectées et ultérieurement traitées par HEXA COOP sont
              celles que le client, le bénéficiaire des services ou le Coopérateur lui transmet
              volontairement via le formulaire d’inscription à la plate-forme des services HEXA COOP
              et lors de leur utilisation desdits services. <br />
              <br />
              Les données collectées via le formulaire d’inscription sont les suivantes : <br />
              •&nbsp;&nbsp; &nbsp;nom
              <br />
              •&nbsp;&nbsp; &nbsp;prénoms
              <br />
              •&nbsp;&nbsp; &nbsp;identifiant et mot de passe
              <br />
              •&nbsp;&nbsp; &nbsp;adresse électronique
              <br />
              •&nbsp;&nbsp; &nbsp;adresse postale (domicile)
              <br />
              <br />
              Les données identifiées comme étant obligatoires dans le formulaire d’inscription,
              c’est-à-dire celles entrées manuellement par le client ou le bénéficiaire des services
              lors de son inscription, sont nécessaires pour bénéficier de l’ensemble des
              prestations des Services HEXA COOP et notamment pour satisfaire les règlementations
              fiscales et financières. <br />
              <br />
              Lorsque l’Internaute (visiteur non identifié) non-enregistré visite et interagit avec
              le Site, certaines données peuvent être recueillies automatiquement, y compris :{" "}
              <br />
              •&nbsp;&nbsp; &nbsp;l’adresse du protocole Internet (IP) du terminal ;<br />
              •&nbsp;&nbsp; &nbsp;le type de terminal, de navigateur et le système d’exploitation ;
              <br />
              •&nbsp;&nbsp; &nbsp;les informations standards du journal du serveur. <br />
              <br />
              2) Finalité des traitements
              <br />
              <br />
              Les données collectées par le Site permettent à HEXA COOP : <br />− avec le
              consentement du client et du bénéficiaire des services, de lui envoyer des
              informations sur son compte et les services souscrits auprès du Coopérateur et,
              toujours avec son consentement, des messages promotionnels sur les prestations de
              Services proposées par HEXA COOP ou sur les services et les produits des partenaires
              marketing.
              <br />− de répondre aux&nbsp; demandes des Coopérateurs, du bénéficiaire des services
              ou des clients.
              <br />− de prévenir des activités illégales, de faire respecter les présentes CG ainsi
              que la Charte déontologique de la coopérative HEXA COOP et, de manière générale,
              protéger nos droits et ceux des utilisateurs. <br />
              <br />
              3) Destinataires des données
              <br />
              <br />
              En aucun cas HEXA COOP ou les coopérateurs qui y ont accès ne partageront avec des
              tiers ni ne divulgueront les données personnelles des clients et des bénéficiaires des
              services, sauf si ceux-ci l’y ont autorisé expressément. <br />
              <br />
              Toutefois, en application d’une loi, d’un règlement ou en vertu d’une décision d’une
              autorité règlementaire ou judiciaire compétente, les données des clients ou des
              bénéficiaires des services pourront être divulguées, si cela s’avère nécessaire, aux
              fins, pour HEXA COOP, de préserver ses droits et ses intérêts. <br />
              <br />
              Le client ou le bénéficiaire des services peut accepter de recevoir des messages
              promotionnels des partenaires commerciaux de HEXA COOP lors de son inscription ou en
              modifiant ses informations personnelles dans son espace personnel. <br />
              <br />
              4) Conservation des données
              <br />
              <br />
              Les données personnelles collectées sont stockées chez l’hébergeur identifié dans les
              Mentions Légales et sont conservées pour la durée strictement nécessaire à la
              réalisation des finalités visées ci-dessus et notamment à la gestion de la relation
              commerciale. Cependant, les données d’identification sont conservées jusqu’à la date
              de suppression du compte au terme de laquelle les données seront anonymisées. <br />
              <br />
              5) Sécurité des données
              <br />
              <br />
              HEXA COOP attache une importance particulière à la protection et à la sécurité des
              données personnelles de ses clients. En conséquence, HEXA COOP met tout en œuvre pour
              assurer l’intégrité et la protection de ces données contre toutes formes de dommage,
              perte, intrusion, divulgation, altération ou destruction. <br />
              <br />
              HEXA COOP ne peut cependant garantir la sécurité à 100% d’une transmission de données
              ou moyen de stockage. En tout état de cause, cet engagement ne pourra s’analyser comme
              une obligation de résultat. <br />
              <br />
              6) Accès aux données personnelles
              <br />
              <br />
              Conformément aux articles 38 et suivants de la loi n° 78-17 du 6 janvier 1978 telle
              que modifiée, le client ou le bénéficiaire des services peut, à tout moment accéder,
              rectifier, compléter, mettre à jour, bloquer ou supprimer ses données personnelles.{" "}
              <br />
              Il bénéficie également d’un accès direct à ces données sur la plate-forme extranet
              HEXA COOP.
              <br />
              <br />
              <h3>ARTICLE 9 – DURÉE - RÉSILIATION</h3>
              Le contrat conclu entre le professionnel et le Coopérateur vaut pour une durée
              attachée à l’exécution des prestations visées. Il prend automatiquement fin à l’issue
              de l’exécution des prestations de façon à ce qu’aucune partie ne puisse en demander
              exécution à posteriori. <br />
              <br />
              Le contrat peut être conclu à durée indéterminée lorsqu’il prévoit l’exécution de
              prestations récurrentes. Dans ce cas, chacune des parties a le pouvoir d’y mettre un
              terme à tout moment, par envoi d’un courrier recommandé avec demande d’avis de
              réception et sous réserve du respect d’un délai de préavis de UN (1) mois. <br />
              <br />
              En cas de manquement de l’une des parties au contrat, l’autre partie aura la
              possibilité d’en demander la résiliation sans délai ni indemnité. <br />
              <br />
            </p>
          </section>
        </div>
      </IonContent>
    </IonPage>
  );
};
