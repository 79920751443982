import type { ComponentPropsWithoutRef, ReactNode } from "react";

import React from "react";

import { cn } from "~/utils/misc";

export type TableProps = {
  data: {
    headers: string[];
    rows: {
      key: string | number;
      columns: ReactNode[];
      // search?: string;
      // sort?: string | number | ReactNode;
      // sortable?: boolean;
      // onMobile?: boolean;
      // condition?: boolean | undefined;
    }[];
  };
  // currentSort?: { key: string; value: "ASC" | "DESC" } | null;
  // onSort: (sortKey: string) => void;
} & ComponentPropsWithoutRef<"div">;

export const Table = React.memo(function Table({
  data,
  // currentSort,
  // onSort,
  className,
  ...props
}: TableProps) {
  return (
    <div
      className={cn("w-full overflow-hidden rounded-lg border border-grey-200", className)}
      {...props}
    >
      <table className="w-full max-md:block">
        <thead className="border-b border-b-grey-200 bg-grey-100 bg-opacity-70 text-sm max-md:hidden">
          <tr className="divide-x divide-grey-100 divide-opacity-70 max-md:block">
            {data.headers.map((header, i) => (
              <th key={`${header}-${i}`} className="p-2 max-md:block">
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="divide-y divide-grey-200 divide-opacity-70 bg-white text-sm max-md:block">
          {data.rows.map((row) => (
            <tr
              key={row.key}
              className="divide-x divide-grey-100 divide-opacity-60 hover:bg-grey-50 hover:bg-opacity-60 max-md:block"
            >
              {row.columns.map((column, colIndex) => (
                <td
                  key={`${row.key}-${colIndex}`}
                  colSpan={row.columns.length === 1 ? data.headers.length : undefined}
                  className={cn("px-2 py-3 max-md:block", !column && "max-md:hidden")}
                >
                  {column ? (
                    <span className="inline font-semibold md:hidden">
                      {data.headers[colIndex]} :{" "}
                    </span>
                  ) : null}
                  {column}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
});
