import type { ReactNode } from "react";

import {
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonModal,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import { closeOutline } from "ionicons/icons";

import "./Modal.scss";

export interface ModalProps extends React.ComponentProps<typeof IonModal> {
  isOpen: boolean;
  onClose: () => void;
  children?: ReactNode;
}

export const Modal = ({ isOpen, onClose, title, children, ...props }: ModalProps) => {
  return (
    <IonModal
      isOpen={isOpen}
      onIonModalDidDismiss={onClose}
      // className="auto-height"
      mode="ios"
      {...props}
    >
      <IonHeader>
        <IonToolbar>
          <IonTitle>{title}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onClose} color="dark" mode="md" shape="round">
              <IonIcon icon={closeOutline} slot="icon-only" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <div className="max-h-[calc(100vh-44px)] overflow-y-auto p-8">{children}</div>
    </IonModal>
  );
};
