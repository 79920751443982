import type { PropsWithChildren } from "react";
import type { RouteProps } from "react-router";

import { IonRouterOutlet } from "@ionic/react";
import { Redirect, Route } from "react-router";

import { userRoles } from "~/config/api-constants";
import { useAuthContext } from "~/contexts/authContext";
import { DashboardLayout } from "~/layouts/DashboardLayout";
import { AdministrationLayout } from "~/pages/Administration/AdministrationLayout";
import { AnonymousMembershipSignForm } from "~/pages/Anonymous/AnonymousMembershipSignForm";
import { AnonymousPayCbError } from "~/pages/Anonymous/AnonymousPayCbError";
import { AnonymousPayCbSuccess } from "~/pages/Anonymous/AnonymousPayCbSuccess";
import { AnonymousPayPrepaidSuccess } from "~/pages/Anonymous/AnonymousPayPrepaidSuccess";
import { AnonymousQuotationSignForm } from "~/pages/Anonymous/AnonymousQuotationSignForm";
import { AnonymousRecommendationLanding } from "~/pages/Anonymous/AnonymousRecommendationLanding";
import { AnonymousSendPrepaidCodeForm } from "~/pages/Anonymous/AnonymousSendPrepaidCodeForm";
import { AnonymousSignSuccess } from "~/pages/Anonymous/AnonymousSignSuccess";
import { AnonymousValidatePrepaidCodeForm } from "~/pages/Anonymous/AnonymousValidatePrepaidCodeForm";
import { AttestationsLayout } from "~/pages/Attestations/AttestationsLayout";
import { CooperatorLayout } from "~/pages/Cooperators/CooperatorsLayout";
import { CustomersLayout } from "~/pages/Customers/CustomersLayout";
import { FileDeliver } from "~/pages/FileDeliver/FileDeliver";
import { LegalNoticePage } from "~/pages/Legals/LegalNoticePage";
import { MembershipGeneralConditions } from "~/pages/Legals/MembershipGeneralConditions";
import { PersonalDataSecurityPolicyPage } from "~/pages/Legals/PersonalDataSecurityPolicyPage";
import { QuotationGeneralConditions } from "~/pages/Legals/QuotationGeneralConditions";
import { ForgotPasswordPage } from "~/pages/Login/ForgotPasswordPage";
import { LoginPage } from "~/pages/Login/LoginPage";
import { RenewPasswordPage } from "~/pages/Login/RenewPasswordPage";
import { PreFinancingLayout } from "~/pages/PreFinancing/PreFinancingLayout";
import { ProfileLayout } from "~/pages/Profile/ProfileLayout";
// import { ProfileLayout } from "~/pages/Profile/ProfileLayout";
import { QuotationsLayout } from "~/pages/Quotations/QuotationsLayout";
import { RecommendationHome } from "~/pages/Recommendation/RecommendationHome";
import { RecommendationModule } from "~/pages/Recommendation/RecommendationModule";
import { StatisticsPage } from "~/pages/Statistics/Statistics";

export const Router = () => {
  const { isAuth, isAdmin, currentUser } = useAuthContext();

  return (
    <IonRouterOutlet>
      {/* Dashboard routes */}
      <DashboardLayout>
        {/* Need to be logged in */}
        <ProtectedRoute path="/quotations" condition={isAuth}>
          <QuotationsLayout />
        </ProtectedRoute>
        <ProtectedRoute path="/customers" condition={isAuth}>
          <CustomersLayout />
        </ProtectedRoute>
        <ProtectedRoute path="/cooperator-dashboard" condition={isAuth}>
          <CooperatorLayout />
        </ProtectedRoute>
        <ProtectedRoute path="/attestations" condition={isAuth}>
          <AttestationsLayout />
        </ProtectedRoute>
        <ProtectedRoute path="/statistics" condition={isAuth}>
          <StatisticsPage />
        </ProtectedRoute>
        <ProtectedRoute path="/recommendation" condition={isAuth}>
          <RecommendationHome />
        </ProtectedRoute>
        <ProtectedRoute path="/recommendation/:id" condition={isAuth}>
          <RecommendationModule />
        </ProtectedRoute>

        {/* Need to be logged in and have the role COOPERATOR */}
        <ProtectedRoute
          path="/profile"
          condition={Boolean(currentUser?.roles?.includes(userRoles.ROLE_COOPERATOR))}
          redirectToHome
        >
          <ProfileLayout />
        </ProtectedRoute>

        {/* Need to be logged in and have the role ADMIN */}
        <ProtectedRoute path="/administration" condition={isAdmin}>
          <AdministrationLayout />
        </ProtectedRoute>
        <ProtectedRoute path="/prefinancing-contracts" condition={isAdmin}>
          <PreFinancingLayout />
        </ProtectedRoute>
      </DashboardLayout>

      {/* Login routes */}
      <ProtectedRoute path="/login" condition={!isAuth} redirectToHome>
        <LoginPage />
      </ProtectedRoute>
      <ProtectedRoute path="/forgot-password" condition={!isAuth} redirectToHome>
        <ForgotPasswordPage />
      </ProtectedRoute>
      <ProtectedRoute path="/renew-password" condition={!isAuth} redirectToHome>
        <RenewPasswordPage />
      </ProtectedRoute>

      {/* File deliver routes */}
      <ProtectedRoute exact path="/file/deliver/:type" condition={isAuth}>
        <FileDeliver />
      </ProtectedRoute>
      <ProtectedRoute exact path="/file/deliver/:type/:id" condition={isAuth}>
        <FileDeliver />
      </ProtectedRoute>

      {/* Anonymous file deliver routes */}
      <Route exact path="/_anon/file/deliver/:type">
        <FileDeliver isAnonymous={true} />
      </Route>
      <Route exact path="/_anon/file/deliver/:type/:id">
        <FileDeliver isAnonymous={true} />
      </Route>

      {/* Anonymous routes */}
      <Route exact path="/_anon/sign/:hash">
        <AnonymousQuotationSignForm />
      </Route>
      <Route exact path="/_anon/sign/membership/:hash">
        <AnonymousMembershipSignForm />
      </Route>
      <Route exact path="/_anon/pay/cartepre/:hash">
        <AnonymousSendPrepaidCodeForm />
      </Route>
      <Route exact path="/_anon/pay/cartepre/:hash/auth">
        <AnonymousValidatePrepaidCodeForm />
      </Route>
      <Route exact path="/_anon/pay/cb/success">
        <AnonymousPayCbSuccess />
      </Route>
      <Route exact path="/_anon/pay/cb/error">
        <AnonymousPayCbError />
      </Route>
      <Route exact path="/_anon/pay/cartepre/success">
        <AnonymousPayPrepaidSuccess />
      </Route>
      <Route exact path="/_anon/sign/success/:payment_method">
        <AnonymousSignSuccess />
      </Route>

      <Route exact path="/_anon/recommendation/:module_type/:user_hash">
        <AnonymousRecommendationLanding />
      </Route>

      {/* Legals routes */}
      <Route exact path="/personal-data-security-policy">
        <PersonalDataSecurityPolicyPage />
      </Route>
      <Route exact path="/legal-notice">
        <LegalNoticePage />
      </Route>
      <Route exact path="/terms-and-conditions/quotation">
        <QuotationGeneralConditions />
      </Route>
      <Route exact path="/terms-and-conditions/membership">
        <MembershipGeneralConditions />
      </Route>

      {/* Redirect "/" to home or login */}
      <Route exact path="/">
        <Redirect to={isAuth ? "/quotations" : "/login"} />
      </Route>
    </IonRouterOutlet>
  );
};

export const ProtectedRoute = ({
  condition,
  redirectToHome = false,
  children,
  ...props
}: PropsWithChildren<{ condition: boolean; redirectToHome?: boolean } & RouteProps>) => {
  return (
    <Route
      render={() => {
        return condition === true ? children : <Redirect to={redirectToHome ? "/" : "/login"} />;
      }}
      {...props}
    />
  );
};
