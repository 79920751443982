import type { FileDeliverType } from "~/api/file-deliver";

import { useCallback, useEffect, useRef } from "react";
import { useHistory, useLocation, useParams } from "react-router";

import { useFileDeliver } from "~/hooks/useFileDeliver";

export const FileDeliver = ({ isAnonymous = false }: { isAnonymous?: boolean }) => {
  const params = useParams<{
    type?: FileDeliverType;
    id?: string;
  }>();
  const history = useHistory();
  const { openFile } = useFileDeliver({ isAnonymous });

  const isDownloadStartedRef = useRef(false);

  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);

  const path = urlSearchParams.get("path");

  const handleOpenFile = useCallback(async () => {
    if (!params.type) return history.push("/404");

    await openFile({
      itemType: params.type,
      itemParams: {
        id: params.id,
        path
      },
      newTab: true
    });
  }, [history, params.id, params.type, path, openFile]);

  useEffect(() => {
    if (!isDownloadStartedRef.current) {
      isDownloadStartedRef.current = true;
      handleOpenFile();
    }
  }, [handleOpenFile, isDownloadStartedRef]);

  return null;
};
