import type { UseFormRegister } from "react-hook-form";

import dayjs from "dayjs";

import { quotationCoopPaymentStates } from "~/config/api-constants";
import { transformDateTimeToDate } from "~/utils/misc";
import { FormDatePicker } from "~/components/ui/FormDatePicker";
import { FormInput } from "~/components/ui/FormInput";
import { FormSelect } from "~/components/ui/FormSelect";

export const CooperatorsQuotationBillsSearchForm = ({
  register
}: {
  register: UseFormRegister<any>;
}) => {
  return (
    <>
      <FormDatePicker
        label="Date min"
        defaultValue={transformDateTimeToDate(dayjs().subtract(3, "year").toISOString()) || ""}
        optional
        {...register("invoicedCooperatorAtAfter")}
      />
      <FormDatePicker
        label="Date max"
        defaultValue={transformDateTimeToDate(dayjs().toISOString()) || ""}
        optional
        {...register("invoicedCooperatorAtBefore")}
      />
      <FormInput
        label="Nom du client"
        placeholder="ex: Dupond"
        type="text"
        optional
        {...register("customerLastname")}
      />
      <FormInput
        label="Société du client"
        placeholder="ex: HEXA-COOP"
        type="text"
        optional
        {...register("customerCompanyName")}
      />
      <FormInput
        label="Nom du coopérateur"
        placeholder="ex: Dupont"
        type="text"
        optional
        {...register("cooperatorLastname")}
      />
      <FormInput
        label="Société du coopérateur"
        placeholder="ex: Jardi Services"
        type="text"
        optional
        {...register("cooperatorCompanyName")}
      />
      <FormInput
        label="Numéro de facture"
        placeholder="6 derniers chiffres (ex: 203621)"
        type="text"
        optional
        {...register("idFacture")}
      />
      <FormInput
        label="Numéro de relevé"
        placeholder="6 derniers chiffres (ex: 231887)"
        type="text"
        optional
        {...register("id")}
      />
      <FormSelect label="Statut" optional {...register("statePaymentCooperator")}>
        <option value="">Tous</option>
        <option value={quotationCoopPaymentStates.STATE_PAYMENT_COOP_PENDING}>
          En attente de virement
        </option>
        <option value={quotationCoopPaymentStates.STATE_PAYMENT_COOP_WAITING}>
          Virement débloqué, en attente
        </option>
        <option value={quotationCoopPaymentStates.STATE_PAYMENT_COOP_PAID}>
          Virement effectué
        </option>
        <option value={quotationCoopPaymentStates.STATE_PAYMENT_COOP_ALREADY_PAID}>
          Réglement antérieur
        </option>
        <option value={quotationCoopPaymentStates.STATE_PAYMENT_COOP_PROBLEM}>
          Virement bloqué pour impayé
        </option>
      </FormSelect>
      <FormInput
        label="Montant TTC min (€)"
        placeholder="ex: 1000"
        type="number"
        optional
        {...register("minTotalTTC")}
      />
      <FormInput
        label="Montant TTC max (€)"
        placeholder="ex: 1500"
        type="number"
        optional
        {...register("maxTotalTTC")}
      />
      <FormDatePicker label="Payé le" optional {...register("invoicedCooperatorPaidAt")} />
    </>
  );
};
