import { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { useForm } from "react-hook-form";

import { queryKeys } from "~/config/query-keys-constants";
import { queryClient } from "~/api/_queryClient";
import { importCustomers } from "~/api/user";
import { Alert } from "~/components/ui/Alert";
import { Button } from "~/components/ui/Button/Button";
import { FormInput } from "~/components/ui/FormInput";
import { ExternalLink } from "~/components/ui/Link";
import { LoadingSpinner } from "~/components/ui/LoadingSpinner";
import { Modal } from "~/components/ui/Modal/Modal";
import { ImportCustomersSchema } from "~/schemas/import-customers.schema";

type ImportCustomersModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const API_URL = import.meta.env.VITE_API_URL;

export const ImportCustomersModal = ({ isOpen, onClose }: ImportCustomersModalProps) => {
  const [errors, setErrors] = useState<Array<{ ligne: number; champ: string; message: string }>>(
    []
  );

  const form = useForm<ImportCustomersSchema>({
    resolver: zodResolver(ImportCustomersSchema),
    defaultValues: {
      fileIndividual: null,
      fileProfessional: null
    }
  });

  const importMutation = useMutation(importCustomers, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.users]);
      form.reset();
      onClose();
    },
    onError: (error: any) => {
      if (error.response?.data?.errors) {
        setErrors(error.response.data.errors);
      }
    }
  });

  const onSubmit = (data: ImportCustomersSchema) => {
    importMutation.mutate({
      fileIndividual: data.fileIndividual || undefined,
      fileProfessional: data.fileProfessional || undefined
    });
  };

  console.log(form.watch("fileIndividual"));

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Importer des clients">
      <Alert
        type="info"
        className="mb-4 text-center"
        text={
          <>
            <p className="mb-2">
              Pour importer vos clients, vous trouverez ci-dessous les modèles de fichier CSV à
              télécharger et à compléter en respectant les informations sur les champs à renseigner.
            </p>
            <p className="mb-2">
              ATTENTION : Lors de l&apos;import de vos clients, il n&apos;y a pas de détection de
              doublons, veillez à ne pas importer des clients déjà présents dans votre base
              actuelle.
            </p>
            <div className="flex flex-col gap-2">
              <ExternalLink
                to={`${API_URL}/modele_import_client_individual.csv`}
                download
                target="_self"
              >
                Modèle pour les particuliers (avec ou sans avance immédiate)
              </ExternalLink>
              <ExternalLink
                to={`${API_URL}/modele_import_client_professional.csv`}
                download
                target="_self"
              >
                Modèle pour les professionels (sans avance immédiate)
              </ExternalLink>
            </div>
          </>
        }
      />

      <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-4">
        <FormInput
          type="file"
          accept=".csv"
          label="Particuliers avec ou sans avance immédiate"
          errorMessage={form.formState.errors.fileIndividual?.message}
          {...form.register("fileIndividual")}
        />

        <FormInput
          type="file"
          accept=".csv"
          label="Professionels sans avance immédiate"
          errorMessage={form.formState.errors.fileProfessional?.message}
          {...form.register("fileProfessional")}
        />

        {errors.length > 0 ? (
          <div className="rounded-lg bg-danger-50 p-4 text-danger-700">
            <h3 className="mb-2 font-medium">Erreurs lors de l&apos;import :</h3>
            <ul className="list-inside list-disc space-y-1">
              {errors.map((error, index) => (
                <li key={index}>
                  Ligne {error.ligne} - {error.champ}: {error.message}
                </li>
              ))}
            </ul>
          </div>
        ) : null}

        <div className="flex justify-end gap-2">
          <Button type="button" fill="outline" onClick={onClose}>
            Annuler
          </Button>
          <Button type="submit" disabled={importMutation.isLoading}>
            {importMutation.isLoading ? (
              <>
                <LoadingSpinner size="sm" className="mr-2" />
                Import en cours...
              </>
            ) : (
              "Importer les clients"
            )}
          </Button>
        </div>
      </form>
    </Modal>
  );
};
