import type { SendModuleSchema } from "~/schemas/module.schema";

import { IonContent, IonIcon, IonPage } from "@ionic/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { checkmarkCircleOutline, listCircleOutline } from "ionicons/icons";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router";

import { queryKeys } from "~/config/query-keys-constants";
import { useUIContext } from "~/contexts/uiContext";
import { getModule, sendModule } from "~/api/module";
import { HtmlParser } from "~/components/HtmlParser";
import { Button } from "~/components/ui/Button/Button";
import { FormTextArea } from "~/components/ui/FormTextArea";
import { LoadingSpinner } from "~/components/ui/LoadingSpinner";

export const RecommendationModule = () => {
  const params = useParams<{ id: string }>();
  const { toast } = useUIContext();
  const history = useHistory();

  const queryParams = {
    id: parseInt(params.id)
  };

  const { data, isLoading } = useQuery({
    queryKey: [queryKeys.modules, queryParams],
    queryFn: () => getModule(queryParams)
  });

  const { mutate } = useMutation({
    mutationFn: sendModule,
    onSuccess: () => {
      toast("Recommandation envoyée avec succès", "success");
      history.push("/recommendation");
    }
  });

  const form = useForm<SendModuleSchema>({
    defaultValues: {
      sms: "",
      emails: ""
    }
  });

  const onSubmit = (data: SendModuleSchema) => {
    mutate({ id: parseInt(params.id), data });
  };

  return (
    <IonPage>
      <IonContent className="container">
        {isLoading ? (
          <LoadingSpinner fullPage />
        ) : (
          <div className="">
            <HtmlParser
              html={data?.title || ""}
              className="mt-8 text-center text-2xl font-bold text-primary"
            />
            <HtmlParser
              html={data?.description2 || ""}
              className="mx-auto mt-8 max-w-4xl text-center"
            />
            <div className="mt-16 flex gap-4">
              <div className="flex w-full basis-1/2 flex-col overflow-hidden rounded-lg text-center text-white">
                <div className="flex grow flex-col items-center justify-center bg-secondary-400 p-6">
                  <IonIcon icon={listCircleOutline} className="mb-2 text-6xl" />
                  <h2 className="mb-4 text-3xl font-bold">Vos actions</h2>
                  <p className="font-medium">
                    1 - Vous saisissez un ou plusieurs numéros de portable et/ou d&apos;emails
                    <br />
                    <br />
                    2 - Vous cliquez sur &quot;ENVOYER&quot;
                    <br />
                    <br />
                    3 - Votre contact reçoit un message avec le lien vers votre page personnalisée.
                    S&apos;il est intéressé, il peut nous contacter directement ou prendre un RDV
                    téléphonique.
                    <br />
                    <br />
                    NOTE : Pour tester et avoir un aperçu de votre page personnalisée, il vous
                    suffit de saisir votre numéro de portable ou votre email et de cliquer sur
                    &quot;ENVOYER&quot;
                  </p>
                </div>
                <div className="flex grow flex-col items-center justify-center bg-primary-500 p-6">
                  <IonIcon icon={checkmarkCircleOutline} className="mb-2 text-6xl" />
                  <h2 className="mb-4 text-3xl font-bold">Nos engagements</h2>
                  <p className="font-medium">
                    HEXA COOP s’engage à appeler votre contact pour lui expliquer les avantages et
                    répondre à ses questions.
                  </p>
                </div>
              </div>
              <div className="w-full basis-1/2 rounded-lg border-2 border-primary p-6">
                <h2 className="mb-4 text-3xl font-medium text-primary">
                  Soumettre un ou plusieurs contacts
                </h2>
                <p className="mb-4 text-sm font-bold">
                  Nous recommandons de préférence l&apos;utilisation de numéros de portable qui sont
                  plus efficacement consultés.
                </p>
                <p className="mb-8 text-sm text-grey-600">
                  Vous pouvez copier-coller des contacts directement à partir d’un fichier (les
                  formats Excel, feuille de calcul Google, CSV ou TXT sont acceptés) ou les saisir
                  manuellement.
                </p>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                  <FormTextArea
                    label="Numéros de portable"
                    placeholder={`0612345678\n0698765432\n0678910234`}
                    rows={5}
                    infoMessage="Un numéro de téléphone par ligne"
                    className="mb-8"
                    {...form.register("sms")}
                  />
                  <FormTextArea
                    label="Emails"
                    placeholder={`exemple1@email.fr\nexemple2@email.com\nexemple3@email.fr`}
                    rows={5}
                    infoMessage="Un email par ligne"
                    className="mb-8"
                    {...form.register("emails")}
                  />
                  <Button
                    type="submit"
                    color="primary"
                    fill="solid"
                    size="large"
                    className="w-full text-base font-bold"
                  >
                    Envoyer la recommandation
                  </Button>
                </form>
              </div>
            </div>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};
