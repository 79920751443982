import type { MainTableProps } from "~/components/ui/MainTable";

import { useInfiniteQuery } from "@tanstack/react-query";
import { createOutline } from "ionicons/icons";

import { queryKeys } from "~/config/query-keys-constants";
import { formatDisplayDate } from "~/utils/misc";
import { getNextPageParamFn } from "~/api/_config";
import { getEmails } from "~/api/email";
import { TableLayout } from "~/components/TableLayout";

export const AdministrationEmails = () => {
  const { data, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: [queryKeys.emails],
    queryFn: ({ pageParam }) => getEmails({ page: pageParam }),
    getNextPageParam: getNextPageParamFn
  });

  const tableData: MainTableProps["data"] =
    data?.pages.flat().map((email) => ({
      itemId: email?.id,
      actions: [
        {
          label: "Éditer l'email",
          icon: createOutline,
          color: "blue",
          href: `/administration/emails/edit/${email?.id}`
        }
      ],
      columns: [
        { key: "name", label: "Nom", display: email?.name },
        { key: "subject", label: "Sujet", display: email?.subject },
        { key: "code", label: "Code", display: email?.code },
        {
          key: "isActive",
          label: "Activé",
          display: email?.isActive ? "Oui" : "Non"
        },
        {
          key: "createdAt",
          label: "Créé le",
          display: email?.createdAt ? formatDisplayDate(email.createdAt) : "",
          sort: email?.createdAt,
          search: email?.createdAt ? formatDisplayDate(email.createdAt) : ""
        }
      ]
    })) || [];

  return (
    <>
      <TableLayout
        data={tableData}
        isLoading={isFetching || isFetchingNextPage}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
      />
    </>
  );
};
