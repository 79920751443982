import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";

import { useUIContext } from "~/contexts/uiContext";
import { handleApiError } from "~/services/errors";
import { renewPassword } from "~/api/auth";
import { LoginLayout } from "~/layouts/LoginLayout";
import { Button } from "~/components/ui/Button/Button";
import { FormInput } from "~/components/ui/FormInput";
import { RenewPasswordSchema } from "~/schemas/password.schema";

export const RenewPasswordPage = () => {
  const { setGlobalLoading, toast } = useUIContext();
  const history = useHistory();

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const id = searchParams.get("id");
  const token = searchParams.get("token");

  const mutation = useMutation({
    mutationFn: renewPassword,
    onSuccess: () => {
      setGlobalLoading({ isLoading: false });
      toast("Votre mot de passe a été modifié avec succès.", "success");
      history.push("/login");
    },
    onError(error) {
      handleApiError(error, toast);
    }
  });

  const form = useForm<RenewPasswordSchema>({
    resolver: zodResolver(RenewPasswordSchema),
    defaultValues: {
      password: "",
      confirmPassword: ""
    }
  });

  const onSubmit = () => {
    if (!id) {
      throw new Error("id is required to renew password");
    }
    if (!token) {
      throw new Error("token is required to renew password");
    }
    mutation.mutate({ id: parseInt(id), token, password: form.getValues().password });
  };

  return (
    <LoginLayout>
      <h1 className="text-center text-2xl">Réinitialisation du mot de passe</h1>

      <form onSubmit={form.handleSubmit(onSubmit)} className="block w-full">
        <FormInput
          type="password"
          label="Nouveau mot de passe"
          placeholder="Votre nouveau mot de passe"
          errorMessage={form.formState.errors.password?.message}
          className="w-full text-grey-900 [&_label]:text-white"
          {...form.register("password")}
        />
        <FormInput
          type="password"
          label="Confirmer le mot de passe"
          placeholder="Confirmez votre nouveau mot de passe"
          errorMessage={form.formState.errors.confirmPassword?.message}
          className="mt-4 w-full text-grey-900 [&_label]:text-white"
          {...form.register("confirmPassword")}
        />

        <Button type="submit" color="primary" fill="solid" className="mt-8 block w-full">
          Valider
        </Button>
      </form>
    </LoginLayout>
  );
};
