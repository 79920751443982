import { IonContent, IonIcon, IonPage } from "@ionic/react";
import { checkmarkCircleOutline } from "ionicons/icons";

export const AnonymousPayPrepaidSuccess = () => {
  return (
    <IonPage>
      <IonContent>
        <div className="flex h-full w-full items-center justify-center bg-grey-100 p-4 sm:p-8">
          <div className="mx-auto max-w-6xl rounded-lg border border-grey-100 bg-white px-4 py-4 shadow-md sm:px-8">
            <h1 className="m-0 border-b border-b-grey-100 pb-4 text-center text-lg font-semibold uppercase">
              Confirmation du paiement par carte préfinancée
            </h1>

            <IonIcon
              className="mx-auto mt-4 block h-12 w-12 text-success-500"
              icon={checkmarkCircleOutline}
            />

            <p className="mt-4 max-w-2xl text-center">
              Votre relevé de prestation a bien été payé.
            </p>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
