import type { Quotation } from "~/types/quotation.types";
import type { User } from "~/types/user.types";
import type { DeepPartial } from "~/types/utils.types";

import { useEffect, useMemo, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { IonIcon } from "@ionic/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import { trashOutline } from "ionicons/icons";
import { Controller, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router";

import {
  quotationStates,
  quotationTaskPeriods,
  quotationTaskPeriodTexts,
  userRoles
} from "~/config/api-constants";
import { queryKeys } from "~/config/query-keys-constants";
import { formatMoney, isZipcodeDomTom, round, transformDateTimeToDate } from "~/utils/misc";
import { useAuthContext } from "~/contexts/authContext";
import { useUIContext } from "~/contexts/uiContext";
import { useDebounce } from "~/hooks/useDebounce";
import { useModal } from "~/hooks/useModal";
import { handleApiError } from "~/services/errors";
import { convertReccurenceToTasks } from "~/services/quotations";
import { getCooperatorCategories } from "~/api/cooperator-categories";
import { createQuotation, getQuotation, updateQuotation } from "~/api/quotation";
import { getUser, getUsers } from "~/api/user";
import { ConfirmationModal } from "~/components/Modals/ConfirmationModal";
import { Button } from "~/components/ui/Button/Button";
import { FormComboBox } from "~/components/ui/FormComboBox";
import { FormDatePicker } from "~/components/ui/FormDatePicker";
import { FormInput } from "~/components/ui/FormInput";
import { FormRow } from "~/components/ui/FormRow";
import { FormSelect } from "~/components/ui/FormSelect";
import { FormTextArea } from "~/components/ui/FormTextArea";
import { FormTitle } from "~/components/ui/FormTitle";
import { LoadingSpinner } from "~/components/ui/LoadingSpinner";
import { QuotationSchema } from "~/schemas/quotation.schema";

export const CreateEditQuotation = ({ editMode = false }: { editMode?: boolean }) => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const { quotation_id: quotationId } = useParams<{ quotation_id?: string }>();
  const { currentUser, isAdmin } = useAuthContext();
  const { toast } = useUIContext();
  const { openModal, closeModal, renderModal } = useModal();

  // Get the quotation when form is in edit mode
  const { data: quotationToEdit, isLoading: isQuotationLoading } = useQuery({
    queryKey: [queryKeys.quotations, quotationId],
    queryFn: quotationId ? () => getQuotation({ id: parseInt(quotationId) }) : () => null,
    enabled: !!quotationId
  });

  // Cooperators list for cooperator selection (Admin only)
  const [selectedCooperator, setSelectedCooperator] = useState<DeepPartial<User> | null>(
    isAdmin ? null : currentUser
  );
  const [cooperatorsSearch, setCooperatorsSearch] = useState("");
  const debouncedCooperatorsSearch = useDebounce<string>(cooperatorsSearch, 500);
  const cooperatorsQueryParams = {
    role: userRoles.ROLE_COOPERATOR,
    searchName: debouncedCooperatorsSearch,
    sort: { key: "lastname", value: "ASC" }
  } as const;
  const { data: cooperators, isLoading: isCooperatorsLoading } = useQuery({
    queryKey: [queryKeys.users, cooperatorsQueryParams],
    queryFn: () => getUsers({ ...cooperatorsQueryParams }),
    enabled: isAdmin
  });

  // Customers list for customer selection
  const [selectedCustomer, setSelectedCustomer] = useState<DeepPartial<User> | null>(null);
  const [customersSearch, setCustomersSearch] = useState("");
  const debouncedCustomersSearch = useDebounce<string>(customersSearch, 500);
  const customersQueryParams = {
    role: userRoles.ROLE_CUSTOMER,
    searchName: debouncedCustomersSearch,
    cooperatorId: selectedCooperator?.id || null,
    sort: { key: "lastname", value: "ASC" },
    itemsPerPage: 1500
  } as const;
  const { data: customers, isLoading: isCustomersLoading } = useQuery({
    queryKey: [queryKeys.users, customersQueryParams],
    queryFn: () => getUsers({ ...customersQueryParams }),
    enabled: !!selectedCooperator?.id
  });

  // Get selected cooperator for categories
  const cooperatorQueryParams = {
    id: selectedCooperator?.id || null
  } as const;
  const { data: cooperator, isLoading: isCooperatorLoading } = useQuery({
    queryKey: [queryKeys.users, cooperatorQueryParams],
    queryFn: () => getUser({ ...cooperatorQueryParams }),
    enabled: !!selectedCooperator?.id
  });

  // Get all categories
  const { data: allCategories } = useQuery({
    queryKey: [queryKeys.cooperatorCategories],
    queryFn: () => getCooperatorCategories({ itemsPerPage: 1000 }),
    enabled: Boolean(selectedCooperator?.id && selectedCustomer?.id && selectedCustomer.isPro)
  });

  const filteredCategories = useMemo(() => {
    const isCooperatorDomTom = isZipcodeDomTom(selectedCooperator?.zipcode || "");

    if (selectedCustomer?.isPro) {
      return (
        allCategories?.filter(
          (category) =>
            category.isPro &&
            !category.isContract &&
            (isCooperatorDomTom ? category.isDomTom : !category.isDomTom)
        ) || []
      );
    }

    return cooperator?.categoriesCooperatorUser?.map((c) => c.category) || [];
  }, [allCategories, selectedCooperator, selectedCustomer, cooperator]);

  const defaultQuotationRow = useMemo(
    () => ({
      nameService: "",
      quantity: "",
      tva: "",
      priceUnit: "0",
      priceUnitTTC: 0,
      totalHT: 0,
      totalTTC: 0
    }),
    []
  );

  // Quotation rows
  const [quotationRows, setQuotationRows] = useState<DeepPartial<Quotation["quotationRow"]>>([
    defaultQuotationRow
  ]);

  // Tasks (recurrence)
  const [recurrence, setRecurrence] = useState<{
    startAt: string | null;
    endAt: string | null;
    period: string | null;
    hourTask: string | null;
  }>({
    // Today Date format for an input date
    startAt: transformDateTimeToDate(new Date().toISOString()) || null,
    endAt: transformDateTimeToDate(dayjs(new Date()).add(1, "month").toISOString()) || null,
    period: quotationTaskPeriods.TASK_PERIOD_ONCE_A_MONTH,
    hourTask: "08:00"
  });
  const [tasks, setTasks] = useState<DeepPartial<Quotation["scheduleQuotationTasks"]> | null>(null);

  // Calculate totals
  const totalHT = quotationRows.reduce((acc, row) => acc + (row?.totalHT || 0), 0);
  const totalTVA = quotationRows.reduce(
    (acc, row) => acc + (row?.totalHT || 0) * (Number(row?.tva || "0") / 100),
    0
  );
  const totalTTC = quotationRows.reduce((acc, row) => acc + (row?.totalTTC || 0), 0);
  const totalCoopHT = totalHT - totalTTC * 0.1;
  const totalCoopTTC =
    selectedCooperator?.hasTva && Number(selectedCooperator.tvaRate) > 0
      ? Number(totalCoopHT) * (1 + Number(selectedCooperator.tvaRate) / 100)
      : totalCoopHT;

  function getTvaRate(tva: string) {
    return Number(tva) / 100;
  }

  function addQuotationRow() {
    setQuotationRows((prev) => [...prev, defaultQuotationRow]);
  }

  function deleteQuotationRow(index: number) {
    setQuotationRows((prev) => prev.filter((_, i) => i !== index));
  }

  const defaultValues = useMemo(
    () => ({
      cooperator: selectedCooperator?.id?.toString() || "",
      customer: selectedCustomer?.id?.toString() || "",
      isRecurrenceMaster: quotationToEdit?.isRecurrenceMaster || false,
      isAvanceImmediate: quotationToEdit
        ? quotationToEdit.isAvanceImmediate
        : selectedCustomer?.canAvanceImmediate && selectedCustomer.isAvanceImmediate,
      isRecoverable: quotationToEdit?.isRecoverable || true,
      comments: quotationToEdit?.comments || "",
      quotationRow: quotationToEdit?.quotationRow?.map((row) => ({
        category: (cooperator ? row?.category?.id?.toString() : "") || "",
        executedAt: transformDateTimeToDate(row?.executedAt) || null,
        endExecutedAt: transformDateTimeToDate(row?.endExecutedAt) || null,
        quantity: row?.quantity || "",
        tva: row?.tva || "",
        priceUnit: round(Number(row?.priceUnit || "0"), 2).toString(),
        priceUnitTTC: round(Number(row?.priceUnit || "0") * (1 + Number(row?.tva || "0") / 100), 2),
        totalHT: round(row?.totalHT || 0, 2),
        totalTTC: round(row?.totalTTC || 0, 2)
      })) || [defaultQuotationRow]
    }),
    [quotationToEdit, selectedCustomer, defaultQuotationRow, selectedCooperator, cooperator]
  );

  // Form
  const form = useForm<QuotationSchema>({
    resolver: zodResolver(QuotationSchema),
    defaultValues
  });

  useEffect(() => {
    form.reset(defaultValues, { keepDirtyValues: true });
  }, [defaultValues, form]);

  const isRecurrenceMasterSelected = form.watch("isRecurrenceMaster");
  const isAvanceImmediateSelected = form.watch("isAvanceImmediate");

  // Quotation mutation
  const quotationMutation = useMutation({
    mutationFn: (data: QuotationSchema) =>
      editMode && quotationId
        ? updateQuotation({ data, id: parseInt(quotationId || "") })
        : createQuotation({ data }),
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: [queryKeys.quotations] });
      toast(
        editMode ? "Le relevé a été modifié avec succès." : "Le relevé a été créé avec succès.",
        "success"
      );
      history.push("/quotations/waiting");
    },
    onError(error) {
      handleApiError(error, toast);
    }
  });

  const onSubmit = ({
    state,
    data
  }: {
    state: number | null | undefined;
    data: QuotationSchema;
  }) => {
    const formatedData = {
      ...data,
      ...(state !== undefined ? { state } : {}),
      scheduleQuotationTasks:
        tasks && tasks.length > 0 && recurrence.hourTask
          ? tasks.map((task) => ({
              // Set the time to recurrence hourTask
              scheduledAt: dayjs(task?.scheduledAt)
                .set("hour", Number(recurrence.hourTask?.split(":")[0]) || 8)
                .set("minute", Number(recurrence.hourTask?.split(":")[1]) || 9)
                .toISOString()
            }))
          : undefined,
      quotationRow: quotationRows.map((row) => {
        if (!row?.category?.id) throw new Error("Veuillez sélectionner une prestation.");
        return {
          category: row.category.id.toString() || "",
          nameService: row.category.name || "",
          executedAt: row.executedAt || null,
          endExecutedAt: row.endExecutedAt || null,
          quantity: row.quantity || "",
          tva: row.tva || "",
          priceUnit: row.priceUnit || ""
        };
      })
    };

    quotationMutation.mutate(formatedData);
  };

  // Set first search values for comboboxes
  useEffect(() => {
    if (quotationToEdit) {
      setCooperatorsSearch(quotationToEdit.cooperator?.lastname || "");
      setSelectedCooperator(quotationToEdit.cooperator || null);
      setCustomersSearch(quotationToEdit.customer?.lastname || "");
      setSelectedCustomer(quotationToEdit.customer || null);
      setQuotationRows(quotationToEdit.quotationRow || []);
    }
  }, [quotationToEdit]);

  if (!!quotationId && isQuotationLoading) {
    return <LoadingSpinner size="lg" fullPage />;
  }

  if (!!quotationId && !quotationToEdit) {
    return <p className="text-center">Le relevé à éditer est introuvable.</p>;
  }

  return (
    <>
      <h2 className="mt-6">
        {editMode
          ? `Édition d'un relevé : ${quotationToEdit?.idNormalized}`
          : "Création d'un relevé"}
      </h2>
      <form>
        {isAdmin ? (
          <FormRow>
            <Controller
              name="cooperator"
              control={form.control}
              render={({ field: { onChange, onBlur, ref } }) => {
                return (
                  <FormComboBox
                    label="Coopérateur associé"
                    onBlur={onBlur}
                    onChange={(value) => {
                      const cooperator = cooperators?.find(
                        (cooperator) => cooperator?.id?.toString() === value
                      );
                      setSelectedCooperator(cooperator || null);
                      setSelectedCustomer(null);
                      form.setValue("customer", "");
                      onChange(value);
                    }}
                    onSearchChange={setCooperatorsSearch}
                    options={
                      cooperators?.map((coop) => ({
                        id: coop?.id || 0,
                        label: `${coop?.lastname || ""} ${coop?.firstname || ""}`,
                        value: coop?.id?.toString() || ""
                      })) || []
                    }
                    defaultOption={
                      quotationToEdit?.cooperator?.id
                        ? {
                            id: quotationToEdit.cooperator.id,
                            label: `${quotationToEdit.cooperator.lastname} ${quotationToEdit.cooperator.firstname}`,
                            value: quotationToEdit.cooperator.id.toString()
                          }
                        : null
                    }
                    infoMessage="Coopérateur auquel est associé le relevé."
                    errorMessage={form.formState.errors.cooperator?.message}
                    isLoading={isCooperatorsLoading}
                    ref={ref}
                  />
                );
              }}
            />
          </FormRow>
        ) : null}

        {(isAdmin && selectedCooperator) || !isAdmin ? (
          <>
            <FormTitle as="h3">Paramètres du relevé</FormTitle>
            <FormRow>
              <Controller
                name="customer"
                control={form.control}
                render={({ field: { onChange, onBlur, ref } }) => {
                  return (
                    <FormComboBox
                      label="Client"
                      onChange={(value) => {
                        const customer = customers?.find(
                          (customer) => customer?.id?.toString() === value
                        );
                        setSelectedCustomer(customer || null);
                        onChange(value);
                      }}
                      onBlur={onBlur}
                      onSearchChange={setCustomersSearch}
                      options={
                        customers?.map((customer) => ({
                          id: customer?.id || 0,
                          label: `${customer?.lastname ?? ""} ${customer?.firstname ?? ""}${customer?.companyName ? " - " + customer.companyName : ""}${customer?.internRefCoop ? " (" + customer.internRefCoop + ")" : ""}`,
                          value: customer?.id?.toString() || ""
                        })) || []
                      }
                      defaultOption={
                        quotationToEdit?.customer?.id
                          ? {
                              id: quotationToEdit.customer.id,
                              label: `${quotationToEdit.customer.lastname ?? ""} ${quotationToEdit.customer.firstname ?? ""} ${quotationToEdit.customer.internRefCoop ? "(" + quotationToEdit.customer.internRefCoop + ")" : ""}`,
                              value: quotationToEdit.customer.id.toString()
                            }
                          : null
                      }
                      errorMessage={form.formState.errors.customer?.message}
                      isLoading={isCustomersLoading}
                      ref={ref}
                    />
                  );
                }}
              />

              {selectedCustomer ? (
                <div className="col-span-1">
                  <h4 className="mb-2 text-sm font-medium leading-none text-grey-900">
                    Coordonnées du client sélectionné
                  </h4>
                  <div className="space-y-1.5 rounded-lg border border-grey-100 bg-grey-50 p-4">
                    {selectedCustomer.companyName ? (
                      <div className="font-semibold">{selectedCustomer.companyName}</div>
                    ) : null}
                    {selectedCustomer.lastname || selectedCustomer.firstname ? (
                      <div className="font-medium">
                        {selectedCustomer.lastname || ""} {selectedCustomer.firstname || ""}
                      </div>
                    ) : null}
                    {selectedCustomer.address ? (
                      <div className="text-sm">{selectedCustomer.address}</div>
                    ) : null}
                    {selectedCustomer.zipcode || selectedCustomer.city ? (
                      <div className="text-sm">
                        {" "}
                        {selectedCustomer.zipcode || ""} {selectedCustomer.city || ""}
                      </div>
                    ) : null}
                    {selectedCustomer.email ? (
                      <div className="text-sm">{selectedCustomer.email}</div>
                    ) : null}
                    {selectedCustomer.mobileNumber ? (
                      <div className="text-sm">{selectedCustomer.mobileNumber}</div>
                    ) : null}
                    {selectedCustomer.fixeNumber ? (
                      <div className="text-sm">{selectedCustomer.fixeNumber}</div>
                    ) : null}
                  </div>
                </div>
              ) : null}
            </FormRow>

            {selectedCustomer ? (
              <FormRow>
                {isAdmin ||
                (currentUser?.canAvanceImmediate && selectedCustomer.canAvanceImmediate) ? (
                  <FormSelect
                    label="Activer l'option d'avance immédiate"
                    warningMessage={
                      selectedCustomer.isAvanceImmediate
                        ? null
                        : "Ce client n'est pas éligible à l'avance immédiate. Completez sa fiche pour activer l'option."
                    }
                    errorMessage={form.formState.errors.isAvanceImmediate?.message}
                    disabled={Boolean(!isAdmin && !selectedCustomer.isAvanceImmediate)}
                    {...form.register("isAvanceImmediate", {
                      setValueAs: (v) => v === "true" || v === true,
                      onChange: () => {
                        form.resetField("isRecoverable");
                      }
                    })}
                  >
                    <option value="false">Non</option>
                    <option value="true">Oui</option>
                  </FormSelect>
                ) : null}

                {!isAvanceImmediateSelected ? (
                  <FormSelect
                    label="Activer le système de relance"
                    errorMessage={form.formState.errors.isRecoverable?.message}
                    {...form.register("isRecoverable", {
                      setValueAs: (v) => v === "true" || v === true
                    })}
                  >
                    <option value="false">Non</option>
                    <option value="true">Oui</option>
                  </FormSelect>
                ) : null}
              </FormRow>
            ) : null}
          </>
        ) : null}

        {selectedCustomer ? (
          <>
            {selectedCooperator?.hasRecurrence ? (
              <>
                <FormTitle as="h3">Récurrence du relevé</FormTitle>
                <FormRow>
                  <FormSelect
                    label="Souhaitez vous rendre ce relevé récurrent ?"
                    errorMessage={form.formState.errors.isRecurrenceMaster?.message}
                    {...form.register("isRecurrenceMaster", {
                      setValueAs: (v) => v === "true" || v === true,
                      onChange: () => {
                        setTasks([]);
                      }
                    })}
                  >
                    <option value="false">Non</option>
                    <option value="true">Oui</option>
                  </FormSelect>
                </FormRow>
              </>
            ) : null}
            {isRecurrenceMasterSelected ? (
              <>
                <FormRow>
                  <FormDatePicker
                    label="Date de début de la récurrence"
                    errorMessage={form.formState.errors.recurrence?.startAt?.message}
                    value={recurrence.startAt || ""}
                    onChange={(e) => {
                      setRecurrence((prev) => ({ ...prev, startAt: e.target.value }));
                      setTasks(
                        convertReccurenceToTasks({
                          isRecurrenceMaster: isRecurrenceMasterSelected,
                          startAt: e.target.value,
                          endAt: recurrence.endAt,
                          period: recurrence.period,
                          hourTask: recurrence.hourTask
                        })
                      );
                    }}
                  />
                  <FormDatePicker
                    label="Date de fin de la récurrence"
                    errorMessage={form.formState.errors.recurrence?.endAt?.message}
                    value={recurrence.endAt || ""}
                    onChange={(e) => {
                      setRecurrence((prev) => ({ ...prev, endAt: e.target.value }));
                      setTasks(
                        convertReccurenceToTasks({
                          isRecurrenceMaster: isRecurrenceMasterSelected,
                          startAt: recurrence.startAt,
                          endAt: e.target.value,
                          period: recurrence.period,
                          hourTask: recurrence.hourTask
                        })
                      );
                    }}
                  />
                  <FormSelect
                    label="Fréquence de la récurrence"
                    errorMessage={form.formState.errors.recurrence?.period?.message}
                    value={recurrence.period || ""}
                    onChange={(e) => {
                      setRecurrence((prev) => ({ ...prev, period: e.target.value }));
                      setTasks(
                        convertReccurenceToTasks({
                          isRecurrenceMaster: isRecurrenceMasterSelected,
                          startAt: recurrence.startAt,
                          endAt: recurrence.endAt,
                          period: e.target.value,
                          hourTask: recurrence.hourTask
                        })
                      );
                    }}
                  >
                    {Object.entries(quotationTaskPeriods).map(([key, value]) => (
                      <option key={key} value={value}>
                        {quotationTaskPeriodTexts[key as keyof typeof quotationTaskPeriodTexts]}
                      </option>
                    ))}
                  </FormSelect>

                  <FormDatePicker
                    type="time"
                    label="Heure d'envoi des relevés"
                    errorMessage={form.formState.errors.recurrence?.hourTask?.message}
                    value={recurrence.hourTask || ""}
                    onChange={(e) => {
                      setRecurrence((prev) => ({ ...prev, hourTask: e.target.value }));
                    }}
                  />
                </FormRow>
                <p>Mon relevé sera dupliqué {tasks?.length} fois.</p>
                {tasks && tasks.length > 0 ? (
                  <div className="mt-6 grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
                    {tasks.map((task, index) => (
                      <div key={index} className="flex items-center gap-2">
                        <FormDatePicker
                          type="date"
                          label={`Date d'envoi du relevé n° ${index + 1}`}
                          value={transformDateTimeToDate(task?.scheduledAt) || ""}
                          onChange={(e) =>
                            setTasks(
                              (prev) =>
                                prev?.map((t, i) =>
                                  i === index ? { ...t, scheduledAt: e.target.value } : t
                                ) || []
                            )
                          }
                        />
                        <button
                          type="button"
                          className="flex items-center justify-center p-1 text-danger-500 transition-colors hover:text-danger-700"
                          onClick={() => {
                            setTasks((prev) => prev?.filter((_, i) => i !== index) || null);
                          }}
                        >
                          <IonIcon icon={trashOutline} className="mt-5 h-4 w-4" />
                        </button>
                      </div>
                    ))}
                  </div>
                ) : null}
              </>
            ) : null}

            <FormTitle as="h3">Prestations</FormTitle>

            {quotationRows.length > 0
              ? quotationRows.map((quotationRow, index) => (
                  <div
                    key={index}
                    className="mt-6 rounded-xl border border-grey-100 bg-grey-50 p-4 shadow"
                  >
                    <div className="flex items-center justify-between text-sm font-medium">
                      <div className="text-grey-700">Prestation {index + 1}</div>
                      <button
                        type="button"
                        className="flex items-center justify-center p-1 text-danger-500 transition-colors hover:text-danger-700"
                        onClick={() => deleteQuotationRow(index)}
                      >
                        <IonIcon icon={trashOutline} className="h-4 w-4" />
                      </button>
                    </div>
                    <FormRow>
                      <FormSelect
                        label="Nature de la prestation"
                        errorMessage={
                          form.formState.errors.quotationRow?.[index]?.category?.message
                        }
                        defaultValue={quotationRow?.category?.id?.toString() || ""}
                        {...form.register(`quotationRow.${index}.category`, {
                          onChange(event) {
                            const category = filteredCategories.find(
                              (category) => category.id.toString() === event.target.value
                            );
                            const tva = getTvaRate(category?.tva || "0");
                            const priceUnitHT = Number(quotationRow?.priceUnit || "0");
                            const priceUnitTTC = priceUnitHT * (1 + tva);
                            const quantity = Number(quotationRow?.quantity || "0");
                            const totalTTC = priceUnitTTC * quantity;

                            form.setValue(
                              `quotationRow.${index}.priceUnitTTC`,
                              round(priceUnitTTC, 2)
                            );
                            form.setValue(`quotationRow.${index}.totalTTC`, round(totalTTC, 2));
                            setQuotationRows((prev) => {
                              const newRows = [...prev];
                              newRows[index] = {
                                ...newRows[index],
                                category,
                                nameService: category?.name || "",
                                tva: category?.tva || "0"
                              };
                              return newRows;
                            });
                          }
                        })}
                      >
                        <option value="" disabled>
                          Sélectionner une prestation
                        </option>
                        {isCooperatorLoading ? (
                          <option value="" disabled>
                            Chargement...
                          </option>
                        ) : null}
                        {filteredCategories.map((category) => (
                          <option key={category.id} value={category.id.toString()}>
                            {category.name}
                          </option>
                        ))}
                      </FormSelect>
                      <FormDatePicker
                        label="Date de début réalisation"
                        max={new Date().toISOString().split("T")[0]}
                        errorMessage={
                          form.formState.errors.quotationRow?.[index]?.executedAt?.message
                        }
                        {...form.register(`quotationRow.${index}.executedAt`, {
                          setValueAs: (v) => (v === "" ? null : v),
                          deps: [`quotationRow.${index}.endExecutedAt`],
                          onChange: (e) => {
                            setQuotationRows((prev) => {
                              const newRows = [...prev];
                              newRows[index] = {
                                ...newRows[index],
                                executedAt: e.target.value
                              };
                              return newRows;
                            });
                          }
                        })}
                      />
                    </FormRow>
                    <FormRow>
                      <FormDatePicker
                        label="Date de fin réalisation"
                        max={new Date().toISOString().split("T")[0]}
                        errorMessage={
                          form.formState.errors.quotationRow?.[index]?.endExecutedAt?.message
                        }
                        {...form.register(`quotationRow.${index}.endExecutedAt`, {
                          deps: [`quotationRow.${index}.executedAt`],
                          setValueAs: (v) => (v === "" ? null : v),
                          onChange: (e) => {
                            setQuotationRows((prev) => {
                              const newRows = [...prev];
                              newRows[index] = {
                                ...newRows[index],
                                endExecutedAt: e.target.value
                              };
                              return newRows;
                            });
                          }
                        })}
                      />
                      <FormInput
                        type="number"
                        step="any"
                        label="Quantité"
                        infoMessage="Ex: Pour 1 heure et 30 minutes, saisir 1.5"
                        errorMessage={
                          form.formState.errors.quotationRow?.[index]?.quantity?.message
                        }
                        {...form.register(`quotationRow.${index}.quantity`, {
                          setValueAs: (v) => v.toString(),
                          onChange: (e) => {
                            const tva = getTvaRate(quotationRow?.tva || "0");
                            const quantity = Number(e.target.value || "0");

                            const priceUnitHT = Number(quotationRow?.priceUnit || "0");
                            const priceUnitTTC = priceUnitHT * (1 + tva);
                            const totalHT = priceUnitHT * quantity;
                            const totalTTC = priceUnitTTC * quantity;
                            form.setValue(`quotationRow.${index}.totalHT`, round(totalHT, 2));
                            form.setValue(`quotationRow.${index}.totalTTC`, round(totalTTC, 2));
                            setQuotationRows((prev) => {
                              const newRows = [...prev];
                              newRows[index] = {
                                ...newRows[index],
                                quantity: quantity.toString(),
                                totalHT,
                                totalTTC
                              };
                              return newRows;
                            });
                          }
                        })}
                      />
                    </FormRow>
                    <FormRow>
                      <FormInput
                        type="number"
                        step="any"
                        label="Montant HT"
                        rightAddon="€ HT"
                        errorMessage={
                          form.formState.errors.quotationRow?.[index]?.priceUnit?.message
                        }
                        {...form.register(`quotationRow.${index}.priceUnit`, {
                          setValueAs: (v) => v.toString(),
                          onChange: (e) => {
                            const tva = getTvaRate(quotationRow?.tva || "0");
                            const priceUnitHT = Number(e.target.value || "0");

                            const priceUnitTTC = priceUnitHT * (1 + tva);
                            const quantity = Number(quotationRow?.quantity || "0");
                            const totalHT = priceUnitHT * quantity;
                            const totalTTC = priceUnitTTC * quantity;
                            form.setValue(
                              `quotationRow.${index}.priceUnitTTC`,
                              round(priceUnitTTC, 2)
                            );
                            form.setValue(`quotationRow.${index}.totalHT`, round(totalHT, 2));
                            form.setValue(`quotationRow.${index}.totalTTC`, round(totalTTC, 2));
                            setQuotationRows((prev) => {
                              const newRows = [...prev];
                              newRows[index] = {
                                ...newRows[index],
                                priceUnit: priceUnitHT.toString(),
                                totalHT,
                                totalTTC
                              };
                              return newRows;
                            });
                          }
                        })}
                      />
                      <FormInput
                        type="number"
                        step="any"
                        label="Montant TTC"
                        infoMessage={`${Number(quotationRow?.tva || "0")}% de TVA.`}
                        rightAddon="€ TTC"
                        errorMessage={
                          form.formState.errors.quotationRow?.[index]?.priceUnitTTC?.message
                        }
                        {...form.register(`quotationRow.${index}.priceUnitTTC`, {
                          setValueAs: (v) => Number(v),
                          onChange: (e) => {
                            const tva = getTvaRate(quotationRow?.tva || "0");
                            const priceUnitTTC = Number(e.target.value || "0");
                            const priceUnitHT = priceUnitTTC / (1 + tva);
                            const quantity = Number(quotationRow?.quantity || "0");
                            const totalHT = priceUnitHT * quantity;
                            const totalTTC = priceUnitTTC * quantity;
                            form.setValue(
                              `quotationRow.${index}.priceUnit`,
                              round(priceUnitHT, 2).toString()
                            );
                            form.setValue(`quotationRow.${index}.totalHT`, round(totalHT, 2));
                            form.setValue(`quotationRow.${index}.totalTTC`, round(totalTTC, 2));
                            setQuotationRows((prev) => {
                              const newRows = [...prev];
                              newRows[index] = {
                                ...newRows[index],
                                priceUnit: priceUnitHT.toString(),
                                totalHT,
                                totalTTC
                              };
                              return newRows;
                            });
                          }
                        })}
                      />
                    </FormRow>
                    <FormRow>
                      <FormInput
                        type="number"
                        step="any"
                        label="Total HT"
                        rightAddon="€ HT"
                        errorMessage={form.formState.errors.quotationRow?.[index]?.totalHT?.message}
                        {...form.register(`quotationRow.${index}.totalHT`, {
                          setValueAs: (v) => Number(v),
                          onChange: (e) => {
                            const tva = getTvaRate(quotationRow?.tva || "0");
                            const totalHT = Number(e.target.value || "0");

                            const priceUnitHT = totalHT / Number(quotationRow?.quantity || "0");
                            const priceUnitTTC = priceUnitHT * (1 + tva);
                            const totalTTC = totalHT * (1 + tva);
                            form.setValue(
                              `quotationRow.${index}.priceUnit`,
                              round(priceUnitHT, 2).toString()
                            );
                            form.setValue(
                              `quotationRow.${index}.priceUnitTTC`,
                              round(priceUnitTTC, 2)
                            );
                            form.setValue(`quotationRow.${index}.totalTTC`, round(totalTTC, 2));
                            setQuotationRows((prev) => {
                              const newRows = [...prev];
                              newRows[index] = {
                                ...newRows[index],
                                priceUnit: priceUnitHT.toString(),
                                totalHT,
                                totalTTC
                              };
                              return newRows;
                            });
                          }
                        })}
                      />
                      <FormInput
                        type="number"
                        step="any"
                        label="Total TTC"
                        infoMessage={`${Number(quotationRow?.tva || "0")}% de TVA.`}
                        rightAddon="€ TTC"
                        errorMessage={
                          form.formState.errors.quotationRow?.[index]?.totalTTC?.message
                        }
                        {...form.register(`quotationRow.${index}.totalTTC`, {
                          setValueAs: (v) => Number(v),
                          onChange: (e) => {
                            const tva = getTvaRate(quotationRow?.tva || "0");
                            const totalTTC = Number(e.target.value || "0");

                            const priceUnitTTC = totalTTC / Number(quotationRow?.quantity || "0");
                            const priceUnitHT = priceUnitTTC / (1 + tva);
                            const totalHT = totalTTC / (1 + tva);
                            form.setValue(
                              `quotationRow.${index}.priceUnit`,
                              round(priceUnitHT, 2).toString()
                            );
                            form.setValue(
                              `quotationRow.${index}.priceUnitTTC`,
                              round(priceUnitTTC, 2)
                            );
                            form.setValue(`quotationRow.${index}.totalHT`, round(totalHT, 2));
                            setQuotationRows((prev) => {
                              const newRows = [...prev];
                              newRows[index] = {
                                ...newRows[index],
                                priceUnit: priceUnitHT.toString(),
                                totalHT,
                                totalTTC
                              };
                              return newRows;
                            });
                          }
                        })}
                      />
                    </FormRow>
                  </div>
                ))
              : null}

            <p className="mt-2 text-sm text-danger-700">
              Vous vous engagez à respecter les prestations listées dans le catalogue de service.
            </p>

            <Button fill="outline" className="mt-6" onClick={() => addQuotationRow()}>
              Ajouter une prestation
            </Button>

            <div className="mt-8 grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
              <FormTextArea
                label="Commentaires / Observations"
                className="lg:col-span-2"
                textareaClassName="min-h-32"
                errorMessage={form.formState.errors.comments?.message}
                {...form.register("comments")}
              />
              <div className="space-y-1 rounded-xl border border-grey-200 bg-grey-100 p-4 shadow-sm md:mt-6">
                <div className="mb-3 border-b border-grey-200 pb-3">
                  <div className="flex items-center justify-between gap-8">
                    <div className="text-sm font-medium">Total perçu coopérateur</div>
                    <div className="text-lg font-bold text-primary-700">
                      {selectedCooperator?.hasTva && Number(selectedCooperator.tvaRate) > 0
                        ? `${formatMoney(totalCoopTTC)} TTC`
                        : `${formatMoney(totalCoopHT)} HT`}
                    </div>
                  </div>
                  {selectedCooperator?.hasTva && Number(selectedCooperator.tvaRate) > 0 ? (
                    <div className="text-end text-sm text-grey-700">
                      Soit {formatMoney(totalCoopHT)} HT
                    </div>
                  ) : null}
                </div>
                <div className="flex items-center justify-between gap-8">
                  <div className="text-sm">Total HT</div>
                  <div className="font-medium">{formatMoney(totalHT)}</div>
                </div>
                <div className="flex items-center justify-between gap-8">
                  <div className="text-sm">TVA</div>
                  <div className="font-medium">{formatMoney(totalTVA)}</div>
                </div>
                <div className="!mt-2 flex items-center justify-between gap-8">
                  <div className="font-medium">Total TTC</div>
                  <div className="text-lg font-bold text-primary-700">{formatMoney(totalTTC)}</div>
                </div>
              </div>
            </div>

            <div className="mt-12 flex flex-wrap gap-4 border-t border-grey-200 pt-6 sm:justify-end">
              <Button fill="clear" onClick={() => history.goBack()}>
                Annuler
              </Button>

              {(!quotationToEdit?.state || quotationToEdit.state === quotationStates.STATE_DRAFT) &&
              !isRecurrenceMasterSelected ? (
                <Button
                  type="button"
                  fill="outline"
                  onClick={form.handleSubmit((data) =>
                    onSubmit({ state: quotationStates.STATE_DRAFT, data })
                  )}
                  disabled={quotationMutation.isLoading}
                >
                  Enregistrer comme brouillon
                </Button>
              ) : null}

              {quotationToEdit?.state === quotationStates.STATE_BILL ? (
                <Button
                  type="button"
                  onClick={form.handleSubmit((data) =>
                    onSubmit({ state: quotationStates.STATE_BILL, data })
                  )}
                  disabled={quotationMutation.isLoading}
                >
                  Enregistrer & régénérer la facture
                </Button>
              ) : quotationToEdit?.signedAt ? (
                <Button
                  type="button"
                  onClick={form.handleSubmit((data) => onSubmit({ state: undefined, data }))}
                  disabled={quotationMutation.isLoading}
                >
                  Modifier
                </Button>
              ) : isRecurrenceMasterSelected &&
                (!tasks || !dayjs(tasks[0]?.scheduledAt).isSame(dayjs(), "day")) ? (
                <Button
                  type="button"
                  onClick={() =>
                    openModal(
                      <ConfirmationModal
                        title="Valider le relevé"
                        isOpen={true}
                        onClose={closeModal}
                        onConfirm={form.handleSubmit((data) =>
                          onSubmit({ state: quotationStates.STATE_DRAFT, data })
                        )}
                      >
                        Êtes vous sûr de vouloir valider le relevé ? <br />
                        Des récurrences vont être programmées selon les options choisies.
                      </ConfirmationModal>
                    )
                  }
                  disabled={quotationMutation.isLoading}
                >
                  Enregistrer & programmer les récurrences
                </Button>
              ) : (
                <Button
                  type="button"
                  onClick={() =>
                    openModal(
                      <ConfirmationModal
                        title="Valider le relevé"
                        isOpen={true}
                        onClose={closeModal}
                        onConfirm={form.handleSubmit((data) => {
                          onSubmit({
                            state: quotationStates.STATE_SEND,
                            data
                          });
                        })}
                      >
                        {tasks &&
                        tasks.length > 0 &&
                        tasks[0]?.scheduledAt &&
                        dayjs(tasks[0]?.scheduledAt).isSame(dayjs(), "day") ? (
                          <>
                            Etes vous sûr de vouloir valider le relévé ?<br />
                            Un email et un sms de signature seront envoyés au client pour le premier
                            relevé, et les récurrences suivantes seront programmées selon les
                            options choisies.
                          </>
                        ) : (
                          <>
                            Etes vous sûr de vouloir valider le relévé ?<br />
                            Un email et un sms de signature seront envoyés au client.
                          </>
                        )}
                      </ConfirmationModal>
                    )
                  }
                  disabled={quotationMutation.isLoading}
                >
                  {tasks?.[0]?.scheduledAt && dayjs(tasks[0]?.scheduledAt).isSame(dayjs(), "day")
                    ? "Enregistrer, envoyer au client & programmer les récurrences"
                    : "Enregistrer & envoyer au client"}
                </Button>
              )}

              {isAdmin && quotationToEdit?.id ? (
                <Button
                  type="button"
                  onClick={form.handleSubmit((data) => onSubmit({ state: undefined, data }))}
                  disabled={quotationMutation.isLoading}
                >
                  Enregistrer (sans changement de statut)
                </Button>
              ) : null}
            </div>
          </>
        ) : null}
      </form>
      {renderModal()}
    </>
  );
};
