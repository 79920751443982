import { IonContent, IonPage } from "@ionic/react";

export const LegalNoticePage = () => {
  return (
    <IonPage>
      <IonContent className="container">
        <div className="mx-auto max-w-5xl">
          <h1 className="text-center text-lg font-bold uppercase text-grey-700">
            Mentions légales
          </h1>

          <section className="mt-6 text-justify text-base font-medium text-grey-700">
            <h2 className="mb-4 mt-6 text-base font-bold">Responsable du site :</h2>
            <p className="mt-2 leading-snug">
              HEXA COOP <br />
              SIRET 83422509600025 <br />
              Siège social : 17 rue de Sarliève 63800 Cournon d&apos;Auvergne <br />
              Téléphone : 04 15 40 00 00 <br />
              contact@hexa-coop.com
            </p>
          </section>

          <section className="mt-6 text-justify text-base font-medium text-grey-700">
            <h2 className="mb-4 mt-6 text-base font-bold">Activité :</h2>
            <p className="mt-2 leading-snug">
              Hexa Coop est une coopérative nationale de services à la personne agréée. Ce statut
              vous permet de bénéficier de crédits d’impôts sur les prestations réalisées par nos
              coopérateurs.
            </p>
          </section>

          <section className="mt-6 text-justify text-base font-medium text-grey-700">
            <h2 className="mb-4 mt-6 text-base font-bold">Hébergement du site :</h2>
            <p className="mt-2 leading-snug">
              PlanetHoster inc. <br />
              4416 Louis B. Mayer <br />
              Laval, Québec H7P 0G1 <br />
              Canada FR (Nº Vert): 0 805 080 426 <br />
              www.planethoster.com
            </p>
          </section>

          <section className="mt-6 text-justify text-base font-medium text-grey-700">
            <h2 className="mb-4 mt-6 text-base font-bold">Propriété – droit de reproduction :</h2>
            <p className="mt-2 leading-snug">
              Le présent Site est la propriété exclusive d’Hexa Coop. Toute représentation totale ou
              partielle des informations contenues, par quelque procédé que ce soit, sans
              l’autorisation expresse et préalable de son propriétaire est interdite et
              constituerait une contrefaçon sanctionnée par les articles L 335-2 et suivants du Code
              de la Propriété Intellectuelle. <br />
              De même, toute autorisation pour la mise en place d’un lien hypertexte doit
              préalablement être obtenue auprès d’Hexa Coop.
            </p>
          </section>

          <section className="mt-6 text-justify text-base font-medium text-grey-700">
            <h2 className="mb-4 mt-6 text-base font-bold">Propriété – Confidentialité :</h2>
            <p className="mt-2 leading-snug">
              Le présent support et les données, informations et documents qu’il contient sont la
              propriété exclusive d’HEXA COOP, protégés par le droit d’auteur (Code de la propriété
              intellectuelle), le savoir-faire et le secret des affaires (L. 151-1 du Code de
              commerce). <br />
              Toute extraction, utilisation, divulgation, représentation et/ou reproduction totale
              ou partielle des informations contenues, par quelque procédé que ce soit, sans
              l’autorisation expresse et préalable d’HEXA COOP est interdite et constituerait une
              contrefaçon sanctionnée par les articles L. 335-2 et suivants du Code de la Propriété
              Intellectuelle ou une divulgation illicite d’informations confidentielles ainsi que
              par les articles 314-1 et suivants et 323-3 du Code pénal.
            </p>
          </section>
        </div>
      </IonContent>
    </IonPage>
  );
};
