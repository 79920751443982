import { IonContent, IonPage } from "@ionic/react";
import { useQuery } from "@tanstack/react-query";

import { getModules } from "~/api/module";
import { HtmlParser } from "~/components/HtmlParser";
import { Button } from "~/components/ui/Button/Button";
import { LoadingSpinner } from "~/components/ui/LoadingSpinner";

export const RecommendationHome = () => {
  const { data, isLoading } = useQuery({
    queryKey: ["all-modules"],
    queryFn: () => getModules({})
  });

  return (
    <IonPage>
      <IonContent className="container">
        <h1 className="mb-12 mt-8 text-center">Choisissez la nature de votre recommandation</h1>
        {isLoading ? (
          <LoadingSpinner fullPage />
        ) : (
          <div className="flex flex-wrap items-stretch justify-center gap-16">
            {data?.map((module) => (
              <div
                key={module?.id}
                className="flex max-w-md flex-col items-center justify-between rounded-lg border border-primary p-6 text-center text-primary"
              >
                <h2 className="mb-6 text-xl font-bold">{module?.title}</h2>
                <HtmlParser className="mb-8 text-grey-800" html={module?.description1 || ""} />

                <Button
                  routerLink={`/recommendation/${module?.id}`}
                  size="large"
                  className="font-bold uppercase"
                >
                  Accéder
                </Button>
              </div>
            ))}
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};
