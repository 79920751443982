import type { ChangeEvent } from "react";

import { useEffect, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Controller, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router";

import { legalForms, userRoles } from "~/config/api-constants";
import { queryKeys } from "~/config/query-keys-constants";
import { removeSpaces, transformDateTimeToDate } from "~/utils/misc";
import { useUIContext } from "~/contexts/uiContext";
import { useDebounce } from "~/hooks/useDebounce";
import { handleApiError } from "~/services/errors";
import { handleIbanChange } from "~/services/iban";
import { handleSiretChange } from "~/services/siret";
import { getCooperatorCategories } from "~/api/cooperator-categories";
import { getGouvAddresses } from "~/api/gouv-addresses";
import { getJobs } from "~/api/job";
import {
  createCooperator,
  getDuplicatedCooperatorRibCount,
  getUser,
  getUsers,
  updateCooperator
} from "~/api/user";
import { Button } from "~/components/ui/Button/Button";
import { FormComboBox } from "~/components/ui/FormComboBox";
import { FormDatePicker } from "~/components/ui/FormDatePicker";
import { FormInput } from "~/components/ui/FormInput";
import { FormRow } from "~/components/ui/FormRow";
import { FormSelect } from "~/components/ui/FormSelect";
import { FormTitle } from "~/components/ui/FormTitle";
import { LoadingSpinner } from "~/components/ui/LoadingSpinner";
import { CooperatorSchema } from "~/schemas/cooperator.schema";

export const CreateEditCooperator = ({ editMode = false }: { editMode?: boolean }) => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const { user_id: userId } = useParams<{ user_id?: string }>();
  const { toast } = useUIContext();

  // Get the cooperator when form is in edit mode
  const { data: userData, isLoading: isUserLoading } = useQuery({
    queryKey: [queryKeys.users, { id: userId ? parseInt(userId) : null }],
    queryFn: () => getUser({ id: userId ? parseInt(userId) : null }),
    enabled: !!userId
  });

  // Jobs list for job selection
  const [jobsSearch, setJobsSearch] = useState("");
  const jobsQueryParams = { itemsPerPage: 1500 };
  const { data: jobsData, isLoading: isJobsSearchLoading } = useQuery({
    queryKey: [queryKeys.jobs, jobsQueryParams],
    queryFn: () => getJobs(jobsQueryParams)
  });
  const filteredJobs =
    jobsData && jobsData.length > 0
      ? jobsData.filter((job) => job?.name?.includes(jobsSearch))
      : [];

  // Commercials list for commercial selection
  const [commercialsSearch, setCommercialsSearch] = useState("");
  const debouncedCommercialsSearch = useDebounce<string>(commercialsSearch, 500);
  const commercialsQueryParams = {
    role: userRoles.ROLE_COMMERCIAL,
    searchName: debouncedCommercialsSearch,
    sort: { key: "lastname", value: "ASC" }
  } as const;
  const { data: commercialsData, isLoading: isCommercialsLoading } = useQuery({
    queryKey: [queryKeys.users, commercialsQueryParams],
    queryFn: () => getUsers({ ...commercialsQueryParams })
    // enabled: isAdmin
  });

  // Addresses list for address selection
  const [addressSearch, setAddressSearch] = useState("");
  const debouncedAddressSearch = useDebounce<string>(addressSearch, 500);
  const { data: addressesData, isLoading: isAddressesLoading } = useQuery({
    queryKey: [queryKeys.addresses, { search: debouncedAddressSearch }],
    queryFn: () => getGouvAddresses({ search: debouncedAddressSearch }),
    enabled: debouncedAddressSearch.length > 3
  });

  // Categories list for category selection
  const categoriesQueryParams = { itemsPerPage: 1500 };
  const { data: categoriesData } = useQuery({
    queryKey: [queryKeys.cooperatorCategories, categoriesQueryParams],
    queryFn: () => getCooperatorCategories(categoriesQueryParams)
  });

  //Validator iban
  // const [errorMessage, setErrorMessage] = useState("");

  // Set first search values for comboboxes
  useEffect(() => {
    if (userData) {
      setJobsSearch(userData.job?.name ? userData.job.name : "");
      setCommercialsSearch(userData.commercial?.lastname ? userData.commercial.lastname : "");
      setAddressSearch(userData.address || "");
    }
  }, [userData]);

  // Customer mutation
  const cooperatorMutation = useMutation({
    mutationFn: (data: CooperatorSchema) =>
      editMode
        ? updateCooperator({ userId: userId ? parseInt(userId) : null, data })
        : createCooperator({ data }),
    async onSuccess(data) {
      let duplicatedCooperatorRibCount = 0;
      try {
        const response = await queryClient.fetchQuery({
          queryKey: [queryKeys.users, { iban: data.iban }],
          queryFn: () => (data?.id ? getDuplicatedCooperatorRibCount({ userId: data.id }) : null)
        });
        duplicatedCooperatorRibCount = response || 0;
      } catch (error) {
        handleApiError(error, toast);
      }
      queryClient.invalidateQueries({ queryKey: [queryKeys.users] });

      const toastMessageSuffix =
        duplicatedCooperatorRibCount && duplicatedCooperatorRibCount > 1
          ? ` Attention, ce RIB est déjà utilisé par ${duplicatedCooperatorRibCount} coopérateur${
              duplicatedCooperatorRibCount > 2 ? "s" : ""
            }.`
          : "";
      const toastType =
        duplicatedCooperatorRibCount && duplicatedCooperatorRibCount > 1 ? "warning" : "success";

      toast(
        editMode
          ? `Le coopérateur a été modifié avec succès.${toastMessageSuffix}`
          : `Le coopérateur a été créé avec succès.${toastMessageSuffix}`,
        toastType
      );

      history.push("/administration/cooperators");
    },
    onError(error) {
      handleApiError(error, toast);
    }
  });

  // Define form
  const form = useForm<CooperatorSchema>({
    resolver: zodResolver(CooperatorSchema),
    values: {
      lastname: userData?.lastname || "",
      firstname: userData?.firstname || "",
      job: userData?.job?.id.toString() || "",
      companyName: userData?.companyName || null,
      // logo: null,
      legalForm: userData?.legalForm || "",
      siret: userData?.siret || "",
      tvaNumber: userData?.tvaNumber || "",
      email: userData?.email || "",
      emailContact: userData?.emailContact || null,
      mobileNumber: userData?.mobileNumber || null,
      fixeNumber: userData?.fixeNumber || null,
      address: userData?.address || "",
      zipcode: userData?.zipcode || "",
      city: userData?.city || "",
      nameSMS: userData?.nameSMS || null,
      recommandation: userData?.recommandation || null,
      shareCapital: userData?.shareCapital || null,
      cityAndNumberRCSRCM: userData?.cityAndNumberRCSRCM || null,
      professionalLiabilityDueDateAt:
        transformDateTimeToDate(userData?.professionalLiabilityDueDateAt) || null,
      // defaultPrestation: userData?.defaultPrestation || null, (API Missing)
      categoriesCooperatorUser: userData?.categoriesCooperatorUser?.map((c) =>
        c.category.id.toString()
      ) || [""],
      commercial: userData?.commercial?.id.toString() || "",
      hasTva: userData?.hasTva || false,
      tvaRate: userData?.tvaRate || null,
      accountNumber: userData?.accountNumber || null,
      accountNumberCoopAsCustomer: userData?.accountNumberCoopAsCustomer || null,
      isActive: userData?.isActive || false,
      isRecoverable: userData?.isRecoverable || true,
      hasRecurrence: userData?.hasRecurrence || false,
      canAvanceImmediate: userData?.canAvanceImmediate || false,
      hasAlertsActivated: userData?.hasAlertsActivated || true,
      hasAutoDeletionQuotation: userData?.hasAutoDeletionQuotation || true,
      iban: userData?.ibanCountryCode
        ? `${userData.ibanCountryCode || ""}${userData.ibanControlKey || ""}${
            userData.ibanBankCode || ""
          }${userData.ibanGuichetCode || ""}${userData.ibanAccountNumber || ""}${
            userData.ibanRIBKey || ""
          }`
        : "",
      bic: userData?.bicBankCode
        ? `${userData.bicBankCode || ""}${userData.bicCountryCode || ""}${
            userData.bicLocalisationCode || ""
          }${userData.bicOptionnalCode || ""}`
        : ""
    }
  });

  const onSubmit = (data: CooperatorSchema) => {
    const { iban, bic, ...dataRest } = data;
    const formatedData =
      iban && bic
        ? {
            ...dataRest,
            ibanCountryCode: iban.slice(0, 2),
            ibanControlKey: iban.slice(2, 4),
            ibanBankCode: iban.slice(4, 9),
            ibanGuichetCode: iban.slice(9, 14),
            ibanAccountNumber: iban.slice(14, 25),
            ibanRIBKey: iban.slice(25, 27),
            bicBankCode: bic.slice(0, 4),
            bicCountryCode: bic.slice(4, 6),
            bicLocalisationCode: bic.slice(6, 8),
            bicOptionnalCode: bic.slice(8, 11) || ""
          }
        : dataRest;
    const cleanData = Object.fromEntries(
      Object.entries(formatedData).filter(([_, v]) => v !== null)
    ) as CooperatorSchema;

    cooperatorMutation.mutate(cleanData);
  };

  if (!!userId && isUserLoading) {
    return <LoadingSpinner size="lg" fullPage />;
  }

  if (!!userId && !userData) {
    return <p className="text-center">Le coopérateur à éditer est introuvable.</p>;
  }

  return (
    <>
      <h2 className="mt-6">
        {editMode
          ? `Édition d'un coopérateur : ${userData?.lastname} ${userData?.firstname}`
          : "Création d'un coopérateur"}
      </h2>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <FormTitle as="h3">Informations personnelles</FormTitle>

        <FormRow>
          <Controller
            name="job"
            control={form.control}
            render={({ field: { onChange, onBlur, ref } }) => {
              return (
                <FormComboBox
                  label="Métier"
                  onBlur={onBlur}
                  onChange={onChange}
                  onSearchChange={setJobsSearch}
                  options={filteredJobs.map((job) => ({
                    id: job?.id || 0,
                    label: job?.name || "",
                    value: job?.id?.toString() || ""
                  }))}
                  defaultOption={
                    userData?.job?.id
                      ? {
                          id: userData.job.id || 0,
                          label: userData.job.name || "",
                          value: userData.job.id.toString()
                        }
                      : null
                  }
                  isLoading={isJobsSearchLoading}
                  errorMessage={form.formState.errors.job?.message}
                  ref={ref}
                />
              );
            }}
          />
          <FormInput
            label="Nom"
            errorMessage={form.formState.errors.lastname?.message}
            {...form.register("lastname")}
          />
          <FormInput
            label="Prénom"
            errorMessage={form.formState.errors.firstname?.message}
            {...form.register("firstname")}
          />
        </FormRow>
        <FormRow>
          <FormInput
            label="Email"
            errorMessage={form.formState.errors.email?.message}
            {...form.register("email")}
          />
          <FormInput
            label="Email de contact"
            optional
            errorMessage={form.formState.errors.emailContact?.message}
            {...form.register("emailContact")}
          />
        </FormRow>
        <FormRow>
          <Controller
            name="address"
            control={form.control}
            render={({ field: { onChange, onBlur, ref } }) => {
              return (
                <FormComboBox
                  label="Adresse"
                  onBlur={onBlur}
                  onChange={(value) => {
                    const address = addressesData?.find((address) => address.id === value);
                    if (!address) return onChange(value || "");
                    form.setValue("zipcode", address.postcode || "", { shouldValidate: true });
                    form.setValue("city", address.district || address.city || "", {
                      shouldValidate: true
                    });
                    form.trigger("address");
                    onChange(
                      address.name ||
                        (address.housenumber && address.street
                          ? `${address.housenumber} ${address.street}`
                          : "")
                    );
                  }}
                  onSearchChange={setAddressSearch}
                  allowCustomValue={true}
                  options={
                    addressesData?.map((address) => ({
                      id: address.id || 0,
                      value: address.id || "",
                      label: address.label || "",
                      labelInInput: address.name || `${address.housenumber} ${address.street}`
                    })) || []
                  }
                  defaultOption={
                    userData?.address
                      ? {
                          id: userData.address,
                          value: userData.address,
                          label: userData.address
                        }
                      : null
                  }
                  isLoading={isAddressesLoading}
                  infoMessage="Selectionnez une des propositions afin de correspondre aux données de l'URSSAF."
                  errorMessage={form.formState.errors.address?.message}
                  ref={ref}
                />
              );
            }}
          />
          <FormInput
            label="Code postal"
            errorMessage={form.formState.errors.zipcode?.message}
            {...form.register("zipcode")}
          />
          <FormInput
            label="Ville"
            errorMessage={form.formState.errors.city?.message}
            {...form.register("city")}
          />
        </FormRow>
        <FormRow>
          <FormInput
            label="Nom expéditeur SMS"
            optional
            infoMessage="Max 11 caractères, pas de caractères spéciaux ni d'espace"
            errorMessage={form.formState.errors.nameSMS?.message}
            {...form.register("nameSMS")}
          />
          <FormInput
            label="N° de mobile"
            optional
            errorMessage={form.formState.errors.mobileNumber?.message}
            {...form.register("mobileNumber")}
          />
          <FormInput
            label="N° de fixe"
            optional
            errorMessage={form.formState.errors.fixeNumber?.message}
            {...form.register("fixeNumber")}
          />
        </FormRow>
        <FormRow>
          <FormInput
            label="Recommandation"
            optional
            infoMessage="Max 11 caractères, pas de caractères spéciaux ni d'espace"
            errorMessage={form.formState.errors.recommandation?.message}
            {...form.register("recommandation")}
          />
        </FormRow>

        <FormTitle as="h3">Informations de la société</FormTitle>
        <FormRow>
          <FormInput
            label="Logo de la société"
            type="file"
            optional
            infoMessage="Attention à la taille du logo envoyé"
            errorMessage={
              typeof form.formState.errors.logo?.message !== "string"
                ? form.formState.errors.logo?.message?.toString()
                : form.formState.errors.logo.message
            }
            {...form.register("logo")}
          />
          {userData?.logoUrl ? (
            <img src={userData.logoUrl} alt="Logo de la société" className="h-24 w-auto p-0" />
          ) : null}
        </FormRow>
        <FormRow>
          <FormSelect
            label="Forme juridique"
            errorMessage={form.formState.errors.legalForm?.message}
            {...form.register("legalForm")}
          >
            {Object.values(legalForms).map((legalForm) => (
              <option key={legalForm} value={legalForm}>
                {legalForm}
              </option>
            ))}
          </FormSelect>
          <FormInput
            label="Nom de la société"
            optional
            errorMessage={form.formState.errors.companyName?.message}
            {...form.register("companyName")}
          />
          <FormInput
            label="SIRET"
            errorMessage={form.formState.errors.siret?.message}
            {...form.register("siret", {
              onChange: handleSiretChange,
              setValueAs: (v) => removeSpaces(v)
            })}
          />
        </FormRow>
        <FormRow>
          <FormInput
            label="Capital Social"
            errorMessage={form.formState.errors.shareCapital?.message}
            {...form.register("shareCapital")}
          />
          <FormInput
            label="Ville + n° RCS / RM"
            errorMessage={form.formState.errors.cityAndNumberRCSRCM?.message}
            {...form.register("cityAndNumberRCSRCM")}
          />
          <FormDatePicker
            label="Date d'expiration RC Pro"
            errorMessage={form.formState.errors.professionalLiabilityDueDateAt?.message}
            {...form.register("professionalLiabilityDueDateAt")}
          />
        </FormRow>
        <FormRow>
          <FormSelect
            label="Assujetti à la TVA"
            {...form.register("hasTva", { setValueAs: (v) => v === "true" || v === true })}
          >
            <option value="true">Oui</option>
            <option value="false">Non</option>
          </FormSelect>
          <FormInput label="Taux de TVA" optional {...form.register("tvaRate")} />
          <FormInput
            label="Numéro de TVA"
            optional
            errorMessage={form.formState.errors.tvaNumber?.message}
            {...form.register("tvaNumber")}
          />
        </FormRow>

        <FormTitle as="h3">Informations HEXA-COOP</FormTitle>
        <FormRow>
          <FormSelect
            label="Prestations associées au coopérateur"
            multiple
            errorMessage={form.formState.errors.categoriesCooperatorUser?.message}
            {...form.register("categoriesCooperatorUser")}
          >
            {categoriesData
              ?.filter((cat) => {
                if (form.watch("zipcode").startsWith("97")) {
                  return cat.isDomTom;
                } else {
                  return !cat.isDomTom;
                }
              })
              .map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
          </FormSelect>
          <Controller
            name="commercial"
            control={form.control}
            render={({ field: { onChange, onBlur, ref } }) => {
              return (
                <FormComboBox
                  label="Commercial associé"
                  onBlur={onBlur}
                  onChange={onChange}
                  onSearchChange={setCommercialsSearch}
                  options={
                    commercialsData?.map((commercial) => ({
                      id: commercial?.id || 0,
                      label: `${commercial?.lastname || ""} ${commercial?.firstname || ""}`,
                      value: commercial?.id?.toString() || ""
                    })) || []
                  }
                  defaultOption={
                    userData?.commercial?.id
                      ? {
                          id: userData.commercial.id,
                          label: `${userData.commercial.lastname} ${userData.commercial.firstname}`,
                          value: userData.commercial.id.toString()
                        }
                      : null
                  }
                  isLoading={isCommercialsLoading}
                  infoMessage="Commercial auquel est associé le coopérateur."
                  errorMessage={form.formState.errors.commercial?.message}
                  ref={ref}
                />
              );
            }}
          />
        </FormRow>
        <FormRow>
          <FormInput label="Code tiers fournisseur" optional {...form.register("accountNumber")} />
          <FormInput
            label="Code tiers client"
            optional
            {...form.register("accountNumberCoopAsCustomer")}
          />
        </FormRow>

        <FormTitle as="h3">Fonctionnalités HEXA-COOP</FormTitle>
        <FormRow>
          <FormSelect
            label="Compte activé"
            {...form.register("isActive", { setValueAs: (v) => v === "true" || v === true })}
          >
            <option value="false">Non</option>
            <option value="true">Oui</option>
          </FormSelect>
          <FormSelect
            label="Système de relance"
            {...form.register("isRecoverable", { setValueAs: (v) => v === "true" || v === true })}
          >
            <option value="true">Oui</option>
            <option value="false">Non</option>
          </FormSelect>
          <FormSelect
            label="Récurrence des relevés"
            {...form.register("hasRecurrence", { setValueAs: (v) => v === "true" || v === true })}
          >
            <option value="false">Non</option>
            <option value="true">Oui</option>
          </FormSelect>
          <FormSelect
            label="Autoriser l'avance immédiate sur ses clients"
            infoMessage="Permet d'autoriser l'avance immédiate pour ce coopérateur."
            {...form.register("canAvanceImmediate", {
              setValueAs: (v) => v === "true" || v === true
            })}
          >
            <option value="true">Oui</option>
            <option value="false">Non</option>
          </FormSelect>
          <FormSelect
            label="Vérifications fraudes AI activées sur ses relevés"
            {...form.register("hasAlertsActivated", {
              setValueAs: (v) => v === "true" || v === true
            })}
          >
            <option value="true">Oui</option>
            <option value="false">Non</option>
          </FormSelect>
          <FormSelect
            label="Suppression auto des relevés"
            {...form.register("hasAutoDeletionQuotation", {
              setValueAs: (v) => v === "true" || v === true
            })}
          >
            <option value="true">Oui</option>
            <option value="false">Non</option>
          </FormSelect>
        </FormRow>

        <FormTitle as="h3">Coordonnées bancaires</FormTitle>
        <FormRow>
          <FormInput
            label="IBAN"
            placeholder="FR76 2852 1966 1423 3450 8845 009"
            errorMessage={form.formState.errors.iban?.message}
            {...form.register("iban", {
              onChange: (e) => {
                handleIbanChange(e);
                form.trigger("iban").then((isValid) => {
                  if (isValid) {
                    form.setFocus("bic");
                  }
                });
              },
              setValueAs: (v) => removeSpaces(v?.toUpperCase())
            })}
          />

          <FormInput
            label="BIC"
            placeholder="CMCIFRMM"
            errorMessage={form.formState.errors.bic?.message}
            {...form.register("bic", {
              setValueAs: (v) => v?.toUpperCase() || "",
              onChange: (e: ChangeEvent<HTMLInputElement>) => {
                e.target.value = e.target.value.toUpperCase();
              }
            })}
          />
        </FormRow>

        <div className="mt-12 flex justify-end gap-4 border-t border-grey-200 pt-6">
          <Button fill="clear" onClick={() => history.goBack()}>
            Annuler
          </Button>
          <Button type="submit" disabled={cooperatorMutation.isLoading}>
            {editMode ? "Modifier le coopérateur" : "Créer le coopérateur"}
          </Button>
        </div>
      </form>
    </>
  );
};
