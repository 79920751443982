import type { ReactNode } from "react";

import { useState } from "react";
import { createPortal } from "react-dom";

export const useModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalNode, setModalNode] = useState<ReactNode | null>(null);

  const openModal = (modalNode: ReactNode) => {
    setModalNode(modalNode);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalNode(null);
  };

  const renderModal = () => createPortal(modalNode, document.body);

  return { openModal, closeModal, renderModal, isModalOpen };
};
