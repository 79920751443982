import { IonContent, IonPage } from "@ionic/react";
import { Redirect, Switch } from "react-router";

import { useAuthContext } from "~/contexts/authContext";
import { AdministrationAdmins } from "~/pages/Administration/AdministrationAdmins";
import { AdministrationCommercials } from "~/pages/Administration/AdministrationCommercials";
import { AdministrationConfig } from "~/pages/Administration/AdministrationConfig";
import { AdministrationCooperators } from "~/pages/Administration/AdministrationCooperators";
import { AdministrationCooperatorsGeolocalisation } from "~/pages/Administration/AdministrationCooperatorsGeolocalisation";
import { AdministrationEmails } from "~/pages/Administration/AdministrationEmails";
import { AdministrationExports } from "~/pages/Administration/AdministrationExports";
import { AdministrationJobs } from "~/pages/Administration/AdministrationJobs";
import { AdministrationModules } from "~/pages/Administration/AdministrationModules";
import { AdministrationPayments } from "~/pages/Administration/AdministrationPayments";
import { AdministrationSms } from "~/pages/Administration/AdministrationSms";
import { CreateEditAdmin } from "~/pages/Administration/CreateEditAdmin";
import { CreateEditCommercial } from "~/pages/Administration/CreateEditCommercial";
import { CreateEditConfig } from "~/pages/Administration/CreateEditConfig";
import { CreateEditCooperator } from "~/pages/Administration/CreateEditCooperator";
import { CreateEditEmail } from "~/pages/Administration/CreateEditEmail";
import { CreateEditJob } from "~/pages/Administration/CreateEditJob";
import { CreateEditModule } from "~/pages/Administration/CreateEditModule";
import { CreateEditSms } from "~/pages/Administration/CreateEditSms";
import { SubNavigation } from "~/components/SubNavigation";
import { ProtectedRoute } from "~/Router";

export const AdministrationLayout = () => {
  const { isAdmin } = useAuthContext();

  return (
    <IonPage>
      <IonContent className="container">
        <SubNavigation
          links={[
            {
              label: "Admin",
              to: "/administration/administrators"
            },
            {
              label: "Coop",
              to: "/administration/cooperators"
            },
            {
              label: "Commerciaux",
              to: "/administration/commercials"
            },
            {
              label: "Emails",
              to: "/administration/emails"
            },
            {
              label: "SMS",
              to: "/administration/sms"
            },
            {
              label: "Métiers",
              to: "/administration/jobs"
            },
            {
              label: "Configuration",
              to: "/administration/configurations"
            },
            {
              label: "Virements",
              to: "/administration/payments"
            },
            {
              label: "Exports",
              to: "/administration/exports"
            },
            {
              label: "Modules",
              to: "/administration/modules"
            }
          ]}
        />
        <Switch>
          <ProtectedRoute exact path="/administration/administrators" condition={isAdmin}>
            <AdministrationAdmins />
          </ProtectedRoute>
          <ProtectedRoute exact path="/administration/cooperators" condition={isAdmin}>
            <AdministrationCooperators />
          </ProtectedRoute>
          <ProtectedRoute exact path="/administration/commercials" condition={isAdmin}>
            <AdministrationCommercials />
          </ProtectedRoute>
          <ProtectedRoute exact path="/administration/emails" condition={isAdmin}>
            <AdministrationEmails />
          </ProtectedRoute>
          <ProtectedRoute exact path="/administration/sms" condition={isAdmin}>
            <AdministrationSms />
          </ProtectedRoute>
          <ProtectedRoute exact path="/administration/jobs" condition={isAdmin}>
            <AdministrationJobs />
          </ProtectedRoute>
          <ProtectedRoute exact path="/administration/configurations" condition={isAdmin}>
            <AdministrationConfig />
          </ProtectedRoute>
          <ProtectedRoute exact path="/administration/payments" condition={isAdmin}>
            <AdministrationPayments />
          </ProtectedRoute>
          <ProtectedRoute exact path="/administration/exports" condition={isAdmin}>
            <AdministrationExports />
          </ProtectedRoute>
          <ProtectedRoute exact path="/administration/modules" condition={isAdmin}>
            <AdministrationModules />
          </ProtectedRoute>

          <ProtectedRoute exact path="/administration/administrators/create" condition={isAdmin}>
            <CreateEditAdmin />
          </ProtectedRoute>
          <ProtectedRoute
            exact
            path="/administration/administrators/edit/:user_id"
            condition={isAdmin}
          >
            <CreateEditAdmin editMode />
          </ProtectedRoute>

          <ProtectedRoute exact path="/administration/cooperators/create" condition={isAdmin}>
            <CreateEditCooperator />
          </ProtectedRoute>
          <ProtectedRoute
            exact
            path="/administration/cooperators/edit/:user_id"
            condition={isAdmin}
          >
            <CreateEditCooperator editMode />
          </ProtectedRoute>

          <ProtectedRoute exact path="/administration/cooperators/geolocation" condition={isAdmin}>
            <AdministrationCooperatorsGeolocalisation />
          </ProtectedRoute>

          <ProtectedRoute exact path="/administration/commercials/create" condition={isAdmin}>
            <CreateEditCommercial />
          </ProtectedRoute>
          <ProtectedRoute
            exact
            path="/administration/commercials/edit/:user_id"
            condition={isAdmin}
          >
            <CreateEditCommercial editMode />
          </ProtectedRoute>

          <ProtectedRoute exact path="/administration/jobs/create" condition={isAdmin}>
            <CreateEditJob />
          </ProtectedRoute>
          <ProtectedRoute exact path="/administration/jobs/edit/:job_id" condition={isAdmin}>
            <CreateEditJob editMode />
          </ProtectedRoute>

          {/* <ProtectedRoute exact path="/administration/sms/create" condition={isAdmin}>
            <CreateEditSms />
          </ProtectedRoute> */}
          <ProtectedRoute exact path="/administration/sms/edit/:sms_id" condition={isAdmin}>
            <CreateEditSms editMode />
          </ProtectedRoute>

          {/* <ProtectedRoute exact path="/administration/emails/create" condition={isAdmin}>
            <CreateEditEmail />
          </ProtectedRoute> */}
          <ProtectedRoute exact path="/administration/emails/edit/:email_id" condition={isAdmin}>
            <CreateEditEmail editMode />
          </ProtectedRoute>

          {/* <ProtectedRoute exact path="/administration/configurations/create" condition={isAdmin}>
            <CreateEditConfig />
          </ProtectedRoute> */}
          <ProtectedRoute
            exact
            path="/administration/configurations/edit/:config_id"
            condition={isAdmin}
          >
            <CreateEditConfig editMode />
          </ProtectedRoute>

          {/* <ProtectedRoute exact path="/administration/modules/create" condition={isAdmin}>
            <CreateEditModule />
          </ProtectedRoute> */}
          <ProtectedRoute exact path="/administration/modules/edit/:module_id" condition={isAdmin}>
            <CreateEditModule editMode />
          </ProtectedRoute>

          <Redirect to={"/administration/administrators"} />
        </Switch>
      </IonContent>
    </IonPage>
  );
};
