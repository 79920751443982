import { z } from "zod";

export const FinancerSchema = z
  .object({
    isActive: z.boolean().optional().nullable(),
    lastname: z.string().min(1, "Veuillez renseigner un nom."),
    firstname: z.string().optional().nullable(),
    email: z.string().min(1, "Veuillez renseigner un email."),
    mobileNumber: z
      .string()
      .min(1, "Veuillez renseigner un numéro de téléphone mobile.")
      .regex(
        /^(\+33|\+330|0)[67]([-. ]?[0-9]{2}){4}$/,
        "Veuillez renseigner un numéro de téléphone mobile français valide ou laisser ce champ vide."
      )
      .transform((value) => value.replace(/[-. ]/g, "")),
    fixeNumber: z
      .string()
      .regex(
        /^(\+33|\+330|0)[1-9]([-. ]?[0-9]{2}){4}$/,
        "Veuillez renseigner un numéro de téléphone fixe valide ou laisser ce champ vide."
      )
      .transform((value) => value.replace(/[-. ]/g, ""))
      .or(z.literal("").transform(() => null))
      .optional()
      .nullable(),
    address: z.string().min(1, "Veuillez renseigner une adresse."),
    zipcode: z.string().min(1, "Veuillez renseigner un code postal."),
    city: z.string().min(1, "Veuillez renseigner une ville."),
    isPro: z.boolean(),
    legalForm: z.string().optional().nullable(),
    siret: z
      .string()
      .length(14, "Le numéro SIRET doit contenir 14 chiffres.")
      .regex(/^\d+$/, "Le numéro SIRET doit contenir uniquement des chiffres.")
      .optional()
      .nullable(),
    tvaNumber: z.string().optional().nullable(),
    companyName: z.string().optional().nullable(),
    commercial: z.string().min(1, "Veuillez sélectionner un commercial.")
  })
  .superRefine((data, ctx) => {
    if (data.isPro) {
      if (!data.companyName || data.companyName === "") {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Veuillez renseigner le nom de l'entreprise.",
          path: ["companyName"]
        });
      }
    }
  });

export type FinancerSchema = z.infer<typeof FinancerSchema>;
