import { ArcElement, Chart as ChartJS, Legend, PieController, Tooltip } from "chart.js";
import { Pie } from "react-chartjs-2";

import useDetectMobile from "~/hooks/useDetectMobile";

// Register used modules
ChartJS.register(PieController, ArcElement, Legend, Tooltip);

// Custom positioner for tooltip
Tooltip.positioners.cursor = (_, coords) => coords;

interface Props {
  data: { label: string; value: number }[];
  maxSlices?: number;
}

const Spectral11 = [
  "#9e0142",
  "#d53e4f",
  "#f46d43",
  "#fdae61",
  "#fee08b",
  "#ffffbf",
  "#e6f598",
  "#abdda4",
  "#66c2a5",
  "#3288bd",
  "#5e4fa2"
];

export const PieChart = ({ data, maxSlices = 10 }: Props) => {
  const isMobile = useDetectMobile();
  const total = data.reduce((a, c) => (a += c.value), 0);

  const sortedData: { label: string | string[]; value: number }[] = data
    .map((row) => ({
      value: row.value,
      label: `${row.label} - ${((row.value * 100) / total).toFixed(1)}%`
    }))
    .sort((a, b) => b.value - a.value);

  const slices = sortedData.slice(0, maxSlices);
  const otherSlices = sortedData.slice(maxSlices);

  if (otherSlices.length) {
    slices.push({
      label: ["- Autres -", ...otherSlices.map((r) => r.label as string)],
      value: otherSlices.reduce((a, c) => (a += c.value), 0)
    });
  }

  return (
    <Pie
      options={{
        cutout: "50%",
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: isMobile ? "bottom" : "right"
          },
          tooltip: {
            position: "cursor",
            callbacks: {}
          }
        }
      }}
      data={{
        labels: slices.map((row) => row.label),
        datasets: [
          {
            data: slices.map((row) => row.value),
            backgroundColor: Spectral11
          }
        ]
      }}
    />
  );
};
