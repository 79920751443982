import type { ReactElement, ReactNode } from "react";

import { cloneElement, forwardRef, useCallback, useImperativeHandle, useRef } from "react";
import { IonIcon } from "@ionic/react";
import { closeOutline } from "ionicons/icons";

import { cn } from "~/utils/misc";
import { FormLabel } from "~/components/ui/FormLabel";

type FormTextAreaProps = {
  label?: string | null;
  icon?: ReactElement;
  onIconClick?: (e: any) => void;
  infoMessage?: string | null | undefined;
  warningMessage?: string | null | undefined;
  errorMessage?: string | null | undefined;
  optional?: boolean;
  clearable?: boolean;
  textareaClassName?: string;
  absoluteComponent?: ReactNode;
} & React.ComponentPropsWithRef<"textarea">;

export const FormTextArea = forwardRef<HTMLTextAreaElement, FormTextAreaProps>(
  function ForwardedRefInput(
    {
      label,
      infoMessage,
      warningMessage,
      errorMessage,
      optional,
      icon,
      clearable,
      className,
      textareaClassName,
      absoluteComponent,
      ...props
    },
    ref
  ) {
    const innerRef = useRef<HTMLTextAreaElement>(null);

    useImperativeHandle(ref, () => innerRef.current!);

    const clearInput = useCallback(() => {
      if (innerRef.current) {
        // Trigger onInput event when clearing input
        innerRef.current.value = "";
        const event = new Event("input", {
          bubbles: true,
          cancelable: true
        });
        innerRef.current.dispatchEvent(event);
        innerRef.current.focus();
      }
    }, [innerRef]);

    const LeftIconComponent = icon
      ? cloneElement(icon, {
          className:
            "pointer-events-none absolute left-4 top-1/2 h-5 w-5 -translate-y-1/2 text-grey-500"
        })
      : null;

    return (
      <div className={cn("flex flex-col", className)}>
        {label ? (
          <FormLabel htmlFor={props.id || props.name} optional={optional || false}>
            {label}
          </FormLabel>
        ) : null}

        <div className="group relative grow">
          {LeftIconComponent ? LeftIconComponent : null}

          <textarea
            id={props.id || props.name}
            className={cn(
              "block h-full w-full resize-none appearance-none rounded-lg border border-grey-200 py-2 text-sm transition-colors placeholder:text-grey-400 focus-visible:outline-primary disabled:bg-grey-100 group-hover:border-grey-300",
              errorMessage && "border-danger-700 text-danger-900 focus-visible:outline-danger-700",
              LeftIconComponent ? "pl-12" : "pl-3",
              clearable ? "pr-12" : "pr-3",
              textareaClassName
            )}
            ref={innerRef}
            {...props}
          />

          {clearable && innerRef.current?.value !== "" ? (
            <button
              type="button"
              className="group/clear absolute right-4 top-1/2 -translate-y-1/2 leading-[0px]"
              onClick={clearInput}
              aria-label="Clear input"
            >
              <IonIcon
                icon={closeOutline}
                className="h-5 w-5 text-grey-400 transition-colors group-hover/clear:text-grey-600"
              />
            </button>
          ) : null}

          {absoluteComponent ? absoluteComponent : null}
        </div>

        {errorMessage ? (
          <p className="mx-1 mt-1 whitespace-pre-line text-sm text-danger-700">{errorMessage}</p>
        ) : null}
        {warningMessage ? (
          <p className="mx-1 mt-1 whitespace-pre-line text-xs text-danger-700">{warningMessage}</p>
        ) : null}
        {infoMessage ? (
          <p className="mx-1 mt-1 whitespace-pre-line text-xs text-grey-500">{infoMessage}</p>
        ) : null}
      </div>
    );
  }
);
