import type { MembershipSchema } from "~/schemas/membership.schema";
import type { Membership } from "~/types/membership.types";
import type { DeepPartial } from "~/types/utils.types";

import { INFINITE_SCROLL_LIMIT } from "~/config/api";
import { membershipStates } from "~/config/api-constants";
import { api } from "./_config";

export const getMemberships = async ({
  states,
  state,
  invoicedAtAfter,
  invoicedAtBefore,
  createdAtAfter,
  createdAtBefore,
  cooperatorLastname,
  cooperatorCompanyName,
  id,
  idFacture,
  minTotalTTC,
  maxTotalTTC,
  hasSocialPart,
  sort,
  page,
  itemsPerPage
}: {
  states?: number[];
  state?: number;
  invoicedAtAfter?: string;
  invoicedAtBefore?: string;
  createdAtAfter?: string;
  createdAtBefore?: string;
  cooperatorLastname?: string;
  cooperatorCompanyName?: string;
  id?: string;
  idFacture?: string;
  minTotalTTC?: number;
  maxTotalTTC?: number;
  hasSocialPart?: string;
  sort?: { key: string; value: "ASC" | "DESC" };
  page?: number;
  itemsPerPage?: number;
}) => {
  const params = {
    ...(state !== undefined ? { state } : states ? { "state[]": states } : {}),
    ...(state && { state }),
    ...(invoicedAtAfter && { "invoicedAt[after]": invoicedAtAfter }),
    ...(invoicedAtBefore && { "invoicedAt[before]": invoicedAtBefore + "T23:59:59" }),
    ...(createdAtAfter && { "createdAt[after]": createdAtAfter }),
    ...(createdAtBefore && { "createdAt[before]": createdAtBefore + "T23:59:59" }),
    ...(cooperatorLastname && { "cooperator.lastname": cooperatorLastname }),
    ...(cooperatorCompanyName && { "cooperator.companyName": cooperatorCompanyName }),
    ...(id && { id }),
    ...(idFacture && { idFacture }),
    ...(minTotalTTC && { "totalTTC[gte]": minTotalTTC }),
    ...(maxTotalTTC && { "totalTTC[lte]": maxTotalTTC }),
    ...(hasSocialPart && {
      hasSocialPart: hasSocialPart === "true" ? true : hasSocialPart === "false" ? false : null
    }),
    ...(sort && { [`order[${sort.key}]`]: sort.value }),
    page,
    itemsPerPage: itemsPerPage ?? INFINITE_SCROLL_LIMIT
  };

  const response = await api.get<DeepPartial<Membership[]>>(`/api/admin/memberships`, { params });
  return response.data;
};

export const getMembership = async ({ id }: { id: Membership["id"] | null }) => {
  const response = await api.get<DeepPartial<Membership>>(`/api/admin/memberships/${id}`);
  return response.data;
};

export const createMembership = async ({ data }: { data: MembershipSchema }) => {
  const response = await api.post<Membership>(`/api/admin/memberships`, data);
  return response.data;
};

export const updateMembership = async ({
  id,
  data
}: {
  id: Membership["id"];
  data: MembershipSchema;
}) => {
  const response = await api.patch<Membership>(`/api/admin/memberships/${id}`, data);
  return response.data;
};

export const deleteMembership = async ({ id }: { id: Membership["id"] }) => {
  const response = await api.put<Membership>(`/api/admin/memberships/${id}`, {
    state: -1
  });
  return response.data;
};

export const renewMembership = async ({ id }: { id: Membership["id"] }) => {
  const response = await api.post(`/api/admin/memberships/${id}/renew`, {});
  return response.data;
};

export const passMembershipToBilling = async ({ id }: { id: Membership["id"] }) => {
  const response = await api.patch(`/api/admin/memberships/${id}`, {
    state: membershipStates.STATE_BILL
  });
  return response.data;
};

export const passMultipleMembershipsToBilling = async ({ ids }: { ids: Membership["id"][] }) => {
  const response = await api.patch(`/api/admin/memberships/to/facture`, {
    membership_id: ids
  });
  return response.data;
};

export const sendMembershipSignatureToCustomer = async ({ id }: { id: Membership["id"] }) => {
  const response = await api.post(`/api/admin/memberships/${id}/send/signature`, {});
  return response.data;
};

export const sendMembershipInvoiceToCustomer = async ({ id }: { id: Membership["id"] }) => {
  const response = await api.post(`/api/admin/memberships/${id}/send/invoice`, {});
  return response.data;
};
