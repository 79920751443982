import type { MembershipPaymentSchema } from "~/schemas/membership-payment.schema";
import type { MembershipPayment } from "~/types/membership-payment.types";
import type { Membership } from "~/types/membership.types";
import type { DeepPartial } from "~/types/utils.types";

import { INFINITE_SCROLL_LIMIT } from "~/config/api";
import { api } from "~/api/_config";

export const getMembershipPayments = async ({
  membershipId,
  page,
  itemsPerPage
}: {
  membershipId?: number;
  page?: number;
  itemsPerPage?: number;
}) => {
  const params = {
    ...(membershipId && { "membership.id": membershipId }),
    page,
    itemsPerPage: itemsPerPage ?? INFINITE_SCROLL_LIMIT,
    "amount[gt]": 0
  };

  const response = await api.get<DeepPartial<MembershipPayment[]>>(
    "/api/admin/membershipPayments",
    {
      params
    }
  );

  return response.data;
};

export const createMembershipPayment = async ({
  membershipId,
  data
}: {
  membershipId: Membership["id"];
  data: MembershipPaymentSchema;
}) => {
  const response = await api.post<DeepPartial<MembershipPayment>>("/api/admin/membershipPayments", {
    membership: membershipId,
    ...data
  });
  return response.data;
};

export const editMembershipPayment = async ({
  paymentId,
  data
}: {
  paymentId: MembershipPayment["id"];
  data: MembershipPaymentSchema;
}) => {
  const response = await api.put<DeepPartial<MembershipPayment>>(
    `/api/admin/membershipPayments/${paymentId}`,
    data
  );
  return response.data;
};

export const deleteMembershipPayment = async ({
  paymentId
}: {
  paymentId: MembershipPayment["id"];
}) => {
  const response = await api.delete(`/api/admin/membershipPayments/${paymentId}`);
  return response.data;
};
