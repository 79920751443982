import type { InseeCountry } from "~/types/insee-countries.types";
import type { DeepPartial } from "~/types/utils.types";

import { INFINITE_SCROLL_LIMIT } from "~/config/api";
import { api } from "~/api/_config";

export const getInseeCountries = async ({
  page,
  itemsPerPage
}: {
  page?: number;
  itemsPerPage?: number;
}) => {
  const response = await api.get<DeepPartial<InseeCountry[]>>("/api/admin/insee_countries", {
    params: { page, itemsPerPage: itemsPerPage ?? INFINITE_SCROLL_LIMIT }
  });
  return response.data;
};
