import type { AdvancedSearchParams } from "~/components/TableLayout";
import type { MainTableProps } from "~/components/ui/MainTable";

import { useMemo, useState } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import { documentAttachOutline, mailOutline } from "ionicons/icons";

import { queryKeys } from "~/config/query-keys-constants";
import { formatDisplayDate } from "~/utils/misc";
import { useAuthContext } from "~/contexts/authContext";
import { useFileDeliver } from "~/hooks/useFileDeliver";
import { useModal } from "~/hooks/useModal";
import { getNextPageParamFn } from "~/api/_config";
import { getAttestations } from "~/api/attestation";
import { AttestationsSearchForm } from "~/components/AdvancedSearchForms/Attestations/AttestationsSearchForm";
import { SendAttestationMailModal } from "~/components/Modals/SendAttestationMailModal";
import { TableLayout } from "~/components/TableLayout";
import { ExternalLink } from "~/components/ui/Link";

export const AttestationsPage = ({ year }: { year: string }) => {
  const { isAdmin } = useAuthContext();
  const { openFile } = useFileDeliver();
  const { openModal, closeModal, renderModal } = useModal();

  const [advancedSearchParams, setAdvancedSearchParams] = useState<AdvancedSearchParams>(null);

  const queryParams = {
    year,
    ...advancedSearchParams
  };

  const { data, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: [queryKeys.attestations, queryParams],
    queryFn: ({ pageParam }) => getAttestations({ ...queryParams, page: pageParam }),
    getNextPageParam: getNextPageParamFn
  });

  const groupedActions = [
    {
      id: "download-pdf",
      label: "Télécharger les pdfs des attestations sélectionnées",
      action: (ids: number[]) =>
        openFile({
          itemType: "multiple-attestations",
          fileType: "application/zip",
          itemParams: { attestationIds: ids }
        })
    }
  ];

  const tableData: MainTableProps["data"] = useMemo(
    () =>
      data?.pages.flat().map((attestation) => ({
        itemId: attestation?.id,
        checkboxConditions: [{ id: "download-pdf", condition: true }],
        actions: [
          {
            label: "Consulter l'attestation",
            icon: documentAttachOutline,
            color: "slate",
            action: () =>
              openFile({
                itemType: "attestation",
                itemParams: { id: attestation?.id }
              })
          },
          {
            label: "Envoyer l'attestation par mail",
            icon: mailOutline,
            color: "amber",
            action: () =>
              attestation?.id
                ? openModal(
                    <SendAttestationMailModal
                      attestationId={attestation.id}
                      isOpen={true}
                      onClose={closeModal}
                    />
                  )
                : null
          }
        ],
        columns: [
          {
            key: "sendAt",
            label: "Envoyée le",
            display: attestation?.sendAt ? formatDisplayDate(attestation.sendAt) : "",
            sort: attestation?.sendAt,
            search: attestation?.sendAt ? formatDisplayDate(attestation.sendAt) : ""
          },
          {
            key: "customer.companyName",
            label: "Société",
            display: attestation?.customer?.companyName
          },
          {
            key: "customer.lastname",
            label: "Nom",
            display: attestation?.customer?.lastname
          },
          {
            key: "customer.firstname",
            label: "Prénom",
            display: attestation?.customer?.firstname
          },
          {
            key: "customer.email",
            label: "Email",
            display: (
              <div className="flex flex-col gap-1">
                <ExternalLink to={`mailto:${attestation?.customer?.email}`}>
                  {attestation?.customer?.email}
                </ExternalLink>
                {attestation?.emailSent &&
                attestation.emailSent !== attestation?.customer?.email ? (
                  <>
                    <span className="mt-1">Attestation envoyée à :</span>
                    <ExternalLink to={`mailto:${attestation?.emailSent}`}>
                      {attestation?.emailSent}
                    </ExternalLink>
                  </>
                ) : null}
              </div>
            )
          },
          {
            key: "customer.address",
            label: "Adresse",
            display: (
              <div className="flex flex-col gap-1">
                <span>{attestation?.customer?.address}</span>
                <span>
                  {attestation?.customer?.zipcode} {attestation?.customer?.city}
                </span>
              </div>
            )
          },
          {
            key: "customer.isPro",
            label: "Type",
            display: attestation?.customer?.isPro ? "Professionnel" : "Particulier"
          },
          {
            key: "cooperator",
            label: "Coopérateur associé",
            display: (
              <div className="flex flex-col gap-1">
                {attestation?.cooperator?.companyName ? (
                  <span className="font-medium">{attestation.cooperator.companyName}</span>
                ) : null}
                {attestation?.cooperator?.lastname || attestation?.cooperator?.firstname ? (
                  <span>
                    {attestation.cooperator.lastname} {attestation.cooperator.firstname}
                  </span>
                ) : null}
              </div>
            ),
            sort: attestation?.cooperator?.lastname,
            search: `${attestation?.cooperator?.companyName} ${attestation?.cooperator?.lastname} ${attestation?.cooperator?.firstname}`,
            condition: isAdmin
          }
        ]
      })) || [],
    [data?.pages, isAdmin, openFile, openModal, closeModal]
  );

  return (
    <>
      <TableLayout
        data={tableData}
        isLoading={isFetching || isFetchingNextPage}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        AdvancedSearchForm={AttestationsSearchForm}
        advancedSearchParams={advancedSearchParams}
        handleAdvancedSearch={(data) => setAdvancedSearchParams(data)}
        groupedActions={groupedActions}
      />

      {renderModal()}
    </>
  );
};
