import { IonContent, IonPage } from "@ionic/react";
import { Redirect, Switch } from "react-router-dom";

import { useAuthContext } from "~/contexts/authContext";
import { CreateEditQuotation } from "~/pages/Quotations/CreateEditQuotation";
import { QuotationsBills } from "~/pages/Quotations/QuotationsBills";
import { QuotationsPending } from "~/pages/Quotations/QuotationsPending";
import { QuotationsRecovery } from "~/pages/Quotations/QuotationsRecovery";
import { QuotationRecoveryStatistics } from "~/pages/Quotations/QuotationsRecoveryStatistics";
import { QuotationsTasks } from "~/pages/Quotations/QuotationsTasks";
import { SubNavigation } from "~/components/SubNavigation";
import { ProtectedRoute } from "~/Router";

export const QuotationsLayout = () => {
  const { isAuth, isAdmin, currentUser } = useAuthContext();
  return (
    <IonPage>
      <IonContent className="container">
        <SubNavigation
          links={[
            {
              label: "Relevés en attente",
              to: "/quotations/waiting"
            },
            {
              label: "Factures clients",
              to: "/quotations/bills"
            },
            {
              label: "Système de relance",
              to: "/quotations/recovery",
              condition: isAdmin || currentUser?.isRecoverable
            },
            {
              label: "Relevés récurrents",
              to: "/quotations/tasks",
              condition: isAdmin || currentUser?.hasRecurrence
            }
          ]}
        />
        {/* <h1>Relevés de prestation</h1> */}
        <Switch>
          <ProtectedRoute exact path="/quotations/waiting" condition={isAuth}>
            <QuotationsPending />
          </ProtectedRoute>
          <ProtectedRoute exact path="/quotations/bills" condition={isAuth}>
            <QuotationsBills />
          </ProtectedRoute>
          <ProtectedRoute exact path="/quotations/recovery" condition={isAdmin}>
            <QuotationsRecovery />
          </ProtectedRoute>
          <ProtectedRoute exact path="/quotations/tasks" condition={isAuth}>
            <QuotationsTasks />
          </ProtectedRoute>

          <ProtectedRoute exact path="/quotations/create" condition={isAuth}>
            <CreateEditQuotation />
          </ProtectedRoute>
          <ProtectedRoute exact path="/quotations/edit/:quotation_id" condition={isAuth}>
            <CreateEditQuotation editMode />
          </ProtectedRoute>

          <ProtectedRoute exact path="/quotations/recovery/statistics" condition={isAuth}>
            <QuotationRecoveryStatistics />
          </ProtectedRoute>

          <Redirect to={"/quotations/waiting"} />
        </Switch>
      </IonContent>
    </IonPage>
  );
};
