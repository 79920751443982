import type { PropsWithChildren } from "react";
import type { DeepPartial } from "react-hook-form";
import type { ModalProps } from "~/components/ui/Modal/Modal";
import type { Quotation } from "~/types/quotation.types";

import { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { IonIcon } from "@ionic/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { createOutline, trashOutline } from "ionicons/icons";
import { useForm } from "react-hook-form";

import { paymentMethodCodes } from "~/config/api-constants";
import { queryKeys } from "~/config/query-keys-constants";
import { formatDisplayDate, formatMoney, roundMoney, transformDateTimeToDate } from "~/utils/misc";
import { useUIContext } from "~/contexts/uiContext";
import { useModal } from "~/hooks/useModal";
import { handleApiError } from "~/services/errors";
import { queryClient } from "~/api/_queryClient";
import { getPaymentMethods } from "~/api/payment-method";
import {
  createQuotationPayment,
  deleteQuotationPayment,
  editQuotationPayment,
  getQuotationPayments
} from "~/api/quotation-payment";
import { ConfirmationModal } from "~/components/Modals/ConfirmationModal";
import { Button } from "~/components/ui/Button/Button";
import { FormDatePicker } from "~/components/ui/FormDatePicker";
import { FormInput } from "~/components/ui/FormInput";
import { FormRow } from "~/components/ui/FormRow";
import { FormSelect } from "~/components/ui/FormSelect";
import { FormTextArea } from "~/components/ui/FormTextArea";
import { LoadingSpinner } from "~/components/ui/LoadingSpinner";
import { Modal } from "~/components/ui/Modal/Modal";
import { Table } from "~/components/ui/Table";
import { QuotationPaymentSchema } from "~/schemas/quotation-payment.schema";

interface QuotationPaymentsModalProps extends ModalProps {
  quotation: DeepPartial<Quotation>;
  withForm?: boolean;
  onSuccess?: () => void;
}

export const QuotationPaymentsModal = ({
  quotation,
  withForm = false,
  onSuccess,
  ...props
}: PropsWithChildren<QuotationPaymentsModalProps>) => {
  const { toast } = useUIContext();
  const { openModal, closeModal, renderModal } = useModal();

  const queryParams = {
    quotationId: quotation.id || 0,
    itemsPerPage: 100
  };

  const { data: payments, isLoading: isPaymentsLoading } = useQuery({
    queryKey: [queryKeys.quotationPayments, queryParams],
    queryFn: () => getQuotationPayments(queryParams)
  });

  const [paymentToEditId, setPaymentToEditId] = useState<number | null>(null);
  const paymentToEdit = payments?.find((payment) => payment?.id === paymentToEditId) ?? null;

  const queryParamsCodesPaymentMethods = [
    paymentMethodCodes.CODE_CB,
    paymentMethodCodes.CODE_CARTE_PRE,
    paymentMethodCodes.CODE_CESU,
    paymentMethodCodes.CODE_CHEQUE,
    paymentMethodCodes.CODE_VIREMENT
  ];

  const { data: paymentMethods, isLoading: isPaymentMethodsLoading } = useQuery({
    queryKey: [queryKeys.paymentMethods],
    queryFn: () => getPaymentMethods({ codes: queryParamsCodesPaymentMethods })
  });

  const alreadyPaid =
    payments?.reduce((acc, payment) => acc + parseFloat(payment?.amount || ""), 0) || 0;
  const lastPayment = payments?.[payments.length - 1];
  const totalTTC = quotation.totalTTC ? parseFloat(roundMoney(quotation.totalTTC)) : 0;
  const remaining = totalTTC - alreadyPaid;

  const displayedPayments = payments?.filter(
    (payment) => payment?.amount && Number(payment.amount) > 0
  );

  const getTodayCodeRemise = () => {
    const today = new Date();
    const day = today.getDate().toString().padStart(2, "0");
    const month = (today.getMonth() + 1).toString().padStart(2, "0");
    const year = today.getFullYear();
    return day + month + year;
  };

  const form = useForm<QuotationPaymentSchema>({
    resolver: zodResolver(QuotationPaymentSchema),
    values: {
      paymentMethod:
        paymentToEdit?.paymentMethod?.id?.toString() ??
        lastPayment?.paymentMethod?.id?.toString() ??
        "",
      amount: roundMoney(paymentToEdit?.amount ?? remaining),
      logInfo: paymentToEdit?.logInfo ?? "",
      codeRemise:
        (paymentToEdit?.codeRemise ?? lastPayment?.paymentMethod?.id === 1)
          ? getTodayCodeRemise()
          : "",
      receivedAt:
        transformDateTimeToDate(paymentToEdit?.receivedAt ?? new Date().toISOString()) || "",
      billedLastYear: false
    }
  });

  const paymentMutation = useMutation({
    mutationFn: (data: QuotationPaymentSchema) =>
      paymentToEdit?.id
        ? editQuotationPayment({ paymentId: paymentToEdit.id, data })
        : createQuotationPayment({ quotationId: quotation.id || 0, data }),
    onSuccess: () => {
      form.reset();
      queryClient.invalidateQueries([queryKeys.quotationPayments]);
      queryClient.invalidateQueries([queryKeys.quotations]);
      toast(paymentToEdit ? "Le réglement a été modifié" : "Le réglement a été ajouté", "success");
      setPaymentToEditId(null);
      if (onSuccess) onSuccess();
      props.onClose();
    },
    onError(error) {
      handleApiError(error, toast);
    }
  });
  const deletePaymentMutation = useMutation({
    mutationFn: deleteQuotationPayment,
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.quotationPayments]);
      queryClient.invalidateQueries([queryKeys.quotations]);
      toast("Le réglement a été supprimé", "success");
      setPaymentToEditId(null);
    },
    onError(error) {
      handleApiError(error, toast);
    }
  });

  const onSubmit = (data: QuotationPaymentSchema) => {
    paymentMutation.mutate(data);
  };

  return (
    <>
      <Modal title="Réglements" {...props}>
        <h2 className="text-xl font-semibold">Historique des réglements</h2>
        {isPaymentsLoading ? (
          <LoadingSpinner fullPage />
        ) : displayedPayments && displayedPayments.length > 0 ? (
          <Table
            data={{
              headers: [
                "Mode de paiement",
                "Montant",
                "Commentaire",
                "Reçu le",
                "Enregistré le",
                "Actions"
              ],
              rows: displayedPayments.map((payment) => ({
                key: payment?.id || 0,
                columns: [
                  payment?.paymentMethod?.name,
                  formatMoney(payment?.amount || 0),
                  payment?.logInfo,
                  formatDisplayDate(
                    payment?.receivedAt ? payment.receivedAt : payment?.createdAt || ""
                  ),
                  formatDisplayDate(payment?.createdAt || ""),
                  payment?.paymentMethod?.code !== paymentMethodCodes.CODE_CB ? (
                    <div key={payment?.id} className="flex items-center justify-center gap-2">
                      <button
                        className="p-1 text-grey-500 hover:text-grey-900"
                        onClick={() => {
                          setPaymentToEditId(payment?.id ?? null);
                        }}
                      >
                        <IonIcon icon={createOutline} className="text-lg" />
                      </button>
                      {
                        <button
                          className="p-1 text-danger-600 hover:text-danger-800"
                          onClick={() =>
                            openModal(
                              <ConfirmationModal
                                title="Supprimer le réglement"
                                isOpen={true}
                                onClose={closeModal}
                                onConfirm={() =>
                                  payment?.id
                                    ? deletePaymentMutation.mutate({ paymentId: payment.id })
                                    : null
                                }
                              >
                                Êtes vous sûr de vouloir supprimer ce réglement ?
                              </ConfirmationModal>
                            )
                          }
                        >
                          <IonIcon icon={trashOutline} className="text-lg" />
                        </button>
                      }
                    </div>
                  ) : null
                ]
              }))
            }}
          />
        ) : (
          <p>Aucun réglement enregistré</p>
        )}
        {withForm ? (
          isPaymentMethodsLoading ? (
            <LoadingSpinner fullPage />
          ) : (
            <>
              <h2 className="mt-8 text-xl font-semibold">
                {paymentToEdit
                  ? `Modifier le réglement (${paymentToEdit.paymentMethod?.name} de ${formatMoney(
                      paymentToEdit.amount ?? 0
                    )})`
                  : "Ajouter un réglement"}
              </h2>
              <form className="mt-4" onSubmit={form.handleSubmit(onSubmit)}>
                <FormRow className="lg:grid-cols-2">
                  <FormSelect
                    label="Mode de paiement"
                    errorMessage={form.formState.errors.paymentMethod?.message}
                    {...form.register("paymentMethod", {
                      onChange(event) {
                        if (event.target.value == 1) {
                          const codeRemise = getTodayCodeRemise();
                          form.setValue("codeRemise", codeRemise);
                        } else {
                          form.setValue("codeRemise", "");
                        }
                      }
                    })}
                  >
                    <>
                      <option value="" disabled>
                        Sélectionner un mode de paiement
                      </option>
                      {paymentMethods?.map((paymentMethod) => (
                        <option key={paymentMethod?.id} value={paymentMethod?.id}>
                          {paymentMethod?.name}
                        </option>
                      ))}
                    </>
                  </FormSelect>
                  <FormInput
                    type="number"
                    step="any"
                    label="Montant reçu"
                    rightAddon="€"
                    errorMessage={form.formState.errors.amount?.message}
                    {...form.register("amount")}
                  />
                  <FormInput
                    label="Code remise"
                    errorMessage={form.formState.errors.codeRemise?.message}
                    {...form.register("codeRemise")}
                  />
                  <FormDatePicker
                    label="Date de réception du paiement"
                    errorMessage={form.formState.errors.receivedAt?.message}
                    {...form.register("receivedAt")}
                  />
                </FormRow>
                <FormRow className="lg:grid-cols-2">
                  <FormTextArea
                    label="Commentaire"
                    className="col-span-2"
                    rows={4}
                    errorMessage={form.formState.errors.logInfo?.message}
                    {...form.register("logInfo")}
                  />
                </FormRow>
                <FormRow className="lg:grid-cols-2">
                  <FormSelect
                    label="Facturer sur l'exercice précédent"
                    {...form.register("billedLastYear", {
                      setValueAs: (v) => v === "true" || v === true
                    })}
                  >
                    <option value="false">Non</option>
                    <option value="true">Oui</option>
                  </FormSelect>
                </FormRow>
                <div className="mt-12 flex justify-end gap-4 border-t border-grey-200 pt-6">
                  <Button fill="clear" onClick={() => props.onClose()}>
                    Annuler
                  </Button>
                  <Button type="submit" disabled={paymentMutation.isLoading}>
                    {paymentToEdit ? "Modifier" : "Ajouter"}
                  </Button>
                </div>
              </form>
            </>
          )
        ) : null}
      </Modal>
      {renderModal()}
    </>
  );
};
