import { z } from "zod";

export const AdminSchema = z.object({
  lastname: z.string().min(1, "Veuillez renseigner un nom."),
  firstname: z.string().min(1, "Veuillez renseigner un prénom."),
  email: z.string().min(1, "Veuillez renseigner un email."),
  isActive: z.boolean()
});

export type AdminSchema = z.infer<typeof AdminSchema>;
