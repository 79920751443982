import { z } from "zod";

export const EmailSchema = z.object({
  name: z.string().min(1, "Veuillez renseigner le nom de l'email."),
  subject: z.string().min(1, "Veuillez renseigner le sujet de l'email."),
  content: z.string().min(1, "Veuillez renseigner le contenu de l'email."),
  code: z.string().min(1, "Veuillez renseigner le code de l'email."),
  isActive: z.boolean()
});

export type EmailSchema = z.infer<typeof EmailSchema>;
