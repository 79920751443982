import type { QuotationRecovery } from "~/types/quotation-recovery.types";
import type { DeepPartial } from "~/types/utils.types";

import { api } from "~/api/_config";

export const getQuotationRecoveries = async ({ quotationId }: { quotationId?: number }) => {
  const response = await api.get<DeepPartial<QuotationRecovery[]>>(
    `/api/admin/recovery_quotations`,
    {
      params: {
        "quotation.id": quotationId
      }
    }
  );
  return response.data;
};
