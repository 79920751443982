import type { MainTableProps } from "~/components/ui/MainTable";

const getSortValue = (data: any[], sortKey: string) => {
  const found = data.find((item) => item?.key === sortKey);
  return found?.sort ?? found?.display;
};

export const getSortedData = ({
  sort,
  data
}: {
  sort: { key: string; value: "ASC" | "DESC" } | null;
  data: MainTableProps["data"] | null | undefined;
}) => {
  if (!data || data.length === 0) {
    return [];
  }

  if (!sort) {
    return data;
  }

  return data.sort((a, b) => {
    const sortKey = sort.key || "";

    const aSortValue = getSortValue(a.columns, sortKey);
    const bSortValue = getSortValue(b.columns, sortKey);

    const isNumberType = typeof aSortValue === "number" && typeof bSortValue === "number";

    if (sort.value === "ASC") {
      if (isNumberType) {
        return aSortValue - bSortValue;
      }
      return aSortValue?.toString().localeCompare(bSortValue?.toString() || "");
    }
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (sort.value === "DESC") {
      if (isNumberType) {
        return bSortValue - aSortValue;
      }
      return bSortValue?.toString().localeCompare(aSortValue?.toString() || "");
    }

    return 0;
  });
};
