import type { AdvancedSearchParams } from "~/components/TableLayout";
import type { MainTableProps } from "~/components/ui/MainTable";

import { useMemo, useState } from "react";
import { useInfiniteQuery, useMutation } from "@tanstack/react-query";
import {
  add as addIcon,
  archiveOutline,
  cloudUploadOutline,
  createOutline,
  trashBinOutline,
  trashOutline
} from "ionicons/icons";

import { urssafStatusTexts, userRoles } from "~/config/api-constants";
import { queryKeys } from "~/config/query-keys-constants";
import { formatDisplayDate } from "~/utils/misc";
import { useAuthContext } from "~/contexts/authContext";
import { useUIContext } from "~/contexts/uiContext";
import { useModal } from "~/hooks/useModal";
import { handleApiError } from "~/services/errors";
import { getNextPageParamFn } from "~/api/_config";
import { queryClient } from "~/api/_queryClient";
import {
  archiveCustomer,
  deleteCustomer,
  deleteMultipleUsers,
  getUsers,
  unarchiveCustomer,
  updateClientUrssafStatus
} from "~/api/user";
import { CustomersSearchForm } from "~/components/AdvancedSearchForms/Customers/CustomersSearchForm";
import { ConfirmationModal } from "~/components/Modals/ConfirmationModal";
import { ImportCustomersModal } from "~/components/Modals/ImportCustomersModal";
import { TableLayout } from "~/components/TableLayout";
import { Tooltip } from "~/components/Tooltip";
import { Button } from "~/components/ui/Button/Button";
import { ExternalLink } from "~/components/ui/Link";
import { LoadingSpinner } from "~/components/ui/LoadingSpinner";

export const CustomersPage = () => {
  const { isAdmin } = useAuthContext();
  const { toast } = useUIContext();
  const { openModal, closeModal, renderModal } = useModal();

  const [showArchivedCustomers, setShowArchivedCustomers] = useState(false);
  const [advancedSearchParams, setAdvancedSearchParams] = useState<AdvancedSearchParams>(null);

  const queryParams = {
    role: userRoles.ROLE_CUSTOMER,
    isArchived: showArchivedCustomers,
    ...advancedSearchParams
  };

  const { data, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: [queryKeys.users, queryParams],
    queryFn: ({ pageParam, signal }) => getUsers({ ...queryParams, page: pageParam, signal }),
    getNextPageParam: getNextPageParamFn
  });

  const archiveCustomerMutation = useMutation(archiveCustomer, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.users]);
      toast("Le client a été archivé avec succès", "success");
    },
    onError(error) {
      handleApiError(error, toast);
    }
  });

  const unarchiveCustomerMutation = useMutation(unarchiveCustomer, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.users]);
      toast("Le client a été désarchivé avec succès", "success");
    },
    onError(error) {
      handleApiError(error, toast);
    }
  });

  const deleteCustomerMutation = useMutation(deleteCustomer, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.users]);
      toast("Le client a été supprimé avec succès", "success");
    },
    onError(error) {
      handleApiError(error, toast);
    }
  });

  const deleteMultipleUsersMutation = useMutation(deleteMultipleUsers, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.users]);
      toast("Les clients ont été supprimés avec succès", "success");
    },
    onError(error) {
      handleApiError(error, toast);
    }
  });

  const updateClientUrssafStatusMutation = useMutation(updateClientUrssafStatus, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.users]);
      toast("Le statut URSSAF du client a été mis à jour avec succès", "success");
    },
    onError(error) {
      handleApiError(error, toast);
    }
  });

  const globalActions = [
    {
      label: "Nouvelle fiche client",
      icon: addIcon,
      href: "/customers/create"
    },
    {
      label: "Importer CSV",
      icon: cloudUploadOutline,
      action: () => openModal(<ImportCustomersModal isOpen={true} onClose={closeModal} />)
    },
    {
      label: showArchivedCustomers ? "Voir les clients non archivés" : "Voir les clients archivés",
      icon: archiveOutline,
      action: () => setShowArchivedCustomers((prev) => !prev),
      align: "end" as const,
      color: "warning" as const
    }
  ];

  const groupedActions = [
    {
      id: "delete",
      label: "Supprimer les clients sélectionnés",
      condition: isAdmin,
      action: (ids: number[]) =>
        openModal(
          <ConfirmationModal
            title="Supprimer les clients sélectionnés"
            danger
            isOpen={true}
            onClose={closeModal}
            onConfirm={() => deleteMultipleUsersMutation.mutate(ids)}
          >
            Êtes vous sûr de vouloir supprimer les clients sélectionnés ?
          </ConfirmationModal>
        )
    }
  ];

  const tableData: MainTableProps["data"] = useMemo(
    () =>
      data?.pages.flat().map((user) => ({
        itemId: user?.id,
        checkboxConditions: [
          {
            id: "delete",
            condition: isAdmin
          }
        ],
        actions: [
          {
            label:
              !isAdmin && user?.hasAWaitingQuotation
                ? "Impossible d'editer (relevé(s) en attente)"
                : "Editer le client",
            icon: createOutline,
            color: "blue",
            href: `/customers/edit/${user?.id}`,
            disabled: !isAdmin && user?.hasAWaitingQuotation
          },
          {
            label: "Archiver le client",
            icon: trashBinOutline,
            color: "orange",
            condition: !user?.isArchived,
            action: () =>
              openModal(
                <ConfirmationModal
                  title="Archiver le client"
                  danger
                  isOpen={true}
                  onClose={closeModal}
                  onConfirm={() => (user?.id ? archiveCustomerMutation.mutate(user.id) : null)}
                >
                  Êtes vous sûr de vouloir archiver ce client ?
                </ConfirmationModal>
              )
          },
          {
            label: "Désarchiver le client",
            icon: trashBinOutline,
            color: "green",
            condition: user?.isArchived,
            action: () =>
              openModal(
                <ConfirmationModal
                  title="Désarchiver le client"
                  danger
                  isOpen={true}
                  onClose={closeModal}
                  onConfirm={() => (user?.id ? unarchiveCustomerMutation.mutate(user.id) : null)}
                >
                  Êtes vous sûr de vouloir désarchiver ce client ?
                </ConfirmationModal>
              )
          },
          {
            label: "Supprimer le client",
            icon: trashOutline,
            color: "red",
            condition: isAdmin,
            action: () =>
              openModal(
                <ConfirmationModal
                  title="Supprimer le client"
                  danger
                  isOpen={true}
                  onClose={closeModal}
                  onConfirm={() => (user?.id ? deleteCustomerMutation.mutate(user.id) : null)}
                >
                  Êtes vous sûr de vouloir supprimer ce client ?
                </ConfirmationModal>
              )
          }
        ],
        columns: [
          {
            key: "internRefCoop",
            label: "Ref interne coop",
            display: user?.internRefCoop
          },
          {
            key: "accountNumber",
            label: "N° de tiers",
            display: user?.accountNumber,
            condition: isAdmin
          },
          {
            key: "lastname",
            label: "Nom",
            display: (
              <div className="flex flex-col gap-1">
                {user?.companyName ? (
                  <span className="font-medium">{user.companyName.toUpperCase()}</span>
                ) : null}
                {user?.lastname || user?.firstname ? (
                  <span>
                    {user.lastname?.toUpperCase()} {user.firstname}
                  </span>
                ) : null}
              </div>
            )
          },
          {
            key: "email",
            label: "Email",
            display: <ExternalLink to={`mailto:${user?.email}`}>{user?.email}</ExternalLink>
          },
          {
            key: "mobileNumber",
            label: "Mobile",
            display: (
              <ExternalLink to={`tel:${user?.mobileNumber}`}>{user?.mobileNumber}</ExternalLink>
            )
          },
          {
            key: "address",
            label: "Adresse",
            display: (
              <div className="flex flex-col gap-1">
                <span>{user?.address}</span>
                <span>
                  {user?.zipcode} {user?.city}
                </span>
              </div>
            ),
            sort: user?.zipcode,
            search: `${user?.address} ${user?.zipcode} ${user?.city}`
          },
          {
            key: "isPro",
            label: "Type",
            display: user?.isPro ? "Professionnel" : "Particulier"
          },
          {
            key: "isAvanceImmediate",
            label: "Avance Immédiate",
            display: user?.isAvanceImmediate ? (
              user.urssafUser?.statutEtat == "OK" ? (
                <div className="text-center text-success-700">Oui</div>
              ) : (
                <Tooltip
                  content={
                    user.urssafUser?.statutCode
                      ? urssafStatusTexts[
                          user.urssafUser.statutCode as keyof typeof urssafStatusTexts
                        ]
                      : ""
                  }
                >
                  <div className="mb-2 text-center text-warning-700">Oui, en cours</div>
                  <Button
                    fill="outline"
                    color="warning"
                    onClick={() =>
                      user.id
                        ? updateClientUrssafStatusMutation.mutate({
                            userId: user.id
                          })
                        : null
                    }
                    className="mx-auto w-full text-xs"
                  >
                    {updateClientUrssafStatusMutation.isLoading &&
                    updateClientUrssafStatusMutation.variables?.userId === user.id ? (
                      <LoadingSpinner size="sm" />
                    ) : (
                      "Rafraichir le statut"
                    )}
                  </Button>
                </Tooltip>
              )
            ) : (
              <div className="text-center text-danger-800">Non</div>
            ),
            sort: user?.isAvanceImmediate,
            search: user?.isAvanceImmediate ? "avance immédiate" : ""
          },
          {
            key: "createdAt",
            label: "Date création",
            display: user?.createdAt ? formatDisplayDate(user.createdAt) : "",
            sort: user?.createdAt,
            search: user?.createdAt ? formatDisplayDate(user.createdAt) : ""
          },
          {
            key: "cooperator.lastname",
            label: "Coopérateur",
            display: (
              <div className="flex flex-col gap-1">
                {user?.cooperator?.[0]?.companyName ? (
                  <span className="font-medium">{user.cooperator[0].companyName}</span>
                ) : null}
                {user?.cooperator?.[0]?.lastname || user?.cooperator?.[0]?.firstname ? (
                  <span>
                    {user.cooperator[0].lastname} {user.cooperator[0].firstname}
                  </span>
                ) : null}
                {user?.cooperator?.[0]?.email ? (
                  <ExternalLink to={`mailto:${user.cooperator[0].email}`} className="text-xs">
                    {user.cooperator[0].email}
                  </ExternalLink>
                ) : null}
                {user?.cooperator?.[0]?.mobileNumber ? (
                  <ExternalLink to={`tel:${user.cooperator[0].mobileNumber}`} className="text-xs">
                    {user.cooperator[0].mobileNumber}
                  </ExternalLink>
                ) : null}
                {user?.cooperator?.[0]?.fixeNumber ? (
                  <ExternalLink to={`tel:${user.cooperator[0].fixeNumber}`} className="text-xs">
                    {user.cooperator[0].fixeNumber}
                  </ExternalLink>
                ) : null}
              </div>
            ),
            sort: user?.cooperator?.[0]?.lastname,
            search: `${user?.cooperator?.[0]?.companyName} ${user?.cooperator?.[0]?.lastname} ${user?.cooperator?.[0]?.firstname} ${user?.cooperator?.[0]?.email} ${user?.cooperator?.[0]?.mobileNumber} ${user?.cooperator?.[0]?.fixeNumber}`,
            condition: isAdmin
          }
        ]
      })) || [],
    [
      isAdmin,
      data?.pages,
      openModal,
      closeModal,
      deleteCustomerMutation,
      archiveCustomerMutation,
      unarchiveCustomerMutation,
      updateClientUrssafStatusMutation
    ]
  );

  return (
    <>
      <TableLayout
        data={tableData}
        isLoading={isFetching || isFetchingNextPage}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        AdvancedSearchForm={CustomersSearchForm}
        globalActions={globalActions}
        groupedActions={groupedActions}
        advancedSearchParams={advancedSearchParams}
        handleAdvancedSearch={setAdvancedSearchParams}
        handleSimpleSearch={setAdvancedSearchParams}
      />

      {renderModal()}
    </>
  );
};
