import type { UseFormRegister } from "react-hook-form";

import { FormInput } from "~/components/ui/FormInput";
import { FormSelect } from "~/components/ui/FormSelect";

export const CustomersSearchForm = ({ register }: { register: UseFormRegister<any> }) => {
  return (
    <>
      <FormInput
        label="Référence interne coopérateur"
        type="text"
        optional
        {...register("internRefCoop")}
      />
      <FormInput label="Numéro de tiers" type="text" optional {...register("accountNumber")} />
      <FormInput
        label="Société du coopérateur"
        type="text"
        optional
        {...register("cooperatorCompanyName")}
      />
      <FormInput
        label="Nom du coopérateur"
        type="text"
        optional
        {...register("cooperatorLastname")}
      />
      <FormInput
        label="Prénom du coopérateur"
        type="text"
        optional
        {...register("cooperatorFirstname")}
      />
      <FormInput label="Société du client" type="text" optional {...register("companyName")} />
      <FormInput label="Nom du client" type="text" optional {...register("lastname")} />
      <FormInput label="Prénom du client" type="text" optional {...register("firstname")} />
      <FormInput label="Email" type="text" optional {...register("email")} />
      <FormInput label="Adresse" type="text" optional {...register("address")} />
      <FormInput label="Code postal" type="text" optional {...register("zipcode")} />
      <FormInput label="Ville" type="text" optional {...register("city")} />
      <FormInput label="Numéro de mobile" type="text" optional {...register("mobileNumber")} />
      <FormInput label="Numéro de fixe" type="text" optional {...register("fixeNumber")} />
      <FormSelect label="Type" optional {...register("isPro")}>
        <option value="">Tous</option>
        <option value="true">Professionnel</option>
        <option value="false">Particulier</option>
      </FormSelect>
      <FormSelect label="Avance immédiate activée" optional {...register("isAvanceImmediate")}>
        <option value="">Tous</option>
        <option value="true">Oui</option>
        <option value="false">Non</option>
      </FormSelect>
    </>
  );
};
