import type { ComponentPropsWithoutRef, PropsWithChildren } from "react";

import { cn } from "~/utils/misc";

export const FormRow = ({
  children,
  className
}: PropsWithChildren<ComponentPropsWithoutRef<"div">>) => {
  return (
    <div className={cn("mt-6 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3", className)}>
      {children}
    </div>
  );
};
