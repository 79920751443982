import type { AdvancedSearchParams } from "~/components/TableLayout";
import type { MainTableProps } from "~/components/ui/MainTable";

import { useState } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import { add as addIcon, createOutline } from "ionicons/icons";

import { userRoles } from "~/config/api-constants";
import { queryKeys } from "~/config/query-keys-constants";
import { getNextPageParamFn } from "~/api/_config";
import { getUsers } from "~/api/user";
import { TableLayout } from "~/components/TableLayout";
import { ExternalLink } from "~/components/ui/Link";

export const AdministrationAdmins = () => {
  const [advancedSearchParams, setAdvancedSearchParams] = useState<AdvancedSearchParams>(null);

  const queryParams = { role: userRoles.ROLE_ADMIN, ...advancedSearchParams };

  const { data, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: [queryKeys.users, queryParams],
    queryFn: ({ pageParam, signal }) => getUsers({ ...queryParams, page: pageParam, signal }),
    getNextPageParam: getNextPageParamFn
  });

  const globalActions = [
    {
      label: "Nouveau compte admin",
      icon: addIcon,
      href: "/administration/administrators/create"
    }
  ];

  const tableData: MainTableProps["data"] =
    data?.pages.flat().map((user) => ({
      itemId: user?.id,
      actions: [
        {
          label: "Éditer l'administrateur",
          icon: createOutline,
          color: "blue",
          href: `/administration/administrators/edit/${user?.id}`
        }
      ],
      columns: [
        { key: "lastname", label: "Nom", display: user?.lastname },
        { key: "firstname", label: "Prénom", display: user?.firstname },
        {
          key: "email",
          label: "Email",
          display: <ExternalLink to={`mailto:${user?.email}`}>{user?.email}</ExternalLink>
        },
        { key: "isActive", label: "Activé", display: user?.isActive ? "Oui" : "Non" }
      ]
    })) || [];

  return (
    <>
      <TableLayout
        data={tableData}
        isLoading={isFetching || isFetchingNextPage}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        globalActions={globalActions}
        advancedSearchParams={advancedSearchParams}
        handleAdvancedSearch={setAdvancedSearchParams}
        handleSimpleSearch={setAdvancedSearchParams}
      />
    </>
  );
};
