import type { MainTableProps } from "~/components/ui/MainTable";

import { useInfiniteQuery } from "@tanstack/react-query";
import { createOutline } from "ionicons/icons";

import { queryKeys } from "~/config/query-keys-constants";
import { formatDisplayDate } from "~/utils/misc";
import { getNextPageParamFn } from "~/api/_config";
import { getAllSms } from "~/api/sms";
import { TableLayout } from "~/components/TableLayout";

export const AdministrationSms = () => {
  const { data, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: [queryKeys.sms],
    queryFn: ({ pageParam }) => getAllSms({ page: pageParam }),
    getNextPageParam: getNextPageParamFn
  });

  const tableData: MainTableProps["data"] =
    data?.pages.flat().map((sms) => ({
      itemId: sms?.id,
      actions: [
        {
          label: "Éditer le SMS",
          icon: createOutline,
          color: "blue",
          href: `/administration/sms/edit/${sms?.id}`
        }
      ],
      columns: [
        { key: "name", label: "Nom", display: sms?.name },
        { key: "code", label: "Code", display: sms?.code },
        { key: "content", label: "Contenu", display: sms?.content },
        {
          key: "createdAt",
          label: "Créé le",
          display: sms?.createdAt ? formatDisplayDate(sms.createdAt) : "",
          sort: sms?.createdAt,
          search: sms?.createdAt ? formatDisplayDate(sms.createdAt) : ""
        }
      ]
    })) || [];

  return (
    <>
      <TableLayout
        data={tableData}
        isLoading={isFetching || isFetchingNextPage}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
      />
    </>
  );
};
