import type { ComponentProps, PropsWithChildren } from "react";

import { IonContent, IonIcon, IonPage } from "@ionic/react";
import { useQuery } from "@tanstack/react-query";
import { checkmarkCircleOutline } from "ionicons/icons";
import { useParams } from "react-router";

import { queryKeys } from "~/config/query-keys-constants";
import { cn } from "~/utils/misc";
import { anonymousGetUser } from "~/api/anonymous";
import { ExternalLink } from "~/components/ui/Link";
import carteDynabuyImg from "~/assets/images/carte-dynabuy.png";
import companyImg from "~/assets/images/company-image-shutterstock.jpg";
import logoSvg from "~/assets/logo/logo-bg.svg";

const Wrapper = ({ children, className, ...props }: PropsWithChildren & ComponentProps<"div">) => {
  return (
    <div className={cn("mx-auto max-w-4xl px-6", className)} {...props}>
      {children}
    </div>
  );
};

export const AnonymousRecommendationLanding = () => {
  const { module_type, user_hash } = useParams<{ module_type: string; user_hash: string }>();

  const { data: user } = useQuery({
    queryKey: [queryKeys.users],
    queryFn: () => anonymousGetUser({ hash: user_hash })
  });

  const moduleType = module_type === "1" ? "client" : module_type === "2" ? "pro" : null;

  if (!moduleType) {
    return <p>L&apos;url ne correspond à aucun type de recommandation</p>;
  }

  return (
    <IonPage>
      <IonContent>
        <img alt="Logo " className="mx-auto mt-8 block h-16 w-16" src={user?.logoUrl} />
        <div
          className={cn(
            "oblique-clip mt-10 overflow-visible bg-grey-200 bg-opacity-50 py-16 pb-20"
          )}
        >
          <Wrapper className="text-center">
            {moduleType === "client" ? (
              <>
                <h1 className="mb-0 text-3xl font-bold !leading-snug text-grey-900 sm:text-5xl">
                  Faites comme nous !
                </h1>
                <p className="mt-4 text-lg leading-8 text-grey-600 sm:text-xl">
                  Rejoignez la coopérative Hexa Coop
                </p>
              </>
            ) : (
              <>
                <h1 className="mb-0 text-2xl font-bold !leading-snug text-grey-900 sm:text-4xl">
                  Nous tenions à vous informer d&apos;un{" "}
                  <strong>dispositif fiscal très avantageux !</strong>
                </h1>
              </>
            )}
          </Wrapper>
        </div>

        <IonIcon icon={logoSvg} className="mx-auto -mt-14 block h-24 w-24" />

        <Wrapper>
          <div className={cn(moduleType === "client" ? "mt-24" : "mt-16")}>
            <h2 className="text-center text-2xl font-extrabold text-primary">
              {moduleType === "client" ? (
                <>
                  Développez votre activité <span className="font-medium"> et </span>Augmentez votre
                  marge
                </>
              ) : (
                "Le saviez-vous ?"
              )}
            </h2>
            {moduleType === "client" ? (
              <p className="mt-4 text-center">
                Vous aussi, en rejoignant la coopérative Hexa Coop, facturez vos clients des
                prestations éligibles aux services à la personne autour d&apos;un crédit
                d&apos;impôt grâce à l&apos;agrément préfectoral de la coopérative.
              </p>
            ) : (
              <p className="mt-4 text-center text-lg">
                Avec la loi Borloo, vous pouvez <strong>financer avec votre entreprise</strong>{" "}
                jusqu&apos;à <strong>2540 €/an</strong> de services à la personne à utiliser{" "}
                <strong>pour vos besoins privés !</strong>
              </p>
            )}
          </div>
        </Wrapper>
        <div className="oblique-clip mt-20 bg-primary text-white">
          {moduleType === "client" ? null : (
            <img
              src={companyImg}
              alt="Entreprise de service à la personne"
              className="oblique-clip h-48 w-full object-cover"
            />
          )}
          <Wrapper className="py-12">
            <h3 className="text-center text-xl font-bold">Vos avantages !</h3>
            <ul className="mx-auto mt-4 w-fit space-y-6">
              <li className="flex w-fit items-start text-lg">
                <IonIcon icon={checkmarkCircleOutline} className="mr-2 h-8 w-8 shrink-0" />
                <div className="mt-0.5">
                  {moduleType === "client" ? (
                    <>
                      Faites <strong>bénéficier</strong> vos clients de{" "}
                      <strong>50% de crédit d&apos;impôt</strong> sur vos prestations facturées.
                    </>
                  ) : (
                    <>
                      Totalement <strong>déductible de votre bénéfice</strong> imposable
                    </>
                  )}
                </div>
              </li>
              <li className="flex w-fit items-start text-lg">
                <IonIcon icon={checkmarkCircleOutline} className="mr-2 h-8 w-8 shrink-0" />
                <div className="mt-0.5">
                  {moduleType === "client" ? (
                    <>
                      <strong>Profitez d&apos;un dispositif fiscal exclusif</strong> pour vos
                      clients indépendants
                      <br />
                      (chefs d&apos;entreprise, professions libérales, ...)
                    </>
                  ) : (
                    <>
                      Totalement <strong>exonéré de charges sociales</strong>
                    </>
                  )}
                </div>
              </li>
              <li className="flex w-fit items-start text-lg">
                <IonIcon icon={checkmarkCircleOutline} className="mr-2 h-8 w-8 shrink-0" />
                <div className="mt-0.5">
                  {moduleType === "client" ? (
                    <>
                      <strong>Recevez un apport de clientèle</strong> avec notre réseau national
                    </>
                  ) : (
                    <>+ Crédit d&apos;impôt</>
                  )}
                </div>
              </li>
            </ul>
          </Wrapper>
        </div>
        <Wrapper>
          {moduleType === "client" ? null : (
            <div className="mt-16">
              <div className="flex items-center gap-4">
                <div className="flex h-8 w-8 shrink-0 items-center justify-center rounded-full border-2 border-primary text-xl font-bold text-primary">
                  1
                </div>
                <p className="">
                  Vous <strong>préfinancez jusqu&apos;à 2540 €</strong> avec votre entreprise
                </p>
              </div>
              <div className="mt-8 flex items-center gap-4">
                <div className="flex h-8 w-8 shrink-0 items-center justify-center rounded-full border-2 border-primary text-xl font-bold text-primary">
                  2
                </div>
                <p className="">
                  <strong>Vous utilisez</strong> votre crédit de services{" "}
                  <strong>à titre privé</strong> pour <strong>nos prestations de services</strong>
                </p>
              </div>
              <div className="mt-6 text-center">
                <p className="text-sm">Mais aussi sur plus de 50 autres services :</p>
                <p className="mt-4">
                  Travaux ménagers, jardinage, bricolage, cours à domicile, assistance informatique,
                  garde d’enfants, coach sportif ...
                </p>
              </div>
            </div>
          )}

          <div className="mt-16 rounded-lg bg-grey-200 bg-opacity-50 p-4">
            <h3 className="text-center text-xl font-medium leading-relaxed">
              Avec {user?.companyName}
              <br />
              <span className="text-xl font-bold text-primary">vous bénéficiez en + de</span>
            </h3>
            <div className="mt-2 text-center">
              {moduleType === "client" ? (
                <>
                  <p className="mt-2">
                    Frais d&apos;adhésion à <span className="font-bold text-primary">190€ HT</span>{" "}
                    au lieu de 390€ HT
                  </p>
                  <p className="mt-2">+</p>
                </>
              ) : null}
              <p className="mt-2">
                La <strong>carte Dynabuy offerte</strong> en cadeau de bienvenue
              </p>
              <img
                alt="Dynabuy"
                className="mx-auto !-mb-10 !mt-6 block h-12 rotate-12"
                src={carteDynabuyImg}
              />
            </div>
          </div>

          <p className="mt-24 text-center text-2xl font-semibold text-grey-900">
            L’équipe d’HEXA COOP prendra contact avec vous sous peu pour vous détailler les
            avantages pour votre activité.
          </p>
          <div className="mt-16 text-center">
            <p className="text-sm">Une question ?</p>
            <p className="text-xl font-semibold">
              Tél. <ExternalLink to="tel:0415400000">04 15 400 000</ExternalLink>
            </p>
            <p className="mt-2 text-base font-medium">
              <ExternalLink to="mailto:contact@hexa-coop.com">contact@hexa-coop.com</ExternalLink>
            </p>
            <p className="mt-2 text-base font-medium">
              <ExternalLink to="https://www.hexa-coop.com">www.hexa-coop.com</ExternalLink>
            </p>
          </div>
          <div className="mt-8 flex justify-center">
            <IonIcon icon={logoSvg} className="h-12 w-12" />
          </div>
          <div className="my-4 text-center text-xs">
            <p>HEXA COOP - N° Agrément : SAP/N° 834225096</p>
            <p>SIRET: 83422509600017</p>
          </div>
        </Wrapper>
      </IonContent>
    </IonPage>
  );
};
