import { z } from "zod";

export const CommercialSchema = z.object({
  lastname: z.string().min(1, "Veuillez renseigner un nom."),
  firstname: z.string().min(1, "Veuillez renseigner un prénom."),
  email: z.string().min(1, "Veuillez renseigner un email."),
  isActive: z.boolean()
});

export type CommercialSchema = z.infer<typeof CommercialSchema>;
