import { RadioGroup } from "@headlessui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { IonContent, IonIcon, IonPage } from "@ionic/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  cardOutline,
  pencilOutline,
  swapHorizontalOutline,
  ticketOutline,
  walletOutline
} from "ionicons/icons";
import { Controller, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router";

import { quotationStates } from "~/config/api-constants";
import { queryKeys } from "~/config/query-keys-constants";
import { cn, formatDisplayDate, formatMoney } from "~/utils/misc";
import { initiateCreditCardPayment } from "~/utils/payment";
import { useUIContext } from "~/contexts/uiContext";
import { handleApiError } from "~/services/errors";
import {
  anonymousGetQuotation,
  anonymousPayQuotationByCreditCard,
  anonymousSignQuotation
} from "~/api/anonymous";
import { HtmlParser } from "~/components/HtmlParser";
import { Alert } from "~/components/ui/Alert";
import { Button } from "~/components/ui/Button/Button";
import { FormCheckbox } from "~/components/ui/FormCheckbox";
import { Link } from "~/components/ui/Link";
import { LoadingSpinner } from "~/components/ui/LoadingSpinner";
import { Table } from "~/components/ui/Table";
import LogoIcon from "~/assets/logo/logo-bg.svg";
import { AnonymousSignFormSchema } from "~/schemas/anonymous.schema";

const PAYMENT_METHODS = [
  { name: "Carte bleue", icon: cardOutline, value: "cb", available: true },
  { name: "Virement", icon: swapHorizontalOutline, value: "virement", available: true },
  { name: "Chèque", icon: pencilOutline, value: "cheque", available: true },
  { name: "CESU préfinancé", icon: ticketOutline, value: "cesu", available: true },
  { name: "Compte prépayé", icon: walletOutline, value: "carte-prefinancee", available: true }
];

export const AnonymousQuotationSignForm = () => {
  const { hash: quotationHash } = useParams<{ hash: string }>();
  const history = useHistory();
  const { toast } = useUIContext();

  const {
    data: quotation,
    isFetching: isQuotationFetching,
    isError: isQuotationError
  } = useQuery({
    queryKey: [queryKeys.quotations],
    queryFn: () => anonymousGetQuotation({ hash: quotationHash })
  });

  const payByCreditCard = useMutation({
    mutationFn: anonymousPayQuotationByCreditCard,
    onError(error) {
      handleApiError(error, toast);
    }
  });

  const signQuotation = useMutation({
    mutationFn: anonymousSignQuotation,
    onSuccess: (_data, variables) => history.push(`/_anon/sign/success/${variables.paymentMethod}`),
    onError(error) {
      handleApiError(error, toast);
    }
  });

  const form = useForm<AnonymousSignFormSchema>({
    resolver: zodResolver(AnonymousSignFormSchema),
    defaultValues: {
      paymentMethod: "cb",
      conditions: true,
      privacy: true
    }
  });

  const onSubmit = async (data: AnonymousSignFormSchema) => {
    if (!quotation?.id) return;

    if (data.paymentMethod === "cb") {
      const payboxData = await payByCreditCard.mutateAsync({
        id: quotation.id
      });
      await initiateCreditCardPayment(payboxData);
    } else if (data.paymentMethod === "carte-prefinancee") {
      history.push(`/_anon/pay/cartePre/${quotationHash}`);
    } else {
      signQuotation.mutate({
        hash: quotationHash,
        paymentMethod: data.paymentMethod
      });
    }
  };

  return (
    <IonPage>
      <IonContent>
        <div className="flex min-h-full w-full items-center justify-center bg-grey-100 p-4 sm:p-8">
          <div className="mx-auto max-w-6xl rounded-lg border border-grey-100 bg-white px-4 py-4 shadow-md sm:px-8">
            {isQuotationFetching ? (
              <LoadingSpinner size="lg" fullPage />
            ) : !quotation || isQuotationError ? (
              <Alert
                type="error"
                title="Erreur"
                text={
                  <>
                    Une erreur est survenue lors de la récupération de votre relevé de prestation,
                    merci de réessayer ultérieurement.
                    <br />
                    Si le problème persiste, merci de vous rapprocher de votre prestataire pour plus
                    de détails.
                  </>
                }
              />
            ) : quotation.state === quotationStates.STATE_CANCEL ? (
              <Alert
                type="error"
                title="Erreur"
                text={
                  <>
                    Ce relevé de prestation a été annulé ou supprimé.
                    <br />
                    Merci de vous rapprocher de votre prestataire pour plus de détails.
                  </>
                }
              />
            ) : quotation.signedAt ? (
              <Alert
                type="error"
                title="Erreur"
                text={
                  <>
                    Ce relevé de prestation a déjà été signé le{" "}
                    {formatDisplayDate(quotation.signedAt)}.
                    <br />
                    Merci de vous rapprocher de votre prestataire pour plus de détails.
                  </>
                }
              />
            ) : quotation.state !== quotationStates.STATE_SEND ? (
              <Alert
                type="error"
                title="Erreur"
                text={
                  <>
                    Ce relevé de prestation ne peut pas être signé car il n&apos;a pas le statut
                    &quot;envoyé&quot;.
                    <br />
                    Merci de vous rapprocher de votre prestataire pour plus de détails.
                  </>
                }
              />
            ) : (
              <>
                <h1 className="m-0 border-b border-b-grey-100 pb-4 text-center text-lg font-semibold uppercase">
                  Votre{" "}
                  {quotation.typeQuotation === 1
                    ? "contrat de préfinancement"
                    : "relevé de prestation"}{" "}
                  n°{quotation.id}
                </h1>
                <div className="mt-4 grid grid-cols-1 gap-8 sm:grid-cols-12">
                  <div className="text-start sm:col-span-6 md:col-span-4">
                    <h2 className="mb-2 text-lg">
                      {quotation.typeQuotation === 1 ? "Financeur" : "Votre intervenant"}
                    </h2>
                    <address className="text-xs not-italic text-grey-600 sm:text-sm">
                      {quotation.quotationCooperatorInfo?.[0]?.header1 ||
                        quotation.quotationCooperatorInfo?.[0]?.companyName ||
                        null}
                      <br />
                      {quotation.quotationCooperatorInfo?.[0]?.header2 ||
                        `${quotation.quotationCooperatorInfo?.[0]?.lastname} ${quotation.quotationCooperatorInfo?.[0]?.firstname}` ||
                        null}
                      <br />
                      {quotation.quotationCooperatorInfo?.[0]?.header3 ||
                        quotation.quotationCooperatorInfo?.[0]?.address ||
                        null}
                      <br />
                      {quotation.quotationCooperatorInfo?.[0]?.header4 ||
                        `${quotation.quotationCooperatorInfo?.[0]?.zipcode} ${quotation.quotationCooperatorInfo?.[0]?.city}` ||
                        null}
                      <br />
                      {quotation.quotationCooperatorInfo?.[0]?.header5 ||
                        quotation.quotationCooperatorInfo?.[0]?.email ||
                        null}
                      <br />
                      {quotation.quotationCooperatorInfo?.[0]?.header6 ? (
                        <>
                          {quotation.quotationCooperatorInfo[0]?.header6}
                          <br />
                        </>
                      ) : null}
                    </address>
                  </div>

                  <div className="order-first sm:col-span-12 md:order-none md:col-span-4">
                    {quotation.typeQuotation === 1 ? (
                      <IonIcon
                        src={LogoIcon}
                        title="Logo Hexa Coop"
                        className="mx-auto block h-20 sm:h-24 md:h-28"
                      />
                    ) : quotation.cooperator?.logoUrl ? (
                      <img
                        src={quotation.cooperator.logoUrl}
                        alt="Logo de l'entreprise"
                        className="mx-auto h-20 sm:h-24 md:h-28"
                      />
                    ) : null}
                  </div>

                  <div className="ml-auto text-end sm:col-span-6 md:col-span-4">
                    <h2 className="mb-2 text-lg">
                      {quotation.typeQuotation === 1 ? "Bénéficiaire" : "Vos informations"}
                    </h2>
                    <address className="text-xs not-italic text-grey-600 sm:text-sm">
                      {quotation.quotationCustomerInfo?.[0]?.isPro ? (
                        <>
                          {quotation.quotationCustomerInfo[0]?.legalForm}{" "}
                          {quotation.quotationCustomerInfo[0]?.companyName}
                          <br />
                        </>
                      ) : null}
                      {quotation.quotationCustomerInfo?.[0]?.lastname}{" "}
                      {quotation.quotationCustomerInfo?.[0]?.firstname}
                      <br />
                      {quotation.quotationCustomerInfo?.[0]?.address}
                      <br />
                      {quotation.quotationCustomerInfo?.[0]?.zipcode}{" "}
                      {quotation.quotationCustomerInfo?.[0]?.city}
                      <br />
                      {quotation.quotationCustomerInfo?.[0]?.email}
                      <br />
                      {quotation.quotationCustomerInfo?.[0]?.siret ? (
                        <>
                          SIRET: {quotation.quotationCustomerInfo[0]?.siret}
                          <br />
                        </>
                      ) : null}
                      {quotation.quotationCustomerInfo?.[0]?.tvaNumber ? (
                        <>
                          N° TVA: {quotation.quotationCustomerInfo[0]?.tvaNumber}
                          <br />
                        </>
                      ) : null}
                    </address>
                  </div>
                </div>
                <Table
                  className="mt-8"
                  data={{
                    headers:
                      quotation.typeQuotation === 1
                        ? ["Nature de la prestation", "Total HT", "TVA", "Total TTC"]
                        : [
                            "Nature de la prestation",
                            "Date de la prestation",
                            "Quantité",
                            "Taux HT",
                            "TVA",
                            "Total TTC"
                          ],
                    rows: [
                      ...(quotation.quotationRow && quotation.quotationRow.length > 0
                        ? quotation.quotationRow.map((row) => ({
                            key: `${row.category.name}-${row.executedAt}-${row.quantity}-${row.priceUnit}-${row.tva}-${row.totalTTC}`,
                            columns:
                              quotation.typeQuotation === 1
                                ? [
                                    row.nameService,
                                    formatMoney(row.totalHT),
                                    parseFloat(row.tva).toFixed(2) + "%",
                                    formatMoney(row.totalTTC)
                                  ]
                                : [
                                    row.nameService,
                                    row.executedAt === row.endExecutedAt
                                      ? formatDisplayDate(row.executedAt)
                                      : `Du ${formatDisplayDate(
                                          row.executedAt
                                        )} au ${formatDisplayDate(row.endExecutedAt)}`,
                                    row.quantity,
                                    formatMoney(row.priceUnit),
                                    parseFloat(row.tva).toFixed(2) + "%",
                                    formatMoney(row.totalTTC)
                                  ]
                          }))
                        : []),
                      {
                        key: "total",
                        columns: [
                          "",
                          "",
                          "",
                          "",
                          <div key="total-row-text" className="font-medium">
                            TOTAL
                          </div>,
                          <div key="total-row-value" className="font-semibold">
                            {formatMoney(quotation.totalTTC || 0)}
                          </div>
                        ]
                      }
                    ]
                  }}
                />
                {quotation.comments ? (
                  <div className="mt-8">
                    <h2 className="mb-4 text-lg">Commentaires :</h2>
                    <HtmlParser className="text-sm text-grey-700" html={quotation.comments} />
                  </div>
                ) : null}
                <div className="mt-12">
                  <h2 className="mb-4 text-xl">Comment souhaitez-vous régler ?</h2>
                  <form onSubmit={form.handleSubmit(onSubmit)}>
                    <Controller
                      name="paymentMethod"
                      control={form.control}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <RadioGroup
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          ref={ref}
                          className="mt-2"
                        >
                          <RadioGroup.Label className="sr-only">
                            Sélectionnez un moyen de paiement
                          </RadioGroup.Label>
                          <div className="mx-auto grid grid-cols-4 gap-4 md:grid-cols-6 lg:grid-cols-10">
                            {PAYMENT_METHODS.map((option) => (
                              <RadioGroup.Option
                                key={option.value}
                                value={option.value}
                                disabled={!option.available}
                                className={({ checked }) =>
                                  cn(
                                    "flex flex-col items-center justify-center gap-2 rounded-md p-2 text-xs font-medium sm:p-3 sm:text-sm md:p-4",
                                    "col-span-2 first:col-span-4 md:first:col-span-3 lg:first:col-span-2 md:[&:nth-child(2)]:col-span-3 lg:[&:nth-child(2)]:col-span-2",
                                    option.available
                                      ? "cursor-pointer focus:outline-none"
                                      : "cursor-not-allowed opacity-25",
                                    checked
                                      ? "bg-primary text-white hover:bg-primary-500"
                                      : "bg-white text-primary ring-1 ring-inset ring-primary hover:bg-grey-50"
                                  )
                                }
                              >
                                <IonIcon icon={option.icon} className="h-6 w-6" />
                                <RadioGroup.Label className="cursor-pointer text-center">
                                  {option.name}
                                </RadioGroup.Label>
                              </RadioGroup.Option>
                            ))}
                          </div>
                        </RadioGroup>
                      )}
                    />
                    {form.formState.errors.paymentMethod?.message ? (
                      <p className="mx-auto mt-2 whitespace-pre-line text-center text-sm text-danger-700">
                        {form.formState.errors.paymentMethod.message}
                      </p>
                    ) : null}

                    <FormCheckbox
                      label={
                        <>
                          Je reconnais avoir été préalablement informé des modalités contractuelles
                          et avoir pris connaissances des{" "}
                          <Link
                            to="/terms-and-conditions/quotation"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            conditions générales
                          </Link>{" "}
                          d&apos;HEXA COOP.
                        </>
                      }
                      className="mt-8"
                      errorMessage={form.formState.errors.conditions?.message}
                      {...form.register("conditions")}
                    />
                    <FormCheckbox
                      label={
                        <>
                          J&apos;ai lu, j&apos;accepte et je me conforme à la{" "}
                          <Link
                            to="/personal-data-security-policy"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            politique de sécurité des données personnelles HEXA COOP.
                          </Link>
                        </>
                      }
                      className="mt-4"
                      errorMessage={form.formState.errors.privacy?.message}
                      {...form.register("privacy")}
                    />

                    <Button type="submit" size="large" className="mx-auto mt-8 block w-fit">
                      Valider et régler
                    </Button>
                  </form>
                </div>
              </>
            )}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
