import type { UseFormRegister } from "react-hook-form";

import dayjs from "dayjs";

import { scheduleQuotationTaskStates } from "~/config/api-constants";
import { transformDateTimeToDate } from "~/utils/misc";
import { FormDatePicker } from "~/components/ui/FormDatePicker";
import { FormInput } from "~/components/ui/FormInput";
import { FormSelect } from "~/components/ui/FormSelect";

export const QuotationsTasksSearchForm = ({ register }: { register: UseFormRegister<any> }) => {
  return (
    <>
      <FormDatePicker
        label="Date min"
        defaultValue={transformDateTimeToDate(dayjs().subtract(3, "year").toISOString()) || ""}
        optional
        {...register("scheduledAtAfter")}
      />
      <FormDatePicker
        label="Date max"
        defaultValue={transformDateTimeToDate(dayjs().toISOString()) || ""}
        optional
        {...register("scheduledAtBefore")}
      />
      <FormInput
        label="Nom du client"
        placeholder="Nom"
        type="text"
        optional
        {...register("customerLastname")}
      />
      <FormInput
        label="Prénom du client"
        placeholder="Prénom"
        type="text"
        optional
        {...register("customerFirstname")}
      />
      <FormInput
        label="Société du client"
        placeholder="Société"
        type="text"
        optional
        {...register("customerCompanyName")}
      />
      <FormInput
        label="Nom du coopérateur"
        placeholder="Nom"
        type="text"
        optional
        {...register("cooperatorLastname")}
      />
      <FormInput
        label="Prénom du coopérateur"
        placeholder="Prénom"
        type="text"
        optional
        {...register("cooperatorFirstname")}
      />
      <FormInput
        label="Société du coopérateur"
        placeholder="Société"
        type="text"
        optional
        {...register("cooperatorCompanyName")}
      />
      <FormInput
        label="Numéro de relevé parent"
        placeholder="6 derniers chiffres (ex: 231887)"
        type="text"
        optional
        {...register("id")}
      />
      <FormSelect label="Statut de la tâche" optional {...register("state")}>
        <option value="">Toutes</option>
        <option value={scheduleQuotationTaskStates.STATE_CANCELED}>Annulée</option>
        <option value={scheduleQuotationTaskStates.STATE_WAITING}>En attente</option>
        <option value={scheduleQuotationTaskStates.STATE_TREATED}>Envoyée</option>
        <option value={scheduleQuotationTaskStates.STATE_ERROR}>Erreur</option>
      </FormSelect>
    </>
  );
};
