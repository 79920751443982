import type { UIContextProps } from "~/contexts/uiContext";

import { isAxiosError } from "axios";

export function handleApiError(error: unknown, toast: UIContextProps["toast"]) {
  let errorMessage: string = "Une erreur est survenue.";

  // Handle axios errors

  if (!error) {
    errorMessage = "Une erreur est survenue.";
  } else if (
    isAxiosError(error) &&
    error.response?.data.violations &&
    Array.isArray(error.response.data.violations) &&
    error.response.data.violations.length > 0
  ) {
    errorMessage = error.response.data.violations
      .map((violation: any) => violation.message)
      .join(". \n");
  } else if (isAxiosError(error) && error.response?.data.message) {
    errorMessage = error.response.data.message;
  } else if (isAxiosError(error) && error.response?.data["hydra:description"]) {
    errorMessage = error.response.data["hydra:description"];
  } else if (isAxiosError(error) && error.response?.data.details) {
    errorMessage = error.response.data.details;
  } else if (isAxiosError(error) && error.response?.data.detail) {
    errorMessage = error.response.data.detail;
  } else if (isAxiosError(error)) {
    const { response } = error;

    if (response?.status === 401) {
      errorMessage = "Vous n'êtes pas autorisé à effectuer cette action.";
    } else if (response?.status === 403) {
      errorMessage = "Vous n'êtes pas autorisé à effectuer cette action.";
    } else if (response?.status === 404) {
      errorMessage = "La ressource demandée est introuvable.";
    } else if (response?.status === 422) {
      errorMessage = "Les données envoyées sont incorrectes.";
    } else if (response?.status === 500) {
      errorMessage = "Une erreur est survenue côté serveur.";
    } else {
      errorMessage = "Une erreur est survenue.";
    }
  } else if (error instanceof Error) {
    errorMessage = error.message;
  }

  console.error(error);

  toast(errorMessage, "error");
}
