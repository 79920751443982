import type { User } from "~/types/user.types";
import type { DeepPartial } from "~/types/utils.types";

import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";

import { queryKeys } from "~/config/query-keys-constants";
import { useUIContext } from "~/contexts/uiContext";
import { handleApiError } from "~/services/errors";
import { updateCooperator } from "~/api/user";
import { Button } from "~/components/ui/Button/Button";
import { FormInput } from "~/components/ui/FormInput";
import { FormRow } from "~/components/ui/FormRow";
import { ProfilePasswordSchema } from "~/schemas/profile.schema";

export const ProfilePassword = ({ userData }: { userData?: DeepPartial<User> }) => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const { toast } = useUIContext();

  // Customer mutation
  const mutation = useMutation({
    mutationFn: (data: Omit<ProfilePasswordSchema, "passwordConfirmation">) =>
      updateCooperator({ userId: userData?.id ? userData.id : null, data }),
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: [queryKeys.users] });
      toast("Mot de passe mis à jour avec succès.", "success");
    },
    onError(error) {
      handleApiError(error, toast);
    }
  });

  // Define form
  const form = useForm<ProfilePasswordSchema>({
    resolver: zodResolver(ProfilePasswordSchema),
    values: {
      password: "",
      passwordConfirmation: ""
    }
  });

  const onSubmit = (data: ProfilePasswordSchema) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { passwordConfirmation, ...dataRest } = data;

    mutation.mutate(dataRest);
  };

  return (
    <>
      <h2 className="mt-6">Informations de connexion</h2>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        {/* <p className="text-sm text-grey-600">
          Laissez les champs souhaités vides pour conserver les informations de base.
        </p> */}
        <FormRow className="lg:grid-cols-2">
          <FormInput
            label="Mot de passe"
            type="password"
            {...form.register("password")}
            errorMessage={form.formState.errors.password?.message}
          />
          <FormInput
            label="Confirmation mot de passe"
            type="password"
            {...form.register("passwordConfirmation")}
            errorMessage={form.formState.errors.passwordConfirmation?.message}
          />
        </FormRow>

        <div className="mt-12 flex justify-end gap-4 border-t border-grey-200 pt-6">
          <Button fill="clear" onClick={() => history.goBack()}>
            Annuler
          </Button>
          <Button type="submit" disabled={mutation.isLoading}>
            Enregistrer
          </Button>
        </div>
      </form>
    </>
  );
};
