import { IonContent, IonPage } from "@ionic/react";

export const QuotationGeneralConditions = () => {
  return (
    <IonPage>
      <IonContent className="container">
        <div className="mx-auto max-w-5xl [&_h2]:mb-6 [&_h2]:text-2xl [&_h2]:font-medium [&_h2]:text-grey-700 [&_h3]:mb-4 [&_h3]:mt-6 [&_h3]:text-base [&_h3]:font-bold [&_h4]:mb-4 [&_h4]:mt-6 [&_h4]:text-base [&_h4]:font-bold [&_section]:mt-6 [&_section]:text-justify [&_section]:text-sm [&_section]:font-medium [&_section]:text-grey-700">
          <h1 className="text-center text-lg font-bold uppercase text-grey-700">
            Conditions générales
          </h1>

          <section>
            <h2>Conditions générales de prestations de services particuliers</h2>
            <h3>ARTICLE 1 – CHAMP D’APPLICATION</h3>
            La société HEXA COOP est une société Coopérative regroupant sous une seule entité des
            Coopérateurs affiliés réalisant en son nom et pour son compte des prestations de
            services à la personne.
            <br />
            <br />
            HEXA COOP est immatriculée au Registre du Commerce et des Sociétés de CLERMONT-FERRAND
            sous le numéro 834 225 096 et remplit l’intégralité des conditions posées par la&nbsp;
            Loi n° 2005-841 du 26 juillet 2005 relative au développement des services à la personne
            et portant diverses mesures en faveur de la cohésion sociale et notamment l’obligation
            de déclaration d’activité. A ce titre, les prestations qu’elle exécute auprès des
            particuliers sont éligibles au dispositif de crédit d’impôt prévu par la l’article 199
            sexdecies du Code général des impôts C.G.I..
            <br />
            <br />
            Tout client souhaitant recourir aux services proposés par la société Coopérative HEXA
            COOP, s’engage à adhérer aux présentes conditions générales de prestations de services.
            Le client prend l’engagement d’en prendre connaissance préalablement à toute commande de
            manière à ce que toute sollicitation auprès de HEXA COOP ou de ses Coopérateurs en
            suppose adhésion entière et sans réserve.
            <br />
            <br />
            <h3>ARTICLE 2 – RELEVÉ ET PRESTATIONS </h3>
            Client et Coopérateur procèdent à la conclusion d’un relevé de prestations de services à
            la personne, lequel est réputé formé dès signature et péremption du droit de rétraction
            prévu en pareille matière. <br />
            <br />
            Les prestations assurées par le Coopérateur affilié à la Coopérative HEXA COOP sont
            celles relevant du domaine des services à la personne à domicile tels qu’ils sont
            définis aux articles L. 7231-1 et D. 7231-1 du Code du travail. <br />
            <br />
            Client et Coopérateur font leur affaire de la planification des prestations de services
            à la personne et notamment de leur nature, lieu et date d’exécution.
            <br />
            <br />
            <h3>ARTICLE 3 – LE PRIX </h3>
            Les prix des interventions et des éventuels frais afférents sont fixés librement par les
            parties. HEXA COOP ne fournit aucune grille tarifaire au Coopérateur qui fixe, d’un
            commun d’accord avec le client les tarifs appliqués aux prestations assurées. La
            signature du relevé par le client vaut consentement à l’égard desdits tarifs.
            <br />
            <br />
            Les prestations effectuées sont affectées de la T.V.A. au taux applicable (selon la
            nature des prestations).
            <br />
            <br />
            <h3>ARTICLE 4 – EXTRANET</h3>
            Une fois les prestations effectuées, le Coopérateur procède, via son espace personnel
            affilié, à la saisie en ligne d’un relevé de prestations recensant l’intégralité des
            prestations assurées.
            <br />
            <br />
            <h3>ARTICLE 5 – PAIEMENT</h3>
            La Coopérative, en qualité de mandataire, et après validation du relevé de prestations
            par le client, établit la facture et recouvre les sommes dues par ce dernier au titre
            des prestations effectivement effectuées par le Coopérateur.
            <br />
            <br />
            Le client s’engage à régler l’intégralité des sommes dues au titre des prestations
            effectuées dés validation du relevé et, au plus tard, dans les QUINZE (15) jours suivant
            la validation. Les prestations sont réglées, à la convenance du client, par chèque
            bancaire, virement, prélèvement, chèque emploi service universel (CESU) préfinancé,
            carte bancaire (la Coopérative HEXA COOP se réserve le droit d’appliquer des frais sur
            les paiements par carte bancaire) ou par tout autre moyen de paiement auquel la
            Coopérative aura préalablement donné son aval. Tout rejet du règlement fera l’objet de
            frais supplémentaires à la charge du client.
            <br />
            <br />
            Le paiement effectué entre les mains de la Coopérative vaut règlement définitif et non
            équivoque des prestations effectuées et libère le client.
            <br />
            <br />
            <h3>ARTICLE 6 – FISCALITE</h3>
            Conformément à l’article 199 sexdecies du Code général des impôts (C.G.I.), les dépenses
            exposées par les particuliers pour financer des prestations de services à la personne à
            domicile (entrant dans le champ des articles L. 7231-1 et D. 7231-1 du Code du travail)
            ouvrent droit à crédit d’impôt. <br />
            <br />
            Ce crédit d’impôt est égal à 50% des dépenses effectivement supportées par le client,
            retenues dans une certaine limite annuelle. Ces dépenses s’entendent nettes des
            éventuelles aides perçues pour l’emploi de personnes à domicile (aide personnalisée
            d’autonomie, prestation d’accueil du jeune enfant, chèque emploi service universel CESU,
            …).
            <br />
            <br />
            Les dépenses sont retenues dans la limite annuelle de 12.000 euros, majorée de 1.500 € :
            <br />
            <br />
            •&nbsp;&nbsp; &nbsp;par enfant à charge (750 € en cas de résidence alternée) ;
            <br />
            •&nbsp;&nbsp; &nbsp;par membre du foyer âgé de plus de 65 ans ;<br />
            •&nbsp;&nbsp; &nbsp;par ascendant âgé de plus de 65 ans, remplissant les conditions pour
            bénéficier de l’APA lorsque les dépenses sont engagées à son domicile.
            <br />
            <br />
            La limite majorée ne peut en aucun cas excéder la somme de 15.000€.
            <br />
            <br />
            Ce plafond est applicable pour toutes les activités de services à la personne, sauf pour
            :<br />
            <br />
            •&nbsp;&nbsp; &nbsp;Le petit jardinage à domicile : plafond limité à 5.000 € ;
            <br />
            •&nbsp;&nbsp; &nbsp;L’assistance informatique et internet : plafond limité à 3.000 € ;
            <br />
            •&nbsp;&nbsp; &nbsp;Le petit bricolage : plafond limité à 500 € (une intervention ne
            peut dépasser 2 heures).
            <br />
            <br />
            Le plafond de dépenses retenues est porté de 12.000 € à 15.000 € (majoré de 1.500 € par
            personne à charge dans les mêmes conditions que la limite de 12.000€, sans excéder le
            total de 18.000 € après majorations) pour la première année où le bénéficiaire recourt à
            la réduction d’impôt (case 7DQ de la déclaration des revenus).
            <br />
            <br />
            Les dépenses sont retenues dans la limite de 20.000 € lorsque l’un des membres du foyer
            fiscal est titulaire d’une carte d’invalidité d’au moins 80 % (case 7DG de la
            déclaration de revenus) (limite prévue par l’article L. 241-3 du code de&nbsp; l’action
            sociale et des familles) ou perçoit une pension d’invalidité de 3ème catégorie ou le
            complément d’allocation d’éducation de l’enfant handicapé. Aucune majoration ne
            s’applique à cette limite de 20.000€.
            <br />
            <br />
            La Coopérative HEXA COOP assure l’envoi, au client, de l’attestation fiscale permettant
            à ce dernier de faire valoir ses droits à crédit d’impôt. Cette attestation est envoyée
            à chaque début d’année civile et recense le montant des prestations éligibles et ayant
            été effectivement assurées au bénéfice du client.
            <br />
            <br />
            Le client fait son affaire de l’établissement de sa déclaration de revenus et de
            l’imputation du crédit d’impôt dont il bénéfice au titre des prestations éligibles à
            l’article 199 sexdecies du Code général des impôts (C.G.I.). <br />
            <br />
            La responsabilité de la Coopérative HEXA COOP ou de ses Coopérateurs ne saurait être
            recherchée en cas de remise en cause dudit avantage fiscal par l’administration trouvant
            son origine dans une erreur commise par le client lors de sa déclaration de revenus.
            <br />
            <br />
            <h3>ARTICLE 7 – RESPONSABILITÉ</h3>
            Dans le cadre de son adhésion à la Coopérative, le Coopérateur a souscrit les polices
            d’assurance couvrant les dommages qui pourraient être causés au client de part son fait.
            Toute demande de réparation et/ou dédommagement relative à un dommage causé par le
            Coopérateur sera à adresser à ce dernier. La Coopérative ne saurait être tenue pour
            responsable des dommages causés par le fait du Coopérateur à l’occasion de l’exécution
            de prestations. <br />
            <br />
            En cas de manquement du Coopérateur constaté par le client, ce dernier peut en informer
            sans délai la Coopérative qui effectuera les contrôles et opérations nécessaires. <br />
            <br />
            Le client certifie avoir contracté les polices d’assurance d’usage quant à son domicile
            et à sa responsabilité civile afin de couvrir tout dommage qui pourrait être causé par
            son fait au Coopérateur. La responsabilité de la Coopérative ne saurait être engagée en
            cas de dommage imputable au client.
            <br />
            <br />
            <h3>ARTICLE 8 –DONNÉES PERSONNELLES</h3>
            L’utilisation des données personnelles se fait dans le respect des dispositions de la
            Loi n° 78-17 du 6 janvier 1978 et de la Directive du 27 avril 2016 (RGPD). Sauf à ses
            coopérateurs, HEXA COOP s’engage à ne transmettre à aucun tiers l’ensemble des données
            communiquées par les clients dont l’anonymat à l’égard du public est garanti par
            l’utilisation d’identifiants utilisés par ces derniers.
            <br />
            <br />
            Les informations recueillies sont strictement confidentielles et ne seront utilisées que
            dans le cadre des fonctionnements des services d’HEXA COOP. Conformément à la loi
            informatique et libertés du 6 janvier 1978, tout utilisateur bénéfice d’un droit d’accès
            et, le cas échéant, de modification, de rectification, d’oubli et d’opposition de ses
            données personnelles. D’autre part, les informations communiquées pourront également
            être utilisées à titre promotionnel et/ou publicitaires par HEXA COOP ou par l’un de ses
            partenaires en cas d’accord du client.
            <br />
            <br />
            Conformément aux dispositions de la loi du n°78-17 du 6 janvier 1978, l’utilisateur est
            informé qu’il dispose d’un droit d’accès, de rectification et d’opposition portant sur
            les données le concernant en écrivant à l’exploitant du site web dont l’adresse est la
            suivante : contact@hexa-coop.fr
            <br />
            <br />
            1) Collecte des données
            <br />
            <br />
            Les données personnelles collectées et ultérieurement traitées par HEXA COOP sont celles
            que le client ou le Coopérateur lui transmet volontairement via le formulaire
            d’inscription à la plate-forme des services HEXA COOP et lors de leur utilisation
            desdits services. <br />
            <br />
            Les données collectées via le formulaire d’inscription sont les suivantes : <br />
            •&nbsp;&nbsp; &nbsp;nom
            <br />
            •&nbsp;&nbsp; &nbsp;prénoms
            <br />
            •&nbsp;&nbsp; &nbsp;identifiant et mot de passe
            <br />
            •&nbsp;&nbsp; &nbsp;adresse électronique
            <br />
            •&nbsp;&nbsp; &nbsp;adresse postale (domicile)
            <br />
            <br />
            Les données identifiées comme étant obligatoires dans le formulaire d’inscription,
            c’est-à-dire celles entrées manuellement par le client lors de son inscription, sont
            nécessaires pour bénéficier de l’ensemble des prestations des Services HEXA COOP et
            notamment pour satisfaire les réglementations fiscales et financières. <br />
            <br />
            Lorsque l’Internaute (visiteur non identifié) non-enregistré visite et interagit avec le
            Site, certaines données peuvent être recueillies automatiquement, y compris : <br />
            •&nbsp;&nbsp; &nbsp;l’adresse du protocole Internet (IP) du terminal ;<br />
            •&nbsp;&nbsp; &nbsp;le type de terminal, de navigateur et le système d’exploitation ;
            <br />
            •&nbsp;&nbsp; &nbsp;les informations standards du journal du serveur. <br />
            <br />
            2) Finalité des traitements
            <br />
            <br />
            Les données collectées par le Site permettent à HEXA COOP : <br />− avec le consentement
            du client, de lui envoyer des informations sur son compte et les services souscrits
            auprès du Coopérateur et, toujours avec son consentement, des messages promotionnels sur
            les prestations de Services proposées par HEXA COOP ou sur les services et les produits
            des partenaires marketing.
            <br />− de répondre aux&nbsp; demandes des Coopérateurs ou des clients.
            <br />− de prévenir des activités illégales, de faire respecter les présentes CG aini
            que la Charte déontologique de la coopérative HEXA COOP et, de manière générale,
            protéger nos droits et ceux des utilisateurs. <br />
            <br />
            3) Destinataires des données
            <br />
            <br />
            En aucun cas HEXA COOP ou les coopérateurs qui y ont accès ne partageront avec des tiers
            ni ne divulgueront les données personnelles des clients, sauf si ceux-ci l’y ont
            autorisé expressément. <br />
            <br />
            Toutefois, en application d’une loi, d’un règlement ou en vertu d’une décision d’une
            autorité règlementaire ou judiciaire compétente, les données des clients pourront être
            divulguées, si cela s’avère nécessaire, aux fins, pour HEXA COOP, de préserver ses
            droits et ses intérêts. <br />
            <br />
            Le client peut accepter de recevoir des messages promotionnels des partenaires
            commerciaux de HEXA COOP lors de son inscription ou en modifiant ses informations
            personnelles dans son espace personnel. <br />
            <br />
            4) Conservation des données
            <br />
            <br />
            Les données personnelles collectées sont stockées chez l’hébergeur identifié dans les
            Mentions Légales et sont conservées pour la durée strictement nécessaire à la
            réalisation des finalités visées ci-dessus et notamment à la gestion de la relation
            commerciale. Cependant, les données d’identification sont conservées jusqu’à la date de
            suppression du compte au terme de laquelle les données seront anonymisées. <br />
            <br />
            5) Sécurité des données
            <br />
            <br />
            HEXA COOP attache une importance particulière à la protection et à la sécurité des
            données personnelles de ses clients. En conséquence, HEXA COOP met tout en œuvre pour
            assurer l’intégrité et la protection de ces données contre toutes formes de dommage,
            perte, intrusion, divulgation, altération ou destruction. <br />
            <br />
            HEXA COOP ne peut cependant garantir la sécurité à 100% d’une transmission de données ou
            moyen de stockage. En tout état de cause, cet engagement ne pourra s’analyser comme une
            obligation de résultat. <br />
            <br />
            6) Accès aux données personnelles
            <br />
            <br />
            Conformément aux articles 38 et suivants de la loi n° 78-17 du 6 janvier 1978 telle que
            modifiée, le client peut, à tout moment accéder, rectifier, compléter, mettre à jour,
            bloquer ou supprimer ses données personnelles. <br />
            Il bénéficie également d’un accès direct à ces données sur la plate-forme extranet HEXA
            COOP.
            <br />
            <br />
            <h3>ARTICLE 9 – DURÉE - RÉSILIATION</h3>
            Le contrat conclu entre le client et le Coopérateur vaut pour une durée attachée à
            l’exécution des prestations visées. Il prend automatiquement fin à l’issue de
            l’exécution des prestations de façon à ce qu’aucune partie ne puisse en demander
            exécution à posteriori. <br />
            <br />
            Le contrat peut être conclu à durée indéterminée lorsqu’il prévoit l’exécution de
            prestations récurrentes. Dans ce cas, chacune des parties a le pouvoir d’y mettre un
            terme à tout moment, par envoi d’un courrier recommandé avec demande d’avis de réception
            et sous réserve du respect d’un délai de préavis de UN (1) mois. <br />
            <br />
            En cas de manquement de l’une des parties au contrat, l’autre partie aura la possibilité
            d’en demander la résiliation sans délai ni indemnité.{" "}
          </section>

          <section>
            <h2>Conditions générales de prestations de services professionnels</h2>
            <h3>ARTICLE 1 – CHAMP D’APPLICATION</h3>
            La société HEXA COOP est une société Coopérative regroupant sous une seule entité des
            Coopérateurs affiliés réalisant en son nom et pour son compte des prestations de
            services à la personne.
            <br />
            <br />
            HEXA COOP est immatriculée au Registre du Commerce et des Sociétés de CLERMONT-FERRAND
            sous le numéro 834 225 096 et remplit l’intégralité des conditions posées par la&nbsp;
            Loi n° 2005-841 du 26 juillet 2005 relative au développement des services à la personne
            et portant diverses mesures en faveur de la cohésion sociale et notamment l’obligation
            de déclaration d’activité. A ce titre, les prestations qu’elle propose et assure auprès
            des dirigeants et salariés d’entreprises sont éligibles au dispositif du crédit d’impôt
            famille prévu par l’article 244 quater F du Code général des impôts C.G.I..
            <br />
            <br />
            Tout dirigeant d’entreprise souhaitant mettre en place au sein de sa structure les
            services proposés par la société Coopérative HEXA COOP, s’engage à adhérer aux présentes
            conditions générales de prestations de services. Le dirigeant prend l’engagement d’en
            prendre connaissance préalablement à toute commande de manière à ce que toute
            sollicitation auprès de HEXA COOP ou de ses Coopérateurs en suppose adhésion entière et
            sans réserve.
            <br />
            <br />
            <h3>ARTICLE 2 – RELEVÉ ET PRESTATIONS </h3>
            Professionnel et Coopérateur procèdent à la conclusion d’un relevé de prestations de
            services à la personne, lequel est réputé formé dès sa signature. <br />
            <br />
            Les prestations assurées par le Coopérateur affilié à HEXA COOP sont celles relevant du
            domaine des services à la personne tels qu’ils sont définis aux articles L. 7231-1 et D.
            7231-1 du Code du travail et assurées auprès de toutes personnes exerçant une activité
            professionnelle au sein de l’entreprise (article L. 7233-5 du Code du travail) :<br />
            •&nbsp;&nbsp; &nbsp;personnel salarié au sens du droit du travail, c’est-à-dire
            titulaire d’un contrat de travail ;<br />
            •&nbsp;&nbsp; &nbsp;personnel non salarié :<br />
            o&nbsp;&nbsp; &nbsp;le chef d’entreprise dans une entreprise individuelle (profession
            libérale, artisan, commerçant...) ;<br />
            o&nbsp;&nbsp; &nbsp;les dirigeants sociaux d’une société : président, directeur général,
            directeur général délégué, gérant ou membre du directoire.
            <br />
            <br />
            Professionnel et Coopérateur font leur affaire de la planification des prestations de
            services à la personne et notamment de leur nature, lieu et date d’exécution.
            <br />
            <br />
            ATTENTION : L’octroi du crédit d’impôt est subordonné au respect, par le professionnel,
            des conditions fixées par la loi et l’URSSAF.
            <br />
            <br />
            <h3>ARTICLE 3 – LE PRIX </h3>
            Les prix des interventions et des éventuels frais afférents sont fixés librement par les
            parties. HEXA COOP ne fournit aucune grille tarifaire au Coopérateur qui fixe, d’un
            commun d’accord avec le professionnel les tarifs appliqués aux prestations assurées. La
            signature du relevé par le professionnel vaut consentement à l’égard desdits tarifs.
            <br />
            <br />
            Les prestations effectuées sont affectées de la T.V.A. au taux applicable (selon la
            nature des prestations).
            <br />
            <br />
            <h3>ARTICLE 4 – EXTRANET</h3>
            Une fois les prestations effectuées, le Coopérateur procède, via son espace personnel
            affilié, à la saisie en ligne d’un relevé de prestations recensant l’intégralité des
            prestations assurées.
            <br />
            <br />
            <h3>ARTICLE 5 – PAIEMENT</h3>
            La Coopérative, en qualité de mandataire, et après validation du relevé de prestations
            par le professionnel, établit la facture et recouvre les sommes dues par ce dernier au
            titre des prestations effectivement effectuées par le Coopérateur.
            <br />
            <br />
            Le professionnel s’engage à régler l’intégralité des sommes dues au titre des
            prestations effectuées dés validation du relevé et, au plus tard, dans les QUINZE (15)
            jours suivant la validation. Les prestations sont réglées, à la convenance du
            professionnel, par chèque bancaire, virement, prélèvement automatique, chèque emploi
            service universel (CESU) préfinancé, carte bancaire (la Coopérative HEXA COOP se réserve
            le droit d’appliquer des frais sur les paiements par carte bancaire) ou par tout autre
            moyen de paiement auquel la Coopérative HEXA COOP aura préalablement donné son aval.
            Tout rejet du règlement fera l’objet de frais supplémentaires à la charge du
            professionnel.
            <br />
            <br />
            Le paiement effectué entre les mains de la Coopérative vaut règlement définitif et non
            équivoque des prestations effectuées et libère le professionnel.
            <br />
            <br />
            <h3>ARTICLE 6 – FISCALITÉ</h3>
            Conformément à l’article 244 quater F du Code général des impôts (C.G.I.), les dépenses
            exposées par une entreprise et ayant servi à financer des prestations de services
            entrant dans le champ des « activités de services à la personne » au sens des articles
            L. 7231-1 et D. 7231-1 du Code du travail ouvrent droit à un crédit d’impôt égal à 25 %
            desdites dépenses. Ce crédit est calculé en prenant en compte les dépenses effectivement
            supportées par l’entreprise au cours de l’année civile (peu importe la date de clôture
            de l’exercice). <br />
            <br />
            ATTENTION : L’octroi du crédit d’impôt est subordonné au respect, par le professionnel,
            des conditions fixées par la loi et l’URSSAF.
            <br />
            <br />
            Ce crédit d’impôt sur les bénéfices d’un maximum de 500.000 € par exercice prend en
            compte les dépenses effectuées par l’entreprise (à concurrence de 2 millions €). Ce
            crédit est imputé sur l’impôt sur le revenu (IR) ou sur l’impôt sur les sociétés (IS) dû
            par l’entreprise au titre de l’année au cours de laquelle les dépenses ont été engagées.{" "}
            <br />
            <br />
            Les sommes supportées par l’entreprise pour des prestations au profit de ses salariés ou
            dirigeants sociaux n’ont pas le caractère de rémunération (article L. 7233-4 et D.
            7233-8 du Code du travail) et sont à ce titre exonérées d’impôt sur le revenu (IR) dans
            la limite de 1.830 € par an et par bénéficiaire (article 81 du Code général des impôts).{" "}
            <br />
            <br />
            La Coopérative HEXA COOP assure l’envoi, au professionnel, de l’attestation fiscale
            permettant à l’entreprise de faire valoir ses droits à crédit d’impôt. Cette attestation
            est envoyée à chaque début d’année civile et recense le montant des prestations
            éligibles et ayant été effectivement assurées.
            <br />
            <br />
            Le professionnel fait son affaire de l’établissement de sa déclaration de résultat et de
            l’imputation du crédit d’impôt dont il bénéfice au titre des prestations éligibles à
            l’article 244 quater F du Code général des impôts (C.G.I.). <br />
            <br />
            La responsabilité de la Coopérative HEXA COOP ou de ses Coopérateurs ne saurait être
            recherchée en cas de remise en cause dudit avantage fiscal par l’administration trouvant
            son origine dans une erreur commise par le professionnel lors de sa déclaration de
            résultat.
            <br />
            <br />
            <h3>ARTICLE 7 – RESPONSABILITÉ</h3>
            Dans le cadre de son adhésion à la Coopérative HEXA COOP, le Coopérateur a souscrit les
            polices d’assurance couvrant les dommages qui pourraient être causés de part son fait.
            Toute demande de réparation et/ou dédommagement relative à un dommage causé par le
            Coopérateur sera à adresser à ce dernier. La Coopérative HEXA COOP ne saurait être tenue
            pour responsable des dommages causés par le fait du Coopérateur à l’occasion de
            l’exécution de prestations. <br />
            <br />
            En cas de manquement du Coopérateur constaté par le professionnel, ce dernier peut en
            informer sans délai la Coopérative qui effectuera les contrôles et opérations
            nécessaires. <br />
            <br />
            Le professionnel certifie disposer des police d’assurance d’usage afin de couvrir tout
            dommage qui pourrait être causé par son fait, ou celui de ses salariés bénéficiaires des
            prestations, au Coopérateur. La responsabilité de la Coopérative ne saurait être engagée
            en cas de dommage imputable au professionnel.
            <br />
            <br />
            <h3>
              ARTICLE 8 – CONFIDENTIALITÉ - DONNÉES PERSONNELLES DES BÉNÉFICIAIRES DES SERVICES
            </h3>
            L’utilisation des données personnelles se fait dans le respect des dispositions de la
            Loi n° 78-17 du 6 janvier 1978 et de la Directive du 27 avril 2016 (RGPD). Sauf à ses
            coopérateurs, HEXA COOP s’engage à ne transmettre à aucun tiers l’ensemble des données
            personnelles des bénéficiaires des services communiquées par les clients dont l’anonymat
            à l’égard du public est garanti par l’utilisation d’identifiants utilisés par ces
            derniers.
            <br />
            <br />
            Les informations des bénéficiaires des services recueillies sont strictement
            confidentielles et ne seront utilisées que dans le cadre des fonctionnements des
            services d’HEXA COOP. Conformément à la loi informatique et libertés du 6 janvier 1978,
            tout bénéficiaire des services bénéfice d’un droit d’accès et, le cas échéant, de
            modification, de rectification, d’oubli et d’opposition de ses données personnelles.
            D’autre part, les informations communiquées pourront également être utilisées à titre
            promotionnel et/ou publicitaires par HEXA COOP ou par l’un de ses partenaires en cas
            d’accord du client.
            <br />
            <br />
            Conformément aux dispositions de la loi du n°78-17 du 6 janvier 1978, le bénéficiaire
            des services est informé qu’il dispose d’un droit d’accès, de rectification et
            d’opposition portant sur les données le concernant en écrivant à l’exploitant du site
            web dont l’adresse est la suivante : <br />
            contact@hexa-coop.fr
            <br />
            <br />
            1) Collecte des données
            <br />
            <br />
            Les données personnelles collectées et ultérieurement traitées par HEXA COOP sont celles
            que le client, le bénéficiaire des services ou le Coopérateur lui transmet
            volontairement via le formulaire d’inscription à la plate-forme des services HEXA COOP
            et lors de leur utilisation desdits services. <br />
            <br />
            Les données collectées via le formulaire d’inscription sont les suivantes : <br />
            •&nbsp;&nbsp; &nbsp;nom
            <br />
            •&nbsp;&nbsp; &nbsp;prénoms
            <br />
            •&nbsp;&nbsp; &nbsp;identifiant et mot de passe
            <br />
            •&nbsp;&nbsp; &nbsp;adresse électronique
            <br />
            •&nbsp;&nbsp; &nbsp;adresse postale (domicile)
            <br />
            <br />
            Les données identifiées comme étant obligatoires dans le formulaire d’inscription,
            c’est-à-dire celles entrées manuellement par le client ou le bénéficiaire des services
            lors de son inscription, sont nécessaires pour bénéficier de l’ensemble des prestations
            des Services HEXA COOP et notamment pour satisfaire les règlementations fiscales et
            financières. <br />
            <br />
            Lorsque l’Internaute (visiteur non identifié) non-enregistré visite et interagit avec le
            Site, certaines données peuvent être recueillies automatiquement, y compris : <br />
            •&nbsp;&nbsp; &nbsp;l’adresse du protocole Internet (IP) du terminal ;<br />
            •&nbsp;&nbsp; &nbsp;le type de terminal, de navigateur et le système d’exploitation ;
            <br />
            •&nbsp;&nbsp; &nbsp;les informations standards du journal du serveur. <br />
            <br />
            2) Finalité des traitements
            <br />
            <br />
            Les données collectées par le Site permettent à HEXA COOP : <br />− avec le consentement
            du client et du bénéficiaire des services, de lui envoyer des informations sur son
            compte et les services souscrits auprès du Coopérateur et, toujours avec son
            consentement, des messages promotionnels sur les prestations de Services proposées par
            HEXA COOP ou sur les services et les produits des partenaires marketing.
            <br />− de répondre aux&nbsp; demandes des Coopérateurs, du bénéficiaire des services ou
            des clients.
            <br />− de prévenir des activités illégales, de faire respecter les présentes CG ainsi
            que la Charte déontologique de la coopérative HEXA COOP et, de manière générale,
            protéger nos droits et ceux des utilisateurs. <br />
            <br />
            3) Destinataires des données
            <br />
            <br />
            En aucun cas HEXA COOP ou les coopérateurs qui y ont accès ne partageront avec des tiers
            ni ne divulgueront les données personnelles des clients et des bénéficiaires des
            services, sauf si ceux-ci l’y ont autorisé expressément. <br />
            <br />
            Toutefois, en application d’une loi, d’un règlement ou en vertu d’une décision d’une
            autorité règlementaire ou judiciaire compétente, les données des clients ou des
            bénéficiaires des services pourront être divulguées, si cela s’avère nécessaire, aux
            fins, pour HEXA COOP, de préserver ses droits et ses intérêts. <br />
            <br />
            Le client ou le bénéficiaire des services peut accepter de recevoir des messages
            promotionnels des partenaires commerciaux de HEXA COOP lors de son inscription ou en
            modifiant ses informations personnelles dans son espace personnel. <br />
            <br />
            4) Conservation des données
            <br />
            <br />
            Les données personnelles collectées sont stockées chez l’hébergeur identifié dans les
            Mentions Légales et sont conservées pour la durée strictement nécessaire à la
            réalisation des finalités visées ci-dessus et notamment à la gestion de la relation
            commerciale. Cependant, les données d’identification sont conservées jusqu’à la date de
            suppression du compte au terme de laquelle les données seront anonymisées. <br />
            <br />
            5) Sécurité des données
            <br />
            <br />
            HEXA COOP attache une importance particulière à la protection et à la sécurité des
            données personnelles de ses clients. En conséquence, HEXA COOP met tout en œuvre pour
            assurer l’intégrité et la protection de ces données contre toutes formes de dommage,
            perte, intrusion, divulgation, altération ou destruction. <br />
            <br />
            HEXA COOP ne peut cependant garantir la sécurité à 100% d’une transmission de données ou
            moyen de stockage. En tout état de cause, cet engagement ne pourra s’analyser comme une
            obligation de résultat. <br />
            <br />
            6) Accès aux données personnelles
            <br />
            <br />
            Conformément aux articles 38 et suivants de la loi n° 78-17 du 6 janvier 1978 telle que
            modifiée, le client ou le bénéficiaire des services peut, à tout moment accéder,
            rectifier, compléter, mettre à jour, bloquer ou supprimer ses données personnelles.{" "}
            <br />
            Il bénéficie également d’un accès direct à ces données sur la plate-forme extranet HEXA
            COOP.
            <br />
            <br />
            <h3>ARTICLE 9 – DURÉE - RÉSILIATION</h3>
            Le contrat conclu entre le professionnel et le Coopérateur vaut pour une durée attachée
            à l’exécution des prestations visées. Il prend automatiquement fin à l’issue de
            l’exécution des prestations de façon à ce qu’aucune partie ne puisse en demander
            exécution à posteriori. <br />
            <br />
            Le contrat peut être conclu à durée indéterminée lorsqu’il prévoit l’exécution de
            prestations récurrentes. Dans ce cas, chacune des parties a le pouvoir d’y mettre un
            terme à tout moment, par envoi d’un courrier recommandé avec demande d’avis de réception
            et sous réserve du respect d’un délai de préavis de UN (1) mois. <br />
            <br />
            En cas de manquement de l’une des parties au contrat, l’autre partie aura la possibilité
            d’en demander la résiliation sans délai ni indemnité. <br />
            <br />
          </section>
        </div>
      </IonContent>
    </IonPage>
  );
};
