import { IonContent, IonPage } from "@ionic/react";
import { Redirect, Switch } from "react-router";

import { useAuthContext } from "~/contexts/authContext";
import { CooperatorsMembershipBills } from "~/pages/Cooperators/CooperatorsMembershipBills";
import { CooperatorsQuotationBills } from "~/pages/Cooperators/CooperatorsQuotationBills";
import { CooperatorsWaitingMembership } from "~/pages/Cooperators/CooperatorsWaitingMembership";
import { CreateEditMembership } from "~/pages/Cooperators/CreateEditMembership";
import { SubNavigation } from "~/components/SubNavigation";
import { ProtectedRoute } from "~/Router";

export const CooperatorLayout = () => {
  const { isAuth } = useAuthContext();

  return (
    <IonPage>
      <IonContent className="container">
        <SubNavigation
          links={[
            {
              label: "Factures de prestation",
              to: "/cooperator-dashboard/service-bills"
            },
            {
              label: "Adhésions en attente",
              to: "/cooperator-dashboard/waiting-memberships"
            },
            {
              label: "Factures d'adhésions",
              to: "/cooperator-dashboard/membership-bills"
            }
          ]}
        />
        <Switch>
          <ProtectedRoute exact path="/cooperator-dashboard/service-bills" condition={isAuth}>
            <CooperatorsQuotationBills />
          </ProtectedRoute>
          <ProtectedRoute exact path="/cooperator-dashboard/waiting-memberships" condition={isAuth}>
            <CooperatorsWaitingMembership />
          </ProtectedRoute>
          <ProtectedRoute exact path="/cooperator-dashboard/membership-bills" condition={isAuth}>
            <CooperatorsMembershipBills />
          </ProtectedRoute>

          <ProtectedRoute exact path="/cooperator-dashboard/memberships/create" condition={isAuth}>
            <CreateEditMembership />
          </ProtectedRoute>
          <ProtectedRoute
            exact
            path="/cooperator-dashboard/memberships/edit/:membership_id"
            condition={isAuth}
          >
            <CreateEditMembership editMode />
          </ProtectedRoute>

          <Redirect to={"/cooperator-dashboard/service-bills"} />
        </Switch>
      </IonContent>
    </IonPage>
  );
};
