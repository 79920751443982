import type {
  GetStatisticsParams,
  GlobalStatistics,
  RecoveryStatistics,
  StatisticsType
} from "~/types/statistics.types";

import { useQuery } from "@tanstack/react-query";

import { queryKeys } from "~/config/query-keys-constants";
import { api } from "./_config";

export const getStatistics = async (type: StatisticsType, params: GetStatisticsParams) => {
  const response = await api.get(`/api/statistics/main`, { params: { type, ...params } });
  return response.data;
};

export const useGetStatisticsQuery = <T>(
  type: StatisticsType,
  params: GetStatisticsParams,
  options: { enabled?: boolean; suspense?: boolean } = {}
) => {
  return useQuery<T>(
    [queryKeys.statisticsMain, type, params],
    async () => await getStatistics(type, params),
    options
  );
};

export const getHomeStatistics = async () => {
  const response = await api.get<GlobalStatistics>(`/api/statistics/global`);
  return response.data;
};

export const getRecoveryStatistics = async ({
  startDate,
  endDate
}: {
  startDate?: string;
  endDate?: string;
}) => {
  const response = await api.get<RecoveryStatistics>(`/api/statistics/recoveries`, {
    params: { startDate, endDate }
  });
  return response.data;
};
