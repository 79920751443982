import { api } from "~/api/_config";

export type FileDeliverType =
  | "beneficiary-transactions"
  | "contract-billed"
  | "contract-unbilled"
  | "export-compta"
  | "export-divers"
  | "export-quotations-csv"
  | "export-quotation-invoices-csv"
  | "export-quotation-cooperator-invoices-csv"
  | "membership-billed"
  | "membership-transactions"
  | "membership-unbilled"
  | "quotation-billed"
  | "quotation-cooperator-billed"
  | "quotation-devis"
  | "quotation"
  | "virement"
  | "attestation"
  | "geoloc-cooperators-pdf"
  | "multiple-attestations"
  | "multiple-quotation-invoices";

export type AnonymousFileDeliverType =
  | "adhesion"
  | "attestation"
  | "contrat"
  | "facture-adhesion"
  | "facture-contrat"
  | "facture-releve-cooperateur"
  | "facture-releve"
  | "releve";

export type FileDeliverParams = {
  id?: number | string | null | undefined;
  attestationIds?: number[] | string[] | null | undefined;
  quotationIds?: number[] | string[] | null | undefined;
  path?: string | null | undefined;
  dateStart?: string | null | undefined;
  dateEnd?: string | null | undefined;
  geoloc?: {
    // firstName: string | null;
    // lastName: string | null;
    latitude: number | null;
    longitude: number | null;
    radius: number | null;
    prestations: number[] | null;
    selectedCooperators?: number[];
  };
};

export type GetFileDeliverParams = {
  type: FileDeliverType | AnonymousFileDeliverType;
  params?: FileDeliverParams;
  isAnonymous?: boolean;
};

export const getFileDeliver = async ({
  type,
  params,
  isAnonymous = false
}: GetFileDeliverParams) => {
  let postRequest = false;
  let apiPath = "";

  switch (isAnonymous) {
    case true:
      switch (type) {
        case "adhesion":
          apiPath = `/api/_anon/adhesion/${params?.id}`;
          break;
        case "attestation":
          apiPath = `/api/_anon/attestation/${params?.id}`;
          break;
        case "contrat":
          apiPath = `/api/_anon/contrat/${params?.id}`;
          break;
        case "facture-adhesion":
          apiPath = `/api/_anon/facture/adhesion/${params?.id}`;
          break;
        case "facture-contrat":
          apiPath = `/api/_anon/facture/contrat/${params?.id}`;
          break;
        case "facture-releve-cooperateur":
          apiPath = `/api/_anon/facture/releve/cooperator/${params?.id}`;
          break;
        case "facture-releve":
          apiPath = `/api/_anon/facture/releve/${params?.id}`;
          break;
        case "releve":
          apiPath = `/api/_anon/releve/${params?.id}`;
          break;
        default:
          throw new Error("Unknown file deliver type");
      }
      break;
    case false:
      switch (type) {
        case "contract-billed":
          apiPath = `/api/file/deliver/contract/billed/${params?.id}`;
          break;
        case "contract-unbilled":
          apiPath = `/api/file/deliver/contract/unbilled/${params?.id}`;
          break;
        case "export-compta":
          apiPath = `/api/file/deliver/export/compta`;
          break;
        case "export-divers":
          apiPath = `/api/file/deliver/export/divers`;
          break;
        case "export-quotations-csv":
          apiPath = `/api/admin/quotations/export/csv`;
          break;
        case "export-quotation-invoices-csv":
          apiPath = `/api/admin/quotations/export/invoices/csv`;
          break;
        case "export-quotation-cooperator-invoices-csv":
          apiPath = `/api/admin/quotations/export/cooperator/invoices/csv`;
          break;
        case "membership-billed":
          apiPath = `/api/file/deliver/membership/billed/${params?.id}`;
          break;
        case "membership-transactions":
          apiPath = `/api/file/deliver/membership/transactions/${params?.id}`;
          break;
        case "membership-unbilled":
          apiPath = `/api/file/deliver/membership/unbilled/${params?.id}`;
          break;
        case "quotation-billed":
          apiPath = `/api/file/deliver/quotation/billed/${params?.id}`;
          break;
        case "quotation-cooperator-billed":
          apiPath = `/api/file/deliver/quotation/cooperator/billed/${params?.id}`;
          break;
        case "quotation-devis":
          apiPath = `/api/file/deliver/quotation/devis/${params?.id}`;
          break;
        case "quotation":
          apiPath = `/api/file/deliver/quotation/${params?.id}`;
          break;

        case "virement":
          postRequest = true;
          apiPath = `/api/file/deliver/virement`;
          break;
        case "attestation":
          apiPath = `/api/admin/attestations/pdf/${params?.id}`;
          break;
        case "beneficiary-transactions":
          apiPath = `/api/file/deliver/beneficiary/transactions/${params?.id}`;
          break;
        case "multiple-attestations":
          postRequest = true;
          apiPath = `api/admin/attestations/pdf/download`;
          break;
        case "multiple-quotation-invoices":
          postRequest = true;
          apiPath = `/api/admin/quotations/invoices/cooperator/pdfs/download`;
          break;
        case "geoloc-cooperators-pdf":
          apiPath = `/api/cooperator/geoloc/pdf`;
          break;
        default:
          throw new Error("Unknown file deliver type");
      }
      break;
  }

  const response = postRequest
    ? await api.post<string>(
        apiPath,
        {
          ...(params?.path ? { path: params.path } : {}),
          ...(params?.attestationIds ? { attestation_id: params.attestationIds } : {}),
          ...(params?.quotationIds ? { quotation_id: params.quotationIds } : {})
        },
        {
          responseType: "blob",
          ...(isAnonymous ? { headers: { Authorization: null } } : {})
        }
      )
    : await api.get<string>(apiPath, {
        params: {
          ...(params?.path ? { path: params.path } : {}),
          ...(params?.attestationIds ? { attestation_id: params.attestationIds } : {}),
          ...(params?.quotationIds ? { quotation_id: params.quotationIds } : {}),
          ...(params?.dateStart ? { date_start: params.dateStart } : {}),
          ...(params?.dateEnd ? { date_end: params.dateEnd } : {}),
          // ...(params?.geoloc?.firstName ? { lat: params.geoloc.firstName } : {}),
          // ...(params?.geoloc?.lastName ? { lat: params.geoloc.lastName } : {}),
          ...(params?.geoloc?.latitude ? { lat: params.geoloc.latitude } : {}),
          ...(params?.geoloc?.longitude ? { long: params.geoloc.longitude } : {}),
          ...(params?.geoloc?.radius ? { rayon: params.geoloc.radius } : {}),
          ...(params?.geoloc?.prestations ? { prestas: params.geoloc.prestations.join(",") } : {}),
          ...(params?.geoloc?.selectedCooperators
            ? { selected: params.geoloc.selectedCooperators.join(",") }
            : {})
        },
        responseType: "blob",
        ...(isAnonymous ? { headers: { Authorization: null } } : {})
      });

  const filename = response.headers["content-disposition"]
    .split("filename=")[1]
    .replace(/"/g, "")
    .split(";")[0];

  const filetype = response.headers["content-type"];

  return { filename, filetype, file: response.data };
};
