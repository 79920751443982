import type { AdvancedSearchParams } from "~/components/TableLayout";
import type { MainTableProps } from "~/components/ui/MainTable";

import { useState } from "react";
import { useInfiniteQuery, useMutation } from "@tanstack/react-query";
import { add as addIcon, createOutline, keyOutline, locationOutline } from "ionicons/icons";

import { userRoles } from "~/config/api-constants";
import { queryKeys } from "~/config/query-keys-constants";
import { formatDisplayDate } from "~/utils/misc";
import { useUIContext } from "~/contexts/uiContext";
import { handleApiError } from "~/services/errors";
import { getNextPageParamFn } from "~/api/_config";
import { getUsers, resendCooperatorCode } from "~/api/user";
import { AdministrationCoopsSearchForm } from "~/components/AdvancedSearchForms/Administration/AdministrationCoopsSearchForm";
import { TableLayout } from "~/components/TableLayout";
import { ExternalLink } from "~/components/ui/Link";

export const AdministrationCooperators = () => {
  const { toast } = useUIContext();

  const [advancedSearchParams, setAdvancedSearchParams] = useState<AdvancedSearchParams>(null);

  const queryParams = { role: userRoles.ROLE_COOPERATOR, ...advancedSearchParams };

  const { data, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: [queryKeys.users, queryParams],
    queryFn: ({ pageParam, signal }) => getUsers({ ...queryParams, page: pageParam, signal }),
    getNextPageParam: getNextPageParamFn
  });

  const resendCooperatorCodeMutation = useMutation(resendCooperatorCode, {
    onSuccess: () => {
      toast("Le mot de passe a été renvoyé avec succès.", "success");
    },
    onError(error) {
      handleApiError(error, toast);
    }
  });

  const globalActions = [
    {
      label: "Nouveau coopérateur",
      icon: addIcon,
      href: "/administration/cooperators/create"
    },
    {
      label: "Géolocalisation des coopérateurs",
      icon: locationOutline,
      fill: "outline" as const,
      href: "/administration/cooperators/geolocation"
    }
  ];

  const tableData: MainTableProps["data"] =
    data?.pages.flat().map((user) => ({
      itemId: user?.id,
      actions: [
        {
          label: "Renvoyer un nouveau mot de passe",
          icon: keyOutline,
          color: "amber",
          action: () => (user?.id ? resendCooperatorCodeMutation.mutate(user.id) : null)
        },
        {
          label: "Éditer le coopérateur",
          icon: createOutline,
          color: "blue",
          href: `/administration/cooperators/edit/${user?.id}`
        }
      ],
      columns: [
        { key: "lastname", label: "Nom", display: user?.lastname },
        { key: "firstname", label: "Prénom", display: user?.firstname },
        { key: "companyName", label: "Société", display: user?.companyName },
        {
          key: "email",
          label: "Email",
          display: <ExternalLink to={`mailto:${user?.email}`}>{user?.email}</ExternalLink>
        },
        {
          key: "phone",
          label: "Téléphone",
          display: (
            <ExternalLink to={`tel:${user?.mobileNumber ?? user?.fixeNumber}`}>
              {user?.mobileNumber ?? user?.fixeNumber}
            </ExternalLink>
          )
        },
        { key: "isActive", label: "Activé", display: user?.isActive ? "Oui" : "Non" },
        {
          key: "createdAt",
          label: "Date création",
          display: user?.createdAt ? formatDisplayDate(user.createdAt) : "",
          sort: user?.createdAt,
          search: user?.createdAt ? formatDisplayDate(user.createdAt) : ""
        },
        {
          key: "lastLogin",
          label: "Dernière connexion",
          display: user?.lastLogin ? formatDisplayDate(user.lastLogin) : "",
          sort: user?.lastLogin,
          search: user?.lastLogin ? formatDisplayDate(user.lastLogin) : ""
        }
      ]
    })) || [];

  return (
    <>
      <TableLayout
        data={tableData}
        isLoading={isFetching || isFetchingNextPage}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        globalActions={globalActions}
        AdvancedSearchForm={AdministrationCoopsSearchForm}
        advancedSearchParams={advancedSearchParams}
        handleAdvancedSearch={setAdvancedSearchParams}
        handleSimpleSearch={setAdvancedSearchParams}
      />
    </>
  );
};
