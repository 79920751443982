import { useEffect, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Controller, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router";

import { legalForms, userRoles } from "~/config/api-constants";
import { queryKeys } from "~/config/query-keys-constants";
import { removeSpaces } from "~/utils/misc";
import { useUIContext } from "~/contexts/uiContext";
import { useDebounce } from "~/hooks/useDebounce";
import { handleApiError } from "~/services/errors";
import { handleSiretChange } from "~/services/siret";
import { getGouvAddresses } from "~/api/gouv-addresses";
import { createFinancer, getUser, getUsers, updateFinancer } from "~/api/user";
import { Button } from "~/components/ui/Button/Button";
import { FormComboBox } from "~/components/ui/FormComboBox";
import { FormInput } from "~/components/ui/FormInput";
import { FormRow } from "~/components/ui/FormRow";
import { FormSelect } from "~/components/ui/FormSelect";
import { FormTitle } from "~/components/ui/FormTitle";
import { LoadingSpinner } from "~/components/ui/LoadingSpinner";
import { FinancerSchema } from "~/schemas/financer.schema";

export const CreateEditFinancer = ({ editMode = false }: { editMode?: boolean }) => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const { user_id: userId } = useParams<{ user_id?: string }>();
  const { toast } = useUIContext();

  // Get the financer when form is in edit mode
  const { data: financerToEdit, isLoading: isFinancerLoading } = useQuery({
    queryKey: [queryKeys.users, userId],
    queryFn: () => getUser({ id: userId ? parseInt(userId) : null }),
    enabled: !!userId
  });

  // Get commercials list for combobox autocomplete
  const [commercialsSearch, setCommercialsSearch] = useState("");
  const debouncedCommercialsSearch = useDebounce<string>(commercialsSearch, 500);
  const commercialsQueryParams = {
    role: userRoles.ROLE_COMMERCIAL,
    searchName: debouncedCommercialsSearch,
    sort: { key: "lastname", value: "ASC" }
  } as const;
  const { data: commercials, isLoading: isCommercialsLoading } = useQuery({
    queryKey: [queryKeys.users, commercialsQueryParams],
    queryFn: () => getUsers({ ...commercialsQueryParams })
  });

  // Get addresses list for combobox autocomplete
  const [addressSearch, setAddressSearch] = useState("");
  const debouncedAddressSearch = useDebounce<string>(addressSearch, 500);
  const { data: addresses, isLoading: isAddressesLoading } = useQuery({
    queryKey: ["addresses", { search: debouncedAddressSearch }],
    queryFn: () => getGouvAddresses({ search: debouncedAddressSearch }),
    enabled: debouncedAddressSearch.length > 3
  });

  // Set first search values for comboboxes
  useEffect(() => {
    if (financerToEdit) {
      setCommercialsSearch(financerToEdit.commercial?.lastname || "");
      setAddressSearch(financerToEdit.address || "");
    }
  }, [financerToEdit]);

  // Financer mutation
  const financerMutation = useMutation({
    mutationFn: (data: FinancerSchema) =>
      editMode
        ? updateFinancer({ userId: userId ? parseInt(userId) : null, data })
        : createFinancer({ data }),
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: [queryKeys.users] });
      toast(
        editMode
          ? "Le financeur a été modifié avec succès."
          : "Le financeur a été créé avec succès.",
        "success"
      );
      history.push("/prefinancing-contracts/financers");
    },
    onError(error) {
      handleApiError(error, toast);
    }
  });

  // Define form
  const form = useForm<FinancerSchema>({
    resolver: zodResolver(FinancerSchema),
    values: {
      lastname: financerToEdit?.lastname || "",
      firstname: financerToEdit?.firstname || "",
      email: financerToEdit?.email || "",
      mobileNumber: financerToEdit?.mobileNumber || "",
      fixeNumber: financerToEdit?.fixeNumber || null,
      address: financerToEdit?.address || "",
      zipcode: financerToEdit?.zipcode || "",
      city: financerToEdit?.city || "",
      isPro: financerToEdit?.isPro || true,
      companyName: financerToEdit?.companyName || "",
      legalForm: financerToEdit?.legalForm || "",
      siret: financerToEdit?.siret || "",
      tvaNumber: financerToEdit?.tvaNumber || "",
      commercial: financerToEdit?.commercial?.id.toString() || ""
    }
  });

  const isProSelected = form.watch("isPro");

  const onSubmit = (data: FinancerSchema) => {
    financerMutation.mutate(data);
  };

  if (!!userId && isFinancerLoading) {
    return <LoadingSpinner size="lg" fullPage />;
  }

  if (!!userId && !financerToEdit) {
    return <p className="text-center">Le financeur à éditer est introuvable.</p>;
  }

  return (
    <>
      <h2 className="mt-6">
        {editMode
          ? `Édition d'un financeur (${financerToEdit?.lastname} ${financerToEdit?.firstname})`
          : "Création d'un financeur"}
      </h2>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <FormRow>
          <Controller
            name="commercial"
            control={form.control}
            render={({ field: { onChange, onBlur, ref } }) => {
              return (
                <FormComboBox
                  label="Commercial associé"
                  onChange={onChange}
                  onBlur={onBlur}
                  onSearchChange={setCommercialsSearch}
                  options={
                    commercials?.map((commercial) => ({
                      id: commercial?.id || 0,
                      label: `${commercial?.lastname || ""} ${commercial?.firstname || ""}`,
                      value: commercial?.id?.toString() || ""
                    })) || []
                  }
                  defaultOption={
                    financerToEdit?.commercial?.id
                      ? {
                          id: financerToEdit.commercial.id,
                          label: `${financerToEdit.commercial.lastname} ${financerToEdit.commercial.firstname}`,
                          value: financerToEdit.commercial.id.toString()
                        }
                      : null
                  }
                  infoMessage="Commercial associé au financeur."
                  errorMessage={form.formState.errors.commercial?.message}
                  isLoading={isCommercialsLoading}
                  ref={ref}
                />
              );
            }}
          />
        </FormRow>

        <FormTitle as="h3">Informations du financeur</FormTitle>

        <FormRow>
          <FormSelect
            label="Type de financeur"
            disabled
            errorMessage={form.formState.errors.isPro?.message}
            {...form.register("isPro", {
              setValueAs: (v) => v === "true" || v === true
            })}
          >
            {/* <option value="false">Particulier</option> */}
            <option value="true">Professionnel</option>
          </FormSelect>
        </FormRow>

        <FormRow>
          <FormInput
            label="Nom"
            errorMessage={form.formState.errors.lastname?.message}
            {...form.register("lastname")}
          />
          <FormInput
            label="Prénom"
            errorMessage={form.formState.errors.firstname?.message}
            {...form.register("firstname")}
          />
          <FormInput
            label="Email"
            errorMessage={form.formState.errors.email?.message}
            {...form.register("email")}
          />
          <FormInput
            label="N° de mobile"
            errorMessage={form.formState.errors.mobileNumber?.message}
            {...form.register("mobileNumber")}
          />
          <FormInput
            label="N° de fixe"
            optional
            errorMessage={form.formState.errors.fixeNumber?.message}
            {...form.register("fixeNumber")}
          />
        </FormRow>

        <FormRow>
          <Controller
            name="address"
            control={form.control}
            render={({ field: { onChange, onBlur, ref } }) => {
              return (
                <FormComboBox
                  label={isProSelected ? "Adresse de l'entreprise" : "Adresse"}
                  onChange={(value) => {
                    const address = addresses?.find((address) => address.id === value);
                    if (!address) return onChange(value || "");
                    form.setValue("zipcode", address.postcode || "", { shouldValidate: true });
                    form.setValue("city", address.district || address.city || "", {
                      shouldValidate: true
                    });
                    form.trigger("address");
                    onChange(
                      address.name ||
                        (address.housenumber && address.street
                          ? `${address.housenumber} ${address.street}`
                          : "")
                    );
                  }}
                  onBlur={onBlur}
                  onSearchChange={setAddressSearch}
                  allowCustomValue={true}
                  options={
                    addresses?.map((address) => ({
                      id: address.id || 0,
                      value: address.id || "",
                      label: address.label || "",
                      labelInInput: address.name || `${address.housenumber} ${address.street}`
                    })) || []
                  }
                  defaultOption={
                    financerToEdit?.address
                      ? {
                          id: financerToEdit.address,
                          value: financerToEdit.address,
                          label: financerToEdit.address
                        }
                      : null
                  }
                  infoMessage="Selectionnez une des propositions afin de correspondre aux données de l'URSSAF."
                  warningMessage={
                    isProSelected
                      ? "Adresse de l'entreprise à facturer et non celle du client."
                      : null
                  }
                  errorMessage={form.formState.errors.address?.message}
                  isLoading={isAddressesLoading}
                  ref={ref}
                />
              );
            }}
          />

          <FormInput
            label={isProSelected ? "Code postal de l'entreprise" : "Code postal"}
            warningMessage={
              isProSelected
                ? "Code postal de l'entreprise à facturer et non celui du client."
                : null
            }
            errorMessage={form.formState.errors.zipcode?.message}
            {...form.register("zipcode")}
          />
          <FormInput
            label={isProSelected ? "Ville de l'entreprise" : "Ville"}
            warningMessage={
              isProSelected ? "Ville de l'entreprise à facturer et non celle du client." : null
            }
            errorMessage={form.formState.errors.city?.message}
            {...form.register("city")}
          />
        </FormRow>

        {isProSelected ? (
          <>
            <FormTitle as="h3">Informations professionnelles du financeur</FormTitle>

            <FormRow>
              <FormInput
                label="Nom de la société"
                errorMessage={form.formState.errors.companyName?.message}
                {...form.register("companyName")}
              />
              <FormSelect
                label="Forme juridique"
                errorMessage={form.formState.errors.legalForm?.message}
                {...form.register("legalForm")}
              >
                {Object.values(legalForms).map((legalForm) => (
                  <option key={legalForm} value={legalForm}>
                    {legalForm}
                  </option>
                ))}
              </FormSelect>
              <FormInput
                label="SIRET"
                errorMessage={form.formState.errors.siret?.message}
                {...form.register("siret", {
                  onChange: handleSiretChange,
                  setValueAs: (v) => removeSpaces(v)
                })}
              />
              <FormInput
                label="Numéro de TVA"
                errorMessage={form.formState.errors.tvaNumber?.message}
                {...form.register("tvaNumber")}
              />
            </FormRow>
          </>
        ) : null}

        <div className="mt-12 flex justify-end gap-4 border-t border-grey-200 pt-6">
          <Button fill="clear" onClick={() => history.goBack()}>
            Annuler
          </Button>
          <Button type="submit" disabled={financerMutation.isLoading}>
            {editMode ? "Modifier le financeur" : "Créer le financeur"}
          </Button>
        </div>
      </form>
    </>
  );
};
