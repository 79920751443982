import { IonContent, IonPage } from "@ionic/react";
import dayjs from "dayjs";
import { Redirect, Switch } from "react-router";

import { useAuthContext } from "~/contexts/authContext";
import { AttestationsPage } from "~/pages/Attestations/Attestations";
import { SubNavigation } from "~/components/SubNavigation";
import { ProtectedRoute } from "~/Router";

const currentYear = dayjs().year();
const lastYear = currentYear - 1;
const maxYear = 2019;

// [2023, 2022, 2021, 2020, 2019, 2018]
const yearsRange = Array.from({ length: lastYear - maxYear + 1 }, (_, i) =>
  (lastYear - i).toString()
);

export const AttestationsLayout = () => {
  const { isAuth } = useAuthContext();

  return (
    <IonPage>
      <IonContent className="container">
        <SubNavigation
          links={yearsRange.map((year) => ({ label: year, to: `/attestations/${year}` }))}
        />
        <Switch>
          {yearsRange.map((year) => (
            <ProtectedRoute exact path={`/attestations/${year}`} condition={isAuth} key={year}>
              <AttestationsPage year={year} />
            </ProtectedRoute>
          ))}
          <Redirect to={`/attestations/${lastYear}`} />
        </Switch>
      </IonContent>
    </IonPage>
  );
};
