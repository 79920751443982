import type { QuotationRowBilledStats, StatisticsChartProps } from "~/types/statistics.types";

import { useMemo, useState } from "react";

import { formatMoney } from "~/utils/misc";
import { useGetStatisticsQuery } from "~/api/statistics";
import { StatisticsType } from "~/types/statistics.types";
import { DailyLineChart } from "../charts/DailyLineChart";
import { FormComboBox } from "../ui/FormComboBox";
import { LoadingSpinner } from "../ui/LoadingSpinner";

const parseQuotationsRowBilledStats = (data?: QuotationRowBilledStats) => {
  if (!data) return;

  const rawData = data.quotationsRowBilledStatsN[0];
  const rawCumul = data.quotationsRowBilledStatsN[1];

  const groups: Record<
    string,
    { id: string; name: string; data: Record<string, number>; total: number }
  > = {};

  for (const dailyDataGroup of Object.values(rawData)) {
    for (const [categId, { name, nb, datefull }] of Object.entries(dailyDataGroup)) {
      if (!groups[categId]) {
        groups[categId] = { id: categId, name, data: {}, total: 0 };
      }
      if (datefull) {
        const isoDate = datefull.split("/").reverse().join("-");
        groups[categId]!.data[isoDate] = Number.parseFloat(nb);
      }
    }
  }
  for (const [categId, { total }] of Object.entries(rawCumul)) {
    if (groups[categId]) {
      groups[categId]!.total = total;
    }
  }

  return Object.values(groups).filter((g) => Object.keys(g.data).length);
};

export const QuotationRowBilledChart = (params: StatisticsChartProps) => {
  const { data, isLoading } = useGetStatisticsQuery<QuotationRowBilledStats>(
    StatisticsType.QUOTATION_ROW_BILLED,
    params
  );
  const stats = useMemo(() => parseQuotationsRowBilledStats(data), [data]);
  const statsOptions = useMemo(
    () =>
      stats
        ? stats.map((row, idx) => ({
            label: row.name,
            value: idx.toString(),
            id: row.id
          }))
        : [],
    [stats]
  );
  const [categ1, setCateg1] = useState<number>(0);
  const [categ2, setCateg2] = useState<number>(1);
  const [categ3, setCateg3] = useState<number>(2);

  if (isLoading) {
    return (
      <div className="my-20 flex w-full items-center justify-center gap-4">
        <LoadingSpinner /> Chargement des données
      </div>
    );
  }

  if (!data || !stats || !stats.length) {
    return (
      <div className="my-20 flex w-full items-center justify-center gap-4">
        Aucun montant facturé sur la période
      </div>
    );
  }

  return (
    <>
      <fieldset>
        <legend>Services à comparer</legend>
        <div className="mt-2 flex w-full flex-wrap gap-4">
          {stats.length >= 1 ? (
            <FormComboBox
              className="min-w-80 grow"
              defaultOption={statsOptions[categ1 as number]!}
              onChange={(value) => setCateg1(+value)}
              onSearchChange={() => {}}
              options={statsOptions}
              isLoading={false}
            />
          ) : null}
          {stats.length >= 2 ? (
            <FormComboBox
              className="min-w-80 grow"
              defaultOption={statsOptions[categ2 as number]!}
              onChange={(value) => setCateg2(+value)}
              onSearchChange={() => {}}
              options={statsOptions}
              isLoading={false}
            />
          ) : null}
          {stats.length >= 3 ? (
            <FormComboBox
              className="min-w-80 grow"
              defaultOption={statsOptions[categ3 as number]!}
              onChange={(value) => setCateg3(+value)}
              onSearchChange={() => {}}
              options={statsOptions}
              isLoading={false}
            />
          ) : null}
        </div>
      </fieldset>

      <div className="mt-2 h-[550px] sm:px-4">
        <DailyLineChart
          datasets={[
            ...(stats.length >= 1
              ? [
                  {
                    label: `${stats[categ1]!.name} (${formatMoney(stats[categ1]!.total)})`,
                    data: stats[categ1]!.data,
                    backgroundColor: "#82a907",
                    borderWidth: 1,
                    borderColor: "#82a907"
                  }
                ]
              : []),
            ...(stats.length >= 2
              ? [
                  {
                    label: `${stats[categ2]!.name} (${formatMoney(stats[categ2]!.total)})`,
                    data: stats[categ2]!.data,
                    backgroundColor: "#386982",
                    borderWidth: 1,
                    borderColor: "#386982"
                  }
                ]
              : []),
            ...(stats.length >= 3
              ? [
                  {
                    label: `${stats[categ3]!.name} (${formatMoney(stats[categ3]!.total)})`,
                    data: stats[categ3]!.data,
                    backgroundColor: "#0284c7",
                    borderWidth: 1,
                    borderColor: "#0284c7"
                  }
                ]
              : [])
          ]}
        />
      </div>
    </>
  );
};
