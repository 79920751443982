import type { GeolocationUser } from "~/types/user.types";
import type { DeepPartial } from "~/types/utils.types";

import { api } from "~/api/_config";

export const getCooperatorsByGeolocation = async ({
  // firstName,
  // lastName,
  latitude,
  longitude,
  radius,
  prestations
}: {
  // firstName: string | null;
  // lastName: string | null;
  latitude: number | null;
  longitude: number | null;
  radius: number | null;
  prestations: number[] | null;
}) => {
  const response = await api.get<DeepPartial<GeolocationUser[]>>(`/api/cooperator/geoloc/find`, {
    params: {
      // firstName,
      // lastName,
      lat: latitude,
      long: longitude,
      rayon: radius,
      prestas: prestations?.join(",") || null
    }
  });
  return response.data;
};

export const getPdfByGeolocation = async ({
  latitude,
  longitude,
  radius,
  prestas,
  selectedCooperators
}: {
  latitude: number | null;
  longitude: number | null;
  radius: number | null;
  prestas: number[] | null;
  selectedCooperators: number[];
}) => {
  const response = await api.get<Blob>(`/api/cooperator/geoloc/pdf`, {
    params: {
      lat: latitude,
      long: longitude,
      rayon: radius,
      prestas: prestas?.join(",") || null,
      cooperators: selectedCooperators.join(",")
    },
    responseType: "blob"
  });
  return response.data;
};
