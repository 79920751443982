import { z } from "zod";

export const MembershipSchema = z.object({
  cooperator: z.string().min(1, "Veuillez sélectionner un coopérateur."),
  contactFirstname: z.string().min(1, "Veuillez renseigner un prénom."),
  contactLastname: z.string().min(1, "Veuillez renseigner un nom."),
  contactEmail: z.string().email("Veuillez renseigner un email valide."),
  contactPhone: z.string().min(1, "Veuillez renseigner un numéro de téléphone."),
  amountMembership: z.string().min(1, "Veuillez saisir un montant."),
  discount: z.string().optional().nullable(),
  startAt: z.string().min(1, "Veuillez renseigner une date de début."),
  endAt: z.string().min(1, "Veuillez renseigner une date de fin."),
  comments: z.string().optional().nullable(),
  hasSocialPart: z.boolean()
});

export type MembershipSchema = z.infer<typeof MembershipSchema>;
