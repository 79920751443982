import { IonContent, IonPage } from "@ionic/react";

export const PersonalDataSecurityPolicyPage = () => {
  return (
    <IonPage>
      <IonContent className="container">
        <div className="mx-auto max-w-5xl">
          <h1 className="text-center text-lg font-bold uppercase text-grey-700">
            Annexe contractuelle
            <br />
            relative à la protection des données à caractère personnel
            <br />
            Hexa Coop
          </h1>

          <section className="mt-6 text-justify text-sm font-medium text-grey-700">
            <h2 className="mb-6 text-center text-base font-bold uppercase">Preambule</h2>
            <h3 className="mb-4 mt-6 text-center">- I -</h3>
            <p className="mt-4">
              Suivant offre contractuelle, le coopérateur - responsable de traitement (RT) - a
              souscrit différents services et prestations suivant contrat principal formant un tout
              juridique avec le présent avenant (ci-après « le contrat principal ») tels que décrits
              en ANNEXE I et constituant autant que faire se peut la finalité du traitement entre
              les parties soussignées.
            </p>
            <p className="mt-4">
              En considération du Règlement général (UE) de protection des données personnelles du
              27 avril 2016 (ci-après « RGPD »), les prestations souscrites sont susceptibles
              d&apos;être qualifiées de traitement de données à caractère personnel en considération
              des données collectées et transférées par le responsable de traitement à HEXA COOP,
              qui le cas échéant peut être amené à les héberger.
            </p>

            <h3 className="mb-4 mt-6 text-center">- II -</h3>
            <p className="mt-4">
              Le responsable de traitement est la personne physique ou morale qui détermine les
              finalités et les moyens d&apos;un traitement de données à caractère personnel (RGPD,
              article 4).
            </p>
            <p className="mt-4">
              En vertu de l&apos;article 4 alinéa 8 du RGPD le sous-traitant est « la personne
              physique ou morale, l&apos;autorité publique, le service ou un autre organisme qui
              traite des données à caractère personnel pour le compte de son responsable de
              traitement ».
            </p>
            <p className="mt-4">
              En conséquence de quoi, HEXA COOP est désigné en qualité de sous-traitant (ST) au sens
              du RGPD.
            </p>
            <p className="mt-4">
              Le RGPD a institué une obligation générale de protection pesant sur l&apos;ensemble
              des acteurs du traitement. L&apos;article 4 alinéa 3 du RGPD impose la conclusion
              d&apos;un contrat liant le sous-traitant au responsable de traitement, objet des
              présentes.
            </p>
          </section>

          <section className="mt-12 text-justify text-sm font-medium text-grey-700">
            <h2 className="mb-6 text-center text-base font-bold uppercase">
              CECI EXPOSÉ, IL EST CONVENU CE QUI SUIT :
            </h2>
            <h3 className="mb-4 mt-6 text-base font-bold">Article 1. Objet :</h3>
            <p className="mt-4">
              Les présentes clauses ont pour objet de définir les conditions dans lesquelles HEXA
              COOP s&apos;engage à effectuer, pour le compte du responsable de traitement, les
              opérations de traitement de données à caractère personnel définies ci-après, en
              application du Contrat principal.
            </p>
            <p className="mt-4">
              Dans le cadre de leurs relations contractuelles, les parties s&apos;engagent à
              respecter la réglementation en vigueur applicable au traitement de données à caractère
              personnel et, en particulier, le règlement (UE) 2016/679 du Parlement européen et du
              Conseil du 27 avril 2016 applicable à compter du 25 mai 2018.
            </p>

            <h3 className="mb-4 mt-6 text-base font-bold">
              Article 2. Description du traitement faisant l&apos;objet de la sous-traitance :
            </h3>
            <p className="mt-4">
              HEXA COOP est autorisé à traiter pour le compte du responsable de traitement les
              données à caractère personnel nécessaires pour fournir le ou les services et
              prestations définis en ANNEXE I, à l’exclusion de tout autre.
            </p>
            <p className="mt-4">L’ANNEXE II ci-après décrit :</p>
            <p className="mt-4">
              <ol>
                <li className="ml-8 list-decimal">
                  La nature des opérations réalisées sur les données
                </li>
                <li className="ml-8 list-decimal">La ou les finalité(s) du traitement</li>
                <li className="ml-8 list-decimal">Les données à caractère personnel traitées</li>
                <li className="ml-8 list-decimal">Les catégories de personnes concernées</li>
                <li className="ml-8 list-decimal">
                  Les informations nécessaires pour l’exécution du service objet du présent contrat
                  principal que le responsable de traitement met à la disposition du sous-traitant.
                </li>
              </ol>
            </p>
            <p className="mt-4">
              HEXA COOP s’efforce de fournir les niveaux de sécurité technique, sur la base des
              seuls renseignements communiqués par le responsable de traitement. Il appartient à ce
              dernier de se conformer en amont s’agissant du recueil du consentement libre et
              éclairé du titulaires des données personnelles. En aucun cas la responsabilité d’ HEXA
              COOP ne pourra être retenue de ce chef. A toutes fins utiles, le sous-traitant a
              communiqué dans son pack documentaire un formulaire de consentement à destination du
              responsable de traitement.
            </p>
            <p className="mt-4">
              HEXA COOP propose des niveaux élevés de protection des données ; HEXA COOP s’efforce
              d’utiliser des produits pertinents et efficaces en regard des règles de l’art
              applicable. A défaut, il appartient au client responsable de traitement de définir les
              critères de sécurité à retenir.
            </p>

            <h3 className="mb-4 mt-6 text-base font-bold">Article 3. Durée du contrat :</h3>
            <p className="mt-4">
              Le présent contrat entre en vigueur à compter de la souscription ou son renouvellement
              pour s’achever au terme du contrat principal de service décrit en ANNEXE I.
            </p>

            <h3 className="mb-4 mt-6 text-base font-bold">
              Article 4. Obligations du sous-traitant vis-à-vis du responsable de traitement :
            </h3>
            <p className="mt-4">Le sous-traitant s&apos;engage à :</p>

            <p className="mt-4">
              <ol>
                <li className="mt-3">
                  <strong>1. Finalité : </strong>
                  traiter les données uniquement pour la ou les seule(s) finalité(s) qui fait/font
                  l’objet du Contrat principal.
                </li>
                <li className="mt-3">
                  <strong>2. Traitement : </strong>
                  traiter les données conformément aux instructions documentées du responsable de
                  traitement figurant en ANNEXE II du présent contrat. Si le sous-traitant considère
                  qu’une instruction constitue une violation du règlement européen sur la protection
                  des données ou de toute autre disposition du droit de l’Union ou du droit des
                  Etats membres relative à la protection des données, il en informe immédiatement le
                  responsable de traitement. En outre, si le sous-traitant est tenu de procéder à un
                  transfert de données vers un pays tiers ou à une organisation internationale, en
                  vertu du droit de l’Union ou du droit de l’Etat membre auquel il est soumis, il
                  doit informer le responsable du traitement de cette obligation juridique avant le
                  traitement, sauf si le droit concerné interdit une telle information pour des
                  motifs importants d&apos;intérêt public.
                </li>
                <li className="mt-3">
                  <strong>3. Confidentialité : </strong>
                  garantir la confidentialité des données à caractère personnel traitées dans le
                  cadre du présent contrat. Cette obligation de confidentialité subsiste après la
                  fin des prestations.
                </li>
                <li className="mt-3">
                  <strong>4. Personnes autorisées : </strong>
                  veiller à ce que les personnes autorisées à traiter les données à caractère
                  personnel en vertu du présent contrat :
                  <ol>
                    <li className="ml-4 mt-1">
                      a. s’engagent à respecter la confidentialité ou soient soumises à une
                      obligation légale appropriée de confidentialité ;
                    </li>
                    <li className="ml-4 mt-1">
                      b. reçoivent la formation nécessaire en matière de protection des données à
                      caractère personnel.
                    </li>
                  </ol>
                </li>
                <li className="mt-3">
                  <strong>5. Privacy by design/default : </strong>
                  prendre en compte avec le sous-traitant dans sa mission de conception, s’agissant
                  de ses outils, produits, applications ou services déployés dans le cadre de la
                  prestation souscrite par le responsable de traitement, les principes de protection
                  des données dès la conception et de protection des données par défaut.
                </li>
                <li className="mt-3">
                  <strong>6. Sous-traitance : </strong>
                  Le sous-traitant peut faire appel à un autre sous-traitant (ci-après, « le
                  sous-traitant ultérieur ») pour mener des activités de traitement spécifiques.
                  Dans ce cas, il informe préalablement et par écrit le responsable de traitement de
                  tout changement envisagé concernant l’ajout ou le remplacement d’autres
                  sous-traitants. Cette information doit indiquer clairement les activités de
                  traitement sous-traitées, l’identité et les coordonnées du sous-traitant et les
                  dates du contrat de sous-traitance. Le responsable de traitement dispose d’un
                  délai minium de 48 heures à compter de la date de réception de cette information
                  pour présenter ses objections. Cette sous-traitance ne peut être effectuée que si
                  le responsable de traitement n&apos;a pas émis d&apos;objection pendant le délai
                  convenu.
                </li>
                <li className="mt-3">
                  <strong>7. Informations des titulaires : </strong>
                  Il appartient au responsable de traitement de fournir l’information aux personnes
                  concernées par les opérations de traitement au moment de la collecte des données.
                </li>
                <li className="mt-3">
                  <strong>8. Exercice des droits des personnes concernées : </strong>
                  Dans la mesure du possible, le sous-traitant doit aider le responsable de
                  traitement à s’acquitter de son obligation de donner suite aux demandes d’exercice
                  des droits des personnes concernées : droit d’accès, de rectification,
                  d’effacement et d’opposition, droit à la limitation du traitement, droit à la
                  portabilité des données, droit de ne pas faire l’objet d’une décision individuelle
                  automatisée (y compris le profilage). Lorsque les personnes concernées exercent
                  auprès du sous-traitant des demandes d’exercice de leurs droits, le sous-traitant
                  doit adresser ces demandes dès réception par courrier électronique à la personne
                  désignée par le responsable de traitement en ANNEXE II.
                </li>
                <li className="mt-3">
                  <strong>
                    9. Notification des violations de données à caractère personnel :{" "}
                  </strong>
                  Le sous-traitant notifie au responsable de traitement toute violation de données à
                  caractère personnel dans un délai maximum de 48 heures après en avoir pris
                  connaissance et par courrier électronique à la personne désignée par le
                  responsable de traitement en ANNEXE II. Cette notification est accompagnée de
                  toute documentation utile afin de permettre au responsable de traitement, si
                  nécessaire, de notifier cette violation à l’autorité de contrôle compétente.
                </li>
                <li className="mt-3">
                  <strong>
                    10. Aide du sous-traitant dans le cadre du respect par le responsable de
                    traitement de ses obligations :{" "}
                  </strong>
                  Le sous-traitant aide le responsable de traitement pour la réalisation d’analyses
                  d’impact relative à la protection des données. Le sous-traitant aide le
                  responsable de traitement pour la réalisation de la consultation préalable de
                  l’autorité de contrôle.
                </li>
                <li className="mt-3">
                  <strong>11. Mesures de sécurité : </strong>
                  Le sous-traitant s’engage à mettre en œuvre les mesures de sécurité prévues et
                  souscrites par le responsable de données en ANNEXE III.
                </li>
                <li className="mt-3">
                  <strong>12. Sort des données : </strong>
                  Au terme de la prestation de services relatifs au traitement de ces données, le
                  sous-traitant s’engage à renvoyer toutes les données à caractère personnel au
                  responsable de traitement ou le cas échéant, sur instruction du responsable de
                  traitement, à renvoyer les données à caractère personnel au sous-traitant désigné
                  par le responsable de traitement. Le renvoi doit s’accompagner de la destruction
                  de toutes les copies existantes dans les systèmes d’information du sous-traitant.
                  Une fois détruites, le sous-traitant doit justifier par écrit de la destruction.
                </li>
                <li className="mt-3">
                  <strong>13. Registre des catégories d’activités de traitement : </strong>
                  Le sous-traitant déclare tenir par écrit un registre de toutes les catégories
                  d’activités de traitement effectuées pour le compte du responsable de traitement
                  comprenant :
                  <ol>
                    <li className="ml-4 mt-1">
                      a. le nom et les coordonnées du responsable de traitement pour le compte
                      duquel le sous-traitant agit, de tout représentant du responsable de
                      traitement et du délégué à la protection des données ;
                    </li>
                    <li className="ml-4 mt-1">
                      b. les catégories de traitements effectués pour le compte du responsable de
                      traitement ;
                    </li>
                    <li className="ml-4 mt-1">
                      c. le cas échéant, les transferts de données à caractère personnel vers un
                      pays tiers ou à une organisation internationale, y compris l’identification de
                      ce pays tiers ou de cette organisation internationale et, dans le cas des
                      transferts visés à l’article 49, paragraphe 1, deuxième alinéa, la
                      documentation des garanties appropriées ;
                    </li>
                    <li className="ml-4 mt-1">
                      d. dans la mesure du possible, une description générale des mesures de
                      sécurité techniques et organisationnelles, y compris entre autres, selon les
                      besoins :
                      <ol>
                        <li className="ml-4 mt-1">
                          i. le pseudonymisation et le chiffrement des données à caractère personnel
                          ;
                        </li>
                        <li className="ml-4 mt-1">
                          ii. des moyens permettant de garantir la confidentialité,
                          l&apos;intégrité, la disponibilité et la résilience constantes des
                          systèmes et des services de traitement;
                        </li>
                        <li className="ml-4 mt-1">
                          iii. des moyens permettant de rétablir la disponibilité des données à
                          caractère personnel et l&apos;accès à celles-ci dans des délais appropriés
                          en cas d&apos;incident physique ou technique ;
                        </li>
                        <li className="ml-4 mt-1">
                          iv. une procédure visant à tester, à analyser et à évaluer régulièrement
                          l&apos;efficacité des mesures techniques et organisationnelles pour
                          assurer la sécurité du traitement.
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <li className="mt-3">
                  <strong>14. Documentation : </strong>
                  Le sous-traitant met à la disposition du responsable de traitement la
                  documentation nécessaire pour démontrer le respect de toutes ses obligations et
                  pour permettre la réalisation d&apos;audits, y compris des inspections, par le
                  responsable du traitement ou un autre auditeur qu&apos;il a mandaté, et contribuer
                  à ces audits.
                </li>
              </ol>
            </p>

            <h3 className="mb-4 mt-6 text-base font-bold">
              Article 5. Obligations du responsable de traitement vis-à-vis du sous-traitant :
            </h3>
            <p className="mt-4">
              En application des présentes, le responsable de traitement s’engage à :{" "}
            </p>
            <p className="mt-4">
              <ol>
                <li className="ml-4 mt-1">
                  i. fournir au sous-traitant les données visées au II des présentes clauses ;
                </li>
                <li className="ml-4 mt-1">
                  ii. documenter par écrit toute instruction concernant le traitement des données
                  par le sous-traitant ;
                </li>
                <li className="ml-4 mt-1">
                  iii. veiller, au préalable et pendant toute la durée du traitement, au respect des
                  obligations prévues par le règlement européen sur la protection des données de la
                  part du sous-traitant ;
                </li>
                <li className="ml-4 mt-1">
                  iv. superviser le traitement, y compris réaliser les audits et les inspections
                  auprès du sous-traitant.
                </li>
              </ol>
            </p>

            <h3 className="mb-4 mt-6 text-base font-bold">Article 6. Coopération :</h3>
            <p className="mt-4">
              Il est convenu que les parties devront communiquer mutuellement pour s&apos;assurer,
              dans les limites du raisonnable, que l&apos;efficacité opérationnelle est maintenue,
              et, si possible, renforcée afin de s’assurer de la conformité de leurs obligations
              respectives.
            </p>
            <p className="mt-4">
              Les parties s&apos;engagent à communiquer et à se consulter régulièrement pendant la
              période du présent contrat en vue de décider des améliorations et des modifications
              que chacun pourrait apporter à leur système, en particulier dans le cas où celles-ci
              maintiendraient et renforceraient la bonne exécution du présent contrat au profit des
              deux parties, chaque partie acceptant de mettre en œuvre tout ce qui est
              raisonnablement possible pour obtenir des réductions, pendant toute la durée du
              présent contrat, du coût par élément visé.
            </p>

            <h3 className="mb-4 mt-6 text-base font-bold">Article 7. Dispositions finales :</h3>
            <p className="mt-4">
              De tout ce que dessus, il a été convenu et arrêté le présent avenant qui est un
              additif au contrat principal. Le présent avenant forme un tout juridique,
              indissociable avec le contrat principal.
            </p>
            <p className="mt-4">
              Il ne vient contredire et/ou modifier aucune disposition antérieurement prévue par les
              parties sauf à convenir par écrit, sur la base des conseils de , un niveau de
              prestations et/ou de protection plus élevé(e)s en application du RGPD.
            </p>
          </section>

          <section className="mt-12 text-justify text-sm font-medium text-grey-700">
            <p className="mb-1 font-bold">
              ANNEXE I – Prestations souscrites par le responsable de traitement
            </p>
            <p className="mb-1 font-bold">
              ANNEXE II – Informations communiquées au sous-traitant par le responsable de
              traitement
            </p>
            <p className="mb-1 font-bold">
              ANNEXE III – Politique de sécurité des données à caractère personnel du sous-traitant
            </p>
          </section>

          <section className="mt-12 text-justify text-sm font-medium text-grey-700">
            <h2 className="mb-6 text-center text-base font-bold uppercase">
              ANNEXE I - CONTRAT PRINCIPAL conclu avec le sous-traitant
            </h2>
            <p className="mb-2 mt-4 text-center">
              Nature des prestations souscrites par le client responsable de traitement :
            </p>
            <h3 className="mb-4 mt-6 text-base font-bold">Finalité du contrat :</h3>
            <p className="mt-4">
              La Coopérative a pour objectif de regrouper en son sein des professionnels
              indépendants dont les activités habituelles relèvent, à titre principal ou accessoire,
              des services à la personne tels qu’ils sont définis par les articles L. 7231-1 et D.
              7231-1 du Code du travail.
            </p>
            <p className="mt-4">
              Ainsi, entre dans le champ d’activité de la Coopérative, divers services à la
              personne, parmi lesquels :
            </p>
            <p className="mt-4">
              <ul>
                <li className="ml-8 list-disc">Entretien de la maison</li>
                <li className="ml-8 list-disc">Petit travaux de jardinage</li>
                <li className="ml-8 list-disc">Travaux de petit bricolage</li>
                <li className="ml-8 list-disc">Garde d’enfants de plus de 3 ans à domicile</li>
                <li className="ml-8 list-disc">Soutien scolaire ou cours à domicile</li>
                <li className="ml-8 list-disc">
                  Soins esthétiques à domicile pour les personnes dépendantes
                </li>
                <li className="ml-8 list-disc">
                  Préparation de repas à domicile (y compris temps passé aux courses)
                </li>
                <li className="ml-8 list-disc">Livraison de repas à domicile</li>
                <li className="ml-8 list-disc">Livraison de courses à domicile</li>
                <li className="ml-8 list-disc">
                  Collecte et livraison à domicile de linge repassé
                </li>
                <li className="ml-8 list-disc">Assistance informatique à domicile</li>
                <li className="ml-8 list-disc">
                  Soins et promenades d’animaux de compagnie pour les personnes dépendantes
                </li>
                <li className="ml-8 list-disc">
                  Maintenance, entretien et vigilance temporaires à domicile
                </li>
                <li className="ml-8 list-disc">Assistance administrative à domicile</li>
                <li className="ml-8 list-disc">
                  Accompagnement des enfants de plus de 3 ans dans leurs déplacements
                </li>
                <li className="ml-8 list-disc">Télé-assistance et visio-assistance</li>
                <li className="ml-8 list-disc">
                  Interprète en langue des signes, technicien de l&apos;écrit et codeur en langage
                  parlé complété
                </li>
                <li className="ml-8 list-disc">
                  Prestation du véhicule de conduite personnel des personnes qui présente une
                  invalidité temporaire
                </li>
                <li className="ml-8 list-disc">
                  Accompagnement des personnes qui présentent une invalidité temporaire en dehors de
                  leur domicile
                </li>
                <li className="ml-8 list-disc">
                  Assistance aux personnes qui ont besoin temporairement d&apos;une aide personnelle
                  à leur domicile, à l&apos;exclusion des soins relevant d&apos;actes médicaux
                </li>
                <li className="ml-8 list-disc">Coordination et délivrance des services</li>
              </ul>
            </p>
            <p className="mt-4">
              En conséquence de quoi, HEXA COOP traite les données des clients personnes physiques
              bénéficiaires des services à domicile ci-dessus recensés, souscrits et réalisés par le
              Coopérateur.
            </p>
          </section>

          <section className="mt-12 text-justify text-sm font-medium text-grey-700">
            <h2 className="mb-6 text-center text-base font-bold uppercase">
              ANNEXE II - Informations communiquées par le responsable de traitement
            </h2>

            <h3 className="mb-4 mt-6 text-base font-bold">
              Nature des opérations réalisées sur les données :
            </h3>
            <p className="mt-4">
              Les opérations réalisées sur les données à caractère personnel sont les suivantes :
            </p>
            <p className="mt-4">
              <ul>
                <li className="ml-8 list-disc">
                  Enregistrement des données des clients personnes physiques bénéficiaires.
                </li>
                <li className="ml-8 list-disc">
                  Ouverture de compte « services à domicile ». Gestion du compte client final et
                  relevé de prestations. Paiement en ligne.
                </li>
                <li className="ml-8 list-disc">
                  Facturation suivant mandat pour le compte du Responsable de traitement.
                </li>
                <li className="ml-8 list-disc">Délivrance d’une attestation fiscale.</li>
              </ul>
            </p>

            <h3 className="mb-4 mt-6 text-base font-bold">Finalité(s) du traitement :</h3>
            <p className="mt-4">
              Gestion déléguée par voie électronique des prestations comptables, juridiques et
              fiscales des services à domicile dans le cadre de la coopérative, pour le compte des
              coopérateurs.
            </p>

            <h3 className="mb-4 mt-6 text-base font-bold">
              Données à caractère personnel traitées :
            </h3>
            <p className="mt-4">Les données à caractère personnel traitées sont les suivantes :</p>
            <p className="mt-4">
              <ul>
                <li className="ml-8 list-disc">Nom, prénom, adresse.</li>
                <li className="ml-8 list-disc">Courrier électronique (Email).</li>
                <li className="ml-8 list-disc">
                  Coordonnées téléphoniques (Numéro de téléphone mobile).
                </li>
                <li className="ml-8 list-disc">Coordonnées bancaires (RIB, IBAN, BIC).</li>
                <li className="ml-8 list-disc">Date et lieu de naissance.</li>
              </ul>
            </p>
          </section>

          <section className="mt-12 text-justify text-sm font-medium text-grey-700">
            <h2 className="mb-6 text-center text-base font-bold uppercase">
              ANNEXE III - Politique de sécurité des données à caractère personnel souscrite par le
              responsable de traitement auprès du sous-traitant :
            </h2>

            <p className="mt-4">
              <ul>
                <li className="ml-8 list-disc">Réalisation de mises à jour régulières ;</li>
                <li className="ml-8 list-disc">Réalisations de sauvegardes régulières ;</li>
                <li className="ml-8 list-disc">
                  Déploiement d’un certificat SSL dans le but de sécuriser les données échangées
                  entre le Coopérateur et le site ;
                </li>
                <li className="ml-8 list-disc">Adoption d’un pare-feu ;</li>
                <li className="ml-8 list-disc">
                  Mise à disposition par l’hébergeur d’un serveur dédié ;
                </li>
                <li className="ml-8 list-disc">
                  Définition des moyens permettant de garantir la confidentialité, l&apos;intégrité,
                  la disponibilité et la résilience constantes des systèmes et des services de
                  traitement ;
                </li>
                <li className="ml-8 list-disc">
                  Choix de moyens permettant de rétablir la disponibilité des données à caractère
                  personnel et l&apos;accès à celles-ci dans des délais appropriés en cas
                  d&apos;incident physique ou technique.
                </li>
              </ul>
            </p>
          </section>

          <section className="mt-12 text-justify text-base font-medium text-grey-700">
            <p>
              HEXA COOP s’engage à mettre en œuvre les mesures de sécurité prévues par le contrat.
            </p>
          </section>
        </div>
      </IonContent>
    </IonPage>
  );
};
