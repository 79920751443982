import { z } from "zod";

import { zodAlwaysRefine } from "~/utils/misc";

export const QuotationBaseSchema = z.object({
  cooperator: z.string().min(1, "Veuillez sélectionner un coopérateur."),
  customer: z.string().min(1, "Veuillez sélectionner un client."),
  comments: z.string().optional().nullable(),
  isRecurrenceMaster: z.boolean(),
  isAvanceImmediate: z.boolean(),
  isRecoverable: z.boolean(),
  recurrence: z
    .object({
      startAt: z.string().min(1, "Veuillez renseigner la date de début de la récurrence."),
      endAt: z.string().min(1, "Veuillez renseigner la date de fin de la récurrence."),
      period: z.string().min(1, "Veuillez renseigner la fréquence de la récurrence."),
      hourTask: z.string().min(1, "Veuillez renseigner l'heure d'envoi de la récurrence.")
    })
    .optional()
    .nullable(),
  scheduleQuotationTasks: z
    .array(
      z.object({
        scheduledAt: z.string().optional().nullable()
      })
    )
    .optional()
    .nullable(),
  quotationRow: zodAlwaysRefine(
    z.array(
      z
        .object({
          category: z
            .string({ required_error: "Veuillez sélectionner une catégorie." })
            .min(1, "Veuillez sélectionner une catégorie."),
          // nameService: z.string().min(1, "Veuillez sélectionner un service."),
          executedAt: z
            .string()
            .refine((date) => {
              const today = new Date();
              const dateExecutedAt = new Date(date);
              return dateExecutedAt <= today;
            }, "La date d'exécution ne peut pas être supérieure à la date d'aujourd'hui.")
            .optional()
            .nullable(),
          endExecutedAt: z
            .string()
            .refine((date) => {
              const today = new Date();
              const dateExecutedAt = new Date(date);
              return dateExecutedAt <= today;
            }, "La date de fin d'exécution ne peut pas être supérieure à la date d'aujourd'hui.")
            .optional()
            .nullable(),
          quantity: z.string().min(1, "Veuillez saisir une quantité."),
          priceUnit: z.string().min(1, "Veuillez saisir un prix unitaire."),
          // Not sended
          priceUnitTTC: z.number().optional().nullable(),
          totalHT: z.number().optional().nullable(),
          totalTTC: z.number().optional().nullable()
        })
        .optional()
        .nullable()
    )
  )
});

export const QuotationSchema = QuotationBaseSchema.superRefine((data, ctx) => {
  data.quotationRow.forEach((row, index) => {
    if (row?.executedAt && row.endExecutedAt) {
      const executedAt = new Date(row.executedAt);
      const endExecutedAt = new Date(row.endExecutedAt);
      if (executedAt > endExecutedAt) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message:
            "La date de fin d'exécution doit être supérieure ou égale à la date d'exécution.",
          path: [`quotationRow.${index}.endExecutedAt`]
        });
      }
    }
  });
});

export const PrefinancingContractSchema = QuotationBaseSchema.extend({
  quotationRow: z.array(
    z
      .object({
        category: z
          .string({ required_error: "Veuillez sélectionner une catégorie." })
          .min(1, "Veuillez sélectionner une catégorie."),
        // nameService: z.string().min(1, "Veuillez sélectionner un service."),
        quantity: z.string().min(1, "Veuillez saisir une quantité."),
        priceUnit: z.string().min(1, "Veuillez saisir un prix unitaire."),
        // Not sended
        priceUnitTTC: z.number().optional().nullable(),
        totalHT: z.number().optional().nullable(),
        totalTTC: z.number().optional().nullable()
      })
      .optional()
      .nullable()
  )
});

export type QuotationSchema = z.infer<typeof QuotationSchema>;

export type PrefinancingContractSchema = z.infer<typeof PrefinancingContractSchema>;
