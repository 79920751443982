import type { ChangeEvent } from "react";

// Add spaces every 4 characters in an IBAN input, keep the cursor position and handle the backspace key correctly
export const handleIbanChange = (e: ChangeEvent<HTMLInputElement>) => {
  const { value, selectionEnd } = e.target;

  const formattedValue = value
    .replace(/\s/g, "")
    .replace(/(.{4})/g, "$1 ")
    .trim();
  const cursorPosition = (selectionEnd || 0) + (formattedValue.length - value.length);

  e.target.value = formattedValue;
  e.target.setSelectionRange(cursorPosition, cursorPosition);
};

export const allowedIbanPrefixes = [
  "FR",
  "RE",
  "MC",
  "GP",
  "GF",
  "MQ",
  "YT",
  "NC",
  "PF",
  "PM",
  "TF",
  "WF"
];
