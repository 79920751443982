import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Controller, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router";

import { queryKeys } from "~/config/query-keys-constants";
import { useUIContext } from "~/contexts/uiContext";
import { createModule, getModule, updateModule } from "~/api/module";
import { Button } from "~/components/ui/Button/Button";
import { FormInput } from "~/components/ui/FormInput";
import { FormRow } from "~/components/ui/FormRow";
import { LoadingSpinner } from "~/components/ui/LoadingSpinner";
import { ModuleSchema } from "~/schemas/module.schema";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { handleApiError } from "~/services/errors";
import { FormRichTextEditor } from "~/components/ui/FormRichTextEditor";

export const CreateEditModule = ({ editMode = false }: { editMode?: boolean }) => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const { toast } = useUIContext();
  const { module_id: moduleId } = useParams<{ module_id?: string }>();

  const { data: moduleData, isLoading: isModuleLoading } = useQuery({
    queryKey: [queryKeys.modules, { id: moduleId }],
    queryFn: () => (moduleId ? getModule({ id: parseInt(moduleId) }) : null),
    enabled: !!moduleId
  });

  const moduleMutation = useMutation({
    mutationFn: (data: ModuleSchema) =>
      editMode && moduleId
        ? updateModule({ id: parseInt(moduleId), data })
        : createModule({ data }),
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: [queryKeys.modules] });
      toast(
        editMode ? "Le module a été modifié avec succès." : "Le module a été créé avec succès.",
        "success"
      );
      history.push("/administration/modules");
    },
    onError(error) {
      handleApiError(error, toast);
    }
  });

  const form = useForm<ModuleSchema>({
    resolver: zodResolver(ModuleSchema),
    values: {
      name: moduleData?.name || "",
      code: moduleData?.code || "",
      title: moduleData?.title || "",
      // imageFile: null,
      description1: moduleData?.description1 || "",
      description2: moduleData?.description2 || "",
      description3: moduleData?.description3 || ""
    }
  });

  const onSubmit = (data: ModuleSchema) => {
    moduleMutation.mutate(data);
  };

  if (!!moduleId && isModuleLoading) {
    return <LoadingSpinner size="lg" fullPage />;
  }

  if (!!moduleId && !moduleData) {
    return <p className="text-center">Le module à éditer est introuvable.</p>;
  }

  return (
    <>
      <h2 className="mt-6">
        {editMode ? `Édition d'un module : ${moduleData?.name}` : "Création d'un module"}
      </h2>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <FormRow>
          <FormInput
            label="Nom du menu"
            errorMessage={form.formState.errors.name?.message}
            {...form.register("name")}
          />
          <FormInput
            label="Titre"
            errorMessage={form.formState.errors.title?.message}
            {...form.register("title")}
          />
          <FormInput
            label="Code"
            errorMessage={form.formState.errors.code?.message}
            {...form.register("code")}
          />
        </FormRow>
        {/* <FormRow>
          <FormInput
            label="Image"
            type="file"
            errorMessage={
              typeof form.formState.errors.imageFile?.message !== "string"
                ? form.formState.errors.imageFile?.message?.toString()
                : form.formState.errors.imageFile.message
            }
            {...form.register("imageFile")}
          />
        </FormRow> */}
        <FormRow>
          <Controller
            name="description1"
            control={form.control}
            render={({ field }) => {
              return (
                <FormRichTextEditor
                  label="Contenu coopérateur"
                  defaultValue={field.value}
                  editorRef={field.ref}
                  className="col-span-3"
                  {...field}
                />
              );
            }}
          />
        </FormRow>
        <FormRow>
          <Controller
            name="description2"
            control={form.control}
            render={({ field }) => {
              return (
                <FormRichTextEditor
                  label="Contenu coopérateur 2"
                  defaultValue={field.value}
                  editorRef={field.ref}
                  className="col-span-3"
                  {...field}
                />
              );
            }}
          />
        </FormRow>
        <FormRow>
          <Controller
            name="description3"
            control={form.control}
            render={({ field }) => {
              return (
                <FormRichTextEditor
                  label="Contenu landing"
                  defaultValue={field.value}
                  editorRef={field.ref}
                  className="col-span-3"
                  {...field}
                />
              );
            }}
          />
        </FormRow>

        <div className="mt-12 flex justify-end gap-4 border-t border-grey-200 pt-6">
          <Button fill="clear" onClick={() => history.goBack()}>
            Annuler
          </Button>
          <Button type="submit" disabled={moduleMutation.isLoading}>
            {editMode ? "Modifier le module" : "Créer le module"}
          </Button>
        </div>
      </form>
    </>
  );
};
