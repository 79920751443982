import { isValidBIC, isValidIBAN } from "ibantools";
import { z } from "zod";

import { civilities } from "~/config/api-constants";

export const CustomerSchema = z
  .object({
    canAvanceImmediate: z.boolean().optional().nullable(),
    isPro: z.boolean({ required_error: "Veuillez sélectionner un type de client." }),
    cooperator: z
      .array(z.string().min(1, "Veuillez sélectionner un coopérateur."))
      .min(1, "Veuillez sélectionner un coopérateur."),
    isAvanceImmediate: z.boolean(),
    internRefCoop: z.string().optional().nullable(),
    civility: z.number().optional().nullable(),
    lastnameBirth: z.string().optional().nullable(),
    lastname: z.string().min(1, "Veuillez renseigner un nom."),
    firstname: z.string().min(1, "Veuillez renseigner un prénom."),
    email: z.string().min(1, "Veuillez renseigner un email."),
    mobileNumber: z
      .string()
      .regex(
        /^(\+33|\+330|0)[67]([-. ]?[0-9]{2}){4}$/,
        "Veuillez renseigner un numéro de téléphone mobile français valide ou laisser ce champ vide."
      )
      .transform((value) => value.replace(/[-. ]/g, ""))
      .or(z.literal("").transform(() => null))
      .optional()
      .nullable(),
    fixeNumber: z
      .string()
      .regex(
        /^(\+33|\+330|0)[1-9]([-. ]?[0-9]{2}){4}$/,
        "Veuillez renseigner un numéro de téléphone fixe valide ou laisser ce champ vide."
      )
      .transform((value) => value.replace(/[-. ]/g, ""))
      .or(z.literal("").transform(() => null))
      .optional()
      .nullable(),
    address: z.string().min(1, "Veuillez renseigner une adresse."),
    zipcode: z.string().min(1, "Veuillez renseigner un code postal."),
    city: z.string().min(1, "Veuillez renseigner une ville."),
    birthdayDate: z.string().optional().nullable(),
    countryBirth: z.string().optional().nullable(),
    birthdayLocation: z.string().optional().nullable(),
    companyName: z.string().optional().nullable(),
    siret: z
      .string()
      .length(14, "Le numéro SIRET doit contenir 14 chiffres.")
      .regex(/^\d+$/, "Le numéro SIRET doit contenir uniquement des chiffres.")
      .optional()
      .nullable(),
    legalForm: z.string().optional().nullable(),
    tvaNumber: z.string().optional().nullable(),
    iban: z
      .string()
      .refine(
        (iban) => isValidIBAN(iban),
        "L'IBAN renseigné est invalide (obligatoire pour l'avance immédiate)."
      )
      .optional()
      .nullable(),
    bic: z
      .string()
      .refine(
        (bic) => isValidBIC(bic),
        "Le BIC renseigné est invalide (obligatoire pour l'avance immédiate)."
      )
      .optional()
      .nullable()
  })
  .superRefine((data, ctx) => {
    if (data.isPro) {
      if (!data.companyName || data.companyName === "") {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message:
            "Veuillez sélectionner une raison sociale (obligatoire pour les professionnels).",
          path: ["companyName"]
        });
      }
      if (!data.siret || data.siret === "") {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Veuillez renseigner un SIRET (obligatoire pour les professionnels).",
          path: ["siret"]
        });
      }
      if (!data.legalForm || data.legalForm === "") {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message:
            "Veuillez sélectionner un forme juridique (obligatoire pour les professionnels).",
          path: ["legalForm"]
        });
      }
      if (!data.tvaNumber || data.tvaNumber === "") {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Veuillez renseigner un numéro de TVA (obligatoire pour les professionnels).",
          path: ["tvaNumber"]
        });
      }
    }
    if (!data.isPro && data.isAvanceImmediate) {
      if (!Object.values(civilities).includes(data.civility as any)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Veuillez sélectionner une civilité (obligatoire pour l'avance immédiate).",
          path: ["civility"]
        });
      }
      if (!data.mobileNumber || data.mobileNumber === "") {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message:
            "Veuillez sélectionner un numéro de téléphone (obligatoire pour l'avance immédiate).",
          path: ["mobileNumber"]
        });
      }
      if (
        data.civility === civilities.MADAME &&
        (!data.lastnameBirth || data.lastnameBirth === "")
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message:
            "Veuillez sélectionner un nom de naissance (obligatoire pour l'avance immédiate).",
          path: ["lastnameBirth"]
        });
      }
      if (!data.birthdayDate || data.birthdayDate === "") {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message:
            "Veuillez sélectionner une date de naissance (obligatoire pour l'avance immédiate).",
          path: ["birthdayDate"]
        });
      }
      if (!data.countryBirth || data.countryBirth === "") {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message:
            "Veuillez renseigner un pays de naissance (obligatoire pour l'avance immédiate).",
          path: ["countryBirth"]
        });
      }
      if (!data.birthdayLocation || data.birthdayLocation === "") {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message:
            "Veuillez renseigner une commune de naissance (obligatoire pour l'avance immédiate).",
          path: ["birthdayLocation"]
        });
      }
      if (!data.iban || data.iban === "") {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Veuillez renseigner un IBAN (obligatoire pour l'avance immédiate).",
          path: ["iban"]
        });
      }
      if (!data.bic || data.bic === "") {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Veuillez renseigner un BIC (obligatoire pour l'avance immédiate).",
          path: ["bic"]
        });
      }
    }
  });

export type CustomerSchema = z.infer<typeof CustomerSchema>;
