import type { AdvancedSearchParams } from "~/components/TableLayout";
import type { MainTableProps } from "~/components/ui/MainTable";
import type { Quotation } from "~/types/quotation.types";

import { useMemo, useState } from "react";
import { IonIcon } from "@ionic/react";
import { useInfiniteQuery, useMutation } from "@tanstack/react-query";
import {
  cardOutline,
  checkmarkCircleOutline,
  documentAttachOutline,
  listOutline,
  mailOutline
} from "ionicons/icons";

import { quotationStates, taskStates } from "~/config/api-constants";
import { queryKeys } from "~/config/query-keys-constants";
import { formatDisplayDate, formatDisplayHourFromDate, formatMoney } from "~/utils/misc";
import { initiateCreditCardPayment } from "~/utils/payment";
import { useAuthContext } from "~/contexts/authContext";
import { useUIContext } from "~/contexts/uiContext";
import { useFileDeliver } from "~/hooks/useFileDeliver";
import { useModal } from "~/hooks/useModal";
import { handleApiError } from "~/services/errors";
import { getNextPageParamFn } from "~/api/_config";
import { queryClient } from "~/api/_queryClient";
import { anonymousPayQuotationByCreditCard } from "~/api/anonymous";
import { getRecoveryQuotations, sendQuotationSignatureToCustomer } from "~/api/quotation";
import { sendMultipleQuotationRecoveryActionsToCooperator } from "~/api/recovery-action";
import { QuotationsRecoverySearchForm } from "~/components/AdvancedSearchForms/Quotations/QuotationsRecoverySearchForm";
import { MultipleRecoveryQuotationsActionsModal } from "~/components/Modals/MultipleRecoveryQuotationsActionsModal";
import { QuotationRecoveriesModal } from "~/components/Modals/QuotationRecoveriesModal";
import { RecoveryQuotationsActionsModal } from "~/components/Modals/RecoveryQuotationActionsModal";
import { TableLayout } from "~/components/TableLayout";
import { Button } from "~/components/ui/Button/Button";
import { FormSelect } from "~/components/ui/FormSelect";
import { ExternalLink, Link } from "~/components/ui/Link";

export const QuotationsRecovery = () => {
  const { isAdmin } = useAuthContext();
  const { toast } = useUIContext();
  const { openFile } = useFileDeliver();
  const { openModal, closeModal, renderModal } = useModal();

  const [selectedState, setSelectedState] = useState<
    "all" | "signature-failed" | "payment-failed" | "recovery-follow-up" | "actions-of-the-day"
  >("all");

  const getStateTitle = (state: string) => {
    switch (state) {
      case "signature-failed":
        return 'Relevés en "signature échouée"';
      case "payment-failed":
        return 'Relevés en "paiement échoué"';
      case "recovery-follow-up":
        return 'Relevés en "suivi de relance"';
      case "actions-of-the-day":
        return "Actions du jour";
      default:
        return "Tout les relevés en relance";
    }
  };

  const states = useMemo(() => {
    switch (selectedState) {
      case "all":
        return [];
      case "signature-failed":
        return [taskStates.STATE_TREATED];
      case "payment-failed":
        return [taskStates.STATE_ERROR];
      case "recovery-follow-up":
        return [taskStates.STATE_PROGRESS];
      case "actions-of-the-day":
        return [taskStates.STATE_TODO];
      default:
        return [];
    }
  }, [selectedState]);

  const [advancedSearchParams, setAdvancedSearchParams] = useState<AdvancedSearchParams>(null);

  const queryParams = { states, ...advancedSearchParams };

  const { data, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: [queryKeys.recoveryQuotations, queryParams],
    queryFn: ({ pageParam }) => getRecoveryQuotations({ ...queryParams, page: pageParam }),
    getNextPageParam: getNextPageParamFn
  });

  const sendQuotationSignatureToCustomerMutation = useMutation(sendQuotationSignatureToCustomer, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.recoveryQuotations]);
      toast("Le relevé a été envoyé avec succès.", "success");
    },
    onError(error) {
      handleApiError(error, toast);
    }
  });

  const payByCreditCard = useMutation({
    mutationFn: anonymousPayQuotationByCreditCard,
    onError(error) {
      handleApiError(error, toast);
    }
  });

  const groupedActions = [
    {
      id: "add-actions",
      label: "Ajouter des actions à la séléction",
      action: (ids: number[]) =>
        openModal(
          <MultipleRecoveryQuotationsActionsModal
            isOpen={true}
            onClose={closeModal}
            quotationIds={ids}
          />
        )
    },
    {
      id: "send-history",
      label: "Envoyer l'historique à la séléction",
      action: (ids: Array<Quotation["id"]>) => sendActionsMutation.mutate({ quotationIds: ids })
    }
  ];

  const sendActionsMutation = useMutation({
    mutationFn: sendMultipleQuotationRecoveryActionsToCooperator,
    onSuccess: () => {
      toast("L'historique a été envoyé avec succès.", "success");
    },
    onError(error: any) {
      toast(error?.response?.data?.message || error.message, "error");
    }
  });

  const globalActions = [
    {
      label: "Voir les statistiques",
      href: "/quotations/recovery/statistics"
    }
  ];

  const tableData: MainTableProps["data"] = useMemo(
    () =>
      data?.pages.flat().map((quotation) => {
        return {
          itemId: quotation?.id,
          checkboxConditions: [
            {
              id: "add-actions",
              condition: isAdmin
            },
            {
              id: "send-history",
              condition: isAdmin
            }
          ],
          actions: [
            {
              label: "Consulter le document",
              icon: documentAttachOutline,
              color: "slate",
              action: () => openFile({ itemType: "quotation", itemParams: { id: quotation?.id } }),
              condition: quotation?.state !== quotationStates.STATE_DRAFT
            },
            {
              label: "Voir les actions",
              icon: listOutline,
              color: "indigo",
              condition: quotation?.state !== quotationStates.STATE_DRAFT,
              action: () =>
                quotation?.id
                  ? openModal(
                      <RecoveryQuotationsActionsModal
                        quotationId={quotation.id}
                        withForm
                        isOpen
                        onClose={closeModal}
                      />
                    )
                  : null
            },
            {
              label: "Voir les relances",
              icon: mailOutline,
              color: "lime",
              condition:
                quotation?.isRecoverable === true &&
                quotation.isAvanceImmediate !== true &&
                quotation.state !== quotationStates.STATE_DRAFT,
              action: () =>
                quotation?.id
                  ? openModal(
                      <QuotationRecoveriesModal
                        quotationId={quotation.id}
                        isOpen
                        onClose={closeModal}
                      />
                    )
                  : null
            },
            {
              label: "Payer par carte bancaire",
              icon: cardOutline,
              color: "purple",
              condition:
                quotation?.state !== quotationStates.STATE_DRAFT &&
                quotation?.state !== quotationStates.STATE_INSTANT_ADVANCE_FRAUD &&
                quotation?.isAvanceImmediate !== true &&
                isAdmin,
              action: async () => {
                if (!quotation?.id) return;
                const payboxData = await payByCreditCard.mutateAsync({
                  id: quotation.id
                });
                await initiateCreditCardPayment(payboxData);
              }
            }
          ],
          columns: [
            {
              key: "trueDateValidate",
              label: "Date création",
              display: quotation?.trueDateValidate
                ? formatDisplayDate(quotation.trueDateValidate)
                : "",
              sort: quotation?.trueDateValidate,
              search: quotation?.trueDateValidate
                ? formatDisplayDate(quotation.trueDateValidate)
                : ""
            },
            {
              key: "idNormalized",
              label: "N° relevé",
              display: (
                <Link
                  to={`/file/deliver/quotation/${quotation?.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {quotation?.idNormalized}
                </Link>
              )
            },
            {
              key: "customer.lastname",
              label: "Client",
              display: (
                <div className="flex flex-col gap-1">
                  {quotation?.quotationCustomerInfo?.[0]?.companyName ? (
                    <span className="font-medium">
                      {quotation.quotationCustomerInfo[0].companyName}
                    </span>
                  ) : null}
                  {quotation?.quotationCustomerInfo?.[0]?.lastname ||
                  quotation?.quotationCustomerInfo?.[0]?.firstname ? (
                    <span>
                      {quotation.quotationCustomerInfo[0].lastname}{" "}
                      {quotation.quotationCustomerInfo[0].firstname}
                    </span>
                  ) : null}
                  {quotation?.quotationCustomerInfo?.[0]?.email ? (
                    <ExternalLink
                      to={`mailto:${quotation.quotationCustomerInfo[0].email}`}
                      className="text-xs"
                    >
                      {quotation.quotationCustomerInfo[0].email}
                    </ExternalLink>
                  ) : null}
                  {quotation?.quotationCustomerInfo?.[0]?.mobileNumber ? (
                    <ExternalLink
                      to={`tel:${quotation.quotationCustomerInfo[0].mobileNumber}`}
                      className="text-xs"
                    >
                      {quotation.quotationCustomerInfo[0].mobileNumber}
                    </ExternalLink>
                  ) : null}
                  {quotation?.quotationCustomerInfo?.[0]?.fixeNumber ? (
                    <ExternalLink
                      to={`tel:${quotation.quotationCustomerInfo[0].fixeNumber}`}
                      className="text-xs"
                    >
                      {quotation.quotationCustomerInfo[0].fixeNumber}
                    </ExternalLink>
                  ) : null}
                </div>
              ),
              sort:
                quotation?.quotationCustomerInfo?.[0]?.companyName ||
                quotation?.quotationCustomerInfo?.[0]?.lastname
            },
            {
              key: "cooperator.lastname",
              label: "Coopérateur",
              display: (
                <div className="flex flex-col gap-1">
                  {quotation?.quotationCooperatorInfo?.[0]?.companyName ? (
                    <span className="font-medium">
                      {quotation.quotationCooperatorInfo[0].companyName}
                    </span>
                  ) : null}
                  {quotation?.quotationCooperatorInfo?.[0]?.lastname ||
                  quotation?.quotationCooperatorInfo?.[0]?.firstname ? (
                    <span>
                      {quotation.quotationCooperatorInfo[0].lastname}{" "}
                      {quotation.quotationCooperatorInfo[0].firstname}
                    </span>
                  ) : null}
                  {quotation?.quotationCooperatorInfo?.[0]?.email ? (
                    <ExternalLink
                      to={`mailto:${quotation.quotationCooperatorInfo[0].email}`}
                      className="text-xs"
                    >
                      {quotation.quotationCooperatorInfo[0].email}
                    </ExternalLink>
                  ) : null}
                  {quotation?.quotationCooperatorInfo?.[0]?.mobileNumber ? (
                    <ExternalLink
                      to={`tel:${quotation.quotationCooperatorInfo[0].mobileNumber}`}
                      className="text-xs"
                    >
                      {quotation.quotationCooperatorInfo[0].mobileNumber}
                    </ExternalLink>
                  ) : null}
                  {quotation?.quotationCooperatorInfo?.[0]?.fixeNumber ? (
                    <ExternalLink
                      to={`tel:${quotation.quotationCooperatorInfo[0].fixeNumber}`}
                      className="text-xs"
                    >
                      {quotation.quotationCooperatorInfo[0].fixeNumber}
                    </ExternalLink>
                  ) : null}
                </div>
              ),
              sort:
                quotation?.quotationCooperatorInfo?.[0]?.companyName ||
                quotation?.quotationCooperatorInfo?.[0]?.lastname,
              condition: isAdmin
            },
            {
              key: "totalTTC",
              label: "Montant TTC",
              display: quotation?.totalTTC ? formatMoney(quotation.totalTTC) : "",
              sort: quotation?.totalTTC ? parseFloat(quotation.totalTTC) : "",
              search: `${quotation?.totalTTC ? formatMoney(quotation.totalTTC) : ""} ${
                quotation?.totalTTC ? parseFloat(quotation.totalTTC) : ""
              }`
            },
            {
              key: "state",
              label: "Statut",
              display:
                quotation?.state === quotationStates.STATE_DRAFT
                  ? "Brouillon"
                  : quotation?.state === quotationStates.STATE_INSTANT_ADVANCE_FRAUD
                    ? "A contrôler par Hexa Coop"
                    : quotation?.state === quotationStates.STATE_SEND
                      ? "En attente de signature"
                      : quotation?.state === quotationStates.STATE_SIGNED
                        ? "Signé, en attente de choix du réglement"
                        : quotation?.state === quotationStates.STATE_PENDING
                          ? "En attente de réglement"
                          : quotation?.state === quotationStates.STATE_PENDING_CHOOSE
                            ? `En attente de réglement (${quotation.lastQuotationPayments?.paymentMethod?.name})`
                            : quotation?.state === quotationStates.STATE_PARTIAL_PAID
                              ? "Réglement incomplet"
                              : quotation?.state === quotationStates.STATE_PAID
                                ? "Payé"
                                : quotation?.state === quotationStates.STATE_CANCEL
                                  ? "Annulé"
                                  : "",
              sort: quotation?.state
            },
            {
              key: "signedAt",
              label: "Signature",
              display: (
                <div className="text-center">
                  {quotation?.state !== quotationStates.STATE_DRAFT ? (
                    quotation?.signedAt ? (
                      <div className="flex flex-col items-center justify-center gap-1">
                        <IonIcon color="primary" icon={checkmarkCircleOutline} className="size-4" />
                        Signé le
                        <br />
                        {formatDisplayDate(quotation.signedAt)} à{" "}
                        {formatDisplayHourFromDate(quotation.signedAt)}
                      </div>
                    ) : (
                      <>
                        {quotation?.sendAt ? (
                          <div className="mb-2">
                            Envoyé le
                            <br />
                            {formatDisplayDate(quotation.sendAt)} à{" "}
                            {formatDisplayHourFromDate(quotation.sendAt)}
                          </div>
                        ) : null}
                        {quotation?.isAvanceImmediate !== true ? (
                          <Button
                            onClick={() =>
                              quotation?.id
                                ? sendQuotationSignatureToCustomerMutation.mutate({
                                    id: quotation.id
                                  })
                                : null
                            }
                            fill="outline"
                            className="w-full"
                          >
                            {quotation?.sendAt ? "Renvoyer" : "Envoyer"}
                          </Button>
                        ) : null}
                      </>
                    )
                  ) : (
                    "Veuillez enregistrer le relevé de prestation"
                  )}
                </div>
              ),
              sort: quotation?.signedAt,
              search: quotation?.signedAt ? formatDisplayDate(quotation.signedAt) : ""
            },
            {
              key: "lastAction",
              label: "Dernière action",
              display: quotation?.lastActionRecovery ? (
                quotation.lastActionRecovery.dateActionAt ? (
                  <>
                    {quotation.lastActionRecovery.name} (
                    {formatDisplayDate(quotation.lastActionRecovery.dateActionAt)})
                  </>
                ) : (
                  "Date non définie"
                )
              ) : (
                ""
              )
            }
          ]
        };
      }) || [],
    [
      isAdmin,
      data?.pages,
      openFile,
      openModal,
      closeModal,
      sendQuotationSignatureToCustomerMutation,
      payByCreditCard
    ]
  );

  return (
    <>
      <div className="mt-4 flex flex-wrap items-center justify-between gap-8">
        <h2 className="m-0">{getStateTitle(selectedState)}</h2>

        <FormSelect onChange={(e) => setSelectedState(e.currentTarget.value as any)}>
          <option value="all">Toutes les relances</option>
          <option value="actions-of-the-day">Actions du jour</option>
          <option value="signature-failed">Signatures échouées</option>
          <option value="payment-failed">Paiements échoués</option>
          <option value="recovery-follow-up">Suivi de relance</option>
        </FormSelect>
      </div>
      <TableLayout
        data={tableData}
        isLoading={isFetching || isFetchingNextPage}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        AdvancedSearchForm={QuotationsRecoverySearchForm}
        advancedSearchParams={advancedSearchParams}
        handleAdvancedSearch={(data) => setAdvancedSearchParams(data)}
        globalActions={globalActions}
        groupedActions={groupedActions}
      >
        <div className="text-end text-sm font-medium">
          Total montants TTC :{" "}
          <span className="text-sm text-primary-600">
            {formatMoney(
              data?.pages.flat().reduce((acc, curr) => acc + Number(curr?.totalTTC ?? 0), 0) ?? 0
            )}
          </span>
        </div>
      </TableLayout>

      {renderModal()}
    </>
  );
};
