import type { ChartData } from "chart.js";

import {
  BarController,
  BarElement,
  Chart as ChartJS,
  Legend,
  LinearScale,
  TimeScale,
  Tooltip
} from "chart.js";
import { Bar } from "react-chartjs-2";

import "chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm";

// Register used modules
ChartJS.register(BarController, BarElement, Legend, LinearScale, TimeScale, Tooltip);

// Custom positioner for tooltip
Tooltip.positioners.cursor = (_, coords) => coords;

interface Props {
  datasets: ChartData<"bar", Record<string, number>>["datasets"];
}

export const MonthlyBarChart = ({ datasets }: Props) => {
  return (
    <Bar
      options={{
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            type: "time",
            time: {
              tooltipFormat: "MM/YYYY",
              displayFormats: {
                month: "MM/YYYY"
              },
              unit: "month"
            }
          }
        },
        plugins: {
          legend: {
            position: "bottom"
          },
          tooltip: {
            position: "cursor"
          }
        }
      }}
      data={{
        datasets
      }}
    />
  );
};
