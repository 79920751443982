import type { AdvancedSearchParams } from "~/components/TableLayout";
import type { MainTableProps } from "~/components/ui/MainTable";

import { useMemo, useState } from "react";
import { IonIcon } from "@ionic/react";
import { useInfiniteQuery, useMutation } from "@tanstack/react-query";
import {
  add,
  arrowForwardOutline,
  checkmarkCircleOutline,
  createOutline,
  documentAttachOutline,
  refreshOutline,
  trashOutline
} from "ionicons/icons";

import { membershipStates } from "~/config/api-constants";
import { queryKeys } from "~/config/query-keys-constants";
import { formatDisplayDate, formatDisplayHourFromDate, formatMoney } from "~/utils/misc";
import { useAuthContext } from "~/contexts/authContext";
import { useUIContext } from "~/contexts/uiContext";
import { useFileDeliver } from "~/hooks/useFileDeliver";
import { useModal } from "~/hooks/useModal";
import { handleApiError } from "~/services/errors";
import { getNextPageParamFn } from "~/api/_config";
import { queryClient } from "~/api/_queryClient";
import {
  deleteMembership,
  getMemberships,
  passMembershipToBilling,
  passMultipleMembershipsToBilling,
  renewMembership,
  sendMembershipSignatureToCustomer
} from "~/api/membership";
import { CooperatorsWaitingMembershipSearchForm } from "~/components/AdvancedSearchForms/Cooperators/CooperatorsWaitingMembershipSearchForm";
import { ConfirmationModal } from "~/components/Modals/ConfirmationModal";
import { MembershipPaymentsModal } from "~/components/Modals/MembershipPaymentsModal";
import { TableLayout } from "~/components/TableLayout";
import { Button } from "~/components/ui/Button/Button";
import { ExternalLink } from "~/components/ui/Link";
import euroIcon from "~/assets/icons/euro.svg";

export const CooperatorsWaitingMembership = () => {
  const { isAdmin } = useAuthContext();
  const { toast } = useUIContext();
  const { openModal, closeModal, renderModal } = useModal();
  const { openFile } = useFileDeliver();

  const [advancedSearchParams, setAdvancedSearchParams] = useState<AdvancedSearchParams>(null);

  const queryParams = {
    states: [
      membershipStates.STATE_DRAFT,
      membershipStates.STATE_SEND,
      membershipStates.STATE_SIGNED,
      membershipStates.STATE_PENDING,
      membershipStates.STATE_PENDING_CHOOSE,
      membershipStates.STATE_PARTIAL_PAID,
      membershipStates.STATE_PAID
    ],
    sort: {
      key: "createdAt",
      value: "DESC" as const
    },
    ...advancedSearchParams
  };

  const { data, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: [queryKeys.memberships, queryParams],
    queryFn: ({ pageParam }) => getMemberships({ ...queryParams, page: pageParam }),
    getNextPageParam: getNextPageParamFn
  });

  const deleteMembershipMutation = useMutation(deleteMembership, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.memberships]);
      toast("L'adhésion a été suprimée avec succès.", "success");
    },
    onError(error) {
      handleApiError(error, toast);
    }
  });

  const renewMembershipMutation = useMutation(renewMembership, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.memberships]);
      toast("Le contrat d'adhésion a été réinitialisé avec succès.", "success");
    },
    onError(error) {
      handleApiError(error, toast);
    }
  });

  const passMembershipToBillingMutation = useMutation(passMembershipToBilling, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.memberships]);
      toast("Le contrat d'adhésion a été passé en facturation avec succès.", "success");
    },
    onError(error) {
      handleApiError(error, toast);
    }
  });

  const passMultipleMembershipsToBillingMutation = useMutation(passMultipleMembershipsToBilling, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.memberships]);
      toast("Les contrats d'adhésion ont été passés en facturation avec succès.", "success");
    },
    onError(error) {
      handleApiError(error, toast);
    }
  });

  const sendMembershipSignatureToCustomerMutation = useMutation(sendMembershipSignatureToCustomer, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.memberships]);
      toast("Le contrat d'adhésion a été envoyé avec succès.", "success");
    },
    onError(error) {
      handleApiError(error, toast);
    }
  });

  const globalActions = [
    {
      label: "Nouvelle adhésion",
      icon: add,
      href: "/cooperator-dashboard/memberships/create",
      condition: isAdmin
    }
  ];

  const groupedActions = [
    {
      id: "pass-to-billing",
      label: "Passer en facturation les adhesions sélectionnés",
      condition: isAdmin,
      action: (ids: number[]) =>
        openModal(
          <ConfirmationModal
            title="Passer en facturation les adhesions sélectionnés"
            isOpen={true}
            onClose={closeModal}
            onConfirm={() => passMultipleMembershipsToBillingMutation.mutate({ ids })}
          >
            Êtes vous sûr de vouloir passer les adhesions sélectionnés en facture ? L&apos;action
            déclenchera l&apos;envoi des factures aux clients et aux coopérateurs.
          </ConfirmationModal>
        )
    }
  ];

  const tableData: MainTableProps["data"] = useMemo(
    () =>
      data?.pages.flat().map((membership) => ({
        itemId: membership?.id,
        checkboxConditions: [
          {
            id: "pass-to-billing",
            condition: isAdmin && membership?.state === membershipStates.STATE_PAID
          }
        ],
        actions: [
          {
            label: "Consulter le document",
            icon: documentAttachOutline,
            color: "slate",
            condition: membership?.state !== membershipStates.STATE_DRAFT,
            action: () =>
              openFile({ itemType: "membership-unbilled", itemParams: { id: membership?.id } })
          },
          {
            label: "Ajouter un réglement",
            icon: euroIcon,
            color: "teal",
            condition: isAdmin && membership?.state !== membershipStates.STATE_DRAFT,
            action: () =>
              membership?.id
                ? openModal(
                    <MembershipPaymentsModal
                      membership={membership}
                      withForm
                      isOpen={true}
                      onClose={closeModal}
                    />
                  )
                : null
          },
          {
            label: "Passer en facturation",
            icon: arrowForwardOutline,
            color: "orange-accent",
            condition: isAdmin && membership?.state === membershipStates.STATE_PAID,
            action: () =>
              openModal(
                <ConfirmationModal
                  title="Passer le contrat en facturation"
                  isOpen={true}
                  onClose={closeModal}
                  onConfirm={() =>
                    membership?.id
                      ? passMembershipToBillingMutation.mutate({ id: membership.id })
                      : null
                  }
                >
                  Êtes vous sûr de vouloir passer ce contrat en facture ? L&apos;action déclenchera
                  l&apos;envoi de la facture au coopérateur.
                </ConfirmationModal>
              )
          },
          {
            label: "Éditer le contrat",
            icon: createOutline,
            color: "blue",
            condition:
              isAdmin &&
              (membership?.state === membershipStates.STATE_DRAFT ||
                membership?.state === membershipStates.STATE_SEND),
            href: `/cooperator-dashboard/memberships/edit/${membership?.id}`
          },
          {
            label: "Supprimer le contrat",
            icon: trashOutline,
            color: "red",
            condition:
              isAdmin &&
              (membership?.state === membershipStates.STATE_DRAFT ||
                membership?.state === membershipStates.STATE_SEND),
            action: () =>
              openModal(
                <ConfirmationModal
                  title="Supprimer le contrat"
                  danger
                  isOpen={true}
                  onClose={closeModal}
                  onConfirm={() =>
                    membership?.id ? deleteMembershipMutation.mutate({ id: membership.id }) : null
                  }
                >
                  Êtes vous sûr de vouloir supprimer le contrat ?
                </ConfirmationModal>
              )
          },
          {
            label: "Réinitialiser",
            icon: refreshOutline,
            color: "orange",
            condition: isAdmin && !!membership?.signedAt,
            action: () =>
              openModal(
                <ConfirmationModal
                  title="Réinitialiser le contrat"
                  danger
                  isOpen={true}
                  onClose={closeModal}
                  onConfirm={() =>
                    membership?.id ? renewMembershipMutation.mutate({ id: membership.id }) : null
                  }
                >
                  Êtes vous sûr de vouloir réinitialiser le contrat ? Cela remettra le contrat en
                  brouillon en annulant la signature, il faudra alors le renvoyer au client en
                  l&apos;éditant.
                </ConfirmationModal>
              )
          }
        ],
        columns: [
          {
            key: "createdAt",
            label: "Date création",
            display: membership?.createdAt ? formatDisplayDate(membership.createdAt) : "",
            sort: membership?.createdAt,
            search: membership?.createdAt ? formatDisplayDate(membership.createdAt) : ""
          },
          {
            key: "period",
            label: "Période",
            display: (
              <div className="flex flex-col gap-1">
                <span>{membership?.startAt ? formatDisplayDate(membership.startAt) : " "}</span>
                <span>{membership?.endAt ? formatDisplayDate(membership.endAt) : " "}</span>
              </div>
            )
          },
          {
            key: "idNormalized",
            label: "Numéro",
            display: membership?.idNormalized
          },
          {
            key: "cooperator.lastname",
            label: "Coopérateur",
            display: (
              <div className="flex flex-col gap-1">
                {membership?.cooperator?.companyName ? (
                  <span className="font-medium">{membership.cooperator.companyName}</span>
                ) : null}
                {membership?.cooperator?.lastname ||
                membership?.contactLastname ||
                membership?.cooperator?.firstname ||
                membership?.contactFirstname ? (
                  <span>
                    {membership.contactLastname || membership.cooperator?.lastname}{" "}
                    {membership.contactFirstname || membership.cooperator?.firstname}
                  </span>
                ) : null}
                {membership?.contactEmail || membership?.cooperator?.email ? (
                  <ExternalLink
                    to={`mailto:${membership.contactEmail || membership.cooperator?.email}`}
                    className="text-xs"
                  >
                    {membership.contactEmail || membership.cooperator?.email}
                  </ExternalLink>
                ) : null}
                {membership?.cooperator?.mobileNumber ? (
                  <ExternalLink
                    to={`tel:${membership.cooperator.mobileNumber}`}
                    className="text-xs"
                  >
                    {membership.cooperator.mobileNumber}
                  </ExternalLink>
                ) : null}
                {membership?.cooperator?.fixeNumber ? (
                  <ExternalLink to={`tel:${membership.cooperator.fixeNumber}`} className="text-xs">
                    {membership.cooperator.fixeNumber}
                  </ExternalLink>
                ) : null}
              </div>
            ),
            sort: membership?.cooperator?.lastname,
            search: `${membership?.cooperator?.companyName} ${membership?.cooperator?.lastname} ${membership?.cooperator?.firstname} ${membership?.cooperator?.email} ${membership?.cooperator?.mobileNumber} ${membership?.cooperator?.fixeNumber}`,
            condition: isAdmin
          },
          {
            key: "totalTTC",
            label: "Montant TTC",
            display: membership?.totalTTC ? formatMoney(membership.totalTTC) : "",
            sort: membership?.totalTTC ? membership.totalTTC : "",
            search: membership?.totalTTC ? formatMoney(membership.totalTTC) : ""
          },
          {
            key: "state",
            label: "Statut",
            display:
              membership?.state === membershipStates.STATE_DRAFT
                ? "Brouillon"
                : membership?.state === membershipStates.STATE_SEND
                  ? "En attente de signature"
                  : membership?.state === membershipStates.STATE_SIGNED
                    ? "Signé, en attente de choix du réglement"
                    : membership?.state === membershipStates.STATE_PENDING
                      ? "En attente de réglement"
                      : membership?.state === membershipStates.STATE_PENDING_CHOOSE
                        ? `En attente de réglement (${membership.lastMembershipPayments?.paymentMethod?.name})`
                        : membership?.state === membershipStates.STATE_PARTIAL_PAID
                          ? "Réglement incomplet"
                          : membership?.state === membershipStates.STATE_PAID
                            ? "Payé"
                            : membership?.state === membershipStates.STATE_CANCEL
                              ? "Annulé"
                              : "",
            sort: membership?.state
          },
          {
            key: "signedAt",
            label: "Signature",
            display: (
              <div className="text-center">
                {membership?.state !== membershipStates.STATE_DRAFT ? (
                  membership?.signedAt ? (
                    <div className="flex flex-col items-center justify-center gap-1">
                      <IonIcon color="primary" icon={checkmarkCircleOutline} className="size-4" />
                      Signé le
                      <br />
                      {formatDisplayDate(membership.signedAt)} à{" "}
                      {formatDisplayHourFromDate(membership.signedAt)}
                    </div>
                  ) : (
                    <>
                      {membership?.sendAt ? (
                        <div className="mb-2">
                          Envoyé le
                          <br />
                          {formatDisplayDate(membership.sendAt)} à{" "}
                          {formatDisplayHourFromDate(membership.sendAt)}
                        </div>
                      ) : null}
                      <Button
                        onClick={() =>
                          membership?.id
                            ? sendMembershipSignatureToCustomerMutation.mutate({
                                id: membership.id
                              })
                            : null
                        }
                        fill="outline"
                        className="w-full"
                      >
                        {membership?.sendAt ? "Renvoyer" : "Envoyer"}
                      </Button>
                    </>
                  )
                ) : (
                  "Veuillez enregistrer le contrat d'adhésion"
                )}
              </div>
            ),
            sort: membership?.signedAt,
            search: membership?.signedAt ? formatDisplayDate(membership.signedAt) : ""
          },
          {
            key: "hasSocialPart",
            label: "Première adhésion",
            display: membership?.hasSocialPart ? "Oui" : "Non",
            sort: membership?.hasSocialPart
          }
        ]
      })) ?? [],
    [
      isAdmin,
      data?.pages,
      openFile,
      openModal,
      closeModal,
      deleteMembershipMutation,
      renewMembershipMutation,
      passMembershipToBillingMutation,
      sendMembershipSignatureToCustomerMutation
    ]
  );

  return (
    <>
      <TableLayout
        data={tableData}
        isLoading={isFetching || isFetchingNextPage}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        AdvancedSearchForm={CooperatorsWaitingMembershipSearchForm}
        advancedSearchParams={advancedSearchParams}
        handleAdvancedSearch={(data) => setAdvancedSearchParams(data)}
        globalActions={globalActions}
        groupedActions={groupedActions}
      >
        <div className="text-end text-sm font-medium">
          Total montants TTC :{" "}
          <span className="text-sm text-primary-600">
            {formatMoney(
              data?.pages.flat().reduce((acc, curr) => acc + Number(curr?.totalTTC ?? 0), 0) ?? 0
            )}
          </span>
        </div>
      </TableLayout>

      {renderModal()}
    </>
  );
};
