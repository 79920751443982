import type { AdvancedSearchParams } from "~/components/TableLayout";
import type { MainTableProps } from "~/components/ui/MainTable";

import { useState } from "react";
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import { add as addIcon, createOutline, personAddOutline, trashOutline } from "ionicons/icons";

import { userRoles } from "~/config/api-constants";
import { queryKeys } from "~/config/query-keys-constants";
import { formatMoney } from "~/utils/misc";
import { useUIContext } from "~/contexts/uiContext";
import { useModal } from "~/hooks/useModal";
import { handleApiError } from "~/services/errors";
import { getNextPageParamFn } from "~/api/_config";
import { queryClient } from "~/api/_queryClient";
import { getTotalRemainingCredit } from "~/api/credit-service";
import { deleteMultipleUsers, disableFinancer, getUsers } from "~/api/user";
import { PreFinancingFinancersSearchForm } from "~/components/AdvancedSearchForms/PreFinancing/PreFinancingFinancersSearchForm";
import { ConfirmationModal } from "~/components/Modals/ConfirmationModal";
import { TableLayout } from "~/components/TableLayout";
import { ExternalLink } from "~/components/ui/Link";

export const PreFinancingFinancers = () => {
  const { toast } = useUIContext();
  const { openModal, closeModal, renderModal } = useModal();

  const [advancedSearchParams, setAdvancedSearchParams] = useState<AdvancedSearchParams>(null);

  const queryParams = {
    role: userRoles.ROLE_FINANCER,
    ...advancedSearchParams
  };

  const { data, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: [queryKeys.users, queryParams],
    queryFn: ({ pageParam, signal }) => getUsers({ ...queryParams, page: pageParam, signal }),
    getNextPageParam: getNextPageParamFn
  });

  const { data: remainingCredit, isLoading: isRemainingCreditLoading } = useQuery({
    queryKey: [queryKeys.remainingCredit],
    queryFn: getTotalRemainingCredit
  });

  const disableFinancerMutation = useMutation(disableFinancer, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.users]);
      toast("Le financeur a été désactivé avec succès.", "success");
    },
    onError(error) {
      handleApiError(error, toast);
    }
  });

  const disableMultipleFinancerMutation = useMutation(deleteMultipleUsers, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.users]);
      toast("Les financeurs ont été désactivés avec succès.", "success");
    },
    onError(error) {
      handleApiError(error, toast);
    }
  });

  const globalActions = [
    {
      label: "Nouveau financeur",
      icon: addIcon,
      href: "/prefinancing-contracts/financers/create"
    }
  ];

  const groupedActions = [
    {
      id: "disable",
      label: "Désactiver les financeurs sélectionnés",
      action: (ids: number[]) =>
        openModal(
          <ConfirmationModal
            title="Désactiver les financeurs sélectionnés"
            danger
            isOpen={true}
            onClose={closeModal}
            onConfirm={() => disableMultipleFinancerMutation.mutate(ids)}
          >
            Êtes vous sûr de vouloir désactiver les financeurs sélectionnés ?
          </ConfirmationModal>
        )
    }
  ];

  const tableData: MainTableProps["data"] =
    data?.pages.flat().map((user) => ({
      itemId: user?.id,
      checkboxConditions: [{ id: "disable", condition: true }],
      actions: [
        {
          label: "Ajouter un bénéficiaire",
          icon: personAddOutline,
          color: "green",
          href: `/prefinancing-contracts/beneficiaries/create/${user?.id}`
        },
        {
          label: "Éditer le financeur",
          icon: createOutline,
          color: "blue",
          href: `/prefinancing-contracts/financers/edit/${user?.id}`
        },
        {
          label: "Désactiver le financeur",
          icon: trashOutline,
          color: "red",
          action: () =>
            openModal(
              <ConfirmationModal
                title="Désactiver le financeur"
                danger
                isOpen={true}
                onClose={closeModal}
                onConfirm={() => (user?.id ? disableFinancerMutation.mutate(user.id) : null)}
              >
                Êtes vous sûr de vouloir désactiver ce financeur ? Cette action est irréversible.
              </ConfirmationModal>
            )
        }
      ],
      columns: [
        {
          key: "lastname",
          label: "Nom/Prénom",
          display: `${user?.lastname} ${user?.firstname}`,
          sort: user?.lastname,
          search: `${user?.lastname} ${user?.firstname}`
        },
        { key: "companyName", label: "Société", display: user?.companyName },
        {
          key: "email",
          label: "Email",
          display: <ExternalLink to={`mailto:${user?.email}`}>{user?.email}</ExternalLink>
        },
        {
          key: "beneficiaries",
          label: "Bénéficiaires",
          display: (
            <div className="flex flex-col gap-1">
              {user?.beneficiaries?.slice(0, 8).map((b) => (
                <span key={b?.id}>
                  {b?.lastname} {b?.firstname}
                </span>
              ))}
              {user?.beneficiaries && user.beneficiaries.length > 8 ? (
                <span>+ {user.beneficiaries.length - 8} autres.</span>
              ) : null}
            </div>
          ),
          sort: user?.beneficiaries?.map((b) => `${b?.lastname} ${b?.firstname}`),
          search:
            user?.beneficiaries?.reduce((acc, b) => `${acc} ${b?.lastname} ${b?.firstname}`, "") ||
            ""
        },
        {
          key: "remainingCredit",
          label: "Crédit restant",
          display: user?.remainingCredit != null ? formatMoney(user.remainingCredit) : "",
          sort: user?.remainingCredit
        },
        { key: "deleted", label: "Activé", display: user?.deleted ? "Non" : "Oui" },
        {
          key: "commercial",
          label: "Commercial",
          display:
            user?.commercial?.lastname || user?.commercial?.firstname
              ? `${user.commercial.lastname || ""} ${user.commercial.firstname || ""}`
              : null
        }
      ]
    })) || [];

  return (
    <>
      <TableLayout
        data={tableData}
        isLoading={isFetching || isFetchingNextPage}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        globalActions={globalActions}
        groupedActions={groupedActions}
        AdvancedSearchForm={PreFinancingFinancersSearchForm}
        advancedSearchParams={advancedSearchParams}
        handleAdvancedSearch={setAdvancedSearchParams}
        handleSimpleSearch={setAdvancedSearchParams}
      >
        <div className="text-end text-sm font-medium">
          Total crédit restant :{" "}
          {isRemainingCreditLoading || !remainingCredit ? "..." : formatMoney(remainingCredit)}
        </div>
      </TableLayout>
      {renderModal()}
    </>
  );
};
