import type { UseFormRegister } from "react-hook-form";

import { useQuery } from "@tanstack/react-query";

import { queryKeys } from "~/config/query-keys-constants";
import { getJobs } from "~/api/job";
import { FormInput } from "~/components/ui/FormInput";
import { FormSelect } from "~/components/ui/FormSelect";

export const AdministrationCoopsSearchForm = ({ register }: { register: UseFormRegister<any> }) => {
  const queryParams = { itemsPerPage: 1500 };

  const { data, isLoading } = useQuery({
    queryKey: [queryKeys.jobs, queryParams],
    queryFn: () => getJobs(queryParams)
  });

  return (
    <>
      <FormInput label="Nom de société" type="text" optional {...register("companyName")} />
      <FormInput label="Nom" type="text" optional {...register("lastname")} />
      <FormInput label="Prénom" type="text" optional {...register("firstname")} />
      <FormInput label="Email" type="text" optional {...register("email")} />
      <FormInput label="Adresse" type="text" optional {...register("address")} />
      <FormInput label="Code postal" type="text" optional {...register("zipcode")} />
      <FormInput label="Ville" type="text" optional {...register("city")} />
      <FormSelect label="Métier" optional {...register("jobName")}>
        <option value="">Tous</option>
        {isLoading || !data || data.length === 0 ? (
          <option>Chargement des métiers...</option>
        ) : (
          <>
            {data.map((job) => (
              <option key={job?.id} value={job?.name}>
                {job?.name}
              </option>
            ))}
          </>
        )}
      </FormSelect>
    </>
  );
};
