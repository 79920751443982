import { IonContent, IonPage } from "@ionic/react";
import { Redirect, Switch } from "react-router";

import { useAuthContext } from "~/contexts/authContext";
import { CreateEditCustomer } from "~/pages/Customers/CreateEditCustomer";
import { CustomersPage } from "~/pages/Customers/Customers";
import { SubNavigation } from "~/components/SubNavigation";
import { ProtectedRoute } from "~/Router";

export const CustomersLayout = () => {
  const { isAuth } = useAuthContext();

  return (
    <IonPage>
      <IonContent className="container">
        <SubNavigation
          links={[
            {
              label: "Liste des clients",
              to: "/customers"
            }
          ]}
        />
        <Switch>
          <ProtectedRoute exact path="/customers" condition={isAuth}>
            <CustomersPage />
          </ProtectedRoute>

          <ProtectedRoute exact path="/customers/create" condition={isAuth}>
            <CreateEditCustomer />
          </ProtectedRoute>
          <ProtectedRoute exact path="/customers/edit/:user_id" condition={isAuth}>
            <CreateEditCustomer editMode />
          </ProtectedRoute>

          <Redirect to={"/customers"} />
        </Switch>
      </IonContent>
    </IonPage>
  );
};
