import type { PropsWithChildren } from "react";
import type { ModalProps } from "~/components/ui/Modal/Modal";

import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useForm } from "react-hook-form";

import { recoveryActionTypes } from "~/config/api-constants";
import { queryKeys } from "~/config/query-keys-constants";
import { formatDisplayDate } from "~/utils/misc";
import { useUIContext } from "~/contexts/uiContext";
import { handleApiError } from "~/services/errors";
import { queryClient } from "~/api/_queryClient";
import {
  createQuotationRecoveryAction,
  getRecoveryActions,
  sendQuotationRecoveryActionsToCooperator
} from "~/api/recovery-action";
import { Button } from "~/components/ui/Button/Button";
import { FormDatePicker } from "~/components/ui/FormDatePicker";
import { FormRow } from "~/components/ui/FormRow";
import { FormSelect } from "~/components/ui/FormSelect";
import { FormTextArea } from "~/components/ui/FormTextArea";
import { LoadingSpinner } from "~/components/ui/LoadingSpinner";
import { Modal } from "~/components/ui/Modal/Modal";
import { Table } from "~/components/ui/Table";
import { RecoveryQuotationActionSchema } from "~/schemas/recovery-action.schema";

interface RecoveryQuotationsActionsModalProps extends ModalProps {
  quotationId: number;
  withForm?: boolean;
}

export const RecoveryQuotationsActionsModal = ({
  quotationId,
  withForm = false,
  ...props
}: PropsWithChildren<RecoveryQuotationsActionsModalProps>) => {
  const { toast } = useUIContext();

  const queryParams = {
    quotationId
  };

  const { data: actions, isLoading: isActionsLoading } = useQuery({
    queryKey: [queryKeys.recoveryActions, queryParams],
    queryFn: () => getRecoveryActions({ ...queryParams, itemsPerPage: 100 })
  });

  const form = useForm<RecoveryQuotationActionSchema>({
    resolver: zodResolver(RecoveryQuotationActionSchema),
    values: {
      name: "",
      comment: null,
      dateActionAt: null,
      dateActionDoneAt: null
    }
  });

  const createActionMutation = useMutation({
    mutationFn: (data: RecoveryQuotationActionSchema) =>
      createQuotationRecoveryAction({ quotationId, data }),
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.quotations]);
      queryClient.invalidateQueries([queryKeys.recoveryQuotations]);
      queryClient.invalidateQueries([queryKeys.recoveriesActions]);
      form.reset();
      toast("L'action a été ajouté avec succès.", "success");
    },
    onError(error) {
      handleApiError(error, toast);
    }
  });

  const sendActionsMutation = useMutation({
    mutationFn: sendQuotationRecoveryActionsToCooperator,
    onSuccess: () => {
      toast("L'historique a été envoyé avec succès.", "success");
    },
    onError(error) {
      handleApiError(error, toast);
    }
  });

  const onSubmit = (data: RecoveryQuotationActionSchema) => {
    createActionMutation.mutate(data);
  };

  return (
    <Modal title="Actions" {...props}>
      <h2 className="text-xl font-semibold">Historiques des actions manuelles</h2>
      {isActionsLoading ? (
        <LoadingSpinner fullPage />
      ) : actions && actions.length > 0 ? (
        <div className="">
          <Table
            data={{
              headers: ["Nom", "Date d'action prévue", "Date d'action effectuée", "Action"],
              rows: actions.map((action) => ({
                key: action?.id || 0,
                columns: [
                  action?.name,
                  formatDisplayDate(action?.dateActionAt || ""),
                  formatDisplayDate(action?.dateActionDoneAt || ""),
                  action?.comment
                ]
              }))
            }}
          />
          <div className="mt-6 flex justify-end gap-4">
            <Button
              onClick={() => sendActionsMutation.mutate({ quotationId })}
              disabled={createActionMutation.isLoading}
            >
              Envoyer l&apos;historique au coopérateur
            </Button>
          </div>
        </div>
      ) : (
        <p>Aucune action enregistré.</p>
      )}
      {withForm ? (
        <>
          <h2 className="mt-8 text-xl font-semibold">Ajouter une action</h2>
          <form className="mt-4" onSubmit={form.handleSubmit(onSubmit)}>
            <FormRow className="lg:grid-cols-2">
              <FormSelect
                label="Nom de l'action"
                errorMessage={form.formState.errors.name?.message}
                {...form.register("name")}
              >
                <option value="" disabled>
                  Choisir une action
                </option>
                {Object.entries(recoveryActionTypes).map(([key, value]) => (
                  <option key={key} value={value}>
                    {value}
                  </option>
                ))}
              </FormSelect>
            </FormRow>
            <FormRow className="lg:grid-cols-2">
              <FormDatePicker
                label="Date prévue"
                errorMessage={form.formState.errors.dateActionAt?.message}
                {...form.register("dateActionAt")}
              />
              <FormDatePicker
                label="Effectuée le"
                errorMessage={form.formState.errors.dateActionDoneAt?.message}
                {...form.register("dateActionDoneAt")}
              />
            </FormRow>
            <FormRow className="lg:grid-cols-2">
              <FormTextArea
                label="Commentaire"
                className="col-span-2"
                rows={4}
                errorMessage={form.formState.errors.comment?.message}
                {...form.register("comment")}
              />
            </FormRow>
            <div className="mt-12 flex justify-end gap-4 border-t border-grey-200 pt-6">
              <Button fill="clear" onClick={() => props.onClose()}>
                Annuler
              </Button>
              <Button type="submit" disabled={createActionMutation.isLoading}>
                Ajouter
              </Button>
            </div>
          </form>
        </>
      ) : null}
    </Modal>
  );
};
