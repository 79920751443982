import { z } from "zod";

export const BeneficiarySchema = z.object({
  isActive: z.boolean().optional().nullable(),
  lastname: z.string().min(1, "Veuillez renseigner un nom."),
  firstname: z.string().min(1, "Veuillez renseigner un prénom."),
  email: z.string().min(1, "Veuillez renseigner un email."),
  mobileNumber: z
    .string()
    .min(1, "Veuillez renseigner un numéro de téléphone mobile.")
    .regex(
      /^(\+33|\+330|0)[67]([-. ]?[0-9]{2}){4}$/,
      "Veuillez renseigner un numéro de téléphone mobile français valide."
    )
    .transform((value) => value.replace(/[-. ]/g, "")),
  fixeNumber: z
    .string()
    .regex(
      /^(\+33|\+330|0)[1-9]([-. ]?[0-9]{2}){4}$/,
      "Veuillez renseigner un numéro de téléphone fixe valide ou laisser ce champ vide."
    )
    .transform((value) => value.replace(/[-. ]/g, ""))
    .or(z.literal("").transform(() => null))
    .optional()
    .nullable(),
  address: z.string().min(1, "Veuillez renseigner une adresse."),
  zipcode: z.string().min(1, "Veuillez renseigner un code postal."),
  city: z.string().min(1, "Veuillez renseigner une ville."),
  isPro: z.boolean(),
  financer: z.string().min(1, "Veuillez sélectionner un financeur.")
});

export const BeneficiaryFormSchema = BeneficiarySchema.omit({
  isActive: true
});

export type BeneficiarySchema = z.infer<typeof BeneficiarySchema>;
export type BeneficiaryFormSchema = z.infer<typeof BeneficiaryFormSchema>;
