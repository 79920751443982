import type { AdvancedSearchParams } from "~/components/TableLayout";
import type { MainTableProps } from "~/components/ui/MainTable";

import { useMemo, useState } from "react";
import { IonIcon } from "@ionic/react";
import { useInfiniteQuery, useMutation } from "@tanstack/react-query";
import {
  add as addIcon,
  arrowForwardOutline,
  cardOutline,
  checkmarkCircleOutline,
  createOutline,
  documentAttachOutline,
  downloadOutline,
  listOutline,
  mailOutline,
  refreshOutline,
  trashOutline,
  warningOutline
} from "ionicons/icons";

import { quotationStates, quotationTypes } from "~/config/api-constants";
import { queryKeys } from "~/config/query-keys-constants";
import { formatDisplayDate, formatDisplayHourFromDate, formatMoney } from "~/utils/misc";
import { initiateCreditCardPayment } from "~/utils/payment";
import { useAuthContext } from "~/contexts/authContext";
import { useUIContext } from "~/contexts/uiContext";
import { useFileDeliver } from "~/hooks/useFileDeliver";
import { useModal } from "~/hooks/useModal";
import { handleApiError } from "~/services/errors";
import { isQuotationDeletable, isQuotationEditable } from "~/services/quotations";
import { getNextPageParamFn } from "~/api/_config";
import { queryClient } from "~/api/_queryClient";
import { anonymousPayQuotationByCreditCard } from "~/api/anonymous";
import {
  deleteQuotation,
  getQuotations,
  passMultipleQuotationsToBilling,
  passQuotationToBilling,
  renewQuotation,
  sendQuotationSignatureToCustomer
} from "~/api/quotation";
import { QuotationsPendingSearchForm } from "~/components/AdvancedSearchForms/Quotations/QuotationsPendingSearchForm";
import { ConfirmationModal } from "~/components/Modals/ConfirmationModal";
import { QuotationPaymentsModal } from "~/components/Modals/QuotationPaymentsModal";
import { QuotationRecoveriesModal } from "~/components/Modals/QuotationRecoveriesModal";
import { RecoveryQuotationsActionsModal } from "~/components/Modals/RecoveryQuotationActionsModal";
import { StatusPayment } from "~/components/StatusPayment";
import { TableLayout } from "~/components/TableLayout";
import { Button } from "~/components/ui/Button/Button";
import { ExternalLink, Link } from "~/components/ui/Link";
import euroIcon from "~/assets/icons/euro.svg";

export const QuotationsPending = () => {
  const { isAdmin } = useAuthContext();
  const { toast } = useUIContext();
  const { openModal, closeModal, renderModal } = useModal();
  const { openFile } = useFileDeliver();

  const [advancedSearchParams, setAdvancedSearchParams] = useState<AdvancedSearchParams>(null);

  const queryParams = {
    typeQuotation: quotationTypes.TYPE_QUOTATION_NORMAL,
    states: [
      quotationStates.STATE_DRAFT,
      quotationStates.STATE_SEND,
      quotationStates.STATE_SIGNED,
      quotationStates.STATE_PENDING,
      quotationStates.STATE_PENDING_CHOOSE,
      quotationStates.STATE_PARTIAL_PAID,
      quotationStates.STATE_PAID,
      quotationStates.STATE_INSTANT_ADVANCE_FRAUD
    ],
    ...advancedSearchParams
  };

  const { data, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: [queryKeys.quotations, queryParams],
    queryFn: ({ pageParam }) => {
      return getQuotations({ ...queryParams, page: pageParam });
    },
    getNextPageParam: getNextPageParamFn
  });

  const passQuotationToBillingMutation = useMutation(passQuotationToBilling, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.quotations]);
      toast("Le relevé a été passé en facturation avec succès.", "success");
    },
    onError(error) {
      handleApiError(error, toast);
    }
  });

  const passMultipleQuotationToBillingMutation = useMutation(passMultipleQuotationsToBilling, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.quotations]);
      toast("Les relevés ont été passés en facturation avec succès.", "success");
    },
    onError(error) {
      handleApiError(error, toast);
    }
  });

  const renewQuotationMutation = useMutation(renewQuotation, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.quotations]);
      toast("Le relevé a été réinitialisé avec succès.", "success");
    },
    onError(error) {
      handleApiError(error, toast);
    }
  });

  const deleteQuotationMutation = useMutation(deleteQuotation, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.quotations]);
      toast("Le relevé a été supprimé avec succès.", "success");
    },
    onError(error) {
      handleApiError(error, toast);
    }
  });

  const sendQuotationSignatureToCustomerMutation = useMutation(sendQuotationSignatureToCustomer, {
    onSuccess: () => {
      toast("Le relevé a été envoyé avec succès.", "success");
    },
    onError(error) {
      handleApiError(error, toast);
    }
  });

  const payByCreditCard = useMutation({
    mutationFn: anonymousPayQuotationByCreditCard,
    onError(error) {
      handleApiError(error, toast);
    }
  });

  const globalActions = [
    {
      label: "Nouveau relevé",
      icon: addIcon,
      href: "/quotations/create"
    },
    {
      label: "Exporter",
      icon: downloadOutline,
      action: () =>
        openFile({
          itemType: "export-quotations-csv"
        })
    }
  ];

  const groupedActions = [
    {
      id: "pass-to-biling",
      label: "Passer en facturation les relevés sélectionnés",
      condition: isAdmin,
      action: (ids: number[]) =>
        openModal(
          <ConfirmationModal
            title="Passer en facturation les relevés sélectionnés"
            isOpen={true}
            onClose={closeModal}
            onConfirm={() => passMultipleQuotationToBillingMutation.mutate({ ids })}
          >
            Êtes vous sûr de vouloir passer les relevés sélectionnés en facture ? L&apos;action
            déclenchera l&apos;envoi des factures aux clients et aux coopérateurs.
          </ConfirmationModal>
        )
    }
  ];

  const tableData: MainTableProps["data"] = useMemo(
    () =>
      data?.pages.flat().map((quotation) => ({
        itemId: quotation?.id,
        checkboxConditions: [
          {
            id: "pass-to-biling",
            condition: isAdmin && quotation?.state === quotationStates.STATE_PAID
          }
        ],
        actions: [
          {
            label: "Consulter le relevé",
            icon: documentAttachOutline,
            color: "slate",
            condition:
              quotation?.state !== quotationStates.STATE_DRAFT &&
              quotation?.state !== quotationStates.STATE_INSTANT_ADVANCE_FRAUD,
            action: () => openFile({ itemType: "quotation", itemParams: { id: quotation?.id } })
          },
          {
            label: "Consulter le devis",
            icon: documentAttachOutline,
            color: "yellow",
            condition:
              quotation?.state === quotationStates.STATE_DRAFT ||
              quotation?.state === quotationStates.STATE_INSTANT_ADVANCE_FRAUD,
            action: () =>
              openFile({ itemType: "quotation-devis", itemParams: { id: quotation?.id } })
          },
          {
            label: "Voir les actions",
            icon: listOutline,
            color: "indigo",
            condition: quotation?.isRecoverable === true,
            action: () =>
              quotation?.id
                ? openModal(
                    <RecoveryQuotationsActionsModal
                      quotationId={quotation.id}
                      withForm
                      isOpen
                      onClose={closeModal}
                    />
                  )
                : null
          },
          {
            label: "Voir les relances",
            icon: mailOutline,
            color: "lime",
            condition:
              quotation?.isRecoverable === true &&
              quotation.isAvanceImmediate !== true &&
              quotation.state !== quotationStates.STATE_DRAFT,
            action: () =>
              quotation?.id
                ? openModal(
                    <QuotationRecoveriesModal
                      quotationId={quotation.id}
                      isOpen
                      onClose={closeModal}
                    />
                  )
                : null
          },
          {
            label: "Ajouter un réglement",
            icon: euroIcon,
            color: "teal",
            condition:
              isAdmin &&
              !quotation?.isAvanceImmediate &&
              quotation?.state !== quotationStates.STATE_DRAFT &&
              quotation?.state !== quotationStates.STATE_INSTANT_ADVANCE_FRAUD,
            action: () =>
              quotation?.id
                ? openModal(
                    <QuotationPaymentsModal
                      quotation={quotation}
                      withForm
                      isOpen={true}
                      onClose={closeModal}
                      onSuccess={() => setAdvancedSearchParams({})}
                    />
                  )
                : null
          },
          {
            label: "Payer par carte bancaire",
            icon: cardOutline,
            color: "purple",
            condition:
              quotation?.state !== quotationStates.STATE_DRAFT &&
              quotation?.state !== quotationStates.STATE_INSTANT_ADVANCE_FRAUD &&
              quotation?.isAvanceImmediate !== true &&
              isAdmin,
            action: async () => {
              if (!quotation?.id) return;
              const payboxData = await payByCreditCard.mutateAsync({
                id: quotation.id
              });
              await initiateCreditCardPayment(payboxData);
            }
          },
          {
            label: "Passer en facturation",
            icon: arrowForwardOutline,
            color: "orange-accent",
            condition: isAdmin && quotation?.state === quotationStates.STATE_PAID,
            action: () =>
              openModal(
                <ConfirmationModal
                  title="Passer le relevé en facturation"
                  isOpen={true}
                  onClose={closeModal}
                  onConfirm={() =>
                    quotation?.id
                      ? passQuotationToBillingMutation.mutate({ id: quotation.id })
                      : null
                  }
                >
                  Êtes vous sûr de vouloir passer ce relevé en facturation ? L&apos;action
                  déclenchera l&apos;envoi de la facture au client.
                </ConfirmationModal>
              )
          },
          {
            label: "Éditer le relevé",
            icon: createOutline,
            color: "blue",
            condition: isQuotationEditable({ quotation, isAdmin }),
            href: `/quotations/edit/${quotation?.id}`
          },
          {
            label: "Annuler le relevé",
            icon: trashOutline,
            color: "red",
            condition: isQuotationDeletable({ quotation, isAdmin }),
            action: () =>
              openModal(
                <ConfirmationModal
                  title="Supprimer un relevé"
                  isOpen={true}
                  onClose={closeModal}
                  danger
                  onConfirm={() =>
                    quotation?.id ? deleteQuotationMutation.mutate({ id: quotation.id }) : null
                  }
                >
                  Êtes-vous sûr de vouloir supprimer ce relevé ? Cette action est irréversible.
                </ConfirmationModal>
              )
          },
          {
            label: "Réinitialiser",
            icon: refreshOutline,
            color: "orange",
            condition:
              isAdmin &&
              quotation?.isAvanceImmediate === false &&
              !!quotation.signedAt &&
              !(quotation.quotationPayments?.length && quotation.quotationPayments.length > 0),
            action: () =>
              openModal(
                <ConfirmationModal
                  title="Réinitialiser le relevé"
                  danger
                  isOpen={true}
                  onClose={closeModal}
                  onConfirm={() =>
                    quotation?.id ? renewQuotationMutation.mutate({ id: quotation.id }) : null
                  }
                >
                  Êtes-vous sûr de vouloir réinitialiser ce relevé ? Cela remettra le relevé en
                  brouillon en annulant la signature, il faudra alors le renvoyer au client en
                  l&apos;éditant.
                </ConfirmationModal>
              )
          }
        ],
        columns: [
          {
            key: "trueDateValidate",
            label: "Date",
            display: quotation?.trueDateValidate
              ? formatDisplayDate(quotation.trueDateValidate)
              : "",
            sort: quotation?.trueDateValidate,
            search: quotation?.trueDateValidate ? formatDisplayDate(quotation.trueDateValidate) : ""
          },
          {
            key: "idNormalized",
            label: "N° relevé",
            display: (
              <Link
                to={`/file/deliver/quotation/${quotation?.id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {quotation?.idNormalized}
              </Link>
            )
          },
          {
            key: "customer.lastname",
            label: "Client",
            display: (
              <div className="flex flex-col gap-1">
                {quotation?.quotationCustomerInfo?.[0]?.companyName ? (
                  <span className="font-medium">
                    {quotation.quotationCustomerInfo[0]?.companyName}
                  </span>
                ) : null}
                {quotation?.quotationCustomerInfo?.[0]?.lastname ||
                quotation?.quotationCustomerInfo?.[0]?.firstname ? (
                  <span>
                    {quotation.quotationCustomerInfo[0]?.lastname}{" "}
                    {quotation.quotationCustomerInfo[0]?.firstname}
                  </span>
                ) : null}
                {quotation?.quotationCustomerInfo?.[0]?.email ? (
                  <ExternalLink
                    to={`mailto:${quotation.quotationCustomerInfo[0]?.email}`}
                    className="text-xs"
                  >
                    {quotation.quotationCustomerInfo[0]?.email}
                  </ExternalLink>
                ) : null}
                {quotation?.quotationCustomerInfo?.[0]?.mobileNumber ? (
                  <ExternalLink
                    to={`tel:${quotation.quotationCustomerInfo[0]?.mobileNumber}`}
                    className="text-xs"
                  >
                    {quotation.quotationCustomerInfo[0]?.mobileNumber}
                  </ExternalLink>
                ) : null}
                {quotation?.quotationCustomerInfo?.[0]?.fixeNumber ? (
                  <span className="text-xs">{quotation.quotationCustomerInfo[0]?.fixeNumber}</span>
                ) : null}
              </div>
            ),
            sort:
              quotation?.quotationCustomerInfo?.[0]?.companyName ||
              quotation?.quotationCustomerInfo?.[0]?.lastname,
            search: `${quotation?.quotationCustomerInfo?.[0]?.companyName} ${quotation?.quotationCustomerInfo?.[0]?.lastname} ${quotation?.quotationCustomerInfo?.[0]?.firstname} ${quotation?.quotationCustomerInfo?.[0]?.email} ${quotation?.quotationCustomerInfo?.[0]?.mobileNumber} ${quotation?.quotationCustomerInfo?.[0]?.fixeNumber}`
          },
          {
            key: "cooperator.lastname",
            label: "Coopérateur",
            display: (
              <div className="flex flex-col gap-1">
                {quotation?.quotationCooperatorInfo?.[0]?.companyName ? (
                  <span className="font-medium">
                    {quotation.quotationCooperatorInfo[0].companyName}
                  </span>
                ) : null}
                {quotation?.quotationCooperatorInfo?.[0]?.lastname ||
                quotation?.quotationCooperatorInfo?.[0]?.firstname ? (
                  <span>
                    {quotation.quotationCooperatorInfo[0].lastname}{" "}
                    {quotation.quotationCooperatorInfo[0].firstname}
                  </span>
                ) : null}
                {quotation?.quotationCooperatorInfo?.[0]?.email ? (
                  <ExternalLink
                    to={`mailto:${quotation.quotationCooperatorInfo[0].email}`}
                    className="text-xs"
                  >
                    {quotation.quotationCooperatorInfo[0].email}
                  </ExternalLink>
                ) : null}
                {quotation?.quotationCooperatorInfo?.[0]?.mobileNumber ? (
                  <ExternalLink
                    to={`tel:${quotation.quotationCooperatorInfo[0].mobileNumber}`}
                    className="text-xs"
                  >
                    {quotation.quotationCooperatorInfo[0].mobileNumber}
                  </ExternalLink>
                ) : null}
                {quotation?.quotationCooperatorInfo?.[0]?.fixeNumber ? (
                  <ExternalLink
                    to={`tel:${quotation.quotationCooperatorInfo[0].fixeNumber}`}
                    className="text-xs"
                  >
                    {quotation.quotationCooperatorInfo[0].fixeNumber}
                  </ExternalLink>
                ) : null}
              </div>
            ),
            sort: quotation?.quotationCooperatorInfo?.[0]?.lastname,
            search: `${quotation?.quotationCooperatorInfo?.[0]?.companyName} ${quotation?.quotationCooperatorInfo?.[0]?.lastname} ${quotation?.quotationCooperatorInfo?.[0]?.firstname} ${quotation?.quotationCooperatorInfo?.[0]?.email} ${quotation?.quotationCooperatorInfo?.[0]?.mobileNumber} ${quotation?.quotationCooperatorInfo?.[0]?.fixeNumber}`,
            condition: isAdmin
          },
          {
            key: "totalTTC",
            label: "Montant TTC",
            display: quotation?.totalTTC ? formatMoney(quotation.totalTTC) : "",
            sort: quotation?.totalTTC ? parseFloat(quotation.totalTTC) : "",
            search: quotation?.totalTTC ? formatMoney(quotation.totalTTC) : ""
          },
          {
            key: "isAvanceImmediate",
            label: "Avance immédiate",
            display: quotation?.isAvanceImmediate ? "Oui" : "Non",
            sort: quotation?.isAvanceImmediate,
            search: quotation?.isAvanceImmediate ? "avance immédiate" : ""
          },
          {
            key: "state",
            label: "Statut",
            display:
              quotation?.state === quotationStates.STATE_DRAFT
                ? "Brouillon"
                : quotation?.state === quotationStates.STATE_INSTANT_ADVANCE_FRAUD
                  ? "A contrôler par Hexa Coop"
                  : quotation?.state === quotationStates.STATE_SEND
                    ? "En attente de signature"
                    : quotation?.state === quotationStates.STATE_SIGNED
                      ? "Signé, en attente de choix du réglement"
                      : quotation?.state === quotationStates.STATE_PENDING
                        ? "En attente de réglement"
                        : quotation?.state === quotationStates.STATE_PENDING_CHOOSE
                          ? `En attente de réglement (${quotation.lastQuotationPayments?.paymentMethod?.name})`
                          : quotation?.state === quotationStates.STATE_PARTIAL_PAID
                            ? "Réglement incomplet"
                            : quotation?.state === quotationStates.STATE_PAID
                              ? "Payé"
                              : quotation?.state === quotationStates.STATE_CANCEL
                                ? "Annulé"
                                : "",
            sort: quotation?.state
          },
          {
            key: "signed",
            label: "Envoi & Signature",
            display: (
              <div className="text-center">
                {quotation?.state !== quotationStates.STATE_DRAFT &&
                quotation?.state !== quotationStates.STATE_INSTANT_ADVANCE_FRAUD ? (
                  quotation?.signedAt ? (
                    <div className="flex flex-col items-center justify-center gap-1">
                      <IonIcon color="primary" icon={checkmarkCircleOutline} className="size-4" />
                      Signé le
                      <br />
                      {formatDisplayDate(quotation.signedAt)} à{" "}
                      {formatDisplayHourFromDate(quotation.signedAt)}
                    </div>
                  ) : (
                    <>
                      {quotation?.sendAt ? (
                        <div className="mb-2">
                          Envoyé le
                          <br />
                          {formatDisplayDate(quotation.sendAt)} à{" "}
                          {formatDisplayHourFromDate(quotation.sendAt)}
                        </div>
                      ) : null}
                      {quotation?.isAvanceImmediate !== true ? (
                        <Button
                          onClick={() =>
                            quotation?.id
                              ? sendQuotationSignatureToCustomerMutation.mutate({
                                  id: quotation.id
                                })
                              : null
                          }
                          fill="outline"
                          className="w-full"
                        >
                          {quotation?.sendAt ? "Renvoyer" : "Envoyer"}
                        </Button>
                      ) : null}
                    </>
                  )
                ) : quotation.scheduleQuotationTasks &&
                  quotation.scheduleQuotationTasks.length > 0 &&
                  quotation.scheduleQuotationTasks[0]?.scheduledAt ? (
                  <div className="flex flex-col items-center justify-center gap-1 text-center">
                    <IonIcon icon={warningOutline} color="warning" className="size-4" />
                    Envoi prévu le{" "}
                    {formatDisplayDate(quotation.scheduleQuotationTasks[0].scheduledAt)}
                  </div>
                ) : (
                  "Veuillez enregistrer le relevé de prestation"
                )}
              </div>
            ),
            sort: quotation?.state
          }
        ]
      })) || [],
    [
      data,
      deleteQuotationMutation,
      isAdmin,
      openFile,
      openModal,
      closeModal,
      renewQuotationMutation,
      sendQuotationSignatureToCustomerMutation,
      passQuotationToBillingMutation,
      payByCreditCard
    ]
  );

  return (
    <>
      <StatusPayment className="mb-6 mt-6" />

      <TableLayout
        data={tableData}
        isLoading={isFetching || isFetchingNextPage}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        AdvancedSearchForm={QuotationsPendingSearchForm}
        advancedSearchParams={advancedSearchParams}
        handleAdvancedSearch={(data) => setAdvancedSearchParams(data)}
        globalActions={globalActions}
        groupedActions={groupedActions}
      >
        <div className="text-end text-sm font-medium">
          Total montants TTC :{" "}
          <span className="text-sm text-primary-600">
            {formatMoney(
              data?.pages.flat().reduce((acc, curr) => acc + Number(curr?.totalTTC ?? 0), 0) ?? 0
            )}
          </span>
        </div>
      </TableLayout>

      {renderModal()}
    </>
  );
};
