import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router";

import { queryKeys } from "~/config/query-keys-constants";
import { useUIContext } from "~/contexts/uiContext";
import { handleApiError } from "~/services/errors";
import { createConfig, getConfig, updateConfig } from "~/api/config";
import { Button } from "~/components/ui/Button/Button";
import { FormInput } from "~/components/ui/FormInput";
import { FormRow } from "~/components/ui/FormRow";
import { LoadingSpinner } from "~/components/ui/LoadingSpinner";
import { ConfigSchema } from "~/schemas/config.schema";

export const CreateEditConfig = ({ editMode = false }: { editMode?: boolean }) => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const { toast } = useUIContext();
  const { config_id: configId } = useParams<{ config_id?: string }>();

  const { data: configData, isLoading: isConfigLoading } = useQuery({
    queryKey: [queryKeys.configs, { id: configId }],
    queryFn: () => (configId ? getConfig(parseInt(configId)) : null),
    enabled: !!configId
  });

  const configMutation = useMutation({
    mutationFn: (data: ConfigSchema) =>
      editMode && configId ? updateConfig({ id: parseInt(configId), data }) : createConfig(data),
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: [queryKeys.configs] });
      toast(
        editMode
          ? "La configuration a été modifié avec succès."
          : "La configuration a été créé avec succès.",
        "success"
      );
      history.push("/administration/configurations");
    },
    onError(error) {
      handleApiError(error, toast);
    }
  });

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<ConfigSchema>({
    resolver: zodResolver(ConfigSchema),
    values: {
      name: configData?.name || "",
      code: configData?.code || "",
      value: configData?.value || ""
    }
  });

  const onSubmit = (data: ConfigSchema) => {
    configMutation.mutate(data);
  };

  if (!!configId && isConfigLoading) {
    return <LoadingSpinner size="lg" fullPage />;
  }

  if (!!configId && !configData) {
    return <p className="text-center">La configuration à éditer est introuvable.</p>;
  }

  return (
    <>
      <h2 className="mt-6">
        {editMode
          ? `Édition d'une configuration : ${configData?.name}`
          : "Création d'une configuration"}
      </h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormRow>
          <FormInput label="Nom" errorMessage={errors.name?.message} {...register("name")} />
          <FormInput label="Code" errorMessage={errors.code?.message} {...register("code")} />
        </FormRow>
        <FormRow>
          <FormInput label="Valeur" errorMessage={errors.value?.message} {...register("value")} />
        </FormRow>

        <div className="mt-12 flex justify-end gap-4 border-t border-grey-200 pt-6">
          <Button fill="clear" onClick={() => history.goBack()}>
            Annuler
          </Button>
          <Button type="submit" disabled={configMutation.isLoading}>
            {editMode ? "Modifier la config" : "Créer la config"}
          </Button>
        </div>
      </form>
    </>
  );
};
