import type { DeepPartial } from "react-hook-form";
import type { CreditServiceSchema } from "~/schemas/credit-service.schema";
import type { CreditService } from "~/types/credit-service.types";

import { INFINITE_SCROLL_LIMIT } from "~/config/api";
import { api } from "~/api/_config";

export const getCreditServices = async ({
  beneficiaryId,
  page,
  itemsPerPage
}: {
  beneficiaryId?: number;
  page?: number;
  itemsPerPage?: number;
}) => {
  const response = await api.get<DeepPartial<CreditService[]>>(`/api/admin/credit_service`, {
    params: {
      ...(beneficiaryId && { "creditFor.id": beneficiaryId }),
      page,
      itemsPerPage: itemsPerPage ?? INFINITE_SCROLL_LIMIT
    }
  });
  return response.data;
};

export const createCreditService = async ({
  beneficiaryId,
  data
}: {
  beneficiaryId: number;
  data: CreditServiceSchema;
}) => {
  const response = await api.post<DeepPartial<CreditService>>(`/api/admin/credit_service`, {
    "creditFor.id": beneficiaryId,
    ...data
  });
  return response.data;
};

export const getTotalRemainingCredit = async () => {
  const response = await api.get<number>(`/api/admin/credit_service/total/remaining`);
  return response.data;
};
