import { createRoot } from "react-dom/client";

import { App, withProviders } from "./App";

const container = document.getElementById("root");

if (container) {
  const root = createRoot(container);
  root.render(withProviders(App));
}
