export const connectedUserRoles = {
  ROLE_ADMIN: "ROLE_ADMIN",
  ROLE_COOPERATOR: "ROLE_COOPERATOR"
} as const;
export type ConnectedUserRole = (typeof connectedUserRoles)[keyof typeof connectedUserRoles];

export const civilities = {
  MONSIEUR: 1,
  MADAME: 2
} as const;
export type Civility = (typeof civilities)[keyof typeof civilities];

export const userRoles = {
  ROLE_ADMIN: "ROLE_ADMIN",
  ROLE_COOPERATOR: "ROLE_COOPERATOR",
  ROLE_CUSTOMER: "ROLE_CUSTOMER",
  ROLE_COMMERCIAL: "ROLE_COMMERCIAL",
  ROLE_BENEFICIARY: "ROLE_BENEFICIARY",
  ROLE_FINANCER: "ROLE_FINANCER"
} as const;
export type UserRole = (typeof userRoles)[keyof typeof userRoles];

export const userTypes = {
  TYPE_SIMPLE: 1,
  TYPE_FINANCER: 2,
  TYPE_BENEFICIARY: 3
} as const;
export type UserType = (typeof userTypes)[keyof typeof userTypes];

export const quotationStates = {
  STATE_INSTANT_ADVANCE_FRAUD: -3,
  STATE_PAYMENT_PROBLEM: -2,
  STATE_CANCEL: -1, // ( = STATE_DELETED)
  // STATE_DELETED: -1, // ( = STATE_CANCEL)
  STATE_DRAFT: 0,
  STATE_SEND: 1,
  STATE_SIGNED: 2,
  STATE_PENDING: 3,
  STATE_PENDING_CHOOSE: 4,
  STATE_PARTIAL_PAID: 5,
  STATE_BILL: 6,
  STATE_PAID: 7
} as const;
export type QuotationState = (typeof quotationStates)[keyof typeof quotationStates];

export const membershipStates = {
  STATE_PAYMENT_PROBLEM: -2,
  STATE_DELETED: -1,
  STATE_CANCEL: -1,
  STATE_DRAFT: 0,
  STATE_SEND: 1,
  STATE_SIGNED: 2,
  STATE_PENDING: 3,
  STATE_PENDING_CHOOSE: 4,
  STATE_PARTIAL_PAID: 5,
  STATE_BILL: 6,
  STATE_PAID: 7
} as const;
export type MembershipState = (typeof membershipStates)[keyof typeof membershipStates];

export const quotationPaymentStates = {
  STATE_PAYMENT_PENDING: 0,
  STATE_PAYMENT_TO_SEND: 1,
  STATE_PAYMENT_SENT: 2,
  STATE_PAYMENT_CANCEL: 3
} as const;
export type QuotationPaymentState =
  (typeof quotationPaymentStates)[keyof typeof quotationPaymentStates];

export const quotationCoopPaymentStates = {
  STATE_PAYMENT_COOP_PROBLEM: -1,
  STATE_PAYMENT_COOP_PENDING: 0,
  STATE_PAYMENT_COOP_WAITING: 1,
  STATE_PAYMENT_COOP_PAID: 2,
  STATE_PAYMENT_COOP_ALREADY_PAID: 3
} as const;
export type QuotationCoopPaymentState =
  (typeof quotationCoopPaymentStates)[keyof typeof quotationCoopPaymentStates];

export const quotationTypes = {
  TYPE_QUOTATION_NORMAL: 0,
  TYPE_QUOTATION_CONTRACT: 1
} as const;
export type QuotationType = (typeof quotationTypes)[keyof typeof quotationTypes];

export const recoveryActionTypes = {
  TYPE_PHONE_CALL: "Appel Téléphonique",
  TYPE_VOCAL_MESSAGE: "Message Vocal et ou Mail",
  TYPE_CHECK_SIGN_PAIEMENT: "Vérification signature ou paiement",
  TYPE_FEEDBACK_COOP: "Retour au coopérateur",
  TYPE_COOP_CHECK: "Vérification dossier Coopérateur",
  TYPE_DEADLINE_REMINDER: "Rappel deadline avant suppression relevé",
  TYPE_DEADLINE_DELETE: "Deadline suppresion relevé",
  TYPE_QUOTATION_REINIT: "Relevé réinitialisé (souffrance)"
} as const;
export type RecoveryActionType = (typeof recoveryActionTypes)[keyof typeof recoveryActionTypes];

export const paymentMethodCodes = {
  CODE_CHEQUE: "cheque",
  CODE_CESU: "cesu",
  CODE_VIREMENT: "virement",
  CODE_CB: "cb",
  CODE_SEPA: "prelevement-sepa",
  CODE_CARTE_PRE: "carte-prefinancee",
  CODE_AVANCE_IMMEDIATE: "avance-immediate"
} as const;
export type PaymentMethodCode = (typeof paymentMethodCodes)[keyof typeof paymentMethodCodes];

export const quotationTaskPeriods = {
  TASK_PERIOD_ONCE_A_MONTH: "once-a-month",
  TASK_PERIOD_EVERY_2_MONTHS: "every-2-months",
  TASK_PERIOD_FOUR_TIMES_A_YEAR: "four-times-a-year",
  TASK_PERIOD_TWICE_A_YEAR: "twice-a-year",
  TASK_PERIOD_ONCE_A_YEAR: "once-a-year"
} as const;
export type QuotationTaskPeriod = (typeof quotationTaskPeriods)[keyof typeof quotationTaskPeriods];

export const quotationTaskPeriodTexts = {
  TASK_PERIOD_ONCE_A_MONTH: "Une fois par mois",
  TASK_PERIOD_EVERY_2_MONTHS: "Une fois tous les 2 mois",
  TASK_PERIOD_FOUR_TIMES_A_YEAR: "Une fois tous les 3 mois",
  TASK_PERIOD_TWICE_A_YEAR: "Une fois tous les 6 mois",
  TASK_PERIOD_ONCE_A_YEAR: "Une fois par an"
} as const;

export const scheduleQuotationTaskStates = {
  STATE_ERROR: -1,
  STATE_CANCELED: 0,
  STATE_TREATED: 1,
  STATE_WAITING: 2
} as const;
export type ScheduleQuotationTaskState =
  (typeof scheduleQuotationTaskStates)[keyof typeof scheduleQuotationTaskStates];

export const taskStates = {
  STATE_WAITING: 0,
  STATE_TREATED: 1,
  STATE_ERROR: 2,
  STATE_PROGRESS: 3,
  STATE_TODO: 4
} as const;
export type TaskState = (typeof taskStates)[keyof typeof taskStates];

export const urssafStatuses = {
  STATUT_INTEGREE: 10, // La demande de paiement a été reçue et a passé les différents contrôle de cohérence
  STATUT_EN_ATTENTE_VALIDATION: 20, // Le particulier a été prévenu que la demande de paiement est à valider/rejeter par lui
  STATUT_VALIDEE: 30, // La demande de paiement a été validée par le particulier et le circuit de prélèvement est lancé
  STATUT_REFUSEE: 40, // La demande de paiement est refusée par le particulier : il a renseigné son rejet et un code motif associé
  STATUT_PRELEVEE: 50, // La demande de paiement a été payée avec succès par le particulier (le RAC uniquement)
  STATUT_REFUS_PRELEVEMENT: 60, // Le prélèvement associé à la demande de paiement est revenu en erreur . La demande de paiement est en impayée.
  STATUT_PAYEE: 70, // Le virement de paiement de la demande de paiement au prestataire est lancé (fichier de virement ok)
  STATUT_ANNULEE: 110, // La demande de paiement a été annulée par le particulier
  STATUT_RECOUVREE: 120 // La demande de paiement en impayé a été recouvrée par le prestataire
} as const;
export type UrssafStatus = (typeof urssafStatuses)[keyof typeof urssafStatuses];

export const legalForms = {
  AE: "AE",
  ASSOCIATION: "ASSOCIATION",
  EARL: "EARL",
  EI: "EI",
  EIRL: "EIRL",
  EURL: "EURL",
  // ME: "ME",
  MICRO_ENTREPRISE: "Micro Entreprise",
  PROFESSION_LIBERALE: "Profession Libérale",
  SA: "SA",
  SARL: "SARL",
  SARLU: "SARLU",
  SAS: "SAS",
  SASU: "SASU",
  SCDF: "SCDF",
  SCIC: "SCIC",
  SCM: "SCM",
  SCOPARL: "SCOPARL",
  SCP: "SCP",
  SEL: "SEL",
  SELARL: "SELARL"
} as const;
export type LegalForm = (typeof legalForms)[keyof typeof legalForms];

export const exportTypes = {
  EXPORT_COOPERATORS: 1,
  EXPORT_CUSTOMERS: 2,
  EXPORT_COMMERCIALS: 3,
  EXPORT_RELEVES: 4,
  EXPORT_CUSTOMER_BILL: 5,
  EXPORT_COOPERATOR_BILL: 6,
  EXPORT_COMPTA_TIERS: 9,
  EXPORT_REGLEMENTS: 12,
  EXPORT_REGLEMENTS_COOP: 13,
  EXPORT_BENEFICIAIRE: 15,
  EXPORT_CUSTOMER_CONTRACT_BILL: 16,
  EXPORT_LOG_CREDIT: 17,
  EXPORT_MEMBERSHIPS: 18,
  EXPORT_SAGE_TRANSACTIONS: 20,
  EXPORT_SAGE_ALL: 21
} as const;
export type ExportType = (typeof exportTypes)[keyof typeof exportTypes];

// public const CUSTOMER_APPAREILLAGE_VALIDE = 'APPAREILLAGE_VALIDE';
// public const CUSTOMER_APPAREILLAGE_EC = 'APPAREILLAGE_EC';
// public const CUSTOMER_APPAREILLAGE_NF = 'APPAREILLAGE_NF';
// public const CUSTOMER_PARTICULIER_BLOQUE = 'PARTICULIER_BLOQUE';
// public static array $getCustomerMessageError = [
//     self::CUSTOMER_APPAREILLAGE_VALIDE => 'Avance immédiate disponible pour ce client.',
//     self::CUSTOMER_APPAREILLAGE_EC => 'Avance immédiate non disponible, appareillage en cours de traitement.',
//     self::CUSTOMER_APPAREILLAGE_NF => 'Avance immédiate non disponible, appareillage non finalisé.',
//     self::CUSTOMER_PARTICULIER_BLOQUE => 'Avance immédiate non disponible pour ce client (bloqué).',
// ];

export const urssafStatusTexts = {
  APPAREILLAGE_VALIDE: "Avance immédiate disponible pour ce client.",
  APPAREILLAGE_EC: "Avance immédiate non disponible, appareillage en cours de traitement.",
  APPAREILLAGE_NF: "Avance immédiate non disponible, appareillage non finalisé.",
  PARTICULIER_BLOQUE: "Avance immédiate non disponible pour ce client (bloqué)."
} as const;
