import type { ReprogramQuotationTaskSchema } from "~/schemas/quotation-task.schema";
import type { PrefinancingContractSchema, QuotationSchema } from "~/schemas/quotation.schema";
import type { Quotation } from "~/types/quotation.types";
import type { RecoveryQuotation } from "~/types/recovery-quotation.types";
import type { TaskQuotation } from "~/types/task-quotation.types";
import type { DeepPartial } from "~/types/utils.types";

import { INFINITE_SCROLL_LIMIT } from "~/config/api";
import { quotationCoopPaymentStates, quotationStates } from "~/config/api-constants";
import { api } from "~/api/_config";

export const getQuotations = async ({
  states,
  state,
  statePaymentCooperator,
  createdAtAfter,
  createdAtBefore,
  invoicedCustomerAtAfter,
  invoicedCustomerAtBefore,
  invoicedCooperatorAtAfter,
  invoicedCooperatorAtBefore,
  id,
  idFacture,
  minTotalTTC,
  maxTotalTTC,
  typeQuotation,
  customerLastname,
  customerCompanyName,
  cooperatorLastname,
  cooperatorCompanyName,
  invoicedCooperatorPaidAt,
  page,
  itemsPerPage,
  statePaymentCooperatorSort
}: {
  states?: number[];
  state?: number;
  statePaymentCooperator?: number;
  typeQuotation?: number;
  createdAtAfter?: string;
  createdAtBefore?: string;
  invoicedCustomerAtAfter?: string;
  invoicedCustomerAtBefore?: string;
  invoicedCooperatorAtAfter?: string;
  invoicedCooperatorAtBefore?: string;
  invoicedCooperatorPaidAt?: string;
  id?: string;
  idFacture?: string;
  minTotalTTC?: number;
  maxTotalTTC?: number;
  customerLastname?: string;
  customerCompanyName?: string;
  cooperatorLastname?: string;
  cooperatorCompanyName?: string;
  cooperator?: string;
  page?: number;
  itemsPerPage?: number;
  statePaymentCooperatorSort?: string;
}) => {
  const params = {
    ...(state !== undefined ? { state } : states ? { "state[]": states } : {}),
    ...(statePaymentCooperator !== undefined && { statePaymentCooperator }),
    ...(typeQuotation !== undefined && { typeQuotation: typeQuotation.toString() }),
    ...(createdAtAfter && { "createdAt[after]": createdAtAfter }),
    ...(createdAtBefore && { "createdAt[before]": createdAtBefore + "T23:59:59" }),
    ...(invoicedCustomerAtAfter && { "invoicedCustomerAt[after]": invoicedCustomerAtAfter }),
    ...(invoicedCustomerAtBefore && {
      "invoicedCustomerAt[before]": invoicedCustomerAtBefore + "T23:59:59"
    }),
    ...(invoicedCooperatorAtAfter && { "invoicedCooperatorAt[after]": invoicedCooperatorAtAfter }),
    ...(invoicedCooperatorAtBefore && {
      "invoicedCooperatorAt[before]": invoicedCooperatorAtBefore + "T23:59:59"
    }),
    ...(id && { id: id.slice(-6) }), // 6 derniers caractères
    ...(idFacture && { idFacture: idFacture.slice(-6) }), // 6 derniers caractères
    ...(customerLastname && { "customer.lastname": customerLastname }),
    ...(customerCompanyName && { "customer.companyName": customerCompanyName }),
    ...(cooperatorLastname && { "cooperator.lastname": cooperatorLastname }),
    ...(cooperatorCompanyName && { "cooperator.companyName": cooperatorCompanyName }),
    ...(invoicedCooperatorPaidAt && { invoicedCooperatorPaidAt }),
    ...(minTotalTTC && { "totalTTC[gte]": minTotalTTC }),
    ...(maxTotalTTC && { "totalTTC[lte]": maxTotalTTC }),
    ...(statePaymentCooperatorSort && {
      order: { statePaymentCooperator: statePaymentCooperatorSort }
    }),
    page,
    itemsPerPage: itemsPerPage ?? INFINITE_SCROLL_LIMIT
  };

  const response = await api.get<DeepPartial<Quotation[]>>(`/api/admin/quotations`, { params });
  return response.data;
};

export const getRecoveryQuotations = async ({
  states,
  state,
  createdAtAfter,
  createdAtBefore,
  id,
  minTotalTTC,
  maxTotalTTC,
  customer,
  cooperator,
  invoicedCooperatorPaidAt,
  page,
  itemsPerPage
}: {
  states?: number[];
  state?: number;
  createdAtAfter?: string;
  createdAtBefore?: string;
  id?: string;
  minTotalTTC?: number;
  maxTotalTTC?: number;
  customer?: string;
  cooperator?: string;
  invoicedCooperatorPaidAt?: string;
  page?: number;
  itemsPerPage?: number;
}) => {
  const params = {
    ...(state ? { state } : states ? { "state[]": states } : {}),
    ...(createdAtAfter && { "createdAt[after]": createdAtAfter }),
    ...(createdAtBefore && { "createdAt[before]": createdAtBefore + "T23:59:59" }),
    ...(id && { id: id.slice(-6) }), // 6 derniers caractères
    ...(customer && {
      "customer.lastname": customer,
      "customer.firstname": customer,
      "customer.companyName": customer
    }),
    ...(cooperator && {
      "cooperator.lastname": cooperator,
      "cooperator.firstname": cooperator,
      "cooperator.companyName": cooperator
    }),
    ...(invoicedCooperatorPaidAt && { invoicedCooperatorPaidAt }),
    ...(minTotalTTC && maxTotalTTC && { "totalTTC[between]": `${minTotalTTC}..${maxTotalTTC}` }),
    page,
    itemsPerPage: itemsPerPage ?? INFINITE_SCROLL_LIMIT
  };

  const response = await api.get<DeepPartial<RecoveryQuotation[]>>(
    `/api/admin/quotations/recoveries`,
    {
      params
    }
  );
  return response.data;
};

export const getTaskQuotations = async ({
  states,
  state,
  scheduledAtAfter,
  scheduledAtBefore,
  id,
  customerLastname,
  customerFirstname,
  customerCompanyName,
  cooperatorLastname,
  cooperatorFirstname,
  cooperatorCompanyName,
  isRecoverable,
  page,
  itemsPerPage
}: {
  states?: number[];
  state?: number;
  scheduledAtAfter?: string;
  scheduledAtBefore?: string;
  id?: string;
  customerLastname?: string;
  customerFirstname?: string;
  customerCompanyName?: string;
  cooperatorLastname?: string;
  cooperatorFirstname?: string;
  cooperatorCompanyName?: string;
  isRecoverable?: boolean;
  page?: number;
  itemsPerPage?: number;
}) => {
  const params = {
    ...(state ? { state } : states ? { "state[]": states } : {}),
    ...(scheduledAtAfter && { "scheduledAt[after]": scheduledAtAfter }),
    ...(scheduledAtBefore && { "scheduledAt[before]": scheduledAtBefore + "T23:59:59" }),
    ...(id && { id: id.slice(-6) }), // 6 derniers caractères
    ...(customerLastname && {
      "quotationMaster.customer.lastname": customerLastname
    }),
    ...(customerFirstname && {
      "quotationMaster.customer.firstname": customerFirstname
    }),
    ...(customerCompanyName && {
      "quotationMaster.customer.companyName": customerCompanyName
    }),
    ...(cooperatorLastname && {
      "quotationMaster.cooperator.lastname": cooperatorLastname
    }),
    ...(cooperatorFirstname && {
      "quotationMaster.customer.firstname": cooperatorFirstname
    }),
    ...(cooperatorCompanyName && {
      "quotationMaster.cooperator.companyName": cooperatorCompanyName
    }),
    ...(isRecoverable && { isRecoverable }),
    page,
    itemsPerPage: itemsPerPage ?? INFINITE_SCROLL_LIMIT
  };

  const response = await api.get<DeepPartial<TaskQuotation[]>>(
    "/api/admin/schedule_quotation_tasks",
    {
      params
    }
  );
  return response.data;
};

export const updateTaskQuotation = async ({
  id,
  data
}: {
  id: TaskQuotation["id"];
  data: ReprogramQuotationTaskSchema;
}) => {
  const response = await api.put(`/api/admin/schedule_quotation_tasks/${id}`, data);
  return response.data;
};

export const getQuotation = async ({ id }: { id: Quotation["id"] }) => {
  const response = await api.get<DeepPartial<Quotation>>(`/api/admin/quotations/${id}`);
  return response.data;
};

export const createQuotation = async ({
  data
}: {
  data: QuotationSchema | PrefinancingContractSchema;
}) => {
  const response = await api.post("/api/admin/quotations", data);
  return response.data;
};

export const updateQuotation = async ({
  id,
  data
}: {
  id: Quotation["id"];
  data: QuotationSchema | PrefinancingContractSchema;
}) => {
  const response = await api.patch(`/api/admin/quotations/${id}`, data);
  return response.data;
};

export const unblockQuotationPayment = async ({ id }: { id: Quotation["id"] }) => {
  const response = await api.patch(`/api/admin/quotations/${id}`, {
    statePaymentCooperator: 1
  });
  return response.data;
};

export const unlockMultipleQuotationPayments = async ({ ids }: { ids: Quotation["id"][] }) => {
  const response = await api.patch(`/api/admin/quotations/invoices/cooperator/waiting`, {
    quotation_id: ids
  });
  return response.data;
};

export const putQuotationPaymentOnPending = async ({ id }: { id: Quotation["id"] }) => {
  const response = await api.patch(
    `/api/admin/quotations/${id}`,
    {
      statePaymentCooperator: 0
    },
    {}
  );
  return response.data;
};

export const putQuotationPaymentOnProblem = async ({ id }: { id: Quotation["id"] }) => {
  const response = await api.patch(
    `/api/admin/quotations/${id}`,
    {
      statePaymentCooperator: quotationCoopPaymentStates.STATE_PAYMENT_COOP_PROBLEM
    },
    {}
  );
  return response.data;
};

export const cancelQuotationTask = async ({ id }: { id: TaskQuotation["id"] }) => {
  const response = await api.put(`/api/admin/schedule_quotation_tasks/${id}`, {
    state: quotationStates.STATE_CANCEL
  });
  return response.data;
};

export const cancelMultipleQuotationTasks = async ({ ids }: { ids: TaskQuotation["id"][] }) => {
  const response = await api.patch(`/api/admin/schedule_quotation_tasks/bulk/cancel`, {
    schedule_quotation_task_id: ids
  });
  return response.data;
};

export const deleteQuotation = async ({ id }: { id: Quotation["id"] }) => {
  const response = await api.delete(`/api/admin/quotations/${id}`);
  return response.data;
};

export const passQuotationToBilling = async ({ id }: { id: Quotation["id"] }) => {
  const response = await api.put(`/api/admin/quotations/${id}`, {
    state: quotationStates.STATE_BILL
  });
  return response.data;
};

export const passMultipleQuotationsToBilling = async ({ ids }: { ids: Quotation["id"][] }) => {
  const response = await api.patch(`/api/admin/quotations/to/facture`, {
    quotation_id: ids
  });
  return response.data;
};

export const renewQuotation = async ({ id }: { id: Quotation["id"] }) => {
  const response = await api.post(`/api/admin/quotations/${id}/renew`, {});
  return response.data;
};

export const sendQuotationInvoiceToCustomer = async ({ id }: { id: Quotation["id"] }) => {
  const response = await api.post(`/api/admin/quotations/${id}/send/invoice`, {});
  return response.data;
};

export const sendQuotationSignatureToCustomer = async ({ id }: { id: Quotation["id"] }) => {
  const response = await api.post(`/api/admin/quotations/${id}/send/signature`, {});
  return response.data;
};

export const duplicateQuotationFromInvoice = async ({ id }: { id: Quotation["id"] }) => {
  const response = await api.post(`/api/admin/quotations/${id}/duplicate`, {});
  return response.data;
};

export const exportQuotationBills = async ({
  dateStart,
  dateEnd
}: {
  dateStart: string;
  dateEnd: string;
}) => {
  const response = await api.post("/api/admin/quotations/export/cooperator/invoices/csv", null, {
    params: { dateStart, dateEnd }
  });
  return response.data;
};
