import type { PropsWithChildren } from "react";
import type { ModalProps } from "~/components/ui/Modal/Modal";

import { useQuery } from "@tanstack/react-query";

import { queryKeys } from "~/config/query-keys-constants";
import { formatDisplayDate, formatMoney } from "~/utils/misc";
import { useFileDeliver } from "~/hooks/useFileDeliver";
import { getUser } from "~/api/user";
import { Button } from "~/components/ui/Button/Button";
import { LoadingSpinner } from "~/components/ui/LoadingSpinner";
import { Modal } from "~/components/ui/Modal/Modal";
import { Table } from "~/components/ui/Table";

interface BeneficiaryTransactionsModalProps extends ModalProps {
  beneficiaryId: number;
}

export const BeneficiaryTransactionsModal = ({
  beneficiaryId,
  ...props
}: PropsWithChildren<BeneficiaryTransactionsModalProps>) => {
  const { openFile } = useFileDeliver();

  const queryParams = {
    id: beneficiaryId
  };

  const { data: beneficiary, isLoading: isBeneficiaryLoading } = useQuery({
    queryKey: [queryKeys.users, queryParams],
    queryFn: () => getUser(queryParams)
  });

  return (
    <Modal title="Réglements" {...props}>
      <div className="mb-6 flex items-center justify-between gap-4">
        <h2 className="m-0 text-xl font-semibold">Historique des transactions</h2>
        <Button
          onClick={() =>
            openFile({
              itemType: "beneficiary-transactions",
              itemParams: { id: beneficiaryId }
            })
          }
          disabled={isBeneficiaryLoading}
        >
          Exporter en PDF
        </Button>
      </div>
      {isBeneficiaryLoading ? (
        <LoadingSpinner fullPage />
      ) : beneficiary?.creditServices && beneficiary.creditServices.length > 0 ? (
        <>
          <Table
            data={{
              headers: ["Contrat", "Montant", "Après transaction", "Motif", "Date"],
              rows: beneficiary.creditServices.flatMap((creditService) => [
                {
                  key: `new-${creditService.id}`,
                  columns: [
                    <div key={`new-cols-${creditService.id}`} className="w-full text-center">
                      --- Ouverture nouveau contrat ---
                    </div>
                  ]
                },
                ...creditService.creditServiceTransactions.flatMap((transaction) => ({
                  key: `${creditService.id}-${transaction.id}`,
                  columns: [
                    <div
                      key={`cols-${creditService.id}-${transaction.id}`}
                      className="flex w-max flex-col gap-1"
                    >
                      <div>{creditService.contract.idNormalized}</div>
                      <div className="text-xs">
                        {creditService.contract.cooperator.firstname}{" "}
                        {creditService.contract.cooperator.lastname}
                      </div>
                      <div className="text-xs">{creditService.contract.cooperator.companyName}</div>
                    </div>,
                    <>
                      {transaction.typeTransaction === 1 ? "-" : "+"}
                      {formatMoney(transaction.total || "")}
                    </>,
                    formatMoney(transaction.newAmount || ""),
                    transaction.comment,
                    formatDisplayDate(transaction.createdAt || "")
                  ]
                }))
              ])
            }}
          />
        </>
      ) : (
        <p>Aucune transaction.</p>
      )}
    </Modal>
  );
};
