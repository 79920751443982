import { Fragment } from "react";
import { NavLink, useLocation } from "react-router-dom";

import { cn, invalidateAllQueries } from "~/utils/misc";
import { queryClient } from "~/api/_queryClient";

export const SubNavigation = ({
  links
}: {
  links: { label: string; to: string; condition?: boolean }[];
}) => {
  const location = useLocation();

  return (
    <nav className="no-scrollbar flex flex-nowrap gap-8 overflow-x-scroll border-b border-grey-200">
      {links.map((link) => (
        <Fragment key={link.to}>
          {link.condition === undefined || link.condition === true ? (
            <NavLink
              to={link.to}
              onClick={() => {
                if (location.pathname === link.to) {
                  invalidateAllQueries(queryClient);
                }
              }}
              className={(isActive) =>
                cn(
                  isActive
                    ? "border-primary text-primary"
                    : "border-transparent text-grey-500 hover:border-grey-400 hover:text-grey-700",
                  "shrink-0 border-b-2 px-1 py-2 text-sm font-medium transition-colors"
                )
              }
            >
              {link.label}
            </NavLink>
          ) : null}
        </Fragment>
      ))}
    </nav>
  );
};
