export const queryKeys = {
  users: "users",
  quotations: "quotations",
  recoveryQuotations: "recovery-quotations",
  taskQuotations: "task-quotations",
  quotationPayments: "quotation-payments",
  recoveryActions: "recovery-actions",
  recoveriesActions: "recoveries-actions",
  memberships: "memberships",
  membershipPayments: "membership-payments",
  attestations: "attestations",
  jobs: "jobs",
  configs: "configs",
  configsWithoutPagination: "configs-without-pagination",
  paymentExports: "payment-exports",
  paymentMethods: "payment-methods",
  exports: "exports",
  modules: "modules",
  emails: "emails",
  sms: "sms",
  addresses: "addresses",
  countries: "countries",
  creditServices: "credit-services",
  remainingCredit: "remaining-credit",
  statisticsMain: "statistics-main",
  statisticsGlobal: "statistics-global",
  statisticsRecoveries: "statistics-recoveries",
  cooperatorCategories: "cooperator-categories"
};
