import type { UseFormRegister } from "react-hook-form";

import dayjs from "dayjs";

import { transformDateTimeToDate } from "~/utils/misc";
import { FormDatePicker } from "~/components/ui/FormDatePicker";
import { FormInput } from "~/components/ui/FormInput";

export const QuotationsBillsSearchForm = ({ register }: { register: UseFormRegister<any> }) => {
  return (
    <>
      <FormDatePicker
        label="Date min"
        defaultValue={transformDateTimeToDate(dayjs().subtract(3, "year").toISOString()) || ""}
        optional
        {...register("invoicedCustomerAtAfter")}
      />
      <FormDatePicker
        label="Date max"
        defaultValue={transformDateTimeToDate(dayjs().toISOString()) || ""}
        optional
        {...register("invoicedCustomerAtBefore")}
      />
      <FormInput
        label="Nom du client"
        placeholder="ex: Dupond"
        type="text"
        optional
        {...register("customerLastname")}
      />
      <FormInput
        label="Société du client"
        placeholder="ex: HEXA-COOP"
        type="text"
        optional
        {...register("customerCompanyName")}
      />
      <FormInput
        label="Nom du coopérateur"
        placeholder="ex: Dupont"
        type="text"
        optional
        {...register("cooperatorLastname")}
      />
      <FormInput
        label="Société du coopérateur"
        placeholder="ex: Jardi Services"
        type="text"
        optional
        {...register("cooperatorCompanyName")}
      />
      <FormInput
        label="Numéro de facture"
        placeholder="6 derniers chiffres (ex: 202622)"
        type="text"
        optional
        {...register("idFacture")}
      />
      <FormInput
        label="Numéro de relevé"
        placeholder="6 derniers chiffres (ex: 202622)"
        type="text"
        optional
        {...register("id")}
      />
      <FormInput
        label="Montant TTC min (€)"
        placeholder="ex: 1000"
        type="number"
        optional
        {...register("minTotalTTC")}
      />
      <FormInput
        label="Montant TTC max (€)"
        placeholder="ex: 1500"
        type="number"
        optional
        {...register("maxTotalTTC")}
      />
      <FormDatePicker label="Payé le" optional {...register("invoicedCooperatorPaidAt")} />
    </>
  );
};
