import type { UseFormRegister } from "react-hook-form";

import dayjs from "dayjs";

import { membershipStates } from "~/config/api-constants";
import { transformDateTimeToDate } from "~/utils/misc";
import { FormDatePicker } from "~/components/ui/FormDatePicker";
import { FormInput } from "~/components/ui/FormInput";
import { FormSelect } from "~/components/ui/FormSelect";

export const CooperatorsWaitingMembershipSearchForm = ({
  register
}: {
  register: UseFormRegister<any>;
}) => {
  return (
    <>
      <FormDatePicker
        label="Date min"
        defaultValue={transformDateTimeToDate(dayjs().subtract(3, "year").toISOString()) || ""}
        optional
        {...register("createdAtAfter")}
      />
      <FormDatePicker
        label="Date max"
        defaultValue={transformDateTimeToDate(dayjs().toISOString()) || ""}
        optional
        {...register("createdAtBefore")}
      />
      <FormInput
        label="Nom du coopérateur"
        placeholder="ex: Dupont"
        type="text"
        optional
        {...register("cooperatorLastname")}
      />
      <FormInput
        label="Société du coopérateur"
        placeholder="ex: Jardi Services"
        type="text"
        optional
        {...register("cooperatorCompanyName")}
      />
      <FormInput
        label="Numéro de l'adhésion"
        placeholder="4 derniers chiffres (ex: 2820)"
        type="text"
        optional
        {...register("id")}
      />
      <FormInput
        label="Montant TTC min (€)"
        placeholder="Ex: 1000"
        type="number"
        optional
        {...register("minTotalTTC")}
      />
      <FormInput
        label="Montant TTC max (€)"
        placeholder="Ex: 1500"
        type="number"
        optional
        {...register("maxTotalTTC")}
      />
      <FormSelect label="Statut" optional {...register("state")}>
        <option value="">Tous</option>
        <option value={membershipStates.STATE_DRAFT}>Brouillon</option>
        <option value={membershipStates.STATE_SEND}>En attente de signature</option>
        <option value={membershipStates.STATE_SIGNED}>
          Signé, en attente de choix du réglement
        </option>
        <option value={membershipStates.STATE_PENDING}>En attente de réglement</option>
        <option value={membershipStates.STATE_PENDING_CHOOSE}>
          En attente de réglement (choisi)
        </option>
        <option value={membershipStates.STATE_PARTIAL_PAID}>Réglement incomplet</option>
        <option value={membershipStates.STATE_PAID}>Payé</option>
      </FormSelect>
      <FormSelect label="Première adhésion" optional {...register("hasSocialPart")}>
        <option value="">Tous</option>
        <option value="true">Oui</option>
        <option value="false">Non</option>
      </FormSelect>
    </>
  );
};
