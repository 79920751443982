import type { MainTableProps } from "~/components/ui/MainTable";

import { useInfiniteQuery } from "@tanstack/react-query";
import { createOutline } from "ionicons/icons";

import { queryKeys } from "~/config/query-keys-constants";
import { formatDisplayDate } from "~/utils/misc";
import { getNextPageParamFn } from "~/api/_config";
import { getConfigs } from "~/api/config";
import { TableLayout } from "~/components/TableLayout";

export const AdministrationConfig = () => {
  const { data, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: [queryKeys.configs],
    queryFn: ({ pageParam }) => getConfigs({ page: pageParam }),
    getNextPageParam: getNextPageParamFn
  });

  const tableData: MainTableProps["data"] =
    data?.pages.flat().map((config) => ({
      itemId: config?.id,
      actions: [
        {
          label: "Éditer la configuration",
          icon: createOutline,
          color: "blue",
          href: `/administration/configurations/edit/${config?.id}`
        }
      ],
      columns: [
        { key: "code", label: "Code", display: config?.code },
        { key: "name", label: "Nom", display: config?.name },
        { key: "value", label: "Valeur", display: <div>{config?.value}</div> },
        {
          key: "updatedAt",
          label: "Mis à jour le",
          display: config?.updatedAt ? formatDisplayDate(config.updatedAt) : "",
          sort: config?.updatedAt,
          search: config?.updatedAt ? formatDisplayDate(config.updatedAt) : ""
        }
      ]
    })) || [];

  return (
    <>
      <TableLayout
        data={tableData}
        isLoading={isFetching || isFetchingNextPage}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
      />
    </>
  );
};
