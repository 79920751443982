import type { PropsWithChildren, ReactNode } from "react";

import { forwardRef } from "react";

import { cn } from "~/utils/misc";

type FormCheckboxProps = PropsWithChildren<{
  label?: ReactNode;
  infoMessage?: string | null | undefined;
  warningMessage?: string | null | undefined;
  errorMessage?: string | null | undefined;
  optional?: boolean;
}> &
  React.ComponentPropsWithRef<"input">;

export const FormCheckbox = forwardRef<HTMLInputElement, FormCheckboxProps>(
  function ForwardedRefFormCheckbox(
    { label, infoMessage, warningMessage, errorMessage, className, ...props },
    ref
  ) {
    // const [selectedOption, setSelectedOption] = useState<SelectOption>(null);

    return (
      <div className={className}>
        <div
          className={cn(
            "group flex items-start",
            props.disabled && "pointer-events-none opacity-50"
          )}
        >
          <input
            type="checkbox"
            className="mt-0.5 h-4 w-4 shrink-0 rounded border-gray-300 text-primary focus:ring-primary"
            id={props.id || props.name}
            ref={ref}
            {...props}
          />
          <label
            htmlFor={props.id || props.name}
            className="ml-3 text-sm font-normal text-grey-800"
          >
            {label}
          </label>
        </div>

        {errorMessage ? (
          <p className="mx-1 mt-1 whitespace-pre-line text-sm text-danger-700">{errorMessage}</p>
        ) : null}
        {warningMessage ? (
          <p className="mx-1 mt-1 whitespace-pre-line text-xs text-danger-700">{warningMessage}</p>
        ) : null}
        {infoMessage ? (
          <p className="mx-1 mt-1 whitespace-pre-line text-xs text-grey-500">{infoMessage}</p>
        ) : null}
      </div>
    );
  }
);
