import type { UseFormRegister } from "react-hook-form";

import dayjs from "dayjs";

import { transformDateTimeToDate } from "~/utils/misc";
import { FormDatePicker } from "~/components/ui/FormDatePicker";
import { FormInput } from "~/components/ui/FormInput";

export const CooperatorsMembershipBillsSearchForm = ({
  register
}: {
  register: UseFormRegister<any>;
}) => {
  return (
    <>
      <FormDatePicker
        label="Date min"
        defaultValue={transformDateTimeToDate(dayjs().subtract(3, "year").toISOString()) || ""}
        optional
        {...register("invoicedAtAfter")}
      />
      <FormDatePicker
        label="Date max"
        defaultValue={transformDateTimeToDate(dayjs().toISOString()) || ""}
        optional
        {...register("invoicedAtBefore")}
      />
      <FormInput
        label="Numéro de facture"
        placeholder="5 derniers chiffres (ex: 28120)"
        type="text"
        optional
        {...register("idFacture")}
      />
      <FormInput
        label="Numéro de contrat"
        placeholder="5 derniers chiffres (ex: 28120)"
        type="text"
        optional
        {...register("id")}
      />
      <FormInput label="Montant TTC min (€)" type="number" optional {...register("minTotalTTC")} />
      <FormInput label="Montant TTC max (€)" type="number" optional {...register("maxTotalTTC")} />
      <FormInput
        label="Nom du coopérateur"
        placeholder="ex: Dupont"
        type="text"
        optional
        {...register("cooperatorLastname")}
      />
      <FormInput
        label="Société du coopérateur"
        placeholder="ex: Jardi Services"
        type="text"
        optional
        {...register("cooperatorCompanyName")}
      />
    </>
  );
};
