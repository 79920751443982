import type { EditorProps } from "react-draft-wysiwyg";

import { forwardRef } from "react";
import { ContentState, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { Editor } from "react-draft-wysiwyg";

import { cn } from "~/utils/misc";
import { useIsMounted } from "~/hooks/useIsMounted";
import { FormLabel } from "~/components/ui/FormLabel";

export const getEditorState = (content: string | null | undefined) => {
  const { contentBlocks, entityMap } = htmlToDraft(content || "");
  const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
  return EditorState.createWithContent(contentState);
};

type FormRichTextEditorProps = Omit<EditorProps, "onChange"> & {
  defaultValue?: string | null | undefined;
  onChange: (html: string) => void;
  label?: string;
  id?: string;
  name?: string;
  optional?: boolean;
  errorMessage?: string | null | undefined;
  warningMessage?: string | null | undefined;
  infoMessage?: string | null | undefined;
  className?: string;
};

export const FormRichTextEditor = forwardRef<any, FormRichTextEditorProps>(
  function ForwardedFormRichTextEditor({ onChange, ...props }, ref) {
    const isMounted = useIsMounted();

    return isMounted() ? (
      <div className={cn("flex flex-col", props.className)}>
        {props.label ? (
          <FormLabel htmlFor={props.id || props.name} optional={props.optional || false}>
            {props.label}
          </FormLabel>
        ) : null}
        <Editor
          defaultEditorState={getEditorState(props.defaultValue || "")}
          onChange={(state) => onChange(draftToHtml(state))}
          locale="fr"
          wrapperClassName="border border-grey-200 rounded-lg p-2 h-96 flex flex-col gap-1 overflow-x-hidden"
          toolbarClassName="border border-grey-200 rounded-md px-1 pt-1 mb-2"
          editorClassName="border border-grey-200 rounded-md p-2 grow h-auto"
          toolbar={{
            options: ["inline", "blockType", "fontSize", "list", "textAlign", "link"],
            inline: {
              inDropdown: false,
              options: ["bold", "italic", "underline", "strikethrough"]
            },
            blockType: {
              inDropdown: true,
              options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6", "Blockquote"]
            },
            list: {
              inDropdown: true,
              options: ["unordered", "ordered"]
            }
          }}
          ref={ref}
          {...props}
        />

        {props.errorMessage ? (
          <p className="mx-1 mt-1 whitespace-pre-line text-sm text-danger-700">
            {props.errorMessage}
          </p>
        ) : null}

        {props.warningMessage ? (
          <p className="mx-1 mt-1 whitespace-pre-line text-xs text-danger-700">
            {props.warningMessage}
          </p>
        ) : null}

        {props.infoMessage ? (
          <p className="mx-1 mt-1 whitespace-pre-line text-xs text-grey-500">{props.infoMessage}</p>
        ) : null}
      </div>
    ) : null;
  }
);
